var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.years.length
      ? _c("div", { staticClass: "row mb-3" }, [
          _c("div", { staticClass: "col-12 text-center" }, [
            _c(
              "nav",
              { staticClass: "nav nav-pills" },
              _vm._l(_vm.years, function (year) {
                return _c(
                  "a",
                  {
                    key: year,
                    staticClass: "nav-link",
                    class: { active: year == _vm.yearShowing },
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.setShowingYear(year)
                      },
                    },
                  },
                  [_vm._v("\n          " + _vm._s(year) + "\n        ")]
                )
              }),
              0
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      _vm._l(_vm.tables, function (table) {
        return _c(
          "div",
          { key: table.id, staticClass: "col-12 col-lg-6 mt-2" },
          [
            _c("h6", [_vm._v(_vm._s(table.name))]),
            _vm._v(" "),
            _c(
              "conditional-wrapper",
              {
                attrs: {
                  "show-for-admin": true,
                  "show-for-executives": false,
                  "show-for-customers": false,
                  disable: true,
                },
              },
              [
                _c("hot-table", {
                  attrs: {
                    "nested-headers": _vm.colHeaders,
                    columns: _vm.columns,
                    data: table.items,
                    "max-cols": 4,
                    "license-key": "non-commercial-and-evaluation",
                  },
                }),
              ],
              1
            ),
          ],
          1
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "row mt-4" }, [
      _c("div", { staticClass: "col-12 text-center" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            on: {
              click: function ($event) {
                return _vm.save()
              },
            },
          },
          [_vm._v("\n        " + _vm._s(_vm.$t("general.save")) + "\n      ")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }