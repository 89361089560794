var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-content" }, [
    _c("div", { staticClass: "page-header pt-5" }, [
      _c("h3", { staticClass: "page-title" }, [
        _vm._v(
          "\n      " + _vm._s(_vm.$t("customers.previous_invoices")) + "\n    "
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mt-3" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3" },
          [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "card p-3" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("general.start_date"))),
                      ]),
                      _vm._v(" "),
                      _c("base-date-picker", {
                        attrs: {
                          invalid: _vm.$v.form.startDate.$error,
                          "calendar-button": true,
                          "calendar-button-icon": "calendar",
                        },
                        model: {
                          value: _vm.form.startDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "startDate", $$v)
                          },
                          expression: "form.startDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", [_vm._v(_vm._s(_vm.$t("general.end_date")))]),
                      _vm._v(" "),
                      _c("base-date-picker", {
                        attrs: {
                          invalid: _vm.$v.form.endDate.$error,
                          "calendar-button": true,
                          "calendar-button-icon": "calendar",
                        },
                        model: {
                          value: _vm.form.endDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "endDate", $$v)
                          },
                          expression: "form.endDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group text-center font-italic" },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t("customers.this_process_may_take_a_while")
                          ) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group text-center" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { disabled: _vm.loading },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("general.save")) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }