<template>
  <font-awesome-icon
    v-tooltip="{ content: messageTooltip }"
    v-if="showIcon"
    class="text-danger"
    icon="exclamation-circle"
  />
</template>

<script>
import moment from 'moment'
export default {
  props: {
    csdExpiresAt: {
      type: String,
      default: null,
    },
    fielExpiresAt: {
      type: String,
      default: null,
    },
  },

  computed: {
    isCsdExpired () {
      if (!this.csdExpiresAt) {
        return false
      }
      const now = moment()
      const csdExpires = moment(this.csdExpiresAt)
      return now > csdExpires
    },
    isFielExpired () {
      if (!this.fielExpiresAt) {
        return false
      }
      const now = moment()
      const fielExpires = moment(this.fielExpiresAt)
      return now > fielExpires
    },
    messageTooltip () {
      const messages = []
      if (this.isFielExpired) {
        messages.push(this.$t('customers.fiel_expired'))
      }
      if (this.isCsdExpired) {
        messages.push(this.$t('customers.csd_expired'))
      }
      return messages.join('<br>')
    },
    showIcon () {
      if (this.isCsdExpired || this.isFielExpired) {
        return true
      }
      return false
    },
  },
}
</script>
