var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-content mt-5" }, [
    _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [
        _vm._v("\n      " + _vm._s(_vm.$t("tax_returns.title")) + "\n    "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page-actions row" },
        [
          _c(
            "router-link",
            {
              staticClass: "col-xs-2 mr-2",
              attrs: {
                slot: "item-title",
                to: { name: "tax-return.tax-losses" },
              },
              slot: "item-title",
            },
            [
              _c(
                "base-button",
                { attrs: { color: "theme", icon: "plus", size: "large" } },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("tax_losses.title")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "base-button",
            {
              attrs: { color: "theme", icon: "plus", size: "large" },
              on: { click: _vm.generateTax },
            },
            [_vm._v("\n        Generar Borrador\n      ")]
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c(
            "div",
            { staticClass: "card p-4" },
            [
              _c(
                "table-component",
                {
                  ref: "table",
                  attrs: {
                    data: _vm.taxReturns,
                    "filter-no-results": _vm.$t("general.filterNoResults"),
                    "is-clickable": true,
                    "show-filter": false,
                    "table-class": "table",
                  },
                },
                [
                  _c("table-column", {
                    attrs: { label: "PERÍODO" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            row.complementaria == true
                              ? _c("font-awesome-icon", {
                                  staticClass: "icon",
                                  attrs: { icon: "file-invoice" },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "tax-return.show",
                                    params: {
                                      id: row.id,
                                      period:
                                        _vm.period(
                                          row.start_month,
                                          row.end_month
                                        ) +
                                        " " +
                                        row.year,
                                      startMonth: row.start_month,
                                      endMonth: row.end_month,
                                      year: row.year,
                                    },
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.period(row.start_month, row.end_month)
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("table-column", { attrs: { label: "AÑO", show: "year" } }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: { label: "RÉGIMEN FISCAL" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _c("tax-regime-catalog", {
                              attrs: { "tax-regime": row.tax_regime },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: { label: "ISR A PAGAR" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$utils.formatMoney(
                                    row.isr_to_pay,
                                    _vm.defaultCurrency
                                  )
                                ),
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: { label: "IVA A CARGO" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$utils.formatMoney(
                                    row.iva_in_charge,
                                    _vm.defaultCurrency
                                  )
                                ),
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: { label: "", "cell-class": "no-click" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _c(
                              "base-button",
                              {
                                attrs: { size: "small", color: "theme" },
                                on: {
                                  click: function ($event) {
                                    _vm.enviaDeclaracion(
                                      row.id,
                                      row.status,
                                      _vm.period(
                                        row.start_month,
                                        row.end_month
                                      ) +
                                        " " +
                                        row.year
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                Enviar\n              "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: { label: "", "cell-class": "no-click" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return row.url != null
                              ? [
                                  _c(
                                    "base-button",
                                    {
                                      attrs: { size: "small", color: "theme" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.verPdf(row.url)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                Ver PDF\n              "
                                      ),
                                    ]
                                  ),
                                ]
                              : undefined
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: { label: "ESTATUS", "cell-class": "no-click" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _c("div", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(row.status) +
                                  "\n              "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: { label: "CHAT", "cell-class": "no-click" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            row.chat > 1
                              ? _c(
                                  "div",
                                  { staticStyle: { "padding-left": "15px" } },
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "text-blue",
                                      attrs: { icon: "comment", size: "lg" },
                                      on: {
                                        click: function ($event) {
                                          _vm.goChat(
                                            row.id,
                                            _vm.period(
                                              row.start_month,
                                              row.end_month
                                            ) +
                                              " " +
                                              row.year
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: {
                      label: "Ultima Actualización",
                      show: "updated_at",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("create-tax-return"),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }