export const fetchTaxReturns = (store) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/customer_modules/tax-returns`)
      .then(resolve)
      .catch(reject)
  })
}

export const fetchTaxReturn = (store, id) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/customer_modules/tax-returns/${id}`)
      .then(resolve)
      .catch(reject)
  })
}
