import * as types from './mutation-types'

export default {
  [types.UPDATED_RIF_ISR](state, rifIsr) {
    state.rifIsr = rifIsr
  },

  [types.UPDATED_RIF_IVA](state, rifIva) {
    state.rifIva = rifIva
  },

  [types.UPDATED_COMPENSACION](state, compensacion) {
    state.compensacion = compensacion
  },

  [types.UPDATED_ACREDITAMIENTO](state, acreditamiento) {
    state.acreditamiento = acreditamiento
  },

  [types.UPDATED_INGRESOS_CFDI](state, ingresos) {
    state.ingresosCfdi = ingresos
  },

  [types.UPDATED_INGRESOS_DISMINUIR](state, ingresos_disminuir) {
    state.ingresoDisminuir = ingresos_disminuir

    let totalDisminuir = ingresos_disminuir.invoices.reduce(
      (total, invoice) => {
        return total + invoice.retention_isr
      },
      0
    )
    let totalDisminuirAdicional = ingresos_disminuir.invoicesAdicional.reduce(
      (total, invoice) => {
        return total + invoice.retention_isr
      },
      0
    )
      console.log('este es el valor ', totalDisminuir + totalDisminuirAdicional)
    state.isrRetenido = {
      retenido: ingresos_disminuir.disminuir,
      ingresoDisminuircfdi: totalDisminuir + totalDisminuirAdicional,
      invoices: ingresos_disminuir.invoices.concat(ingresos_disminuir.invoicesAdicional),
      nota: '',
      monto: 0,
      notaAdicional: '',
      
      retenidoAdicionar: null,
      montoAdicionar: 0,
      notaAdicionar: '',
    }
  },
  [types.UPDATED_INGRESOS_ADICIONAL](state, ingresos_adicional) {
    state.ingresoAdicional = ingresos_adicional
  },

  [types.UPDATED_ISR_RETENIDO](state, isrRetenido) {
    state.isrRetenido = isrRetenido
  },
}
