var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-content invoice-create-page" }, [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitForm.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "page-header mt-5" }, [
          _c("h4", { staticClass: "page-title" }, [
            _vm._v(_vm._s(_vm.$t("expenses.title"))),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "page-actions" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row invoice-input-group" }, [
          _c(
            "div",
            { staticClass: "col invoice-input" },
            [
              _c("label", [
                _vm._v(_vm._s(_vm.$t("expenses.name")) + " "),
                _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
              ]),
              _vm._v(" "),
              _c("base-input", {
                attrs: {
                  invalid: _vm.$v.expenseForm.name.$error,
                  icon: "user",
                  type: "text",
                },
                model: {
                  value: _vm.expenseForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.expenseForm, "name", $$v)
                  },
                  expression: "expenseForm.name",
                },
              }),
              _vm._v(" "),
              _vm.$v.expenseForm.name.$error
                ? _c("div", [
                    !_vm.$v.expenseForm.name.maxLength
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.$t("validation.required"))),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col invoice-input" },
            [
              _c("label", [
                _vm._v(_vm._s(_vm.$t("expenses.expense_date")) + " "),
                _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
              ]),
              _vm._v(" "),
              _c("base-date-picker", {
                attrs: {
                  invalid: _vm.$v.expenseForm.expense_date.$error,
                  "calendar-button": true,
                  "calendar-button-icon": "calendar",
                },
                on: {
                  change: function ($event) {
                    return _vm.$v.expenseForm.expense_date.$touch()
                  },
                },
                model: {
                  value: _vm.expenseForm.expense_date,
                  callback: function ($$v) {
                    _vm.$set(_vm.expenseForm, "expense_date", $$v)
                  },
                  expression: "expenseForm.expense_date",
                },
              }),
              _vm._v(" "),
              _vm.$v.expenseForm.expense_date.$error &&
              !_vm.$v.expenseForm.expense_date.required
                ? _c("span", { staticClass: "text-danger mt-1" }, [
                    _vm._v(
                      " \n          " +
                        _vm._s(_vm.$t("validation.required")) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col invoice-input" },
            [
              _c("label", [_vm._v(_vm._s(_vm.$t("expenses.expense_number")))]),
              _vm._v(" "),
              _c("base-input", {
                attrs: { icon: "hashtag", type: "text" },
                model: {
                  value: _vm.expenseForm.expense_number,
                  callback: function ($$v) {
                    _vm.$set(_vm.expenseForm, "expense_number", $$v)
                  },
                  expression: "expenseForm.expense_number",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mt-4" }, [
          _c(
            "div",
            { staticClass: "col invoice-input" },
            [
              _c("label", [
                _vm._v(_vm._s(_vm.$t("expenses.rfc")) + " "),
                _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
              ]),
              _vm._v(" "),
              _c("base-input", {
                attrs: {
                  invalid: _vm.$v.expenseForm.rfc.$error,
                  icon: "user",
                  type: "text",
                },
                model: {
                  value: _vm.expenseForm.rfc,
                  callback: function ($$v) {
                    _vm.$set(_vm.expenseForm, "rfc", $$v)
                  },
                  expression: "expenseForm.rfc",
                },
              }),
              _vm._v(" "),
              _vm.$v.expenseForm.rfc.$error
                ? _c("div", [
                    !_vm.$v.expenseForm.rfc.maxLength
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.$t("validation.required"))),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col invoice-input form-group" },
            [
              _c("label", { staticClass: "control-label" }, [
                _vm._v(_vm._s(_vm.$t("expenses.category"))),
              ]),
              _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
              _vm._v(" "),
              _c(
                "base-select",
                {
                  ref: "baseSelect",
                  attrs: {
                    options: _vm.categories,
                    invalid: _vm.$v.category.$error,
                    searchable: true,
                    "show-labels": false,
                    placeholder: _vm.$t(
                      "expenses.categories.select_a_category"
                    ),
                    label: "name",
                    "track-by": "id",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$v.category.$touch()
                    },
                  },
                  model: {
                    value: _vm.category,
                    callback: function ($$v) {
                      _vm.category = $$v
                    },
                    expression: "category",
                  },
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "afterList" }, slot: "afterList" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "list-add-button",
                          attrs: { type: "button" },
                          on: { click: _vm.openCategoryModal },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "icon",
                            attrs: { icon: "cart-plus" },
                          }),
                          _vm._v(" "),
                          _c("label", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "settings.expense_category.add_new_category"
                                )
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.$v.category.$error
                ? _c("div", [
                    !_vm.$v.category.required
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.$t("validation.required"))),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col invoice-input" }),
        ]),
        _vm._v(" "),
        _c("table", { staticClass: "item-table mt-5" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("thead", { staticClass: "item-table-header" }, [
            _c("tr", [
              _c("th", { staticClass: "text-left" }, [
                _c("span", { staticClass: "column-heading item-heading" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.$tc("items.item", 2)) +
                      "\n            "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("th", { staticClass: "text-center" }, [
                _c("span", { staticClass: "column-heading" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.$t("invoices.item.quantity")) +
                      "\n            "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("th", { staticClass: "text-left" }, [
                _c("span", { staticClass: "column-heading" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.$t("invoices.item.price")) +
                      "\n            "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("th", { staticClass: "text-right" }, [
                _c("span", { staticClass: "column-heading amount-heading" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.$t("invoices.item.total")) +
                      "\n            "
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            { staticClass: "item-body" },
            _vm._l(_vm.expenseForm.items, function (item, index) {
              return _c("create-expense-item", {
                key: item.id,
                attrs: {
                  index: index,
                  "item-data": item,
                  items: _vm.expenseForm.items,
                },
                on: {
                  remove: _vm.removeItem,
                  update: _vm.updateItem,
                  itemValidate: _vm.checkItemsData,
                },
              })
            }),
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "add-item-action", on: { click: _vm.addItem } },
          [
            _c("font-awesome-icon", {
              staticClass: "mr-2",
              attrs: { icon: "shopping-basket" },
            }),
            _vm._v("\n      " + _vm._s(_vm.$t("invoices.add_item")) + "\n    "),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "row mt-4 invoice-foot" }, [
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("label", [_vm._v(_vm._s(_vm.$t("invoices.notes")))]),
              _vm._v(" "),
              _c("base-text-area", {
                attrs: { rows: "3", cols: "50" },
                on: {
                  input: function ($event) {
                    return _vm.$v.expenseForm.notes.$touch()
                  },
                },
                model: {
                  value: _vm.expenseForm.notes,
                  callback: function ($$v) {
                    _vm.$set(_vm.expenseForm, "notes", $$v)
                  },
                  expression: "expenseForm.notes",
                },
              }),
              _vm._v(" "),
              _vm.$v.expenseForm.notes.$error
                ? _c("div", [
                    !_vm.$v.expenseForm.notes.maxLength
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.$t("validation.notes_maxlength"))),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col" }),
          _vm._v(" "),
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "invoice-total" }, [
              _c("div", { staticClass: "section" }, [
                _c("label", { staticClass: "invoice-label" }, [
                  _vm._v(_vm._s(_vm.$t("expenses.sub_total"))),
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "invoice-amount" }, [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$utils.formatMoney(
                          _vm.subtotal,
                          _vm.defaultCurrency
                        )
                      ),
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "section" }, [
                _c("label", { staticClass: "invoice-label" }, [
                  _vm._v(_vm._s(_vm.$t("invoices.discount"))),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "invoice-amount" }, [
                  _c(
                    "div",
                    {
                      staticClass: "btn-group discount-drop-down",
                      attrs: { role: "group" },
                    },
                    [
                      _c("base-input", {
                        attrs: {
                          invalid: _vm.$v.expenseForm.discount_val.$error,
                          "input-class": "item-discount",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.expenseForm.discount_val.$touch()
                          },
                        },
                        model: {
                          value: _vm.expenseForm.discount,
                          callback: function ($$v) {
                            _vm.$set(_vm.expenseForm, "discount", $$v)
                          },
                          expression: "expenseForm.discount",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "v-dropdown",
                        { attrs: { "show-arrow": false } },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn item-dropdown dropdown-toggle",
                              attrs: {
                                slot: "activator",
                                type: "button",
                                "data-toggle": "dropdown",
                                "aria-haspopup": "true",
                                "aria-expanded": "false",
                              },
                              slot: "activator",
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.expenseForm.discount_type == "fixed"
                                      ? _vm.defaultCurrency.symbol
                                      : "%"
                                  ) +
                                  "\n                  "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-dropdown-item", [
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.selectPercentage.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(_vm.$t("general.percentage")) +
                                    "\n                    "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("v-dropdown-item", [
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.selectFixed.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                      " +
                                    _vm._s(_vm.$t("general.fixed")) +
                                    "\n                    "
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "section" }, [
                _c("label", { staticClass: "invoice-label" }, [
                  _vm._v(_vm._s(_vm.$t("expenses.base"))),
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "invoice-amount" }, [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$utils.formatMoney(_vm.base, _vm.defaultCurrency)
                      ),
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                _vm._l(_vm.expenseForm.taxes, function (tax, index) {
                  return _c("tax", {
                    key: tax.id,
                    attrs: {
                      index: index,
                      total: _vm.base,
                      tax: tax,
                      taxes: _vm.expenseForm.taxes,
                      currency: _vm.defaultCurrency,
                      "total-tax": _vm.totalTax,
                    },
                    on: { remove: _vm.removeExpenseTax, update: _vm.updateTax },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "base-popup",
                    { ref: "taxModal", staticClass: "tax-selector" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "float-right",
                          attrs: { slot: "activator" },
                          slot: "activator",
                        },
                        [
                          _vm._v(
                            "\n                + " +
                              _vm._s(_vm.$t("invoices.add_tax")) +
                              "\n              "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("tax-select-popup", {
                        attrs: {
                          taxes: [],
                          "show-search-bar": false,
                          "show-add-new-tax": false,
                        },
                        on: { select: _vm.onSelectTax },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "section border-top mt-3" }, [
                _c("label", { staticClass: "invoice-label" }, [
                  _vm._v(_vm._s(_vm.$t("invoices.total"))),
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "invoice-amount" }, [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$utils.formatMoney(
                          _vm.totalAmount,
                          _vm.defaultCurrency
                        )
                      ),
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mt-2" }, [
          _c(
            "div",
            { staticClass: "col-4 offset-8 center-content" },
            [
              _c(
                "base-button",
                {
                  staticClass: "invoice-action-btn",
                  attrs: {
                    loading: _vm.isLoading,
                    disabled: _vm.isLoading,
                    icon: "save",
                    color: "theme",
                    type: "submit",
                  },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("expenses.save_expense")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "40%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "10%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "15%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "15%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }