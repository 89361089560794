var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "customer-create main-content mt-5" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitCustomerData.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "page-header" }, [
          _c("h3", { staticClass: "page-title" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.isEdit
                    ? _vm.$t("customers.edit_customer")
                    : _vm.$t("customers.new_customer")
                ) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page-actions header-button-container" },
            [
              _c(
                "base-button",
                {
                  attrs: {
                    loading: _vm.isLoading,
                    disabled: _vm.isLoading,
                    tabindex: 23,
                    icon: "save",
                    color: "theme",
                    type: "submit",
                  },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.isEdit
                          ? _vm.$t("customers.update_customer")
                          : _vm.$t("customers.save_customer")
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "customer-card card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "section-title col-sm-2" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("customers.basic_info")) +
                    "\n          "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-5" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.rfc"))),
                    ]),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        invalid: _vm.$v.formData.rfc.$error,
                        focus: "",
                        type: "text",
                        name: "rfc",
                        "tab-index": "1",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.rfc.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.rfc,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "rfc", $$v)
                        },
                        expression: "formData.rfc",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.rfc.$error
                      ? _c("div", [
                          !_vm.$v.formData.rfc.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$tc("validation.required")) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.formData.rfc.minLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$tc(
                                        "validation.name_min_length",
                                        _vm.$v.formData.rfc.$params.minLength
                                          .min,
                                        {
                                          count:
                                            _vm.$v.formData.rfc.$params
                                              .minLength.min,
                                        }
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.display_name"))),
                    ]),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        invalid: _vm.$v.formData.name.$error,
                        focus: "",
                        type: "text",
                        name: "name",
                        "tab-index": "1",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.name.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "name", $$v)
                        },
                        expression: "formData.name",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.name.$error
                      ? _c("div", [
                          !_vm.$v.formData.name.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$tc("validation.required")) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.formData.name.minLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$tc(
                                        "validation.name_min_length",
                                        _vm.$v.formData.name.$params.minLength
                                          .min,
                                        {
                                          count:
                                            _vm.$v.formData.name.$params
                                              .minLength.min,
                                        }
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.email"))),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _c("base-input", {
                      attrs: {
                        invalid: _vm.$v.formData.email.$error,
                        type: "text",
                        name: "email",
                        "tab-index": "3",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.email.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.email,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "email",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.email",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.email.$error
                      ? _c("div", [
                          !_vm.$v.formData.email.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$tc("validation.required")) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.formData.email.email
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$tc("validation.email_incorrect")
                                    ) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.primary_currency"))),
                    ]),
                    _vm._v(" "),
                    _c("base-select", {
                      attrs: {
                        options: _vm.currencies,
                        "custom-label": _vm.currencyNameWithCode,
                        "allow-empty": false,
                        searchable: true,
                        "show-labels": false,
                        tabindex: 5,
                        placeholder: _vm.$t("customers.select_currency"),
                        label: "name",
                        "track-by": "id",
                        disabled: true,
                      },
                      model: {
                        value: _vm.currency,
                        callback: function ($$v) {
                          _vm.currency = $$v
                        },
                        expression: "currency",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-5" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.tax_regime"))),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c("base-select", {
                      attrs: {
                        invalid: _vm.$v.tax_regimes.$error,
                        options: _vm.taxRegimes,
                        searchable: true,
                        "show-labels": false,
                        "allow-empty": true,
                        tabindex: 4,
                        placeholder: _vm.$t("customers.select_tax_regime"),
                        label: "description",
                        "track-by": "id",
                      },
                      model: {
                        value: _vm.tax_regimes,
                        callback: function ($$v) {
                          _vm.tax_regimes = $$v
                        },
                        expression: "tax_regimes",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.tax_regimes.$error
                      ? _c("div", [
                          !_vm.$v.tax_regimes.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$tc("validation.required")) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(
                        "Segundo " + _vm._s(_vm.$t("customers.tax_regime"))
                      ),
                    ]),
                    _vm._v(" "),
                    _c("base-select", {
                      attrs: {
                        options: _vm.taxRegimes,
                        searchable: true,
                        "show-labels": false,
                        "allow-empty": true,
                        tabindex: 4,
                        placeholder: _vm.$t("customers.select_tax_regime"),
                        label: "description",
                        "track-by": "id",
                      },
                      model: {
                        value: _vm.second_tax_regimes,
                        callback: function ($$v) {
                          _vm.second_tax_regimes = $$v
                        },
                        expression: "second_tax_regimes",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.primary_contact_name"))),
                    ]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        label: _vm.$t("customers.contact_name"),
                        type: "text",
                        "tab-index": "2",
                      },
                      model: {
                        value: _vm.formData.contact_name,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "contact_name",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.contact_name",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.phone"))),
                    ]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: { type: "text", name: "phone", "tab-index": "4" },
                      model: {
                        value: _vm.formData.phone,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "phone",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.phone",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.website"))),
                    ]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        invalid: _vm.$v.formData.website.$error,
                        type: "url",
                        "tab-index": "6",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.website.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.website,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "website", $$v)
                        },
                        expression: "formData.website",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.website.$error
                      ? _c("div", [
                          !_vm.$v.formData.website.url
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$tc("validation.invalid_url")) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-2 form-group" }, [
                _c("label", { staticClass: "form-label" }, [
                  _vm._v(_vm._s(_vm.$t("customers.previou_tax_regime"))),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-5 form-group" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v(_vm._s(_vm.label_one_previous_tax_regime)),
                  ]),
                  _vm._v(" "),
                  _c("base-select", {
                    attrs: {
                      options: _vm.taxRegimes,
                      searchable: true,
                      "show-labels": false,
                      "allow-empty": true,
                      tabindex: 4,
                      placeholder: _vm.$t("customers.select_tax_regime"),
                      label: "description",
                      "track-by": "id",
                    },
                    model: {
                      value: _vm.one_previous_tax_regime,
                      callback: function ($$v) {
                        _vm.one_previous_tax_regime = $$v
                      },
                      expression: "one_previous_tax_regime",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-5 form-group" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v(_vm._s(_vm.label_two_previous_tax_regime)),
                  ]),
                  _vm._v(" "),
                  _c("base-select", {
                    attrs: {
                      options: _vm.taxRegimes,
                      searchable: true,
                      "show-labels": false,
                      "allow-empty": true,
                      tabindex: 4,
                      placeholder: _vm.$t("customers.select_tax_regime"),
                      label: "description",
                      "track-by": "id",
                    },
                    model: {
                      value: _vm.two_previous_tax_regime,
                      callback: function ($$v) {
                        _vm.two_previous_tax_regime = $$v
                      },
                      expression: "two_previous_tax_regime",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row" },
              [
                _vm._l(_vm.formData.previous_tax_regime, function (item) {
                  return [
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "button",
                          {
                            staticClass: "form-control btn btn-outline-primary",
                            attrs: { type: "button" },
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(item.anio) +
                                "-" +
                                _vm._s(item.tax_regime) +
                                "\n                "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "section-title col-sm-2" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("customers.billing_address")) +
                    "\n          "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-5" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.name"))),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        invalid: _vm.$v.billing.name.$error,
                        type: "text",
                        name: "address_name",
                        "tab-index": "7",
                      },
                      model: {
                        value: _vm.billing.name,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.billing,
                            "name",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "billing.name",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.billing.name.$error
                      ? _c("div", [
                          !_vm.$v.billing.name.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$tc("validation.required")) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.state"))),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c("base-select", {
                      attrs: {
                        invalid: _vm.$v.billing.state.$error,
                        options: _vm.billingStates,
                        searchable: true,
                        "show-labels": false,
                        "allow-empty": true,
                        tabindex: 8,
                        placeholder: "Selecciona el estado",
                      },
                      model: {
                        value: _vm.billing.state,
                        callback: function ($$v) {
                          _vm.$set(_vm.billing, "state", $$v)
                        },
                        expression: "billing.state",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.billing.state.$error
                      ? _c("div", [
                          !_vm.$v.billing.state.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$tc("validation.required")) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.address"))),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c("base-text-area", {
                      attrs: {
                        invalid: _vm.$v.billing.address_street_1.$error,
                        tabindex: 11,
                        placeholder: _vm.$t("general.street_1"),
                        type: "text",
                        name: "billing_street1",
                        rows: "2",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.billing.address_street_1.$touch()
                        },
                      },
                      model: {
                        value: _vm.billing.address_street_1,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.billing,
                            "address_street_1",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "billing.address_street_1",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.billing.address_street_1.$error
                      ? _c("div", [
                          !_vm.$v.billing.address_street_1.maxLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t("validation.address_maxlength")
                                    ) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.billing.address_street_1.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$tc("validation.required")) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("base-text-area", {
                      attrs: {
                        tabindex: 12,
                        invalid: _vm.$v.billing.address_street_2.$error,
                        placeholder: _vm.$t("general.street_2"),
                        type: "text",
                        name: "billing_street2",
                        rows: "2",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.billing.address_street_2.$touch()
                        },
                      },
                      model: {
                        value: _vm.billing.address_street_2,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.billing,
                            "address_street_2",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "billing.address_street_2",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.billing.address_street_2.$error
                      ? _c("div", [
                          !_vm.$v.billing.address_street_2.maxLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t("validation.address_maxlength")
                                    ) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.billing.address_street_2.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$tc("validation.required")) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("base-text-area", {
                      attrs: {
                        tabindex: 12,
                        invalid: _vm.$v.billing.colony.$error,
                        placeholder: _vm.$t("general.colony"),
                        type: "text",
                        name: "billing_colony",
                        rows: "2",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.billing.colony.$touch()
                        },
                      },
                      model: {
                        value: _vm.billing.colony,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.billing,
                            "colony",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "billing.colony",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.billing.colony.$error
                      ? _c("div", [
                          !_vm.$v.billing.colony.maxLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t("validation.address_maxlength")
                                    ) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.billing.colony.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$tc("validation.required")) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-5" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.country"))),
                    ]),
                    _vm._v(" "),
                    _c("base-select", {
                      attrs: {
                        disabled: true,
                        options: _vm.billingCountries,
                        searchable: true,
                        "show-labels": false,
                        "allow-empty": true,
                        tabindex: 8,
                        placeholder: _vm.$t("general.select_country"),
                        label: "name",
                        "track-by": "id",
                      },
                      model: {
                        value: _vm.billing_country,
                        callback: function ($$v) {
                          _vm.billing_country = $$v
                        },
                        expression: "billing_country",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.city"))),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        invalid: _vm.$v.billing.city.$error,
                        name: "billing.city",
                        type: "text",
                        "tab-index": "10",
                      },
                      model: {
                        value: _vm.billing.city,
                        callback: function ($$v) {
                          _vm.$set(_vm.billing, "city", $$v)
                        },
                        expression: "billing.city",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.billing.city.$error
                      ? _c("div", [
                          !_vm.$v.billing.city.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$tc("validation.required")) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.phone"))),
                    ]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: { type: "text", name: "phone", "tab-index": "13" },
                      model: {
                        value: _vm.billing.phone,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.billing,
                            "phone",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "billing.phone",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.zip_code"))),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        invalid: _vm.$v.billing.zip.$error,
                        type: "text",
                        name: "zip",
                        "tab-index": "14",
                      },
                      model: {
                        value: _vm.billing.zip,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.billing,
                            "zip",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "billing.zip",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.billing.zip.$error
                      ? _c("div", [
                          !_vm.$v.billing.zip.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$tc("validation.required")) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "row mb-5" }, [
              _c(
                "div",
                {
                  staticClass: "section-title text-center",
                  staticStyle: { width: "100%" },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("customers.sat_files")) +
                      "\n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "form-group col-md-4" }, [
                _c("label", { attrs: { for: "description" } }, [
                  _vm._v(
                    _vm._s(_vm.$t("customers.access_file_cer")) +
                      " :\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "image-upload-box" }, [
                  _c("input", {
                    ref: "e_sign_cer",
                    attrs: { type: "file", accept: ".cer" },
                    on: { change: _vm.onESignCerChange },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group col-md-4" }, [
                _c("label", { attrs: { for: "description" } }, [
                  _vm._v(
                    _vm._s(_vm.$t("customers.access_file_key")) +
                      " :\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "image-upload-box" }, [
                  _c("input", {
                    ref: "e_sign_key",
                    attrs: { type: "file", accept: ".key" },
                    on: { change: _vm.onESignKeyChange },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group col-md-4" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v(_vm._s(_vm.$t("customers.fiel_password"))),
                  ]),
                  _vm._v(" "),
                  _c("base-input", {
                    attrs: {
                      invalid: _vm.$v.formData.fiel_password.$error,
                      focus: "",
                      type: "text",
                      name: "fiel_password",
                      "tab-index": "1",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.fiel_password.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.fiel_password,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "fiel_password", $$v)
                      },
                      expression: "formData.fiel_password",
                    },
                  }),
                  _vm._v(" "),
                  _vm.$v.formData.fiel_password.$error
                    ? _c("div", [
                        !_vm.$v.formData.fiel_password.required
                          ? _c("span", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$tc("validation.required")) +
                                  "\n              "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.$v.formData.fiel_password.minLength
                          ? _c("span", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$tc(
                                      "validation.name_min_length",
                                      _vm.$v.formData.fiel_password.$params
                                        .minLength.min,
                                      {
                                        count:
                                          _vm.$v.formData.fiel_password.$params
                                            .minLength.min,
                                      }
                                    )
                                  ) +
                                  "\n              "
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "form-group col-md-4" }, [
                _c("label", { attrs: { for: "description" } }, [
                  _vm._v(
                    _vm._s(_vm.$t("customers.digital_stamp_cer")) +
                      " :\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "image-upload-box" }, [
                  _c("input", {
                    ref: "digital_stamp_cer",
                    attrs: { type: "file", accept: ".cer" },
                    on: { change: _vm.onDigitalCerChange },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group col-md-4" }, [
                _c("label", { attrs: { for: "description" } }, [
                  _vm._v(
                    _vm._s(_vm.$t("customers.digital_stamp_key")) +
                      " :\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "image-upload-box" }, [
                  _c("input", {
                    ref: "digital_stamp_key",
                    attrs: { type: "file", accept: ".key" },
                    on: { change: _vm.onDigitalKeyChange },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group col-md-4" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v(_vm._s(_vm.$t("customers.csd_password"))),
                  ]),
                  _vm._v(" "),
                  _c("base-input", {
                    attrs: {
                      invalid: _vm.$v.formData.csd_password.$error,
                      focus: "",
                      type: "text",
                      name: "csd_password",
                      "tab-index": "1",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.csd_password.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.csd_password,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "csd_password", $$v)
                      },
                      expression: "formData.csd_password",
                    },
                  }),
                  _vm._v(" "),
                  _vm.$v.formData.csd_password.$error
                    ? _c("div", [
                        !_vm.$v.formData.csd_password.required
                          ? _c("span", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$tc("validation.required")) +
                                  "\n              "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.$v.formData.csd_password.minLength
                          ? _c("span", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$tc(
                                      "validation.name_min_length",
                                      _vm.$v.formData.csd_password.$params
                                        .minLength.min,
                                      {
                                        count:
                                          _vm.$v.formData.csd_password.$params
                                            .minLength.min,
                                      }
                                    )
                                  ) +
                                  "\n              "
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }