<template>
  <footer class="site-footer">
    <div class="text-right">
      <a
        href="http://strappberry.com/"
        target="_blank">Strappberry | Mobile App Development 
      </a>
    </div>
  </footer>
</template>

<script type="text/babel">
export default {
  data () {
    return {
      footer: 'footer'
    }
  }
}
</script>
