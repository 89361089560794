var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-content invoice-create-page mt-4" }, [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "page-header" }, [
          _c("h3", { staticClass: "page-title" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("payments.general_public_invoices.title")) +
                "\n      "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row invoice-input-group" }, [
          _c(
            "div",
            { staticClass: "col invoice-input" },
            [
              _c("label", [
                _vm._v("\n          " + _vm._s(_vm.$t("payments.name")) + " "),
                _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
              ]),
              _vm._v(" "),
              _c("base-input", {
                attrs: {
                  invalid: _vm.$v.invoiceForm.receiverName.$error,
                  icon: "user",
                  type: "text",
                },
                model: {
                  value: _vm.invoiceForm.receiverName,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoiceForm, "receiverName", $$v)
                  },
                  expression: "invoiceForm.receiverName",
                },
              }),
              _vm._v(" "),
              _vm.$v.invoiceForm.receiverName.$error
                ? _c("div", [
                    !_vm.$v.invoiceForm.receiverName.required
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("validation.required")) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col invoice-input" },
            [
              _c("label", [
                _vm._v(
                  _vm._s(_vm.$t("customers.sat_settings.expedition_place"))
                ),
              ]),
              _vm._v(" "),
              _c("base-input", {
                attrs: {
                  invalid: _vm.$v.invoiceForm.expeditionPlace.$error,
                  icon: "hashtag",
                  type: "text",
                  disabled: "",
                },
                model: {
                  value: _vm.invoiceForm.expeditionPlace,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoiceForm, "expeditionPlace", $$v)
                  },
                  expression: "invoiceForm.expeditionPlace",
                },
              }),
              _vm._v(" "),
              _vm.$v.invoiceForm.expeditionPlace.$error
                ? _c("div", [
                    !_vm.$v.invoiceForm.expeditionPlace.required
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("validation.required")) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col invoice-input" },
            [
              _c("label", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("customers.sat_settings.use_cfdi")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("base-select", {
                attrs: {
                  options: _vm.useCFDIOptions,
                  searchable: true,
                  "allow-empty": false,
                  "show-labels": false,
                  placeholder: _vm.$t("customers.sat_settings.use_cfdi"),
                  label: "description",
                  "track-by": "id",
                  disabled: "",
                },
                model: {
                  value: _vm.invoiceForm.receiverCfdiUse,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoiceForm, "receiverCfdiUse", $$v)
                  },
                  expression: "invoiceForm.receiverCfdiUse",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row invoice-input-group" }, [
          _c(
            "div",
            { staticClass: "col invoice-input" },
            [
              _c("label", [
                _vm._v("\n          " + _vm._s(_vm.$t("payments.rfc")) + " "),
                _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
              ]),
              _vm._v(" "),
              _c("base-input", {
                attrs: {
                  invalid: _vm.$v.invoiceForm.receiverRfc.$error,
                  icon: "user",
                  type: "text",
                  readOnly: "",
                },
                model: {
                  value: _vm.invoiceForm.receiverRfc,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoiceForm, "receiverRfc", $$v)
                  },
                  expression: "invoiceForm.receiverRfc",
                },
              }),
              _vm._v(" "),
              _vm.$v.invoiceForm.receiverRfc.$error
                ? _c("div", [
                    !_vm.$v.invoiceForm.receiverRfc.required
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("validation.required")) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col invoice-input" },
            [
              _c("label", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("invoices.payment_method")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("base-select", {
                attrs: {
                  options: _vm.paymentMethodsOptions,
                  searchable: true,
                  "allow-empty": false,
                  "show-labels": false,
                  placeholder: _vm.$t("invoices.payment_method"),
                  label: "description",
                  "track-by": "id",
                  disabled: "",
                },
                model: {
                  value: _vm.invoiceForm.paymentMethod,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoiceForm, "paymentMethod", $$v)
                  },
                  expression: "invoiceForm.paymentMethod",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col invoice-input" },
            [
              _c("label", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("invoices.way_to_pay")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("base-select", {
                attrs: {
                  options: _vm.wayToPayOptions,
                  searchable: true,
                  "allow-empty": false,
                  "show-labels": false,
                  placeholder: _vm.$t("invoices.way_to_pay"),
                  invalid: _vm.$v.invoiceForm.paymentForm.$error,
                  label: "description",
                  "track-by": "id",
                },
                model: {
                  value: _vm.invoiceForm.paymentForm,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoiceForm, "paymentForm", $$v)
                  },
                  expression: "invoiceForm.paymentForm",
                },
              }),
              _vm._v(" "),
              _vm.$v.invoiceForm.paymentForm.$error
                ? _c("div", [
                    !_vm.$v.invoiceForm.paymentForm.required
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("validation.required")) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col invoice-input" }),
          _vm._v(" "),
          _c("div", { staticClass: "col invoice-input" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col invoice-input" },
            [
              _c("label", [_vm._v("Fecha emisión")]),
              _vm._v(" "),
              _c("base-select", {
                attrs: {
                  options: _vm.additionalOptions,
                  searchable: true,
                  "allow-empty": false,
                  "show-labels": false,
                  label: "description",
                  "track-by": "id",
                },
                model: {
                  value: _vm.invoiceForm.broadcastDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoiceForm, "broadcastDate", $$v)
                  },
                  expression: "invoiceForm.broadcastDate",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("table", { staticClass: "item-table mt-5" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("thead", { staticClass: "item-table-header" }, [
            _c("tr", [
              _c("th", { staticClass: "text-left" }, [
                _c("span", { staticClass: "column-heading item-heading" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.$tc("items.item", 2)) +
                      "\n            "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("th", { staticClass: "text-center" }, [
                _c("span", { staticClass: "column-heading" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.$t("invoices.item.clave_prod_serv")) +
                      "\n            "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("th", { staticClass: "text-center" }, [
                _c("span", { staticClass: "column-heading" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.$t("invoices.item.quantity")) +
                      "\n            "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("th", { staticClass: "text-center" }, [
                _c("span", { staticClass: "column-heading" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.$t("invoices.item.unit_code")) +
                      "\n            "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("th", { staticClass: "text-left" }, [
                _c("span", { staticClass: "column-heading" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.$t("invoices.item.price")) +
                      "\n            "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("th", { staticClass: "text-right" }, [
                _c("span", { staticClass: "column-heading" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.$t("invoices.iva")) +
                      "\n            "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("th", { staticClass: "text-right" }, [
                _c("span", { staticClass: "column-heading amount-heading" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.$t("invoices.item.total")) +
                      "\n            "
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            { staticClass: "item-body" },
            _vm._l(_vm.invoiceForm.items, function (item, index) {
              return _c("general-public-invoice-item", {
                key: item.id,
                attrs: {
                  index: index,
                  "item-data": item,
                  items: _vm.invoiceForm.items,
                },
                on: {
                  remove: _vm.removeItem,
                  update: _vm.updateItem,
                  itemValidate: _vm.checkItemsData,
                },
              })
            }),
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "add-item-action", on: { click: _vm.addEmptyRow } },
          [
            _c("font-awesome-icon", {
              staticClass: "mr-2",
              attrs: { icon: "shopping-basket" },
            }),
            _vm._v("\n      " + _vm._s(_vm.$t("invoices.add_item")) + "\n    "),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "row mt-3 invoice-foot" }, [
          _c("div", { staticClass: "col" }),
          _vm._v(" "),
          _c("div", { staticClass: "col" }),
          _vm._v(" "),
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "invoice-total" }, [
              _c("div", { staticClass: "section mt-3" }, [
                _c("label", { staticClass: "invoice-label" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.$t("invoices.sub_total")) +
                      "\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "invoice-amount" }, [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$utils.formatMoney(
                          _vm.subtotal,
                          _vm.defaultCurrency
                        )
                      ),
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "section border-top mt-3" }, [
                _c("label", { staticClass: "invoice-label" }, [
                  _vm._v(_vm._s(_vm.$t("invoices.iva"))),
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "invoice-amount" }, [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$utils.formatMoney(_vm.iva, _vm.defaultCurrency)
                      ),
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "section border-top mt-3" }, [
                _c("label", { staticClass: "invoice-label" }, [
                  _vm._v(_vm._s(_vm.$t("invoices.total"))),
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "invoice-amount" }, [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$utils.formatMoney(_vm.total, _vm.defaultCurrency)
                      ),
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mt-2" }, [
          _c(
            "div",
            { staticClass: "col-6 col-md-4 offset-md-8 flex-content-right" },
            [
              _c(
                "base-button",
                {
                  staticClass: "invoice-action-btn",
                  attrs: {
                    loading: _vm.isLoading,
                    disabled: _vm.isLoading,
                    icon: "save",
                    color: "theme",
                    type: "submit",
                  },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("payments.general_public_invoices.make_invoice")
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "20%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "10%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "10%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "20%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "10%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "10%" } }),
      _vm._v(" "),
      _c("col", { staticStyle: { width: "10%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }