import * as types from './mutation-types'

export const fetchCustomers = ({ commit, dispatch, state }, params) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/api/customers`, { params }).then((response) => {
            commit(types.BOOTSTRAP_CUSTOMERS, response.data.customers.data)
            commit(types.SET_TOTAL_CUSTOMERS, response.data.customers.total)

            const fecha = new Date()
            let oneTexRegime = fecha.getFullYear() - 1
            let twoTexRegime = fecha.getFullYear() - 2

            for (let j = 0; j < response.data.customers.data.length; j++) {
                if ( response.data.customers.data[j].previous_tax_regime != null && response.data.customers.data[j].previous_tax_regime != '') {
                    let prevTaxRegime = JSON.parse(response.data.customers.data[j].previous_tax_regime)
                    prevTaxRegime.forEach((item) => {
                        switch (item.anio) {
                        case oneTexRegime:
                            response.data.customers.data[j]['oneTaxRegime'] = item.tax_regime
                            break
                        case twoTexRegime:
                            response.data.customers.data[j]['twoTaxRegime'] = item.tax_regime
                            break
                        } 
                    })
                }
            } 
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const fetchCustomer = ({ commit, dispatch }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/api/customers/${id}/edit`).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const addCustomer = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post('/api/customers', data).then((response) => {
            commit(types.ADD_CUSTOMER, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateCustomer = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`/api/customers/${data.id}`, data.editData).then((response) => {
            if (response.data.success) {
                commit(types.UPDATE_CUSTOMER, response.data)
            }
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteCustomer = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete(`/api/customers/${id}`).then((response) => {
            commit(types.DELETE_CUSTOMER, id)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const deleteMultipleCustomers = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`/api/customers/delete`, { 'id': state.selectedCustomers }).then((response) => {
            commit(types.DELETE_MULTIPLE_CUSTOMERS, state.selectedCustomers)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const setSelectAllState = ({ commit, dispatch, state }, data) => {
    commit(types.SET_SELECT_ALL_STATE, data)
}

export const selectAllCustomers = ({ commit, dispatch, state }) => {
    if (state.selectedCustomers.length === state.customers.length) {
        commit(types.SET_SELECTED_CUSTOMERS, [])
        commit(types.SET_SELECT_ALL_STATE, false)
    } else {
        let allCustomerIds = state.customers.map(cust => cust.id)
        commit(types.SET_SELECTED_CUSTOMERS, allCustomerIds)
        commit(types.SET_SELECT_ALL_STATE, true)
    }
}

export const selectCustomer = ({ commit, dispatch, state }, data) => {
    commit(types.SET_SELECTED_CUSTOMERS, data)
    if (state.selectedCustomers.length === state.customers.length) {
        commit(types.SET_SELECT_ALL_STATE, true)
    } else {
        commit(types.SET_SELECT_ALL_STATE, false)
    }
}

export const resetSelectedCustomer = ({ commit, dispatch, state }, data) => {
    commit(types.RESET_SELECTED_CUSTOMER)
}

export const giveAccessToCustomersPortal = (state, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post('/api/customers/give-access-to-portal', data)
      .then(resolve)
      .catch(reject)
  })
}

export const sendRequestPreviousInvoices = (store, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post('/api/customers/request-previous-invoices', data)
      .then(resolve)
      .catch(reject)
  })
}

export const fetchCustomerSatSettings = (store, id) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/customers/${id}/sat-settings`)
      .then(resolve)
      .catch(reject)
  })
}

export const saveSatSettings = (store, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/customers/${data.id}/sat-settings`, data.data)
      .then(resolve)
      .catch(reject)
  })
}

export const fetchTaxLosses = (store, params) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`/api/tax-losses`, { params }).then(resolve).catch(reject)
  })
}

export const saveSatUnitFavorite = (store, data) => {
    return new Promise((resolve, reject) => {
      window.axios
        .post(`/api/customers/${data.id}/sat-settings_unit`, data.data)
        .then(resolve)
        .catch(reject)
    })
  }

  
export const removeSatUnitFavorite = (store, data) => {
    return new Promise((resolve, reject) => {
      window.axios
        .post(`/api/customers/${data.id}/del-sat-settings_unit`, data.data)
        .then(resolve)
        .catch(reject)
    })
  }

export const fetchCatalogoServProd = (store, clavePalabra) => {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/api/catalogo-productos-servicios/${clavePalabra}`)
        .then(resolve)
        .catch(reject)
    })
  }
  
export const saveSatProdServFavorite = (store, data) => {
    return new Promise((resolve, reject) => {
      window.axios
        .post(`/api/customers/${data.id}/sat-settings_prod_serv`, data.data)
        .then(resolve)
        .catch(reject)
    })
  }

    export const removeSatProdServFavorite = (store, data) => {
    return new Promise((resolve, reject) => {
      window.axios
        .post(`/api/customers/${data.id}/del-sat-settings_prod_serv`, data.data)
        .then(resolve)
        .catch(reject)
    })
  }

  export const fetchPagos = ( store, data ) => {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/api/pagos/list/${data}`)
        .then(resolve)
        .catch(reject)
    })
  }

  export const fetchCreatePago = (store, data) => {
    return new Promise((resolve, reject) => {
      window.axios
        .post(`/api/pagos/create`, data)
        .then(resolve)
        .catch(reject)
    })
  }
  
  export const fetchMensajes = ( store, data ) => {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/api/chat/list/${data}`)
        .then(resolve)
        .catch(reject)
    })
  }
  
  export const fetchDeclaracionMensajes = ( store, data ) => {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/api/chat/getMensajes/${data}`)
        .then(resolve)
        .catch(reject)
    })
  }
  
  export const createSendMensaje = (store, data) => {
    console.log(data);

    return new Promise((resolve, reject) => {
      window.axios
        .post(`/api/chat/create`, data)
        .then(resolve)
        .catch(reject)
    })
  }