<template>
  <div v-if="taxReturn">
    <!-- Table  ISR -->
    <div class="card p-4">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr class="table-primary">
              <th colspan="2" class="text-center">
                {{ $t('tax_returns.isr') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <!-- Ingresos por plataformas digitales -->
            <tr class="clickable" @click="toggleDisplayTable('retentions')">
              <td><font-awesome-icon class="icon" icon="arrow-down"/> {{ $t('tax_returns.revenue_digital_platforms') }}</td>
              <td class="text-right">
                <money-text
                  :amount="taxReturn.amounts.revenue_digital_platforms"
                />
              </td>
            </tr>
            <!-- Table retentions -->
            <tr>
              <td v-if="displayTables.retentions" colspan="2">
                <tax-return-retentions-table
                  v-if="taxReturn.retentions"
                  :retentions="taxReturn.retentions.invoices"
                  :totals="taxReturn.retentions.totals"
                  title="tax_returns.retentions"
                />
              </td>
            </tr>
            <!-- Ingresos directos del usuario -->
            <tr>
              <td>{{ $t('tax_returns.revenue_direct_users') }}</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.revenue_direct_users" />
              </td>
            </tr>
            <!-- Ingresos del periodo -->
            <tr>
              <td>{{ $t('tax_returns.revenue_period') }}</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.revenue_period" />
              </td>
            </tr>
            <!-- Porcentaje de ISR -->
            <tr>
              <td>{{ $t('tax_returns.isr_percentage') }}</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.isr_percentage" />
              </td>
            </tr>
            <!-- ISR Causado -->
            <tr>
              <td>{{ $t('tax_returns.isr_caused') }}</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.isr_caused" />
              </td>
            </tr>
            <!-- ISR Retenido -->
            <tr>
              <td>{{ $t('tax_returns.isr_detained') }}</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.isr_detained" />
              </td>
            </tr>
            <!-- ISR a cargo -->
            <tr>
              <td>{{ $t('tax_returns.isr_in_charge') }}</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.isr_in_charge" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Table IVA -->
    <div class="card p-4">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr class="table-primary">
              <th colspan="2" class="text-center">
                {{ $t('tax_returns.iva') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <!-- Ingresos por plataformas digitales -->
            <tr>
              <td>{{ $t('tax_returns.revenue_intermediaries') }}</td>
              <td class="text-right">
                <money-text
                  :amount="taxReturn.amounts.revenue_digital_platforms"
                />
              </td>
            </tr>
            <!-- Ingresos directos del usuario -->
            <tr>
              <td>{{ $t('tax_returns.revenue_direct_users') }}</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.revenue_direct_users" />
              </td>
            </tr>
            <!-- Porcentaje IVA-->
            <tr>
              <td>{{ $t('tax_returns.iva_percentage') }}</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.iva_percentage" />
              </td>
            </tr>
            <!-- IVA Causado -->
            <tr>
              <td>{{ $t('tax_returns.iva_caused') }}</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.iva_caused" />
              </td>
            </tr>
            <!-- IVA Acreditable -->
            <tr
              class="clickable"
              @click="toggleDisplayTable('purchasesAndDeductions')"
            >
              <td><font-awesome-icon class="icon" icon="arrow-down"/> {{ $t('tax_returns.iva_creditable') }}</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.iva_creditable" />
              </td>
            </tr>
            <!-- Tablas de compras y deduciones -->
            <tr v-if="displayTables.purchasesAndDeductions">
              <td colspan="2">
                <tax-return-invoices-table
                  v-if="taxReturn.tables.deductions"
                  :invoices="taxReturn.tables.deductions.invoices"
                  :totals="taxReturn.tables.deductions.totals"
                  title="tax_returns.deductions"
                />
                <tax-return-invoices-table
                  v-if="taxReturn.tables.purchases"
                  :invoices="taxReturn.tables.purchases.invoices"
                  :totals="taxReturn.tables.purchases.totals"
                  title="tax_returns.purchases"
                />
              </td>
            </tr>
            <!-- IVA retenido -->
            <tr>
              <td>{{ $t('tax_returns.iva_detained') }}</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.iva_detained" />
              </td>
            </tr>
            <!-- IVA del periodo -->
            <tr>
              <td>{{ $t('tax_returns.iva_period') }}</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.iva_period" />
              </td>
            </tr>
            <!-- IVA Periodo previo -->
            <tr>
              <td>{{ $t('tax_returns.iva_previous_period') }}</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.iva_previous_period" />
              </td>
            </tr>
            <!-- IVA Periodo previo -->
            <tr>
              <td>{{ $t('tax_returns.iva_in_charge') }}</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.iva_in_charge" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import TaxReturnRetentionsTable from './TaxReturnRetentionsTable.vue'
import TaxReturnInvoicesTable from './TaxReturnInvoicesTable'
export default {
  components: {
    TaxReturnRetentionsTable,
    TaxReturnInvoicesTable,
  },
  props: {
    taxReturn: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    displayTables: {
      retentions: false,
      purchasesAndDeductions: false,
    },
  }),
  methods: {
    toggleDisplayTable(table) {
      this.displayTables[table] = !this.displayTables[table]
    },
  },
}
</script>

<style lang="scss" scoped>
.clickable {
  td {
    font-weight: 500;
  }
}
</style>
