import mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'
import catalogs from './catalogs'

const initialState = {
  catalogs: catalogs,

  ingresosCfdi: null,

  ingresoAdicional: {
    adicional: null,
    conceptoAdicional: null,
    totalAdicional: 0,
    nota: '',
  },

  ingresoDisminuir: {
    disminuir: null,
    conceptoDisminuir: null,
    otroDisminuir: 0,
    invoices: [],
    ingresoDisminuircfdi: 0,
    nota: '',
    // aqui termina el primero
    disminuirAdicional: null,
    conceptoDisminuirAdicional: null,
    otroDisminuirAdicional: 0,
    notaAdicional: '',
    invoicesAdicional: [],
    ingresoDisminuircfdiAdicional: 0,
    totalDisminuir: 0,
  },

  isrRetenido: {
    retenido: null,
    ingresoDisminuircfdi: 0,
    invoices: [],
    nota: '',
    monto: 0,
    notaAdicional: '',

    retenidoAdicionar: null,
    montoAdicionar: 0,
    notaAdicionar: '',
  },

  acreditamiento: {
    tipo: null,
    periodo: '',
    ejercicio: null,
    saldoAplicar: 0,
    tipoDeclaracion: null,
    numeroOperacion: 0,
    montoOriginal: 0,
    remanenteHistorico: 0,
    fecha: null,
  },

  compensacion: {
    tipo: null,
    periodicidad: null,
    periodo: '',
    ejercicio: null,
    saldoAplicar: 0,
    tipoDeclaracion: null,
    numeroOperacion: 0,
    montoOriginal: 0,
    remanenteHistorico: 0,
    fecha: null,
    remanenteActualizado: 0,
  },

  rifIsr: {
    tipo: null,
    bimestre: null,
    ejercicio: null,
    numeroOperacion: 0,
    saldoAplicar: 0,
    montoOriginal: 0,
    fecha: null,
    remanente: 0,
  },
  rifIva: {
    bimestre: null,
    ejercicio: null,
    numeroOperacion: 0,
    saldoAplicar: 0,
    montoOriginal: 0,
    fecha: null,
    remanente: 0,
  },
}

export default {
  namespaced: true,

  state: initialState,

  getters: getters,

  actions: actions,

  mutations: mutations,
}
