export const taxRegimesCatalog = [
   {
     id: 601,
     description: 'General de Ley Personas Morales',
     physical: false,
     moral: true,
   },
  // {
  //   id: 603,
  //   description: 'Personas Morales con Fines no Lucrativos',
  //   physical: false,
  //   moral: true,
  // },
  // {
  //   id: 605,
  //   description: 'Sueldos y Salarios e Ingresos Asimilados a Salarios',
  //   physical: true,
  //   moral: false,
  // },
  {
    id: 606,
    description: 'Arrendamiento',
    physical: true,
    moral: false,
    taxReturnMonths: 1,
  },
  // {
  //   id: 608,
  //   description: 'Demás ingresos',
  //   physical: true,
  //   moral: false,
  // },
  // {
  //   id: 609,
  //   description: 'Consolidación',
  //   physical: false,
  //   moral: true,
  // },
  // {
  //   id: 610,
  //   description: 'Residentes en el Extranjero sin Establecimiento Permanente en México',
  //   physical: true,
  //   moral: true,
  // },
  // {
  //   id: 611,
  //   description: 'Ingresos por Dividendos (socios y accionistas)',
  //   physical: true,
  //   moral: false,
  // },
  {
    id: 612,
    description:
      'Personas Físicas con Actividades Empresariales y Profesionales',
    physical: true,
    moral: false,
    taxReturnMonths: 1,
  },
  // {
  //   id: 614,
  //   description: 'Ingresos por intereses',
  //   physical: true,
  //   moral: false,
  // },
  // {
  //   id: 616,
  //   description: 'Sin obligaciones fiscales',
  //   physical: true,
  //   moral: false,
  // },
  // {
  //   id: 620,
  //   description: 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos',
  //   physical: false,
  //   moral: true,
  // },
  {
    id: 621,
    description: 'Régimen Incorporación Fiscal',
    physical: true,
    moral: false,
    taxReturnMonths: 2,
  },
  // {
  //   id: 622,
  //   description: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras',
  //   physical: true,
  //   moral: true,
  // },
  // {
  //   id: 623,
  //   description: 'Opcional para Grupos de Sociedades',
  //   physical: false,
  //   moral: true,
  // },
  // {
  //   id: 624,
  //   description: 'Coordinados',
  //   physical: false,
  //   moral: true,
  // },
  // {
  //   id: 628,
  //   description: 'Hidrocarburos',
  //   physical: false,
  //   moral: true,
  // },
  // {
  //   id: 607,
  //   description: 'Régimen de Enajenación o Adquisición de Bienes',
  //   physical: false,
  //   moral: true,
  // },
  // {
  //   id: 629,
  //   description: 'De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales',
  //   physical: true,
  //   moral: false,
  // },
  // {
  //   id: 630,
  //   description: 'Enajenación de acciones en bolsa de valores',
  //   physical: true,
  //   moral: false,
  // },
  // {
  //   id: 615,
  //   description: 'Régimen de los ingresos por obtención de premios',
  //   physical: true,
  //   moral: false,
  // },
  {
    id: 625,
    description: 'Plataformas Digitales',
    physical: true,
    moral: false,
    taxReturnMonths: 1,
  },
  {
    id: 626,
    description: 'Régimen Simplificado de Confianza',
    physical: true,
    moral: false,
    taxReturnMonths: 1,
  },
]
