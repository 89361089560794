<template>
  <div v-if="taxReturn">
    <!-- Table isr -->
    <div v-if="taxReturn.amounts" class="card p-4">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr class="table-primary">
              <th colspan="2" class="text-center">
                {{ $t('tax_returns.isr') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <!-- Ingresos cobrados -->
            <tr
              class="clickable"
              @click="toggleDisplayTable('revenueCollected')"
            >
              <td>
                <font-awesome-icon class="icon" icon="arrow-down" />
                {{ $t('tax_returns.revenue_collected') }}
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.revenue_collected" />
              </td>
            </tr>
            <!-- Tablas de ingresos -->
            <tr v-if="displayTables.revenueCollected">
              <td colspan="2">
                <tax-return-invoices-table
                  v-if="taxReturn.tables.general_public"
                  :invoices="taxReturn.tables.general_public.invoices"
                  :totals="taxReturn.tables.general_public.totals"
                  title="tax_returns.general_public_sales"
                />
                <tax-return-invoices-table
                  v-if="taxReturn.tables.individuals"
                  :invoices="taxReturn.tables.individuals.invoices"
                  :totals="taxReturn.tables.individuals.totals"
                  title="tax_returns.individual_sales"
                />
              </td>
            </tr>
            <!-- Compras -->
            <tr class="clickable" @click="toggleDisplayTable('purchases')">
              <td>
                <font-awesome-icon class="icon" icon="arrow-down" />
                Compras y Gastos pagados
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.purchases_subtotal" />
              </td>
            </tr>
            <!-- Tablas de compras -->
            <tr v-if="displayTables.purchases">
              <td colspan="2">
                <tax-return-invoices-table
                  v-if="taxReturn.tables.purchases"
                  :invoices="taxReturn.tables.purchases.invoices"
                  :totals="taxReturn.tables.purchases.totals"
                  title="tax_returns.purchases"
                />
              </td>
            </tr>
            <!-- Sueldos pagados -->
            <!-- <tr>
              <td>{{ $t('tax_returns.paid_salaries') }}</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.paid_salaries" />
              </td>
            </tr> -->
            <!-- Deducciones -->
            <!-- <tr class="clickable" @click="toggleDisplayTable('taxDeductions')">
              <td>{{ $t('tax_returns.deductions') }}</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.isr_in_tax_return" />
              </td>
            </tr> -->
            <!-- Tablas de deducciones -->
            <!-- <tr v-if="displayTables.taxDeductions">
              <td colspan="2">
                <tax-return-invoices-table
                  v-if="taxReturn.tables.tax_deductions"
                  :invoices="taxReturn.tables.tax_deductions.invoices"
                  :totals="taxReturn.tables.tax_deductions.totals"
                  :can-dispay-no-object="true"
                  title="tax_returns.deductions"
                />
              </td>
            </tr> -->
            <!-- Exceso de gastos sobre ingresos  -->
            <tr>
              <td>Diferencia por gastos mayores a ingresos</td>
              <td class="text-right">
                <money-text
                  :amount="taxReturn.amounts.excess_expenses_over_revenue"
                />
              </td>
            </tr>
            <!-- Exceso de gastos periodos anteriores  -->
            <tr>
              <td>
                Diferencia de gastos mayores a ingresos de periodos anteriores
              </td>
              <td class="text-right">
                <!-- <money-text
                  :amount="taxReturn.amounts.excess_expenses_previous_periods"
                /> -->
                <input
                  type="number"
                  class="w-50"
                  v-model="
                    taxReturn.amounts.excess_expenses_previous_periods_value
                  "
                  @keydown="onKeyDown"
                  @click="toggleModalExceso('Detalle de Exceso de Deducciones')"
                />
              </td>
            </tr>
            <!-- Base para isr  -->
            <!-- <tr>
              <td>
                {{ $t('tax_returns.isr_base') }}
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.isr_base" />
              </td>
            </tr> -->
            <!-- Base Gravable  -->
            <tr>
              <td>
                <!-- {{ $t('tax_returns.taxable_base') }} -->
                Utilidad
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.taxable_base" />
              </td>
            </tr>
            <!-- ISR DETERMINADO -->
            <tr class="clickable" @click="toggleDisplayTable('determinado')">
              <td>
                <font-awesome-icon class="icon" icon="arrow-down" />
                ISR Determinado
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.isr_caused" />
              </td>
            </tr>
            <!-- Tablas de ingresos -->
            <tr v-if="displayTables.determinado">
              <td colspan="2">
                <div class="table-responsive">
                  <table class="table table-bordered table-active">
                    <tbody>
                      <!-- Utilidad gravable -->
                      <tr>
                        <td>
                          {{ $t('tax_returns.taxable_base') }}
                        </td>
                        <td class="text-right">
                          <money-text
                            :amount="taxReturn.amounts.taxable_base"
                          />
                        </td>
                      </tr>
                      <!-- Limite inferior -->
                      <tr>
                        <td>
                          {{ $t('rate_tables.lower_limit') }}
                        </td>
                        <td class="text-right">
                          <money-text :amount="taxReturn.amounts.lower_limit" />
                        </td>
                      </tr>
                      <!-- Excedente limite inferior -->
                      <tr>
                        <td>
                          {{ $t('tax_returns.surplus_lower_limit') }}
                        </td>
                        <td class="text-right">
                          <money-text
                            :amount="taxReturn.amounts.surplus_lower_limit"
                          />
                        </td>
                      </tr>
                      <!-- Tasa S/Excedente -->
                      <tr>
                        <td>
                          {{ $t('tax_returns.rate_on_surplus') }}
                        </td>
                        <td class="text-right">
                          {{ taxReturn.amounts.rate_on_surplus }} %
                        </td>
                      </tr>
                      <!-- Importe marginal -->
                      <tr>
                        <td>
                          {{ $t('tax_returns.marginal_amount') }}
                        </td>
                        <td class="text-right">
                          <money-text
                            :amount="taxReturn.amounts.marginal_amount"
                          />
                        </td>
                      </tr>
                      <!-- Cuota fija -->
                      <tr>
                        <td>
                          {{ $t('rate_tables.fixed_fee') }}
                        </td>
                        <td class="text-right">
                          <money-text :amount="taxReturn.amounts.fixed_fee" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>

            <!-- % Condonacion -->
            <tr>
              <td>% de Reducción</td>
              <td class="text-right">
                <input
                  id="myNumber"
                  type="number"
                  min="0"
                  max="100"
                  v-model="taxReturn.amounts.condonation_percentage"
                  @keydown="onKeyDown"
                  @keyup.enter="actualizacion"
                />
                %
              </td>
            </tr>
            <!-- Cantidad condonada -->
            <tr>
              <td>Monto de Redducción</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.amount_forgiven" />
              </td>
            </tr>
            <!-- ISR a pagar -->
            <tr>
              <td>
                {{ $t('tax_returns.isr_to_pay') }}
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.isr_to_pay" />
              </td>
            </tr>
            <!-- ISR actualización -->
            <tr>
              <td>
                {{ $t('tax_returns.update') }}
              </td>
              <td class="text-right">
                <!-- <money-text :amount="taxReturn.amounts.isr_update" /> -->
                <input
                  type="number"
                  class="w-50"
                  v-model="taxReturn.amounts.isr_update_value"
                  @keydown="onKeyDown"
                  @keyup.enter="actualizacion"
                />
              </td>
            </tr>
            <!-- ISR recargos -->
            <tr>
              <td>
                {{ $t('tax_returns.surcharges') }}
              </td>
              <td class="text-right">
                <input
                  type="number"
                  class="w-50"
                  v-model="taxReturn.amounts.isr_surcharge_value"
                  @keydown="onKeyDown"
                  @keyup.enter="actualizacion"
                />
                <!-- <money-text :amount="taxReturn.amounts.isr_surcharges" /> -->
              </td>
            </tr>
            <!--Total de contribuciones  -->
            <tr class="clickable">
              <td>Total de contribuciones</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.total_contribuciones" />
              </td>
            </tr>
            <!--Subsidio Para el Empleo  -->
            <tr>
              <td>Subsidio Para el Empleo</td>
              <td class="text-right">
                <input
                  type="number"
                  class="w-50"
                  v-model="taxReturn.amounts.employment_subsidy"
                  @keydown="onKeyDown"
                  @keyup.enter="actualizacion"
                />
                <!-- <money-text :amount="taxReturn.amounts.isr_surcharges" /> -->
              </td>
            </tr>
            <!--Monto Pagado con Anterioridad  -->
            <tr>
              <td>Monto Pagado con Anterioridad</td>
              <td class="text-right">
                <input
                  type="number"
                  class="w-50"
                  v-model="taxReturn.amounts.amount_previously_paid"
                  @keydown="onKeyDown"
                  @keyup.enter="actualizacion"
                />
                <!-- <money-text :amount="taxReturn.amounts.isr_surcharges" /> -->
              </td>
            </tr>
            <!--Total a pagar de Isr  -->
            <tr class="clickable">
              <td>Total de contribuciones</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.total_ISR_payable" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Table iva -->
    <div v-if="taxReturn.amounts" class="card p-4">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr class="table-primary">
              <th colspan="2" class="text-center">
                {{ $t('tax_returns.iva') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <!-- INGRESOS POR VENTA AL PUBLICO EN GENERAL -->
            <tr
              class="clickable"
              @click="toggleDisplayTable('generalPublicSales')"
            >
              <td>
                <font-awesome-icon class="icon" icon="arrow-down" />
                {{ $t('tax_returns.revenue_general_public_sales') }}
              </td>
              <td class="text-right">
                <money-text
                  :amount="taxReturn.amounts.revenue_general_public_sales"
                />
              </td>
            </tr>
            <!-- Tabla ventas al publico en general -->
            <tr v-if="displayTables.generalPublicSales">
              <td colspan="2">
                <tax-return-invoices-table
                  v-if="taxReturn.tables.general_public"
                  :invoices="taxReturn.tables.general_public.invoices"
                  :totals="taxReturn.tables.general_public.totals"
                  title="tax_returns.general_public_sales"
                />
              </td>
            </tr>
            <!-- INGRESOS FACTURADOS CLIENTES INDIVIDUALES AL 16% -->
            <tr
              class="clickable"
              @click="toggleDisplayTable('individualSales')"
            >
              <td>
                <font-awesome-icon class="icon" icon="arrow-down" />
                {{ $t('tax_returns.revenue_individual_sales') }}
              </td>
              <td class="text-right">
                <money-text
                  :amount="taxReturn.amounts.revenue_individual_sales"
                />
              </td>
            </tr>
            <!-- Table ventas individuales -->
            <tr v-if="displayTables.individualSales">
              <td colspan="2">
                <tax-return-invoices-table
                  v-if="taxReturn.tables.individuals"
                  :invoices="taxReturn.tables.individuals.invoices"
                  :totals="taxReturn.tables.individuals.totals"
                  title="tax_returns.individual_sales"
                />
              </td>
            </tr>

            <!-- Compras y gastos pagados 16% -->
            <!-- <tr>
              <td>
                {{ $t('tax_returns.purchases_and_expenses_paid_16_percent') }}
              </td>
              <td class="text-right">
                <money-text
                  :amount="taxReturn.amounts.purchases_and_expenses_paid16"
                />
              </td>
            </tr> -->
            <tr class="clickable" @click="toggleDisplayTable('purchases_16')">
              <td>
                <font-awesome-icon class="icon" icon="arrow-down" />
                {{ $t('tax_returns.purchases_and_expenses_paid_16_percent') }}
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.actividades16" />
              </td>
            </tr>
            <!-- Tablas de compras -->
            <tr v-if="displayTables.purchases_16">
              <td colspan="2">
                <tax-return-invoices-table
                  v-if="taxReturn.tables.tasa_16"
                  :invoices="taxReturn.tables.tasa_16.invoices"
                  title="tax_returns.purchases"
                />
              </td>
            </tr>

              <!-- Actividades Gravadas a la tasa del 0%  -->
              <tr v-if="taxReturn.tables.tasa_0.invoices.length > 0" class="clickable"
              @click="toggleDisplayTable('tasa_0')">
              <td>
                <font-awesome-icon class="icon" icon="arrow-down" />
                Compras y gastos pagados 0%
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.actividades0" />
              </td>
            </tr>

            <!-- Tabla detalle Total de Actividades Gravadas a la tasa del 0%  -->
            <tr v-if="displayTables.tasa_0 &&
              taxReturn.tables.tasa_0.invoices.length > 0
              ">
              <td colspan="2">
                <tax-return-invoices-table
                v-if="taxReturn.tables.tasa_0"
                :invoices="taxReturn.tables.tasa_0.invoices"
                  title="Compras y gastos pagados 0%" />
              </td>
            </tr>
            <!-- Proporcion utilizada conforme a la LIVA -->
            <tr>
              <td>Proporcion utilizada conforme a la LIVA</td>
              <td class="text-right">
                <input
                  type="number"
                  class="w-50"
                  v-model="taxReturn.amounts.proportion_used_liva"
                  @input="validateInput"
                  step="0.01"
                  min="0"
                  max="1"
                  @keyup.enter="actualizacion"
                />
              </td>
            </tr>
            <!-- IVA que te retuvieron -->
            <tr>
              <td>IVA que te retuvieron</td>
              <td class="text-right">
                <input
                  type="number"
                  class="w-50"
                  v-model="taxReturn.amounts.withheld_tax_value"
                  @keydown="onKeyDown"
                  @keyup.enter="actualizacion"
                />
              </td>
            </tr>
            <!-- IVA POR VENTA AL PUBLICO EN GENERAL -->
            <tr>
              <td>IVA por venta al público en general 2%</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.iva_general_public" />
              </td>
            </tr>
            <!-- % REDUCCION DE IVA -->
            <tr>
              <td>% de Reducción</td>
              <td class="text-right">
                <input
                  id="myNumber"
                  type="number"
                  min="0"
                  max="100"
                  v-model="taxReturn.amounts.percentage_iva_reduction"
                  @keydown="onKeyDown"
                  @keyup.enter="actualizacion"
                />
                %
              </td>
            </tr>
            <!-- Iva Cobrado -->
            <tr>
              <td>Iva Cobrado</td>
              <td class="text-right">
                <money-text
                  :amount="taxReturn.amounts.iva_caused_induvidual_sales"
                />
              </td>
            </tr>
            <!-- IVA Acreditable -->
            <tr class="clickable" @click="toggleDisplayTable('acreditable')">
              <td>
                <font-awesome-icon class="icon" icon="arrow-down" />
                {{ $t('tax_returns.iva_creditable') }}
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.iva_creditable" />
              </td>
            </tr>
            <!-- Tablas  IVA Acreditable -->
            <tr v-if="displayTables.acreditable">
              <td colspan="2">
                <div class="table-responsive">
                  <table class="table table-bordered table-info">
                    <tbody>
                      <!-- IVA pagado del bimestre -->
                      <tr>
                        <td>
                          {{ $t('tax_returns.iva_paid_bimester') }}
                        </td>
                        <td class="text-right">
                          <money-text
                            :amount="taxReturn.amounts.iva_paid_bimester"
                          />
                        </td>
                      </tr>
                      <!--% Propoción del IVA Acreditable  -->
                      <tr
                        class="clickable"
                        @click="toggleDisplayTable('proportion')"
                      >
                        <td>
                          <font-awesome-icon class="icon" icon="arrow-down" />
                          {{ $t('tax_returns.proportion_of_iva_creditable') }}
                        </td>
                        <td class="text-right">
                          {{ taxReturn.amounts.proportion_of_iva_creditable }} %
                        </td>
                      </tr>
                      <tr v-if="displayTables.proportion">
                        <td colspan="2">
                          <div class="table-responsive">
                            <table class="table table-bordered table-secondary">
                              <tbody>
                                <!-- Ingresos facturados clientes individuales tasa 16% ↓ -->
                                <tr>
                                  <td>
                                    Ingresos facturados clientes individuales
                                    tasa 16% ↓
                                  </td>
                                  <td class="text-right">
                                    <money-text
                                      :amount="
                                        taxReturn.amounts
                                          .revenue_individual_sales
                                      "
                                    />
                                  </td>
                                </tr>
                                <!-- Total de ingresos -->

                                <tr>
                                  <td>{{ $t('tax_returns.total_revenue') }}</td>
                                  <td class="text-right">
                                    <money-text
                                      :amount="taxReturn.amounts.total_revenue"
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
            <!-- IVA  IVA a Cargo/Favor -->
            <tr class="clickable" @click="toggleDisplayTable('cargo_favor')">
              <td>
                <font-awesome-icon class="icon" icon="arrow-down" />
                IVA a Cargo/Favor
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.iva_in_charge" />
              </td>
            </tr>
            <!-- Tablas   IVA a Cargo/Favor -->
            <tr v-if="displayTables.cargo_favor">
              <td colspan="2">
                <div class="table-responsive">
                  <table class="table table-bordered table-info">
                    <tbody>
                      <!-- IVA Cobrado -->
                      <tr>
                        <td>IVA Cobrado</td>
                        <td class="text-right">
                          <money-text
                            :amount="taxReturn.amounts.total_iva_caused"
                          />
                        </td>
                      </tr>
                      <!--% Propoción del IVA Acreditable  -->
                      <tr>
                        <td>IVA Acreditable</td>
                        <td class="text-right">
                          <money-text
                            :amount="taxReturn.amounts.iva_creditable"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
            <!-- IVA que te retuvieron -->
            <tr>
              <td>IVA a Favor de Periodos Anteriores</td>
              <td class="text-right">
                <input
                  type="number"
                  class="w-50"
                  v-model="
                    taxReturn.amounts.iva_credit_balance_previous_periods_value
                  "
                  @click="toggleModalSaldo()"
                />
              </td>
            </tr>
            <!-- Iva pagar -->
            <tr>
              <td>Iva a Pagar</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.iva_pay" />
              </td>
            </tr>
            <!-- IVA actualización -->
            <tr>
              <td>
                {{ $t('tax_returns.update') }}
              </td>
              <td class="text-right">
                <!-- <money-text :amount="taxReturn.amounts.isr_update" /> -->
                <input
                  type="number"
                  v-model="taxReturn.amounts.iva_update_value"
                  @keydown="onKeyDown"
                  @keyup.enter="actualizacion"
                />
              </td>
            </tr>
            <!-- IVA recargos -->
            <tr>
              <td>
                {{ $t('tax_returns.surcharges') }}
              </td>
              <td class="text-right">
                <input
                  type="number"
                  v-model.number="taxReturn.amounts.iva_surcharge_value"
                  @keydown="onKeyDown"
                  @keyup.enter="actualizacion"
                />
                <!-- <money-text :amount="taxReturn.amounts.isr_surcharges" /> -->
              </td>
            </tr>
            <!-- Iva pagar -->
            <tr>
              <td>Total a pagar de IVA</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.iva_total_pay" />
              </td>
            </tr>
            <!--  -->
            <tr>
              <td></td>
              <td class="text-right"></td>
            </tr>

            <!-- TOTAL DE INGRESOS -->
            <!-- <tr>
              <td>{{ $t('tax_returns.total_revenue') }}</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.total_revenue" />
              </td>
            </tr> -->
            <!-- % DE IVA CAUSADO SOBRE VENTAS AL PUBLICO EN GENERAL -->
            <!-- <tr>
              <td>{{ $t('tax_returns.percent_iva_general_public') }}</td>
              <td class="text-right">
                {{ taxReturn.amounts.percentage_iva_general_public }} %
              </td>
            </tr> -->
            <!-- REDUCCION DE IVA -->
            <!-- <tr>
              <td>{{ $t('tax_returns.iva_reduction') }}</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.iva_reduction" />
              </td>
            </tr> -->
            <!-- IVA CAUSADO DE VENTAS AL PUBLICO EN GENERAL -->
            <!-- <tr>
              <td>{{ $t('tax_returns.iva_caused_general_public_sales') }}</td>
              <td class="text-right">
                <money-text
                  :amount="taxReturn.amounts.iva_caused_general_public_sales"
                />
              </td>
            </tr> -->
            <!-- IVA CAUSADO DE VENTAS INDIVIDUALES AL 16% -->
            <!-- <tr>
              <td>{{ $t('tax_returns.iva_caused_individual_sales') }}</td>
              <td class="text-right">
                <money-text
                  :amount="taxReturn.amounts.iva_caused_induvidual_sales"
                />
              </td>
            </tr> -->
            <!-- TOTAL DE IVA CAUSADO -->
            <!-- <tr>
              <td>{{ $t('tax_returns.total_iva_caused') }}</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.total_iva_caused" />
              </td>
            </tr> -->
            <!-- Compras y gastos no objeto -->
            <!-- <tr>
              <td>
                {{ $t('tax_returns.purchases_and_expenses_no_object') }}
              </td>
              <td class="text-right">
                <money-text
                  :amount="taxReturn.amounts.purchases_and_expenses_no_object"
                />
              </td>
            </tr> -->
            <!-- Total de compras -->
            <!-- <tr>
              <td>
                {{ $t('tax_returns.total_purchases') }}
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.total_purchases" />
              </td>
            </tr> -->
            <!-- IVA pagado del bimestre -->
            <!-- <tr>
              <td>
                {{ $t('tax_returns.iva_paid_bimester') }}
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.iva_paid_bimester" />
              </td>
            </tr> -->
            <!-- Proporcion  (%) de IVA acreditable -->
            <!-- <tr>
              <td>
                {{ $t('tax_returns.proportion_of_iva_creditable') }}
              </td>
              <td class="text-right">
                {{ taxReturn.amounts.proportion_of_iva_creditable }} %
              </td>
            </tr> -->
            <!-- IVA acreditable -->
            <!-- <tr>
              <td>
                {{ $t('tax_returns.iva_creditable') }}
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.iva_creditable" />
              </td>
            </tr> -->
            <!-- IVA a cargo (FAVOR) -->
            <!-- <tr>
              <td>
                {{ $t('tax_returns.iva_in_charge') }}
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.iva_in_charge" />
              </td>
            </tr> -->
          </tbody>
        </table>
      </div>
    </div>

    <!-- Table payroll -->
    <div v-if="taxReturn.amounts" class="card p-4">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr class="table-primary">
              <th colspan="2" class="text-center">
                {{ $t('tax_returns.isr_detained_for_payroll') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ $t('tax_returns.isr_detained') }}</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.isr_detained_payroll" />
              </td>
            </tr>
            <tr>
              <td>{{ $t('tax_returns.employment_subsidy') }}</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.employment_subsidy" />
              </td>
            </tr>
            <tr>
              <td>{{ $t('tax_returns.isr_detained_to_pay') }}</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.isr_detained_to_pay" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TaxReturnInvoicesTable from './TaxReturnInvoicesTable.vue'
export default {
  components: { TaxReturnInvoicesTable },
  props: {
    taxReturn: {
      type: Object,
      default: null,
    },
    taxLoad: Function,
  },
  data: () => ({
    displayTables: {
      revenueCollected: false,
      purchases: false,
      purchases_16: false,
      taxDeductions: false,
      generalPublicSales: false,
      individualSales: false,
      determinado: false,
      acreditable: false,
      proportion: false,
      cargo_favor: false,
      tasa_0:false,
    },
  }),
  watch: {
    rifIsr() {
      this.taxReturn.amounts.excess_expenses_previous_periods_value =
        this.rifIsr['saldoAplicar']
      this.actualizacion()
    },
    rifIva() {
      this.taxReturn.amounts.iva_credit_balance_previous_periods_value =
        this.rifIva['saldoAplicar']
      this.actualizacion()
    },
  },
  computed: {
    ...mapGetters('taxReturns', ['rifIsr', 'rifIva']),
  },
  methods: {
    ...mapActions('modal', ['openModal']),

    toggleDisplayTable(table) {
      this.displayTables[table] = !this.displayTables[table]
    },
    toggleModalExceso(title) {
      this.openModal({
        title: title,
        componentName: 'ExcesoRifModal',
        size: 'lg',
      })
    },
    toggleModalSaldo() {
      this.openModal({
        title: 'Detalle del Saldo a Favor de IVA',
        componentName: 'SaldoRifModal',
        size: 'lg',
      })
    },
    actualizacion() {
      if (this.taxReturn.amounts.condonation_percentage > 100) {
        this.taxReturn.amounts.condonation_percentage = 100
      }
      this.taxLoad(this.taxReturn)
    },
    validateInput() {
        // Asegúrate de que el valor esté dentro del rango de 0 a 1
        this.taxReturn.amounts.proportion_used_liva = Math.max(0, Math.min(1, this.taxReturn.amounts.proportion_used_liva));
    },

    onKeyDown(event) {
      const regex = /^[0-9]+|^$/

      // Verificar si la tecla presionada es 'Backspace'
      if (event.key === 'Backspace') {
        return // Permitir la eliminación
      }

      if (!regex.test(event.key)) {
        event.preventDefault()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.clickable {
  td {
    font-weight: 500;
  }
}
</style>
