<template>
  <div class="main-content">
    <div class="page-header mt-4">
      <h3 class="page-title">
        {{ $t('treasury_accounts.upload_transactions') }}
      </h3>
      <div class="btn-group" role="group">
        <base-button color="theme" @click="descargarPlantilla()">
          Descargar plantilla
        </base-button>
      </div>
    </div>

    <div class="card mt-3 pt-4 pb-4">
      <div class="row">
        <div class="col-12 col-md-6 offset-md-3">
          <form @submit.prevent="submit">

            <!-- Archivo -->
            <div class="form-group">
              <label for="archivo">Archivo</label>
              <input type="file" id="archivo" class="form-control" accept=".xlsx" @change="readFile" />
            </div>

            <div class="form-group text-center">
              <button class="btn btn-primary" type="submit">
                {{ $t('general.save') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data: () => ({
    file: null,
    id_cuenta:null,
  }),
  computed: {
    ...mapGetters('CurrentCustomer', ['currentCustomer']),
    ...mapGetters('assigned', ['assingedSelect']),

  },
  mounted() {
    this.id_cuenta= this.$route.params.id
  },
  methods: {
    ...mapActions('treasuryAccounts', ['uploadTransactionsFile']),
    readFile(event) {
      const input = event.target
      this.file = input.files[0]
    },
    descargarPlantilla(){
      window.open('/storage/plantillas/plantilla.xlsx', '_blank');

    },
    submit() {
      const formData = new FormData()
      formData.append('file', this.file)
      formData.append('customer_id', this.currentCustomer.id)
      formData.append('account_id',this.id_cuenta)
      this.uploadTransactionsFile(formData)
        .then((response) => {
          window.toastr.success(this.$t('treasury_accounts.upload_success'))
          this.$router.push({ name: 'treasury_accounts.index' })
        })
        .catch((err) => {
          window.toastr.error(this.$t('treasury_accounts.errors.upload'))
        })
    },
  },
}
</script>
