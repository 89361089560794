<template>
  <router-link
    v-if="invoiceId"
    :to="{ name: 'invoices.view', params: { id: invoiceId } }"
    :title="invoiceId"
  >
    {{ uuid }}
  </router-link>
  <div v-else>
    {{ uuid }}
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    uuid: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    invoiceId: null,
  }),

  mounted() {
    this.loadInvoiceId()
  },

  methods: {
    ...mapActions('invoice', ['checkInvoiceIdExitst']),
    loadInvoiceId() {
      this.checkInvoiceIdExitst({ uuid: this.uuid })
        .then((id) => {
          this.invoiceId = id
        })
        .catch(err => {})
    },
  },
}
</script>

<style scoped>
a {
  text-decoration: underline;
}
</style>
