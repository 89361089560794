<template>
  <tr class="item-row invoice-item-row">
    <td style="padding: 15px !important;">
      <base-input
        v-model="item.name"
        :invalid="$v.item.name.$error"
        type="text"
        @input="$v.item.name.$touch()"
        @keyup="updateItem"
      />
      <div v-if="$v.item.name.$error">
        <span v-if="!$v.item.name.required" class="text-danger">{{ $t('validation.required') }}</span>
      </div>
    </td>

    <td class="text-center" style="padding: 15px !important;">
      <base-input
        v-model="item.quantity"
        :invalid="$v.item.quantity.$error"
        type="text"
        small
        @keyup="updateItem"
        @input="$v.item.quantity.$touch()"
      />
      <div v-if="$v.item.quantity.$error">
        <span v-if="!$v.item.quantity.maxLength" class="text-danger">{{ $t('validation.quantity_maxlength') }}</span>
      </div>
    </td>

    <td class="text-left" style="padding: 15px !important;">
      <div class="d-flex flex-column">
        <div class="flex-fillbd-highlight">
          <div class="base-input">
            <money
              v-model="item.price"
              v-bind="customerCurrency"
              class="input-field"
              @input="$v.item.price.$touch()"
              @keyup="updateItem"
            />
          </div>
          <div v-if="$v.item.price.$error">
            <span v-if="!$v.item.price.maxLength" class="text-danger">{{ $t('validation.price_maxlength') }}</span>
          </div>
        </div>
      </div>
    </td>

    <td class="text-right" style="padding: 15px !important;">
      <div class="item-amount">
        <span>
          <div v-html="$utils.formatMoney(subtotal, defaultCurrency)" />
        </span>

        <div class="remove-icon-wrapper">
          <font-awesome-icon
            v-if="showRemoveItemIcon"
            class="remove-icon"
            icon="trash-alt"
            @click="removeItem"
          />
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import { required, maxLength, minValue } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { mapGetters } from 'vuex';

export default {
  mixins: [validationMixin],
  props: {
    itemData: {
      type: Object,
      default: null
    },
    items: {
      type: Array,
      default: null
    },
    index: {
      type: Number,
      default: null
    },
  },
  data () {
    return {
      item: {
        ...this.itemData,
      },
    };
  },
  validations: function () {
    return {
      item: {
        name: {
          required,
        },
        quantity: {
          required,
          minValue: minValue(1),
          maxLength: maxLength(20)
        },
        price: {
          required,
          minValue: minValue(1),
          maxLength: maxLength(20)
        },
      }
    };
  },
  computed: {
    ...mapGetters('currency', [
      'defaultCurrency'
    ]),
    customerCurrency () {
      if (this.defaultCurrency) {
        return {
          decimal: this.defaultCurrency.decimal_separator,
          thousands: this.defaultCurrency.thousand_separator,
          prefix: this.defaultCurrency.symbol + ' ',
          precision: this.defaultCurrency.precision,
          masked: false
        }
      } else {
        return this.defaultCurrenctForInput
      }
    },
    showRemoveItemIcon () {
      if (this.items.length == 1) {
        return false
      }
      return true
    },
    subtotal () {
      return this.item.price * this.item.quantity * 100;
    },
  },
  watch: {
    item: {
      handler: 'updateItem',
      deep: true
    },
  },
  created () {
    window.hub.$on('checkItems', this.validateItem);
  },
  methods: {
    removeItem () {
      this.$emit('remove', this.index);
    },
    updateItem () {
      this.$emit('update', {
        'index': this.index,
        'item': {
          ...this.item,
          subtotal: this.subtotal,
        }
      });
    },
    validateItem () {
      this.$v.item.$touch()
      if (this.item !== null) {
        this.$emit('itemValidate', this.index, !this.$v.$invalid)
      } else {
        this.$emit('itemValidate', this.index, false)
      }
    },
  }
}
</script>
