<template>
  <div class="customer-create main-content">
    <div class="page-header mt-5">
      <h3 class="page-title">{{ $t('customers.title') }}</h3>
      <div class="page-actions row">
        <div class="col-xs-2 mr-4">
          <base-button
            v-show="totalCustomers || filtersApplied"
            :outline="true"
            :icon="filterIcon"
            size="large"
            color="theme"
            right-icon
            @click="toggleFilter"
          >
            {{ $t('general.filter') }}
          </base-button>
        </div>
        <router-link slot="item-title" class="col-xs-2" to="customers/create">
          <base-button size="large" icon="plus" color="theme">
            {{ $t('customers.new_customer') }}
          </base-button>
        </router-link>
      </div>
    </div>

    <transition name="fade">
      <div v-show="showFilters" class="filter-section">
        <div class="row">
          <div class="col-sm-3">
            <label class="form-label">{{ $t('customers.display_name') }}</label>
            <base-input
              v-model="filters.display_name"
              type="text"
              name="name"
              autocomplete="on"
            />
          </div>
          <div class="col-sm-3">
            <label class="form-label">{{ $t('customers.rfc') }}</label>
            <base-input
              v-model="filters.rfc"
              type="text"
              name="rfc"
              autocomplete="on"
            />
          </div>
          <div class="col-sm-3">
            <label class="form-label">{{ $t('customers.tax_regime') }}</label>
            <base-select
              v-model="filters.taxRegime"
              :options="taxRegimes"
              :searchable="true"
              :show-labels="false"
              :allow-empty="true"
              :tabindex="4"
              :placeholder="$t('customers.select_tax_regime')"
              label="name"
              track-by="id"
            />
          </div>
<!--           <label class="clear-filter" @click="clearFilter">{{
            $t('general.clear_all')
          }}</label> -->
        </div>
      </div>
    </transition>

    <div
      v-cloak
      v-show="showEmptyScreen"
      class="col-xs-1 no-data-info"
      align="center"
    >
      <astronaut-icon class="mt-5 mb-4" />
      <div class="row" align="center">
        <label class="col title">{{ $t('customers.no_customers') }}</label>
      </div>
      <div class="row">
        <label class="description col mt-1" align="center">{{
          $t('customers.list_of_customers')
        }}</label>
      </div>
      <div class="btn-container">
        <base-button
          :outline="true"
          color="theme"
          class="mt-3"
          size="large"
          @click="$router.push('customers/create')"
        >
          {{ $t('customers.add_new_customer') }}
        </base-button>
      </div>
    </div>

    <div v-show="!showEmptyScreen" class="table-container">
      <div class="table-actions mt-5">
        <p class="table-stats">
          {{ $t('general.showing') }}: <b>{{ customers.length }}</b>
          {{ $t('general.of') }} <b>{{ totalCustomers }}</b>
        </p>

        <transition name="fade">
          <v-dropdown v-if="selectedCustomers.length" :show-arrow="false">
            <span
              slot="activator"
              href="#"
              class="table-actions-button dropdown-toggle"
            >
              {{ $t('general.actions') }}
            </span>
            <v-dropdown-item>
              <div class="dropdown-item" @click="removeMultipleCustomers">
                <font-awesome-icon
                  :icon="['fas', 'trash']"
                  class="dropdown-item-icon"
                />
                {{ $t('general.delete') }}
              </div>
            </v-dropdown-item>
          </v-dropdown>
        </transition>
      </div>

      <div class="custom-control custom-checkbox">
        <input
          id="select-all"
          v-model="selectAllFieldStatus"
          type="checkbox"
          class="custom-control-input"
          @change="selectAllCustomers"
        />
        <label for="select-all" class="custom-control-label selectall">
          <span class="select-all-label">{{ $t('general.select_all') }} </span>
        </label>
      </div>

      <table-component
        ref="table"
        :show-filter="false"
        :data="fetchData"
        table-class="table"
      >
        <table-column
          :sortable="false"
          :filterable="false"
          cell-class="no-click"
        >
          <template slot-scope="row">
            <div class="custom-control custom-checkbox">
              <input
                :id="row.id"
                v-model="selectField"
                :value="row.id"
                type="checkbox"
                class="custom-control-input"
              />
              <label :for="row.id" class="custom-control-label" />
            </div>
          </template>
        </table-column>
        <table-column :label="$t('customers.name')" show="name">
          <template slot-scope="row">
            <span>{{ $t('customers.name') }}</span>
            <div>
              {{ row.name }}
              <certificate-warning
                :csd-expires-at="row.csd_expires_at"
                :fiel-expires-at="row.fiel_expires_at"
              />
            </div>
          </template>
        </table-column>
        <table-column :label="$t('customers.rfc')" show="rfc" />
        <table-column :label="$t('customers.tax_regime')" show="tax_regime">
          <template slot-scope="row">
            <span>{{ $t('customers.tax_regime') }}</span>
            <div style="width: 150px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  ">
            <tax-regime-catalog :tax-regime="row.tax_regime" />
            <template v-if="row.tax_regime_b">
              <tax-regime-catalog :tax-regime="row.tax_regime_b" />
            </template>
            </div>
          </template>
        </table-column>

        <table-column :label="label_one_previous_tax_regime" show="oneTaxRegime">
          <template slot-scope="row">
            <span>{{ label_one_previous_tax_regime }}</span>
            <template v-if="row.oneTaxRegime">
              <div style="width: 100px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  ">
              <tax-regime-catalog :tax-regime="row.oneTaxRegime" />
              </div>
            </template>
          </template>
        </table-column>

        <table-column :label="label_two_previous_tax_regime" show="twoTaxRegime">
          <template slot-scope="row">
            <span>{{ label_two_previous_tax_regime }}</span>
              <template v-if="row.twoTaxRegime">
                <div style="width: 100px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  "><tax-regime-catalog :tax-regime="row.twoTaxRegime"/>
                </div>
              </template>
          </template>
        </table-column>

        <table-column>
          <template slot-scope="row">
            <font-awesome-icon
              v-if="!row.status"
              :icon="!row.status ? 'exclamation-triangle' : ''"
              class="right-icon"
              color="#eed202"
              v-tooltip="{ content: row.textstatus }"
            />
            <!--{{row.textstatus}}-->
          </template>
        </table-column>

        <table-column :sortable="false" :filterable="false">
          <template slot-scope="row">
            <button
              class="btn btn-primary btn-sm"
              @click="declaracionesCustomerViews(row)"
            >
              {{ $t('customers.declaracion_customer') }}
            </button>
          </template>
        </table-column>

        <table-column :sortable="false" :filterable="false">
          <template slot-scope="row">
            <button
              class="btn btn-primary btn-sm"
              @click="setCustomerToViewDetails(row)"
            >
              {{ $t('customers.select_customer') }}
            </button>
          </template>
        </table-column>
        <table-column
          :sortable="false"
          :filterable="false"
          cell-class="action-dropdown"
        >
          <template slot-scope="row">
            <span> {{ $t('customers.action') }} </span>
            <v-dropdown>
              <a slot="activator" href="#">
                <dot-icon />
              </a>

              <v-dropdown-item>
                <div
                  @click="giveAccessToCustomerPortal(row)"
                  class="dropdown-item"
                >
                  {{ $t('customers.give_access_customer_portal') }}
                </div>
              </v-dropdown-item>

              <v-dropdown-item>
                <router-link
                  :to="{
                    name: 'customer.sat-settings',
                    params: { id: row.id },
                  }"
                  class="dropdown-item"
                >
                  <font-awesome-icon
                    :icon="['fas', 'file-alt']"
                    class="dropdown-item-icon"
                  />
                  {{ $t('customers.sat_settings.page_title') }}
                </router-link>
              </v-dropdown-item>

              <v-dropdown-item>
                <router-link
                  :to="{
                    name: 'customer.previous-invoices',
                    params: { id: row.id },
                  }"
                  class="dropdown-item"
                >
                  <font-awesome-icon
                    :icon="['fas', 'file-alt']"
                    class="dropdown-item-icon"
                  />
                  {{ $t('customers.previous_invoices') }}
                </router-link>
              </v-dropdown-item>

              <v-dropdown-item>
                <router-link
                  :to="{ path: `customers/${row.id}/edit` }"
                  class="dropdown-item"
                >
                  <font-awesome-icon
                    :icon="['fas', 'pencil-alt']"
                    class="dropdown-item-icon"
                  />
                  {{ $t('general.edit') }}
                </router-link>
              </v-dropdown-item>

              <v-dropdown-item>
                <div class="dropdown-item" @click="removeCustomer(row.id)">
                  <font-awesome-icon
                    :icon="['fas', 'trash']"
                    class="dropdown-item-icon"
                  />
                  {{ $t('general.delete') }}
                </div>
              </v-dropdown-item>
            </v-dropdown>
          </template>
        </table-column>
      </table-component>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { SweetModal, SweetModalTab } from 'sweet-modal-vue'
import DotIcon from '../../components/icon/DotIcon'
import AstronautIcon from '../../components/icon/AstronautIcon'
import BaseButton from '../../../js/components/base/BaseButton'
import CertificateWarning from '../components/CertificateWarning'
import TaxRegimeCatalog from '../components/TaxRegimeCatalog'
import { request } from 'http'

export default {
  components: {
    DotIcon,
    AstronautIcon,
    SweetModal,
    SweetModalTab,
    BaseButton,
    CertificateWarning,
    TaxRegimeCatalog,
  },
  data() {
    return {
      showFilters: false,
      filtersApplied: false,
      isRequestOngoing: true,
      taxRegimes: [
        { id: 1, name: 'Honorarios Profesionales' },
        { id: 2, name: 'Arrendatarios' },
        { id: 3, name: 'RIF' },
        { id: 4, name: 'Plataformas Digitales' },
      ],
      filters: {
        display_name: '',
        rfc: '',
        phone: '',
        taxRegime: '',
      },
      label_one_previous_tax_regime: null,
      label_two_previous_tax_regime: null,
    }
  },
  computed: {
    showEmptyScreen() {
      return (
        !this.totalCustomers && !this.isRequestOngoing && !this.filtersApplied
      )
    },
    filterIcon() {
      return this.showFilters ? 'times' : 'filter'
    },
    ...mapGetters('customer', [
      'customers',
      'selectedCustomers',
      'totalCustomers',
      'selectAllField',
    ]),
    selectField: {
      get: function () {
        return this.selectedCustomers
      },
      set: function (val) {
        this.selectCustomer(val)
      },
    },
    selectAllFieldStatus: {
      get: function () {
        return this.selectAllField
      },
      set: function (val) {
        this.setSelectAllState(val)
      },
    },
  },
  watch: {
    filters: {
      handler: 'setFilters',
      deep: true,
    },
  },
  destroyed() {
    if (this.selectAllField) {
      this.selectAllCustomers()
    }
  },
  methods: {
    ...mapActions('customer', [
      'fetchCustomers',
      'selectAllCustomers',
      'selectCustomer',
      'deleteCustomer',
      'deleteMultipleCustomers',
      'setSelectAllState',
      'giveAccessToCustomersPortal',
    ]),
    ...mapActions('CurrentCustomer', ['setCurrentCustomer']),
    refreshTable() {
      this.$refs.table.refresh()
    },
    async fetchData({ page, sort }) {
      const fecha = new Date()
      this.label_one_previous_tax_regime = fecha.getFullYear() - 1
      this.label_one_previous_tax_regime=this.label_one_previous_tax_regime.toString()
      this.label_two_previous_tax_regime = fecha.getFullYear() - 2
      this.label_two_previous_tax_regime=this.label_two_previous_tax_regime.toString()

      let data = {
        display_name: this.filters.display_name,
        phone: this.filters.phone,
        rfc: this.filters.rfc,
        taxRegime: this.filters.taxRegime.id,
        orderByField: sort.fieldName || 'created_at',
        orderBy: sort.order || 'desc',
        page,
      }

      this.isRequestOngoing = true
      let response = await this.fetchCustomers(data)
      this.isRequestOngoing = false

      return {
        data: response.data.customers.data,
        pagination: {
          totalPages: response.data.customers.last_page,
          currentPage: page,
        },
      }
    },
    setFilters() {
      this.filtersApplied = true
      this.refreshTable()
    },
    clearFilter() {
      this.filters = {
        display_name: '',
        phone: '',
        taxRegime: '',
        rfc: '',
      }

      this.$nextTick(() => {
        this.filtersApplied = false
      })
    },
    toggleFilter() {
      if (this.showFilters && this.filtersApplied) {
        this.clearFilter()
        this.refreshTable()
      }

      this.showFilters = !this.showFilters
    },
    async removeCustomer(id) {
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$tc('customers.confirm_delete'),
        icon: '/assets/icon/trash-solid.svg',
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let res = await this.deleteCustomer(id)
          if (res.data.success) {
            window.toastr['success'](this.$tc('customers.deleted_message'))
            this.refreshTable()
            return true
          } else if (request.data.error) {
            window.toastr['error'](res.data.message)
          }
        }
      })
    },
    async removeMultipleCustomers() {
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$tc('customers.confirm_delete', 2),
        icon: '/assets/icon/trash-solid.svg',
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let request = await this.deleteMultipleCustomers()
          if (request.data.success) {
            window.toastr['success'](this.$tc('customers.deleted_message', 2))
            this.refreshTable()
          } else if (request.data.error) {
            window.toastr['error'](request.data.message)
          }
        }
      })
    },
    setCustomerToViewDetails(customer) {
      this.setCurrentCustomer(customer)
      this.$router.push('/general/payments')
    },
    declaracionesCustomerViews(customer) {
      this.setCurrentCustomer(customer)
      this.$router.push('/general/tax-returns')
    },
    giveAccessToCustomerPortal(customer) {
      swal({
        title: this.$t('general.are_you_sure'),
        text: this.$tc('customers.confirm_give_access_customer_portal'),
        buttons: true,
      }).then(async (giveAccess) => {
        if (giveAccess) {
          this.giveAccessToCustomersPortal({ customer_id: customer.id })
            .then((response) => {
              window.toastr['success'](
                this.$t('customers.access_has_been_successfully_given')
              )
            })
            .catch((err) => {
              window.toastr['error'](
                this.$t('customers.error_when_give_access_to_portal')
              )
            })
        }
      })
    },
  },
}
</script>