var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-content" },
    [
      _c("loading", {
        attrs: {
          active: _vm.isLoading,
          "can-cancel": true,
          "on-cancel": _vm.onCancel,
          "is-full-page": _vm.fullPage,
        },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "card mt-3 pt-4 pb-4" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 col-md-6 offset-md-3" }, [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    staticClass: "form-control",
                    attrs: { type: "file", accept: ".xml,.zip" },
                    on: { change: _vm.readFile },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group text-center" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "submit" },
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("general.save")) +
                          "\n            "
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("div", { staticClass: "card mt-3 pt-4 pb-4" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 col-md-6 offset-md-3" }, [
            _c("div", { staticClass: "form-group text-center" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: { click: _vm.descargarScrapper },
                },
                [_vm._v("\n            Descargar CFDI\n          ")]
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page-header mt-4" }, [
      _c("h3", { staticClass: "page-title" }, [_vm._v("Carga manual de CFDI")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page-header mt-4" }, [
      _c("h3", { staticClass: "page-title" }, [
        _vm._v("Carga manual scrapper"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }