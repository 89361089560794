<template>
  <div class="customer-create main-content mt-5">
    <form action="" @submit.prevent="submitCustomerData">
      <div class="page-header">
        <h3 class="page-title">
          {{ isEdit ? $t('customers.edit_customer') : 'Nuevo usuario' }}
        </h3>
        <div class="page-actions header-button-container">
          <base-button
            :loading="isLoading"
            :disabled="isLoading"
            :tabindex="23"
            icon="save"
            color="theme"
            type="submit"
          >
            {{ isEdit ? $t('customers.update_customer') : 'Guardar usuario' }}
          </base-button>
        </div>
      </div>
      <div class="customer-card card">
        <div class="card-body">
          <div class="row">
            <div class="section-title col-sm-2">
              {{ $t('customers.basic_info') }}
            </div>
            <div class="col-sm-5">
              <div class="form-group">
                <label class="form-label">{{ 'Nombre Completo' }}</label
                ><span class="text-danger"> *</span>
                <base-input
                  :invalid="$v.formData.name.$error"
                  v-model="formData.name"
                  focus
                  type="text"
                  name="name"
                  tab-index="1"
                  @input="$v.formData.name.$touch()"
                />
                <div v-if="$v.formData.name.$error">
                  <span v-if="!$v.formData.name.required" class="text-danger">
                    {{ $tc('validation.required') }}
                  </span>
                  <span v-if="!$v.formData.name.minLength" class="text-danger">
                    {{
                      $tc(
                        'validation.name_min_length',
                        $v.formData.name.$params.minLength.min,
                        { count: $v.formData.name.$params.minLength.min }
                      )
                    }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label class="form-label">{{ $t('customers.email') }}</label>
                <span class="text-danger"> *</span
                ><base-input
                  :invalid="$v.formData.email.$error"
                  v-model.trim="formData.email"
                  type="text"
                  name="email"
                  tab-index="3"
                  @input="$v.formData.email.$touch()"
                />
                <div v-if="$v.formData.email.$error">
                  <span v-if="!$v.formData.email.required" class="text-danger">
                    {{ $tc('validation.required') }}
                  </span>
                  <span v-if="!$v.formData.email.email" class="text-danger">
                    {{ $tc('validation.email_incorrect') }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label class="form-label">Contraseña</label>
                <span class="text-danger"> *</span
                ><base-input
                  :invalid="$v.formData.password.$error"
                  v-model.trim="formData.password"
                  type="text"
                  name="pasword"
                  tab-index="3"
                  @input="$v.formData.password.$touch()"
                />
                <div v-if="$v.formData.password.$error">
                  <span
                    v-if="!$v.formData.password.required"
                    class="text-danger"
                  >
                    {{ $tc('validation.required') }}
                  </span>
                  <span
                    v-if="!$v.formData.password.minLength"
                    class="text-danger"
                  >
                    {{
                      $tc(
                        'validation.name_min_length',
                        $v.formData.password.$params.minLength.min,
                        { count: $v.formData.password.$params.minLength.min }
                      )
                    }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label class="form-label">Role</label>
                <span class="text-danger"> *</span>

                <v-dropdown :show-arrow="false" v-model.trim="formData.role">
                  <span
                    slot="activator"
                    href="#"
                    class="table-actions-button dropdown-toggle"
                  >
                   {{formData.role}}
                  </span>
                  <v-dropdown-item>
                    <div class="dropdown-item" @click="setRole('ejecutivo')">
                      ejecutivo
                    </div>
                  </v-dropdown-item>

                  <v-dropdown-item>
                    <div class="dropdown-item" @click="setRole('admin')">
                      administrador
                    </div>
                  </v-dropdown-item>
                </v-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MultiSelect from 'vue-multiselect'
import { validationMixin } from 'vuelidate'
import AddressStub from '../../stub/address'
const {
  required,
  minLength,
  email,
  url,
  maxLength,
} = require('vuelidate/lib/validators')

export default {
  components: { MultiSelect },
  mixins: [validationMixin],
  data() {
    return {
      isCopyFromBilling: false,
      isLoading: false,
      formData: {
        name: null,
        email: null,
        password: null,
        phone: 'S/N',
        rfc: 'S/N',
        role: 'ejecutivo',
      },
    }
  },
  validations: {
    formData: {
      name: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
      },
    },
  },
  computed: {
    ...mapGetters('currency', ['defaultCurrency', 'currencies']),
    ...mapGetters('catalogs', ['taxRegimes']),
    isEdit() {
      if (this.$route.name === 'customers.edit') {
        return true
      }
      return false
    },
  },
  watch: {},
  mounted() {
    if (this.isEdit) {
      this.loadCustomer()
    } else {
      this.currency = this.defaultCurrency
    }
  },
  methods: {
    currencyNameWithCode({ name, code }) {
      return `${code} - ${name}`
    },
    ...mapActions('auth', ['register']),
    ...mapActions('customer', [
      'addCustomer',
      'fetchCustomer',
      'updateCustomer',
    ]),

    setRole(role){
      this.formData.role = role;
    },
    async loadCustomer() {
      let {
        data: { customer, currencies, currency },
      } = await this.fetchCustomer(this.$route.params.id)

      this.formData.name = customer.name
      this.formData.email = customer.email
    },
    async fetchCountry() {
      let res = await window.axios.get('/api/countries')
      if (res) {
        this.billingCountries = res.data.countries
        this.shippingCountries = res.data.countries
      }
    },
    async fetchStates() {
      let res = await window.axios.get('/api/states')
      if (res) {
        this.billingStates = res.data.states
      }
    },
    copyAddress(val) {
      if (val === true) {
        this.isCopyFromBilling = true
        this.shipping = { ...this.billing, type: 'shipping' }
        this.shipping_country = this.billing_country
        this.shipping_state = this.billing_state
        this.shipping_city = this.billing_city
      } else {
        this.shipping = { ...AddressStub, type: 'shipping' }
        this.shipping_country = null
        this.shipping_state = null
        this.shipping_city = null
      }
    },
    async submitCustomerData() {
      this.$v.formData.$touch()

      if (this.$v.$invalid) {
        return true
      }

      if (this.isEdit) {
        data.append('id', this.formData.id)
        if (this.currency) {
          this.formData.currency_id = this.currency.id
          data.append('currency_id', this.formData.currency_id)
        }
        this.isLoading = true
        try {
          data.append('_method', 'PUT')
          let response = await this.updateCustomer({
            id: this.$route.params.id,
            editData: data,
          })
          if (response.data.success) {
            window.toastr['success'](this.$t('customers.updated_message'))
            this.$router.push('/general/customers/pending')
            this.isLoading = false
            return true
          } else {
            this.isLoading = false
            if (response.data.error) {
              window.toastr['error'](this.$t('validation.email_already_taken'))
            }
          }
        } catch (err) {
          if (err.response.data.toast) {
            this.isLoading = false
            window.toastr['error'](err.response.data.toast)
          }
          if (err.response.data.errors.email) {
            this.isLoading = false
            window.toastr['error'](this.$t('validation.email_already_taken'))
          }
        }
      } else {
        this.isLoading = true
        try {
          //   this.register(this.registroData)
          // .then((res) => {
          //   if (res.status == 200) {
          //     this.$router.push('/wizard/contratacion')
          //   }
          //   this.isLoading = false
          // })
          // .catch((er) => {
          //   this.isLoading = false
          // })
          console.log(this.formData)
          let response = await this.register(this.formData) //this.formData)
          if (response.status == 200) {
            window.toastr['success']('Usuario creado exitosamente')
            this.$router.push('/general/users/')
            this.isLoading = false
            return true
          }
        } catch (err) {
          if (err.response.data.toast) {
            this.isLoading = false
            window.toastr['error'](err.response.data.toast)
          }
          if (err.response.data.errors.email) {
            this.isLoading = false
            window.toastr['error'](this.$t('validation.email_already_taken'))
          }
        }
      }
    },
    onESignCerChange(e) {
      var input = event.target
      var filePath = input.value

      var allowedExtensions = /(\.cer)$/i
      if (!allowedExtensions.exec(filePath)) {
        swal('Revise que el archivo sea .CER ')
        input.value = ''
        return false
      }

      this.e_sign_cer = input.files[0]

      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = (e) => {
          this.previewESignCer = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      }
    },
    onESignKeyChange(e) {
      var input = event.target
      var filePath = input.value

      var allowedExtensions = /(\.key)$/i
      if (!allowedExtensions.exec(filePath)) {
        swal('Revise que el archivo sea .KEY ')
        input.value = ''
        return false
      }
      var input = event.target
      this.e_sign_key = input.files[0]

      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = (e) => {
          this.previewESignCer = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      }
    },
    onDigitalCerChange(e) {
      var input = event.target
      var filePath = input.value

      var allowedExtensions = /(\.cer)$/i
      if (!allowedExtensions.exec(filePath)) {
        swal('Revise que el archivo sea .CER ')
        input.value = ''
        return false
      }
      var input = event.target
      this.digital_stamp_cer = input.files[0]

      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = (e) => {
          this.previewESignCer = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      }
    },
    onDigitalKeyChange(e) {
      var input = event.target
      var filePath = input.value

      var allowedExtensions = /(\.key)$/i
      if (!allowedExtensions.exec(filePath)) {
        swal('Revise que el archivo sea .KEY ')
        input.value = ''
        return false
      }
      var input = event.target
      this.digital_stamp_key = input.files[0]

      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = (e) => {
          this.previewESignCer = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      }
    },
  },
}
</script>
