<template>
  <div v-if="taxReturn" class="card p-3">
    <h5 class="text-center mb-3">
      Declaración Anual RESICO
    </h5>

    <!-- Determinación Del Impuesto -->
    <div class="border-top border-bottom text-center table-title">
      {{ $t('tax_returns.determination_of_tax') }}
    </div>
    <div class="p-2">
      <!-- Use tax loss -->
      <div class="form-group flex-content-right">
        <base-button
          class="mr-2"
          color="danger"
          size="small"
          @click="openApplyTaxLossModal"
        >
          {{ $t('tax_losses.apply_tax_loss') }}
        </base-button>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table">
        <tbody>
          <!-- INGRESOS ACUMULABLES -->
          <tr>
            <td>
              <a href="#revenue">
                {{ $t('tax_returns.accumulable_revenue') }}
              </a>
            </td>
            <td class="text-right">
              <money-text :amount="taxDetermination.accumulable_revenue" />
            </td>
          </tr>
          <!-- DEDUCCIONES PERSONALES -->
          <tr>
            <td>
              <a href="#persona_deductions">
                {{ $t('tax_returns.personal_deductions') }}
              </a>
            </td>
            <td class="text-right">
              <money-text :amount="taxDetermination.personal_deductions" />
            </td>
          </tr>
          <!-- BASE GRAVABLE -->
          <tr>
            <td>{{ $t('tax_returns.taxable_base') }}</td>
            <td class="text-right">
              <money-text :amount="taxDetermination.taxable_base" />
            </td>
          </tr>
          <!-- ISR CONFORME A LA TARIFA ANUAL -->
          <tr>
            <td>{{ $t('tax_returns.isr_according_to_the_annual_rate') }}</td>
            <td class="text-right">
              <money-text
                :amount="taxDetermination.isr_to_according_annual_rate"
              />
            </td>
          </tr>
          <!-- PAGOS PROVISIONALES -->
<!--           <tr>
            <td>{{ $t('tax_returns.provisional_payments') }}</td>
            <td class="text-right">
              <money-text :amount="taxDetermination.provisional_payments" />
            </td>
          </tr> -->
          <!-- ISR RETENIDO -->
          <tr>
            <td>
              <a href="#isr_withheld">
                {{ $t('tax_returns.isr_detained') }}
              </a>
            </td>
            <td class="text-right">
              <money-text :amount="taxDetermination.isr_withheld" />
            </td>
          </tr>
          <!-- Pérdida fiscal -->
<!--           <tr>
            <td>{{ $t('tax_returns.tax_loss') }}</td>
            <td class="text-right">
              <money-text :amount="taxDetermination.tax_loss" />
            </td>
          </tr> -->
          <!-- ISR A CARGO -->
          <tr>
            <td>{{ $t('tax_returns.isr_in_charge') }}</td>
            <td class="text-right">
              <money-text :amount="taxDetermination.isr_in_charge" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Sueldos y salarios -->
    <div v-if="byIssuer">
      <div class="border-top border-bottom text-center table-title">
        <a href="#tables-wages-salaries">
          {{ $t('tax_returns.wages_and_salaries') }}
        </a>
      </div>
      <table v-if="showByIssuerTable" class="sub-table">
        <thead>
          <tr>
            <td>{{ $t('tax_returns.retainer_rfc') }}</td>
            <td>{{ $t('invoices.name') }}</td>
            <td class="text-right">
              {{ $t('tax_returns.annual_revenue') }}
            </td>
            <td class="text-right">
              {{ $t('tax_returns.exempt_revenue') }}
            </td>
            <td class="text-right">
              {{ $t('tax_returns.isr_withheld') }}
            </td>
            <td class="text-right">
              {{ $t('tax_returns.employment_subsidy') }}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="issuer in byIssuer" :key="issuer.rfc">
            <td>{{ issuer.rfc }}</td>
            <td>{{ issuer.name }}</td>
            <td class="text-right">
              <money-text :amount="issuer.annual_revenue" />
            </td>
            <td class="text-right">
              <money-text :amount="issuer.exempt_amount" />
            </td>
            <td class="text-right">
              <money-text :amount="issuer.deduction_isr" />
            </td>
            <td class="text-right">
              <money-text :amount="issuer.employment_subsidy" />
            </td>
          </tr>
        </tbody>
      </table>

      <div class="table-responsive">
        <table class="table">
          <tbody>
            <!-- INGRESO ANUAL -->
            <tr>
              <td>{{ $t('tax_returns.annual_revenue') }}</td>
              <td class="text-right">
                <money-text
                  :amount="taxReturn.wages_and_salaries.annual_revenue"
                />
              </td>
            </tr>
            <!-- INGRESO EXENTOS -->
            <tr>
              <td>{{ $t('tax_returns.exempt_revenue') }}</td>
              <td class="text-right">
                <money-text
                  :amount="taxReturn.wages_and_salaries.exempt_revenue"
                />
              </td>
            </tr>
            <!-- INGRESO ACUMULABLES -->
            <tr>
              <td>{{ $t('tax_returns.accumulable_revenue') }}</td>
              <td class="text-right">
                <money-text
                  :amount="taxReturn.wages_and_salaries.accumulable_revenue"
                />
              </td>
            </tr>
            <!-- SUBSIDIO PARA EL EMPLEO -->
            <tr>
              <td>{{ $t('tax_returns.employment_subsidy') }}</td>
              <td class="text-right">
                <money-text
                  :amount="taxReturn.wages_and_salaries.employment_subsidy"
                />
              </td>
            </tr>
            <!-- IMPUESTO RETENIDO -->
            <tr>
              <td>{{ $t('tax_returns.tax_detained') }}</td>
              <td class="text-right">
                <money-text :amount="taxReturn.wages_and_salaries.isr_withheld" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Deducciones personales -->
    <div
      id="personal_deductions"
      class="border-top border-bottom text-center table-title"
    >
      <a href="#table-personal-deductions">
        {{ $t('tax_returns.personal_deductions') }}
      </a>
    </div>
    <div class="table-responsive">
      <table
        v-for="(category, i) in personalDeductions"
        :key="i"
        class="table personal-deductions"
      >
        <thead>
          <tr>
            <th colspan="2">{{ category.subcategory.name }}</th>
          </tr>
          <tr>
            <td class="col">{{ $t('invoices.date') }}</td>
            <td class="col">{{ $t('invoices.rfc') }}</td>
            <td>{{ $t('invoices.name') }}</td>
            <td class="amount">{{ $t('tax_returns.deductible_amount') }}</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in category.items" :key="item.id">
            <td class="col">
              <router-link
                :to="{ name: 'invoices.view', params: { id: item.invoice_id } }"
                target="_blank"
              >
                {{ item.date }}
              </router-link>
            </td>
            <td class="col">
              <router-link
                :to="{ name: 'invoices.view', params: { id: item.invoice_id } }"
                target="_blank"
              >
                {{ item.rfc }}
              </router-link>
            </td>
            <td>{{ item.name }}</td>
            <td class="amount">
              <money-text :amount="item.amount" />
            </td>
          </tr>
          <tr>
            <td colspan="3" class="text-right">
              {{ $t('tax_returns.total') }}
            </td>
            <td class="text-right">
              <money-text :amount="category.total" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Retenciones -->
    <div
      id="isr_withheld"
      class="border-top border-bottom text-center table-title"
    >
      <a href="#table-revenue">
        {{ $t('tax_returns.retentions') }}
      </a>
    </div>
    <div class="table-responsive">
      <table class="table">
        <tbody>
          <!-- Sueldos y salarios -->
          <tr>
            <th colspan="3" class="text-center table-subtitle">
              {{ $t('tax_returns.wages_and_salaries') }}
            </th>
          </tr>
          <tr>
            <td class="text-center">
              {{ $t('tax_returns.retainer_rfc') }}
            </td>
            <td class="text-center">
              {{ $t('tax_returns.revenue_amount') }}
            </td>
            <td class="text-center">
              {{ $t('tax_returns.isr_retentions') }}
            </td>
          </tr>
          <tr v-for="(withheld, i) in withhelds.salaries" :key="i">
            <td class="text-center">
              {{ withheld.rfc }}
            </td>
            <td class="text-right">
              <money-text :amount="withheld.amount" />
            </td>
            <td class="text-right">
              <money-text :amount="withheld.isr" />
            </td>
          </tr>
          <!-- Del regime -->
          <tr>
            <th colspan="3" class="text-center table-subtitle">
              <tax-regime-catalog :tax-regime="currentCustomer.tax_regime" />
            </th>
          </tr>
          <tr>
            <td class="text-center">
              {{ $t('tax_returns.retainer_rfc') }}
            </td>
            <td class="text-right">
              {{ $t('tax_returns.revenue_amount') }}
            </td>
            <td class="text-right">
              {{ $t('tax_returns.isr_retentions') }}
            </td>
          </tr>
          <tr v-for="(withheld, i) in withhelds.regime" :key="i">
            <td class="text-center">
              {{ withheld.rfc }}
            </td>
            <td class="text-right">
              <money-text :amount="withheld.amount" />
            </td>
            <td class="text-right">
              <money-text :amount="withheld.isr" />
            </td>
          </tr>

        </tbody>
      </table>
    </div>

    <!-- Tabla de ingresos -->
    <div
      id="table-revenue"
      class="mt-4 text-center clickable"
      @click="toggleDisplayTable('revenue')"
    >
      <ins>{{ $t('tax_returns.revenue_perceived') }}</ins>
    </div>
    <div v-if="displayTables.revenue">
      <tax-return-invoices-table
        v-if="taxReturn.tables.revenue"
        :invoices="taxReturn.tables.revenue"
      />
    </div>

    <!-- Tabla de egresos -->
    <div
      id="table-expenses"
      class="mt-4 text-center clickable"
      @click="toggleDisplayTable('purchases')"
    >
      <ins>{{ $t('tax_returns.purchases') }}</ins>
    </div>
    <div v-if="displayTables.purchases">
      <tax-return-invoices-table
        v-if="taxReturn.tables.purchases"
        :invoices="taxReturn.tables.purchases"
      />
    </div>

    <!-- Tabla de deducciones -->
    <div
      id="table-deductions"
      class="mt-4 text-center clickable"
      @click="toggleDisplayTable('deductions')"
    >
      <ins>{{ $t('tax_returns.deductions') }}</ins>
    </div>
    <div v-if="displayTables.deductions">
      <tax-return-invoices-table
        v-if="taxReturn.tables.tax_deductions"
        :invoices="taxReturn.tables.tax_deductions"
        :can-display-no-object="true"
      />
    </div>

    <!-- Tabla sueldos y salarios -->
    <div
      id="tables-wages-salaries"
      class="mt-4 text-center clickable"
      @click="toggleDisplayTable('salaries')"
    >
      <ins>{{ $t('tax_returns.wages_and_salaries') }}</ins>
    </div>
    <div v-if="displayTables.salaries" class="mt-4 table-responsive">
      <table class="table table-bordered invoices-table">
        <thead>
          <tr>
            <th>{{ $t('invoices.invoice_date') }}</th>
            <th>{{ $t('invoices.uuid') }}</th>
            <th>{{ $t('invoices.rfc') }}</th>
            <th class="text-right">{{ $t('tax_returns.taxed_amount') }}</th>
            <th class="text-right">{{ $t('tax_returns.exempt_amount') }}</th>
            <th class="text-right">{{ $t('tax_returns.deduction_isr') }}</th>
            <th class="text-right">
              {{ $t('tax_returns.employment_subsidy') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="invoice in taxReturn.tables.wages_and_salaries"
            :key="invoice.id"
          >
            <td>{{ invoice.invoice_date }}</td>
            <td>
              <router-link
                :to="{ name: 'invoices.view', params: { id: invoice.id } }"
                target="_blank"
              >
                {{ invoice.uuid }}
              </router-link>
            </td>
            <td>{{ invoice.rfc }}</td>
            <td class="text-right">
              <money-text :amount="invoice.taxed_amount" />
            </td>
            <td class="text-right">
              <money-text :amount="invoice.exempt_amount" />
            </td>
            <td class="text-right">
              <money-text :amount="invoice.deduction_isr" />
            </td>
            <td class="text-right">
              <money-text :amount="invoice.employment_subsidy" />
            </td>
          </tr>
          <tr>
            <td colspan="3"></td>
            <td class="text-right">
              <money-text :amount="wagesAndSaliariesTotals.taxed_amount" />
            </td>
            <td class="text-right">
              <money-text :amount="wagesAndSaliariesTotals.exempt_amount" />
            </td>
            <td class="text-right">
              <money-text :amount="wagesAndSaliariesTotals.deduction_isr" />
            </td>
            <td class="text-right">
              <money-text
                :amount="wagesAndSaliariesTotals.employment_subsidy"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Tablas de deducciones personales -->
    <div
      id="table-personal-deductions"
      class="mt-4 text-center clickable"
      @click="toggleDisplayTable('personalDeductions')"
    >
      <ins>{{ $t('tax_returns.personal_deductions') }}</ins>
    </div>
    <div v-if="displayTables.personalDeductions">
      <div
        v-for="subcategory in taxReturn.tables.personal_deductions"
        :key="subcategory.id"
        class="mt-4 table-responsive"
      >
        <table class="table table-bordered invoices-table">
          <thead>
            <tr class="border-bottom">
              <th colspan="6" class="text-center">
                {{ subcategory.subcategory.name }}
              </th>
            </tr>
            <tr>
              <th>{{ $t('invoices.invoice_date') }}</th>
              <th>{{ $t('invoices.uuid') }}</th>
              <th>{{ $t('invoices.rfc') }}</th>
              <th>{{ $t('invoices.name') }}</th>
              <th>{{ $t('invoices.item.description') }}</th>
              <th class="text-right">{{ $t('invoices.amount') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in subcategory.items" :key="item.id">
              <td>{{ item.invoice_date }}</td>
              <td>
                <router-link
                  :to="{
                    name: 'invoices.view',
                    params: { id: item.invoice_id },
                  }"
                  target="_blank"
                >
                  {{ item.uuid }}
                </router-link>
              </td>
              <td>{{ item.rfc }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.description }}</td>
              <td class="text-right">
                <div class="base-input">
                  <money
                    v-model="item.amount_edit"
                    v-bind="customerCurrency"
                    class="input-field text-right"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="can.applyPersonalDeductions" class="mt-2 flex-content-right">
        <base-button size="small" color="info" @click="applyPersonalDeductions">
          {{ $t('tax_returns.apply_personal_deductions') }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import Guid from 'guid'
import { mapActions, mapGetters } from 'vuex'
import TaxRegimeCatalog from './TaxRegimeCatalog.vue'
import TaxReturnInvoicesTable from './TaxReturnInvoicesTable.vue'

export default {
  components: { TaxRegimeCatalog, TaxReturnInvoicesTable },
  props: {
    taxReturn: {
      type: Object,
      default: null,
    },
    blindTaxReturn: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    form: {
      financialInstitutions: [
        {
          guid: Guid.raw(),
          rfc: '',
          amount_of_nominal_interest: 0,
          amount_of_real_interest: 0,
          lost: 0,
          isr_withheld: 0,
        },
      ],
      isrConcepts: [],
      isrConcept: '',
    },
    displayTables: {
      personalDeductions: false,
      salaries: false,
      revenue: false,
      deductions: false,
      purchases: false,
    },
    can: {
      applyPersonalDeductions: false,
    },
    showInterests: true,
  }),
  computed: {
    ...mapGetters('currency', ['defaultCurrency']),
    ...mapGetters('CurrentCustomer', ['currentCustomer']),
    ...mapGetters('taxReturns', ['catalogs']),
    customerCurrency() {
      if (this.defaultCurrency) {
        return {
          decimal: this.defaultCurrency.decimal_separator,
          thousands: this.defaultCurrency.thousand_separator,
          prefix: this.defaultCurrency.symbol + ' ',
          precision: this.defaultCurrency.precision,
          masked: false,
        }
      } else {
        return this.defaultCurrenctForInput
      }
    },
    showByIssuerTable() {
      if(this.byIssuer)
      return this.byIssuer.length > 0
    },
    byIssuer() {
      if(this.taxReturn.wages_and_salaries)
      return this.taxReturn.wages_and_salaries.by_issuer
    },
    personalDeductions() {
      const categories = this.taxReturn.personal_deductions.categories
      for (let i = 0; i < categories.length; i++) {
        const category = categories[i]
        let categoryTotal = 0
        for (let j = 0; j < category.items.length; j++) {
          const item = category.items[j]
          categoryTotal += item.amount
        }
        categories[i].total = categoryTotal
      }
      return categories
    },
    withhelds() {
      return this.taxReturn.withheld
    },
    taxDetermination() {
      return this.taxReturn.tax_determination
    },
    isrIndividuals() {
      return this.taxReturn.payment_isr_individuals
    },
    amountInCharge() {
      const concepts = this.taxReturn.payment_isr_individuals.concepts
      let conceptsAmount = 0
      for (let i = 0; i < concepts.length; i++) {
        const concept = concepts[i]
        let amount = concept.amount * 100
        amount = concept.action == 'add' ? amount : amount * -1
        conceptsAmount += amount
      }
      return this.taxReturn.payment_isr_individuals.in_charge + conceptsAmount
    },
    amountWithoutFirstBias() {
      const amount =
        this.amountInCharge -
        this.taxReturn.payment_isr_individuals.amount_with_first_bias * 100
      return amount
    },
    amountPayable() {
      if (this.taxReturn.payment_isr_individuals.does_first_bias_apply) {
        return (
          this.taxReturn.payment_isr_individuals.amount_with_first_bias * 100
        )
      }
      return this.amountInCharge
    },
    totalContributions() {
      let total = 0
      const concepts = this.taxReturn.payment_isr_individuals.concepts
      const updatedPart = concepts.find((item) => item.order == 0)
      const surcharges = concepts.find((item) => item.order == 1)
      const penalityForCorrection = concepts.find((item) => item.order == 2)
      if (updatedPart) {
        total += updatedPart.amount
      }
      if (surcharges) {
        total += surcharges.amount
      }
      if (penalityForCorrection) {
        total += penalityForCorrection.amount
      }
      return total * 100
    },
    wagesAndSaliariesTotals() {
      return this.taxReturn.tables.wages_and_salaries.reduce(
        (accumulator, item) => {
          accumulator.taxed_amount += item.taxed_amount
          accumulator.exempt_amount += item.exempt_amount
          accumulator.deduction_isr += item.deduction_isr
          accumulator.employment_subsidy += item.employment_subsidy

          return accumulator
        },
        {
          taxed_amount: 0,
          exempt_amount: 0,
          deduction_isr: 0,
          employment_subsidy: 0,
        }
      )
    },
    isrConcepts() {
      const concepts = [...this.taxReturn.payment_isr_individuals.concepts]

      concepts.push({
        action: 'add',
        description: 'Total de contribuciones',
        order: 3,
        amount: this.totalContributions,
      })

      return concepts.sort((a, b) => a.order - b.order)
    },
  },
  watch: {
    'taxReturn.tables.personal_deductions': {
      deep: true,
      handler: function () {
        this.can.applyPersonalDeductions = true
      },
    },
  },
  created() {},
  mounted() {
    window.hub.$on('applyTaxLoss', ({ taxLoss }) => {
      this.$emit('applyTaxLoss', taxLoss)
    })
  },
  methods: {
    ...mapActions('modal', ['openModal']),
    addEmptyFinancialInstitution() {
      this.form.financialInstitutions.push({
        guid: Guid.raw(),
        rfc: '',
        amount_of_nominal_interest: 0,
        amount_of_real_interest: 0,
        lost: 0,
        isr_withheld: 0,
      })
    },

    applyInterests() {
      this.$emit('applyInterests', this.form.financialInstitutions)
    },

    applyPersonalDeductions() {
      this.$emit(
        'applyPersonalDeductions',
        this.taxReturn.tables.personal_deductions
      )
    },

    addISRConcept() {
      if (this.form.isrConcept == '') {
        return null
      }
      const symbol = this.form.isrConcept.action == 'add' ? '+' : '-'
      const description = `${symbol} ${this.form.isrConcept.description}`
      let index = this.taxReturn.payment_isr_individuals.concepts.findIndex(
        (item) => {
          return item.description == description
        }
      )
      if (index != -1) {
        return null
      }
      const concept = {
        action: this.form.isrConcept.action,
        order: this.form.isrConcept.order,
        description: description,
        amount: 0,
      }
      if ('date' in this.form.isrConcept && this.form.isrConcept.date) {
        concept['date'] = new Date().toString()
      }
      this.taxReturn.payment_isr_individuals.concepts.push(concept)
      // this.taxReturn.payment_isr_individuals.concepts.sort((a, b) => {
      //   return a.order - b.order
      // })
      this.form.isrConcept = ''
    },

    removeIsrConcept(index) {
      this.taxReturn.payment_isr_individuals.concepts.splice(index, 1)
    },

    cleanAmountWithFirstBias() {
      if (!this.taxReturn.payment_isr_individuals.does_first_bias_apply) {
        this.taxReturn.payment_isr_individuals.amount_with_first_bias = 0
      }
    },

    openApplyTaxLossModal() {
      this.openModal({
        title: this.$t('tax_losses.tax_loss'),
        componentName: 'ApplyTaxLossModal',
      })
    },

    toggleDisplayTable(table) {
      this.displayTables[table] = !this.displayTables[table]
    },
  },
}
</script>

<style lang="scss" scoped>
table {
  a {
    text-decoration: underline;
  }
}
a {
  text-decoration: underline;
}
.sub-table {
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
  border: 1px solid #d7d8da;

  td,
  th {
    border: 1px solid #ccc;
    padding: 0.5rem;
  }
}
.personal-deductions {
  .col {
    width: 150px;
  }
  .amount {
    width: 150px;
    text-align: right;
  }
}
.invoices-table {
  th,
  td {
    font-size: 12px;
  }
}
</style>
