<template>
  <div>
    <div class="card-body">
      <form class="pt-4 pb-4 pl-4 pr-4">
        <div class="row">
          <div class="col">
            <label for="input1">Tipo</label>
            <base-select
              v-model="form.tipo"
              :options="tipoOptions"
              :searchable="true"
              :allow-empty="false"
              :show-labels="false"
              label="label"
              track-by="id"
              style="font-size: 12px"
            />
          </div>
          <div class="col">
            <label for="input2">Bimestre</label>
            <base-select
              v-model="form.bimestre"
              :options="bimestreOptions"
              :searchable="true"
              :allow-empty="false"
              :show-labels="false"
              label="label"
              track-by="id"
              style="font-size: 12px"
            />
          </div>
          <div class="col">
            <label for="input3">Ejercicio</label>
            <base-select
              v-model="form.ejercicio"
              :options="ejercicioOptions"
              :searchable="true"
              :allow-empty="false"
              :show-labels="false"
              label="label"
              track-by="id"
              style="font-size: 12px"
            />
          </div>
          <div class="col">
            <label for="input4">Número de Operación</label>
            <base-input type="number" v-model="form.numeroOperacion" />
          </div>
        </div>

        <!-- Tercera fila con 2 inputs -->
        <div class="row mt-3">
          <div class="col">
            <label for="input5">Saldo a Aplicar</label>
            <base-input type="number" v-model="form.saldoAplicar" />
          </div>
          <div class="col">
            <label for="input7">Monto Original del Saldo</label>
            <base-input type="number" v-model="form.montoOriginal" />
          </div>
          <div class="col form-group">
            <label>Fecha en que se presento</label>
            <base-date-picker
              v-model="form.fecha"
              :calendar-button="true"
              calendar-button-icon="calendar"
              :typeable='true'
            />
          </div>
          <div class="col">
            <label for="input11">Remanente por aplicar </label>
            <base-input type="number" v-model="form.remanente" />
          </div>
        </div>
      </form>
    </div>
    <div class="card-footer">
      <div class="mt-2 flex-content-right">
        <base-button color="theme" @click="saveExceso">
          Aplicar Saldo
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [validationMixin],

  data: () => ({
    form: {
      tipo: null,
      bimestre: null,
      ejercicio: null,
      numeroOperacion: 0,
      saldoAplicar: 0,
      montoOriginal: 0,
      fecha: null,
      remanente: 0,
    },

    tipoOption: [
      { id: 0, name: 'Exceso de Deducciones de Periodos Ant' },
      { id: 1, name: 'Monto pagado con Anterioridad' },
    ],

    bimestreOption: [
      { id: 0, name: 'ENE - FEB' },
      { id: 1, name: 'MAR - ABR' },
      { id: 2, name: 'MAY - JUN' },
      { id: 3, name: 'JUL - AGO' },
      { id: 4, name: 'SEP - OCT' },
      { id: 5, name: 'NOV - DIC' },
    ],

    ejercicioOption: [
      { id: 0, name: '2023' },
      { id: 1, name: '2022' },
      { id: 2, name: '2021' },
      { id: 3, name: '2020' },
      { id: 4, name: '2019' },
      { id: 5, name: '2018' },
      { id: 6, name: '2017' },
    ],
  }),

  computed: {
    ...mapGetters('currency', ['defaultCurrency']),
    ...mapGetters('invoice', ['dataModalAddPaidDocument']),
    ...mapGetters('catalogs', [
      'paidDocumentOptions',
      'searchPaidDocumentOption',
    ]),

    bimestreOptions() {
      return this.bimestreOption.map((item) => {
        return {
          ...item,
          id: item.id,
          label: `${item.name}`,
        }
      })
    },
    ejercicioOptions() {
      return this.ejercicioOption.map((item) => {
        return {
          ...item,
          id: item.id,
          label: `${item.name}`,
        }
      })
    },
    tipoOptions() {
      return this.tipoOption.map((item) => {
        return {
          ...item,
          id: item.id,
          label: `${item.name}`,
        }
      })
    },

    customerCurrency() {
      if (this.defaultCurrency) {
        return {
          decimal: this.defaultCurrency.decimal_separator,
          thousands: this.defaultCurrency.thousand_separator,
          prefix: this.defaultCurrency.symbol + ' ',
          precision: this.defaultCurrency.precision,
          masked: false,
        }
      } else {
        return this.defaultCurrenctForInput
      }
    },
  },

  created() {},
  mounted() {
    this.form.amount = this.dataModalAddPaidDocument.amount
  },

  methods: {
    ...mapActions('taxReturns', ['updatedModalDataRifIsr']),
    ...mapActions('modal', ['closeModal']),

    saveExceso() {
      this.updatedModalDataRifIsr(this.form)
      this.closeCategoryModal();
    },
    closeCategoryModal() {
      this.closeModal()
    },
  },
}
</script>
