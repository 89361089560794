var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.invoice
    ? _c("div", { staticClass: "main-content" }, [
        _c("div", { staticClass: "page-header" }, [
          _c("h3", { staticClass: "page-title" }, [
            _vm.invoice.serie
              ? _c("span", [_vm._v(_vm._s(_vm.invoice.serie) + " - ")])
              : _vm._e(),
            _vm._v(
              "\n       " + _vm._s(_vm.invoice.invoice_number) + "\n     "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "p-2" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c("div", { staticClass: "table-responsive" }, [
                  _c("table", { staticClass: "table invoice-info" }, [
                    _c("tbody", [
                      _c("tr", [
                        _c("td", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.$t("invoices.serie")) + ":"),
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(
                            "\n                     " +
                              _vm._s(_vm.invoice.serie) +
                              "\n                   "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("strong", [
                            _vm._v(
                              _vm._s(_vm.$t("invoices.invoice_number")) + ": "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(
                            "\n                     " +
                              _vm._s(_vm.invoice.invoice_number) +
                              "\n                   "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("strong", [_vm._v("UUID: ")]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(
                            "\n                     " +
                              _vm._s(_vm.invoice.uuid) +
                              "\n                   "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c(
                          "td",
                          [
                            _c("strong", [
                              _vm._v(
                                _vm._s(_vm.$t("invoices.payment_method")) + ":"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("payment-method-catalog", {
                              attrs: { code: _vm.invoice.payment_method },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("strong", [
                              _vm._v(
                                _vm._s(_vm.$t("invoices.way_to_pay")) + ":"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("way-to-pay-catalog", {
                              attrs: { code: _vm.invoice.way_to_pay },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("strong", [
                              _vm._v(
                                _vm._s(_vm.$t("invoices.cfdi_type")) + ":"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("cfdi-type-catalog", {
                              attrs: { code: _vm.invoice.cfdi_type },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.$t("invoices.date"))),
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(
                            "\n                     " +
                              _vm._s(_vm.invoice.invoice_date) +
                              "\n                   "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.$t("invoices.stamped_date"))),
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(
                            "\n                     " +
                              _vm._s(_vm.invoice.stamped_date) +
                              "\n                   "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td"),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-3 text-center" }, [
                _c("strong", [_vm._v(_vm._s(_vm.$t("invoices.issuing")))]),
                _vm._v(" "),
                _c("br"),
                _vm._v("\n           " + _vm._s(_vm.invoice.issuing_rfc) + " "),
                _c("br"),
                _vm._v(
                  "\n           " +
                    _vm._s(_vm.invoice.issuing_name) +
                    "\n         "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-3 text-center" }, [
                _c("strong", [_vm._v(_vm._s(_vm.$t("invoices.receiver")))]),
                _vm._v(" "),
                _c("br"),
                _vm._v(
                  "\n           " + _vm._s(_vm.invoice.receiver_rfc) + " "
                ),
                _c("br"),
                _vm._v(
                  "\n           " +
                    _vm._s(_vm.invoice.receiver_name) +
                    "\n         "
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-7 text-right" }),
          _vm._v(" "),
          _c("div", { staticClass: "col-3 text-right" }, [
            _vm.invoice.id_facturama != null
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function ($event) {
                        return _vm.descargarPDF(_vm.invoice.id_facturama)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n         " +
                        _vm._s(_vm.$t("general.download_pdf")) +
                        " Facturama\n       "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-2 text-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                on: {
                  click: function ($event) {
                    return _vm.showApplyPaymentModal()
                  },
                },
              },
              [
                _vm._v(
                  "\n         " +
                    _vm._s(_vm.$t("invoices.apply_payment")) +
                    "\n       "
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card mt-2" }, [
          _c("div", { staticClass: "table-responsive" }, [
            _c("table", { staticClass: "table" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("invoices.date")))]),
                  _vm._v(" "),
                  _c("th", [
                    _vm._v(
                      _vm._s(_vm.$t("invoices.account_statement.document_type"))
                    ),
                  ]),
                  _vm._v(" "),
                  _c("th", [
                    _vm._v(
                      _vm._s(_vm.$t("invoices.account_statement.amount_paid"))
                    ),
                  ]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.$t("invoices.remainder")))]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.$t("invoices.percentage")))]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.$t("invoices.iva")))]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.$t("invoices.isr")))]),
                  _vm._v(" "),
                  _c("th"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.documents, function (document) {
                  return _c("tr", { key: document.dom_id }, [
                    _c("td", [_vm._v(_vm._s(document.date))]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        document.link
                          ? _c(
                              "router-link",
                              {
                                staticClass: "link",
                                attrs: { to: document.link },
                              },
                              [
                                _vm._v(
                                  "\n                 " +
                                    _vm._s(document.type) +
                                    "\n               "
                                ),
                              ]
                            )
                          : _c("div", [_vm._v(_vm._s(document.type))]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("money-text", {
                          attrs: { amount: document.related_amount },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("money-text", {
                          attrs: { amount: document.remainder },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(
                        "\n               " +
                          _vm._s(document.percentage) +
                          "\n               %\n             "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("money-text", {
                          attrs: {
                            amount:
                              (_vm.invoice.iva / 100) * document.percentage,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("money-text", {
                          attrs: {
                            amount:
                              document.related_amount -
                              (_vm.invoice.iva / 100) * document.percentage,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("td", [
                      _c(
                        "span",
                        {
                          staticClass: "mr-2",
                          staticStyle: {
                            display: "inline-block",
                            width: "30px",
                            height: "30px",
                          },
                        },
                        [
                          document.id_complement != null
                            ? _c("font-awesome-icon", {
                                staticStyle: { width: "75%", height: "75%" },
                                attrs: { icon: ["fas", "file-alt"] },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          document.id_complement == null &&
                          _vm.invoice.payment_method == "PPD"
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary btn-small",
                                  on: {
                                    click: function ($event) {
                                      return _vm.showEditPaymentModal(document)
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["fas", "sync"] },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      document.sys_type == "paid_document"
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-primary btn-small",
                              on: {
                                click: function ($event) {
                                  return _vm.deletePaidDocument(document)
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["fas", "trash"] },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ])
                }),
                0
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-7 text-right" }),
          _vm._v(" "),
          _c("div", { staticClass: "col-3 text-right" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-2 text-right" },
            [_c("button-category")],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "table-responsive p-2" }, [
            _c("table", [
              _c("thead", [
                _c("tr", { staticClass: "text-uppercase" }, [
                  _c("th", [_vm._v(_vm._s(_vm.$t("invoices.item.title")))]),
                  _vm._v(" "),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t("invoices.item.description"))),
                  ]),
                  _vm._v(" "),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t("invoices.item.clave_prod_serv"))),
                  ]),
                  _vm._v(" "),
                  _c("th", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.$t("invoices.item.quantity"))),
                  ]),
                  _vm._v(" "),
                  _c("th", { staticClass: "text-center amount-cell" }, [
                    _vm.isPayRoll
                      ? _c("span", [
                          _vm._v(
                            "\n                 " +
                              _vm._s(
                                _vm.$t("invoices.complements.total_deductions")
                              ) +
                              "\n               "
                          ),
                        ])
                      : _c("span", [
                          _vm._v(_vm._s(_vm.$t("invoices.item.amount"))),
                        ]),
                  ]),
                  _vm._v(" "),
                  _vm.isPayRoll
                    ? _c("th", [
                        _vm._v(
                          "\n               " +
                            _vm._s(
                              _vm.$t(
                                "invoices.complements.total_other_payments"
                              )
                            ) +
                            "\n             "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("th", { staticClass: "text-center amount-cell" }, [
                    _vm.isPayRoll
                      ? _c("span", [
                          _vm._v(
                            "\n                 " +
                              _vm._s(
                                _vm.$t("invoices.complements.total_perceptions")
                              ) +
                              "\n               "
                          ),
                        ])
                      : _c("span", [
                          _vm._v(_vm._s(_vm.$t("invoices.discount"))),
                        ]),
                  ]),
                  _vm._v(" "),
                  _c("th", { staticClass: "text-center amount-cell" }, [
                    !_vm.isPayRoll ? _c("span", [_vm._v("%")]) : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("th", { staticClass: "text-center amount-cell" }, [
                    !_vm.isPayRoll
                      ? _c("span", [_vm._v(_vm._s(_vm.$t("invoices.iva")))])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("th", { staticClass: "text-center amount-cell" }, [
                    _vm._v(
                      "\n               " +
                        _vm._s(_vm.$t("invoices.item.total")) +
                        "\n             "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("th", { staticClass: "text-center amount-cell" }, [
                    _c("input", {
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked:
                          _vm.categoriesSelect.length == _vm.concepts.length,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.checkCategoryAll()
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.$tc("invoices.category", 1)))]),
                  _vm._v(" "),
                  _c("th", { staticClass: "text-center" }, [
                    _vm._v(
                      "\n               " +
                        _vm._s(_vm.$tc("invoices.tax", 1)) +
                        "\n               "
                    ),
                    _c("input", {
                      ref: "inpuestoCheckbox",
                      attrs: { type: "checkbox" },
                      domProps: { checked: _vm.isInpuestoSelect() },
                      on: {
                        change: function ($event) {
                          return _vm.checkInpuestoAll()
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                [
                  _vm._l(_vm.concepts, function (item) {
                    return [
                      _c("tr", { key: item.uid, staticClass: "border-top" }, [
                        _c(
                          "td",
                          [
                            _c("sub-string", {
                              attrs: { string: item.name, length: 25 },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("sub-string", {
                              attrs: { string: item.description, length: 30 },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                 " +
                              _vm._s(item.clave_prod_serv) +
                              "\n               "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n                 " +
                              _vm._s(item.quantity) +
                              "\n               "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _vm.isPayRoll
                            ? _c(
                                "span",
                                [
                                  _c("money-text", {
                                    attrs: {
                                      amount: Number(
                                        _vm.invoice.complement_nomina
                                          .total_deductions
                                      ),
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "span",
                                [
                                  _c("money-text", {
                                    attrs: { amount: Number(item.total) },
                                  }),
                                ],
                                1
                              ),
                        ]),
                        _vm._v(" "),
                        _vm.isPayRoll
                          ? _c(
                              "td",
                              [
                                _c("money-text", {
                                  attrs: {
                                    amount: Number(
                                      _vm.invoice.complement_nomina
                                        .total_other_payments
                                    ),
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _vm.isPayRoll
                            ? _c(
                                "span",
                                [
                                  _c("money-text", {
                                    attrs: {
                                      amount: Number(
                                        _vm.invoice.complement_nomina
                                          .total_perceptions
                                      ),
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "span",
                                [
                                  _c("money-text", {
                                    attrs: {
                                      amount: Number(item.discount_val),
                                    },
                                  }),
                                ],
                                1
                              ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n                 " +
                              _vm._s(
                                parseFloat(
                                  (item.iva * 100) / item.total
                                ).toFixed(2)
                              ) +
                              "\n               "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-center" },
                          [
                            _c("money-text", {
                              attrs: { amount: Number(item.iva) },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-center" },
                          [
                            _c("money-text", {
                              attrs: {
                                amount: Number(item.total) + Number(item.iva),
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _c("input", {
                            attrs: { type: "checkbox" },
                            domProps: { checked: _vm.isCategorySelected(item) },
                            on: {
                              change: function ($event) {
                                return _vm.checkCategory(item)
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-center" },
                          [
                            _c("concept-category", {
                              attrs: { "invoice-concept": item },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", [
                          _c("div", { staticClass: "text-center" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: item.in_tax_return,
                                  expression: "item.in_tax_return",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(item.in_tax_return)
                                  ? _vm._i(item.in_tax_return, null) > -1
                                  : item.in_tax_return,
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$a = item.in_tax_return,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            item,
                                            "in_tax_return",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            item,
                                            "in_tax_return",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(item, "in_tax_return", $$c)
                                    }
                                  },
                                  function ($event) {
                                    return _vm.inTaxReturn(item)
                                  },
                                ],
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                attrs: { href: "#/" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openModelComment(
                                      item.id,
                                      item.comments_in_tax_return
                                    )
                                  },
                                },
                              },
                              [
                                item.comments_in_tax_return == null
                                  ? _c("font-awesome-icon", {
                                      staticClass: "right-icon text-secondary",
                                      attrs: { icon: "comment" },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                item.comments_in_tax_return == ""
                                  ? _c("font-awesome-icon", {
                                      staticClass: "right-icon text-secondary",
                                      attrs: { icon: "comment" },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                item.comments_in_tax_return != null
                                  ? _c("font-awesome-icon", {
                                      staticClass: "right-icon text-blue",
                                      attrs: { icon: "comment" },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.modelComment
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "transition",
                                      { attrs: { name: "model" } },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "modal-mask" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "modal-wrapper" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "modal-dialog",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "modal-content",
                                                        staticStyle: {
                                                          width: "115%",
                                                          "font-size": "12px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "modal-header",
                                                          },
                                                          [
                                                            _c(
                                                              "h4",
                                                              {
                                                                staticClass:
                                                                  "modal-title",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Comentarios"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "modal-body",
                                                          },
                                                          [
                                                            _c("table", [
                                                              _c(
                                                                "tbody",
                                                                [
                                                                  _vm._l(
                                                                    _vm.comments_in_tax_return,
                                                                    function (
                                                                      item
                                                                    ) {
                                                                      return [
                                                                        _c(
                                                                          "tr",
                                                                          [
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.fecha
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.user
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "td",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.comentario
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    }
                                                                  ),
                                                                ],
                                                                2
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "form-group",
                                                              },
                                                              [
                                                                _c("textarea", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm.comment,
                                                                      expression:
                                                                        "comment",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-control",
                                                                  attrs: {
                                                                    type: "text",
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      _vm.comment,
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        )
                                                                          return
                                                                        _vm.comment =
                                                                          $event.target.value
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c("br"),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                attrs: {
                                                                  align:
                                                                    "center",
                                                                },
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm.id_comment,
                                                                      expression:
                                                                        "id_comment",
                                                                    },
                                                                  ],
                                                                  attrs: {
                                                                    type: "hidden",
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      _vm.id_comment,
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        )
                                                                          return
                                                                        _vm.id_comment =
                                                                          $event.target.value
                                                                      },
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _c("input", {
                                                                  staticClass:
                                                                    "btn btn-secondary btn-xs",
                                                                  attrs: {
                                                                    type: "button",
                                                                    value:
                                                                      "Cancelar",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.modelComment = false
                                                                      },
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _c("input", {
                                                                  staticClass:
                                                                    "btn btn-success btn-xs",
                                                                  attrs: {
                                                                    type: "button",
                                                                    value:
                                                                      "Guardar",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.commentInTaxReturn()
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                    ]
                  }),
                  _vm._v(" "),
                  _vm.isPayRoll
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "8" } }),
                        _vm._v(" "),
                        _c("td", [
                          _c("strong", [
                            _vm._v(
                              "\n                 " +
                                _vm._s(
                                  _vm.$t(
                                    "invoices.complements.total_deductions"
                                  )
                                ) +
                                "\n               "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: {
                                amount: Number(
                                  _vm.invoice.complement_nomina.total_deductions
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isPayRoll
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "8" } }),
                        _vm._v(" "),
                        _c("td", [
                          _c("strong", [
                            _vm._v(
                              "\n                 " +
                                _vm._s(
                                  _vm.$t(
                                    "invoices.complements.total_other_payments"
                                  )
                                ) +
                                "\n               "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: {
                                amount: Number(
                                  _vm.invoice.complement_nomina
                                    .total_other_payments
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isPayRoll
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "8" } }),
                        _vm._v(" "),
                        _c("td", [
                          _c("strong", [
                            _vm._v(
                              "\n                 " +
                                _vm._s(
                                  _vm.$t(
                                    "invoices.complements.total_perceptions"
                                  )
                                ) +
                                "\n               "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: {
                                amount: Number(
                                  _vm.invoice.complement_nomina
                                    .total_perceptions
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isPayRoll
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "8" } }),
                        _vm._v(" "),
                        _c("td", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.$t("invoices.sub_total"))),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: { amount: Number(_vm.invoice.sub_total) },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isPayRoll
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "8" } }),
                        _vm._v(" "),
                        _c("td", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.$t("invoices.discount"))),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: { amount: Number(_vm.invoice.discount) },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isPayRoll
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: "8" } }),
                        _vm._v(" "),
                        _c("td", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.$t("invoices.iva"))),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: { amount: Number(_vm.invoice.iva) },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", { attrs: { colspan: "8" } }),
                    _vm._v(" "),
                    _c("td", [
                      _c("strong", [_vm._v(_vm._s(_vm.$t("invoices.total")))]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "text-right" },
                      [
                        _c("money-text", {
                          attrs: { amount: Number(_vm.invoice.total) },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm.invoice.invoice_taxes.length
          ? _c("div", { staticClass: "card p-2" }, [
              _c("div", { staticClass: "table-responsive" }, [
                _c("table", { staticClass: "table table-bordered" }, [
                  _c("thead", [
                    _c("tr", [
                      _c(
                        "th",
                        {
                          staticClass: "text-center",
                          staticStyle: { width: "20%" },
                        },
                        [
                          _vm._v(
                            "\n               " +
                              _vm._s(_vm.$t("invoices.tax_table.tax")) +
                              "\n             "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticClass: "text-center",
                          staticStyle: { width: "20%" },
                        },
                        [
                          _vm._v(
                            "\n               " +
                              _vm._s(_vm.$t("invoices.tax_table.type")) +
                              "\n             "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticClass: "text-center",
                          staticStyle: { width: "20%" },
                        },
                        [_vm._v("Factor")]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticClass: "text-center",
                          staticStyle: { width: "20%" },
                        },
                        [_vm._v("Tasa")]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticClass: "text-center",
                          staticStyle: { width: "20%" },
                        },
                        [
                          _vm._v(
                            "\n               " +
                              _vm._s(_vm.$t("invoices.tax_table.amount")) +
                              "\n             "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.invoice.invoice_taxes, function (tax) {
                      return _c("tr", { key: tax.id }, [
                        _c(
                          "td",
                          { staticClass: "text-center" },
                          [
                            _c("tax-catalog", {
                              attrs: { "tax-code": tax.tax },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(tax.type.toUpperCase())),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n               " +
                              _vm._s(
                                tax.factor == null
                                  ? "-"
                                  : tax.factor.toUpperCase()
                              ) +
                              "\n             "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n               " +
                              _vm._s(tax.rate == null ? "-" : tax.rate) +
                              "\n             "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-center" },
                          [
                            _c("money-text", {
                              attrs: { amount: Number(tax.amount) },
                            }),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.invoice.related_invoices
          ? _c("div", { staticClass: "card" }, [
              _c(
                "h5",
                { staticClass: "complement-title" },
                [
                  _c("c-relation-type", {
                    attrs: { code: _vm.invoice.related_invoices.relation_type },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("table", { staticClass: "table invoice-info" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.$t("invoices.uuid"))),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(
                    _vm.invoice.related_invoices.invoices,
                    function (uuid) {
                      return _c("tr", { key: uuid }, [
                        _c(
                          "td",
                          { staticClass: "text-center" },
                          [_c("uuid-link", { attrs: { uuid: uuid } })],
                          1
                        ),
                      ])
                    }
                  ),
                  0
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.invoice.complement_payments.length
          ? _c(
              "div",
              { staticClass: "card p-2" },
              [
                _c("h5", { staticClass: "complement-title" }, [
                  _vm._v(
                    "\n       " +
                      _vm._s(
                        _vm.$t("invoices.complements.complement_payments")
                      ) +
                      "\n     "
                  ),
                ]),
                _vm._v(" "),
                _vm._l(
                  _vm.invoice.complement_payments,
                  function (complementPayment) {
                    return _c(
                      "div",
                      { key: complementPayment.id, staticClass: "mb-4" },
                      [
                        _c("div", { staticClass: "table-responsive" }, [
                          _c("table", { staticClass: "invoice-info" }, [
                            _c("thead", [
                              _c("tr", [
                                _c("th", { staticClass: "text-center" }, [
                                  _vm._v(
                                    "\n                 " +
                                      _vm._s(
                                        _vm.$t(
                                          "invoices.complements.payment_date"
                                        )
                                      ) +
                                      "\n               "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("th", { staticClass: "text-center" }, [
                                  _vm._v(
                                    "\n                 " +
                                      _vm._s(
                                        _vm.$t(
                                          "invoices.complements.payment_method"
                                        )
                                      ) +
                                      "\n               "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("th", { staticClass: "text-center" }, [
                                  _vm._v(
                                    "\n                 " +
                                      _vm._s(
                                        _vm.$t("invoices.complements.currency")
                                      ) +
                                      "\n               "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  { staticClass: "text-center amount-cell-md" },
                                  [
                                    _vm._v(
                                      "\n                 " +
                                        _vm._s(
                                          _vm.$t("invoices.complements.amount")
                                        ) +
                                        "\n               "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("tbody", [
                              _c("tr", [
                                _c("td", { staticClass: "text-center" }, [
                                  _vm._v(
                                    "\n                 " +
                                      _vm._s(complementPayment.payment_date) +
                                      "\n               "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-center" }, [
                                  _vm._v(
                                    "\n                 " +
                                      _vm._s(complementPayment.payment_method) +
                                      "\n               "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-center" }, [
                                  _vm._v(
                                    "\n                 " +
                                      _vm._s(complementPayment.currency) +
                                      "\n               "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { staticClass: "text-right" },
                                  [
                                    _c("money-text", {
                                      attrs: {
                                        amount: Number(
                                          complementPayment.amount
                                        ),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "table-responsive mt-2 mb-4" },
                          [
                            _c(
                              "table",
                              { staticClass: "table-bordered invoice-info" },
                              [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("invoices.complements.uuid")
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("invoices.complements.serie")
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "invoices.complements.invoice_number"
                                          )
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("th", { staticClass: "amount-cell" }, [
                                      _vm._v(
                                        "\n                 " +
                                          _vm._s(
                                            _vm.$t(
                                              "invoices.complements.currency"
                                            )
                                          ) +
                                          "\n               "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("th", { staticClass: "amount-cell" }, [
                                      _vm._v(
                                        "\n                 " +
                                          _vm._s(
                                            _vm.$t(
                                              "invoices.complements.payment_method"
                                            )
                                          ) +
                                          "\n               "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("th", { staticClass: "amount-cell" }, [
                                      _vm._v(
                                        "\n                 " +
                                          _vm._s(
                                            _vm.$t(
                                              "invoices.complements.partiality"
                                            )
                                          ) +
                                          "\n               "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        staticClass:
                                          "text-center amount-cell-md",
                                      },
                                      [
                                        _vm._v(
                                          "\n                 " +
                                            _vm._s(
                                              _vm.$t(
                                                "invoices.complements.previous_balance"
                                              )
                                            ) +
                                            "\n               "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        staticClass:
                                          "text-center amount-cell-md",
                                      },
                                      [
                                        _vm._v(
                                          "\n                 " +
                                            _vm._s(
                                              _vm.$t(
                                                "invoices.complements.amount_payment"
                                              )
                                            ) +
                                            "\n               "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        staticClass:
                                          "text-center amount-cell-md",
                                      },
                                      [
                                        _vm._v(
                                          "\n                 " +
                                            _vm._s(
                                              _vm.$t(
                                                "invoices.complements.outstanding_balance"
                                              )
                                            ) +
                                            "\n               "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "tbody",
                                  _vm._l(
                                    complementPayment.related_invoices,
                                    function (relatedInvoice) {
                                      return _c(
                                        "tr",
                                        { key: relatedInvoice.id },
                                        [
                                          _c(
                                            "td",
                                            [
                                              _c("uuid-link", {
                                                attrs: {
                                                  uuid: relatedInvoice.uuid,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(relatedInvoice.serie)
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                relatedInvoice.invoice_number
                                              )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(relatedInvoice.currency)
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                relatedInvoice.payment_method
                                              )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(relatedInvoice.partiality)
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c("money-text", {
                                                attrs: {
                                                  amount: Number(
                                                    relatedInvoice.previous_balance
                                                  ),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c("money-text", {
                                                attrs: {
                                                  amount: Number(
                                                    relatedInvoice.amount_payment
                                                  ),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c("money-text", {
                                                attrs: {
                                                  amount: Number(
                                                    relatedInvoice.outstanding_balance
                                                  ),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  }
                ),
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.invoice.cfdi_type == "N"
          ? _c("div", [
              _vm.invoice.complement_nomina
                ? _c("div", { staticClass: "card p-2" }, [
                    _c("h5", { staticClass: "complement-title" }, [
                      _vm._v(
                        "\n         " +
                          _vm._s(
                            _vm.$t("invoices.complements.complement_nomina")
                          ) +
                          "\n       "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "table-responsive" }, [
                      _c("table", { staticClass: "table table-bordered" }, [
                        _c("thead", [
                          _c("tr", [
                            _c("th", { staticClass: "text-center" }, [
                              _vm._v(
                                "\n                 " +
                                  _vm._s(
                                    _vm.$t("invoices.complements.payment_date")
                                  ) +
                                  "\n               "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("th", { staticClass: "text-center" }, [
                              _vm._v(
                                "\n                 " +
                                  _vm._s(
                                    _vm.$t(
                                      "invoices.complements.payment_start_date"
                                    )
                                  ) +
                                  "\n               "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("th", { staticClass: "text-center" }, [
                              _vm._v(
                                "\n                 " +
                                  _vm._s(
                                    _vm.$t(
                                      "invoices.complements.payment_end_date"
                                    )
                                  ) +
                                  "\n               "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("th", { staticClass: "text-center" }, [
                              _vm._v(
                                "\n                 " +
                                  _vm._s(
                                    _vm.$t(
                                      "invoices.complements.total_deductions"
                                    )
                                  ) +
                                  "\n               "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("th", { staticClass: "text-center" }, [
                              _vm._v(
                                "\n                 " +
                                  _vm._s(
                                    _vm.$t(
                                      "invoices.complements.total_other_payments"
                                    )
                                  ) +
                                  "\n               "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("th", { staticClass: "text-center" }, [
                              _vm._v(
                                "\n                 " +
                                  _vm._s(
                                    _vm.$t(
                                      "invoices.complements.total_perceptions"
                                    )
                                  ) +
                                  "\n               "
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("tbody", [
                          _c("tr", [
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(
                                "\n                 " +
                                  _vm._s(
                                    _vm.invoice.complement_nomina.payment_date
                                  ) +
                                  "\n               "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(
                                "\n                 " +
                                  _vm._s(
                                    _vm.invoice.complement_nomina
                                      .payment_start_date
                                  ) +
                                  "\n               "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-center" }, [
                              _vm._v(
                                "\n                 " +
                                  _vm._s(
                                    _vm.invoice.complement_nomina
                                      .payment_end_date
                                  ) +
                                  "\n               "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "text-center" },
                              [
                                _c("money-text", {
                                  attrs: {
                                    amount:
                                      _vm.invoice.complement_nomina
                                        .total_deductions,
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "text-center" },
                              [
                                _c("money-text", {
                                  attrs: {
                                    amount:
                                      _vm.invoice.complement_nomina
                                        .total_other_payments,
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "text-center" },
                              [
                                _c("money-text", {
                                  attrs: {
                                    amount:
                                      _vm.invoice.complement_nomina
                                        .total_perceptions,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12 col-md-6" }, [
                        _c("div", { staticClass: "table-responsive" }, [
                          _c("table", { staticClass: "table table-bordered" }, [
                            _c("thead", [
                              _c("tr", [
                                _c("th", { staticClass: "text-center" }, [
                                  _vm._v(
                                    "\n                     " +
                                      _vm._s(
                                        _vm.$t(
                                          "invoices.complements.perception"
                                        )
                                      ) +
                                      "\n                   "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("th", { staticClass: "text-center" }, [
                                  _vm._v(
                                    "\n                     " +
                                      _vm._s(
                                        _vm.$t("invoices.complements.amount")
                                      ) +
                                      "\n                   "
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              [
                                _vm._l(
                                  _vm.invoice.complement_nomina.perceptions,
                                  function (perception) {
                                    return _c("tr", { key: perception.id }, [
                                      _c(
                                        "td",
                                        { staticClass: "text-center" },
                                        [
                                          _c("cfdi-perception-deduction-type", {
                                            attrs: {
                                              code: perception.perception_type,
                                              type: "perception",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "text-center" },
                                        [
                                          _c("money-text", {
                                            attrs: {
                                              amount: perception.taxed_amount,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ])
                                  }
                                ),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.invoice.complement_nomina.other_payments,
                                  function (otherPayment) {
                                    return _c("tr", { key: otherPayment.id }, [
                                      _c(
                                        "td",
                                        { staticClass: "text-center" },
                                        [
                                          _c("cfdi-perception-deduction-type", {
                                            attrs: {
                                              code: otherPayment.other_payment_type,
                                              type: "other_payment",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "text-center" },
                                        [
                                          _c("money-text", {
                                            attrs: {
                                              amount: otherPayment.amount,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ])
                                  }
                                ),
                              ],
                              2
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-md-6" }, [
                        _c("div", { staticClass: "table-responsive" }, [
                          _c("table", { staticClass: "table table-bordered" }, [
                            _c("thead", [
                              _c("tr", [
                                _c("th", { staticClass: "text-center" }, [
                                  _vm._v(
                                    "\n                     " +
                                      _vm._s(
                                        _vm.$t("invoices.complements.deduction")
                                      ) +
                                      "\n                   "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("th", { staticClass: "text-center" }, [
                                  _vm._v(
                                    "\n                     " +
                                      _vm._s(
                                        _vm.$t("invoices.complements.amount")
                                      ) +
                                      "\n                   "
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              _vm._l(
                                _vm.invoice.complement_nomina.deductions,
                                function (deduction) {
                                  return _c("tr", { key: deduction.id }, [
                                    _c(
                                      "td",
                                      { staticClass: "text-center" },
                                      [
                                        _c("cfdi-perception-deduction-type", {
                                          attrs: {
                                            code: deduction.deduction_type,
                                            type: "deduction",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      { staticClass: "text-center" },
                                      [
                                        _c("money-text", {
                                          attrs: { amount: deduction.amount },
                                        }),
                                      ],
                                      1
                                    ),
                                  ])
                                }
                              ),
                              0
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.items
          ? _c("div", { staticClass: "card p-2" }, [
              _c("h5", { staticClass: "complement-title" }, [
                _vm._v(
                  "\n       " +
                    _vm._s(_vm.$t("invoices.complements.complement_payments")) +
                    "\n     "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "table-responsive" }, [
                _c("table", { staticClass: "table table-bordered" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", { staticClass: "text-center" }, [
                        _vm._v("UUID"),
                      ]),
                      _vm._v(" "),
                      _c("th", { staticClass: "text-center" }, [
                        _vm._v(
                          "\n               " +
                            _vm._s(
                              _vm.$t("invoices.complements.payment_date")
                            ) +
                            "\n             "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("th", { staticClass: "text-center" }, [
                        _vm._v(
                          "\n               " +
                            _vm._s(
                              _vm.$t("invoices.complements.payment_method")
                            ) +
                            "\n             "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("th", { staticClass: "text-center" }, [
                        _vm._v(
                          "\n               " +
                            _vm._s(_vm.$t("invoices.complements.currency")) +
                            "\n             "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("th", { staticClass: "text-center amount-cell-md" }, [
                        _vm._v(
                          "\n               " +
                            _vm._s(_vm.$t("invoices.complements.amount")) +
                            "\n             "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("th", { staticClass: "text-center amount-cell-md" }, [
                        _vm._v("Acciones"),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.items, function (complementPaymentItems) {
                      return _c("tr", { key: complementPaymentItems.id }, [
                        _c(
                          "td",
                          { staticClass: "text-center" },
                          [
                            _c("uuid-link", {
                              attrs: { uuid: complementPaymentItems.uuid },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n               " +
                              _vm._s(
                                complementPaymentItems.complement_payment
                                  .payment_date
                              ) +
                              "\n             "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n               " +
                              _vm._s(
                                complementPaymentItems.complement_payment
                                  .payment_method
                              ) +
                              "\n             "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n               " +
                              _vm._s(
                                complementPaymentItems.complement_payment
                                  .currency
                              ) +
                              "\n             "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-center" },
                          [
                            _c("money-text", {
                              attrs: {
                                amount: Number(
                                  complementPaymentItems.complement_payment
                                    .amount
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-center", attrs: { td: "" } },
                          [
                            complementPaymentItems.paid_document_id == null
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    on: {
                                      click: function ($event) {
                                        _vm.showApplyPaymentComplementModal(
                                          Number(
                                            complementPaymentItems
                                              .complement_payment.amount
                                          ),
                                          complementPaymentItems
                                            .complement_payment.payment_date,
                                          complementPaymentItems.uuid
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                 " +
                                        _vm._s(
                                          _vm.$t("invoices.apply_payment")
                                        ) +
                                        "\n               "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }