<template>
  <div class="payments main-content">
    <div class="page-header mt-5">
      <h3 class="page-title">
        Nomina
        | {{ filters.month['name'] }},
        {{ filters.year }}
      </h3>
      <!-- <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link
            slot="item-title"
            to="dashboard">
            {{ $t('general.home') }}
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link
            slot="item-title"
            to="#">
            {{ $tc('payments.payment',2) }}
          </router-link>
        </li>
      </ol> -->
      <!-- <div class="page-actions row">
        <div class="col-xs-2 mr-4">
          <base-button
            v-show="false"
            :outline="true"
            :icon="filterIcon"
            color="theme"
            right-icon
            size="large"
            @click="toggleFilter"
          >
            {{ $t('general.filter') }}
          </base-button>
        </div>
        <div class="col-xs-2 mr-2">
          <router-link
            slot="item-title"
            :to="{
              name: 'general-public-invocie.index',
              params: { type: 'payments' },
            }"
            class="col-xs-2"
          >
            <base-button
              v-tooltip.top-center="
                $t('payments.general_public_invoices.title')
              "
              :outline="true"
              color="theme"
              icon="file-alt"
              size="large"
            >
              {{ $t('payments.general_public_invoices.button') }}
              {{ currentCustomer.csd_expires == 1 ? 'CSD EXPIRÓ' : '' }}
            </base-button>
          </router-link>
        </div>
        <div class="col-xs-2 mr-2">
          <router-link
            slot="item-title"
            :to="{ name: 'create-invocie.index', params: { type: 'payments' } }"
            class="col-xs-2"
          >
            <base-button
              v-tooltip.top-center="$t('payments.invoice')"
              :outline="true"
              color="theme"
              icon="file-alt"
              size="large"
            >
              {{ $t('payments.invoice') }}
              {{ currentCustomer.csd_expires == 1 ? 'CSD EXPIRÓ' : '' }}
            </base-button>
          </router-link>
        </div>
        <router-link slot="item-title" class="col-xs-2" to="payments/create">
          <base-button color="theme" icon="plus" size="large">
            {{ $t('payments.add_payment') }}
          </base-button>
        </router-link>
      </div> -->
    </div>

    <!--<transition name="fade" mode="out-in">
      <div v-show="showFilters" class="filter-section">
        <div class="row">
           <div class="col-md-3">
            <label class="form-label">{{ $t('payments.customer') }}</label>
            <base-select
              v-model="filters.customer"
              :options="customers"
              :searchable="true"
              :show-labels="false"
              :placeholder="$t('expenses.select_a_customer')"
              label="name"
              @click="filter = ! filter"
            />
          </div>
          <div class="col-sm-3">
            <label class="form-label">{{ $t('RFC') }}</label>
            <base-select
              v-model="filters.customer"
              :options="customers"
              :searchable="true"
              :show-labels="false"
              :placeholder="$t('RFC')"
              label="rfc"
              @click="filter = ! filter"
            />
          </div>
                   <div class="col-sm-3">
            <label class="form-label">{{ $t('payments.category') }}</label>
            <base-select
              v-model="filters.typeOption"
              :options="typeOptions"
              :searchable="true"
              :show-labels="false"
              :placeholder="$t('payments.payment_type')"
              label="name"
              @click="filter = ! filter"
            />
          </div> 
          <div class="col-sm-3">
            <label class="form-label">{{ filters.month['name'] }}</label>
            <base-select
              v-model="filters.month"
              :options="months"
              :searchable="true"
              :show-labels="false"
              :placeholder="$t('invoices.months_plain')"
              label="name"
              @click="filter = !filter"
              disabled
            />
          </div>
          <div class="col-md-3">
            <label>{{ filters.year }}</label>
            <base-input
              v-model="filters.year"
              type="number"
              name="year"
              disabled
            />
          </div>
                   <div class="col-md-3">
            <label>{{ $t('payments.total') }}</label>
            <base-input
              v-model="filters.total"
              type="text"
              name="total"
              autocomplete="on"
            />
          </div> 
        </div>
 
      </div>
    </transition> -->

    <div
      v-cloak
      v-show="showEmptyScreen"
      class="col-xs-1 no-data-info"
      align="center"
    >
      <capsule-icon class="mt-5 mb-4" />
      <div class="row" align="center">
        <label class="col title">{{ $t('payments.no_payments') }}</label>
      </div>
      <div class="row">
        <label class="description col mt-1" align="center">{{
          $t('payments.list_of_payments')
        }}</label>
      </div>
      <div class="btn-container">
        <base-button
          :outline="true"
          color="theme"
          class="mt-3"
          size="large"
          @click="$router.push('payments/create')"
        >
          {{ $t('payments.add_new_payment') }}
        </base-button>
      </div>
    </div>

    <div class="row">
      <div class="col col-md-8">
        <div class="row">
          <div class="col col-md-6">
            <div class="text-right">
              <strong>{{ $t('payments.sub_total') }}</strong>
              <money-text :amount="Number(paymentsTotals.subtotal)" />
            </div>
            <div class="text-right">
              <strong>{{ $t('payments.retention_isr') }}</strong>
              <money-text :amount="Number(paymentsTotals.retention_isr)" />
            </div>
          </div>
          <div class="col col-md-6">
            <div class="text-right">
              <strong>{{ $t('payments.iva') }}</strong>
              <money-text :amount="Number(paymentsTotals.iva)" />
            </div>
            <div class="text-right">
              <strong>{{ $t('payments.retention_iva') }}</strong>
              <money-text :amount="Number(paymentsTotals.retention_iva)" />
            </div>
            <div class="text-right">
              <strong>{{ $t('payments.total') }}</strong>
              <money-text :amount="Number(paymentsTotals.total)" />
            </div>
          </div>
        </div>
      </div>
      <div class="col col-md-4">
        <div class="form-group">
          <label>
            {{ $t('general.name_or_rfc') }}
          </label>
          <base-input v-model="filters.nameOrRFC" type="text" />
        </div>
      </div>
    </div>

    <div v-show="!showEmptyScreen" class="table-container">
      <div class="table-actions mt-5">
        <p class="table-stats">
          {{ $t('general.showing') }}: <b>{{ payments.length }}</b>
          {{ $t('general.of') }} <b>{{ totalPayments }}</b>
        </p>

        <transition name="fade">
          <v-dropdown v-if="selectedPayments.length" :show-arrow="false">
            <span
              slot="activator"
              href="#"
              class="table-actions-button dropdown-toggle"
            >
              {{ $t('general.actions') }}
            </span>
            <v-dropdown-item>
              <div class="dropdown-item" @click="removeMultiplePayments">
                <font-awesome-icon
                  :icon="['fas', 'trash']"
                  class="dropdown-item-icon"
                />
                {{ $t('general.delete') }}
              </div>
            </v-dropdown-item>
          </v-dropdown>
        </transition>
      </div>

      <table-component
        ref="table"
        :data="fetchData"
        :filter-no-results="$t('general.filterNoResults')"
        :is-clickable="true"
        :show-filter="false"
        table-class="table"
        @columnClick="rowClicked"
      >
        <table-column
          :sortable="false"
          :filterable="false"
          cell-class="no-click"
        >
          <template slot-scope="row">
            <div v-if="row.cfdi_type == 'N'" class="">
              <img class="nomina-icon" src="/images/nomina_icon.svg" />
            </div>
          </template>
        </table-column>
        <table-column
          :filterable="true"
          :label="$t('payments.date')"
          show="date"
        />
        <table-column
          :label="$t('payments.payment_number')"
          show="invoice_number"
        />
        <table-column :label="$t('expenses.provider')" show="receiver_name">
          <template slot-scope="row">
            <span>{{ $t('expenses.provider') }}</span>
            <sub-string
              v-if="row.receiver_rfc == currentCustomer.rfc"
              :string="row.issuing_name"
            />
            <sub-string v-else :string="row.receiver_name" />
          </template>
        </table-column>
        <table-column :label="$t('expenses.provider_rfc')" show="receiver_rfc">
          <template slot-scope="row">
            <span>{{ $t('expenses.provider_rfc') }}</span>
            <sub-string
              v-if="row.receiver_rfc == currentCustomer.rfc"
              :string="row.issuing_rfc"
            />
            <sub-string v-else :string="row.receiver_rfc" />
          </template>
        </table-column>
        <table-column :label="$t('payments.type')" show="type" />
        <table-column :label="$t('payments.sub_total')" show="sub_total">
          <template slot-scope="row">
            <span>{{ $t('payments.sub_total') }}</span>
            <div v-html="$utils.formatMoney(row.sub_total, defaultCurrency)" />
          </template>
        </table-column>

        <table-column :label="$t('payments.iva')" show="iva">
          <template slot-scope="row">
            <span>{{ $t('payments.iva') }}</span>
            <div v-html="$utils.formatMoney(row.iva, defaultCurrency)" />
          </template>
        </table-column>

        <table-column
          :label="$t('payments.retention_iva')"
          show="retention_iva"
        >
          <template slot-scope="row">
            <span>{{ $t('payments.retention_iva') }}</span>
            <div
              v-html="$utils.formatMoney(row.retention_iva, defaultCurrency)"
            />
          </template>
        </table-column>

        <table-column
          :label="$t('payments.retention_isr')"
          show="retention_isr"
        >
          <template slot-scope="row">
            <span>{{ $t('payments.retention_isr') }}</span>
            <div
              v-html="$utils.formatMoney(row.retention_isr, defaultCurrency)"
            />
          </template>
        </table-column>

        <table-column :label="$t('payments.total')" show="amount">
          <template slot-scope="row">
            <span>{{ $t('payments.total') }}</span>
            <div v-html="$utils.formatMoney(row.amount, defaultCurrency)" />
          </template>
        </table-column>
        <table-column
          :label="$t('invoices.account_statement_abbr')"
          show="account_statement"
        >
          <template slot-scope="row">
            <span>{{ $t('invoices.account_statement_abbr') }}</span>
            <icon-in-tax-return
              v-if="row.type != 'Efectivo'"
              :status="row.account_statement"
              module="account_statement"
            />
          </template>
        </table-column>
        <table-column
          :label="$t('payments.general_public_invoices.abbr')"
          :sortable="false"
          :filterable="false"
          show="general_public_invoice"
          cell-class="no-click"
        >
          <template slot-scope="row">
            <span>
              {{ $t('payments.general_public_invoices.abbr') }}
            </span>
            <div class="text-center" v-if="row.type == 'Efectivo'">
              <input
                :checked="row.general_public_invoice == '1'"
                type="checkbox"
                class="form-check-input"
                value="1"
                @change="toggleRowGeneralPublicInvoice(row)"
              />
            </div>
          </template>
        </table-column>
        <table-column
          :sortable="false"
          :filterable="false"
          cell-class="action-dropdown no-click"
        >
          <!-- Dropdown Efectivo -->
          <template v-if="row.type == 'Efectivo'" slot-scope="row">
            <span>{{ $t('payments.action') }}</span>
            <v-dropdown>
              <a slot="activator" href="#">
                <dot-icon />
              </a>
              <!-- <v-dropdown-item>
                <router-link :to="{path: `payments/${row.id}/edit`}" class="dropdown-item">
                  <font-awesome-icon :icon="['fas', 'pencil-alt']" class="dropdown-item-icon" />
                  {{ $t('general.edit') }}
                </router-link>
              </v-dropdown-item> -->

              <!-- <v-dropdown-item>
                <router-link :to="{path: `payments/${row.id}/view`}" class="dropdown-item">
                  <font-awesome-icon icon="eye" class="dropdown-item-icon" />
                  {{ $t('general.view') }}
                </router-link>
              </v-dropdown-item> -->

              <v-dropdown-item>
                <div class="dropdown-item" @click="removePayment(row.id)">
                  <font-awesome-icon
                    :icon="['fas', 'trash']"
                    class="dropdown-item-icon"
                  />
                  {{ $t('general.delete') }}
                </div>
              </v-dropdown-item>
            </v-dropdown>
          </template>
        </table-column>
      </table-component>

      <br />
      <h5>Facturas anteriores sin aplicar cobro</h5>
      <table-component
        ref="tableWithoutPayment"
        :data="fetchDataWithoutPayment"
        :filter-no-results="$t('general.filterNoResults')"
        :is-clickable="true"
        :show-filter="false"
        table-class="table"
        @columnClick="rowClicked"
      >
        <table-column :label="$t('payments.date')" show="date" />
        <table-column
          :label="$t('payments.payment_number')"
          show="invoice_number"
        />
        <table-column :label="$t('expenses.provider')" show="receiver_name">
          <template slot-scope="row">
            <span>{{ $t('expenses.provider') }}</span>
            <sub-string
              v-if="row.receiver_rfc == currentCustomer.rfc"
              :string="row.issuing_name"
            />
            <sub-string v-else :string="row.receiver_name" />
          </template>
        </table-column>
        <table-column :label="$t('expenses.provider_rfc')" show="receiver_rfc">
          <template slot-scope="row">
            <span>{{ $t('expenses.provider_rfc') }}</span>
            <sub-string
              v-if="row.receiver_rfc == currentCustomer.rfc"
              :string="row.issuing_rfc"
            />
            <sub-string v-else :string="row.receiver_rfc" />
          </template>
        </table-column>
        <table-column :label="$t('payments.type')" show="type" />
        <table-column :label="$t('payments.sub_total')" show="sub_total">
          <template slot-scope="row">
            <span>{{ $t('payments.sub_total') }}</span>
            <div v-html="$utils.formatMoney(row.sub_total, defaultCurrency)" />
          </template>
        </table-column>

        <table-column :label="$t('payments.iva')" show="iva">
          <template slot-scope="row">
            <span>{{ $t('payments.iva') }}</span>
            <div v-html="$utils.formatMoney(row.iva, defaultCurrency)" />
          </template>
        </table-column>

        <table-column
          :label="$t('payments.retention_iva')"
          show="retention_iva"
        >
          <template slot-scope="row">
            <span>{{ $t('payments.retention_iva') }}</span>
            <div
              v-html="$utils.formatMoney(row.retention_iva, defaultCurrency)"
            />
          </template>
        </table-column>

        <table-column
          :label="$t('payments.retention_isr')"
          show="retention_isr"
        >
          <template slot-scope="row">
            <span>{{ $t('payments.retention_isr') }}</span>
            <div
              v-html="$utils.formatMoney(row.retention_isr, defaultCurrency)"
            />
          </template>
        </table-column>

        <table-column :label="$t('payments.total')" show="amount">
          <template slot-scope="row">
            <span>{{ $t('payments.total') }}</span>
            <div v-html="$utils.formatMoney(row.amount, defaultCurrency)" />
          </template>
        </table-column>

        <table-column
          :label="$t('invoices.account_statement_abbr')"
          show="account_statement"
        >
          <template slot-scope="row">
            <span>{{ $t('invoices.account_statement_abbr') }}</span>
            <icon-in-tax-return
              :status="row.account_statement"
              module="account_statement"
            />
          </template>
        </table-column>
      </table-component>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { SweetModal, SweetModalTab } from 'sweet-modal-vue'
import CapsuleIcon from '../../components/icon/CapsuleIcon'
import BaseButton from '../../../js/components/base/BaseButton'
import IconInTaxReturn from '../components/IconInTaxReturn'

export default {
  components: {
    'capsule-icon': CapsuleIcon,
    SweetModal: SweetModal,
    SweetModalTab: SweetModalTab,
    BaseButton,
    IconInTaxReturn,
  },
  data() {
    return {
      showFilters: true,
      sortedBy: 'created_at',
      filtersApplied: false,
      isRequestOngoing: true,
      typeOptions: [],
      filters: {
        customer: null,
        payment_mode: '',
        payment_number: '',
        month: null,
        year: null,
        typeOption: null,
        total: '',
        nameOrRFC: '',
      },
      paymentsTotals: {
        total: 0,
        retention_iva: 0,
        retention_isr: 0,
        iva: 0,
      },
    }
  },
  computed: {
    showEmptyScreen() {
      return (
        !this.totalPayments && !this.isRequestOngoing && !this.filtersApplied
      )
    },
    filterIcon() {
      return this.showFilters ? 'times' : 'filter'
    },
    ...mapGetters('customer', ['customers']),
    ...mapGetters('payment', [
      'selectedPayments',
      'totalPayments',
      'payments',
      'selectAllField',
      'paymentModes',
    ]),
    ...mapGetters('currency', ['defaultCurrency']),
    ...mapGetters('CurrentCustomer', ['currentCustomer']),
    ...mapGetters('calendar', ['months', 'defaultMonth', 'defaultYear']),
    selectField: {
      get: function () {
        return this.selectedPayments
      },
      set: function (val) {
        this.selectPayment(val)
      },
    },
    selectAllFieldStatus: {
      get: function () {
        return this.selectAllField
      },
      set: function (val) {
        this.setSelectAllState(val)
      },
    },
  },
  watch: {
    filters: {
      handler: 'setFilters',
      deep: true,
    },
  },
  created() {
    let sessionFilterMonth
    if (sessionStorage.getItem('filtersPaymentsMonth') != 'undefined') {
      sessionFilterMonth = JSON.parse(
        sessionStorage.getItem('filtersPaymentsMonth')
      )
    }
    let sessionFilterYear = sessionStorage.getItem('filtersPaymentsYear')

    this.typeOptions = [
      { id: 'factura', name: 'Factura' },
      { id: 'ingreso', name: 'Ingreso' },
    ]
    // if(sessionFilterMonth){
    //   this.filters.month = sessionFilterMonth;
    //   this.filters.year = sessionFilterYear;
    // }else{
    if (this.defaultMonth && this.defaultYear) {
      this.filters.month = this.defaultMonth
      this.filters.year = this.defaultYear
      sessionStorage.setItem(
        'filtersPaymentsMonth',
        JSON.stringify(this.filters.month)
      )
      sessionStorage.setItem('filtersPaymentsYear', this.filters.year)
    } else {
      this.filters.month = sessionFilterMonth
      this.filters.year = sessionFilterYear
    }

    // }

    if (this.currentCustomer) {
      this.filters.customer = this.currentCustomer
    }
  },
  mounted() {
    this.fetchCustomers()
    this.fetchTotals()
  },
  destroyed() {
    if (this.selectAllField) {
      this.selectAllPayments()
    }
  },
  methods: {
    ...mapActions('payment', [
      'fetchPaymentsNomina',
      'fetchWithoutPaymentsNomina',
      'selectAllPayments',
      'selectPayment',
      'deletePayment',
      'deleteMultiplePayments',
      'setSelectAllState',
      'fetchListTotalsNomina',
      'toggleGeneralPublicInvoice',
    ]),
    ...mapActions('customer', ['fetchCustomers']),
    async fetchData({ page, sort }) {
      if (!this.currentCustomer) {
        this.$router.push('/general/customers')
        return {
          data: [],
          pagination: {
            totalPages: 1,
            currentPage: 1,
            count: 0,
          },
        }
      }

      let data = {
        customer_id:
          this.filters.customer !== null
            ? this.filters.customer.id
            : this.currentCustomer.id,
        payment_number: this.filters.payment_number,
        payment_method_id: this.filters.payment_mode
          ? this.filters.payment_mode.id
          : '',
        total: this.filters.total !== '' ? this.filters.total : '',
        month: this.filters.month ? this.filters.month.id : null,
        year: this.filters.year,
        typeOption: this.filters.typeOption ? this.filters.typeOption.id : null,
        name_or_rfc: this.filters.nameOrRFC,
        //  orderByField: sort.fieldName || 'date',
        //  orderBy: sort.order || 'asc',
        page,
      }

      this.isRequestOngoing = true

      let response = await this.fetchPaymentsNomina(data)

      this.isRequestOngoing = false

      return {
        data: response.data.payments.data,
        pagination: {
          totalPages: response.data.payments.last_page,
          currentPage: page,
          count: response.data.payments.scount,
        },
      }
    },

    async fetchDataWithoutPayment({ page, sort }) {
      if (!this.currentCustomer) {
        this.$router.push('/general/customers')
        return {
          data: [],
          pagination: {
            totalPages: 1,
            currentPage: 1,
            count: 0,
          },
        }
      }

      let data = {
        customer_id:
          this.filters.customer !== null
            ? this.filters.customer.id
            : this.currentCustomer.id,
        payment_number: this.filters.payment_number,
        payment_method_id: this.filters.payment_mode
          ? this.filters.payment_mode.id
          : '',
        total: this.filters.total !== '' ? this.filters.total : '',
        month: this.filters.month ? this.filters.month.id : null,
        year: this.filters.year,
        typeOption: this.filters.typeOption ? this.filters.typeOption.id : null,
        name_or_rfc: this.filters.nameOrRFC,
        //  orderByField: sort.fieldName || 'date',
        //  orderBy: sort.order || 'asc',
        page,
      }

      this.isRequestOngoing = true
      let response = await this.fetchWithoutPaymentsNomina(data)

      this.isRequestOngoing = false

      return {
        data: response.data.payments.data,
        pagination: {
          totalPages: response.data.payments.last_page,
          currentPage: page,
          count: response.data.payments.scount,
        },
      }
    },

    fetchTotals() {
      let data = {
        customer_id:
          this.filters.customer !== null
            ? this.filters.customer.id
            : this.currentCustomer.id,
        payment_number: this.filters.payment_number,
        payment_method_id: this.filters.payment_mode
          ? this.filters.payment_mode.id
          : '',
        total: this.filters.total !== '' ? this.filters.total : '',
        month: this.filters.month ? this.filters.month.id : null,
        year: this.filters.year,
        typeOption: this.filters.typeOption ? this.filters.typeOption.id : null,
        name_or_rfc: this.filters.nameOrRFC,
      }
      this.fetchListTotalsNomina(data).then((response) => {
        this.paymentsTotals = response.data
      })
    },
    refreshTable() {
      this.$refs.table.refresh()
      this.$refs.tableWithoutPayment.refresh()
    },
    setFilters() {
      if (this.showFilters) {
        sessionStorage.setItem(
          'filtersPaymentsMonth',
          JSON.stringify(this.filters.month)
        )
        sessionStorage.setItem('filtersPaymentsYear', this.filters.year)
      }
      this.filtersApplied = true
      this.refreshTable()
      this.fetchTotals()
    },
    clearFilter() {
      this.filters = {
        customer: null,
        payment_mode: '',
        payment_number: '',
        month: this.defaultMonth,
        year: this.defaultYear,
        typeOption: null,
        total: '',
      }

      this.$nextTick(() => {
        this.filtersApplied = false
      })
    },
    toggleFilter() {
      if (this.showFilters && this.filtersApplied) {
        this.clearFilter()
        this.refreshTable()
        this.fetchTotals()
      }

      this.showFilters = !this.showFilters
    },
    onSelectCustomer(customer) {
      this.filters.customer = customer
    },
    async removePayment(id) {
      this.id = id
      window
        .swal({
          title: this.$t('general.are_you_sure'),
          text: this.$tc('payments.confirm_delete'),
          icon: '/assets/icon/trash-solid.svg',
          buttons: true,
          dangerMode: true,
        })
        .then(async (willDelete) => {
          if (willDelete) {
            let res = await this.deletePayment(this.id)
            if (res.data.success) {
              window.toastr['success'](this.$tc('payments.deleted_message', 1))
              this.$refs.table.refresh()
              return true
            } else if (res.data.error) {
              window.toastr['error'](res.data.message)
            }
          }
        })
    },
    async removeMultiplePayments() {
      window
        .swal({
          title: this.$t('general.are_you_sure'),
          text: this.$tc('payments.confirm_delete', 2),
          icon: '/assets/icon/trash-solid.svg',
          buttons: true,
          dangerMode: true,
        })
        .then(async (willDelete) => {
          if (willDelete) {
            let request = await this.deleteMultiplePayments()
            if (request.data.success) {
              window.toastr['success'](this.$tc('payments.deleted_message', 2))
              this.$refs.table.refresh()
            } else if (request.data.error) {
              window.toastr['error'](request.data.message)
            }
          }
        })
    },
    async clearCustomerSearch(removedOption, id) {
      this.filters.customer = ''
      this.$refs.table.refresh()
    },
    showModel(selectedRow) {
      this.selectedRow = selectedRow
      this.$refs.Delete_modal.open()
    },
    async removeSelectedItems() {
      this.$refs.Delete_modal.close()
      await this.selectedRow.forEach((row) => {
        this.deletePayment(row.id)
      })
      this.$refs.table.refresh()
    },
    rowClicked(rowData) {
      if (!rowData.column || rowData.column == 'general_public_invoice') {
        return null
      }
      this.$router.push({
        name: 'invoices.view',
        params: {
          id: rowData.row.id,
        },
      })
    },
    toggleRowGeneralPublicInvoice(row) {
      this.toggleGeneralPublicInvoice(row.id)
        .then((response) => {
          window.toastr.success(
            this.$t('payments.general_public_invoices.messages.saved_status')
          )
        })
        .catch((err) => {
          window.toastr.error(
            this.$t('payments.general_public_invoices.errors.save_status')
          )
        })
    },
  },
}
</script>
