import { reject } from 'lodash'
import * as types from './mutation-types'

export const fetchCustomerProfile = ({ commit }) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get('/api/customer_modules/customer/profile')
      .then((response) => {
        commit(types.SET_USER_DATA, response.data.user)
        resolve(response)
      })
      .catch(reject)
  })
}

export const fetchCustomerCertificates = () => {
  return new Promise((resolve, reject) => {
    window.axios
      .get('/api/customer_modules/customer/certificates')
      .then(resolve)
      .catch(reject)
  })
}

export const uploadCustomerCertificates = (store, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post('/api/customer_modules/customer/certificates', data)
      .then(resolve)
      .catch(reject)
  })
}
