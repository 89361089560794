var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-content" },
    [
      _c(
        "div",
        { staticClass: "page-header mt-4", staticStyle: { "z-index": "1" } },
        [
          _c("h3", { staticClass: "page-title" }, [
            _vm._v("Pendientes del mes"),
          ]),
          _vm._v(" "),
          _c(
            "h6",
            { staticClass: "page-subtitle" },
            [
              _vm.filters.icon != ""
                ? _c("font-awesome-icon", {
                    staticClass: "icon menu-icon",
                    style: { color: "#88C349" },
                    attrs: { icon: _vm.filters.icon },
                  })
                : _vm._e(),
              _vm._v("\n      " + _vm._s(_vm.filtersAppliedName) + "\n    "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page-actions row" },
            [
              _c("div", { staticClass: "col-xs-2 mr-2" }, [
                _c(
                  "div",
                  { staticClass: "btn-group", attrs: { role: "group" } },
                  [
                    _c(
                      "v-dropdown",
                      { attrs: { "show-arrow": true } },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn item-dropdown dropdown-toggle",
                            attrs: {
                              slot: "activator",
                              type: "button",
                              "data-toggle": "dropdown",
                              "aria-haspopup": "true",
                              "aria-expanded": "false",
                            },
                            slot: "activator",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s("Status") +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.statusMenu, function (item, index) {
                          return _c(
                            "v-dropdown-item",
                            { key: index, staticStyle: { width: "350px" } },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.setFilter(item)
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "icon menu-icon",
                                    style: { color: "#88C349" },
                                    attrs: { icon: item.icon },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "ml-3 menu-text" },
                                    [_vm._v(_vm._s(item.name))]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "conditional-wrapper",
                {
                  attrs: {
                    "show-for-admin": true,
                    "show-for-executives": false,
                    "show-for-customers": false,
                  },
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "col-xs-2",
                      attrs: { to: "/general/customers/create" },
                    },
                    [
                      _c(
                        "base-button",
                        {
                          attrs: {
                            size: "small",
                            icon: "plus",
                            color: "theme",
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("customers.new_customer")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", {}, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-sm-3" },
            [
              _c("label", { staticClass: "form-label" }, [_vm._v("Búsqueda")]),
              _vm._v(" "),
              _c("base-input", {
                attrs: { type: "text", name: "search", autocomplete: "on" },
                model: {
                  value: _vm.filters.search,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters, "search", $$v)
                  },
                  expression: "filters.search",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "table-component",
        {
          ref: "table",
          attrs: {
            "show-filter": false,
            data: _vm.fetchData,
            "table-class": "table",
            "is-clickable": true,
          },
          on: { columnClick: _vm.rowClicked },
        },
        [
          _c("table-column", {
            attrs: { label: "No. de cliente", show: "id" },
          }),
          _vm._v(" "),
          _c("table-column", {
            attrs: { label: _vm.$t("customers.name"), show: "name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [_vm._v(_vm._s(_vm.$t("customers.name")))]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _vm._v(
                          "\n          " + _vm._s(row.name) + "\n          "
                        ),
                        _c("certificate-warning", {
                          attrs: {
                            "csd-expires-at": row.csd_expires_at,
                            "fiel-expires-at": row.fiel_expires_at,
                          },
                        }),
                        _vm._v(" "),
                        _vm.isWithinTwoWeeks(row.created_at)
                          ? _c("span", [_c("NewIcon")], 1)
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("table-column", { attrs: { label: "RFC", show: "rfc" } }),
          _vm._v(" "),
          _c("table-column", {
            attrs: { label: "Regimen" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.searchTaxRegime(row.tax_regime).description
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("table-column", {
            attrs: { label: "Encargado" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c(
                      "conditional-wrapper",
                      {
                        attrs: {
                          "show-for-admin": true,
                          "show-for-executives": false,
                          "show-for-customers": false,
                          disable: true,
                        },
                      },
                      [
                        _c(
                          "v-dropdown",
                          { attrs: { "show-arrow": true } },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn item-dropdown dropdown-toggle",
                                attrs: {
                                  slot: "activator",
                                  type: "button",
                                  "data-toggle": "dropdown",
                                  "aria-haspopup": "true",
                                  "aria-expanded": "false",
                                },
                                slot: "activator",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      row.assigned_to
                                        ? row.assigned_to.user_b.name
                                        : "Sin asignar"
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm._l(_vm.ejecutivos, function (item, index) {
                              return _c(
                                "v-dropdown-item",
                                { key: index, staticStyle: { width: "250px" } },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      on: {
                                        click: function ($event) {
                                          return _vm.setEjecutivo(
                                            row.id,
                                            item.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "ml-3 menu-text" },
                                        [_vm._v(_vm._s(item.name))]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("table-column", {
            attrs: { label: "Status" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("font-awesome-icon", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: {
                            content:
                              _vm.filters.icon !== ""
                                ? _vm.filtersAppliedName
                                : row.status,
                          },
                          expression:
                            "{\n            content: filters.icon !== '' ? filtersAppliedName : row.status,\n          }",
                        },
                      ],
                      staticClass: "icon menu-icon",
                      style: {
                        color: "#ABC193",
                        fontSize: "22px",
                      },
                      attrs: {
                        icon:
                          _vm.filters.icon !== "" ? _vm.filters.icon : row.icon,
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("table-column", {
            attrs: {
              sortable: false,
              filterable: false,
              "cell-class": "action-dropdown",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.$t("customers.action")) + " "),
                    ]),
                    _vm._v(" "),
                    _c(
                      "v-dropdown",
                      [
                        _c(
                          "a",
                          {
                            attrs: { slot: "activator", href: "#" },
                            slot: "activator",
                          },
                          [_c("dot-icon")],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-dropdown-item",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item",
                                attrs: {
                                  to: {
                                    name: "customer.sat-settings",
                                    params: { id: row.id },
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "dropdown-item-icon",
                                  attrs: { icon: ["fas", "file-alt"] },
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "customers.sat_settings.page_title"
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-dropdown-item",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item",
                                attrs: {
                                  to: {
                                    name: "customer.previous-invoices",
                                    params: { id: row.id },
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "dropdown-item-icon",
                                  attrs: { icon: ["fas", "file-alt"] },
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t("customers.previous_invoices")
                                    ) +
                                    "\n            "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-dropdown-item",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item",
                                attrs: {
                                  to: `/general/customers/${row.id}/edit`,
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "dropdown-item-icon",
                                  attrs: { icon: ["fas", "pencil-alt"] },
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("general.edit")) +
                                    "\n            "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }