var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "base-loader" }, [
      _c("div", { staticClass: "spinner" }),
      _vm._v(" "),
      _c("div", { staticClass: "overlay" }, [
        _c("div", { staticClass: "loader-inner ball-scale-ripple-multiple" }, [
          _c("div"),
          _vm._v(" "),
          _c("div"),
          _vm._v(" "),
          _c("div"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }