export default {
  get (key) {
    return localStorage.getItem(key) ? localStorage.getItem(key) : null
  },

  getObject (key) {
    return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : null
  },

  set (key, val) {
    localStorage.setItem(key, val)
  },

  setObject (key, val) {
    localStorage.setItem(key, JSON.stringify(val))
  },


  remove (key) {
    localStorage.removeItem(key)
  },

  clear() {
    localStorage.clear()
  },
}
