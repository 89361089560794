<template>
  <div class="invoice-index-page invoices main-content">
    <div class="page-header">
      <h3 class="page-title">{{ $t('Descarga masiva') }}</h3>
      <!-- <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link slot="item-title" to="dashboard">
            {{ $t('general.home') }}
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link slot="item-title" to="#">
            {{ $tc('invoices.invoice', 2) }}
          </router-link>
        </li>
      </ol> -->
      <div class="page-actions row">
        <div class="col-xs-2 mr-4">
          <!--
          <base-button
            v-show="totalRequests || filtersApplied"
            :outline="true"
            :icon="filterIcon"
            size="large"
            color="theme"
            right-icon
            @click="toggleFilter"
          >
            {{ $t('general.filter') }}aqui
          </base-button>
          -->
        </div>

        <!-- <base-button size="large" icon="plus" color="theme" @click="createMassiveRequest()">
          {{ $t('Generar solicitud') }}
        </base-button> -->

        <!-- <a class="dropdown-item" href="#/" @click="convertInToinvoice(row.id)">
          <font-awesome-icon icon="file-alt" class="dropdown-item-icon" />
          {{ $t('estimates.convert_to_invoice') }}
        </a> -->

      </div>
    </div>

    <transition name="fade">
      <div v-show="showFilters" class="filter-section">
        <div class="filter-container">
          <div class="filter-customer">
            <label>{{ $tc('customers.customer', 1) }} </label>
            <base-customer-select
              ref="customerSelect"
              @select="onSelectCustomer"
              @deselect="clearCustomerSearch"
            />
          </div>
          <div class="filter-date">
            <div class="from pr-3">
              <label>{{ $t('general.from') }}</label>
              <base-date-picker
                v-model="filters.from_date"
                :calendar-button="true"
                calendar-button-icon="calendar"
              />
            </div>
            <div class="dashed" />
            <div class="to pl-3">
              <label>{{ $t('general.to') }}</label>
              <base-date-picker
                v-model="filters.to_date"
                :calendar-button="true"
                calendar-button-icon="calendar"
              />
            </div>
          </div>
          <div class="filter-invoice">
            <label>{{ $t('invoices.invoice_number') }}</label>
            <base-input v-model="filters.invoice_number" icon="hashtag" />
          </div>
        </div>
<!--         <label class="clear-filter" @click="clearFilter">{{
          $t('general.clear_all')
        }}</label> -->
      </div>
    </transition>

    <div
      v-cloak
      v-show="showEmptyScreen"
      class="col-xs-1 no-data-info"
      align="center"
    >
      <moon-walker-icon class="mt-5 mb-4" />
      <div class="row" align="center">
        <label class="col title">{{ $t('invoices.no_invoices') }}</label>
      </div>
      <div class="row">
        <label class="description col mt-1" align="center">{{
          $t('invoices.list_of_invoices')
        }}</label>
      </div>
      <div class="btn-container">
        <base-button
          :outline="true"
          color="theme"
          class="mt-3"
          size="large"
          @click="$router.push('invoices/create')"
        >
          {{ $t('invoices.new_invoice') }}
        </base-button>
      </div>
    </div>
    <div v-show="!showEmptyScreen" class="table-container">
      <div class="table-actions mt-5">

        <!-- Tabs -->
        <!-- <ul class="tabs">
          <li class="tab" @click="getStatus('UNPAID')">
            <a
              :class="[
                'tab-link',
                { 'a-active': filters.status.value === 'UNPAID' },
              ]"
              href="#"
              >{{ $t('general.due') }}</a
            >
          </li>
          <li class="tab" @click="getStatus('DRAFT')">
            <a
              :class="[
                'tab-link',
                { 'a-active': filters.status.value === 'DRAFT' },
              ]"
              href="#"
              >{{ $t('general.draft') }}</a
            >
          </li>
          <li class="tab" @click="getStatus('')">
            <a
              :class="[
                'tab-link',
                {
                  'a-active':
                    filters.status.value === '' ||
                    filters.status.value === null ||
                    (filters.status.value !== 'DRAFT' &&
                      filters.status.value !== 'UNPAID'),
                },
              ]"
              href="#"
              >{{ $t('general.all') }}</a
            >
          </li>
        </ul> -->

        <transition name="fade">
          <v-dropdown v-if="selectedInvoices.length" :show-arrow="false">
            <span
              slot="activator"
              href="#"
              class="table-actions-button dropdown-toggle"
            >
              {{ $t('general.actions') }}
            </span>
            <v-dropdown-item>
              <div class="dropdown-item" @click="removeMultipleInvoices">
                <font-awesome-icon
                  :icon="['fas', 'trash']"
                  class="dropdown-item-icon"
                />
                {{ $t('general.delete') }}
              </div>
            </v-dropdown-item>
          </v-dropdown>
        </transition>
      </div>

      <table-component
        ref="table"
        :show-filter="false"
        :data="fetchData"
        table-class="table"
      >

        <table-column
          :label="$t('invoices.date')"
          sort-as="created_at"
          show="created_at"
        />
        <table-column
          :label="$t('invoices.customer')"
          width="20%"
          show="user.name"
        />
        <table-column :label="$t('Fecha inicial Solicitada')" show="start_date_request" />
        <table-column :label="$t('Fecha final Solicitada')" show="end_date_request" />
        <table-column :label="$t('Estatus')" show="status" />
        <table-column :label="$t('Tipo')" show="request_type" />
      </table-component>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MoonWalkerIcon from '../../../js/components/icon/MoonwalkerIcon'
import moment from 'moment'

export default {
  components: {
    'moon-walker-icon': MoonWalkerIcon
  },
  data () {
    return {
      showFilters: false,
      currency: null,
      filtersApplied: false,
      isRequestOngoing: true,
      filters: {
        request_id: '',
        start_date_request: '',
        end_date_request: '',
        user: '',
        created_at: ''
      }
    }
  },

  computed: {
    showEmptyScreen () {
      return !this.totalRequests && !this.isRequestOngoing && !this.filtersApplied
    },
    filterIcon () {
      return (this.showFilters) ? 'times' : 'filter'
    },
    ...mapGetters('customer', [
      'customers'
    ]),
    ...mapGetters('invoice', [
      'selectedInvoices',
      'totalRequests',
      'totalInvoices',
      'invoices',
      'selectAllField'
    ]),
    selectField: {
      get: function () {
        return this.selectedInvoices
      },
      set: function (val) {
        this.selectInvoice(val)
      }
    },
    selectAllFieldStatus: {
      get: function () {
        return this.selectAllField
      },
      set: function (val) {
        this.setSelectAllState(val)
      }
    }
  },
  watch: {
    filters: {
      handler: 'setFilters',
      deep: true
    }
  },
  created () {
    // this.fetchCustomers()
  },
  destroyed () {
    if (this.selectAllField) {
      this.selectAllInvoices()
    }
  },
  methods: {
    ...mapActions('invoice', [
      'getMassiveDownloadRequests',
      'getRecord',
      'selectInvoice',
      'resetSelectedInvoices',
      'selectAllInvoices',
      'setSelectAllState',
      'cloneInvoice'
    ]),
    ...mapActions('customer', [
      'fetchCustomers'
    ]),
    ...mapActions('modal', [
      'openModal',
      'closeModal',
      'resetModalData'
    ]),
    async createMassiveRequest () {
      this.openModal({
        'title': 'Rango de fechas de la solicitud',
        'componentName': 'MassiveRequestForm'
      })
    },
    async sendInvoice (id) {

    },
    async markInvoiceAsSent (id) {

    },
    async onCloneInvoice (id) {

    },
    getStatus (val) {

    },
    refreshTable () {
      this.$refs.table.refresh()
    },
    async fetchData ({ page, filter, sort }) {
      let data = {
        /* request_id: this.filters.request_id,
        start_date_request: this.filters.start_date_request,
        end_date_request: this.filters.end_date_request,
        user: this.filters.user,
        created_at: this.filters.created_at,
        orderByField: sort.fieldName || 'created_at',
        orderBy: sort.order || 'desc', */
        page
      }

      this.isRequestOngoing = true
      let response = await this.getMassiveDownloadRequests(data)
      this.isRequestOngoing = false

      return {
        data: response.data.massive_requests.data,
        pagination: {
          totalPages: response.data.massive_requests.last_page,
          currentPage: page,
          count: response.data.massive_requests.count
        }
      }
    },
    setFilters () {
      this.filtersApplied = true
      this.resetSelectedInvoices()
      this.refreshTable()
    },
    clearFilter () {
      if (this.filters.customer) {
        this.$refs.customerSelect.$refs.baseSelect.removeElement(this.filters.customer)
      }
      this.filters = {
        request_id: '',
        start_date_request: '',
        end_date_request: '',
        user: '',
        created_at: ''
      }

      this.$nextTick(() => {
        this.filtersApplied = false
      })
    },
    toggleFilter () {
      if (this.showFilters && this.filtersApplied) {
        this.clearFilter()
        this.refreshTable()
      }

      this.showFilters = !this.showFilters
    },
    onSelectCustomer (customer) {
      this.filters.customer = customer
    },
    async removeInvoice (id) {

    },
    async removeMultipleInvoices () {

    },
    async clearCustomerSearch (removedOption, id) {
      this.filters.user = ''
      this.refreshTable()
    },
    async clearStatusSearch (removedOption, id) {
      this.filters.request_id = ''
      this.refreshTable()
    }
  }
}
</script>
