<template>
  <div class="p-3">
    <form @submit.prevent="savePaquete()">
      <div class="form-group">
        <div class="input-group mb-3">
          <label for="">Nombre del paquete</label>
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              v-model="form.nombre"
              @change="$v.form.$touch()"
            />
          </div>
          <div class="error" v-if="!$v.form.nombre.required">
            El nombre del paquete es requerido.
          </div>
        </div>

        <div class="input-group mb-3">
          <label for="">Precio</label>
          <div class="input-group mb-3">
            <input
              type="number"
              class="form-control"
              v-model="form.precio"
              @change="$v.form.$touch()"
            />
          </div>
          <div class="error" v-if="!$v.form.precio.required">
            El precio es requerido.
          </div>
          <div class="error" v-if="!$v.form.precio.minValueValue">
            El precio debe ser mayor a cero.
          </div>
        </div>

        <div class="input-group mb-3">
          <label for="">Periodicidad en meses</label>
          <div class="input-group mb-3">
            <select
              class="custom-select"
              v-model="form.periodicidad"
              @change="$v.form.$touch()"
            >
              <option value="" selected>Elegir...</option>
              <option v-for="period in selectPeriodicidad" :value="period" :key="period.id" >{{period.nombre}}</option>
            </select>
          </div>

          <div class="error" v-if="!$v.form.periodicidad.required">
            La periodicidad es requerido.
          </div>
        </div>
        
        <div class="input-group mb-3">
          <label for="">Descripción del paquete</label>
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              v-model="form.descripcion"
              @change="$v.form.$touch()"
            />
          </div>
          <div class="error" v-if="!$v.form.descripcion.required">
            La descripción del paquete es requerido.
          </div>
        </div>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="inputGroupSelect01"
              >Regimen</label
            >
          </div>
          <select
            class="custom-select"
            v-model="form.regimen"
            @change="$v.form.$touch()"
          >
            <option value="" selected>Elegir...</option>
            <option
              v-for="regimen in selectRegimen"
              :key="regimen.id"
              :value="regimen.id"
              >{{ regimen.name }}</option
            >
          </select>
        </div>
      </div>

      <div class="text-right">
        <button type="submit" class="btn btn-primary">
          {{ $t('invoices.save') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minValue } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

export default {
  mixins: [validationMixin],

  data: () => ({
    form: {
      nombre: '',
      precio: 0,
      periodicidad: '',
      descripcion: '',
      regimen: 0,
    },
    selectRegimen: [
      { id: 606, name: 'Arrendamiento' },
      {
        id: 612,
        name: 'Personas Físicas con Actividades Empresariales y Profesionales',
      },
      { id: 621, name: 'Régimen Incorporación Fiscal' },
      { id: 625, name: 'Plataformas Digitales' },
      { id: 626, name: 'Régimen Simplificado de Confianza' },
    ],
    selectPeriodicidad:[ 
          {id: 1, mes: 1, nombre: 'MENSUAL'},
          {id: 2, mes: 2, nombre: 'BIMESTRAL'},
          {id: 3, mes: 3, nombre: 'TRIMESTRAL'},
          {id: 4, mes: 6, nombre: 'SEMESTRAL'},
          {id: 5, mes: 12, nombre: 'ANUAL'},
    ]
  }),

  validations: {
    form: {
      nombre: {
        required,
      },
      precio: {
        required,
        minValueValue: minValue(1),
      },
      descripcion: {
        required,
      },
      periodicidad: {
        required,
      },
    },
  },

  mounted() {
    this.loadClientes()
  },

  methods: {
    ...mapActions('modal', ['closeModal']),
    ...mapActions('customer', ['fetchCustomers']),
    ...mapActions('payment', ['fetchCreatePaquete']),

    async loadClientes() {
      let response = await this.fetchCustomers()
      this.selectCliente = response.data.customers.data
    },

    savePaquete() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid !== false) {
        return
      }

      this.fetchCreatePaquete(this.form)
        .then((response) => {
          this.closeModal()
          window.toastr['success']('El paquete fue registrado con éxito!.')
          window.hub.$emit('concept_categories.category.created')
        })
        .catch((err) => {
          window.toastr['error']('Hubo un error al registrar el paquete!')
        })
    },
  },
}
</script>
