var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.taxReturn
    ? _c("div", { staticClass: "card p-3" }, [
        _c("h5", { staticClass: "text-center mb-3" }, [
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.$t(
                  "tax_returns.declaration_of_the_exercise_of_federal_taxes"
                )
              ) +
              "\n  "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "border-top border-bottom text-center table-title" },
          [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("tax_returns.determination_of_tax")) +
                "\n  "
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "p-2" }, [
          _c(
            "div",
            { staticClass: "form-group flex-content-right" },
            [
              _c(
                "base-button",
                {
                  staticClass: "mr-2",
                  attrs: { color: "danger", size: "small" },
                  on: { click: _vm.openApplyTaxLossModal },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("tax_losses.apply_tax_loss")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "table-responsive" }, [
          _c("table", { staticClass: "table" }, [
            _c("tbody", [
              _c("tr", [
                _c("td", [
                  _c("a", { attrs: { href: "#revenue" } }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("tax_returns.accumulable_revenue")) +
                        "\n            "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: {
                        amount: _vm.taxDetermination.accumulable_revenue,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _c("a", { attrs: { href: "#persona_deductions" } }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("tax_returns.personal_deductions")) +
                        "\n            "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: {
                        amount: _vm.taxDetermination.personal_deductions,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$t("tax_returns.taxable_base")))]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: { amount: _vm.taxDetermination.taxable_base },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("tax_returns.isr_according_to_the_annual_rate")
                    )
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: {
                        amount:
                          _vm.taxDetermination.isr_to_according_annual_rate,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(_vm._s(_vm.$t("tax_returns.provisional_payments"))),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: {
                        amount: _vm.taxDetermination.provisional_payments,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _c("a", { attrs: { href: "#isr_withheld" } }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("tax_returns.isr_detained")) +
                        "\n            "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: { amount: _vm.taxDetermination.isr_withheld },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$t("tax_returns.tax_loss")))]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: { amount: _vm.taxDetermination.tax_loss },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$t("tax_returns.isr_in_charge")))]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: { amount: _vm.taxDetermination.isr_in_charge },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm.byIssuer
          ? _c("div", [
              _c(
                "div",
                {
                  staticClass:
                    "border-top border-bottom text-center table-title",
                },
                [
                  _c("a", { attrs: { href: "#tables-wages-salaries" } }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("tax_returns.wages_and_salaries")) +
                        "\n      "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.showByIssuerTable
                ? _c("table", { staticClass: "sub-table" }, [
                    _c("thead", [
                      _c("tr", [
                        _c("td", [
                          _vm._v(_vm._s(_vm.$t("tax_returns.retainer_rfc"))),
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.$t("invoices.name")))]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("tax_returns.annual_revenue")) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("tax_returns.exempt_revenue")) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("tax_returns.isr_withheld")) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("tax_returns.employment_subsidy")) +
                              "\n          "
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.byIssuer, function (issuer) {
                        return _c("tr", { key: issuer.rfc }, [
                          _c("td", [_vm._v(_vm._s(issuer.rfc))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(issuer.name))]),
                          _vm._v(" "),
                          _c(
                            "td",
                            { staticClass: "text-right" },
                            [
                              _c("money-text", {
                                attrs: { amount: issuer.annual_revenue },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            { staticClass: "text-right" },
                            [
                              _c("money-text", {
                                attrs: { amount: issuer.exempt_amount },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            { staticClass: "text-right" },
                            [
                              _c("money-text", {
                                attrs: { amount: issuer.deduction_isr },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            { staticClass: "text-right" },
                            [
                              _c("money-text", {
                                attrs: { amount: issuer.employment_subsidy },
                              }),
                            ],
                            1
                          ),
                        ])
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "table-responsive" }, [
                _c("table", { staticClass: "table" }, [
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [
                        _vm._v(_vm._s(_vm.$t("tax_returns.annual_revenue"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount:
                                _vm.taxReturn.wages_and_salaries.annual_revenue,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(_vm._s(_vm.$t("tax_returns.exempt_revenue"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount:
                                _vm.taxReturn.wages_and_salaries.exempt_revenue,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm.$t("tax_returns.accumulable_revenue"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount:
                                _vm.taxReturn.wages_and_salaries
                                  .accumulable_revenue,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm.$t("tax_returns.employment_subsidy"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount:
                                _vm.taxReturn.wages_and_salaries
                                  .employment_subsidy,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(_vm._s(_vm.$t("tax_returns.tax_detained"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount:
                                _vm.taxReturn.wages_and_salaries.isr_withheld,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.taxReturn.arrendamiento
          ? _c("div", [
              _c(
                "div",
                {
                  staticClass:
                    "border-top border-bottom text-center table-title",
                  attrs: { id: "revenue" },
                },
                [
                  _c("a", { attrs: { href: "#table-revenue" } }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("tax_returns.lease")) +
                        "\n      "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "table-responsive" }, [
                _c("table", { staticClass: "table" }, [
                  _c("tbody", [
                    _c("tr", [
                      _c(
                        "th",
                        {
                          staticClass: "text-center table-subtitle",
                          attrs: { colspan: "2" },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("tax_returns.revenue")) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(_vm._s(_vm.$t("tax_returns.revenue_collected"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount:
                                _vm.taxReturn.arrendamiento.revenue_collected,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(_vm._s(_vm.$t("tax_returns.exempt_revenue"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount:
                                _vm.taxReturn.arrendamiento.exempt_revenue,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(_vm._s(_vm.$t("tax_returns.taxed_income"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount: _vm.taxReturn.arrendamiento.taxed_income,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c(
                        "th",
                        {
                          staticClass: "text-center table-subtitle",
                          attrs: { colspan: "2" },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t("tax_returns.authorized_deductions")
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(_vm._s(_vm.$t("tax_returns.deduction_type"))),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.taxReturn.arrendamiento.deduction_type) +
                            "\n            "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm.$t("tax_returns.authorized_deductions"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount: _vm.taxReturn.arrendamiento.deductions,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(_vm._s(_vm.$t("tax_returns.property_tax"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount: _vm.taxReturn.arrendamiento.property_tax,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("tax_returns.total_authorized_deductions")
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount:
                                _vm.taxReturn.arrendamiento.total_deductions,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c(
                        "th",
                        {
                          staticClass: "text-center table-subtitle",
                          attrs: { colspan: "2" },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("tax_returns.result")) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm.$t("tax_returns.accumulable_revenue"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount:
                                _vm.taxReturn.arrendamiento.accumulable_revenue,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c(
                        "th",
                        {
                          staticClass: "text-center table-subtitle",
                          attrs: { colspan: "2" },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t("tax_returns.provisional_payments")
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(
                                "tax_returns.provisional_payments_made_in_the_year"
                              )
                            ) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount:
                                _vm.taxReturn.arrendamiento
                                  .provisional_payments,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c(
                        "th",
                        {
                          staticClass: "text-center table-subtitle",
                          attrs: { colspan: "2" },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("tax_returns.isr_retentions")) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(
                                "tax_returns.isr_detained_by_legal_entities"
                              )
                            ) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount: _vm.taxReturn.arrendamiento.isr_withheld,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.taxReturn.arrendamiento
          ? _c("div", [
              _c(
                "div",
                {
                  staticClass:
                    "border-top border-bottom text-center table-title",
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("tax_returns.other_income")) +
                      "\n    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "table-responsive" }, [
                _c("table", { staticClass: "table" }, [
                  _c("tbody", [
                    _c("tr", [
                      _c(
                        "th",
                        {
                          staticClass: "text-center table-subtitle",
                          attrs: { colspan: "2" },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("tax_returns.revenue")) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(_vm._s(_vm.$t("tax_returns.revenue_collected"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount:
                                _vm.taxReturn.other_revenue.revenue_collected,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(_vm._s(_vm.$t("tax_returns.exempt_revenue"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount:
                                _vm.taxReturn.other_revenue.exempt_revenue,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm.$t("tax_returns.accumulable_revenue"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount:
                                _vm.taxReturn.other_revenue.accumulable_revenue,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c(
                        "th",
                        {
                          staticClass: "text-center table-subtitle",
                          attrs: { colspan: "2" },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t("tax_returns.provisional_payments")
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(
                                "tax_returns.provisional_payments_made_in_the_year"
                              )
                            ) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount:
                                _vm.taxReturn.other_revenue
                                  .provisional_payments,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c(
                        "th",
                        {
                          staticClass: "text-center table-subtitle",
                          attrs: { colspan: "2" },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("tax_returns.isr_retentions")) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(
                                "tax_returns.isr_detained_by_legal_entities"
                              )
                            ) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount: _vm.taxReturn.other_revenue.isr_withheld,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "border-top border-bottom text-center table-title",
            attrs: { id: "personal_deductions" },
          },
          [
            _c("a", { attrs: { href: "#table-personal-deductions" } }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("tax_returns.personal_deductions")) +
                  "\n    "
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "table-responsive" },
          _vm._l(_vm.personalDeductions, function (category, i) {
            return _c(
              "table",
              { key: i, staticClass: "table personal-deductions" },
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", { attrs: { colspan: "2" } }, [
                      _vm._v(_vm._s(category.subcategory.name)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", { staticClass: "col" }, [
                      _vm._v(_vm._s(_vm.$t("invoices.date"))),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "col" }, [
                      _vm._v(_vm._s(_vm.$t("invoices.rfc"))),
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.$t("invoices.name")))]),
                    _vm._v(" "),
                    _c("td", { staticClass: "amount" }, [
                      _vm._v(_vm._s(_vm.$t("tax_returns.deductible_amount"))),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  [
                    _vm._l(category.items, function (item) {
                      return _c("tr", { key: item.id }, [
                        _c(
                          "td",
                          { staticClass: "col" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "invoices.view",
                                    params: { id: item.invoice_id },
                                  },
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.date) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "col" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "invoices.view",
                                    params: { id: item.invoice_id },
                                  },
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.rfc) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(item.name))]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "amount" },
                          [
                            _c("money-text", {
                              attrs: { amount: item.amount },
                            }),
                          ],
                          1
                        ),
                      ])
                    }),
                    _vm._v(" "),
                    _c("tr", [
                      _c(
                        "td",
                        { staticClass: "text-right", attrs: { colspan: "3" } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("tax_returns.total")) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: { amount: category.total },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "border-top border-bottom text-center table-title",
            attrs: { id: "isr_withheld" },
          },
          [
            _c("a", { attrs: { href: "#table-revenue" } }, [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("tax_returns.retentions")) + "\n    "
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "table-responsive" }, [
          _c("table", { staticClass: "table" }, [
            _c(
              "tbody",
              [
                _c("tr", [
                  _c(
                    "th",
                    {
                      staticClass: "text-center table-subtitle",
                      attrs: { colspan: "3" },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("tax_returns.wages_and_salaries")) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("tax_returns.retainer_rfc")) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("tax_returns.revenue_amount")) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("tax_returns.isr_retentions")) +
                        "\n          "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm._l(_vm.withhelds.salaries, function (withheld, i) {
                  return _c("tr", { key: i }, [
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(
                        "\n            " + _vm._s(withheld.rfc) + "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "text-right" },
                      [
                        _c("money-text", {
                          attrs: { amount: withheld.amount },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "text-right" },
                      [_c("money-text", { attrs: { amount: withheld.isr } })],
                      1
                    ),
                  ])
                }),
                _vm._v(" "),
                _c("tr", [
                  _c(
                    "th",
                    {
                      staticClass: "text-center table-subtitle",
                      attrs: { colspan: "3" },
                    },
                    [
                      _c("tax-regime-catalog", {
                        attrs: { "tax-regime": _vm.currentCustomer.tax_regime },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("tax_returns.retainer_rfc")) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("tax_returns.revenue_amount")) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("tax_returns.isr_retentions")) +
                        "\n          "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm._l(_vm.withhelds.regime, function (withheld, i) {
                  return _c("tr", { key: i }, [
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(
                        "\n            " + _vm._s(withheld.rfc) + "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "text-right" },
                      [
                        _c("money-text", {
                          attrs: { amount: withheld.amount },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "text-right" },
                      [_c("money-text", { attrs: { amount: withheld.isr } })],
                      1
                    ),
                  ])
                }),
              ],
              2
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "border-top border-bottom text-center table-title" },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-10" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$tc("financial_institutions.finalcial_institution")
                    ) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-2" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.showInterests,
                      expression: "showInterests",
                    },
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.showInterests)
                      ? _vm._i(_vm.showInterests, null) > -1
                      : _vm.showInterests,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.showInterests,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.showInterests = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.showInterests = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.showInterests = $$c
                      }
                    },
                  },
                }),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("financial_institutions.interests")) +
                    "\n      "
                ),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.showInterests
          ? _c("div", { staticClass: "table-responsive mb-4" }, [
              _c(
                "div",
                { staticClass: "flex-content-right pt-2 pb-2" },
                [
                  _c(
                    "base-button",
                    {
                      attrs: { color: "theme", size: "small" },
                      on: { click: _vm.addEmptyFinancialInstitution },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t(
                              "financial_institutions.add_financial_institution"
                            )
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("table", { staticClass: "table" }, [
                _c("thead", [
                  _c("tr", [
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t("financial_institutions.rfc_institution")
                          ) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(
                              "financial_institutions.amount_of_nominal_interest"
                            )
                          ) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(
                              "financial_institutions.amount_of_real_interest"
                            )
                          ) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("financial_institutions.lost")) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t("financial_institutions.isr_withheld")
                          ) +
                          "\n          "
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(
                    _vm.form.financialInstitutions,
                    function (institution) {
                      return _c("tr", { key: institution.guid }, [
                        _c(
                          "td",
                          [
                            _c("base-input", {
                              attrs: {
                                placeholder: _vm.$t(
                                  "financial_institutions.rfc_institution"
                                ),
                              },
                              model: {
                                value: institution.rfc,
                                callback: function ($$v) {
                                  _vm.$set(institution, "rfc", $$v)
                                },
                                expression: "institution.rfc",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "div",
                            { staticClass: "base-input" },
                            [
                              _c(
                                "money",
                                _vm._b(
                                  {
                                    staticClass: "input-field text-right",
                                    model: {
                                      value:
                                        institution.amount_of_nominal_interest,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          institution,
                                          "amount_of_nominal_interest",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "institution.amount_of_nominal_interest",
                                    },
                                  },
                                  "money",
                                  _vm.customerCurrency,
                                  false
                                )
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "div",
                            { staticClass: "base-input" },
                            [
                              _c(
                                "money",
                                _vm._b(
                                  {
                                    staticClass: "input-field text-right",
                                    model: {
                                      value:
                                        institution.amount_of_real_interest,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          institution,
                                          "amount_of_real_interest",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "institution.amount_of_real_interest",
                                    },
                                  },
                                  "money",
                                  _vm.customerCurrency,
                                  false
                                )
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "div",
                            { staticClass: "base-input" },
                            [
                              _c(
                                "money",
                                _vm._b(
                                  {
                                    staticClass: "input-field text-right",
                                    model: {
                                      value: institution.lost,
                                      callback: function ($$v) {
                                        _vm.$set(institution, "lost", $$v)
                                      },
                                      expression: "institution.lost",
                                    },
                                  },
                                  "money",
                                  _vm.customerCurrency,
                                  false
                                )
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "div",
                            { staticClass: "base-input" },
                            [
                              _c(
                                "money",
                                _vm._b(
                                  {
                                    staticClass: "input-field text-right",
                                    model: {
                                      value: institution.isr_withheld,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          institution,
                                          "isr_withheld",
                                          $$v
                                        )
                                      },
                                      expression: "institution.isr_withheld",
                                    },
                                  },
                                  "money",
                                  _vm.customerCurrency,
                                  false
                                )
                              ),
                            ],
                            1
                          ),
                        ]),
                      ])
                    }
                  ),
                  0
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex-content-right" },
                [
                  _c(
                    "base-button",
                    {
                      attrs: { size: "small", color: "info" },
                      on: { click: _vm.applyInterests },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t(
                              "financial_institutions.apply_financial_institutions"
                            )
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "border-top border-bottom text-center table-title" },
          [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("tax_returns.determination_of_tax")) +
                "\n  "
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "p-2" }, [
          _c(
            "div",
            { staticClass: "form-group flex-content-right" },
            [
              _c(
                "base-button",
                {
                  staticClass: "mr-2",
                  attrs: { color: "danger", size: "small" },
                  on: { click: _vm.openApplyTaxLossModal },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("tax_losses.apply_tax_loss")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _vm.taxReturn.arrendamiento
          ? _c("div", [
              _c("div", { staticClass: "table-responsive" }, [
                _c("table", { staticClass: "table" }, [
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [
                        _c("a", { attrs: { href: "#revenue" } }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t("tax_returns.accumulable_revenue")
                              ) +
                              "\n              "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount: _vm.taxDetermination.accumulable_revenue,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _c("a", { attrs: { href: "#persona_deductions" } }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t("tax_returns.personal_deductions")
                              ) +
                              "\n              "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount: _vm.taxDetermination.personal_deductions,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(_vm._s(_vm.$t("tax_returns.taxable_base"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount: _vm.taxDetermination.taxable_base,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "tax_returns.isr_according_to_the_annual_rate"
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount:
                                _vm.taxDetermination
                                  .isr_to_according_annual_rate,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm.$t("tax_returns.provisional_payments"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount: _vm.taxDetermination.provisional_payments,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _c("a", { attrs: { href: "#isr_withheld" } }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("tax_returns.isr_detained")) +
                              "\n              "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount: _vm.taxDetermination.isr_withheld,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(_vm._s(_vm.$t("tax_returns.tax_loss"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: { amount: _vm.taxDetermination.tax_loss },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(_vm._s(_vm.$t("tax_returns.isr_in_charge"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount: _vm.taxDetermination.isr_in_charge,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "border-top border-bottom text-center table-title" },
          [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.$t("tax_returns.determination_of_isr_natural_person")
                ) +
                "\n  "
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "row mt-2 mb-2" }, [
          _c("div", { staticClass: "col-6" }),
          _vm._v(" "),
          _c("div", { staticClass: "col-6" }, [
            _c("div", { staticClass: "flex-row" }, [
              _c("div", { staticClass: "flex-1" }, [
                _c("div", { staticClass: "form-group px-2" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.isrConcept,
                          expression: "form.isrConcept",
                        },
                      ],
                      staticClass: "form-control",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.form,
                            "isrConcept",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("tax_returns.select_concept"))),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.catalogs.isr_concepts, function (concept) {
                        return _c(
                          "option",
                          {
                            key: concept.description,
                            domProps: { value: concept },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(concept.description) +
                                "\n              "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "base-button",
                    {
                      attrs: { color: "theme", size: "small" },
                      on: { click: _vm.addISRConcept },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("tax_returns.add")) +
                          "\n          "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm.taxReturn.arrendamiento
          ? _c("div", [
              _c("div", { staticClass: "table-responsive" }, [
                _c("table", { staticClass: "table table-bordered" }, [
                  _c(
                    "tbody",
                    [
                      _c("tr", [
                        _c("td", [
                          _vm._v(_vm._s(_vm.$t("tax_returns.in_charge"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: { amount: _vm.isrIndividuals.in_charge },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.isrConcepts, function (concept, index) {
                        return _c("tr", { key: index }, [
                          _c("td", [_vm._v(_vm._s(concept.description))]),
                          _vm._v(" "),
                          _c("td", [
                            _c("div", { staticClass: "flex-row" }, [
                              _c("div", { staticClass: "flex-1 px-2" }, [
                                ![3].includes(concept.order)
                                  ? _c(
                                      "div",
                                      { staticClass: "base-input" },
                                      [
                                        _c(
                                          "money",
                                          _vm._b(
                                            {
                                              staticClass:
                                                "input-field text-right",
                                              model: {
                                                value: concept.amount,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    concept,
                                                    "amount",
                                                    $$v
                                                  )
                                                },
                                                expression: "concept.amount",
                                              },
                                            },
                                            "money",
                                            _vm.customerCurrency,
                                            false
                                          )
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "text-right" },
                                      [
                                        _c("money-text", {
                                          attrs: { amount: concept.amount },
                                        }),
                                      ],
                                      1
                                    ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "flex items-center" },
                                [
                                  ![3].includes(concept.order)
                                    ? _c("base-button", {
                                        attrs: {
                                          "icon-button": true,
                                          color: "theme",
                                          size: "small",
                                          icon: "trash",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeIsrConcept(index)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ])
                      }),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.$t("tax_returns.amount_in_charge"))
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: { amount: _vm.amountInCharge },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.$t("tax_returns.does_first_bias_apply"))
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("base-switch", {
                              attrs: { "switch-class": "margin-align-right" },
                              on: { change: _vm.cleanAmountWithFirstBias },
                              model: {
                                value:
                                  _vm.taxReturn.payment_isr_individuals
                                    .does_first_bias_apply,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.taxReturn.payment_isr_individuals,
                                    "does_first_bias_apply",
                                    $$v
                                  )
                                },
                                expression:
                                  "\n                taxReturn.payment_isr_individuals.does_first_bias_apply\n              ",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.taxReturn.payment_isr_individuals
                        .does_first_bias_apply
                        ? _c("tr", [
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("tax_returns.amount_with_first_bias")
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c(
                                "div",
                                { staticClass: "base-input" },
                                [
                                  _c(
                                    "money",
                                    _vm._b(
                                      {
                                        staticClass: "input-field text-right",
                                        model: {
                                          value:
                                            _vm.taxReturn
                                              .payment_isr_individuals
                                              .amount_with_first_bias,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.taxReturn
                                                .payment_isr_individuals,
                                              "amount_with_first_bias",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                  taxReturn.payment_isr_individuals.amount_with_first_bias\n                ",
                                        },
                                      },
                                      "money",
                                      _vm.customerCurrency,
                                      false
                                    )
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.taxReturn.payment_isr_individuals
                        .does_first_bias_apply
                        ? _c("tr", [
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "tax_returns.amount_without_first_bias"
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "text-right" },
                              [
                                _c("money-text", {
                                  attrs: { amount: _vm.amountWithoutFirstBias },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [
                          _vm._v(_vm._s(_vm.$t("tax_returns.amount_payable"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: { amount: _vm.amountPayable },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "mt-4 text-center clickable",
            attrs: { id: "table-revenue" },
            on: {
              click: function ($event) {
                return _vm.toggleDisplayTable("revenue")
              },
            },
          },
          [_c("ins", [_vm._v(_vm._s(_vm.$t("tax_returns.revenue_perceived")))])]
        ),
        _vm._v(" "),
        _vm.displayTables.revenue
          ? _c(
              "div",
              [
                _vm.taxReturn.tables.revenue
                  ? _c("tax-return-invoices-table", {
                      attrs: { invoices: _vm.taxReturn.tables.revenue },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "mt-4 text-center clickable",
            attrs: { id: "table-expenses" },
            on: {
              click: function ($event) {
                return _vm.toggleDisplayTable("purchases")
              },
            },
          },
          [_c("ins", [_vm._v(_vm._s(_vm.$t("tax_returns.purchases")))])]
        ),
        _vm._v(" "),
        _vm.displayTables.purchases
          ? _c(
              "div",
              [
                _vm.taxReturn.tables.purchases
                  ? _c("tax-return-invoices-table", {
                      attrs: { invoices: _vm.taxReturn.tables.purchases },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "mt-4 text-center clickable",
            attrs: { id: "table-deductions" },
            on: {
              click: function ($event) {
                return _vm.toggleDisplayTable("deductions")
              },
            },
          },
          [_c("ins", [_vm._v(_vm._s(_vm.$t("tax_returns.deductions")))])]
        ),
        _vm._v(" "),
        _vm.displayTables.deductions
          ? _c(
              "div",
              [
                _vm.taxReturn.tables.tax_deductions
                  ? _c("tax-return-invoices-table", {
                      attrs: {
                        invoices: _vm.taxReturn.tables.tax_deductions,
                        "can-display-no-object": true,
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "mt-4 text-center clickable",
            attrs: { id: "tables-wages-salaries" },
            on: {
              click: function ($event) {
                return _vm.toggleDisplayTable("salaries")
              },
            },
          },
          [
            _c("ins", [
              _vm._v(_vm._s(_vm.$t("tax_returns.wages_and_salaries"))),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.displayTables.salaries
          ? _c("div", { staticClass: "mt-4 table-responsive" }, [
              _c(
                "table",
                { staticClass: "table table-bordered invoices-table" },
                [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t("invoices.invoice_date"))),
                      ]),
                      _vm._v(" "),
                      _c("th", [_vm._v(_vm._s(_vm.$t("invoices.uuid")))]),
                      _vm._v(" "),
                      _c("th", [_vm._v(_vm._s(_vm.$t("invoices.rfc")))]),
                      _vm._v(" "),
                      _c("th", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm.$t("tax_returns.taxed_amount"))),
                      ]),
                      _vm._v(" "),
                      _c("th", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm.$t("tax_returns.exempt_amount"))),
                      ]),
                      _vm._v(" "),
                      _c("th", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm.$t("tax_returns.deduction_isr"))),
                      ]),
                      _vm._v(" "),
                      _c("th", { staticClass: "text-right" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("tax_returns.employment_subsidy")) +
                            "\n          "
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    [
                      _vm._l(
                        _vm.taxReturn.tables.wages_and_salaries,
                        function (invoice) {
                          return _c("tr", { key: invoice.id }, [
                            _c("td", [_vm._v(_vm._s(invoice.invoice_date))]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "invoices.view",
                                        params: { id: invoice.id },
                                      },
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(invoice.uuid) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(invoice.rfc))]),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "text-right" },
                              [
                                _c("money-text", {
                                  attrs: { amount: invoice.taxed_amount },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "text-right" },
                              [
                                _c("money-text", {
                                  attrs: { amount: invoice.exempt_amount },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "text-right" },
                              [
                                _c("money-text", {
                                  attrs: { amount: invoice.deduction_isr },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "text-right" },
                              [
                                _c("money-text", {
                                  attrs: { amount: invoice.employment_subsidy },
                                }),
                              ],
                              1
                            ),
                          ])
                        }
                      ),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", { attrs: { colspan: "3" } }),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: {
                                amount:
                                  _vm.wagesAndSaliariesTotals.taxed_amount,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: {
                                amount:
                                  _vm.wagesAndSaliariesTotals.exempt_amount,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: {
                                amount:
                                  _vm.wagesAndSaliariesTotals.deduction_isr,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: {
                                amount:
                                  _vm.wagesAndSaliariesTotals
                                    .employment_subsidy,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                ]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "mt-4 text-center clickable",
            attrs: { id: "table-personal-deductions" },
            on: {
              click: function ($event) {
                return _vm.toggleDisplayTable("personalDeductions")
              },
            },
          },
          [
            _c("ins", [
              _vm._v(_vm._s(_vm.$t("tax_returns.personal_deductions"))),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.displayTables.personalDeductions
          ? _c(
              "div",
              [
                _vm._l(
                  _vm.taxReturn.tables.personal_deductions,
                  function (subcategory) {
                    return _c(
                      "div",
                      {
                        key: subcategory.id,
                        staticClass: "mt-4 table-responsive",
                      },
                      [
                        _c(
                          "table",
                          {
                            staticClass: "table table-bordered invoices-table",
                          },
                          [
                            _c("thead", [
                              _c("tr", { staticClass: "border-bottom" }, [
                                _c(
                                  "th",
                                  {
                                    staticClass: "text-center",
                                    attrs: { colspan: "6" },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(subcategory.subcategory.name) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [
                                  _vm._v(
                                    _vm._s(_vm.$t("invoices.invoice_date"))
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("th", [
                                  _vm._v(_vm._s(_vm.$t("invoices.uuid"))),
                                ]),
                                _vm._v(" "),
                                _c("th", [
                                  _vm._v(_vm._s(_vm.$t("invoices.rfc"))),
                                ]),
                                _vm._v(" "),
                                _c("th", [
                                  _vm._v(_vm._s(_vm.$t("invoices.name"))),
                                ]),
                                _vm._v(" "),
                                _c("th", [
                                  _vm._v(
                                    _vm._s(_vm.$t("invoices.item.description"))
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("th", { staticClass: "text-right" }, [
                                  _vm._v(_vm._s(_vm.$t("invoices.amount"))),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              _vm._l(subcategory.items, function (item) {
                                return _c("tr", { key: item.id }, [
                                  _c("td", [_vm._v(_vm._s(item.invoice_date))]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "invoices.view",
                                              params: { id: item.invoice_id },
                                            },
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.uuid) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(item.rfc))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(item.name))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(item.description))]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right" }, [
                                    _c(
                                      "div",
                                      { staticClass: "base-input" },
                                      [
                                        _c(
                                          "money",
                                          _vm._b(
                                            {
                                              staticClass:
                                                "input-field text-right",
                                              model: {
                                                value: item.amount_edit,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "amount_edit",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.amount_edit",
                                              },
                                            },
                                            "money",
                                            _vm.customerCurrency,
                                            false
                                          )
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ])
                              }),
                              0
                            ),
                          ]
                        ),
                      ]
                    )
                  }
                ),
                _vm._v(" "),
                _vm.can.applyPersonalDeductions
                  ? _c(
                      "div",
                      { staticClass: "mt-2 flex-content-right" },
                      [
                        _c(
                          "base-button",
                          {
                            attrs: { size: "small", color: "info" },
                            on: { click: _vm.applyPersonalDeductions },
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(
                                  _vm.$t(
                                    "tax_returns.apply_personal_deductions"
                                  )
                                ) +
                                "\n      "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }