<template>
  <div v-bind:style="{ maxHeight: stylesHeight, overflowY: 'scroll' }">
    <div class="row mt-1">
      <div class="col col-sm-8 offset-sm-2">
        <base-input v-model="form.search" />
      </div>
    </div>

    <div class="table-responsive">
      <table-component
        ref="table"
        :show-filter="false"
        :data="fetchData"
        table-class="table"
        :is-clickable="true"
        @columnClick="useCategory"
      >
        <table-column
          :label="$t('concept_categories.category')"
          show="concept_category"
        >
          <template slot-scope="row">
            <div>
              {{ row.concept_category.name }}
            </div>
          </template>
        </table-column>

        <table-column :label="$t('concept_categories.subcategory')" show="name">
          <template slot-scope="row">
            <div>
              {{ row.name }}
            </div>
          </template>
        </table-column>
      </table-component>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
const defaultUseEvent = 'invoiceItemCategoryChanged'
export default {
  data: () => ({
    form: {
      search: '',
    },
    loading: false,
    prodServMatches: [],
    click: true,

  }),
  watch: {
    // Observa la propiedad CurrentCustomer
    currentCustomer: {
      handler: 'fetchDataOnCurrentCustomerChange',
      immediate: true, // Llama inmediatamente al cargarse el componente.
    },
  },
  computed: {
    ...mapGetters('CurrentCustomer', ['currentCustomer']),

    ...mapGetters('conceptCategories', [
      'modalChangeSubcategoryData',
      'subcategoriesUser',
      'categoriesSelect',
    ]),
    stylesHeight: () => {
      const heigth = window.innerHeight - 70
      return `${heigth}px`
    },
    eventUseCategory() {
      if (this.modalChangeSubcategoryData.hasOwnProperty('event')) {
        return this.modalChangeSubcategoryData.event
      }
      return defaultUseEvent
    },
    filteredSubcategories() {
      if (!this.form.search) {
        return this.subcategoriesUser
      }
      const subcategories = this.subcategoriesUser.filter((sub) => {
        const name = sub.name.toLowerCase()
        const categoryName = sub.concept_category.name.toLowerCase()
        const search = this.form.search.toLowerCase()
        return name.indexOf(search) > -1 || categoryName.indexOf(search) > -1
      })
      return subcategories
    },
  },

  mounted() {},

  methods: {
    ...mapActions('conceptCategories', [
      'fetchSubcategoriesUser',
      'clearCategoriesSelect',
    ]),
    ...mapActions('invoice', [
      'setCategoryToConcept',
      'setCategoryToMultipleConcepts',
    ]),
    ...mapActions('modal', ['closeModal']),
    async fetchDataOnCurrentCustomerChange(newCustomer) {
      if (newCustomer) {
        this.fetchSubcategoriesUser({ user_id: newCustomer.id }).then(() => {
          this.refreshTable()
        })
        // this.fetchAssigned(newCustomer.id)
      }
    },
    async fetchData({ page, sort }) {
      let response = await this.fetchSubcategoriesUser({
        user_id: this.currentCustomer.id,
      })
      // return response.data.subcategories

      return {
        data: response.data.subcategories,
        pagination: {
          totalPages: response.data.subcategories.last_page,
          currentPage: page,
        },
      }
    },
    useCategory(rowData) {
      if (this.click) {
        this.click = false
        if (this.eventUseCategory == defaultUseEvent) {
          this.setSubcategoryToInvoiceItem(rowData.row)
        } else {
          window.hub.$emit(this.eventUseCategory, {
            invoice_item_id: this.modalChangeSubcategoryData.invoice_item_id,
            subcategory: rowData.row,
          })
          this.closeModal()
        }
      }
    },

    setSubcategoryToInvoiceItem(subcategory) {
      this.loading = true
      const data = {
        id: this.categoriesSelect[0].invoice_id,
        form: {
          ids: [],
          concept_subcategory_id: subcategory.id,
        },
      }
      for (let item of this.categoriesSelect) {
        data.form.ids.push(item.id)
      }
      this.setCategoryToMultipleConcepts(data)
        .then((response) => {
          this.loading = false
          window.hub.$emit(this.eventUseCategory, {
            invoice_id: this.modalChangeSubcategoryData.invoice_id,
            invoice_item_id: this.modalChangeSubcategoryData.invoice_item_id,
            subcategory_id: subcategory.id,
          })
          this.closeModal()
          window.toastr['success'](
            this.$t('customer_prod_serv.messages.category_updated')
          )
        })
        .catch((err) => {
          this.loading = false
          window.toastr['error'](
            this.$t('customer_prod_serv.errors.category_update')
          )
        })
      this.clearCategoriesSelect()
    },
    refreshTable() {
      this.$refs.table.refresh()
    },
  },
}
</script>
