import * as types from './mutation-types'

export const setSubcategoryModalData = ({ commit }, data) => {
  commit(types.SET_SUB_CATEGORY_MODAL_DATA, data)
}

export const setModalChangeSubcategoryData = ({ commit }, data) => {
  commit(types.SET_MODAL_CHANGE_CATEGORY_DATA, data)
}

export const addCategoriesSelect = ({ commit }, data) => {
  commit(types.ADD_CATEGORIES_SELECT, data)
}
export const addCategoriesSelectAll = ({ commit }, data) => {
  commit(types.ADD_CATEGORIES_SELECT_ALL, data)
}
export const clearCategoriesSelect = ({ commit },data) => {
  commit(types.CLEAR_CATEGORIES_SELECT)
}
export const removeCategoriesSelect = ({ commit },data) => {
  commit(types.REMOVE_CATEGORIES_SELECT,data)
}
export const fetchCategories = (store, params) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/concept-categories/categories`, { params })
      .then(resolve)
      .catch(reject)
  })
}

export const fetchCategoriesByUser = ({ commit, dispatch, state }, params) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/concept-categories/categories/user`, { params })
      .then(resolve)
      .catch(reject)
  })
}

export const fetchSubcategories = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/concept-categories/subcategories`, { params })
      .then((response) => {
        commit(types.SET_SUBCATEGORIES, response.data.subcategories)
        resolve(response)
      })
      .catch(reject)
  })
}

export const fetchSubcategoriesUser = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/concept-categories/subcategories/user`, { params })
      .then((response) => {
        commit(types.SET_SUBCATEGORIES_USER, response.data.subcategories)
        resolve(response)
      })
      .catch(reject)
  })
}

export const fetchSubcategory = (store, id) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/concept-categories/subcategories/${id}`)
      .then(resolve)
      .catch(reject)
  })
}

export const fetchCreateConceptCategory = (store, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/concept-categories/categories/create`, data)
      .then(resolve)
      .catch(reject)
  })
}
export const fetchCreateConceptCategoryUser = (store, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/concept-categories/categories/create/user`, data)
      .then(resolve)
      .catch(reject)
  })
}

export const fetchDeleteConceptCategory = (store, id) => {
  return new Promise((resolve, reject) => {
    window.axios
      .delete(`/api/concept-categories/categories/${id}`)
      .then(resolve)
      .catch(reject)
  })
}

export const fetchCreateConceptSubcategory = (store, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/concept-categories/subcategories/create`, data)
      .then(resolve)
      .catch(reject)
  })
}

export const fetchUpdateConceptSubcategory = (store, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .put(`/api/concept-categories/subcategories/${data.id}`, data.form)
      .then(resolve)
      .catch(reject)
  })
}

export const fetchDeleteConceptSubcategory = (store, id) => {
  return new Promise((resolve, reject) => {
    window.axios
      .delete(`/api/concept-categories/subcategories/${id}`)
      .then(resolve)
      .catch(reject)
  })
}
