<template>
  <div>{{ taxRegimeDescription }}</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    taxRegime: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters('catalogs', ['taxRegimes']),

    taxRegimeDescription() {
      const taxRegime = this.taxRegimes.filter(
        (regime) => regime.id == this.taxRegime
      )
      if (taxRegime.length) {
        return taxRegime[0].description
      }

      return this.$t('customers.unknown_tax_regime')
    },
  },
}
</script>
