var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sidebar-left" }, [
    _c("div", { staticClass: "sidebar-body scroll-pane" }, [
      _c(
        "div",
        { staticClass: "side-nav" },
        _vm._l(_vm.menu, function (menuItems, index) {
          return _c(
            "div",
            { key: index, staticClass: "menu-group" },
            _vm._l(menuItems, function (item, index1) {
              return _c(
                "router-link",
                {
                  key: index1,
                  staticClass: "menu-item",
                  attrs: { to: item.route },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.Toggle.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "icon menu-icon",
                    attrs: { icon: item.icon },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "ml-3 menu-text" }, [
                    _vm._v(_vm._s(_vm.$t(item.title))),
                  ]),
                ],
                1
              )
            }),
            1
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }