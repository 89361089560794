var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "btn btn-primary",
      attrs: { disabled: _vm.categoriesSelect.length === 0 },
      on: { click: _vm.showModal },
    },
    [
      _vm._v(
        "\n  Aplicar categorías (" + _vm._s(_vm.categoriesSelect.length) + ")\n"
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }