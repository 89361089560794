var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", { staticClass: "item-row invoice-item-row" }, [
    _c(
      "td",
      { staticClass: "text-left", staticStyle: { padding: "10px !important" } },
      [
        _c(
          "div",
          {
            staticClass: "btn-group discount-drop-down",
            attrs: { role: "group" },
          },
          [
            _c("base-select", {
              attrs: {
                options: _vm.unitCodeOptions,
                searchable: true,
                "allow-empty": false,
                "show-labels": false,
                placeholder: _vm.$t("items.select_a_unit"),
                invalid: _vm.$v.item.id.$error,
                label: "description",
                "track-by": "id",
              },
              model: {
                value: _vm.item.id,
                callback: function ($$v) {
                  _vm.$set(_vm.item, "id", $$v)
                },
                expression: "item.id",
              },
            }),
            _vm._v(" "),
            _vm.$v.item.id.$error
              ? _c("div", [
                  !_vm.$v.item.id.required
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("validation.required")) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }