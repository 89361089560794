<template>
  <div class="main-content mt-5">
    <div class="page-header">
      <h3 class="page-title">
        {{ $t('tax_returns.title') }}
      </h3>

      <div class="page-actions row">
        <router-link slot="item-title" :to="{ name: 'tax-return.tax-losses' }" class="col-xs-2 mr-2">
          <base-button color="theme" icon="plus" size="large">
            {{ $t('tax_losses.title') }}
          </base-button>
        </router-link>
        <!-- <router-link
          slot="item-title"
          :to="{ name: 'tax-return.create' }"
          class="col-xs-2"
        >
          <base-button color="theme" icon="plus" size="large" @click="generateTax">
            Generar Borrador
          </base-button>
        </router-link> -->
        <base-button color="theme" icon="plus" size="large" @click="generateTax">
          Generar Borrador
        </base-button>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card p-4">
          <table-component ref="table" :data="taxReturns" :filter-no-results="$t('general.filterNoResults')"
            :is-clickable="true" :show-filter="false" table-class="table">
            <table-column label="PERÍODO">
              <template slot-scope="row">
                <font-awesome-icon v-if="row.complementaria == true" class="icon" icon="file-invoice" />
                <router-link :to="{
                  name: 'tax-return.show',
                  params: {
                    id: row.id,
                    period:
                      period(row.start_month, row.end_month) + ' ' + row.year,
                    startMonth: row.start_month,
                    endMonth: row.end_month,
                    year: row.year,
                  },
                }">
                  {{ period(row.start_month, row.end_month) }}
                </router-link>
              </template>
            </table-column>
            <table-column label="AÑO" show="year" />
            <table-column label="RÉGIMEN FISCAL">
              <template slot-scope="row">
                <tax-regime-catalog :tax-regime="row.tax_regime" />
              </template>
            </table-column>
            <table-column label="ISR A PAGAR">
              <template slot-scope="row">
                <div v-html="$utils.formatMoney(row.isr_to_pay, defaultCurrency)" />
              </template>
            </table-column>
            <table-column label="IVA A CARGO">
              <template slot-scope="row">
                <div v-html="$utils.formatMoney(row.iva_in_charge, defaultCurrency)
                  " />
              </template>
            </table-column>
            <table-column label="" cell-class="no-click">
              <template slot-scope="row">
                <base-button size="small" color="theme" @click="
                  enviaDeclaracion(
                    row.id,
                    row.status,
                    period(row.start_month, row.end_month) + ' ' + row.year
                  )
                  ">
                  Enviar
                </base-button>
              </template>
            </table-column>
            <table-column label="" cell-class="no-click">
              <template slot-scope="row" v-if="row.url != null">
                <base-button size="small" color="theme" @click="verPdf(row.url)">
                  Ver PDF
                </base-button>
              </template>
            </table-column>
            <table-column label="ESTATUS" cell-class="no-click">
              <template slot-scope="row">
                <div>
                  {{ row.status }}
                </div>
              </template>
            </table-column>
            <table-column label="CHAT" cell-class="no-click">
              <template slot-scope="row">
                <div style="padding-left: 15px" v-if="row.chat > 1">
                  <font-awesome-icon class="text-blue" icon="comment" size="lg" @click="
                    goChat(
                      row.id,
                      period(row.start_month, row.end_month) + ' ' + row.year
                    )
                    " />
                </div>
              </template>
            </table-column>
            <table-column label="Ultima Actualización" show="updated_at" />
          </table-component>

          <!-- !tabla de pdfs -->
          <!-- <table-component ref="table" :data="pdfs" :filter-no-results="$t('general.filterNoResults')"
            :is-clickable="true" :show-filter="false" table-class="table">
            <table-column label="ID" show="id" />
            <table-column label="URL" cell-class="no-click">
              <template slot-scope="row">
                <div style="padding-left: 15px">
                  <a :href="`${row.url}`" target="_blank"
                    class="mr-3 default-size invoice-action-btn" outline
                 >{{ row.url }}</a>
                </div>
              </template>
            </table-column>

            <table-column label="Generado" show="created_at" />
          </table-component> -->
          <!--! termina tabla de pdfs -->
        </div>
        <!-- <div v-if="viewBorrador"> -->
        <create-tax-return />
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MonthPeriod from '../components/MonthPeriod.vue'
import TaxRegimeCatalog from '../components/TaxRegimeCatalog.vue'
import IconInCustomerTaxReturn from '../components/IconInCustomerTaxReturn'
import TableColumn from '../../components/base/base-table/components/TableColumn.vue'
import CreateTaxReturn from './Generate.vue'
import { StatusAssigned } from '../../store/modules/assigned/enum'

export default {
  components: {
    MonthPeriod,
    TaxRegimeCatalog,
    IconInCustomerTaxReturn,
    TableColumn,
    CreateTaxReturn,
  },
  data: () => ({
    taxReturns: [],
    pdfs: [],

    months: [
      { id: '01', name: 'Enero' },
      { id: '02', name: 'Febrero' },
      { id: '03', name: 'Marzo' },
      { id: '04', name: 'Abril' },
      { id: '05', name: 'Mayo' },
      { id: '06', name: 'Junio' },
      { id: '07', name: 'Julio' },
      { id: '08', name: 'Agosto' },
      { id: '09', name: 'Septiembre' },
      { id: '10', name: 'Octubre' },
      { id: '11', name: 'Noviembre' },
      { id: '12', name: 'Diciembre' },
    ],
    viewBorrador: false,
    year: '',
    month: '',
  }),
  computed: {
    ...mapGetters('CurrentCustomer', ['currentCustomer']),
    ...mapGetters('currency', ['defaultCurrency']),
    ...mapGetters('calendar', ['defaultYear', 'defaultMonth']),
    ...mapGetters('assigned', ['assingedSelect']),
  },
  mounted() {
    this.loadTaxReturns()
    this.loadPdfsTaxReturns()
    sessionStorage.removeItem('idChatDeclaracion')
    sessionStorage.removeItem('periodoChatDeclaracion')
    sessionStorage.removeItem('periodTaxReturn')
    sessionStorage.removeItem('yearTaxReturn')
  },
  created() {
    let sessionFilterMonth
    if (sessionStorage.getItem('filterTaxReturnMonth') != 'undefined') {
      sessionFilterMonth = JSON.parse(
        sessionStorage.getItem('filterTaxReturnMonth')
      )
    }
    let sessionFilterYear = sessionStorage.getItem('filtersTaxReturnYear')

    if (this.defaultMonth && this.defaultYear) {
      this.year = this.defaultYear
      this.month = this.defaultMonth

      sessionStorage.setItem(
        'filterTaxReturnMonth',
        JSON.stringify(this.defaultMonth)
      )
      sessionStorage.setItem('filtersTaxReturnYear', this.defaultYear)
    } else {
      this.year = sessionFilterYear
      this.month = sessionFilterMonth
    }
  },
  methods: {
    ...mapActions('taxReturns', [
      'listTaxReturns',
      'statusTaxReturns',
      'messageTaxReturns',
      'fetchExistChat',
    ]),
    ...mapActions('assigned', [
      'updateStatusAssignedDeclaration',
      'setAssignedSelect',
    ]),
    ...mapActions('assigned', [
      'getPdfs',
    ]),

    period(startMonth, endMonth) {
      if (!startMonth && !endMonth) {
        return ''
      } else if (startMonth == endMonth) {
        const month = this.months.find(
          (m) => Number(m.id) == Number(startMonth)
        )
        return month.name
      }
      const cStartMonth = this.months.find(
        (m) => Number(m.id) == Number(startMonth)
      )
      const cEndMonth = this.months.find(
        (m) => Number(m.id) == Number(endMonth)
      )

      return `${cStartMonth.name} - ${cEndMonth.name}`
    },
    loadTaxReturns() {
      const params = {
        customer: this.currentCustomer.id,
        year: this.year,
        month: this.month.name.toLowerCase(),
      }
      this.listTaxReturns(params).then((response) => {
        this.taxReturns = response.data.tax_returns
        // console.log(this.taxReturns)
      })
    },
    loadPdfsTaxReturns() {

      this.getPdfs().then((response) => {
        this.pdfs = response.data.pdfs
      })
    },
    enviaDeclaracion(id, statusIcon, periodo) {
      // if (!statusIcon) {
      this.sendMessage(
        id,
        'Hola, le dejo su declaración de ' +
        periodo +
        ' para que la revise y en su caso autorice el envío al SAT.'
      )
      // } else if (statusIcon == 'comentarios') {
      //   sessionStorage.setItem('idChatDeclaracion', id)
      //   sessionStorage.setItem('periodoChatDeclaracion', periodo)
      //   this.$router.push({
      //     name: 'chat.index',
      //   })
      // }
    },
    async sendMessage(idTaxReturn, message) {
      window
        .swal({
          title: this.$t('general.are_you_sure'),
          text: this.$t('tax_returns.confirm_send_declaracion'),
          icon: '/assets/icon/paper-plane-solid.svg',
          buttons: true,
          dangerMode: true,
        })
        .then(async (value) => {
          if (value) {
            await this.messageTaxReturns({
              idTaxReturn: idTaxReturn,
              message: message,
            })
            console.log(this.assingedSelect)
            if (this.assingedSelect.status.slug !== StatusAssigned.ENVIADO) {
              this.updateStatusAssignedDeclaration({
                id: this.assingedSelect.id,
                slug: StatusAssigned.ENVIADO,
              }).then((res) => {
                this.setAssignedSelect(res.data.declaration)
              })
            }
            this.loadTaxReturns()
            this.loadPdfsTaxReturns()

          }
        })
    },

    generateTax() {
      window
        .swal({
          title: 'Esta seguro de generar un borrador?',
          // text: this.$t('tax_returns.confirm_send_declaracion'),
          icon: '/assets/icon/file-earmark-spreadsheet.svg',
          buttons: true,
          dangerMode: true,
        })
        .then(async (value) => {
          if (value) {
            this.viewBorrador = true
          }
        })
    },
    rowClicked(rowData) {
      this.$router.push({
        name: 'tax-return.show',
        params: {
          id: rowData.row.id,
        },
      })
    },

    goChat(id, periodo) {
      sessionStorage.setItem('idChatDeclaracion', id)
      sessionStorage.setItem('periodoChatDeclaracion', periodo)
      this.$router.push({
        name: 'chat.index',
      })
    },

    verPdf(ruta) {
      window.open(`${ruta}`, '_blank')
    },
  },
}
</script>
