<template>
  <span>
    {{ period }}
  </span>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    startMonth: {
      type: Number,
      default: null,
    },
    endMonth: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters('calendar', ['getMonthByNumer']),
    period() {
      if (!this.startMonth && !this.endMonth) {
        return ''
      } else if (this.startMonth == this.endMonth) {
        const month = this.getMonthByNumer(this.startMonth)
        return month.name
      }
      const startMonth = this.getMonthByNumer(this.startMonth)
      const endMonth = this.getMonthByNumer(this.endMonth)

      return `${startMonth.name} - ${endMonth.name}`
    },
  },
}
</script>
