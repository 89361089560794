<template>
  <div class="main-content mt-5">
    <div class="page-header">
      <h3 class="page-title">
       Declaraciones Presentadas
      </h3>

      <!-- <div class="page-actions row">
        <router-link
          slot="item-title"
          :to="{ name: 'tax-return.tax-losses' }"
          class="col-xs-2 mr-2"
        >
          <base-button color="theme" icon="plus" size="large">
            {{ $t('tax_losses.title') }}
          </base-button>
        </router-link>
        <router-link
          slot="item-title"
          :to="{ name: 'tax-return.create' }"
          class="col-xs-2"
        >
          <base-button color="theme" icon="plus" size="large">
            {{ $t('tax_returns.add_tax_return') }}
          </base-button>
        </router-link>
      </div> -->
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card p-4">
          <table-component
            ref="table"
            :data="taxReturns"
            :filter-no-results="$t('general.filterNoResults')"
            :is-clickable="true"
            :show-filter="false"
            table-class="table"
          >
            <table-column label="PERÍODO">
              <template slot-scope="row">
                <div v-if="row.complementaria">
                  <font-awesome-icon class="icon" icon="file-invoice" />
                </div>
                <div>
                  {{ period(row.start_month, row.end_month) + ' ' + row.year }}
                </div>
              </template>
            </table-column>
            <table-column label="AÑO" show="year" />
            <table-column label="RÉGIMEN FISCAL">
              <template slot-scope="row">
                <tax-regime-catalog :tax-regime="row.tax_regime" />
              </template>
            </table-column>
            <table-column label="ISR A PAGAR">
              <template slot-scope="row">
                <div
                  v-html="$utils.formatMoney(row.isr_to_pay, defaultCurrency)"
                />
              </template>
            </table-column>
            <table-column label="IVA A CARGO">
              <template slot-scope="row">
                <div
                  v-html="
                    $utils.formatMoney(row.iva_in_charge, defaultCurrency)
                  "
                />
              </template>
            </table-column>
            <table-column label="ESTATUS" cell-class="no-click">
              <template slot-scope="row">
                <div style="padding-left: 15px">
                  <icon-in-customer-tax-return
                    :id="row.id"
                    :status="row.status"
                    module="account_statement"
                    @click="
                      enviaDeclaracion(
                        row.id,
                        row.status,
                        period(row.start_month, row.end_month) + ' ' + row.year
                      )
                    "
                  />
                </div>
              </template>
            </table-column>
            <table-column label="CHAT" cell-class="no-click">
              <template slot-scope="row">
                <div style="padding-left: 15px" v-if="row.chat > 1">
                  <font-awesome-icon
                    class="text-blue"
                    icon="comment"
                    size="lg"
                    @click="
                      goChat(
                        row.id,
                        period(row.start_month, row.end_month) + ' ' + row.year
                      )
                    "
                  />
                </div>
              </template>
            </table-column>
          </table-component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MonthPeriod from '../components/MonthPeriod.vue'
import TaxRegimeCatalog from '../components/TaxRegimeCatalog.vue'
import IconInCustomerTaxReturn from '../components/IconInCustomerTaxReturn'
import TableColumn from '../../components/base/base-table/components/TableColumn.vue'
export default {
  components: {
    MonthPeriod,
    TaxRegimeCatalog,
    IconInCustomerTaxReturn,
    TableColumn,
  },
  data: () => ({
    taxReturns: [],
    months: [
      { id: '01', name: 'Enero' },
      { id: '02', name: 'Febrero' },
      { id: '03', name: 'Marzo' },
      { id: '04', name: 'Abril' },
      { id: '05', name: 'Mayo' },
      { id: '06', name: 'Junio' },
      { id: '07', name: 'Julio' },
      { id: '08', name: 'Agosto' },
      { id: '09', name: 'Septiembre' },
      { id: '10', name: 'Octubre' },
      { id: '11', name: 'Noviembre' },
      { id: '12', name: 'Diciembre' },
    ],
  }),
  computed: {
    ...mapGetters('CurrentCustomer', ['currentCustomer']),
    ...mapGetters('currency', ['defaultCurrency']),
  },
  mounted() {
    this.loadTaxReturns()
    sessionStorage.removeItem('idChatDeclaracion')
    sessionStorage.removeItem('periodoChatDeclaracion')
    sessionStorage.removeItem('periodTaxReturn')
    sessionStorage.removeItem('yearTaxReturn')
  },

  methods: {
    ...mapActions('taxReturns', [
      'listTaxReturns',
      'statusTaxReturns',
      'messageTaxReturns',
      'fetchExistChat',
    ]),

    period(startMonth, endMonth) {
      if (!startMonth && !endMonth) {
        return ''
      } else if (startMonth == endMonth) {
        const month = this.months.find(
          (m) => Number(m.id) == Number(startMonth)
        )
        return month.name
      }
      const cStartMonth = this.months.find(
        (m) => Number(m.id) == Number(startMonth)
      )
      const cEndMonth = this.months.find(
        (m) => Number(m.id) == Number(endMonth)
      )

      return `${cStartMonth.name} - ${cEndMonth.name}`
    },
    loadTaxReturns() {
      const params = {
        customer: this.currentCustomer.id,
      }
      this.listTaxReturns(params).then((response) => {
        this.taxReturns = response.data.tax_returns
      })
    },
    enviaDeclaracion(id, statusIcon, periodo) {
      if (!statusIcon) {
        this.sendMessage(
          id,
          'Hola, le dejo su declaración de ' +
            periodo +
            ' para que la revise y en su caso autorice el envío al SAT.'
        )
      } else if (statusIcon == 'comentarios') {
        sessionStorage.setItem('idChatDeclaracion', id)
        sessionStorage.setItem('periodoChatDeclaracion', periodo)
        this.$router.push({
          name: 'chat.index',
        })
      }
    },
    async sendMessage(idTaxReturn, message) {
      window
        .swal({
          title: this.$t('general.are_you_sure'),
          text: this.$t('tax_returns.confirm_send_declaracion'),
          icon: '/assets/icon/paper-plane-solid.svg',
          buttons: true,
          dangerMode: true,
        })
        .then(async (value) => {
          if (value) {
            await this.messageTaxReturns({
              idTaxReturn: idTaxReturn,
              message: message,
            })
            this.loadTaxReturns()
          }
        })
    },
    rowClicked(rowData) {
      this.$router.push({
        name: 'tax-return.show',
        params: {
          id: rowData.row.id,
        },
      })
    },

    goChat(id, periodo) {
      sessionStorage.setItem('idChatDeclaracion', id)
      sessionStorage.setItem('periodoChatDeclaracion', periodo)
      this.$router.push({
        name: 'chat.index',
      })
    },
  },
}
</script>
