var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contracted main-content" },
    [
      _c(
        "div",
        { staticClass: "page-header mt-4", staticStyle: { "z-index": "1" } },
        [
          _c("h3", { staticClass: "page-title" }, [
            _vm._v("Períodos - Contratados"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btn-group", attrs: { role: "group" } },
            [
              _c(
                "conditional-wrapper",
                {
                  attrs: {
                    "show-for-admin": true,
                    "show-for-executives": false,
                    "show-for-customers": false,
                  },
                },
                [
                  _c(
                    "base-button",
                    {
                      attrs: { color: "theme" },
                      on: {
                        click: function ($event) {
                          return _vm.assignRoute()
                        },
                      },
                    },
                    [_vm._v("\n          Agregar / Modificar\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.yearsKeys, function (year, index) {
        return _c(
          "div",
          { key: year },
          [
            _c(
              "b-card-header",
              { attrs: { "header-tag": "header", role: "tab" } },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "b-toggle",
                        rawName: "v-b-toggle",
                        value: "collapse-year-" + index,
                        expression: "'collapse-year-' + index",
                      },
                    ],
                    staticClass: "pl-2",
                    attrs: { variant: "primary" },
                  },
                  [_vm._v("\n\n        " + _vm._s(year) + "\n      ")]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "b-collapse",
              {
                staticClass: "my-2",
                attrs: { id: "collapse-year-" + index, visible: index == 0 },
              },
              _vm._l(
                _vm.statusYearKey(`${year}`),
                function (status, indexStatus) {
                  return _c(
                    "div",
                    { key: status },
                    [
                      _c(
                        "b-card-header",
                        {
                          staticClass: "bg-primary text-white",
                          attrs: {
                            "header-tag": "header",
                            role: "tab",
                            variant: "primary",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "b-toggle",
                                  rawName: "v-b-toggle",
                                  value:
                                    "collapse-status-" +
                                    indexStatus +
                                    "index" +
                                    index,
                                  expression:
                                    "'collapse-status-' + indexStatus+'index'+index",
                                },
                              ],
                              staticClass: "pl-2",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(status) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          staticClass: "my-2",
                          attrs: {
                            id:
                              "collapse-status-" +
                              indexStatus +
                              "index" +
                              index,
                            visible: true,
                          },
                        },
                        _vm._l(
                          _vm.statusYearValue(`${year}`, `${status}`),
                          function (statusYear, index) {
                            return _c(
                              "div",
                              { key: year + status + statusYear.month },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "pl-4 my-3",
                                    class:
                                      statusYear.status.slug === "PAGADA"
                                        ? "disabled"
                                        : "",
                                    on: {
                                      click: function ($event) {
                                        statusYear.status.slug !== "PAGADA" &&
                                          _vm.setCustomerToViewDetails(
                                            statusYear.year,
                                            statusYear.month,
                                            statusYear
                                          )
                                      },
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: { content: status },
                                          expression: "{ content: status }",
                                        },
                                      ],
                                      staticClass: "icon menu-icon mr-2",
                                      style: { color: "#88C349" },
                                      attrs: {
                                        icon: ["fas", statusYear.status.icon],
                                        size: "1x",
                                      },
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(statusYear.month) +
                                        " - " +
                                        _vm._s(statusYear.year) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ],
                    1
                  )
                }
              ),
              0
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }