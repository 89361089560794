var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-content" }, [
    _c("div", { staticClass: "page-title" }, [
      _c("h3", { staticClass: "page-title" }, [
        _vm._v("\n      " + _vm._s(_vm.$t("retenciones.retention")) + "\n    "),
      ]),
    ]),
    _vm._v(" "),
    _vm.retention
      ? _c("div", [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col col-md-6" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body text-center" }, [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$t("retenciones.issuing_name"))),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("strong", [_vm._v(_vm._s(_vm.retention.issuing_rfc))]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("strong", [_vm._v(_vm._s(_vm.retention.issuing_name))]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col col-md-6" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body text-center" }, [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$t("retenciones.receiver_name"))),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("strong", [_vm._v(_vm._s(_vm.retention.receiver_type))]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("strong", [_vm._v(_vm._s(_vm.retention.receiver_rfc))]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("strong", [_vm._v(_vm._s(_vm.retention.receiver_name))]),
                  _vm._v(" "),
                  _vm.retention.receiver_curpr
                    ? _c("div", [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.$t("retenciones.receiver_curpr"))),
                        ]),
                        _vm._v(
                          " " +
                            _vm._s(_vm.retention.receiver_curpr) +
                            "\n            "
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-4 text-center" }, [
                  _c("strong", [_vm._v(_vm._s(_vm.$t("retenciones.date")))]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.retention.retention_date) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-4 text-center" }, [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$t("retenciones.stamped_date"))),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.retention.retention_stamped_date) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-4 text-center" }, [
                  _c("strong", [_vm._v(_vm._s(_vm.$t("retenciones.uuid")))]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.retention.uuid) +
                      "\n          "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col col-md-3 text-center" }, [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$t("retenciones.total_exempt_amount"))),
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$utils.formatMoney(
                          _vm.retention.total_exempt_amount,
                          _vm.defaultCurrency
                        )
                      ),
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col col-md-3 text-center" }, [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$t("retenciones.total_amount_taxed"))),
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$utils.formatMoney(
                          _vm.retention.total_amount_taxed,
                          _vm.defaultCurrency
                        )
                      ),
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col col-md-3 text-center" }, [
                  _c("strong", [
                    _vm._v(
                      _vm._s(_vm.$t("retenciones.total_transaction_amount"))
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$utils.formatMoney(
                          _vm.retention.total_transaction_amount,
                          _vm.defaultCurrency
                        )
                      ),
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col col-md-3 text-center" }, [
                  _c("strong", [
                    _vm._v(
                      _vm._s(_vm.$t("retenciones.total_retention_amount"))
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$utils.formatMoney(
                          _vm.retention.total_retention_amount,
                          _vm.defaultCurrency
                        )
                      ),
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("div", { staticClass: "row mt-2" }, [
                _c("div", { staticClass: "col col-sm-4 text-center" }, [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$t("retenciones.period_year"))),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.retention.period_year) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col col-sm-4 text-center" }, [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$t("retenciones.period_start_month"))),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.retention.period_start_month) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col col-sm-4 text-center" }, [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$t("retenciones.period_end_month"))),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.retention.period_end_month) +
                      "\n          "
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("table", { staticClass: "table" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t("retenciones.taxes.tax_base"))),
                    ]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("retenciones.taxes.tax")))]),
                    _vm._v(" "),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t("retenciones.taxes.payment_type"))),
                    ]),
                    _vm._v(" "),
                    _c("th", [
                      _vm._v(
                        _vm._s(_vm.$t("retenciones.taxes.retention_amount"))
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.retention.taxes, function (tax) {
                    return _c("tr", { key: tax.id }, [
                      _c("td", [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$utils.formatMoney(
                                tax.tax_base,
                                _vm.defaultCurrency
                              )
                            ),
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("retention-tax", {
                            attrs: { "tax-code": tax.tax },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                " +
                            _vm._s(tax.payment_type) +
                            "\n              "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$utils.formatMoney(
                                tax.retention_amount,
                                _vm.defaultCurrency
                              )
                            ),
                          },
                        }),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.retention.complement_digital_platform
            ? _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("h5", { staticClass: "text-center" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("retenciones.complement_digital_platform")
                        ) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "table-responsive" }, [
                    _c("table", { staticClass: "table table-bordered" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", [
                            _vm._v(
                              _vm._s(_vm.$t("retenciones.total_amount_wo_iva"))
                            ),
                          ]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(
                              _vm._s(_vm.$t("retenciones.total_iva_transfered"))
                            ),
                          ]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(
                              _vm._s(_vm.$t("retenciones.total_iva_detained"))
                            ),
                          ]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(
                              _vm._s(_vm.$t("retenciones.total_isr_detained"))
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("tbody", [
                        _c("tr", [
                          _c(
                            "td",
                            [
                              _c("money-text", {
                                attrs: {
                                  amount:
                                    _vm.retention.complement_digital_platform
                                      .total_amount_wo_iva,
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            [
                              _c("money-text", {
                                attrs: {
                                  amount:
                                    _vm.retention.complement_digital_platform
                                      .total_iva_transfered,
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            [
                              _c("money-text", {
                                attrs: {
                                  amount:
                                    _vm.retention.complement_digital_platform
                                      .total_iva_detained,
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            [
                              _c("money-text", {
                                attrs: {
                                  amount:
                                    _vm.retention.complement_digital_platform
                                      .total_isr_detained,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("h6", { staticClass: "text-center" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("retenciones.services")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "table-responsive" }, [
                    _c("table", { staticClass: "table table-bordered" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("retenciones.service_type"))),
                          ]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(
                              _vm._s(_vm.$t("retenciones.commission_amount"))
                            ),
                          ]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("retenciones.taxes.tax"))),
                          ]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(
                              _vm._s(_vm.$t("retenciones.taxes.tax_amount"))
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(
                          _vm.retention.complement_digital_platform.services,
                          function (service) {
                            return _c("tr", { key: service.id }, [
                              _c("td", [_vm._v(_vm._s(service.type_service))]),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _c("money-text", {
                                    attrs: { amount: service.comm_amount },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _c("retention-tax", {
                                    attrs: { "tax-code": service.taxt_tax },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _c("money-text", {
                                    attrs: { amount: service.taxt_amount },
                                  }),
                                ],
                                1
                              ),
                            ])
                          }
                        ),
                        0
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }