<template >
  <div>
    <div class="card-body">
      <form class="pt-4 pb-4 pl-4 pr-4">

        <!-- ! primera fila -->
        <div class="row">
          <div class="col-4">
            <label for="input1">Ingreso a Disminuir</label>
            <base-select v-model="form.disminuir" :options="disminuirOptions" :searchable="true" :allow-empty="false"
              :show-labels="false" label="label" track-by="id" style="font-size: 12px" />
          </div>
        </div>
        <!-- ! segunda fila -->
        <div class="row mt-3" v-if="form.disminuir?.id == '0'">
          <div class=" col-12">
            <h6 class="my-2">Total de los ingresos a disminuir:
              <money-text :amount="form.totalDisminuir" />
            </h6>
          </div>
        </div>

        <!-- Tercera fila con 2 inputs -->
        <div class="row mt-3" v-if="form.disminuir?.id == '0'">
          <div class="col-12">
            <label for="input1">Ingreso a Disminuir por</label>
            <base-select v-model="form.conceptoDisminuir" :options="conceptoDisminuirOptions" :searchable="true"
              :allow-empty="false" :show-labels="false" label="label" track-by="id" style="font-size: 12px" />
          </div>
        </div>

        <div class="row mt-3"
          v-if="form.disminuir?.id == '0' && (form.conceptoDisminuir?.id == '0' || form.conceptoDisminuir?.id == '1')">
          <div class="col-12">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <div class="pl-2" v-b-toggle="'collapse'" variant="primary">
                  <font-awesome-icon class="icon" icon="arrow-down" />
                  CFDI de Ingresos
                </div>
              </b-card-header>

              <b-collapse :id="'collapse'" class="mt-2">
                <tax-return-invoices-table-modal :invoices="ingresosCfdi.invoices" title="Ingresos"
                  :setInvoices="invoiceSelect" :invoicesSelect="form.invoices" />
              </b-collapse>
            </b-card>
          </div>
        </div>

        <div class="row mt-3"
          v-if="form.disminuir?.id == '0' && (form.conceptoDisminuir?.id == '0' || form.conceptoDisminuir?.id == '1')">
          <div class="col-12">
            <h6 class="my-2">Total Monto a disminuir proveniente de CFDI:
              <money-text :amount="form.ingresoDisminuircfdi" />
            </h6>
          </div>
        </div>

        <div class="row mt-3" v-if="form.disminuir?.id == '0' && form.conceptoDisminuir?.id == '2'">
          <div class="col-6">
            <label for="input1">Cantidad</label>
            <base-input type="number" v-model="form.otroDisminuir" />

          </div>
        </div>

        <div class="row mt-3" v-if="form.disminuir?.id == '0' && form.conceptoDisminuir != null">
          <div class="col-6">
            <label for="input1">Nota</label>
            <base-text-area v-model="form.nota" />

          </div>
        </div>
        <div class="row mt-3" v-if="form.disminuir?.id == '0' && form.conceptoDisminuir != null">
          <div class="col-4">
            <label for="input1">Tiene más ingresos a Disminuir: </label>
            <base-select v-model="form.disminuirAdicional" :options="disminuirAdicionalOptions" :searchable="true"
              :allow-empty="false" :show-labels="false" label="label" track-by="id" style="font-size: 12px" />
          </div>
        </div>

        <!-- * Aqui debe de ir cuanso se agrega uno nuevo  -->

        <div class="row mt-3" v-if="form.disminuirAdicional?.id == '0'">
          <div class="col-12">
            <label for="input1">Ingreso a Disminuir por</label>
            <base-select v-model="form.conceptoDisminuirAdicional" :options="conceptoDisminuirOptions" :searchable="true"
              :allow-empty="false" :show-labels="false" label="label" track-by="id" style="font-size: 12px" />
          </div>
        </div>

        <div class="row mt-3"
          v-if="form.disminuirAdicional?.id == '0' && (form.conceptoDisminuirAdicional?.id == '0' || form.conceptoDisminuirAdicional?.id == '1')">
          <div class="col-12">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <div class="pl-2" v-b-toggle="'collapse'" variant="primary">
                  <font-awesome-icon class="icon" icon="arrow-down" />
                  CFDI de Ingresos
                </div>
              </b-card-header>

              <b-collapse :id="'collapse'" class="mt-2">
                <tax-return-invoices-table-modal :invoices="ingresosCfdi.invoices" title="Ingresos"
                  :setInvoices="invoiceSelectAdicional" :invoicesSelect="form.invoicesAdicional" />

              </b-collapse>
            </b-card>
          </div>
        </div>

        <div class="row mt-3"
          v-if="form.disminuirAdicional?.id == '0' && (form.conceptoDisminuirAdicional?.id == '0' || form.conceptoDisminuirAdicional?.id == '1')">
          <div class="col-12">
            <h6 class="my-2">Total Monto a disminuir proveniente de CFDI: <money-text
                :amount="form.ingresoDisminuircfdiAdicional" /></h6>
          </div>
        </div>

        <div class="row mt-3" v-if="form.disminuirAdicional?.id == '0' && form.conceptoDisminuirAdicional?.id == '2'">
          <div class="col-6">
            <label for="input1">Cantidad</label>
            <base-input type="number" v-model="form.otroDisminuirAdicional" />

          </div>
        </div>

        <div class="row mt-3" v-if="form.disminuirAdicional?.id == '0' && form.conceptoDisminuirAdicional != null">
          <div class="col-6">
            <label for="input1">Nota</label>
            <base-text-area v-model="form.notaAdicional" />

          </div>
        </div>

        <!-- cuarta con 2 inputs -->
        <!-- <div class="row mt-3">
          <div class="col">
            <label for="input5">Fecha en que se presento el Saldo a Favor</label>
            <base-date-picker v-model="form.fecha" :calendar-button="true" calendar-button-icon="calendar"
              :typeable='true' />
          </div>
        </div> -->
      </form>
    </div>
    <div class="card-footer">
      <div class="mt-2 flex-content-right">
        <base-button color="theme" @click="saveIngresoDisminuir">
          Guardar
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import TaxReturnInvoicesTableModal from './../../../views/components/TaxReturnInvoicesTableModal'

export default {
  components: {
    TaxReturnInvoicesTableModal,
  },
  mixins: [validationMixin],

  data: () => ({
    form: {
      disminuir: null,
      conceptoDisminuir: null,
      otroDisminuir: 0,
      invoices: [],
      ingresoDisminuircfdi: 0,
      nota: '',
      // aqui termina el primero
      disminuirAdicional: null,
      conceptoDisminuirAdicional: null,
      otroDisminuirAdicional: 0,
      notaAdicional: '',
      invoicesAdicional: [],
      ingresoDisminuircfdiAdicional: 0,
      totalDisminuir: 0,
    },

    disminuirOption: [
      { id: 0, name: 'SI' },
      { id: 1, name: 'NO' },
    ],

    conceptoOption: [
      { id: 0, name: 'Ingresos facturados pendientes de cancelación con aceptación del receptor' },
      { id: 1, name: 'Ingresos facturados acumulados en periodos anteriores' },
      { id: 2, name: 'Otros' },
    ],

    disminuirAdicionalOption: [
      { id: 0, name: 'SI' },
      { id: 1, name: 'NO' },
    ],


  }),
  created() {

  },
  watch: {

    otroDisminuir() {
      this.form.totalDisminuir = Number(this.form.otroDisminuir) * 100 + Number(this.form.otroDisminuirAdicional) * 100 + Number(this.form.ingresoDisminuircfdi) + Number(this.form.ingresoDisminuircfdiAdicional)
    },
    otroDisminuirAdiconal() {
      this.form.totalDisminuir = Number(this.form.otroDisminuirAdicional) * 100 + Number(this.form.otroDisminuir) * 100 + Number(this.form.ingresoDisminuircfdi) + Number(this.form.ingresoDisminuircfdiAdicional)
    },
    ingresoDisminuircfdi() {
      this.form.totalDisminuir = Number(this.form.otroDisminuirAdicional) * 100 + Number(this.form.otroDisminuir) * 100 + Number(this.form.ingresoDisminuircfdi) + Number(this.form.ingresoDisminuircfdiAdicional)
    },
    ingresoDisminuircfdiAdicional() {
      this.form.totalDisminuir = Number(this.form.otroDisminuirAdicional) * 100 + Number(this.form.otroDisminuir) * 100 + Number(this.form.ingresoDisminuircfdi) + Number(this.form.ingresoDisminuircfdiAdicional)
    },

    conceptoDisminuir() {
      if (this.form.conceptoDisminuir?.id == '2') {
        this.form.ingresoDisminuircfdi = 0
      } else {
        this.form.otroDisminuir = 0
        this.form.ingresoDisminuircfdi = this.form.invoices.reduce((total, invoice) => {
          return total + invoice.subtotal
        }, 0)
      }
    },
    conceptoDisminuirAdicional() {
      if (this.form.conceptoDisminuirAdicional?.id == '2') {
        this.form.ingresoDisminuircfdiAdicional = 0
      } else {
        this.form.otroDisminuirAdicional = 0
        this.form.ingresoDisminuircfdiAdicional = this.form.invoicesAdicional.reduce((total, invoice) => {
          return total + invoice.subtotal
        }, 0)
      }
    }
  },
  computed: {
    ...mapGetters('taxReturns', ['ingresosCfdi', 'ingresoDisminuir']),

    otroDisminuir() {
      return this.form.otroDisminuir;
    },
    otroDisminuirAdiconal() {
      return this.form.otroDisminuirAdicional;
    },
    ingresoDisminuircfdi() {
      return this.form.ingresoDisminuircfdi;
    },
    ingresoDisminuircfdiAdicional() {
      return this.form.ingresoDisminuircfdiAdicional;
    },
    conceptoDisminuir() {
      return this.form.conceptoDisminuir
    },
    conceptoDisminuirAdicional() {
      return this.form.conceptoDisminuirAdicional
    },

    disminuirOptions() {
      return this.disminuirOption.map((item) => {
        return {
          ...item,
          id: item.id,
          label: `${item.name}`,
        }
      })
    },
    conceptoDisminuirOptions() {
      return this.conceptoOption.map((item) => {
        return {
          ...item,
          id: item.id,
          label: `${item.name}`,
        }
      })
    },

    disminuirAdicionalOptions() {
      return this.disminuirAdicionalOption.map((item) => {
        return {
          ...item,
          id: item.id,
          label: `${item.name}`,
        }
      })
    },

  },

  mounted() {
    this.form = JSON.parse(JSON.stringify(this.ingresoDisminuir));
  },


  methods: {
    ...mapActions('taxReturns', ['updatedModalIngresoDisminuir']),
    ...mapActions('modal', ['closeModal']),

    saveIngresoDisminuir() {
      console.log('este es el guardado', this.form)

      this.updatedModalIngresoDisminuir(this.form)
      this.closeCategoryModal()
    },

    closeCategoryModal() {
      this.closeModal()
    },


    invoiceSelect(invoices) {
      this.form.invoices = invoices

      this.form.ingresoDisminuircfdi = invoices.reduce((total, invoice) => {
        return total + invoice.subtotal
      }, 0)
    },
    invoiceSelectAdicional(invoices) {
      this.form.invoicesAdicional = invoices

      this.form.ingresoDisminuircfdiAdicional = invoices.reduce((total, invoice) => {

        return total + invoice.subtotal
      }, 0)
    }
  },
}
</script>
