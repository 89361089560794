var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", {
    domProps: {
      innerHTML: _vm._s(
        _vm.$utils.formatMoney(_vm.amount, _vm.defaultCurrency)
      ),
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }