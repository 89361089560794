var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-content" }, [
    _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [
        _vm._v("\n      " + _vm._s(_vm.$t("tax_returns.title")) + "\n    "),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card p-3" }, [
      _c("div", { staticClass: "table-responsive" }, [
        _c("table", { staticClass: "table table-bordered" }, [
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("tax_returns.period")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("tax_returns.year")))]),
              _vm._v(" "),
              _c("th", { staticClass: "text-right" }, [
                _vm._v(_vm._s(_vm.$t("tax_returns.isr_to_pay"))),
              ]),
              _vm._v(" "),
              _c("th", { staticClass: "text-right" }, [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.$t("tax_returns.iva_in_charge")) +
                    "\n            "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.taxReturns, function (taxReturn) {
              return _c("tr", { key: taxReturn.id }, [
                _c(
                  "td",
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "customer_dashboard.tax-returns.details",
                            params: { id: taxReturn.id },
                          },
                        },
                      },
                      [
                        _c("month-period", {
                          attrs: {
                            "start-month": taxReturn.start_month,
                            "end-month": taxReturn.end_month,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "customer_dashboard.tax-returns.details",
                            params: { id: taxReturn.id },
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(taxReturn.year) +
                            "\n              "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "customer_dashboard.tax-returns.details",
                            params: { id: taxReturn.id },
                          },
                        },
                      },
                      [
                        _c("money-text", {
                          attrs: { amount: taxReturn.isr_to_pay },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "customer_dashboard.tax-returns.details",
                            params: { id: taxReturn.id },
                          },
                        },
                      },
                      [
                        _c("money-text", {
                          attrs: { amount: taxReturn.iva_in_charge },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            }),
            0
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }