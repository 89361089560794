<template>
  <div class="main-content treasuries-accounts">
    <div class="page-header mt-5">
      <h3 class="page-title">Cuentas</h3>

      <div class="page-actions row">
        <router-link :to="{ name: 'account.create' }" class="mr-2">
          <base-button :outline="true" color="theme" icon="plus" size="large">
            Agregar
          </base-button>
        </router-link>
        <!-- cuentas -->
      </div>
    </div>

    <div v-for="(cuenta, index) in treasuries" :key="cuenta">
      <b-card-header header-tag="header" role="tab">
        <div
          class="d-flex justify-content-between align-items-center"
          v-b-toggle="'collapse-cuenta-' + index"
          variant="primary"
        >
          <!-- Nombre ennegrecido y en UpperCamelCase -->
          <div class="pl-2 font-weight-bold text-uppercase">
            {{ cuenta.name }}
          </div>

          <!-- Iconos en el lado derecho -->
          <div>
            <!-- <font-awesome-icon class="icon" icon="edit" />
            <font-awesome-icon class="icon" icon="trash" /> -->
            
          </div>
        </div>
      </b-card-header>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {},
  data: () => ({
    filters: {
      month: null,
      customer: null,
      year: null,
    },
    showFilters: true,
    treasuries: [],
  }),
  computed: {
    ...mapGetters('calendar', ['defaultMonth', 'defaultYear', 'months']),
    ...mapGetters('CurrentCustomer', ['currentCustomer']),
    ...mapGetters('currency', ['defaultCurrency']),
    ...mapGetters('assigned', ['assingedSelect']),

    filterIcon() {
      return this.showFilters ? 'times' : 'filter'
    },
  },
  watch: {
    filters: {
      handler: 'setFilters',
      deep: true,
    },
  },
  created() {
    let sessionFilterMonth
    if (sessionStorage.getItem('filtersPaymentsMonth') != 'undefined') {
      sessionFilterMonth = JSON.parse(
        sessionStorage.getItem('filtersPaymentsMonth')
      )
    }
    let sessionFilterYear = sessionStorage.getItem('filtersPaymentsYear')

    if (this.defaultMonth && this.defaultYear) {
      this.filters.month = this.defaultMonth
      this.filters.year = this.defaultYear
      sessionStorage.setItem(
        'filtersPaymentsMonth',
        JSON.stringify(this.filters.month)
      )
      sessionStorage.setItem('filtersPaymentsYear', this.filters.year)
    } else {
      this.filters.month = sessionFilterMonth
      this.filters.year = sessionFilterYear
    }

    if (!this.currentCustomer) {
      this.$router.push('/general/customers')
      return
    }
  },
  mounted() {
    this.getTreasuryAccounts()
  },
  methods: {
    ...mapActions('treasuryAccounts', [
      'fetchTreasuryAccounts',
      'toggleGeneralPublicInvoice',
    ]),
    async getTreasuryAccounts() {
      const params = {
        id: this.assingedSelect.id,
        customer_id: this.currentCustomer.id,
        month: this.filters.month
          ? this.filters.month.id
          : this.defaultMonth.id,
        year: this.filters.year,
        // orderByField: sort.fieldName || 'treasury_date',
        // orderBy: sort.order || 'desc',
        // page,
      }
      console.log('params ', params)
      const response = await this.fetchTreasuryAccounts(params)
      this.treasuries = response.data
      // return {
      //   data: response.data.data.reverse(),
      //   pagination: {
      //     totalPages: response.data.last_page,
      //     currentPage: response.data.current_page,
      //     count: response.data.scount,
      //   },
      // }
    },
    clearFilter() {
      this.filters = {
        month: this.defaultMonth,
      }
    },
    refreshTable() {
      this.$refs.table.refresh()
    },
    setFilters() {
      if (this.showFilters) {
        sessionStorage.setItem(
          'filtersPaymentsMonth',
          JSON.stringify(this.filters.month)
        )
        sessionStorage.setItem('filtersPaymentsYear', this.filters.year)
      }

      // this.filtersApplied = true
      this.refreshTable()
    },
    toggleFilter() {
      if (this.showFilters && this.filtersApplied) {
        // this.clearFilter()
        // this.refreshTable();
      }

      this.showFilters = !this.showFilters
    },
    toggleRowGeneralPublicInvoice(row) {
      if (row['general_public_invoiced_at'] == null) {
        this.toggleGeneralPublicInvoice(row.id)
          .then((response) => {
            window.toastr.success(
              this.$t('payments.general_public_invoices.messages.saved_status')
            )
          })
          .catch((err) => {
            window.toastr.error(
              this.$t('payments.general_public_invoices.errors.save_status')
            )
          })
      }
    },
  },
}
</script>
