import * as types from './mutation-types'

export default {
  [types.SET_INVOICES] (state, invoices) {
    state.invoices = invoices
  },

  [types.SET_TOTAL_INVOICES] (state, totalInvoices) {
    state.totalInvoices = totalInvoices
  },

  [types.SET_TOTAL_REQUESTS] (state, totalRequests) {
    state.totalRequests = totalRequests
  },

  [types.ADD_INVOICE] (state, data) {
    state.invoices.push(data)
  },

  [types.DELETE_INVOICE] (state, id) {
    let index = state.invoices.findIndex(invoice => invoice.id === id)
    state.invoices.splice(index, 1)
  },

  [types.SET_SELECTED_INVOICES] (state, data) {
    state.selectedInvoices = data
  },

  [types.UPDATE_INVOICE] (state, data) {
    let pos = state.invoices.findIndex(invoice => invoice.id === data.invoice.id)

    state.invoices[pos] = data.invoice
  },

  [types.UPDATE_INVOICE_STATUS] (state, data) {
    let pos = state.invoices.findIndex(invoice => invoice.id === data.id)

    if (state.invoices[pos]) {
      state.invoices[pos].status = data.status
    }
  },

  [types.RESET_SELECTED_INVOICES] (state, data) {
    state.selectedInvoices = []
    state.selectAllField = false
  },

  [types.DELETE_MULTIPLE_INVOICES] (state, selectedInvoices) {
    selectedInvoices.forEach((invoice) => {
      let index = state.invoices.findIndex(_inv => _inv.id === invoice.id)
      state.invoices.splice(index, 1)
    })
    state.selectedInvoices = []
  },

  [types.SET_TEMPLATE_ID] (state, templateId) {
    state.invoiceTemplateId = templateId
  },

  [types.SELECT_CUSTOMER] (state, data) {
    state.selectedCustomer = data
  },

  [types.RESET_SELECTED_CUSTOMER] (state, data) {
    state.selectedCustomer = null
  },

  [types.SET_SELECT_ALL_STATE] (state, data) {
    state.selectAllField = data
  },

  [types.SET_DATA_MODAL_ADD_PAID_DOCUMENT] (state, data) {
    state.dataModalAddPaidDocument = data;
  },

  [types.SET_INVOICE_ID] (state, data) {
    state.invoicesId.push(data)
  },
  [types.QUICK_ASSIGMENT_APPEND_INVOICES](state, data) {
    const invoicesForQuickAssignment = data.map((item) => {
      return {
        ...item,
        confirmed: false,
        items: item.items.map((concept) => {
          let prodServ = null
          let subcategory = null
          if (
            state.quickAssignment.prodServMatches.hasOwnProperty(
              concept.clave_prod_serv
            )
          ) {
            prodServ =
              state.quickAssignment.prodServMatches[concept.clave_prod_serv]
          }
          if (concept.concept_subcategory_id) {
            subcategory = this.getters['conceptCategories/findSubcategoryById'](
              concept.concept_subcategory_id
            )
          }
          const quickAssignmentConcept = {
            ...concept,
            quickAssignment: {
              inTaxReturn: concept.in_tax_return,
              subcategory: subcategory ? subcategory : null,
              manualInTaxReturn: prodServ ? true : false,
              manualSubcategory: prodServ ? true : false,
            },
          }
          return quickAssignmentConcept
        }),
      }
    })
    state.quickAssignment.invoices = state.quickAssignment.invoices.concat(
      invoicesForQuickAssignment
    )
  },

  [types.QUICK_ASSIGMENT_CLEAR](state) {
    state.quickAssignment = {
      invoices: [],
      prodServMatches: {},
    }
  },

  [types.QUICK_ASSIGMENT_CHANGE_PRODSERV_CATEGORY](state, data) {
    const invoices = state.quickAssignment.invoices.map((invoice) => {
      return {
        ...invoice,
        confirmed: false,
        items: invoice.items.map((item) => {
          if (
            data.prodServ == item.clave_prod_serv &&
            (data.conceptId == item.id ||
              !item.quickAssignment.manualSubcategory)
          ) {
            return {
              ...item,
              quickAssignment: {
                ...item.quickAssignment,
                subcategory: data.subcategory,
                inTaxReturn: data.subcategory.in_tax_return,
                manualInTaxReturn: false,
                manualSubcategory: item.id == data.conceptId ? true : false,
              },
            }
          }
          return item
        }),
      }
    })
    state.quickAssignment.invoices = invoices
    state.quickAssignment.prodServMatches[data.prodServ] = {
      subcategory: data.subcategory,
    }
  },

  [types.QUICK_ASSIGMENT_TOGGLE_IN_TAX_RETURN](state, data) {
    state.quickAssignment.invoices = state.quickAssignment.invoices.map(
      (invoice) => {
        return {
          ...invoice,
          confirmed: false,
          items: invoice.items.map((concept) => {
            const quickAssignment = {
              ...concept.quickAssignment,
            }
            if (data.conceptId == concept.id) {
              quickAssignment.inTaxReturn = data.inTaxReturn
              quickAssignment.manualInTaxReturn = true
            } else if (
              data.prodServ == concept.clave_prod_serv &&
              !concept.quickAssignment.manualInTaxReturn
            ) {
              quickAssignment.inTaxReturn = data.inTaxReturn
            }
            return {
              ...concept,
              quickAssignment,
            }
          }),
        }
      }
    )
  },

  [types.QUICK_ASSIGMENT_SET_CONFIRMED_INVOICE](state, invoiceId) {
    state.quickAssignment.invoices = state.quickAssignment.invoices.map(
      (invoice) => {
        if (invoice.id == invoiceId) {
          return {
            ...invoice,
            confirmed: true,
          }
        }
        return invoice
      }
    )
  },

  [types.QUICK_ASSIGMENT_UPDATE_IN_TAX_RETURN_STATUS](state, data) {
    const index = state.quickAssignment.invoices
      .map((invoice) => invoice.id)
      .indexOf(data.invoiceId)
    if (index != -1) {
      state.quickAssignment.invoices[index].in_tax_return = data.inTaxReturn
    }
  },
}
