<template>
  <div>
    <div class="card-body">
      <div class="mt-2 mb-4">
        <table class="table-bordered invoice-info w-100">
          <thead>
            <tr>
              <th>{{ $t('invoices.complements.serie') }}</th>
              <th>{{ $t('invoices.complements.invoice_number') }}</th>
              <th class="amount-cell">
                {{ $t('invoices.complements.currency') }}
              </th>
              <th class="amount-cell">
                {{ $t('invoices.complements.payment_method') }}
              </th>
              <th class="amount-cell">
                {{ $t('invoices.complements.partiality') }}
              </th>
              <th class="text-center amount-cell-md">
                {{ $t('invoices.complements.previous_balance') }}
              </th>
              <th class="text-center amount-cell-md">
                {{ $t('invoices.complements.amount_payment') }}
              </th>
              <th class="text-center amount-cell-md">
                {{ $t('invoices.complements.outstanding_balance') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ modalData.serie }}</td>
              <td>{{ modalData.invoice_number }}</td>
              <td>{{ modalData.currency }}</td>
              <td>{{ modalData.payment_method }}</td>
              <td>{{ modalData.partiality }}</td>
              <td class="text-right">
                <money-text :amount="Number(modalData.previous_balance)" />
              </td>
              <td class="text-right">
                <money-text :amount="Number(modalData.amount_payment)" />
              </td>
              <td class="text-right">
                <money-text :amount="Number(modalData.outstanding_balance)" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card-footer">
      <div class="mt-2 flex-content-right">
        <base-button color="theme" @click="closeCategoryModal">
          Cerrar
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import TaxReturnInvoicesTableModal from './../../../views/components/TaxReturnInvoicesTableModal'

export default {
  components: {
    TaxReturnInvoicesTableModal,
  },
  mixins: [validationMixin],

  data: () => ({}),
  created() {},
  watch: {},
  computed: {
    ...mapGetters('taxReturns', ['ingresosCfdi', 'isrRetenido']),
    ...mapGetters('modal', ['modalDataID', 'modalData', 'modalActive']),
  },

  mounted() {},

  methods: {
    ...mapActions('modal', ['closeModal']),

    closeCategoryModal() {
      this.closeModal()
    },
  },
}
</script>
