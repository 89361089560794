var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-content" },
    [
      _c(
        "div",
        { staticClass: "page-header mt-4", staticStyle: { "z-index": "1" } },
        [
          _c("h3", { staticClass: "page-title" }, [_vm._v("Usuarios")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page-actions row" },
            [
              _c(
                "conditional-wrapper",
                {
                  attrs: {
                    "show-for-admin": true,
                    "show-for-executives": false,
                    "show-for-customers": false,
                  },
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "col-xs-2",
                      attrs: { to: "/general/users/create" },
                    },
                    [
                      _c(
                        "base-button",
                        {
                          attrs: {
                            size: "small",
                            icon: "plus",
                            color: "theme",
                          },
                        },
                        [_vm._v("\n            Nuevo usuario\n          ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", {}, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-sm-3" },
            [
              _c("label", { staticClass: "form-label" }, [_vm._v("Búsqueda")]),
              _vm._v(" "),
              _c("base-input", {
                attrs: { type: "text", name: "search", autocomplete: "on" },
                model: {
                  value: _vm.filters.search,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters, "search", $$v)
                  },
                  expression: "filters.search",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "table-component",
        {
          ref: "table",
          attrs: {
            "show-filter": false,
            data: _vm.fetchData,
            "table-class": "table",
          },
        },
        [
          _c("table-column", { attrs: { label: "ID", show: "id" } }),
          _vm._v(" "),
          _c("table-column", {
            attrs: { label: _vm.$t("customers.name"), show: "name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [_vm._v(_vm._s(_vm.$t("customers.name")))]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "\n          " +
                          _vm._s(row.name) +
                          "\n         \n        "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("table-column", { attrs: { label: "Email", show: "email" } }),
          _vm._v(" "),
          _c("table-column", { attrs: { label: "Role", show: "role" } }),
          _vm._v(" "),
          _c("table-column", {
            attrs: {
              sortable: false,
              filterable: false,
              "cell-class": "action-dropdown",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.$t("customers.action")) + " "),
                    ]),
                    _vm._v(" "),
                    _c(
                      "v-dropdown",
                      [
                        _c(
                          "a",
                          {
                            attrs: { slot: "activator", href: "#" },
                            slot: "activator",
                          },
                          [_c("dot-icon")],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-dropdown-item",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item",
                                attrs: { to: `/general/users/${row.id}/edit` },
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "dropdown-item-icon",
                                  attrs: { icon: ["fas", "pencil-alt"] },
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("general.edit")) +
                                    "\n            "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-dropdown-item", [
                          _c(
                            "div",
                            {
                              staticClass: "dropdown-item",
                              on: {
                                click: function ($event) {
                                  return _vm.removeCustomer(row.id)
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "dropdown-item-icon",
                                attrs: { icon: ["fas", "trash"] },
                              }),
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("general.delete")) +
                                  "\n            "
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }