// En tu archivo actions.js
import * as types from './mutation-types'
import ls from '../../../services/ls';
export const setYear = ({ commit }, year) => {
  commit(types.SET_YEAR, year)
  ls.set(types.LOCAL_YEAR,year)
}

export const setMonth = ({ commit }, month) => {
  commit(types.SET_MONTH, month)
  ls.set(types.LOCAL_MONTH,month)
}
