var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "card-body" }, [
      _c("form", { staticClass: "pt-4 pb-4 pl-4 pr-4" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-4" },
            [
              _c("label", { attrs: { for: "input1" } }, [
                _vm._v("Ingreso a Disminuir"),
              ]),
              _vm._v(" "),
              _c("base-select", {
                staticStyle: { "font-size": "12px" },
                attrs: {
                  options: _vm.disminuirOptions,
                  searchable: true,
                  "allow-empty": false,
                  "show-labels": false,
                  label: "label",
                  "track-by": "id",
                },
                model: {
                  value: _vm.form.disminuir,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "disminuir", $$v)
                  },
                  expression: "form.disminuir",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _vm.form.disminuir?.id == "0"
          ? _c("div", { staticClass: "row mt-3" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "h6",
                  { staticClass: "my-2" },
                  [
                    _vm._v("Total de los ingresos a disminuir:\n            "),
                    _c("money-text", {
                      attrs: { amount: _vm.form.totalDisminuir },
                    }),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.form.disminuir?.id == "0"
          ? _c("div", { staticClass: "row mt-3" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("label", { attrs: { for: "input1" } }, [
                    _vm._v("Ingreso a Disminuir por"),
                  ]),
                  _vm._v(" "),
                  _c("base-select", {
                    staticStyle: { "font-size": "12px" },
                    attrs: {
                      options: _vm.conceptoDisminuirOptions,
                      searchable: true,
                      "allow-empty": false,
                      "show-labels": false,
                      label: "label",
                      "track-by": "id",
                    },
                    model: {
                      value: _vm.form.conceptoDisminuir,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "conceptoDisminuir", $$v)
                      },
                      expression: "form.conceptoDisminuir",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.form.disminuir?.id == "0" &&
        (_vm.form.conceptoDisminuir?.id == "0" ||
          _vm.form.conceptoDisminuir?.id == "1")
          ? _c("div", { staticClass: "row mt-3" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c(
                    "b-card",
                    { staticClass: "mb-1", attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-header",
                        {
                          staticClass: "p-1",
                          attrs: { "header-tag": "header", role: "tab" },
                        },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "b-toggle",
                                  rawName: "v-b-toggle",
                                  value: "collapse",
                                  expression: "'collapse'",
                                },
                              ],
                              staticClass: "pl-2",
                              attrs: { variant: "primary" },
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "icon",
                                attrs: { icon: "arrow-down" },
                              }),
                              _vm._v(
                                "\n                CFDI de Ingresos\n              "
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        { staticClass: "mt-2", attrs: { id: "collapse" } },
                        [
                          _c("tax-return-invoices-table-modal", {
                            attrs: {
                              invoices: _vm.ingresosCfdi.invoices,
                              title: "Ingresos",
                              setInvoices: _vm.invoiceSelect,
                              invoicesSelect: _vm.form.invoices,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.form.disminuir?.id == "0" &&
        (_vm.form.conceptoDisminuir?.id == "0" ||
          _vm.form.conceptoDisminuir?.id == "1")
          ? _c("div", { staticClass: "row mt-3" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "h6",
                  { staticClass: "my-2" },
                  [
                    _vm._v(
                      "Total Monto a disminuir proveniente de CFDI:\n            "
                    ),
                    _c("money-text", {
                      attrs: { amount: _vm.form.ingresoDisminuircfdi },
                    }),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.form.disminuir?.id == "0" && _vm.form.conceptoDisminuir?.id == "2"
          ? _c("div", { staticClass: "row mt-3" }, [
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c("label", { attrs: { for: "input1" } }, [
                    _vm._v("Cantidad"),
                  ]),
                  _vm._v(" "),
                  _c("base-input", {
                    attrs: { type: "number" },
                    model: {
                      value: _vm.form.otroDisminuir,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "otroDisminuir", $$v)
                      },
                      expression: "form.otroDisminuir",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.form.disminuir?.id == "0" && _vm.form.conceptoDisminuir != null
          ? _c("div", { staticClass: "row mt-3" }, [
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c("label", { attrs: { for: "input1" } }, [_vm._v("Nota")]),
                  _vm._v(" "),
                  _c("base-text-area", {
                    model: {
                      value: _vm.form.nota,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "nota", $$v)
                      },
                      expression: "form.nota",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.form.disminuir?.id == "0" && _vm.form.conceptoDisminuir != null
          ? _c("div", { staticClass: "row mt-3" }, [
              _c(
                "div",
                { staticClass: "col-4" },
                [
                  _c("label", { attrs: { for: "input1" } }, [
                    _vm._v("Tiene más ingresos a Disminuir: "),
                  ]),
                  _vm._v(" "),
                  _c("base-select", {
                    staticStyle: { "font-size": "12px" },
                    attrs: {
                      options: _vm.disminuirAdicionalOptions,
                      searchable: true,
                      "allow-empty": false,
                      "show-labels": false,
                      label: "label",
                      "track-by": "id",
                    },
                    model: {
                      value: _vm.form.disminuirAdicional,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "disminuirAdicional", $$v)
                      },
                      expression: "form.disminuirAdicional",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.form.disminuirAdicional?.id == "0"
          ? _c("div", { staticClass: "row mt-3" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("label", { attrs: { for: "input1" } }, [
                    _vm._v("Ingreso a Disminuir por"),
                  ]),
                  _vm._v(" "),
                  _c("base-select", {
                    staticStyle: { "font-size": "12px" },
                    attrs: {
                      options: _vm.conceptoDisminuirOptions,
                      searchable: true,
                      "allow-empty": false,
                      "show-labels": false,
                      label: "label",
                      "track-by": "id",
                    },
                    model: {
                      value: _vm.form.conceptoDisminuirAdicional,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "conceptoDisminuirAdicional", $$v)
                      },
                      expression: "form.conceptoDisminuirAdicional",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.form.disminuirAdicional?.id == "0" &&
        (_vm.form.conceptoDisminuirAdicional?.id == "0" ||
          _vm.form.conceptoDisminuirAdicional?.id == "1")
          ? _c("div", { staticClass: "row mt-3" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c(
                    "b-card",
                    { staticClass: "mb-1", attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-header",
                        {
                          staticClass: "p-1",
                          attrs: { "header-tag": "header", role: "tab" },
                        },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "b-toggle",
                                  rawName: "v-b-toggle",
                                  value: "collapse",
                                  expression: "'collapse'",
                                },
                              ],
                              staticClass: "pl-2",
                              attrs: { variant: "primary" },
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "icon",
                                attrs: { icon: "arrow-down" },
                              }),
                              _vm._v(
                                "\n                CFDI de Ingresos\n              "
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        { staticClass: "mt-2", attrs: { id: "collapse" } },
                        [
                          _c("tax-return-invoices-table-modal", {
                            attrs: {
                              invoices: _vm.ingresosCfdi.invoices,
                              title: "Ingresos",
                              setInvoices: _vm.invoiceSelectAdicional,
                              invoicesSelect: _vm.form.invoicesAdicional,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.form.disminuirAdicional?.id == "0" &&
        (_vm.form.conceptoDisminuirAdicional?.id == "0" ||
          _vm.form.conceptoDisminuirAdicional?.id == "1")
          ? _c("div", { staticClass: "row mt-3" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "h6",
                  { staticClass: "my-2" },
                  [
                    _vm._v("Total Monto a disminuir proveniente de CFDI: "),
                    _c("money-text", {
                      attrs: { amount: _vm.form.ingresoDisminuircfdiAdicional },
                    }),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.form.disminuirAdicional?.id == "0" &&
        _vm.form.conceptoDisminuirAdicional?.id == "2"
          ? _c("div", { staticClass: "row mt-3" }, [
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c("label", { attrs: { for: "input1" } }, [
                    _vm._v("Cantidad"),
                  ]),
                  _vm._v(" "),
                  _c("base-input", {
                    attrs: { type: "number" },
                    model: {
                      value: _vm.form.otroDisminuirAdicional,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "otroDisminuirAdicional", $$v)
                      },
                      expression: "form.otroDisminuirAdicional",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.form.disminuirAdicional?.id == "0" &&
        _vm.form.conceptoDisminuirAdicional != null
          ? _c("div", { staticClass: "row mt-3" }, [
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c("label", { attrs: { for: "input1" } }, [_vm._v("Nota")]),
                  _vm._v(" "),
                  _c("base-text-area", {
                    model: {
                      value: _vm.form.notaAdicional,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "notaAdicional", $$v)
                      },
                      expression: "form.notaAdicional",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card-footer" }, [
      _c(
        "div",
        { staticClass: "mt-2 flex-content-right" },
        [
          _c(
            "base-button",
            {
              attrs: { color: "theme" },
              on: { click: _vm.saveIngresoDisminuir },
            },
            [_vm._v("\n        Guardar\n      ")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }