<template>
  <div class="main-content">
    <div class="page-header pt-4">
      <h3 class="page-title">
        {{ $t('tax_returns.tax_return') }}
      </h3>
    </div>
    <template v-if="periodTaxReturn">
      <h4>{{ periodTaxReturn }}</h4>
    </template>

    <template v-if="currentCustomer.tax_regime_b">
      <!-- Regimenes -->
      <div class="form-group row">
        <label class="col-form-label col-2"> Régimen </label>
        <div class="col-10">
          <base-select
            v-model="filters.regimen"
            :options="optionsRegime"
            :searchable="true"
            :show-labels="false"
            placeholder="Régimen"
            label="name"
          />
        </div>
      </div>
    </template>

    <!-- Formularios para los regimenes -->
    <div v-show="!showingDetails" class="row">
      <div class="col-12 col-md-6">
        <div v-if="showTaxRegimeForm" class="card p-2">
          <!-- Personas fisicas -->
          <!--<div v-if="currentCustomer.tax_regime == 612"> -->
          <template v-if="filters.regimen.id == 612">
            <!-- ISR a favor -->
            <div class="form-group row">
              <label for="" class="col-3 col-form-label">
                {{ $t('tax_returns.isr_in_favor') }}
              </label>
              <div class="col-9 base-input">
                <money
                  v-model="filters.isrInFavor"
                  v-bind="customerCurrency"
                  class="input-field text-right"
                />
              </div>
            </div>
            <!-- IVA Acreditable -->
            <div class="form-group row">
              <label for="" class="col-3 col-form-label">
                {{ $t('tax_returns.iva_creditable') }}
              </label>
              <div class="col-9 base-input">
                <money
                  v-model="filters.ivaCreditable"
                  v-bind="customerCurrency"
                  class="input-field text-right"
                />
              </div>
            </div>
            <!-- Perdida fiscal -->
            <div class="form-group row">
              <label for="" class="col-3 col-form-label">
                {{ $t('tax_returns.tax_loss_update') }}
              </label>
              <div class="col-9 base-input">
                <money
                  v-model="filters.taxLossUpdate"
                  v-bind="customerCurrency"
                  class="input-field text-right"
                />
              </div>
            </div>
          </template>
        </div>

        <!-- RESICO -->
        <!-- <div class="card p-2" v-if="currentCustomer.tax_regime == 626"> -->
        <div class="card p-2" v-if="filters.regimen.id == 626">
          <div v-if="yearlyTaxReturnSelected == false">
            <!-- Actualizaciones -->
            <div class="form-group row">
              <label for="" class="col-3 col-form-label">
                Actualizaciones
              </label>
              <div class="col-9 base-input">
                <money
                  v-model="filters.resicoUpdates"
                  v-bind="customerCurrency"
                  class="input-field text-right"
                />
              </div>
            </div>
            <!-- Recargos -->
            <div class="form-group row">
              <label for="" class="col-3 col-form-label"> Recargos </label>
              <div class="col-9 base-input">
                <money
                  v-model="filters.resicoRecargos"
                  v-bind="customerCurrency"
                  class="input-field text-right"
                />
              </div>
            </div>
            <!-- Compensaciones -->
            <div class="form-group row">
              <label for="" class="col-3 col-form-label"> Compensación </label>
              <div class="col-9 base-input">
                <money
                  v-model="filters.resicoCompensaciones"
                  v-bind="customerCurrency"
                  class="input-field text-right"
                />
              </div>
            </div>
            <!-- Actualizaciones Iva-->
            <div class="form-group row">
              <label for="" class="col-3 col-form-label">
                IVA Actualizaciones
              </label>
              <div class="col-9 base-input">
                <money
                  v-model="filters.resicoUpdatesIva"
                  v-bind="customerCurrency"
                  class="input-field text-right"
                />
              </div>
            </div>
            <!-- Recargos Iva-->
            <div class="form-group row">
              <label for="" class="col-3 col-form-label"> IVA Recargos </label>
              <div class="col-9 base-input">
                <money
                  v-model="filters.resicoRecargosIva"
                  v-bind="customerCurrency"
                  class="input-field text-right"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <template v-if="filters.regimen.id">
        <!-- Formulario de periodo -->
        <div class="col-12 col-md-6">
          <div class="card p-2">
            <template v-if="!complementaria">
              <!-- Periodo -->
              <div class="form-group row">
                <label class="col-form-label col-3">
                  {{ $t('tax_returns.period') }}
                </label>
                <div class="col-9">
                  <base-select
                    v-model="filters.period"
                    :options="optionsPeriodPerTaxRegime"
                    :searchable="true"
                    :show-labels="false"
                    :placeholder="$t('tax_returns.period')"
                    :value="null"
                    label="name"
                  />
                </div>
              </div>
              <!-- Año -->
              <div class="form-group row">
                <label class="col-form-label col-3">{{
                  $t('general.year')
                }}</label>
                <div class="col-9">
                  <base-input
                    v-model="filters.year"
                    type="number"
                    name="year"
                  />
                </div>
              </div>
            </template>

            <div v-if="yearlyTaxReturnSelected">
              <!-- Arrendamiento -->
              <!-- Declaracion ciega -->
              <div class="form-group" v-if="taxReturn.arrendamiento">
                <div class="row">
                  <div class="col-10 flex-vertical-end">
                    {{ $t('customers.sat_settings.tax_return_at_35_percent') }}
                  </div>
                  <div class="col-2">
                    <base-switch v-model="filters.additional.blindTaxReturn" />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group flex-content-right">
              <!-- Search Info button -->
              <base-button color="theme" size="small" @click="loadInfo">
                {{ $t('tax_returns.search_information') }}
              </base-button>
            </div>
          </div>
        </div>
      </template>
    </div>

    <tax-return-rif v-if="taxReturn.rif" :tax-return="taxReturn.rif" />
    <tax-return-arrendamiento
      v-if="taxReturn.arrendamiento"
      :tax-return="taxReturn.arrendamiento"
    />
    <tax-return-digital-platform
      v-if="taxReturn.digital_platforms"
      :tax-return="taxReturn.digital_platforms"
    />
    <tax-return-resico v-if="taxReturn.resico" :tax-return="taxReturn.resico" />
    <tax-return-professional-activity
      v-if="taxReturn.professional_activity"
      :tax-return="taxReturn.professional_activity"
    />
    <tax-return-yearly
      v-if="taxReturn.yearly"
      :tax-return="taxReturn.yearly"
      :blind-tax-return="filters.additional.blindTaxReturn"
      @applyInterests="applyInterests"
      @applyTaxLoss="applyTaxLoss"
      @applyPersonalDeductions="applyPersonalDeductions"
    />

    <tax-return-yearly-digitales
      v-if="taxReturn.yearly_digitales"
      :tax-return="taxReturn.yearly_digitales"
      :blind-tax-return="filters.additional.blindTaxReturn"
      @applyInterests="applyInterests"
      @applyTaxLoss="applyTaxLoss"
      @applyPersonalDeductions="applyPersonalDeductions"
    />

    <tax-return-yearly-resico
      v-if="taxReturn.yearly_resico"
      :tax-return="taxReturn.yearly_resico"
      :blind-tax-return="filters.additional.blindTaxReturn"
      @applyInterests="applyInterests"
      @applyTaxLoss="applyTaxLoss"
      @applyPersonalDeductions="applyPersonalDeductions"
    />

    <div v-if="haveTaxReturn && !showingDetails" class="mt-4 mb-4 text-center">
      <button class="btn btn-primary" @click="saveTaxReturn">
        {{ $t('tax_returns.save_tax_return') }}
      </button>
    </div>
    <div v-if="taxReturn.status == 'aceptado'" class="mt-4 mb-4 text-center">
      <base-button
        color="theme"
        icon="plus"
        size="large"
        @click="goComplementaria"
        >> Agregar Declaración Complementaria
      </base-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TaxReturnArrendamiento from '../components/TaxReturnArrendamiento.vue'
import TaxReturnRif from '../components/TaxReturnRif.vue'
import TaxReturnDigitalPlatform from '../components/TaxReturnDigitalPlatform'
import TaxReturnResico from '../components/TaxReturnResico'
import TaxReturnProfessionalActivity from '../components/TaxReturnProfessionalActivity.vue'
import TaxReturnYearly from '../components/TaxReturnYearly'
import TaxReturnYearlyResico from '../components/TaxReturnYearlyResico'
import TaxReturnYearlyDigitales from '../components/TaxReturnYearlyDigitales'

export default {
  components: {
    TaxReturnRif,
    TaxReturnArrendamiento,
    TaxReturnDigitalPlatform,
    TaxReturnResico,
    TaxReturnProfessionalActivity,
    TaxReturnYearly,
    TaxReturnYearlyResico,
    TaxReturnYearlyDigitales,
  },
  data: () => ({
    taxReturnId: null,
    periodTaxReturn: null,
    startMonth: null,
    endMonth: null,
    yearTaxReturn: null,
    complementaria: null,
    filters: {
      period: null,
      year: null,
      isrInFavor: 0,
      ivaCreditable: 0,
      taxLossUpdate: 0,
      resicoUpdates: 0,
      resicoRecargos: 0,
      resicoCompensaciones: 0,
      resicoUpdatesIva: 0,
      resicoRecargosIva: 0,
      regimen: { id: '', name: '' },
      additional: {
        blindTaxReturn: false,
        financialInstitutions: null,
        taxLoss: 0,
        personalDeductions: null,
      },
    },
    taxReturn: {
      rif: null,
      arrendamiento: null,
      digital_platforms: null,
      professional_activity: null,
      resico: null,
      yearly: null,
      yearly_resico: null,
      yearly_digitales: null,
      status: null,
    },
  }),
  computed: {
    ...mapGetters('CurrentCustomer', ['currentCustomer']),
    ...mapGetters('calendar', ['defaultYear', 'defaultMonth']),
    ...mapGetters('catalogs', ['taxReturnPeriodPerTaxRegime', 'taxRegimes']),
    ...mapGetters('currency', ['defaultCurrency']),
    optionsPeriodPerTaxRegime() {
      /* const taxRegime = this.currentCustomer.tax_regime */
      const taxRegime = this.filters.regimen.id
      const options = this.taxReturnPeriodPerTaxRegime[taxRegime]
      return options
    },
    optionsRegime() {
      let options = []

      if (this.currentCustomer.tax_regime_b) {
        this.taxRegimes.forEach((element) => {
          if (
            element.id == this.currentCustomer.tax_regime ||
            element.id == this.currentCustomer.tax_regime_b
          )
            options.push({ id: element.id, name: element.description })
        })

        return options
      }
    },
    haveTaxReturn() {
      if (
        this.taxReturn.rif ||
        this.taxReturn.arrendamiento ||
        this.taxReturn.digital_platforms ||
        this.taxReturn.resico ||
        this.taxReturn.professional_activity
      ) {
        return true
      }
      return false
    },
    customerCurrency() {
      if (this.defaultCurrency) {
        return {
          decimal: this.defaultCurrency.decimal_separator,
          thousands: this.defaultCurrency.thousand_separator,
          prefix: this.defaultCurrency.symbol + ' ',
          precision: this.defaultCurrency.precision,
          masked: false,
        }
      } else {
        return this.defaultCurrenctForInput
      }
    },
    showTaxRegimeForm() {
      /* return [612].indexOf(this.currentCustomer.tax_regime) != -1 */
      return [612].indexOf(this.filters.regimen.id) != -1
    },
    yearlyTaxReturnSelected() {
      if (!this.filters.period) {
        return false
      }
      if (
        this.filters.period.startMonth == 1 &&
        this.filters.period.endMonth == 12
      ) {
        return true
      }
      return false
    },
    showingDetails() {
      return this.taxReturnId != null
    },
  },
  created() {
    this.filters.year = this.defaultYear
    this.filters.period = this.defaultMonth
    this.startMonth = this.defaultMonth['id']
    this.endMonth = this.defaultMonth['id']
    const periodSelected = {
      startMonth: this.startMonth,
      name: this.defaultMonth['name'],
      endMonth: this.endMonth,
    }
    this.filters.period = periodSelected

    if ('id' in this.$route.params) {
      this.taxReturnId = this.$route.params.id
      this.periodTaxReturn = this.$route.params.period
      this.startMonth = this.$route.params.startMonth
      this.endMonth = this.$route.params.endMonth
      this.yearTaxReturn = this.$route.params.year
      this.loadTaxReturn()
      this.loadSatSettings()
      return
    }
    if (sessionStorage.getItem('periodTaxReturn')) {
      this.periodTaxReturn =
        'Complementaria ' + sessionStorage.getItem('periodTaxReturn')
      this.startMonth = sessionStorage.getItem('startMonth')
      this.endMonth = sessionStorage.getItem('endMonth')
      this.yearTaxReturn = sessionStorage.getItem('yearTaxReturn')
      const periodSelected = {
        startMonth: this.startMonth,
        name: 'OK',
        endMonth: this.endMonth,
      }
      this.filters.period = periodSelected
      this.complementaria = true
      this.loadInfo()
      this.loadSatSettings()
      return
    }
    this.loadInfo()
    return
  },

  watch: {
    filters: {
      handler: 'setFilters',
      deep: true,
    },
  },

  mounted() {
    if (!this.currentCustomer.tax_regime_b) {
      this.filters.regimen.id = this.currentCustomer.tax_regime
    }
  },
  methods: {
    ...mapActions('taxReturns', [
      'searchInfoForTaxReturn',
      'storeTaxReturn',
      'fetchTaxReturn',
    ]),
    ...mapActions('modal', ['openModal']),
    ...mapActions('customer', ['fetchCustomerSatSettings']),

    setFilters() {
      console.log(this.filters.regimen)
    },

    loadInfo() {
      if (!this.filters.period) {
        return false
      }
      const params = {
        year: this.filters.year,
        start_month: this.filters.period.startMonth,
        end_month: this.filters.period.endMonth,

        isr_in_favor: this.filters.isrInFavor,
        iva_creditable: this.filters.ivaCreditable,
        tax_loss_update: this.filters.taxLossUpdate,
        resico_updates: this.filters.resicoUpdates,
        resico_recargos: this.filters.resicoRecargos,
        resico_compensaciones: this.filters.resicoCompensaciones,
        resico_updates_iva: this.filters.resicoUpdatesIva,
        resico_recargos_iva: this.filters.resicoRecargosIva,
        customer: this.currentCustomer.id,
        additional: this.filters.additional,
        complementaria: this.yearTaxReturn ? true : false,
      }
      this.searchInfoForTaxReturn(params)
        .then((response) => {
          this.cleanTables()
          if (response.data.rif) {
            this.taxReturn.rif = response.data.rif
          }
          if (response.data.arrendamiento) {
            this.taxReturn.arrendamiento = response.data.arrendamiento
          }
          if (response.data.digital_platforms) {
            this.taxReturn.digital_platforms = response.data.digital_platforms
          }
          if (response.data.resico) {
            this.taxReturn.resico = response.data.resico
          }
          if (response.data.professional_activity) {
            this.taxReturn.professional_activity =
              response.data.professional_activity
          }
          if (response.data.yearly_digitales) {
            this.taxReturn.yearly_digitales = response.data.yearly_digitales
          }
          if (response.data.yearly_resico) {
            this.taxReturn.yearly_resico = response.data.yearly_resico
          }
          if (response.data.yearly) {
            this.taxReturn.yearly = response.data.yearly
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
    },

    loadTaxReturn() {
      this.fetchTaxReturn(this.taxReturnId)
        .then((response) => {
          console.log(response)
          const taxReturn = response.data.tax_return
          const regimes = {
            606: 'arrendamiento',
            612: 'professional_activity',
            621: 'rif',
            625: 'digital_platforms',
            626: 'resico',
          }
          this.taxReturn.status = taxReturn.status
          this.taxReturn[regimes[taxReturn.tax_regime]] = {
            amounts: response.data.tax_return.tax_return,
            tables: {},
          }
        })
        .catch(() => {})
    },

    loadSatSettings() {
      this.fetchCustomerSatSettings(this.currentCustomer.id)
        .then((response) => {
          this.filters.additional.blindTaxReturn =
            response.data.sat_settings.tax_return_blind_606
        })
        .catch(() => {})
    },

    saveTaxReturn() {
      const data = {
        year: this.filters.year,
        start_month: this.filters.period.startMonth,
        end_month: this.filters.period.endMonth,
        customer: this.currentCustomer.id,
        tax_return: null,
        documents_and_transactions: null,
        tax_loss_update: this.filters.taxLossUpdate,
        complementaria: this.yearTaxReturn ? true : false,
      }
      if (this.taxReturn.rif) {
        data.tax_return = this.taxReturn.rif.amounts
        data.documents_and_transactions =
          this.taxReturn.rif.documents_and_transactions
      }
      if (this.taxReturn.arrendamiento) {
        data.tax_return = this.taxReturn.arrendamiento.amounts
        data.documents_and_transactions =
          this.taxReturn.arrendamiento.documents_and_transactions
      }
      if (this.taxReturn.digital_platforms) {
        data.tax_return = this.taxReturn.digital_platforms.amounts
        data.documents_and_transactions =
          this.taxReturn.digital_platforms.documents_and_transactions
      }

      if (this.taxReturn.resico) {
        data.tax_return = this.taxReturn.resico.amounts
        data.documents_and_transactions =
          this.taxReturn.resico.documents_and_transactions
      }

      if (this.taxReturn.professional_activity) {
        data.tax_return = this.taxReturn.professional_activity.amounts
        data.documents_and_transactions =
          this.taxReturn.professional_activity.documents_and_transactions
      }
      if (this.taxReturn.yearly_resico) {
        data.tax_return = this.taxReturn.yearly_resico.tax_determination
        //data.documents_and_transactions = this.taxReturn.yearly_resico.documents_and_transactions
      }
      this.storeTaxReturn(data)
        .then((response) => {
          if (response.data.success) {
            window.toastr.success(
              this.$t('tax_returns.messages.tax_return_saved')
            )
            this.$router.push({
              name: 'tax-return.index',
            })
          } else {
            window.toastr.error(
              this.$t('tax_returns.declaracion_without_checking')
            )
          }
        })
        .catch(() => {
          window.toastr.error(this.$t('tax_returns.errors.save_tax_return'))
        })
    },

    toggleDisplayTable(table) {
      this.displayTables[table] = !this.displayTables[table]
    },

    cleanTables() {
      this.taxReturn = {
        rif: null,
        arrendamiento: null,
        digital_platforms: null,
        resico: null,
        professional_activity: null,
        yearly: null,
        yearly_resico: null,
        yearly_digitales: null,
      }
    },

    applyInterests(financialInstitutions) {
      this.filters.additional.financialInstitutions = financialInstitutions
      this.loadInfo()
    },
    applyPersonalDeductions(personalDeductions) {
      this.filters.additional.personalDeductions = personalDeductions
      this.loadInfo()
    },
    applyTaxLoss(taxLoss) {
      this.filters.additional.taxLoss = taxLoss
      this.loadInfo()
    },
    goComplementaria() {
      sessionStorage.setItem('periodTaxReturn', this.periodTaxReturn)
      sessionStorage.setItem('startMonth', this.startMonth)
      sessionStorage.setItem('endMonth', this.endMonth)
      sessionStorage.setItem('yearTaxReturn', this.yearTaxReturn)

      this.$router.push({
        name: 'tax-return.create',
      })
      this.$router.go(0)
    },
  },
}
</script>
