<template>
  <div class="sidebar-left">
    <div class="sidebar-body scroll-pane">
      <div class="side-nav">
        <div v-for="(menuItems, index) in menu" :key="index" class="menu-group">
          <router-link
            v-for="(item, index1) in menuItems"
            :key="index1"
            :to="item.route"
            class="menu-item"
            @click.native="Toggle"
          >
            <font-awesome-icon :icon="item.icon" class="icon menu-icon" />
            <span class="ml-3 menu-text">{{ $t(item.title) }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    menu: [
      [
        {
          title: 'navigation.dashboard',
          icon: 'tachometer-alt',
          route: '/customer/dashboard',
        },
        {
          title: 'navigation.tax_returns',
          icon: 'file',
          route: '/customer/tax-returns',
        },
        {
          title: 'navigation.comunication',
          icon: 'file',
          route: '/customer/dashboard',
        },
        {
          title: 'navigation.payments_history',
          icon: 'file',
          route: '/customer/dashboard',
        },
        {
          title: 'navigation.sat_certificates',
          icon: 'key',
          route: '/customer/certificates',
        },
      ],
    ],
  }),
  methods: {
    Toggle() {
      this.$utils.toggleSidebar()
    },
  },
}
</script>
