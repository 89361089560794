export const paidDocumentOptions = (state) => state.paidDocumentOptions
export const searchPaidDocumentOption = (state) => (name) => {
  const filtered = state.paidDocumentOptions.filter(
    (option) => option.name == name
  )
  if (filtered.length) {
    return filtered[0]
  }
  return null
}
export const taxRegimes = (state) => state.taxRegimes
export const searchTaxRegime = (state) => (id) => {
  const filtered = state.taxRegimes.filter((taxRegime) => {
    return taxRegime.id == id
  })
  if (filtered.length) {
    return filtered[0]
  }
  return null
}
export const digitalPlatformOptions = (state) => state.digitalPlatformOptions
export const searchDigitalPlatformOption = (state) => (key) => {
  const filtered = state.digitalPlatformOptions.filter((item) => {
    return item.key == key
  })
  if (filtered.length) {
    return filtered[0]
  }
  return null
}

export const useCFDI = (state) => state.useCFDI
export const searchUseCFDI = (state) => (id) => {
  const filtered = state.useCFDI.filter((item) => {
    return item.id == id
  })
  if (filtered.length) {
    return filtered[0]
  }
  return null
}

export const paymentMethods = (state) => state.paymentMethods
export const searchPaymentMethod = (state) => (id) => {
  const filtered = state.paymentMethods.filter((item) => {
    return item.id == id
  })
  if (filtered.length) {
    return filtered[0]
  }
  return null
}

export const valuesIva = (state) => state.valueIva
export const searchValueIva = (state) => (id) => {
  const filtered = state.valueIva.filter((item) => {
    return item.id == id
  })
  if (filtered.length) {
    return filtered[0]
  }
  return null
}

export const wayToPay = (state) => state.wayToPay
export const searchWayToPay = (state) => (id) => {
  const filtered = state.wayToPay.filter((item) => {
    return item.id == id
  })
  if (filtered.length) {
    return filtered[0]
  }
  return null
}

export const unitCodeSelect = (state) => state.unitCodeSelect
export const searchUnitCodeSelect = (state) => (id) => {
  const filtered = state.unitCodeSelect.filter((item) => {
    return item.id == id
  })
  if (filtered.length) {
    return filtered[0]
  }
  return null
}

export const taxReturnPeriodPerTaxRegime = (state) =>
  state.taxReturnPeriodPerTaxRegime

export const perceptionTypes = (state) => state.perceptionTypes
export const deductionTypes = (state) => state.deductionTypes
export const otherPaymentTypes = (state) => state.otherPaymentTypes
