const monthlyPeriod = [
  {
    name: 'Enero',
    startMonth: 1,
    endMonth: 1,
  },
  {
    name: 'Febrero',
    startMonth: 2,
    endMonth: 2,
  },
  {
    name: 'Marzo',
    startMonth: 3,
    endMonth: 3,
  },
  {
    name: 'Abril',
    startMonth: 4,
    endMonth: 4,
  },
  {
    name: 'Mayo',
    startMonth: 5,
    endMonth: 5,
  },
  {
    name: 'Junio',
    startMonth: 6,
    endMonth: 6,
  },
  {
    name: 'Julio',
    startMonth: 7,
    endMonth: 7,
  },
  {
    name: 'Agosto',
    startMonth: 8,
    endMonth: 8,
  },
  {
    name: 'Septiembre',
    startMonth: 9,
    endMonth: 9,
  },
  {
    name: 'Octubre',
    startMonth: 10,
    endMonth: 10,
  },
  {
    name: 'Noviembre',
    startMonth: 11,
    endMonth: 11,
  },
  {
    name: 'Diciembre',
    startMonth: 12,
    endMonth: 12,
  },
  {
    name: 'Anual',
    startMonth: 1,
    endMonth: 12,
  },
]
const bimonthlyPeriod = [
  {
    name: 'Enero-Febrero',
    startMonth: 1,
    endMonth: 2,
  },
  {
    name: 'Marzo-Abril',
    startMonth: 3,
    endMonth: 4,
  },
  {
    name: 'Mayo-Junio',
    startMonth: 5,
    endMonth: 6,
  },
  {
    name: 'Julio-Agosto',
    startMonth: 7,
    endMonth: 8,
  },
  {
    name: 'Septiembre-Octubre',
    startMonth: 9,
    endMonth: 10,
  },
  {
    name: 'Noviembre-Diciembre',
    startMonth: 11,
    endMonth: 12,
  },
]

export const taxReturnPeriodPerTaxRegime = {
  '601': monthlyPeriod,
  '606': monthlyPeriod,
  '612': monthlyPeriod,
  '621': bimonthlyPeriod,
  '625': monthlyPeriod,
  '626': monthlyPeriod,
}
