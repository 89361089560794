var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-content mt-5" },
    [
      _vm._l(_vm.quickAssignmentInvoices, function (expense) {
        return _c("expense", { key: expense.id, attrs: { expense: expense } })
      }),
      _vm._v(" "),
      _c(
        "infinite-loading",
        {
          ref: "infinite",
          on: {
            infinite: function ($event) {
              return _vm.loadExpenses($event)
            },
          },
        },
        [
          _c("div", { attrs: { slot: "no-more" }, slot: "no-more" }, [
            _vm._v(_vm._s(_vm.$t("general.no_more_elements"))),
          ]),
          _vm._v(" "),
          _c("div", { attrs: { slot: "no-results" }, slot: "no-results" }, [
            _vm._v(_vm._s(_vm.$t("general.no_more_elements"))),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }