import * as types from './mutation-types'
import Ls from '@/services/ls'

export const updateCurrentUser = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    axios
      .post('/api/hq/users', data)
      .then((response) => {
        commit(types.UPDATE_CURRENT_USER, response.data.user)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getEjecutivos = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    axios
      .get('/api/users/ejecutivos')
      .then((response) => {
        resolve(response.data.ejecutivos)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const setEjecutivos = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    axios
      .post('/api/user/assignment/ejecutivo',data)
      .then((response) => {
        resolve(response.data.assignment)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const fetchUsers = ({ commit, dispatch, state }, params) => {
  return new Promise((resolve, reject) => {
      window.axios.get(`/api/users`, { params }).then((response) => {

         console.log(response);
          resolve(response)
      }).catch((err) => {
          reject(err)
      })
  })
}
