<template>
  <button
    class="btn btn-primary"
    @click="showModal"
    :disabled="categoriesSelect.length === 0"
  >
    Aplicar categorías ({{ categoriesSelect.length }})
  </button>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  props: {
    invoiceConcept: {
      default: null,
      type: Object,
    },
  },

  data: () => ({
    subcategory: null,
    isLoading: true,
  }),

  computed: {
    ...mapGetters('conceptCategories', ['categoriesSelect']),
    categoryString() {
      if (this.isLoading) {
        return this.$t('concept_categories.loading')
      }
      if (this.subcategory) {
        return `${this.subcategory.concept_category.name}/${this.subcategory.name}`
      }
      if (
        !this.isLoading &&
        this.invoiceConcept.concept_subcategory_id &&
        !this.subcategory
      ) {
        return this.$t('concept_categories.category_not_found')
      }
      return this.$t('concept_categories.uncategorized')
    },
  },

  mounted() {
    this.loadSubcategory()
  },

  methods: {
    ...mapActions('modal', ['openModal']),
    ...mapActions('conceptCategories', [
      'fetchSubcategory',
      'setModalChangeSubcategoryData',
      'addCategoriesSelect',
    ]),

    loadSubcategory() {
      if (!this.invoiceConcept.concept_subcategory_id) {
        this.isLoading = false
        return
      }
      this.isLoading = true
      this.fetchSubcategory(this.invoiceConcept.concept_subcategory_id)
        .then((response) => {
          this.subcategory = response.data.concept_subcategory
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
        })
    },

    showModal() {
      // this.setModalChangeSubcategoryData({
      //   invoice_id: this.invoiceConcept.invoice_id,
      //   invoice_item_id: this.invoiceConcept.id,
      // })
      this.openModal({
        title: this.$t('concept_categories.modal.change_category'),
        componentName: 'ChangeCategoriesToInvoiceConceptModal',
      })
    },
  },
}
</script>

<style scoped>
.category-button {
  cursor: pointer;
  color: #0966c3;
}
</style>
