var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showYearView,
          expression: "showYearView",
        },
      ],
      class: [_vm.calendarClass, "vdp-datepicker__calendar"],
      style: _vm.calendarStyle,
      on: {
        mousedown: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _vm._t("beforeCalendarHeader"),
      _vm._v(" "),
      _c("header", [
        _c(
          "span",
          {
            staticClass: "prev",
            class: { disabled: _vm.isLeftNavDisabled },
            on: {
              click: function ($event) {
                _vm.isRtl ? _vm.nextDecade() : _vm.previousDecade()
              },
            },
          },
          [_vm._v("<")]
        ),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm.getPageDecade))]),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass: "next",
            class: { disabled: _vm.isRightNavDisabled },
            on: {
              click: function ($event) {
                _vm.isRtl ? _vm.previousDecade() : _vm.nextDecade()
              },
            },
          },
          [_vm._v(">")]
        ),
      ]),
      _vm._v(" "),
      _vm._l(_vm.years, function (year) {
        return _c(
          "span",
          {
            key: year.timestamp,
            staticClass: "cell year",
            class: { selected: year.isSelected, disabled: year.isDisabled },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.selectYear(year)
              },
            },
          },
          [_vm._v(_vm._s(year.year))]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }