<template>
  <tr>
    <td>
      <sub-string :string="concept.description" :length="30" />
    </td>
    <td>
      {{ concept.clave_prod_serv }}
    </td>
    <td class="text-center">
      {{ concept.quantity }}
    </td>
    <td class="text-center">
      <money-text :amount="Number(concept.total)" />
    </td>
    <td class="text-center">
      <money-text :amount="Number(concept.discount_val)" />
    </td>
    <td class="text-center">
      <money-text :amount="Number(concept.iva)" />
    </td>
    <td class="text-center">
      <money-text :amount="Number(concept.total) + Number(concept.iva)" />
    </td>
    <td>
      <expense-category :concept="concept" :ref="category" />
    </td>
    <td>
      <div class="text-center">
        <input
          :checked="inTaxReturn"
          type="checkbox"
          @click="toogleInTaxReturn"
        />
      </div>
    </td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex'
import ExpenseCategory from './ExpenseCategory.vue'
export default {
  components: {
    ExpenseCategory,
  },
  props: {
    concept: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    inTaxReturnAuto: true,
    category: false,
  }),
  computed: {
    inTaxReturn() {
      return this.concept.quickAssignment.inTaxReturn
    },
  },
  created() {},
  methods: {
    ...mapActions('invoice', ['quickAssignmentToggleInTaxReturn']),
    toogleInTaxReturn() {
      this.quickAssignmentToggleInTaxReturn({
        conceptId: this.concept.id,
        prodServ: this.concept.clave_prod_serv,
        inTaxReturn: !this.inTaxReturn,
      })
    },
  },
}
</script>
