var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.taxReturn
    ? _c("div", { staticClass: "card p-3" }, [
        _c("h5", { staticClass: "text-center mb-3" }, [
          _vm._v("\n      Declaración Anual Plataformas Digitales\n    "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "border-top border-bottom text-center table-title" },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("tax_returns.determination_of_tax")) +
                "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "p-2" }, [
          _c(
            "div",
            { staticClass: "form-group flex-content-right" },
            [
              _c(
                "base-button",
                {
                  staticClass: "mr-2",
                  attrs: { color: "danger", size: "small" },
                  on: { click: _vm.openApplyTaxLossModal },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("tax_losses.apply_tax_loss")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "table-responsive" }, [
          _c("table", { staticClass: "table" }, [
            _c("tbody", [
              _c("tr", [
                _c("td", [
                  _c("a", { attrs: { href: "#revenue" } }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("tax_returns.accumulable_revenue")) +
                        "\n              "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: {
                        amount: _vm.taxDetermination.accumulable_revenue,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _c("a", { attrs: { href: "#persona_deductions" } }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("tax_returns.personal_deductions")) +
                        "\n              "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: {
                        amount: _vm.taxDetermination.personal_deductions,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$t("tax_returns.taxable_base")))]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: { amount: _vm.taxDetermination.taxable_base },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("tax_returns.isr_according_to_the_annual_rate")
                    )
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: {
                        amount:
                          _vm.taxDetermination.isr_to_according_annual_rate,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _c("a", { attrs: { href: "#isr_withheld" } }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("tax_returns.isr_detained")) +
                        "\n              "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: { amount: _vm.taxDetermination.isr_withheld },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$t("tax_returns.isr_in_charge")))]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: { amount: _vm.taxDetermination.isr_in_charge },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm.byIssuer
          ? _c("div", [
              _c(
                "div",
                {
                  staticClass:
                    "border-top border-bottom text-center table-title",
                },
                [
                  _c("a", { attrs: { href: "#tables-wages-salaries" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("tax_returns.wages_and_salaries")) +
                        "\n        "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.showByIssuerTable
                ? _c("table", { staticClass: "sub-table" }, [
                    _c("thead", [
                      _c("tr", [
                        _c("td", [
                          _vm._v(_vm._s(_vm.$t("tax_returns.retainer_rfc"))),
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.$t("invoices.name")))]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("tax_returns.annual_revenue")) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("tax_returns.exempt_revenue")) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("tax_returns.isr_withheld")) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("tax_returns.employment_subsidy")) +
                              "\n            "
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.byIssuer, function (issuer) {
                        return _c("tr", { key: issuer.rfc }, [
                          _c("td", [_vm._v(_vm._s(issuer.rfc))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(issuer.name))]),
                          _vm._v(" "),
                          _c(
                            "td",
                            { staticClass: "text-right" },
                            [
                              _c("money-text", {
                                attrs: { amount: issuer.annual_revenue },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            { staticClass: "text-right" },
                            [
                              _c("money-text", {
                                attrs: { amount: issuer.exempt_amount },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            { staticClass: "text-right" },
                            [
                              _c("money-text", {
                                attrs: { amount: issuer.deduction_isr },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            { staticClass: "text-right" },
                            [
                              _c("money-text", {
                                attrs: { amount: issuer.employment_subsidy },
                              }),
                            ],
                            1
                          ),
                        ])
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "table-responsive" }, [
                _c("table", { staticClass: "table" }, [
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [
                        _vm._v(_vm._s(_vm.$t("tax_returns.annual_revenue"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount:
                                _vm.taxReturn.wages_and_salaries.annual_revenue,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(_vm._s(_vm.$t("tax_returns.exempt_revenue"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount:
                                _vm.taxReturn.wages_and_salaries.exempt_revenue,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm.$t("tax_returns.accumulable_revenue"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount:
                                _vm.taxReturn.wages_and_salaries
                                  .accumulable_revenue,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm.$t("tax_returns.employment_subsidy"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount:
                                _vm.taxReturn.wages_and_salaries
                                  .employment_subsidy,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(_vm._s(_vm.$t("tax_returns.tax_detained"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount:
                                _vm.taxReturn.wages_and_salaries.isr_withheld,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "border-top border-bottom text-center table-title",
            attrs: { id: "personal_deductions" },
          },
          [
            _c("a", { attrs: { href: "#table-personal-deductions" } }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("tax_returns.personal_deductions")) +
                  "\n      "
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "table-responsive" },
          _vm._l(_vm.personalDeductions, function (category, i) {
            return _c(
              "table",
              { key: i, staticClass: "table personal-deductions" },
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", { attrs: { colspan: "2" } }, [
                      _vm._v(_vm._s(category.subcategory.name)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", { staticClass: "col" }, [
                      _vm._v(_vm._s(_vm.$t("invoices.date"))),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "col" }, [
                      _vm._v(_vm._s(_vm.$t("invoices.rfc"))),
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.$t("invoices.name")))]),
                    _vm._v(" "),
                    _c("td", { staticClass: "amount" }, [
                      _vm._v(_vm._s(_vm.$t("tax_returns.deductible_amount"))),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  [
                    _vm._l(category.items, function (item) {
                      return _c("tr", { key: item.id }, [
                        _c(
                          "td",
                          { staticClass: "col" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "invoices.view",
                                    params: { id: item.invoice_id },
                                  },
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(item.date) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "col" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "invoices.view",
                                    params: { id: item.invoice_id },
                                  },
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(item.rfc) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(item.name))]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "amount" },
                          [
                            _c("money-text", {
                              attrs: { amount: item.amount },
                            }),
                          ],
                          1
                        ),
                      ])
                    }),
                    _vm._v(" "),
                    _c("tr", [
                      _c(
                        "td",
                        { staticClass: "text-right", attrs: { colspan: "3" } },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("tax_returns.total")) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: { amount: category.total },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "border-top border-bottom text-center table-title",
            attrs: { id: "isr_withheld" },
          },
          [
            _c("a", { attrs: { href: "#table-revenue" } }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("tax_returns.retentions")) +
                  "\n      "
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "table-responsive" }, [
          _c("table", { staticClass: "table" }, [
            _c(
              "tbody",
              [
                _c("tr", [
                  _c(
                    "th",
                    {
                      staticClass: "text-center table-subtitle",
                      attrs: { colspan: "3" },
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("tax_returns.wages_and_salaries")) +
                          "\n            "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("tax_returns.retainer_rfc")) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("tax_returns.revenue_amount")) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("tax_returns.isr_retentions")) +
                        "\n            "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm._l(_vm.withhelds.salaries, function (withheld, i) {
                  return _c("tr", { key: i }, [
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(withheld.rfc) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "text-right" },
                      [
                        _c("money-text", {
                          attrs: { amount: withheld.amount },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "text-right" },
                      [_c("money-text", { attrs: { amount: withheld.isr } })],
                      1
                    ),
                  ])
                }),
                _vm._v(" "),
                _c("tr", [
                  _c(
                    "th",
                    {
                      staticClass: "text-center table-subtitle",
                      attrs: { colspan: "3" },
                    },
                    [
                      _c("tax-regime-catalog", {
                        attrs: { "tax-regime": _vm.currentCustomer.tax_regime },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("tax_returns.retainer_rfc")) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("tax_returns.revenue_amount")) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("tax_returns.isr_retentions")) +
                        "\n            "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm._l(_vm.withhelds.regime, function (withheld, i) {
                  return _c("tr", { key: i }, [
                    _c("td", { staticClass: "text-center" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(withheld.rfc) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "text-right" },
                      [
                        _c("money-text", {
                          attrs: { amount: withheld.amount },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "text-right" },
                      [_c("money-text", { attrs: { amount: withheld.isr } })],
                      1
                    ),
                  ])
                }),
              ],
              2
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "mt-4 text-center clickable",
            attrs: { id: "table-revenue" },
            on: {
              click: function ($event) {
                return _vm.toggleDisplayTable("revenue")
              },
            },
          },
          [_c("ins", [_vm._v(_vm._s(_vm.$t("tax_returns.revenue_perceived")))])]
        ),
        _vm._v(" "),
        _vm.displayTables.revenue
          ? _c(
              "div",
              [
                _vm.taxReturn.tables.revenue
                  ? _c("tax-return-invoices-table", {
                      attrs: { invoices: _vm.taxReturn.tables.revenue },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "mt-4 text-center clickable",
            attrs: { id: "table-expenses" },
            on: {
              click: function ($event) {
                return _vm.toggleDisplayTable("purchases")
              },
            },
          },
          [_c("ins", [_vm._v(_vm._s(_vm.$t("tax_returns.purchases")))])]
        ),
        _vm._v(" "),
        _vm.displayTables.purchases
          ? _c(
              "div",
              [
                _vm.taxReturn.tables.purchases
                  ? _c("tax-return-invoices-table", {
                      attrs: { invoices: _vm.taxReturn.tables.purchases },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "mt-4 text-center clickable",
            attrs: { id: "table-deductions" },
            on: {
              click: function ($event) {
                return _vm.toggleDisplayTable("deductions")
              },
            },
          },
          [_c("ins", [_vm._v(_vm._s(_vm.$t("tax_returns.deductions")))])]
        ),
        _vm._v(" "),
        _vm.displayTables.deductions
          ? _c(
              "div",
              [
                _vm.taxReturn.tables.tax_deductions
                  ? _c("tax-return-invoices-table", {
                      attrs: {
                        invoices: _vm.taxReturn.tables.tax_deductions,
                        "can-display-no-object": true,
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "mt-4 text-center clickable",
            attrs: { id: "tables-wages-salaries" },
            on: {
              click: function ($event) {
                return _vm.toggleDisplayTable("salaries")
              },
            },
          },
          [
            _c("ins", [
              _vm._v(_vm._s(_vm.$t("tax_returns.wages_and_salaries"))),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.displayTables.salaries
          ? _c("div", { staticClass: "mt-4 table-responsive" }, [
              _c(
                "table",
                { staticClass: "table table-bordered invoices-table" },
                [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t("invoices.invoice_date"))),
                      ]),
                      _vm._v(" "),
                      _c("th", [_vm._v(_vm._s(_vm.$t("invoices.uuid")))]),
                      _vm._v(" "),
                      _c("th", [_vm._v(_vm._s(_vm.$t("invoices.rfc")))]),
                      _vm._v(" "),
                      _c("th", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm.$t("tax_returns.taxed_amount"))),
                      ]),
                      _vm._v(" "),
                      _c("th", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm.$t("tax_returns.exempt_amount"))),
                      ]),
                      _vm._v(" "),
                      _c("th", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm.$t("tax_returns.deduction_isr"))),
                      ]),
                      _vm._v(" "),
                      _c("th", { staticClass: "text-right" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("tax_returns.employment_subsidy")) +
                            "\n            "
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    [
                      _vm._l(
                        _vm.taxReturn.tables.wages_and_salaries,
                        function (invoice) {
                          return _c("tr", { key: invoice.id }, [
                            _c("td", [_vm._v(_vm._s(invoice.invoice_date))]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "invoices.view",
                                        params: { id: invoice.id },
                                      },
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(invoice.uuid) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(invoice.rfc))]),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "text-right" },
                              [
                                _c("money-text", {
                                  attrs: { amount: invoice.taxed_amount },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "text-right" },
                              [
                                _c("money-text", {
                                  attrs: { amount: invoice.exempt_amount },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "text-right" },
                              [
                                _c("money-text", {
                                  attrs: { amount: invoice.deduction_isr },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "text-right" },
                              [
                                _c("money-text", {
                                  attrs: { amount: invoice.employment_subsidy },
                                }),
                              ],
                              1
                            ),
                          ])
                        }
                      ),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", { attrs: { colspan: "3" } }),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: {
                                amount:
                                  _vm.wagesAndSaliariesTotals.taxed_amount,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: {
                                amount:
                                  _vm.wagesAndSaliariesTotals.exempt_amount,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: {
                                amount:
                                  _vm.wagesAndSaliariesTotals.deduction_isr,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: {
                                amount:
                                  _vm.wagesAndSaliariesTotals
                                    .employment_subsidy,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                ]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "mt-4 text-center clickable",
            attrs: { id: "table-personal-deductions" },
            on: {
              click: function ($event) {
                return _vm.toggleDisplayTable("personalDeductions")
              },
            },
          },
          [
            _c("ins", [
              _vm._v(_vm._s(_vm.$t("tax_returns.personal_deductions"))),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.displayTables.personalDeductions
          ? _c(
              "div",
              [
                _vm._l(
                  _vm.taxReturn.tables.personal_deductions,
                  function (subcategory) {
                    return _c(
                      "div",
                      {
                        key: subcategory.id,
                        staticClass: "mt-4 table-responsive",
                      },
                      [
                        _c(
                          "table",
                          {
                            staticClass: "table table-bordered invoices-table",
                          },
                          [
                            _c("thead", [
                              _c("tr", { staticClass: "border-bottom" }, [
                                _c(
                                  "th",
                                  {
                                    staticClass: "text-center",
                                    attrs: { colspan: "6" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(subcategory.subcategory.name) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [
                                  _vm._v(
                                    _vm._s(_vm.$t("invoices.invoice_date"))
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("th", [
                                  _vm._v(_vm._s(_vm.$t("invoices.uuid"))),
                                ]),
                                _vm._v(" "),
                                _c("th", [
                                  _vm._v(_vm._s(_vm.$t("invoices.rfc"))),
                                ]),
                                _vm._v(" "),
                                _c("th", [
                                  _vm._v(_vm._s(_vm.$t("invoices.name"))),
                                ]),
                                _vm._v(" "),
                                _c("th", [
                                  _vm._v(
                                    _vm._s(_vm.$t("invoices.item.description"))
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("th", { staticClass: "text-right" }, [
                                  _vm._v(_vm._s(_vm.$t("invoices.amount"))),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              _vm._l(subcategory.items, function (item) {
                                return _c("tr", { key: item.id }, [
                                  _c("td", [_vm._v(_vm._s(item.invoice_date))]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "invoices.view",
                                              params: { id: item.invoice_id },
                                            },
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(item.uuid) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(item.rfc))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(item.name))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(item.description))]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "text-right" }, [
                                    _c(
                                      "div",
                                      { staticClass: "base-input" },
                                      [
                                        _c(
                                          "money",
                                          _vm._b(
                                            {
                                              staticClass:
                                                "input-field text-right",
                                              model: {
                                                value: item.amount_edit,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "amount_edit",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.amount_edit",
                                              },
                                            },
                                            "money",
                                            _vm.customerCurrency,
                                            false
                                          )
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ])
                              }),
                              0
                            ),
                          ]
                        ),
                      ]
                    )
                  }
                ),
                _vm._v(" "),
                _vm.can.applyPersonalDeductions
                  ? _c(
                      "div",
                      { staticClass: "mt-2 flex-content-right" },
                      [
                        _c(
                          "base-button",
                          {
                            attrs: { size: "small", color: "info" },
                            on: { click: _vm.applyPersonalDeductions },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t(
                                    "tax_returns.apply_personal_deductions"
                                  )
                                ) +
                                "\n        "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }