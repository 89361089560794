<template>
  <span>
    {{ text }}
  </span>
</template>

<script>
export default {
  props: {
    string: {
      default: '',
      type: String,
    },
    start: {
      default: 0,
      type: Number
    },
    length: {
      default: 19,
      type: Number
    }
  },
  computed: {
    text () {
      let substring = this.string.substring(this.start, this.length);
      if (this.string.length > 19) {
        substring += '...';
      }
      return substring;
    }
  }
}
</script>