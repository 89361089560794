var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-content" },
    [
      _c("div", { staticClass: "page-header mt-5" }, [
        _c("h3", { staticClass: "page-title" }, [
          _vm._v(_vm._s(_vm.$t("retenciones.page_title"))),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "table-component",
        {
          ref: "table",
          attrs: {
            data: _vm.getRetenciones,
            "filter-no-results": _vm.$t("general.filterNoResults"),
            "show-filter": false,
            "table-class": "table",
          },
        },
        [
          _c("table-column", {
            attrs: {
              label: _vm.$t("retenciones.date"),
              show: "retention_date",
            },
          }),
          _vm._v(" "),
          _c("table-column", {
            attrs: {
              label: _vm.$t("retenciones.retention_number"),
              show: "retention_number",
            },
          }),
          _vm._v(" "),
          _c("table-column", {
            attrs: {
              label: _vm.$t("retenciones.issuing_name"),
              show: "issuing_name",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("retenciones.issuing_name"))),
                    ]),
                    _vm._v(" "),
                    _c("sub-string", { attrs: { string: row.issuing_name } }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("table-column", {
            attrs: {
              label: _vm.$t("retenciones.issuing_rfc"),
              show: "issuing_rfc",
            },
          }),
          _vm._v(" "),
          _c("table-column", {
            attrs: { label: _vm.$t("retenciones.base") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [_vm._v(_vm._s(_vm.$t("retenciones.base")))]),
                    _vm._v(" "),
                    _c("money-text", {
                      attrs: { amount: row.total_transaction_amount },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("table-column", {
            attrs: { label: _vm.$t("retenciones.retention_iva") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("retenciones.retention_iva"))),
                    ]),
                    _vm._v(" "),
                    _c("filter-retention-tax", {
                      attrs: { taxes: row.taxes, "tax-code": "02" },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("table-column", {
            attrs: { label: _vm.$t("retenciones.retention_isr") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("retenciones.retention_isr"))),
                    ]),
                    _vm._v(" "),
                    _c("filter-retention-tax", {
                      attrs: { taxes: row.taxes, "tax-code": "01" },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("table-column", {
            attrs: {
              label: _vm.$t("retenciones.total_retention_amount"),
              show: "total_retention_amount",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("retenciones.total_retention_amount"))
                      ),
                    ]),
                    _vm._v(" "),
                    _c("money-text", {
                      attrs: { amount: row.total_retention_amount },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("table-column", {
            attrs: {
              sortable: false,
              filterable: false,
              "cell-class": "action-dropdown no-click",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [_vm._v(_vm._s(_vm.$t("retenciones.actions")))]),
                    _vm._v(" "),
                    _c(
                      "v-dropdown",
                      [
                        _c(
                          "a",
                          {
                            attrs: { slot: "activator", href: "#" },
                            slot: "activator",
                          },
                          [_c("dot-icon")],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-dropdown-item",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item",
                                attrs: {
                                  to: {
                                    name: "retenciones.view",
                                    params: { id: row.id },
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "dropdown-item-icon",
                                  attrs: { icon: "eye" },
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("retenciones.view")) +
                                    "\n            "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }