<template>
  <div class="main-content">

    <div class="page-header mt-4">
      <h3 class="page-title">{{ $t('concept_categories.categories') }}</h3>
      <div class="page-actions row">
        <div class="col-4">
          <base-button
            @click="openAddCategoryModal()"
            color="theme"
          >
          {{ $t('concept_categories.add_category') }}
          </base-button>
        </div>
      </div>
    </div>


    <div
      v-for="category in categories"
      :key="category.id"
      class="card p-2"
    >
      <div class="row">
        <div class="col-8">
          <h5>{{ category.name }}</h5>
        </div>
        <div class="col-4 flex-content-right">
          <button
            v-if="category.removeable"
            class="base-button btn btn-sm btn-danger text-white"
            @click="deleteCategory(category)"
          >
            {{ $t('general.delete') }}
          </button>
          &nbsp;
          <base-button
            @click="openAddSubCategoryModal(category)"
            color="theme-light"
            size="small"
          >
            {{ $t('concept_categories.add_subcategory') }}
          </base-button>
        </div>
      </div>

      <div
        v-for="subcategory in category.concept_subcategories"
        :key="subcategory.id"
        class="row"
      >
        <div class="col-12 col-md-6 pl-5 flex-vertical-center">
          {{ subcategory.name }}
        </div>
        <div class="col-12 col-md-2 flex-vertical-center">
          <base-switch
            v-if="subcategory.removeable"
            v-model="subcategory.in_tax_return"
            @change="updateSubcategoryInTaxReturn(subcategory)"
          />
        </div>
        <div class="col-12 col-md-2 flex-vertical-center">
          <button
            v-if="subcategory.removeable"
            class="btn btn-sm btn-danger text-white"
            @click="deleteSubcategory(subcategory)"
          >
            {{ $t('general.delete') }}
          </button>
        </div>
      </div>
    </div>

    <pagination
      v-if="paginationCategories"
      :pagination="paginationCategories"
      @pageChange="pageChange"
    />

  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Pagination from '../../components/base/base-table/components/Pagination'

export default {
  components: {
    Pagination,
  },

  data: () => ({
    categories: [],
    currentPage: 1,
    paginationCategories: null,
  }),

  mounted () {
    this.loadCategories();
    window.hub.$on('concept_categories.category.created', () => {
      this.loadCategories();
    });
  },

  methods: {
    ...mapActions('modal', [
      'openModal',
    ]),
    ...mapActions('conceptCategories', [
      'fetchCategories',
      'setSubcategoryModalData',
      'fetchUpdateConceptSubcategory',
      'fetchDeleteConceptCategory',
      'fetchDeleteConceptSubcategory',
    ]),

    loadCategories ()
    {
      const params = {
        page: this.currentPage,
      }
      this.fetchCategories(params)
        .then(response => {
          this.categories = response.data.data;
          this.paginationCategories = {
            totalPages: response.data.last_page,
            currentPage: response.data.current_page,
            count: response.data.total,
          }
        });
    },

    pageChange (page)
    {
      this.currentPage = page;
      this.loadCategories();
    },

    openAddCategoryModal ()
    {
      this.openModal({
        title: this.$t('concept_categories.add_category'),
        componentName: 'AddConceptCategoryModal'
      });
    },

    openAddSubCategoryModal (category)
    {
      this.setSubcategoryModalData({
        concept_category_id: category.id,
      })
      this.openModal({
        title: this.$t('concept_categories.add_subcategory'),
        componentName: 'AddConceptSubCategoryModal',
      });
    },

    updateSubcategoryInTaxReturn (subcategory)
    {
      const data = {
        id: subcategory.id,
        form: {
          in_tax_return: subcategory.in_tax_return,
        }
      }

      this.fetchUpdateConceptSubcategory(data)
        .then(response => {
          window.toastr['success'](this.$t('concept_categories.messages.subcategory_updated'))
        })
    },

    deleteCategory(category)
    {
      window.swal({
        title: this.$t('general.are_you_sure'),
        text: this.$tc('concept_categories.messages.confirm_delete_category'),
        icon: '/assets/icon/trash-solid.svg',
        buttons: true,
        dangerMode: true
      }).then(async (willDelete) => {
        if (willDelete) {
          this.fetchDeleteConceptCategory(category.id)
            .then((response) => {
              this.loadCategories()
              window.toastr['success'](
                this.$t('concept_categories.messages.category_deleted')
              )
            })
            .catch((err) => {
              window.toastr['error'](
                this.$t('concept_categories.errors.category_delete')
              )
            })
        }
      })
    },

    deleteSubcategory (subcategory)
    {
      window.swal({
        title: this.$t('general.are_you_sure'),
        text: this.$tc('concept_categories.messages.confirm_delete_subcategory'),
        icon: '/assets/icon/trash-solid.svg',
        buttons: true,
        dangerMode: true
      }).then(async (willDelete) => {
        if (willDelete) {
          this.fetchDeleteConceptSubcategory(subcategory.id)
            .then((response) => {
              this.loadCategories()
              window.toastr['success'](
                this.$t('concept_categories.messages.subcategory_deleted')
              )
            })
            .catch((err) => {
              window.toastr['error'](
                this.$t('concept_categories.errors.subcategory_delete')
              )
            })
        }
      })
    },

  }
}
</script>
