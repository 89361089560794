export const useCFDI = [
  {
    id: 'G01',
    description: 'Adquisición de mercancias',
    physical: true,
    moral: true,
  },
  {
    id: 'G02',
    description: 'Devoluciones, descuentos o bonificaciones',
    physical: true,
    moral: true,
  },
  {
    id: 'G03',
    description: 'Gastos en general',
    physical: true,
    moral: true,
  },
  {
    id: 'I01',
    description: 'Construcciones',
    physical: true,
    moral: true,
  },
  {
    id: 'I02',
    description: 'Mobilario y equipo de oficina por inversiones',
    physical: true,
    moral: true,
  },
  {
    id: 'I03',
    description: 'Equipo de transporte',
    physical: true,
    moral: true,
  },
  {
    id: 'I04',
    description: 'Equipo de computo y accesorios',
    physical: true,
    moral: true,
  },
  {
    id: 'I05',
    description: 'Dados, troqueles, moldes, matrices y herramental',
    physical: true,
    moral: true,
  },
  {
    id: 'I06',
    description: 'Comunicaciones telefónicas',
    physical: true,
    moral: true,
  },
  {
    id: 'I07',
    description: 'Comunicaciones satelitales',
    physical: true,
    moral: true,
  },
  {
    id: 'I08',
    description: 'Otra maquinaria y equipo',
    physical: true,
    moral: true,
  },
  {
    id: 'D01',
    description: 'Honorarios médicos, dentales y gastos hospitalarios.',
    physical: true,
    moral: false,
  },
  {
    id: 'D02',
    description: 'Gastos médicos por incapaidd o discapaidd',
    physical: true,
    moral: false,
  },
  {
    id: 'D03',
    description: 'Gastos funerales.',
    physical: true,
    moral: false,
  },
  {
    id: 'D04',
    description: 'Donativos.',
    physical: true,
    moral: false,
  },
  {
    id: 'D05',
    description: 'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).',
    physical: true,
    moral: false,
  },
  {
    id: 'D06',
    description: 'Aportaciones voluntarias al SAR.',
    physical: true,
    moral: false,
  },
  {
    id: 'D07',
    description: 'Primas por seguros de gastos médicos.',
    physical: true,
    moral: false,
  },
  {
    id: 'D08',
    description: 'Gastos de transportación escolar obligatoria.',
    physical: true,
    moral: false,
  },
  {
    id: 'D09',
    description: 'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.',
    physical: true,
    moral: false,
  },
  {
    id: 'D10',
    description: 'Pagos por servicios educativos (colegiaturas)',
    physical: true,
    moral: false,
  },
  {
    id: 'P01',
    description: 'Por definir',
    physical: true,
    moral: true,
  },
  {
    id: 'S01',
    description: 'Sin efectos fiscales',
    physical: true,
    moral: true,
  },
]
