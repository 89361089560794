var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", { staticClass: "item-row invoice-item-row" }, [
    _c(
      "td",
      { staticStyle: { padding: "15px !important" } },
      [
        _c("base-input", {
          attrs: { invalid: _vm.$v.item.name.$error, type: "text" },
          on: {
            input: function ($event) {
              return _vm.$v.item.name.$touch()
            },
            keyup: _vm.updateItem,
          },
          model: {
            value: _vm.item.name,
            callback: function ($$v) {
              _vm.$set(_vm.item, "name", $$v)
            },
            expression: "item.name",
          },
        }),
        _vm._v(" "),
        _vm.$v.item.name.$error
          ? _c("div", [
              !_vm.$v.item.name.required
                ? _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.$t("validation.required"))),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "td",
      {
        staticClass: "text-center",
        staticStyle: { padding: "15px !important" },
      },
      [
        _c("base-input", {
          attrs: {
            invalid: _vm.$v.item.quantity.$error,
            type: "text",
            small: "",
          },
          on: {
            keyup: _vm.updateItem,
            input: function ($event) {
              return _vm.$v.item.quantity.$touch()
            },
          },
          model: {
            value: _vm.item.quantity,
            callback: function ($$v) {
              _vm.$set(_vm.item, "quantity", $$v)
            },
            expression: "item.quantity",
          },
        }),
        _vm._v(" "),
        _vm.$v.item.quantity.$error
          ? _c("div", [
              !_vm.$v.item.quantity.maxLength
                ? _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.$t("validation.quantity_maxlength"))),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "td",
      { staticClass: "text-left", staticStyle: { padding: "15px !important" } },
      [
        _c("div", { staticClass: "d-flex flex-column" }, [
          _c("div", { staticClass: "flex-fillbd-highlight" }, [
            _c(
              "div",
              { staticClass: "base-input" },
              [
                _c(
                  "money",
                  _vm._b(
                    {
                      staticClass: "input-field",
                      on: {
                        input: function ($event) {
                          return _vm.$v.item.price.$touch()
                        },
                        keyup: _vm.updateItem,
                      },
                      model: {
                        value: _vm.item.price,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "price", $$v)
                        },
                        expression: "item.price",
                      },
                    },
                    "money",
                    _vm.customerCurrency,
                    false
                  )
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.$v.item.price.$error
              ? _c("div", [
                  !_vm.$v.item.price.maxLength
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.$t("validation.price_maxlength"))),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "td",
      {
        staticClass: "text-right",
        staticStyle: { padding: "15px !important" },
      },
      [
        _c("div", { staticClass: "item-amount" }, [
          _c("span", [
            _c("div", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.$utils.formatMoney(_vm.subtotal, _vm.defaultCurrency)
                ),
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "remove-icon-wrapper" },
            [
              _vm.showRemoveItemIcon
                ? _c("font-awesome-icon", {
                    staticClass: "remove-icon",
                    attrs: { icon: "trash-alt" },
                    on: { click: _vm.removeItem },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }