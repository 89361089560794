var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "invoice-create-page main-content" },
    [
      !_vm.initLoading
        ? _c(
            "form",
            {
              attrs: { action: "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitInvoiceData.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "page-header" }, [
                _vm.$route.name === "invoices.edit"
                  ? _c("h3", { staticClass: "page-title" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("invoices.edit_invoice")) +
                          "\n      "
                      ),
                    ])
                  : _c("h3", { staticClass: "page-title" }, [
                      _vm._v(_vm._s(_vm.$t("invoices.new_invoice"))),
                    ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "page-actions row" },
                  [
                    _vm.$route.name === "invoices.edit"
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "mr-3 invoice-action-btn base-button btn btn-outline-primary default-size",
                            attrs: {
                              href: `/invoices/pdf/${_vm.newInvoice.unique_hash}`,
                              target: "_blank",
                              outline: "",
                              color: "theme",
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("general.view_pdf")) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "base-button",
                      {
                        staticClass: "invoice-action-btn",
                        attrs: {
                          loading: _vm.isLoading,
                          disabled: _vm.isLoading,
                          icon: "save",
                          color: "theme",
                          type: "submit",
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("invoices.save_invoice")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row invoice-input-group" }, [
                _c(
                  "div",
                  { staticClass: "col-md-5 invoice-customer-container" },
                  [
                    _vm.selectedCustomer
                      ? _c("div", { staticClass: "show-customer" }, [
                          _c("div", { staticClass: "row px-2 mt-1" }, [
                            _vm.selectedCustomer.billing_address
                              ? _c("div", { staticClass: "col col-6" }, [
                                  _c(
                                    "div",
                                    { staticClass: "row address-menu" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "col-sm-4 px-2 title" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("general.bill_to"))
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-sm p-0 px-2 content",
                                        },
                                        [
                                          _vm.selectedCustomer.billing_address
                                            .name
                                            ? _c("label", [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.selectedCustomer
                                                        .billing_address.name
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.selectedCustomer.billing_address
                                            .address_street_1
                                            ? _c("label", [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.selectedCustomer
                                                        .billing_address
                                                        .address_street_1
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.selectedCustomer.billing_address
                                            .address_street_2
                                            ? _c("label", [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.selectedCustomer
                                                        .billing_address
                                                        .address_street_2
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.selectedCustomer.billing_address
                                            .city &&
                                          _vm.selectedCustomer.billing_address
                                            .state
                                            ? _c("label", [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.selectedCustomer
                                                        .billing_address.city
                                                    ) +
                                                    ",\n                    " +
                                                    _vm._s(
                                                      _vm.selectedCustomer
                                                        .billing_address.state
                                                    ) +
                                                    "\n                    " +
                                                    _vm._s(
                                                      _vm.selectedCustomer
                                                        .billing_address.zip
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.selectedCustomer.billing_address
                                            .country
                                            ? _c("label", [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.selectedCustomer
                                                        .billing_address.country
                                                        .name
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.selectedCustomer.billing_address
                                            .phone
                                            ? _c("label", [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.selectedCustomer
                                                        .billing_address.phone
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.selectedCustomer.shipping_address
                              ? _c("div", { staticClass: "col col-6" }, [
                                  _c(
                                    "div",
                                    { staticClass: "row address-menu" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "col-sm-4 px-2 title" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("general.ship_to"))
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-sm p-0 px-2 content",
                                        },
                                        [
                                          _vm.selectedCustomer.shipping_address
                                            .name
                                            ? _c("label", [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.selectedCustomer
                                                        .shipping_address.name
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.selectedCustomer.shipping_address
                                            .address_street_1
                                            ? _c("label", [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.selectedCustomer
                                                        .shipping_address
                                                        .address_street_1
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.selectedCustomer.shipping_address
                                            .address_street_2
                                            ? _c("label", [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.selectedCustomer
                                                        .shipping_address
                                                        .address_street_2
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.selectedCustomer.shipping_address
                                            .city &&
                                          _vm.selectedCustomer.shipping_address
                                            ? _c("label", [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.selectedCustomer
                                                        .shipping_address.city
                                                    ) +
                                                    ",\n                    " +
                                                    _vm._s(
                                                      _vm.selectedCustomer
                                                        .shipping_address.state
                                                    ) +
                                                    "\n                    " +
                                                    _vm._s(
                                                      _vm.selectedCustomer
                                                        .shipping_address.zip
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.selectedCustomer.shipping_address
                                            .country
                                            ? _c(
                                                "label",
                                                { staticClass: "country" },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.selectedCustomer
                                                          .shipping_address
                                                          .country.name
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.selectedCustomer.shipping_address
                                            .phone
                                            ? _c(
                                                "label",
                                                { staticClass: "phone" },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.selectedCustomer
                                                          .shipping_address
                                                          .phone
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "customer-content mb-1" }, [
                            _c("label", { staticClass: "email" }, [
                              _vm._v(_vm._s(_vm.selectedCustomer.name)),
                            ]),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "action",
                                on: { click: _vm.editCustomer },
                              },
                              [_vm._v(_vm._s(_vm.$t("general.edit")))]
                            ),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "action",
                                on: { click: _vm.removeCustomer },
                              },
                              [_vm._v(_vm._s(_vm.$t("general.deselect")))]
                            ),
                          ]),
                        ])
                      : _c(
                          "base-popup",
                          {
                            class: [
                              "add-customer",
                              {
                                "customer-required":
                                  _vm.$v.selectedCustomer.$error,
                              },
                            ],
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "add-customer-action",
                                attrs: { slot: "activator" },
                                slot: "activator",
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "customer-icon",
                                  attrs: { icon: "user" },
                                }),
                                _vm._v(" "),
                                _c("div", [
                                  _c("label", [
                                    _vm._v(
                                      _vm._s(_vm.$t("customers.new_customer")) +
                                        "\n                "
                                    ),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(" * "),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _vm.$v.selectedCustomer.$error &&
                                  !_vm.$v.selectedCustomer.required
                                    ? _c("p", { staticClass: "text-danger" }, [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t("validation.required")
                                            ) +
                                            "\n              "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("customer-select-popup", {
                              attrs: { type: "invoice" },
                            }),
                          ],
                          1
                        ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col invoice-input" }, [
                  _c("div", { staticClass: "row mb-3" }, [
                    _c(
                      "div",
                      { staticClass: "col collapse-input" },
                      [
                        _c("label", [
                          _vm._v(
                            _vm._s(_vm.$tc("invoices.invoice", 1)) +
                              " " +
                              _vm._s(_vm.$t("invoices.date"))
                          ),
                          _c("span", { staticClass: "text-danger" }, [
                            _vm._v(" * "),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("base-date-picker", {
                          attrs: {
                            "calendar-button": true,
                            "calendar-button-icon": "calendar",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$v.newInvoice.invoice_date.$touch()
                            },
                          },
                          model: {
                            value: _vm.newInvoice.invoice_date,
                            callback: function ($$v) {
                              _vm.$set(_vm.newInvoice, "invoice_date", $$v)
                            },
                            expression: "newInvoice.invoice_date",
                          },
                        }),
                        _vm._v(" "),
                        _vm.$v.newInvoice.invoice_date.$error &&
                        !_vm.$v.newInvoice.invoice_date.required
                          ? _c("span", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("validation.required")) +
                                  "\n            "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col collapse-input" },
                      [
                        _c("label", [
                          _vm._v(_vm._s(_vm.$t("invoices.due_date"))),
                          _c("span", { staticClass: "text-danger" }, [
                            _vm._v(" * "),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("base-date-picker", {
                          attrs: {
                            invalid: _vm.$v.newInvoice.due_date.$error,
                            "calendar-button": true,
                            "calendar-button-icon": "calendar",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$v.newInvoice.due_date.$touch()
                            },
                          },
                          model: {
                            value: _vm.newInvoice.due_date,
                            callback: function ($$v) {
                              _vm.$set(_vm.newInvoice, "due_date", $$v)
                            },
                            expression: "newInvoice.due_date",
                          },
                        }),
                        _vm._v(" "),
                        _vm.$v.newInvoice.due_date.$error &&
                        !_vm.$v.newInvoice.due_date.required
                          ? _c("span", { staticClass: "text-danger mt-1" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("validation.required"))
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mt-4" }, [
                    _c(
                      "div",
                      { staticClass: "col collapse-input" },
                      [
                        _c("label", [
                          _vm._v(_vm._s(_vm.$t("invoices.invoice_number"))),
                          _c("span", { staticClass: "text-danger" }, [
                            _vm._v(" * "),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("base-prefix-input", {
                          attrs: {
                            invalid: _vm.$v.invoiceNumAttribute.$error,
                            prefix: _vm.invoicePrefix,
                            icon: "hashtag",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.invoiceNumAttribute.$touch()
                            },
                          },
                          model: {
                            value: _vm.invoiceNumAttribute,
                            callback: function ($$v) {
                              _vm.invoiceNumAttribute = $$v
                            },
                            expression: "invoiceNumAttribute",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.$v.invoiceNumAttribute.$error &&
                                  !_vm.$v.invoiceNumAttribute.required,
                                expression:
                                  "\n                $v.invoiceNumAttribute.$error &&\n                !$v.invoiceNumAttribute.required\n              ",
                              },
                            ],
                            staticClass: "text-danger mt-1",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$tc("validation.required")) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.$v.invoiceNumAttribute.numeric,
                                expression: "!$v.invoiceNumAttribute.numeric",
                              },
                            ],
                            staticClass: "text-danger mt-1",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$tc("validation.numbers_only")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col collapse-input" },
                      [
                        _c("label", [
                          _vm._v(_vm._s(_vm.$t("invoices.ref_number"))),
                        ]),
                        _vm._v(" "),
                        _c("base-input", {
                          attrs: {
                            invalid: _vm.$v.newInvoice.reference_number.$error,
                            icon: "hashtag",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.newInvoice.reference_number.$touch()
                            },
                          },
                          model: {
                            value: _vm.newInvoice.reference_number,
                            callback: function ($$v) {
                              _vm.$set(_vm.newInvoice, "reference_number", $$v)
                            },
                            expression: "newInvoice.reference_number",
                          },
                        }),
                        _vm._v(" "),
                        _vm.$v.newInvoice.reference_number.$error
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$tc("validation.ref_number_maxlength")
                                  ) +
                                  "\n            "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "table",
                { staticClass: "item-table" },
                [
                  _c("colgroup", [
                    _c("col", { staticStyle: { width: "40%" } }),
                    _vm._v(" "),
                    _c("col", { staticStyle: { width: "10%" } }),
                    _vm._v(" "),
                    _c("col", { staticStyle: { width: "15%" } }),
                    _vm._v(" "),
                    _vm.discountPerItem === "YES"
                      ? _c("col", { staticStyle: { width: "15%" } })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("col", { staticStyle: { width: "15%" } }),
                  ]),
                  _vm._v(" "),
                  _c("thead", { staticClass: "item-table-header" }, [
                    _c("tr", [
                      _c("th", { staticClass: "text-left" }, [
                        _c(
                          "span",
                          { staticClass: "column-heading item-heading" },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$tc("items.item", 2)) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("th", { staticClass: "text-right" }, [
                        _c("span", { staticClass: "column-heading" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("invoices.item.quantity")) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("th", { staticClass: "text-left" }, [
                        _c("span", { staticClass: "column-heading" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("invoices.item.price")) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.discountPerItem === "YES"
                        ? _c("th", { staticClass: "text-right" }, [
                            _c("span", { staticClass: "column-heading" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("invoices.item.discount")) +
                                  "\n            "
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("th", { staticClass: "text-right" }, [
                        _c(
                          "span",
                          { staticClass: "column-heading amount-heading" },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("invoices.item.amount")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "draggable",
                    {
                      staticClass: "item-body",
                      attrs: { tag: "tbody", handle: ".handle" },
                      model: {
                        value: _vm.newInvoice.items,
                        callback: function ($$v) {
                          _vm.$set(_vm.newInvoice, "items", $$v)
                        },
                        expression: "newInvoice.items",
                      },
                    },
                    _vm._l(_vm.newInvoice.items, function (item, index) {
                      return _c("invoice-item", {
                        key: item.id,
                        attrs: {
                          index: index,
                          "item-data": item,
                          "invoice-items": _vm.newInvoice.items,
                          currency: _vm.currency,
                          "tax-per-item": _vm.taxPerItem,
                          "discount-per-item": _vm.discountPerItem,
                        },
                        on: {
                          remove: _vm.removeItem,
                          update: _vm.updateItem,
                          itemValidate: _vm.checkItemsData,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "add-item-action", on: { click: _vm.addItem } },
                [
                  _c("font-awesome-icon", {
                    staticClass: "mr-2",
                    attrs: { icon: "shopping-basket" },
                  }),
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("invoices.add_item")) + "\n    "
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "invoice-foot" }, [
                _c(
                  "div",
                  [
                    _c("label", [_vm._v(_vm._s(_vm.$t("invoices.notes")))]),
                    _vm._v(" "),
                    _c("base-text-area", {
                      attrs: { rows: "3", cols: "50" },
                      on: {
                        input: function ($event) {
                          return _vm.$v.newInvoice.notes.$touch()
                        },
                      },
                      model: {
                        value: _vm.newInvoice.notes,
                        callback: function ($$v) {
                          _vm.$set(_vm.newInvoice, "notes", $$v)
                        },
                        expression: "newInvoice.notes",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.newInvoice.notes.$error
                      ? _c("div", [
                          !_vm.$v.newInvoice.notes.maxLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("validation.notes_maxlength"))
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("label", { staticClass: "mt-3 mb-1 d-block" }, [
                      _vm._v(
                        _vm._s(_vm.$t("invoices.invoice_template")) +
                          "\n          "
                      ),
                      _c("span", { staticClass: "text-danger" }, [
                        _vm._v(" * "),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "base-button",
                      {
                        staticClass: "btn-template",
                        attrs: {
                          type: "button",
                          icon: "pencil-alt",
                          "right-icon": "",
                        },
                        on: { click: _vm.openTemplateModal },
                      },
                      [
                        _c("span", { staticClass: "mr-4" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("invoices.template")) +
                              " " +
                              _vm._s(_vm.getTemplateId) +
                              "\n          "
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "invoice-total" },
                  [
                    _c("div", { staticClass: "section" }, [
                      _c("label", { staticClass: "invoice-label" }, [
                        _vm._v(_vm._s(_vm.$t("invoices.sub_total"))),
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "invoice-amount" }, [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$utils.formatMoney(_vm.subtotal, _vm.currency)
                            ),
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.allTaxes, function (tax) {
                      return _c(
                        "div",
                        { key: tax.tax_type_id, staticClass: "section" },
                        [
                          _c("label", { staticClass: "invoice-label" }, [
                            _vm._v(
                              _vm._s(tax.name) +
                                " - " +
                                _vm._s(tax.percent) +
                                "%\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("label", { staticClass: "invoice-amount" }, [
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$utils.formatMoney(
                                    tax.amount,
                                    _vm.currency
                                  )
                                ),
                              },
                            }),
                          ]),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _vm.discountPerItem === "NO" || _vm.discountPerItem === null
                      ? _c("div", { staticClass: "section mt-2" }, [
                          _c("label", { staticClass: "invoice-label" }, [
                            _vm._v(_vm._s(_vm.$t("invoices.discount"))),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "btn-group discount-drop-down",
                              attrs: { role: "group" },
                            },
                            [
                              _c("base-input", {
                                attrs: {
                                  invalid:
                                    _vm.$v.newInvoice.discount_val.$error,
                                  "input-class": "item-discount",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.newInvoice.discount_val.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.discount,
                                  callback: function ($$v) {
                                    _vm.discount = $$v
                                  },
                                  expression: "discount",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "v-dropdown",
                                { attrs: { "show-arrow": false } },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn item-dropdown dropdown-toggle",
                                      attrs: {
                                        slot: "activator",
                                        type: "button",
                                        "data-toggle": "dropdown",
                                        "aria-haspopup": "true",
                                        "aria-expanded": "false",
                                      },
                                      slot: "activator",
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.newInvoice.discount_type ==
                                              "fixed"
                                              ? _vm.currency.symbol
                                              : "%"
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("v-dropdown-item", [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "dropdown-item",
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.selectFixed.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.$t("general.fixed")) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("v-dropdown-item", [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "dropdown-item",
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.selectPercentage.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$t("general.percentage")
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.taxPerItem === "NO" || _vm.taxPerItem === null
                      ? _c(
                          "div",
                          _vm._l(_vm.newInvoice.taxes, function (tax, index) {
                            return _c("tax", {
                              key: tax.id,
                              attrs: {
                                index: index,
                                total: _vm.subtotalWithDiscount,
                                tax: tax,
                                taxes: _vm.newInvoice.taxes,
                                currency: _vm.currency,
                                "total-tax": _vm.totalSimpleTax,
                              },
                              on: {
                                remove: _vm.removeInvoiceTax,
                                update: _vm.updateTax,
                              },
                            })
                          }),
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.taxPerItem === "NO" || _vm.taxPerItem === null
                      ? _c(
                          "base-popup",
                          { ref: "taxModal", staticClass: "tax-selector" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "float-right",
                                attrs: { slot: "activator" },
                                slot: "activator",
                              },
                              [
                                _vm._v(
                                  "\n            + " +
                                    _vm._s(_vm.$t("invoices.add_tax")) +
                                    "\n          "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("tax-select-popup", {
                              attrs: { taxes: _vm.newInvoice.taxes },
                              on: { select: _vm.onSelectTax },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "section border-top mt-3" }, [
                      _c("label", { staticClass: "invoice-label" }, [
                        _vm._v(
                          _vm._s(_vm.$t("invoices.total")) +
                            " " +
                            _vm._s(_vm.$t("invoices.amount")) +
                            ":"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "invoice-amount total" }, [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$utils.formatMoney(_vm.total, _vm.currency)
                            ),
                          },
                        }),
                      ]),
                    ]),
                  ],
                  2
                ),
              ]),
            ]
          )
        : _c("base-loader"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }