<template>
  <div class="main-content">
    <div class="page-header mt-4">
      <h3 class="page-title">Paquetes</h3>
      <div class="page-actions row">
        <div class="col-xs-2 mr-2">
          <base-button @click="openAddPaquetesModal()" icon="plus" color="theme">
            Declaraciones
          </base-button>
        </div>
        <div class="col-xs-2 mr-2">
          <base-button @click="openAddPaqueteFoliosModal()" icon="plus" color="theme">
            Folios
          </base-button>
        </div>
        <div class="col-xs-2 mr-2">
          <base-button @click="openAddPaqueteDeclaraFoliosModal()" icon="plus" color="theme">
            Declaraciones/Folios
          </base-button>
        </div>
      </div>
    </div>
    <table-component
      ref="table"
      :data="paquetes"
      :filter-no-results="$t('general.filterNoResults')"
      :is-clickable="true"
      :show-filter="false"
      table-class="table"
    >
      <table-column label="Paquete" show="nombre" />
      <table-column label="Precio" show="precio" />
      <table-column label="Periodicidad" show="periodicidad_nombre" />
      <table-column label="Cantidad Folios" show="cantidad_folios" />
      <table-column label="Régimen" show="regimen" />
      <table-column>
        <template slot-scope="row">
        <div class="remove-icon-wrapper">
          <font-awesome-icon
            class="remove-icon"
            icon="trash-alt"
            @click="removeItem(row.id)"
            style="color:red"
          />
        </div>
        </template>
      </table-column>
    </table-component>
    
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Pagination from '../../components/base/base-table/components/Pagination'

export default {
  components: {
    Pagination,
  },

  data: () => ({
    paquetes: [],
  }),

  mounted() {
    this.loadPaquetes()
    window.hub.$on('concept_categories.category.created', () => {
      this.loadPaquetes()
    })
  },

  methods: {
    ...mapActions('modal', ['openModal']),
    ...mapActions('payment', ['fetchPaquetes', 'eliminarPaquete']),

    loadPaquetes() {
      this.fetchPaquetes().then((response) => {
        this.paquetes = response.data.data
      })
    },
    openAddPaquetesModal() {
      this.openModal({
        title: 'Registrar paquete Declaraciones',
        componentName: 'AddPaqueteModal',
      })
    },
    openAddPaqueteFoliosModal() {
      this.openModal({
        title: 'Registrar paquete Folios',
        componentName: 'AddPaqueteFoliosModal',
      })
    },
    openAddPaqueteDeclaraFoliosModal() {
      this.openModal({
        title: 'Registrar paquete Declaraciones/Folios',
        componentName: 'AddPaqueteDeclaraFoliosModal',
      })
    },
    removeItem(id) {
      window.swal({
        title: this.$t('general.are_you_sure'),
        text: "Al eliminar este paquete, el cliente ya no lo podrá visualizar.",
        icon: '/assets/icon/trash-solid.svg',
        buttons: true,
        dangerMode: true
      }).then(async (value) => {
        if (value) {
          await this.eliminarPaquete(id)
          this.loadPaquetes()
        }
      })  
      
    },
  },
}
</script>
