var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-content" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "card mt-3 pt-4 pb-4" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-md-6 offset-md-3" }, [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { attrs: { for: "nombre" } }, [_vm._v("Nombre")]),
                  _vm._v(" "),
                  _c("base-input", {
                    ref: "name",
                    attrs: { id: "nombre", type: "text" },
                    model: {
                      value: _vm.name,
                      callback: function ($$v) {
                        _vm.name = $$v
                      },
                      expression: "name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "form-group text-center" }, [
                _c(
                  "button",
                  { staticClass: "btn btn-primary", attrs: { type: "submit" } },
                  [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("general.save")) +
                        "\n            "
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page-header mt-4" }, [
      _c("h3", { staticClass: "page-title" }, [_vm._v("Crear cuenta")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }