<template>
  <div class="expenses main-content">
    <div class="page-header mt-5">
      <h3 class="page-title">
        {{ $t('expenses.title') }} | {{ filters.month['name'] }},
        {{ filters.year }}
      </h3>
      <!-- <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link
            slot="item-title"
            to="dashboard">
            {{ $t('general.home') }}
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link
            slot="item-title"
            to="#">
            {{ $tc('expenses.expense',2) }}
          </router-link>
        </li>
      </ol> -->
      <div class="page-actions row">
        <!-- <div class="col-xs-2 mr-4">
              <base-button
                :loading="isLoading"
                icon="upload"
                color="theme"
                @click="openUploadModal"
                outline
              >
                Importar Gastos
              </base-button>
        </div> -->
        <div class="col-xs-2 mr-4">
          <base-button
            v-show="false"
            :outline="true"
            :icon="filterIcon"
            size="large"
            right-icon
            color="theme"
            @click="toggleFilter"
          >
            {{ $t('general.filter') }}
          </base-button>
        </div>
        <router-link slot="item-title" class="col-xs-2" to="expenses/create">
          <base-button size="large" icon="plus" color="theme">
            {{ $t('expenses.add_expense') }}
          </base-button>
        </router-link>
        <router-link class="co-xs-2" :to="{ name: 'expenses.quick-assign' }">
          <base-button size="large" icon="list-ol" color="theme">
            {{ $t('expenses.list_invoices') }}
          </base-button>
        </router-link>
      </div>
    </div>

    <!-- <transition name="fade">
      <div v-show="showFilters" class="filter-section">
        <div class="row">
           <div class="col-md-3">
            <label>{{ $t('expenses.customer') }}</label>
            <base-select
              v-model="filters.user"
              :options="customers"
              :searchable="true"
              :show-labels="false"
              :placeholder="$t('expenses.select_a_customer')"
              label="name"
              @click="filter = ! filter"
            />
          </div>
          <div class="col-md-3">
            <label>{{ $t('RFC') }}</label>
            <base-select
              v-model="filters.user"
              :options="customers"
              :searchable="true"
              :show-labels="false"
              :placeholder="$t('RFC')"
              label="rfc"
              @click="filter = ! filter"
            />
          </div> 
                    <div class="col-md-3">
            <label>{{ $t('expenses.category') }}</label>
            <base-select
              v-model="filters.typeOption"
              :options="typeOptions"
              :searchable="true"
              :show-labels="false"
              :placeholder="$t('expenses.expense_type')"
              label="name"
              @click="filter = ! filter"
            />
          </div> 
          <div class="col-md-3">
            <label>{{ $t('invoices.month') }}</label>
            <base-select
              v-model="filters.month"
              :options="months"
              :searchable="true"
              :show-labels="false"
              :placeholder="$t('invoices.months_plain')"
              label="name"
              @click="filter = !filter"
            />
          </div>
          <div class="col-md-3">
            <label>{{ $t('invoices.year') }}</label>
            <base-input v-model="filters.year" type="number" name="year" />
          </div>
                   <div class="col-md-3">
            <label>{{ $t('expenses.total') }}</label>
            <base-input
              v-model="filters.total"
              type="text"
              name="total"
              autocomplete="on"
            />
          </div>
        </div>
                 <label class="clear-filter" @click="clearFilter">{{ $t('general.clear_all') }}</label>
 
      </div>
    </transition> -->

    <div
      v-cloak
      v-show="showEmptyScreen"
      class="col-xs-1 no-data-info"
      align="center"
    >
      <observatory-icon class="mt-5 mb-4" />
      <div class="row" align="center">
        <label class="col title">{{ $t('expenses.no_expenses') }}</label>
      </div>
      <div class="row">
        <label class="description col mt-1" align="center">{{
          $t('expenses.list_of_expenses')
        }}</label>
      </div>
      <div class="row">
        <div class="col">
          <base-button
            :outline="true"
            color="theme"
            class="mt-3"
            size="large"
            @click="$router.push('expenses/create')"
          >
            {{ $t('expenses.add_new_expense') }}
          </base-button>
        </div>
      </div>
    </div>

    <div v-show="!showEmptyScreen" class="table-container">
      <div class="row">
        <div class="col col-md-8">
          <div class="row">
            <div class="col col-md-6">
              <div class="text-right">
                <strong>{{ $t('expenses.sub_total') }}</strong>
                <money-text :amount="Number(expenseTotals.subtotal)" />
              </div>
              <div class="text-right">
                <strong>{{ $t('expenses.retention_isr') }}</strong>
                <money-text :amount="Number(expenseTotals.retention_isr)" />
              </div>
            </div>
            <div class="col col-md-6">
              <div class="text-right">
                <strong>{{ $t('expenses.iva') }}</strong>
                <money-text :amount="Number(expenseTotals.iva)" />
              </div>
              <div class="text-right">
                <strong>{{ $t('expenses.retention_iva') }}</strong>
                <money-text :amount="Number(expenseTotals.retention_iva)" />
              </div>
              <div class="text-right">
                <strong>{{ $t('expenses.total') }}</strong>
                <money-text :amount="Number(expenseTotals.total)" />
              </div>
            </div>
          </div>
        </div>
        <div class="col col-md-4">
          <div class="form-group">
            <label>
              {{ $t('general.name_or_rfc') }}
            </label>
            <base-input v-model="filters.nameOrRFC" type="text" />
          </div>
        </div>
      </div>

      <div class="table-actions">
        <p class="table-stats">
          {{ $t('general.showing') }}: <b>{{ expenses.length }}</b>
          {{ $t('general.of') }} <b>{{ totalExpenses }}</b>
        </p>
        <transition name="fade">
          <v-dropdown
            v-if="selectedExpenses.length"
            :show-arrow="false"
            theme-light
            class="action mr-5"
          >
            <span
              slot="activator"
              href="#"
              class="table-actions-button dropdown-toggle"
            >
              {{ $t('general.actions') }}
            </span>
            <v-dropdown-item>
              <div class="dropdown-item" @click="removeMultipleExpenses">
                <font-awesome-icon
                  :icon="['fas', 'trash']"
                  class="dropdown-item-icon"
                />
                {{ $t('general.delete') }}
              </div>
            </v-dropdown-item>
          </v-dropdown>
        </transition>
      </div>

      <table-component
        ref="table"
        :data="fetchData"
        :filter-no-results="$t('general.filterNoResults')"
        :is-clickable="true"
        :show-filter="false"
        table-class="table"
        @columnClick="rowClick"
      >
        <table-column
          :sortable="false"
          :filterable="false"
          cell-class="no-click"
        >
          <template slot-scope="row">
            <div v-if="row.cfdi_type == 'N'" class="">
              <img class="nomina-icon" src="/images/nomina_icon.svg" />
            </div>
          </template>
        </table-column>
        <table-column :label="$t('expenses.date')" show="date" />
        <table-column
          :label="$t('expenses.expense_number')"
          show="invoice_number"
        />
        <table-column :label="$t('expenses.provider')" show="issuing_name">
          <template slot-scope="row">
            <span>{{ $t('expenses.provider') }}</span>
            <sub-string
              v-if="row.issuing_rfc == currentCustomer.rfc"
              :string="row.receiver_name"
            />
            <sub-string v-else :string="row.issuing_name" />
          </template>
        </table-column>
        <table-column :label="$t('expenses.provider_rfc')" show="issuing_rfc">
          <template slot-scope="row">
            <span>{{ $t('expenses.provider_rfc') }}</span>
            <sub-string
              v-if="row.issuing_rfc == currentCustomer.rfc"
              :string="row.receiver_rfc"
            />
            <sub-string v-else :string="row.issuing_rfc" />
          </template>
        </table-column>
        <table-column :label="$t('expenses.type')" show="type">
          <template slot-scope="row">
            <span>{{ $t('expenses.type') }}</span>
           {{ row.type }} - {{ row.cfdi_type }}
          </template>
        </table-column>
        <table-column :label="$t('expenses.sub_total')" show="sub_total">
          <template slot-scope="row">
            <span>{{ $t('expenses.sub_total') }}</span>
            <money-text :amount="Number(row.sub_total)" />
          </template>
        </table-column>
        <table-column :label="$t('payments.iva')" show="iva">
          <template slot-scope="row">
            <span>{{ $t('payments.iva') }}</span>
            <money-text :amount="Number(row.iva)" />
          </template>
        </table-column>
        <table-column :label="$t('expenses.total')" show="amount">
          <template slot-scope="row">
            <span>{{ $t('expenses.total') }}</span>
            <money-text :amount="Number(row.amount)" />
          </template>
        </table-column>
        <table-column
          :sortable="false"
          :label="$t('invoices.in_tax_return_abbr')"
          show="in_tax_return"
        >
          <template slot-scope="row">
            <span>{{ $t('invoices.in_tax_return_abbr') }}</span>
            <icon-in-tax-return
              :status="row.in_tax_return"
              module="in_tax_return"
            />
          </template>
        </table-column>
        <table-column
          :sortable="false"
          :label="$t('invoices.account_statement_abbr')"
          show="account_statement"
        >
          <template slot-scope="row">
            <span>{{ $t('invoices.account_statement_abbr') }}</span>
            <icon-in-tax-return
              :status="row.account_statement"
              module="account_statement"
            />
          </template>
        </table-column>
        <table-column
          :sortable="false"
          :filterable="false"
          cell-class="action-dropdown no-click"
        >
          <!-- Dropdown Pago -->
          <template v-if="row.type == 'Pago'" slot-scope="row">
            <span>{{ $t('expenses.action') }}</span>
            <v-dropdown>
              <a slot="activator" href="#">
                <dot-icon />
              </a>

              <!-- <v-dropdown-item>
                <router-link :to="{path: `expenses/${row.id}/edit`}" class="dropdown-item">
                  <font-awesome-icon :icon="['fas', 'pencil-alt']" class="dropdown-item-icon" />
                  {{ $t('general.edit') }}
                </router-link>
              </v-dropdown-item> -->

              <v-dropdown-item>
                <div class="dropdown-item" @click="removeExpense(row.id)">
                  <font-awesome-icon
                    :icon="['fas', 'trash']"
                    class="dropdown-item-icon"
                  />
                  {{ $t('general.delete') }}
                </div>
              </v-dropdown-item>
            </v-dropdown>
          </template>
        </table-column>
      </table-component>
    </div>
  </div>
</template>

<script>
import { SweetModal, SweetModalTab } from 'sweet-modal-vue'
import { mapActions, mapGetters } from 'vuex'
import ObservatoryIcon from '../../components/icon/ObservatoryIcon'
import MultiSelect from 'vue-multiselect'
import IconInTaxReturn from '../components/IconInTaxReturn'

export default {
  components: {
    MultiSelect,
    'observatory-icon': ObservatoryIcon,
    SweetModal: SweetModal,
    SweetModalTab: SweetModalTab,
    IconInTaxReturn,
  },
  data() {
    return {
      showFilters: true,
      filtersApplied: false,
      isRequestOngoing: true,
      isLoading: false,
      customers: [],
      typeOptions: [],
      filters: {
        category: null,
        from_date: '',
        to_date: '',
        user: '',
        month: '',
        year: '',
        typeOption: '',
        total: '',
        nameOrRFC: '',
      },
      expenseTotals: {
        total: 0,
        retention_iva: 0,
        retention_isr: 0,
        iva: 0,
      },
    }
  },
  computed: {
    showEmptyScreen() {
      return (
        !this.totalExpenses && !this.isRequestOngoing && !this.filtersApplied
      )
    },
    filterIcon() {
      return this.showFilters ? 'times' : 'filter'
    },
    ...mapGetters('category', ['categories']),
    ...mapGetters('expense', [
      'selectedExpenses',
      'totalExpenses',
      'expenses',
      'selectAllField',
    ]),
    ...mapGetters('currency', ['defaultCurrency']),
    ...mapGetters('CurrentCustomer', ['currentCustomer']),
    ...mapGetters('calendar', ['months', 'defaultMonth', 'defaultYear']),
    selectField: {
      get: function () {
        return this.selectedExpenses
      },
      set: function (val) {
        this.selectExpense(val)
      },
    },
    selectAllFieldStatus: {
      get: function () {
        return this.selectAllField
      },
      set: function (val) {
        this.setSelectAllState(val)
      },
    },
  },
  watch: {
    filters: {
      handler: 'setFilters',
      deep: true,
    },
  },
  mounted() {
    if (this.currentCustomer) {
      this.filters.user = this.currentCustomer
      this.fetchTotals()
    }
  },
  destroyed() {
    if (this.selectAllField) {
      this.selectAllExpenses()
    }
  },
  created() {
    let sessionFilterMonth
    if (sessionStorage.getItem('filtersExpensesMonth')) {
      sessionFilterMonth = JSON.parse(
        sessionStorage.getItem('filtersExpensesMonth')
      )
    }
    let sessionFilterYear = sessionStorage.getItem('filtersPaymentsYear')

    if (this.defaultMonth && this.defaultYear) {
      this.filters.month = this.defaultMonth
      this.filters.year = this.defaultYear
      sessionStorage.setItem(
        'filtersExpensesMonth',
        JSON.stringify(this.filters.month)
      )
      sessionStorage.setItem('filtersPaymentsYear', this.filters.year)
    } else {
      this.filters.month = sessionFilterMonth
      this.filters.year = sessionFilterYear
    }

    this.fetchCategories()
    window.hub.$on('importExpense', this.refreshTable)
    this.typeOptions = [
      { id: 'factura', name: 'Factura' },
      { id: 'gasto', name: 'Gasto' },
    ]
  },
  methods: {
    ...mapActions('expense', [
      'fetchExpenses',
      'selectExpense',
      'deleteExpense',
      'deleteMultipleExpenses',
      'selectAllExpenses',
      'setSelectAllState',
      'fetchListTotals',
    ]),
    ...mapActions('modal', ['openModal']),
    ...mapActions('category', ['fetchCategories']),
    ...mapActions('invoice', ['fetchChangeAllInTaxReturn']),
    async fetchData({ page, sort }) {
      if (!this.currentCustomer) {
        this.$router.push('/general/customers')
        return {
          data: [],
          pagination: {
            totalPages: 1,
            currentPage: 1,
            count: 0,
          },
        }
      }

      let data = {
        customer_id: this.filters.user
          ? this.filters.user.id
          : this.currentCustomer.id,
        expense_category_id:
          this.filters.category !== null ? this.filters.category.id : '',
        total: this.filters.total !== '' ? this.filters.total : '',
        orderByField: sort.fieldName || 'date',
        orderBy: sort.order || 'asc',
        month: this.filters.month ? this.filters.month.id : null,
        year: this.filters.year,
        typeOption: this.filters.typeOption ? this.filters.typeOption.id : null,
        name_or_rfc: this.filters.nameOrRFC,
        page,
      }

      this.isRequestOngoing = true
      let response = await this.fetchExpenses(data)
      this.customers = response.data.customers
      this.isRequestOngoing = false

      return {
        data: response.data.expenses.data,
        pagination: {
          totalPages: response.data.expenses.last_page,
          currentPage: page,
          count: response.data.expenses.count,
        },
      }
    },
    fetchTotals() {
      let data = {
        customer_id: this.filters.user
          ? this.filters.user.id
          : this.currentCustomer.id,
        expense_category_id:
          this.filters.category !== null ? this.filters.category.id : '',
        total: this.filters.total !== '' ? this.filters.total : '',
        month: this.filters.month ? this.filters.month.id : null,
        year: this.filters.year,
        typeOption: this.filters.typeOption ? this.filters.typeOption.id : null,
        name_or_rfc: this.filters.nameOrRFC,
      }
      this.fetchListTotals(data).then((response) => {
        this.expenseTotals = response.data
      })
    },
    refreshTable() {
      this.$refs.table.refresh()
    },
    setFilters() {
      if (this.showFilters) {
        sessionStorage.setItem(
          'filtersExpensesMonth',
          JSON.stringify(this.filters.month)
        )
        sessionStorage.setItem('filtersExpensesYear', this.filters.year)
      }
      this.filtersApplied = true
      this.refreshTable()
      this.fetchTotals()
    },
    clearFilter() {
      this.filters = {
        category: null,
        from_date: '',
        to_date: '',
        user: null,
        month: this.defaultMonth,
        year: this.defaultYear,
        typeOption: '',
        total: '',
      }

      this.$nextTick(() => {
        this.filtersApplied = false
      })
    },
    toggleFilter() {
      if (this.showFilters && this.filtersApplied) {
        this.clearFilter()
        this.refreshTable()
        this.fetchTotals()
      }

      this.showFilters = !this.showFilters
    },
    async removeExpense(id) {
      window
        .swal({
          title: this.$t('general.are_you_sure'),
          text: this.$tc('expenses.confirm_delete'),
          icon: '/assets/icon/trash-solid.svg',
          buttons: true,
          dangerMode: true,
        })
        .then(async (willDelete) => {
          if (willDelete) {
            let res = await this.deleteExpense(id)
            if (res.data.success) {
              window.toastr['success'](this.$tc('expenses.deleted_message', 1))
              this.$refs.table.refresh()
              return true
            } else if (res.data.error) {
              window.toastr['error'](res.data.message)
            }
          }
        })
    },
    async removeMultipleExpenses() {
      window
        .swal({
          title: this.$t('general.are_you_sure'),
          text: this.$tc('expenses.confirm_delete', 2),
          icon: '/assets/icon/trash-solid.svg',
          buttons: true,
          dangerMode: true,
        })
        .then(async (willDelete) => {
          if (willDelete) {
            let request = await this.deleteMultipleExpenses()
            if (request.data.success) {
              window.toastr['success'](this.$tc('expenses.deleted_message', 2))
              this.$refs.table.refresh()
            } else if (request.data.error) {
              window.toastr['error'](request.data.message)
            }
          }
        })
    },
    openUploadModal() {
      this.openModal({
        title: 'Seleccionar Archivo Excel',
        componentName: 'UploadModal',
      })
      //this.$refs.table.refresh()
    },
    rowClick(rowData) {
      if (rowData.column == 'in_tax_return') {
        this.confirmChangeAllInTaxReturn(rowData.row)
        return
      }
      this.$router.push({
        name: 'invoices.view',
        params: {
          id: rowData.row.id,
        },
      })
    },

    confirmChangeAllInTaxReturn(row) {
      window
        .swal({
          title: this.$t('invoices.tax_return.change_all'),
          icon: 'warning',
          buttons: {
            defeat: this.$t('invoices.tax_return.continue'),
            cancel: this.$t('invoices.tax_return.cancel'),
          },
          dangerMode: true,
        })
        .then((confirmed) => {
          if (confirmed) {
            this.changeAllInTaxReturnStatus(row)
          }
        })
    },

    changeAllInTaxReturnStatus(row) {
      const data = {
        invoice_id: row.id,
        in_tax_return: row.in_tax_return != 'COMPLETE' ? true : false,
      }
      this.fetchChangeAllInTaxReturn(data)
        .then((response) => {
          this.refreshTable()
        })
        .catch((err) => {
          window.toastr['error'](
            this.$t('invoices.errors.cant_change_all_tax_return')
          )
        })
    },
  },
}
</script>
