export const StatusAssigned = {
  NOINICIADAS: 'NOINICIADAS',
  ENPROCESO: 'ENPROCESO',
  COMENTARIOSPARACLIENTE: 'COMENTARIOSPARACLIENTE',
  COMENTARIOSDECLIENTE: 'COMENTARIOSDECLIENTE',
  ENVIADO: 'ENVIADO',
  ACEPTADA: 'ACEPTADA',
  RECHAZADO: 'RECHAZADO',
  PRESENTADA: 'PRESENTADA',
  PAGADA: 'PAGADA',
  MANUAL: 'MANUAL',
}
