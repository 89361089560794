export const SET_CATEGORIES = 'SET_CATEGORIES'
export const ADD_CATEGORIES_SELECT = 'ADD_CATEGORIES_SELECT'
export const ADD_CATEGORIES_SELECT_ALL = 'ADD_CATEGORIES_SELECT_ALL'

export const REMOVE_CATEGORIES_SELECT = 'REMOVE_CATEGORIES_SELECT'

export const CLEAR_CATEGORIES_SELECT = 'CLEAR_CATEGORIES_SELECT'

export const SET_SUBCATEGORIES = 'SET_SUBCATEGORIES'
export const SET_SUBCATEGORIES_USER = 'SET_SUBCATEGORIES_USER'

export const SET_SUB_CATEGORY_MODAL_DATA = 'SET_SUB_CATEGORY_MODAL_DATA'
export const SET_MODAL_CHANGE_CATEGORY_DATA = 'SET_MODAL_CHANGE_CATEGORY_DATA'
