const catalogs = {
  isr_concepts: [
    {
      action: 'add',
      description: 'Parte actualizada',
      order: 0,
    },
    {
      action: 'add',
      description: 'Recargos',
      order: 1,
    },
    {
      action: 'add',
      description: 'Multa por corrección',
      order: 2,
    },
    // {
    //   action: 'add',
    //   description: 'Total de contribuciones',
    //   order: 3,
    // },
    {
      action: 'subtract',
      description: 'Crédito al salario',
      order: 4,
    },
    {
      action: 'subtract',
      description: 'Subsidio para el empleo',
      order: 5,
    },
    {
      action: 'subtract',
      description: 'Impuesto a los depósitos en efectivo acreditable',
      order: 6,
    },
    {
      action: 'subtract',
      description: 'Compensaciones',
      order: 7,
    },
    {
      action: 'subtract',
      description: 'Crédito IEPS diésel sector primario y minero',
      order: 8,
    },
    {
      action: 'subtract',
      description: 'Diésel automotriz para transporte',
      order: 9,
    },
    {
      action: 'subtract',
      description: 'Uso de infraestructura carretera de cuota',
      order: 10,
    },
    {
      action: 'subtract',
      description: 'Otros estímulos',
      order: 11,
    },
    {
      action: 'subtract',
      description: 'Acreditamiento sorteo del buen fin',
      order: 12,
    },
    {
      action: 'subtract',
      description: 'Diésel marino',
      order: 13,
    },
    {
      action: 'subtract',
      description: 'Total de aplicaciones',
      order: 14,
    },
    {
      // Implica fecha
      action: 'subtract',
      description: 'Monto pagado con anterioridad',
      order: 15,
      date: true,
    },
    {
      action: 'subtract',
      description: 'Importe Pagado en las últimas 48 horas',
      order: 16,
    },
  ],
}

export default catalogs
