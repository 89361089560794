var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "category-button", on: { click: _vm.showModal } },
    [_c("sub-string", { attrs: { string: _vm.categoryString, length: 35 } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }