var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "p-3" }, [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.saveConceptCategory()
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", [
              _vm._v(_vm._s(_vm.$t("concept_categories.category_name"))),
            ]),
            _vm._v(" "),
            _c("base-input", {
              on: {
                change: function ($event) {
                  return _vm.$v.form.$touch()
                },
              },
              model: {
                value: _vm.form.name,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "name", $$v)
                },
                expression: "form.name",
              },
            }),
            _vm._v(" "),
            _vm.$v.form.name.$error
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t(
                          "concept_categories.validations.category_name_required"
                        )
                      ) +
                      "\n      "
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "text-right" }, [
          _c(
            "button",
            { staticClass: "btn btn-primary", attrs: { type: "submit" } },
            [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("invoices.save")) + "\n      "
              ),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }