export const unitCodeSelect = [
    { tipo: 1, subtipo: 89, id: 'P41', description: 'Década (logaritmica) (Una escala logarítmica es una escala de medida que utiliza el logaritmo de una cantidad física en lugar de la propia cantidad.)'},
    { tipo: 1, subtipo: 96, id: 'A60', description: 'Erg por centímetro cúbico'},
    { tipo: 1, subtipo: 180, id: 'A50', description: 'Dina segundo por centímetro cúbico'},
    { tipo: 1, subtipo: 151, id: 'P42', description: 'Pascal por segundo cuadrado (Unidad del conjunto como un producto de la potencia de derivado unidad pascal SI con exponente 2 y la unidad base segunda SI.) '},
    { tipo: 1, subtipo: 194, id: 'A52', description: 'Dina segundo por centímetro a la quinta potencia '},
    { tipo: 1, subtipo: 194, id: 'M32', description: 'Segundos pascal por litro '},
    { tipo: 1, subtipo: 194, id: 'C66', description: 'Segundos pascal por metro cúbico'},
    { tipo: 1, subtipo: 195, id: 'C67', description: 'Segundos pascal por metro'},
    { tipo: 1, subtipo: 196, id: 'A51', description: 'Dina segundo por centímetro '},
    { tipo: 1, subtipo: 196, id: 'C58', description: 'Segundos newton por metro'},
    { tipo: 1, subtipo: 206, id: 'A64', description: 'Erg por segundo centímetro cuadrado'},
    { tipo: 1, subtipo: 206, id: 'D85', description: 'Microwatt por metro cuadrado (Es equivalente a una millonésima parte de un vatio.)'},
    { tipo: 1, subtipo: 206, id: 'C32', description: 'Miliwatt por metro cuadrado (Es equivalente a una milésima de vatio.)'},
    { tipo: 1, subtipo: 206, id: 'C76', description: 'Picowatt por metro cuadrado (Es igual a la trillonésima parte de un vatio (10-12).)'},
    { tipo: 1, subtipo: 209, id: 'C59', description: 'Octava (Una unidad utilizada en la música para describir la relación de la frecuencia entre las notas.)'},
    { tipo: 1, subtipo: 245, id: 'P43', description: 'Bel por metro (Una unidad Bel dividida por la unidad básica del sistema internacional (metros).)'},
    { tipo: 1, subtipo: 245, id: 'H51', description: 'Decibel per kilometro'},
    { tipo: 1, subtipo: 245, id: 'H52', description: 'Decibel per metro'},
    { tipo: 1, subtipo: 247, id: 'C69', description: 'Phon (Unidad de volumen del sonido subjetivo. Un sonido tiene p phons de sonoridad si se parece al oyente a ser iguales en intensidad, con el sonido de un tono puro de frecuencia 1 kilohertz y de la fuerza p decibelios.)'},
    { tipo: 1, subtipo: 322, id: 'D9', description: 'Dina por centímetro cuadrado'},
    { tipo: 1, subtipo: 416, id: 'D15', description: 'Sone (Una unidad de volumen del sonido subjetivo. Un sone es la intensidad de un tono puro de la frecuencia y la fuerza de un kilohertz 40 decibelios.)'},
    { tipo: 2, subtipo: 14, id: 'J83', description: 'Clo (Clo es una unidad de medida empleada para el índice de indumento.)'},
    { tipo: 2, subtipo: 14, id: 'J22', description: 'Grado fahrenheit hora pie cuadrado por unidad térmica británica (tabla internacional).'},
    { tipo: 2, subtipo: 14, id: 'J19', description: 'Grado fahrenheit hora pie cuadrado por unidad térmica británica (termoquímico).'},
    { tipo: 2, subtipo: 14, id: 'L14', description: 'Metro cuadrado hora grado celsius por kilocaloría (tabla internacional)'},
    { tipo: 2, subtipo: 14, id: 'D19', description: 'Metro cuadrado kelvin por watt.'},
    { tipo: 2, subtipo: 23, id: 'A1', description: '15 ° C calorías'},
    { tipo: 2, subtipo: 23, id: 'J75', description: 'Caloría (Unidad de energía térmica, de símbolo cal, que equivale a la cantidad de calor necesaria para elevar 1 grado centígrado la temperatura de 1 gramo de agua.)'},
    { tipo: 2, subtipo: 23, id: 'N69', description: 'Caloría (20 °c) (Unidad para la cantidad de calor, que se requiere para que 1g de agua libre de aire a una presión constante de 101.325 kPa, se caliente a la presión de la atmósfera estándar a nivel del mar, de 19,5 ° C a 20,5 ° C.)'},
    { tipo: 2, subtipo: 23, id: 'D70', description: 'Calorías (tabla internacional)'},
    { tipo: 2, subtipo: 23, id: 'K51', description: 'Kilocaloría (significado)'},
    { tipo: 2, subtipo: 23, id: 'E14', description: 'Kilocaloría (tabla internacional)'},
    { tipo: 2, subtipo: 23, id: 'K53', description: 'Kilocaloría (termoquímico)'},
    { tipo: 2, subtipo: 23, id: 'N70', description: 'Quad (Unidad de energía de acuerdo al sistema imperial de unidades)'},
    { tipo: 2, subtipo: 23, id: 'N71', description: 'Termia (energía comercial) (Unidad de energía calorífica en uso comercial, dentro de Estados Unidos definida como: 1 thm (EC) = 100 000 BtuIT.)'},
    { tipo: 2, subtipo: 23, id: 'N72', description: 'Termia (UEA) (Unidad de energía térmica en uso comercial.)'},
    { tipo: 2, subtipo: 23, id: 'N66', description: 'Unidad térmica británica (39 °f) (Unidad de energía térmica según el sistema Imperial de unidades en una temperatura de referencia de 39 ° F.)'},
    { tipo: 2, subtipo: 23, id: 'N67', description: 'Unidad térmica británica (59 °f) (Unidad de energía térmica según el sistema Imperial de unidades en una temperatura de referencia de 59 ° F.)'},
    { tipo: 2, subtipo: 23, id: 'N68', description: 'Unidad térmica británica (60 °f) (Unidad de energía térmica según el sistema Imperial de unidades en una temperatura de referencia de 60 ° F.)'},
    { tipo: 2, subtipo: 23, id: 'J39', description: 'Unidad térmica británica (significado)'},
    { tipo: 2, subtipo: 23, id: 'BTU', description: 'Unidad térmica británica (tabla internacional)'},
    { tipo: 2, subtipo: 23, id: 'J55', description: 'Watt segundo'},
    { tipo: 2, subtipo: 29, id: 'J76', description: 'Caloría (tabla internacional) por gramo gadro celsius'},
    { tipo: 2, subtipo: 29, id: 'J79', description: 'Caloría (termoquímico) por gramo gadro celsius'},
    { tipo: 2, subtipo: 29, id: 'JE', description: 'Joule por kelvin (Es el aumento de entropía de un sistema que recibe una cantidad de calor de 1 joule, a la temperatura termodinámica constante de 1 kelvin, siempre que en el sistema no tenga lugar ninguna transformación irreversible.)'},
    { tipo: 2, subtipo: 29, id: 'N65', description: 'Kilocaloría (tabla internacional) por gramo kelvin (Unidad de la capacidad calorífica relacionada con la masa como cociente 1000 veces de la caloría (tabla internacional) dividido por el producto de 0,001 veces de las unidades de base kilogramo y kelvin.)'},
    { tipo: 2, subtipo: 29, id: 'B41', description: 'Kilojoule por kelvin'},
    { tipo: 2, subtipo: 29, id: 'N60', description: 'Unidad térmica británica (tabla internacional) por grado fahrenheit (Unidad de la capacidad calorífica según el sistema Imperial de unidades.)'},
    { tipo: 2, subtipo: 29, id: 'N62', description: 'Unidad térmica británica (tabla internacional) por grado rankine (Unidad de la capacidad calorífica según el sistema Imperial de unidades.)'},
    { tipo: 2, subtipo: 29, id: 'J43', description: 'Unidad térmica británica (tabla internacional) por libra grado fahrenheit'},
    { tipo: 2, subtipo: 29, id: 'N61', description: 'Unidad térmica británica (termoquímico) por grado fahrenheit (Unidad de la capacidad calorífica según el sistema Imperial de unidades.)'},
    { tipo: 2, subtipo: 29, id: 'N63', description: 'Unidad térmica británica (termoquímico) por grado rankine (Unidad de la capacidad calorífica según el sistema Imperial de unidades.)'},
    { tipo: 2, subtipo: 29, id: 'J50', description: 'Unidad térmica británica (termoquímico) por libra grado fahrenheit'},
    { tipo: 2, subtipo: 29, id: 'N64', description: 'Unidad térmica británica (termoquímico) por libra grado rankine (Unidad de la capacidad calorífica (unidad térmica británica según la tabla internacional según el grado de Rankine) de acuerdo con el sistema Imperial de unidades dividido por la unidad avoirdupois libra según el sistema de avoirdupois de unidades.)'},
    { tipo: 2, subtipo: 30, id: 'D76', description: 'Calorías (tabla internacional) por gramo de kelvin'},
    { tipo: 2, subtipo: 30, id: 'D37', description: 'Calorías (termoquímicas) por gramo de kelvin'},
    { tipo: 2, subtipo: 30, id: 'B11', description: 'Joule por kilogramo kelvin (Es la capacidad térmica másica de un cuerpo homogéneo de una masa de 1 kilogramo, en el que el aporte de una cantidad de calor de un joule, produce una elevación de temperatura termodinámica de 1 kelvin. )'},
    { tipo: 2, subtipo: 30, id: 'B43', description: 'Kilojoule por kilogramo kelvin'},
    { tipo: 2, subtipo: 30, id: 'A21', description: 'Unidad térmica británica (tabla internacional) por libra grado rankine'},
    { tipo: 2, subtipo: 53, id: 'C91', description: 'Recíprocidad de kelvin ó kelvin a la potencia menos 1'},
    { tipo: 2, subtipo: 53, id: 'M20', description: 'Recíproco de megakelvin ó megakelvin a la potencia menos 1'},
    { tipo: 2, subtipo: 54, id: 'F81', description: 'Bar por kelvin'},
    { tipo: 2, subtipo: 54, id: 'C64', description: 'Pascal por kelvin'},
    { tipo: 2, subtipo: 57, id: 'D72', description: 'Calorías (tabla internacional) por segundo centímetro cuadrado kelvin'},
    { tipo: 2, subtipo: 57, id: 'D39', description: 'Calorías (termoquímicas) por segundo centímetro cuadrado kelvin'},
    { tipo: 2, subtipo: 57, id: 'N78', description: 'Kilowatt por metro cuadrado kelvin (1000 veces la unidad derivada watt, dividida por el producto de la potencia de unidad base, metro, por el exponente 2 y la unidad base, kelvin, del sistema internacional de unidades.)'},
    { tipo: 2, subtipo: 57, id: 'N74', description: 'Unidad térmica británica (tabla internacional) por hora pie cuadrado grado fahrenheit. (Unidad del coeficiente de transición térmica según el sistema Imperial de unidades.)'},
    { tipo: 2, subtipo: 57, id: 'A23', description: 'Unidad térmica británica (tabla internacional) por hora pie cuadrado grado rankine.'},
    { tipo: 2, subtipo: 57, id: 'N76', description: 'Unidad térmica británica (tabla internacional) por segundo pie cuadrado grado fahrenheit. (Unidad del coeficiente de transición térmica según el sistema imperial de unidades.)'},
    { tipo: 2, subtipo: 57, id: 'A20', description: 'Unidad térmica británica (tabla internacional) por segundo pie cuadrado grado rankine.'},
    { tipo: 2, subtipo: 57, id: 'N75', description: 'Unidad térmica británica (termoquímico) por hora pie cuadrado grado farenheit. (Unidad del coeficiente de transición térmica según el sistema imperial de unidades.)'},
    { tipo: 2, subtipo: 57, id: 'N77', description: 'Unidad térmica británica (termoquímico) por segundo pie cuadrado grado fahrenheit. (Unidad del coeficiente de transición térmica según el sistema imperial de unidades.)'},
    { tipo: 2, subtipo: 59, id: 'D55', description: 'Watt por metro cuadrado kelvin'},
    { tipo: 2, subtipo: 69, id: 'D52', description: 'Watt por kelvin'},
    { tipo: 2, subtipo: 73, id: 'J78', description: 'Caloría (termoquímica) por centímetro segundo grado celsius'},
    { tipo: 2, subtipo: 73, id: 'D71', description: 'Calorías (tabla internacional) por segundo centímetro kelvin'},
    { tipo: 2, subtipo: 73, id: 'D38', description: 'Calorías (termoquímicas) por segundo centímetro kelvin'},
    { tipo: 2, subtipo: 73, id: 'K52', description: 'Kilocaloría (tabla internacional) por hora metro grado celsius'},
    { tipo: 2, subtipo: 73, id: 'N82', description: 'Kilowatt por metro grado celsius (1000 veces de la unidad derivada, watt, dividido por el producto de la unidad base metro y la unidad de temperatura grados Celsius.)'},
    { tipo: 2, subtipo: 73, id: 'N81', description: 'Kilowatt por metro kelvin (1000 veces de la unidad derivada, watt, dividido por el producto de la unidad de base metro y la unidad base kelvin, ambas del sistema internacional.)'},
    { tipo: 2, subtipo: 73, id: 'J40', description: 'Unidad térmica británica (tabla internacional) pie por hora pie cuadrado grado fahrenheit.'},
    { tipo: 2, subtipo: 73, id: 'A22', description: 'Unidad térmica británica (tabla internacional) por segundo pie grado rankine'},
    { tipo: 2, subtipo: 73, id: 'J41', description: 'Unidad térmica británica (tabla internacional) pulgada por hora pie cuadrado grado fahrenheit.'},
    { tipo: 2, subtipo: 73, id: 'J42', description: 'Unidad térmica británica (tabla internacional) pulgada por segundo pie cuadrado grado fahrenheit.'},
    { tipo: 2, subtipo: 73, id: 'J46', description: 'Unidad térmica británica (termoquímico) pie por hora pie cuadrado grado fahrenheit.'},
    { tipo: 2, subtipo: 73, id: 'J48', description: 'Unidad térmica británica (termoquímico) pulgada por hora pie cuadrado grado fahrenheit.'},
    { tipo: 2, subtipo: 73, id: 'J49', description: 'Unidad térmica británica (termoquímico) pulgada por segundo pie cuadrado grado fahrenheit.'},
    { tipo: 2, subtipo: 73, id: 'N80', description: 'Watt por metro grado celsius (Unidad derivada del sistema internacional, watt, dividido por el producto de la unidad base, metro, y la unidad de temperatura grados Celsius.)'},
    { tipo: 2, subtipo: 73, id: 'D53', description: 'Watt por metro kelvin (Es la conductividad térmica de un cuerpo homogéneo isótropo, en la que una diferencia de temperatura de 1 kelvin entre dos planos paralelos, de área 1 metro cuadrado y distantes 1 metro, produce entre estos planos un flujo térmico de 1 watt.)'},
    { tipo: 2, subtipo: 93, id: 'N58', description: 'Unidad térmica británica (tabla internacional) por pie cúbico (Unidad de la densidad de energía según el sistema Imperial de unidades.)'},
    { tipo: 2, subtipo: 93, id: 'N59', description: 'Unidad térmica británica (termoquímica) por pie cúbico (Unidad de la densidad de energía según el sistema Imperial de unidades.)'},
    { tipo: 2, subtipo: 101, id: 'N56', description: 'Caloría (termoquímica) por centímetro cuadrado minuto (Unidad del flujo superficial de calor según el sistema Imperial de unidades.)'},
    { tipo: 2, subtipo: 101, id: 'N57', description: 'Caloría (termoquímica) por centímetro cuadrado segundo (Unidad del flujo superficial de calor según el sistema Imperial de unidades.)'},
    { tipo: 2, subtipo: 101, id: 'N50', description: 'Unidad térmica británica (tabla internacional) por pie cuadrado hora. (Unidad del flujo superficial de calor según el sistema Imperial de unidades.)'},
    { tipo: 2, subtipo: 101, id: 'N53', description: 'Unidad térmica británica (tabla internacional) por pie cuadrado segundo. (Unidad del flujo superficial de calor según el sistema Imperial de unidades.)'},
    { tipo: 2, subtipo: 101, id: 'N55', description: 'Unidad térmica británica (tabla internacional) por pulgada cuadrada segundo. (Unidad del flujo superficial de calor según el sistema Imperial de unidades.)'},
    { tipo: 2, subtipo: 101, id: 'N51', description: 'Unidad térmica británica (termoquímica) por pie cuadrado hora. (Unidad del flujo superficial de calor según el sistema Imperial de unidades.)'},
    { tipo: 2, subtipo: 101, id: 'N54', description: 'Unidad térmica británica (termoquímica) por pie cuadrado segundo. (Unidad del flujo superficial de calor según el sistema Imperial de unidades.)'},
    { tipo: 2, subtipo: 101, id: 'N52', description: 'Unidad térmica británica (termoquímico) por pie cuadrado minuto. (Unidad del flujo superficial de calor según el sistema Imperial de unidades.)'},
    { tipo: 2, subtipo: 101, id: 'N48', description: 'Watt por centímetro cuadrado (Unidad derivada del sistema internacional, watt, dividido por la potencia de la 0.01 parte de la unidad base del sistema internacional, metro al exponente 2.)'},
    { tipo: 2, subtipo: 101, id: 'D54', description: 'Watt por metro cuadrado'},
    { tipo: 2, subtipo: 101, id: 'N49', description: 'Watt por pulgada cuadrada (Unidad derivada del sistema internacional, watt, dividido por la potencia de la unidad pulgada al exponente 2, de acuerdo al sistema de unidades Anglo'},
    { tipo: 2, subtipo: 114, id: 'N83', description: 'Metro por grado celsius metro'},
    { tipo: 2, subtipo: 114, id: 'E97', description: 'Mililitro por gadro celsius metro'},
    { tipo: 2, subtipo: 114, id: 'F53', description: 'Mililitro por kelvin'},
    { tipo: 2, subtipo: 138, id: 'AZ', description: 'Unidad térmica británica (tabla internacional) por libra'},
    { tipo: 2, subtipo: 138, id: 'N73', description: 'Unidad térmica británica (termoquímica) por libra (Unidad de la energía calorífica según el sistema imperial de unidades dividió por la unidad de la libra de avoirdupois según el sistema de avoirdupois de unidades.)'},
    { tipo: 2, subtipo: 137, id: 'D75', description: 'Calorías (tabla internacional) por gramo'},
    { tipo: 2, subtipo: 139, id: 'J2', description: 'Joule por kilogramo'},
    { tipo: 2, subtipo: 142, id: 'JK', description: 'Megajoule por kilogramo'},
    { tipo: 2, subtipo: 143, id: 'D95', description: 'Joule por gramo'},
    { tipo: 2, subtipo: 143, id: 'Q31', description: 'Kilojoule por gramo'},
    { tipo: 2, subtipo: 145, id: 'B42', description: 'Kilojoule por kilogramo'},
    { tipo: 2, subtipo: 184, id: 'D35', description: 'Calorías (termoquímicas)'},
    { tipo: 2, subtipo: 187, id: 'B36', description: 'Calorías (termoquímicas) por gramo'},
    { tipo: 2, subtipo: 350, id: 'N88', description: 'Grado fahrenheit hora pie cuadrado por unidad térmica británica (tabla internacional) pulgada (Unidad de resistencia térmica específica según el sistema Imperial de unidades)'},
    { tipo: 2, subtipo: 350, id: 'N89', description: 'Grado fahrenheit hora pie cuadrado por unidad térmica británica (termoquímico) pulgada. (Unidad de resistencia térmica específica según el sistema Imperial de unidades)'},
    { tipo: 2, subtipo: 350, id: 'N84', description: 'Grado fahrenheit hora por unidad térmica británica (tabla internacional) (Unidad no conforme al sistema internacional de unidades,de la resistencia térmica según el sistema Imperial de unidades.)'},
    { tipo: 2, subtipo: 350, id: 'N85', description: 'Grado fahrenheit hora por unidad térmica británica (termoquímico) (Unidad no conforme al sistema internacional de unidades,de la resistencia térmica según el sistema Imperial de unidades.)'},
    { tipo: 2, subtipo: 350, id: 'N86', description: 'Grado fahrenheit segundo por unidad térmica británica (tabla internacional) (Unidad no conforme al sistema internacional de unidades,de la resistencia térmica según el sistema Imperial de unidades.)'},
    { tipo: 2, subtipo: 350, id: 'N87', description: 'Grago fahrenheit segundo por unidad térmica británica (termoquímico) (Unidad no conforme al sistema internacional de unidades,de la resistencia térmica según el sistema Imperial de unidades.)'},
    { tipo: 2, subtipo: 350, id: 'H35', description: 'Kelvin metro por watt'},
    { tipo: 2, subtipo: 350, id: 'B21', description: 'Kelvin por watt'},
    { tipo: 2, subtipo: 375, id: 'J81', description: 'Caloría (termoquímica) por minuto'},
    { tipo: 2, subtipo: 375, id: 'J82', description: 'Caloría (termoquímica) por segundo'},
    { tipo: 2, subtipo: 375, id: 'E15', description: 'Kilocaloría (termoquímica) por hora'},
    { tipo: 2, subtipo: 375, id: 'K54', description: 'Kilocaloría (termoquímica) por minuto'},
    { tipo: 2, subtipo: 375, id: 'K55', description: 'Kilocaloría (termoquímica) por segundo'},
    { tipo: 2, subtipo: 375, id: '2I', description: 'Unidad térmica británica (tabla internacional) por hora'},
    { tipo: 2, subtipo: 375, id: 'J44', description: 'Unidad térmica británica (tabla internacional) por minuto'},
    { tipo: 2, subtipo: 375, id: 'J45', description: 'Unidad térmica británica (tabla internacional) por segundo'},
    { tipo: 2, subtipo: 375, id: 'J47', description: 'Unidad térmica británica (termoquímica) por hora'},
    { tipo: 2, subtipo: 375, id: 'J51', description: 'Unidad térmica británica (termoquímica) por minuto'},
    { tipo: 2, subtipo: 375, id: 'J52', description: 'Unidad térmica británica (termoquímica) por segundo'},
    { tipo: 2, subtipo: 379, id: 'F60', description: 'Grado celsius por bar'},
    { tipo: 2, subtipo: 379, id: 'H12', description: 'Grado celsius por hora'},
    { tipo: 2, subtipo: 379, id: 'E98', description: 'Grado celsius por kelvin'},
    { tipo: 2, subtipo: 379, id: 'H13', description: 'Grado celsius por minuto'},
    { tipo: 2, subtipo: 379, id: 'H14', description: 'Grado celsius por segundo'},
    { tipo: 2, subtipo: 379, id: 'J21', description: 'Grado fahrenheit por bar'},
    { tipo: 2, subtipo: 379, id: 'J20', description: 'Grado fahrenheit por kelvin'},
    { tipo: 2, subtipo: 379, id: 'A48', description: 'Grado rankine (Consulte ISO 80000 - 5 (Cantidades y unidades -  Parte 5: Termodinámica))'},
    { tipo: 2, subtipo: 379, id: 'CEL', description: 'Grados celsius (Consulte ISO 80000 - 5 (Cantidades y unidades -  Parte 5: Termodinámica))'},
    { tipo: 2, subtipo: 379, id: 'F61', description: 'Kelvin por bar'},
    { tipo: 2, subtipo: 379, id: 'F10', description: 'Kelvin por hora'},
    { tipo: 2, subtipo: 379, id: 'F02', description: 'Kelvin por kelvin'},
    { tipo: 2, subtipo: 379, id: 'F11', description: 'Kelvin por minuto'},
    { tipo: 2, subtipo: 379, id: 'N79', description: 'Kelvin por pascal (Unidad base del sistema internacional kelvin, dividido entre pascal, unidad base del sistema internacional)'},
    { tipo: 2, subtipo: 379, id: 'F12', description: 'Kelvin por segundo'},
    { tipo: 2, subtipo: 379, id: 'J26', description: 'Reciprocidad grado fahrenheit'},
    { tipo: 2, subtipo: 381, id: 'FAH', description: 'Grado fahrenheit (Consulte ISO 80000 - 5 (Cantidades y unidades - 5 (Cantidades y unidades -  Parte 5: Termodinámica))'},
    { tipo: 2, subtipo: 384, id: 'KEL', description: 'Kelvin (Unidad de temperatura termodinámica, es la fracción 1/273,16 de la temperatura termodinámica del punto triple del agua. )'},
    { tipo: 2, subtipo: 400, id: 'J23', description: 'Grado fahrenheit por hora'},
    { tipo: 2, subtipo: 400, id: 'J24', description: 'Grado fahrenheit por minuto'},
    { tipo: 2, subtipo: 400, id: 'J25', description: 'Grado fahrenheit por segundo'},
    { tipo: 2, subtipo: 400, id: 'J28', description: 'Grado rankine por hora'},
    { tipo: 2, subtipo: 400, id: 'J29', description: 'Grado rankine por minuto'},
    { tipo: 2, subtipo: 400, id: 'J30', description: 'Grado rankine por segundo'},
    { tipo: 3, subtipo: 200, id: 'P83', description: 'Admosfera estandar por metro. (Unidad anticuadas de la presión dividida por la unidad de base metros.)'},
    { tipo: 3, subtipo: 200, id: 'P84', description: 'Admosfera tecnica por metro. (unidad obsoleta y no legal de la presión que se genera por una columna de agua de 10 metros dividida por la unidad de base metros.)'},
    { tipo: 3, subtipo: 200, id: 'P82', description: 'Hectopascal por metro. (100 veces de la unidad derivada pascal SI dividido por la unidad de base metros. )'},
    { tipo: 3, subtipo: 200, id: 'P81', description: 'Kilopascal por metro. (1000 veces de la unidad derivada pascal dividido por la unidad de base metros.)'},
    { tipo: 3, subtipo: 200, id: '34', description: 'Kilopascal por milimetro'},
    { tipo: 3, subtipo: 200, id: 'P80', description: 'Milipascal por metro. ( 0.001 veces de la unidad derivada pascal dividido por la unidad de base metros.)'},
    { tipo: 3, subtipo: 200, id: 'H42', description: 'Pascal por metro'},
    { tipo: 3, subtipo: 200, id: 'H69', description: 'Picopascal por kilometro'},
    { tipo: 3, subtipo: 200, id: 'P86', description: 'Psi por pulgada (Unidad de compuesto para la presión (libra de fuerza de acuerdo con el sistema de la unidad angloamericana dividida por la potencia de la unidad de pulgada de acuerdo con el sistema angloamericano e Imperial de unidades con el exponente 2) dividido por la unidad de pulgada de acuerdo con la sistema angloamericano e Imperial de unidades.)'},
    { tipo: 3, subtipo: 200, id: 'P85', description: 'Torr por metro. (Unidad de la presión dividida por la unidad de base metros.)'},
    { tipo: 3, subtipo: 201, id: '33', description: 'Kilopascal por grtr'},
    { tipo: 3, subtipo: 201, id: 'P79', description: 'Pascal metro cuadrado por kilogramo ( Unidad del índice de estallido como unidad derivada para pascal presión relacionada con la sustancia, representado como cociente de la unidad base SI kilogramo dividida por la potencia de la unidad base SI metros por exponente 2.)'},
    { tipo: 3, subtipo: 422, id: '1I', description: 'Tipo de interés fijo ( Unidad de cantidad expresada como una tasa predeterminada o conjunto para el uso de una instalación o servicio.)'},
    { tipo: 3, subtipo: 422, id: 'DE', description: 'Acuerdo'},
    { tipo: 3, subtipo: 422, id: 'PD', description: 'Almohadilla (Unidad de conteo que define el número de almohadillas (almohadilla: bloquea de hojas de papel sujetas juntas en un extremo).)'},
    { tipo: 3, subtipo: 422, id: '73', description: 'Amperios por centímetro'},
    { tipo: 3, subtipo: 422, id: 'H77', description: 'Anchura del módulo (Unidad de medida utilizada para describir la anchura de los conjuntos electrónicos como una norma de instalación o una dimensión de montaje.)'},
    { tipo: 3, subtipo: 422, id: 'Z5', description: 'Arrastre'},
    { tipo: 3, subtipo: 422, id: 'Jo', description: 'Articulación'},
    { tipo: 3, subtipo: 422, id: '5', description: 'Ascensor'},
    { tipo: 3, subtipo: 422, id: 'AA', description: 'Balón (Unidad de recuento para definir el número de bolas (Balón: objeto formado en la forma de esfera).)'},
    { tipo: 3, subtipo: 422, id: 'D92', description: 'banda'},
    { tipo: 3, subtipo: 422, id: '5B', description: 'Batch (Unidad de conteo que define el número de lotes (lote: cantidad de material producido en una operación o número de animales o personas que vienen a la vez).)'},
    { tipo: 3, subtipo: 422, id: 'B9', description: 'Batt'},
    { tipo: 3, subtipo: 422, id: 'M19', description: 'Beaufort (Una medida empírica para describir la velocidad del viento basada principalmente en condiciones marinas observadas. La escala de Beaufort indica la velocidad del viento por números que típicamente varían de 0 para la calma, a 12 para el huracán.)'},
    { tipo: 3, subtipo: 422, id: 'D64', description: 'bloquear'},
    { tipo: 3, subtipo: 422, id: 'B6', description: 'Bollo'},
    { tipo: 3, subtipo: 422, id: '46', description: 'Bolsa a granel de cincuenta libras'},
    { tipo: 3, subtipo: 422, id: '44', description: 'Bolsa a granel de quinientos kilos'},
    { tipo: 3, subtipo: 422, id: '45', description: 'Bolsa a granel de trescientos kilos'},
    { tipo: 3, subtipo: 422, id: '47', description: 'Bolso de cincuenta libras'},
    { tipo: 3, subtipo: 422, id: '5G', description: 'bomba'},
    { tipo: 3, subtipo: 422, id: 'B0', description: 'Btu por pie cúbico'},
    { tipo: 3, subtipo: 422, id: 'CE', description: 'Cada mes'},
    { tipo: 3, subtipo: 422, id: '92', description: 'Calorías por centímetro cúbico'},
    { tipo: 3, subtipo: 422, id: '93', description: 'Calorías por gramo (Utilice calorías de la tabla internacional (IT) por gramo (código común D75).)'},
    { tipo: 3, subtipo: 422, id: '19', description: 'Camión cisterna'},
    { tipo: 3, subtipo: 422, id: 'FB', description: 'campo'},
    { tipo: 3, subtipo: 422, id: 'LR', description: 'Capa (Unidad de conteo que define el número de capas.)'},
    { tipo: 3, subtipo: 422, id: '48', description: 'Carga masiva'},
    { tipo: 3, subtipo: 422, id: 'D66', description: 'casete'},
    { tipo: 3, subtipo: 422, id: 'EB', description: 'Casilla de correo electrónico (Es el destino al que correo electrónico se entregan los mensajes. Es el equivalente de un buzón en el sistema postal.)'},
    { tipo: 3, subtipo: 422, id: 'C6', description: 'celda'},
    { tipo: 3, subtipo: 422, id: 'BH', description: 'Cepillo'},
    { tipo: 3, subtipo: 422, id: 'B7', description: 'Ciclo (Unidad de recuento de definir el número de ciclos (ciclo: un período recurrente de duración definida).)'},
    { tipo: 3, subtipo: 422, id: 'II', description: 'Columna pulgada'},
    { tipo: 3, subtipo: 422, id: 'CZ', description: 'Combo'},
    { tipo: 3, subtipo: 422, id: 'Q3', description: 'Comida (Unidad de conteo que define el número de comida)'},
    { tipo: 3, subtipo: 422, id: 'CK', description: 'Conector'},
    { tipo: 3, subtipo: 422, id: '5K', description: 'Contar por minuto'},
    { tipo: 3, subtipo: 422, id: 'IC', description: 'Contar por pulgada'},
    { tipo: 3, subtipo: 422, id: 'Z11', description: 'Contenedor colgante (Unidad de conteo que define el número de contenedores colgantes.)'},
    { tipo: 3, subtipo: 422, id: 'RU', description: 'correr'},
    { tipo: 3, subtipo: 422, id: 'C5', description: 'Costo'},
    { tipo: 3, subtipo: 422, id: 'B17', description: 'Crédito (Unidad de recuento de definir el número de entradas realizadas en el haber de una cuenta.)'},
    { tipo: 3, subtipo: 422, id: 'SQ', description: 'Cuadrado (Unidad de conteo que define el número de cuadrados (cuadrado: forma rectangular))'},
    { tipo: 3, subtipo: 422, id: 'TST', description: 'Decena de conjuntos (Unidad de conteo que define el número de conjuntos en múltiplos de 10 (conjunto: un número de objetos agrupados))'},
    { tipo: 3, subtipo: 422, id: 'TTS', description: 'Decenas de millar de pegatinas (Unidad de conteo que define el número de palos multiples de 10000 (Pegatina: pieza delgada y a menudo cilíndrica de una sustancia))'},
    { tipo: 3, subtipo: 422, id: '30', description: 'Día de potencia de caballos por tonelada métrica de aire seco'},
    { tipo: 3, subtipo: 422, id: '96', description: 'Diez mil galones (US)'},
    { tipo: 3, subtipo: 422, id: 'DC', description: 'Disco (disco)'},
    { tipo: 3, subtipo: 422, id: 'D67', description: 'Dólar por hora'},
    { tipo: 3, subtipo: 422, id: 'E27', description: 'Dosis (Unidad de recuento de definir el número de dosis (dosis: una cantidad definida de un medicamento o fármaco).)'},
    { tipo: 3, subtipo: 422, id: 'LK', description: 'Enlazar (Unidad de distancia igual a 0.01 cadena.)'},
    { tipo: 3, subtipo: 422, id: '11', description: 'Equipos (Unidad de recuento que define el número de equipos (equipo: un conjunto completo de equipo / materiales / objetos utilizados para un propósito específico).)'},
    { tipo: 3, subtipo: 422, id: '69', description: 'Escala específica de prueba'},
    { tipo: 3, subtipo: 422, id: '5H', description: 'Escenario'},
    { tipo: 3, subtipo: 422, id: 'F9', description: 'Fibra por centímetro cúbico de aire'},
    { tipo: 3, subtipo: 422, id: '5C', description: 'Galón (US) por mil'},
    { tipo: 3, subtipo: 422, id: '76', description: 'Gauss'},
    { tipo: 3, subtipo: 422, id: '4B', description: 'Gorra'},
    { tipo: 3, subtipo: 422, id: 'D23', description: 'Gramo pluma (proteína) (Unidad de recuento pata definir el número de gramos de aminoácidos prescritos para la terapia parenteral y terapia enteral.)'},
    { tipo: 3, subtipo: 422, id: 'C9', description: 'Grupo bobinas (Unidad de conteo que define el número de grupos de bobinas (grupo bobina: grupos de elementos dispuestos por longitudes de los objetos colocados en una secuencia de círculos concéntricos unido).)'},
    { tipo: 3, subtipo: 422, id: '10', description: 'Grupos (Una unidad de conteo que define el número de grupos (grupo: conjunto de elementos clasificados juntos).)'},
    { tipo: 3, subtipo: 422, id: 'ROM', description: 'Habitación (Unidad de conteo que define el número de habitaciones)'},
    { tipo: 3, subtipo: 422, id: 'G7', description: 'Hoja de microficha'},
    { tipo: 3, subtipo: 422, id: 'IM', description: 'Impresión'},
    { tipo: 3, subtipo: 422, id: 'SYR', description: 'Jeringuilla (Unidad de conteo que define el número de jeringuillas (jeringa: un pequeño dispositivo para bombear, pulverizar y/o inyectar liquidos a través de una pequeña abertura))'},
    { tipo: 3, subtipo: 422, id: 'KB', description: 'Kilocaracter (Unidad de información igual a 10³ (1000) caracteres.)'},
    { tipo: 3, subtipo: 422, id: '78', description: 'Kilogauss'},
    { tipo: 3, subtipo: 422, id: 'KD', description: 'Kilogram decimal'},
    { tipo: 3, subtipo: 422, id: '3H', description: 'Kilogramo por kilogramo de producto'},
    { tipo: 3, subtipo: 422, id: '3I', description: 'Kilogramo por pedazo de producto'},
    { tipo: 3, subtipo: 422, id: '32', description: 'Kilogramo por tonelada métrica seca del aire'},
    { tipo: 3, subtipo: 422, id: '53', description: 'Kilogramo teórico'},
    { tipo: 3, subtipo: 422, id: 'KF', description: 'Kilopacket'},
    { tipo: 3, subtipo: 422, id: '84', description: 'Kilopound - force por pulgada cuadrada (Unidad de presión que define el número de kilopounds fuerza por pulgada cuadrada.  Utilice kip por pulgada cuadrada (código común N20).)'},
    { tipo: 3, subtipo: 422, id: 'A59', description: 'La cobertura de nubes 8 - parte (Unidad de recuento para definir el número de octavos de partes como una medida de la cobertura de nubes de la ccúpula celeste. Sinónimo: OKTA, OCTA)'},
    { tipo: 3, subtipo: 422, id: 'C1', description: 'Libra de producto compuesto (peso total)'},
    { tipo: 3, subtipo: 422, id: '3E', description: 'Libra por libra de producto'},
    { tipo: 3, subtipo: 422, id: '5F', description: 'Libra por mil'},
    { tipo: 3, subtipo: 422, id: '29', description: 'Libra por mil pies cuadrados'},
    { tipo: 3, subtipo: 422, id: '3G', description: 'Libra por pieza de producto'},
    { tipo: 3, subtipo: 422, id: '64', description: 'Libra por pulgada cuadrada, calíbre'},
    { tipo: 3, subtipo: 422, id: 'D63', description: 'Libro (Unidad de recuento para definir el número de libros (libro: conjunto de elementos unidos entre sí o documento de un material escrito).)'},
    { tipo: 3, subtipo: 422, id: 'DY', description: 'Libro de directorio'},
    { tipo: 3, subtipo: 422, id: '5Q', description: 'Línea sísmica'},
    { tipo: 3, subtipo: 422, id: 'LE', description: 'Lite'},
    { tipo: 3, subtipo: 422, id: 'B2', description: 'litera'},
    { tipo: 3, subtipo: 422, id: 'LN', description: 'Longitud (Unidad de distancia que define la extensión lineal de un elemento medido de extremo a extremo.)'},
    { tipo: 3, subtipo: 422, id: 'LO', description: 'Lote [unidad de adquisición] (Unidad de conteo que define el número de lotes (lote: una colección de artículos asociados).)'},
    { tipo: 3, subtipo: 422, id: 'HA', description: 'Madeja (Unidad de longitud, típicamente para el hilado.)'},
    { tipo: 3, subtipo: 422, id: '57', description: 'Malla (Una unidad de recuento de definir el número de hebras por pulgada como una medida de la finura de un producto tejido.)'},
    { tipo: 3, subtipo: 422, id: '3C', description: 'Manmonth (Unidad de cuenta que define el número de meses que una persona o personas pueden desempeñar alguna actividad.)'},
    { tipo: 3, subtipo: 422, id: 'MA', description: 'Máquina por unidad'},
    { tipo: 3, subtipo: 422, id: 'H1', description: 'Media página -  electrónica'},
    { tipo: 3, subtipo: 422, id: 'NF', description: 'Mensaje (Unidad de conteo que define el número de mensajes)'},
    { tipo: 3, subtipo: 422, id: 'KO', description: 'Miliequivalentes de potasa cáustica por gramo de producto (Unidad de conteo que define el número de miligramos de hidróxido de potasio por gramo de producto como una medida de la concentración de hidróxido de potasio en el producto.)'},
    { tipo: 3, subtipo: 422, id: 'E12', description: 'Mille (Unidad de recuento de definir el número de cigarrillos en unidades de 1.000.)'},
    { tipo: 3, subtipo: 422, id: 'M9', description: 'Millones de btu por 1000 pies cúbicos'},
    { tipo: 3, subtipo: 422, id: 'FD', description: 'Millones de partículas por pie cúbico'},
    { tipo: 3, subtipo: 422, id: '5E', description: 'Mmscf/day (Unidad de volumen equivalente a un millón (1,000,000) pies cúbicos de gas por día.)'},
    { tipo: 3, subtipo: 422, id: 'DS', description: 'monitor'},
    { tipo: 3, subtipo: 422, id: 'AY', description: 'Montaje (Una unidad de recuento de definir el número de conjuntos (montaje: artículos que consisten de partes componentes).)'},
    { tipo: 3, subtipo: 422, id: '8', description: 'Montón de calor'},
    { tipo: 3, subtipo: 422, id: 'ZZ', description: 'Mutuamente definido (Unidad de medida acordada en común entre dos o más partes)'},
    { tipo: 3, subtipo: 422, id: '5P', description: 'Nivel sísmico'},
    { tipo: 3, subtipo: 422, id: 'N2', description: 'número de líneas'},
    { tipo: 3, subtipo: 422, id: 'SW', description: 'Número de madejas (Unidad de conteo que define el número de madejas (skein:un paquete de hilo o hilo suelto))'},
    { tipo: 3, subtipo: 422, id: 'U2', description: 'Número de tabletas (Unidad de conteo que define el número de tabletas)'},
    { tipo: 3, subtipo: 422, id: '66', description: 'Oersted'},
    { tipo: 3, subtipo: 422, id: '38', description: 'Onzas por pie cuadrado por 0,01 pulgadas'},
    { tipo: 3, subtipo: 422, id: 'Z8', description: 'Página de noticias'},
    { tipo: 3, subtipo: 422, id: 'P0', description: 'Página electrónica'},
    { tipo: 3, subtipo: 422, id: 'STW', description: 'Paja (Unidad de conteo que define el número de pajitas (paja:un tubo delgado utilizado para succionar liquidos))'},
    { tipo: 3, subtipo: 422, id: 'B5', description: 'palanquilla'},
    { tipo: 3, subtipo: 422, id: 'STC', description: 'Palo (Unidad de conteo que define el número de palos (palo: pieza delgada y a menudo cilíndirca de una sustancia))'},
    { tipo: 3, subtipo: 422, id: 'STK', description: 'Palo, cigarrillo (Unidad de conteo que define el número de cigarrillos en la unidad más pequeña para el cálculo de la población y/o del trabajo)'},
    { tipo: 3, subtipo: 422, id: '15', description: 'Palo, medida militar. (Unidad para el momento de rotación relacionado con la longitud según el sistema de unidades Anglo - Americanas e Imperial.) '},
    { tipo: 3, subtipo: 422, id: 'OA', description: 'Panel (Unidade de conteo que define el n[umero de paneles )'},
    { tipo: 3, subtipo: 422, id: 'AB', description: 'Paquete a granel (Unidad de recuento para definir el número de artículos por paquete a granel.)'},
    { tipo: 3, subtipo: 422, id: 'FG', description: 'Parche transdérmico'},
    { tipo: 3, subtipo: 422, id: 'KA', description: 'Pastel (Unidad de conteo que define el número de pasteles (torta: objeto en forma de una masa plana y compacta).)'},
    { tipo: 3, subtipo: 422, id: '6', description: 'Pequeño aerosol'},
    { tipo: 3, subtipo: 422, id: 'IE', description: 'Personas (Unidad de conteo que define el número de personas.)'},
    { tipo: 3, subtipo: 422, id: '31', description: 'Pescar'},
    { tipo: 3, subtipo: 422, id: 'BW', description: 'Peso base'},
    { tipo: 3, subtipo: 422, id: 'WI', description: 'Peso por pulgada cuadrada'},
    { tipo: 3, subtipo: 422, id: '36', description: 'Pie cúbico por minuto por pie cuadrado (Se requiere factor de conversión)'},
    { tipo: 3, subtipo: 422, id: 'N1', description: 'Pluma calórica (Unidad de conteo que define el número de calorías que se recetan diariamente para la terapia parenteral / enteral.)'},
    { tipo: 3, subtipo: 422, id: 'AJ', description: 'policía'},
    { tipo: 3, subtipo: 422, id: 'IP', description: 'póliza de seguros'},
    { tipo: 3, subtipo: 422, id: '62', description: 'Por ciento por 1000 horas'},
    { tipo: 3, subtipo: 422, id: '13', description: 'Raciones (Una unidad de recuento para definir el número de raciones (ración: una sola porción de las disposiciones).)'},
    { tipo: 3, subtipo: 422, id: 'IT', description: 'Recuento por centímetro'},
    { tipo: 3, subtipo: 422, id: 'D65', description: 'Redondo (Unidad de recuento para definir el número de rondas (redondos: un objeto circular o cilíndrico).)'},
    { tipo: 3, subtipo: 422, id: 'RS', description: 'Reiniciar'},
    { tipo: 3, subtipo: 422, id: 'WH', description: 'Rueda'},
    { tipo: 3, subtipo: 422, id: 'D7', description: 'Sandwich'},
    { tipo: 3, subtipo: 422, id: '90', description: 'Saybold segundo universal'},
    { tipo: 3, subtipo: 422, id: 'SE', description: 'Sección'},
    { tipo: 3, subtipo: 422, id: 'SG', description: 'Segmento (Unidad de información equivalente a 64000bytes)'},
    { tipo: 3, subtipo: 422, id: 'S6', description: 'Sesión'},
    { tipo: 3, subtipo: 422, id: '14', description: 'Shot (Unidad de medida para liquidos.)'},
    { tipo: 3, subtipo: 422, id: 'D28', description: 'sifón'},
    { tipo: 3, subtipo: 422, id: 'GZ', description: 'Sistema de calibración'},
    { tipo: 3, subtipo: 422, id: '56', description: 'Sitas ( Unidad de área de placa de estaño igual a un área de superficie de 100 metros cuadrados.)'},
    { tipo: 3, subtipo: 422, id: 'LJ', description: 'Spray grande'},
    { tipo: 3, subtipo: 422, id: 'LS', description: 'Suma global (Unidad de conteo que define el número de cantidades monetarias completas o completas.)'},
    { tipo: 3, subtipo: 422, id: '18', description: 'Tambor de cincuenta y cinco galones (EUA)'},
    { tipo: 3, subtipo: 422, id: 'A9', description: 'Tarífa (Unidad de cantidad expresada como una tasa para el uso de una instalación o servicio.)'},
    { tipo: 3, subtipo: 422, id: 'CG', description: 'Tarjeta (Unidad de conteo que define el número de unidades de la tarjeta (tarjeta: papel rígido grueso o cartón).)'},
    { tipo: 3, subtipo: 422, id: '63', description: 'Tasa de fracaso en el tiempo'},
    { tipo: 3, subtipo: 422, id: 'SR', description: 'Tira (Unidad de conteo que define el número de tiras (pieza larga y estrecha de un objeto))'},
    { tipo: 3, subtipo: 422, id: '26', description: 'Tonelada real'},
    { tipo: 3, subtipo: 422, id: '54', description: 'Tonelada teórica (UK)'},
    { tipo: 3, subtipo: 422, id: 'U1', description: 'Tratamiento (Unidad de conteo que define el número de tratamientos (tratamiento: sujección a la acción de un agente químico, físico o biológico))'},
    { tipo: 3, subtipo: 422, id: 'E25', description: 'Unidad activa (Unidad de conteo que define el número de unidades activas dentro de una sustancia.)'},
    { tipo: 3, subtipo: 422, id: 'S7', description: 'unidad de almacenamiento'},
    { tipo: 3, subtipo: 422, id: 'H80', description: 'Unidad de bastidor (Unidad de medida utilizada para describir la altura en unidades de bastidor del equipo destinado a ser montado en un bastidor de 19 pulgadas o un bastidor de 23 pulgadas. Unidad de bastidor tiene 1,45 pulgadas (44,45 mm) de alto.)'},
    { tipo: 3, subtipo: 422, id: '94', description: 'Unidad de curl'},
    { tipo: 3, subtipo: 422, id: 'S8', description: 'Unidad de publicidad estándar'},
    { tipo: 3, subtipo: 422, id: 'AQ', description: 'Unidad del factir anti-hemofilico. (Unidad de medida para la potencia de la sangre (US).)'},
    { tipo: 3, subtipo: 422, id: 'M4', description: 'Valor monetario (Unidad de medida expresada como un monto monetario.)'},
    { tipo: 3, subtipo: 422, id: 'AS', description: 'Variedad (Unidad de recuento para definir el número de surtidos (variedad: conjunto de elementos agrupados en una colección mixta).)'},
    { tipo: 3, subtipo: 422, id: '72', description: 'Vatio por libra'},
    { tipo: 3, subtipo: 422, id: '95', description: 'Veinte mil galones'},
    { tipo: 3, subtipo: 422, id: 'VS', description: 'Visita'},
    { tipo: 3, subtipo: 422, id: 'VA', description: 'Voltio-amperio por kilogramo'},
    { tipo: 3, subtipo: 422, id: '71', description: 'Voltios amperios por libra'},
    { tipo: 3, subtipo: 422, id: 'WA', description: 'Watt por kilogramo (Unidad de potencia)'},
    { tipo: 3, subtipo: 310, id: 'P87', description: 'Metro cúbico por segundo de metro cuadrado (Unidad de volumen de flujo metros cúbicos por segundo relacionado con la superficie de transmisión en metros cuadrados.)'},
    { tipo: 3, subtipo: 310, id: '35', description: 'Milimetro por un segundo centimetro cuadrado'},
    { tipo: 11, subtipo: 244, id: 'SN', description: 'Barra cuadrada'},
    { tipo: 11, subtipo: 244, id: 'T4', description: 'Bolsa de mil'},
    { tipo: 11, subtipo: 244, id: 'HI', description: 'Cien hojas'},
    { tipo: 11, subtipo: 244, id: 'HK', description: 'Cien kilogramos'},
    { tipo: 11, subtipo: 244, id: 'FF', description: 'Cien metros cúbicos (Unidad de volumen igual a cien metros cúbicos.)'},
    { tipo: 11, subtipo: 244, id: 'HO', description: 'Cien onzas troy'},
    { tipo: 11, subtipo: 244, id: 'HF', description: 'Cien pies'},
    { tipo: 11, subtipo: 244, id: 'HL', description: 'Cien pies (lineal)'},
    { tipo: 11, subtipo: 244, id: 'HS', description: 'Cien pies cuadrados'},
    { tipo: 11, subtipo: 244, id: 'HH', description: 'Cien pies cúbicos (Unidad de volumen igual a cien pies cúbicos.)'},
    { tipo: 11, subtipo: 244, id: 'HY', description: 'Cien yardas'},
    { tipo: 11, subtipo: 244, id: 'YL', description: 'Cien yardas lineales'},
    { tipo: 11, subtipo: 244, id: 'CLF', description: 'Cientos de hojas (Una unidad de recuento de definir el número de hojas, expresado en unidades de cien hojas.)'},
    { tipo: 11, subtipo: 244, id: 'CNP', description: 'Cientos de paquetes (Unidad de recuento para definir el número de cientos de paquetes (cien paquete: conjunto de un centenar de artículos empaquetados juntos).)'},
    { tipo: 11, subtipo: 244, id: 'QAN', description: 'Cuarto (de un año) (Unidad de tiempo que define el número de años (3 meses))'},
    { tipo: 11, subtipo: 244, id: 'QK', description: 'Cuarto de kilogramo'},
    { tipo: 11, subtipo: 244, id: '1X', description: 'Cuarto de milla'},
    { tipo: 11, subtipo: 244, id: 'DEC', description: 'Década (Unidad de recuento de definir el número de décadas (década: cantidad igual a 10 o tiempo igual a 10 años).)'},
    { tipo: 11, subtipo: 244, id: 'TPR', description: 'Decenas de pares (Unidad de conteo que define el número de pares en multiplos de 10 (par:elemento descrito por dos))'},
    { tipo: 11, subtipo: 244, id: 'TA', description: 'Décimo de pie cúbico'},
    { tipo: 11, subtipo: 244, id: 'UD', description: 'Décimo minuto'},
    { tipo: 11, subtipo: 244, id: 'UH', description: 'Diez mil yardas'},
    { tipo: 11, subtipo: 244, id: 'TR', description: 'Diez pies cuadrados'},
    { tipo: 11, subtipo: 244, id: 'YT', description: 'Diez yardas'},
    { tipo: 11, subtipo: 244, id: 'TF', description: 'Diez yardas cuadradas'},
    { tipo: 11, subtipo: 244, id: 'W4', description: 'Dos semanas'},
    { tipo: 11, subtipo: 244, id: 'FIT', description: 'Fallas en el tiempo (Unidad de conteo que define el número de fallos que se pueden esperar en un intervalo de tiempo especificado. Las tasas de fallo de los componentes semiconductores se especifican a menudo como FIT (fallas en la unidad de tiempo) donde 1 FIT = 10?? / h.)'},
    { tipo: 11, subtipo: 244, id: 'UE', description: 'Hora de décimo'},
    { tipo: 11, subtipo: 244, id: 'T7', description: 'Impresión de mil'},
    { tipo: 11, subtipo: 244, id: 'HT', description: 'media hora'},
    { tipo: 11, subtipo: 244, id: 'PV', description: 'Media pinta (US)'},
    { tipo: 11, subtipo: 244, id: 'SAN', description: 'Medio año (Unidad de tiempo que define el número de años )'},
    { tipo: 11, subtipo: 244, id: 'GH', description: 'Medio galón (US)'},
    { tipo: 11, subtipo: 244, id: 'H2', description: 'Medio litro'},
    { tipo: 11, subtipo: 244, id: '2V', description: 'Megagrama por hora'},
    { tipo: 11, subtipo: 244, id: 'TJ', description: 'Mil centímetros cuadrados'},
    { tipo: 11, subtipo: 244, id: 'T5', description: 'Mil envolturas'},
    { tipo: 11, subtipo: 244, id: 'T6', description: 'Mil galones (US)'},
    { tipo: 11, subtipo: 244, id: 'TW', description: 'Mil hojas'},
    { tipo: 11, subtipo: 244, id: 'TV', description: 'Mil kilogramos'},
    { tipo: 11, subtipo: 244, id: 'T1', description: 'Mil libras'},
    { tipo: 11, subtipo: 244, id: 'KS', description: 'Mil libras por pulgada cuadrada'},
    { tipo: 11, subtipo: 244, id: 'D40', description: 'Mil litros'},
    { tipo: 11, subtipo: 244, id: 'MQ', description: 'Mil metros'},
    { tipo: 11, subtipo: 244, id: 'R9', description: 'Mil metros cúbicos (Unidad de volumen que equivale a mil metro cúbicos)'},
    { tipo: 11, subtipo: 244, id: 'TQD', description: 'Mil metros cúbicos por día (Unidad de volumen igual a mil metros cúbicos por día)'},
    { tipo: 11, subtipo: 244, id: 'TT', description: 'Mil metros lineales'},
    { tipo: 11, subtipo: 244, id: 'T3', description: 'Mil pedazos (Unidad de conteo que define el número de piezas en múltiplos de 1000 (pieza: un solo artículo, artículo o ejemplar))'},
    { tipo: 11, subtipo: 244, id: 'TQ', description: 'Mil pies'},
    { tipo: 11, subtipo: 244, id: 'TL', description: 'Mil pies (lineal)'},
    { tipo: 11, subtipo: 244, id: 'Ts', description: 'Mil pies cuadrados'},
    { tipo: 11, subtipo: 244, id: 'FC', description: 'Mil pies cúbicos (Unidad de volumen igual a mil pies cúbicos.)'},
    { tipo: 11, subtipo: 244, id: 'F1', description: 'Mil pies cúbicos por día'},
    { tipo: 11, subtipo: 244, id: 'TI', description: 'Mil pulgadas cuadradas (Unidad de medida imperial)'},
    { tipo: 11, subtipo: 244, id: 'T8', description: 'Mil pulgadas lineales'},
    { tipo: 11, subtipo: 244, id: 'UM', description: 'Millón de unidades'},
    { tipo: 11, subtipo: 244, id: 'BZ', description: 'Millones de BTUs'},
    { tipo: 11, subtipo: 244, id: 'HMQ', description: 'Millones de metros cúbicos (Unidad de volumen igual a un millón de metros cúbicos.)'},
    { tipo: 11, subtipo: 244, id: 'FM', description: 'Millones de pies cúbicos'},
    { tipo: 11, subtipo: 244, id: 'NIL', description: 'Nil (Unidad de conteo que define el número de instancias de nada)'},
    { tipo: 11, subtipo: 244, id: 'PB', description: 'Par de la pulgada'},
    { tipo: 11, subtipo: 244, id: 'H87', description: 'Pieza (Unidad de conteo que define el número de piezas (pieza: un solo artículo, artículo o ejemplar).)'},
    { tipo: 11, subtipo: 244, id: 'S5', description: 'Sesenta cuartos de pulgada'},
    { tipo: 11, subtipo: 244, id: 'TD', description: 'Termico'},
    { tipo: 11, subtipo: 244, id: 'QH', description: 'Un cuarto de hora'},
    { tipo: 11, subtipo: 244, id: 'D14', description: 'Yarda mil lineal'},
    { tipo: 12, subtipo: 393, id: 'C62', description: 'Uno (Sinónimo: unidad)'},
    { tipo: 13, subtipo: 291, id: 'M37', description: 'Actual 360 (Unidad de cuenta que define el número de años expresado en múltiplos de 360 días, un día es igual a 24 horas.)'},
    { tipo: 13, subtipo: 291, id: 'BIL', description: 'Billon (Sinonimo: Trillon en EUA.)'},
    { tipo: 13, subtipo: 291, id: 'CEN', description: 'Centenar (Una unidad de recuento de definir el número de unidades en múltiplos de 100.)'},
    { tipo: 13, subtipo: 291, id: 'H92', description: 'Ciento por cien mil (Unidad de proporción, igual a 0,01, en relación a múltiplos de cien.)'},
    { tipo: 13, subtipo: 291, id: 'HC', description: 'Conteo en cientos (Unidad de cuenta que define el número de unidades contadas en múltiplos de 100.)'},
    { tipo: 13, subtipo: 291, id: 'QD', description: 'Cuarta docena'},
    { tipo: 13, subtipo: 291, id: 'B19', description: 'Dígito (Unidad de información que define la cantidad de números utiliza para formar un número.)'},
    { tipo: 13, subtipo: 291, id: 'DZN', description: 'Docena (Unidad de recuento de definir el número de unidades en múltiplos de 12.)'},
    { tipo: 13, subtipo: 291, id: 'D8', description: 'Draize score'},
    { tipo: 13, subtipo: 291, id: 'GC', description: 'Gramo por 100 gramos'},
    { tipo: 13, subtipo: 291, id: 'GK', description: 'Gramo por kilogramo'},
    { tipo: 13, subtipo: 291, id: 'GGR', description: 'Grandioso (Unidad de cuenta que define el número de unidades en múltiplos de 1728 (12 x 12 x 12).)'},
    { tipo: 13, subtipo: 291, id: 'GRO', description: 'Gross (Unidad de conteo que define el número de unidades en múltiplos de 144 (12 x 12).)'},
    { tipo: 13, subtipo: 291, id: 'E30', description: 'Hebra (Unidad de recuento de definir el número de hebras (hebra: de largo,, flexible, solo hilo delgado, tiras de fibra, filamento constituyente o múltiplos de la misma, trenzados entre sí).)'},
    { tipo: 13, subtipo: 291, id: 'HD', description: 'Media docena'},
    { tipo: 13, subtipo: 291, id: 'M36', description: 'Mes de 30 días (Unidad de cuenta que define el número de meses expresado en múltiplos de 30 días, un día es igual a 24 horas.)'},
    { tipo: 13, subtipo: 291, id: 'MIL', description: 'Mil (Es un número natural que se escribe 1000 o 10³ en notación científica, y que sigue del 999 y precede al 1001.)'},
    { tipo: 13, subtipo: 291, id: 'MLD', description: 'Mil millones (Sinónimo: billon (US))'},
    { tipo: 13, subtipo: 291, id: 'MIO', description: 'Millon (Es igual a mil millares, o 106. El concepto de millón también se puede expresar mediante el prefijo mega antepuesto a unidades del SI. Etimología: millón proviene del término italiano milione (del latín: mille).)'},
    { tipo: 13, subtipo: 291, id: 'E23', description: 'Neumático (Unidad de recuento de definir el número de neumáticos (una cubierta sólida o llena de aire colocado alrededor de una llanta de rueda para formar un contacto suave con la carretera, absorber los golpes y proporcionar tracción).)'},
    { tipo: 13, subtipo: 291, id: 'NAR', description: 'Número de artículos (Unidad de conteo que define el número de artículos )'},
    { tipo: 13, subtipo: 291, id: 'NBB', description: 'Número de bobinas'},
    { tipo: 13, subtipo: 291, id: 'NCL', description: 'Número de celulas (Unidad de conteo que define el número de células (célula: un espacio cerrado o circunscrito, cavidad o volumen))'},
    { tipo: 13, subtipo: 291, id: 'JWL', description: 'Número de joyas (Unidad de cuenta que define el número de joyas (joya: piedra preciosa).)'},
    { tipo: 13, subtipo: 291, id: 'MV', description: 'Número de mults'},
    { tipo: 13, subtipo: 291, id: 'D68', description: 'Número de palabras (Unidad de recuento para definir el número de palabras.)'},
    { tipo: 13, subtipo: 291, id: 'NJ', description: 'Número de pantallas'},
    { tipo: 13, subtipo: 291, id: 'NMP', description: 'Número de paquetes (Unidad que define el número de paquetes (paquetes: colección de objetos empaquetados))'},
    { tipo: 13, subtipo: 291, id: 'NPR', description: 'Número de parejas (Una unidad de cuenta que define el número de pares (par: elemento descrito por dos).)'},
    { tipo: 13, subtipo: 291, id: 'NPT', description: 'Número de partes (Unidad de conteo que define el número de partes (partes: componente de larga entidad))'},
    { tipo: 13, subtipo: 291, id: 'NIU', description: 'Número de unidades internacionales (Unidad de conteo que define el número de unidades internacionales)'},
    { tipo: 13, subtipo: 291, id: 'PR', description: 'Par (Unidad de conteo que define el número de pares (par: descripción por 2))'},
    { tipo: 13, subtipo: 291, id: 'E40', description: 'Parte por cien mil'},
    { tipo: 13, subtipo: 291, id: 'NX', description: 'Parte por mil (Unidad de proporcion igual a 10 Sínonimo: por milla)'},
    { tipo: 13, subtipo: 291, id: '61', description: 'Parte por mil millones (EUA)'},
    { tipo: 13, subtipo: 291, id: '59', description: 'Parte por millón'},
    { tipo: 13, subtipo: 291, id: 'H71', description: 'Por ciento al mes (Unidad de proporción, igual a 0,01, en relación a un mes.)'},
    { tipo: 13, subtipo: 291, id: 'H91', description: 'Por ciento por cada diez mil (Unidad de proporción, igual a 0,01, en relación a múltiplos de cien mil.)'},
    { tipo: 13, subtipo: 291, id: 'H73', description: 'Por ciento por decakelvin (Unidad de proporción, igual a 0,01, en relación con 10 veces la unidad de base SI Kelvin)'},
    { tipo: 13, subtipo: 291, id: 'H72', description: 'Por ciento por hectobar (Unidad de proporción, igual a 0,01, en relación con 100 veces la barra unitaria)'},
    { tipo: 13, subtipo: 291, id: 'H25', description: 'Por ciento por kelvin (Unidad de proporción, igual a 0,01, en relación con la unidad de base SI Kelvin.)'},
    { tipo: 13, subtipo: 291, id: 'H99', description: 'Por ciento por metro (Unidad de proporción, igual a 0,01, en relación con un metro.)'},
    { tipo: 13, subtipo: 291, id: 'H94', description: 'Por ciento por mil (Unidad de proporción, igual a 0,01, en relación con la unidad derivada de SI volt.)'},
    { tipo: 13, subtipo: 291, id: 'J10', description: 'Por ciento por milímetro (Unidad de proporción, igual a 0,01, en relación a un milímetro.)'},
    { tipo: 13, subtipo: 291, id: 'H89', description: 'Por ciento por Ohmio (Unidad de proporción, igual a 0,01, en relación con un ángulo de un grado.)'},
    { tipo: 13, subtipo: 291, id: 'H98', description: 'Por ciento por pulgada (Unidad de proporción, igual a 0,01, en relación a una pulgada.)'},
    { tipo: 13, subtipo: 291, id: 'H95', description: 'Por ciento por voltio (Unidad de proporción, igual a 0,01, en relación con una presión atmosférica de una barra.)'},
    { tipo: 13, subtipo: 291, id: 'J12', description: 'Por mille por psi (Unidad de presión igual a una milésima de psi (libra-fuerza por pulgada cuadrada).)'},
    { tipo: 13, subtipo: 291, id: 'H93', description: 'Porcentaje por cien (Unidad de proporción, igual a 0,01, en relación a múltiplos de mil.)'},
    { tipo: 13, subtipo: 291, id: 'H90', description: 'Porcentaje por grado (Unidad de proporción, igual a 0,01, en relación a múltiplos de diez mil.)'},
    { tipo: 13, subtipo: 291, id: 'M25', description: 'Porcentaje por grado celsius (Unidad de proporción, igual a 0,01, en relación a una temperatura de un grado.)'},
    { tipo: 13, subtipo: 291, id: 'SCO', description: 'Puntuación (Unidad de conteo que define el número de unidades en multiplos de 20)'},
    { tipo: 13, subtipo: 291, id: 'E21', description: 'Shares (Unidad de recuento para definir el número de acciones (proporción: un total o una parte de las partes en que se divide el capital de una entidad comercial).)'},
    { tipo: 13, subtipo: 291, id: 'P1', description: 'Tanto por ciento (Unidad de proporcion equivalente a 0.01)'},
    { tipo: 13, subtipo: 291, id: '60', description: 'Tanto por ciento en peso'},
    { tipo: 13, subtipo: 291, id: 'H96', description: 'Tanto por ciento por bar (Unidad de proporción, igual a 0,01, en relación con una presión atmosférica de una barra.)'},
    { tipo: 13, subtipo: 291, id: 'TRL', description: 'Trillones (eur) (Unidad de escala númerica)'},
    { tipo: 13, subtipo: 291, id: 'HIU', description: 'Unidad internacional de cien (Unidad de cuenta que define el número de unidades internacionales en múltiplos de 100.)'},
    { tipo: 13, subtipo: 291, id: 'MIU', description: 'Unidad internacional de millon (Unidad de cuenta que define el número de unidades internacionales en múltiplos de 106.)'},
    { tipo: 13, subtipo: 291, id: 'VP', description: 'Volumen porcentual (Unidad de medida de concentración, expresada tipícamente como el porcentaje de volumen de un soluto en una solución )'},
    { tipo: 15, subtipo: 1, id: 'M45', description: 'Grado por segundo cuadrado (360 partes de un círculo completo dividido por la potencia de la unidad de base SI en segundo lugar y el exponente 2.)'},
    { tipo: 15, subtipo: 1, id: '2B', description: 'Radián por segundo cuadrado (Es la aceleración angular de un cuerpo animado de una rotación uniformemente variada alrededor de un eje fijo, cuya velocidad angular, varía 1 radián por segundo, en 1 segundo. )'},
    { tipo: 15, subtipo: 3, id: 'K40', description: 'Aceleración estándar de la caída libre (Es la aceleración nominal de la gravedad de un objeto en un vacío cerca de la superficie de la Tierra.)'},
    { tipo: 15, subtipo: 3, id: 'M39', description: 'Centímetro por segundo cuadrado (0.01 veces de unidad de base del sistema internacional dividido por la potencia de la unidad de base del sistema internacional en segundo lugar por el exponente 2.)'},
    { tipo: 15, subtipo: 3, id: 'A76', description: 'Galón (Es una unidad de volumen que se emplea en los países anglófonos (especialmente Estados Unidos) o con influencia de estos (como Liberia, Guatemala, Panamá, Honduras, Nicaragua, El Salvador, Colombia y parcialmente en México), para medir volúmenes de líquidos, principalmente la gasolina y su precio. Antiguamente, el volumen de un galón dependía de lo que se midiera, y dónde. Sin embargo, en el siglo XIX existían dos definiciones de uso común: "galón de vino" (wine gallon) y "galón de cerveza británico" (ale gallon).)'},
    { tipo: 15, subtipo: 3, id: 'M38', description: 'Kilómetro por segundo cuadrado (1000 veces de la unidad base del sistema internacional dividido por la potencia de la unidad base del sistema internacional por el segundo al exponente 2.)'},
    { tipo: 15, subtipo: 3, id: 'MSK', description: 'Metro por segundo cuadrado (Es la aceleración de un cuerpo, animado de movimiento uniformemente variado, cuya velocidad varía cada segundo, 1 m/s. )'},
    { tipo: 15, subtipo: 3, id: 'C11', description: 'Miligalón'},
    { tipo: 15, subtipo: 3, id: 'M41', description: 'Milímetro por segundo cuadrado (0.001 veces de unidad de base del sistema internacional, dividido por la potencia de la unidad de base del sistema internacional por el segundo exponente 2.)'},
    { tipo: 15, subtipo: 3, id: 'M42', description: 'Milla (milla estatal) por segundo cuadrado (Unidad de la longitud según el sistema Imperial de unidades dividido por la potencia de la unidad base del sistema internacional por segundo por el exponente 2.)'},
    { tipo: 15, subtipo: 3, id: 'A73', description: 'Pie por segundo al cuadrado'},
    { tipo: 15, subtipo: 3, id: 'IV', description: 'Pulgada por segundo al cuadrado'},
    { tipo: 15, subtipo: 3, id: 'M40', description: 'Yarda por segundo cuadrado (Unidad de longitud de acuerdo con el sistema Anglo-Americano y el sistema imperial de unidades, dividido por la potencia de la unidad del sistema internacional, segundo elevado al exponente 2.)'},
    { tipo: 15, subtipo: 16, id: 'DD', description: 'Grado [unidad de ángulo] (Es el resultado de dividir el ángulo llano en 180 partes iguales.)'},
    { tipo: 15, subtipo: 16, id: 'A91', description: 'Grado centesimal (Sinónimo: grado El grado centesimal o gon —también llamado gradián (plural: gradianes) y gonio — es una unidad de medida de ángulos planos, alternativa al grado sexagesimal y al radián.)'},
    { tipo: 15, subtipo: 16, id: 'B97', description: 'Microrradián (Unidad de distancia angular igual a una milésima de milliradian. Unidad angular - una unidad de medida para ángulos.)'},
    { tipo: 15, subtipo: 16, id: 'M43', description: 'Mil (unidad de Medida Militar) (Unidad para indicar un ángulo en la zona militar, igual a la 6400ª parte del círculo completo del 360° ó 2 pi radian.)'},
    { tipo: 15, subtipo: 16, id: 'C25', description: 'Milirradián (Es una unidad derivada sistema internacional para la medición angular , que se define como una milésima parte de un radián (0,001 radianes).)'},
    { tipo: 15, subtipo: 16, id: 'D61', description: 'Minuto [unidad de ángulo] (Es una sexagesima parte de un grado.)'},
    { tipo: 15, subtipo: 16, id: 'C81', description: 'Radián (Es la unidad de medida de un ángulo con vértice en el centro de un círculo cuyos lados son cortados por el arco de la circunferencia, y que además dicho arco tiene una longitud igual a la del radio.)'},
    { tipo: 15, subtipo: 16, id: 'M44', description: 'Revolución (Unidad para identificar un ángulo de un círculo de 360 grados ó 2*pi*radio (Referencia ISO/TC12 SI Guía).)'},
    { tipo: 15, subtipo: 16, id: 'D62', description: 'Segundo [unidad de ángulo] (Es una unidad de medida angular. Su valor equivale a 1/60 del minuto de arco y a 1/3600 del grado sexagesimal.)'},
    { tipo: 15, subtipo: 19, id: 'D27', description: 'Estereorradían (Es la unidad que mide angulos solidos)'},
    { tipo: 15, subtipo: 19, id: 'H27', description: 'Grado por metro'},
    { tipo: 15, subtipo: 19, id: 'E96', description: 'Grado por segundo'},
    { tipo: 15, subtipo: 19, id: 'M55', description: 'Metro por radián (Unidad del factor de conversión para la implementación de rotación a movimiento lineal.)'},
    { tipo: 15, subtipo: 19, id: 'H57', description: 'Pulgada por dos pi por radián'},
    { tipo: 15, subtipo: 20, id: 'ACR', description: 'Acre (Es una medida de superficie, usada en agricultura en varios países.)'},
    { tipo: 15, subtipo: 20, id: 'ARE', description: 'Are (Sinónimo: decametro cuadrado)'},
    { tipo: 15, subtipo: 20, id: 'CMK', description: 'Centímetro cuadrado (Medida de longitud, de símbolo cm, que es igual a la centésima parte de un metro.)'},
    { tipo: 15, subtipo: 20, id: 'M47', description: 'Circular Mil (Unidad que representa el área del círculo de un mil de diámetro.)'},
    { tipo: 15, subtipo: 20, id: 'H16', description: 'Decámetro cuadrado'},
    { tipo: 15, subtipo: 20, id: 'DAA', description: 'Decar'},
    { tipo: 15, subtipo: 20, id: 'DMK', description: 'Decímetro cuadrado (Medida de longitud, de símbolo dm, que es igual a la décima parte de un metro.)'},
    { tipo: 15, subtipo: 20, id: 'HAR', description: 'hectárea (Sinónimo: hectómetro cuadrado)'},
    { tipo: 15, subtipo: 20, id: 'H18', description: 'Hectómetro cuadrado (Sinónimo: hectárea)'},
    { tipo: 15, subtipo: 20, id: 'KMK', description: 'Kilómetro cuadrado (Es la unidad de superficie de área que se corresponde con un cuadrado de un kilómetro de lado.)'},
    { tipo: 15, subtipo: 20, id: 'MTK', description: 'Metro cuadrado (Es la unidad básica de superficie en el Sistema Internacional de Unidades. Si a esta unidad se antepone un prefijo del Sistema Internacional se crea un múltiplo o submúltiplo de esta.)'},
    { tipo: 15, subtipo: 20, id: 'H59', description: 'Metro cuadrado por newton'},
    { tipo: 15, subtipo: 20, id: 'H30', description: 'Micrómetro cuadrado'},
    { tipo: 15, subtipo: 20, id: 'MMK', description: 'Milímetro cuadrado (Medida de longitud, de símbolo mm, que es igual a la milésima parte de un metro.)'},
    { tipo: 15, subtipo: 20, id: 'M48', description: 'Milla cuadrada (basado en u.s survey foot) (Unidad de área que es principalmente usada en la agricultura y en la forestación.)'},
    { tipo: 15, subtipo: 20, id: 'MIK', description: 'Milla cuadrada (milla estatal) ( Es una unidad de superficie equivalente a un cuadrado cuyos lados miden una milla estatutaria.)'},
    { tipo: 15, subtipo: 20, id: 'FTK', description: 'Pie cuadrado ( Es una unidad de superficie del sistema anglosajón de unidades, equivalente a un cuadrado de un pie de lado.)'},
    { tipo: 15, subtipo: 20, id: 'INK', description: 'Pulgada cuadrada (Es una unidad de medida imperial, equivalente a la superficie de un cuadrado cuyo lado posee 1 pulgada de longitud (2,54 centímetros).)'},
    { tipo: 15, subtipo: 20, id: 'YDK', description: 'Yarda cuadrada (Es una unidad anglosajona de superficie de una yarda de lado. )'},
    { tipo: 15, subtipo: 7, id: 'C92', description: 'Reciprocidad Metro'},
    { tipo: 15, subtipo: 215, id: 'A11', description: 'Ángstrom (El ángstrom es una unidad de longitud empleada principalmente para expresar longitudes de onda, distancias moleculares y atómicas, etc.)'},
    { tipo: 15, subtipo: 215, id: 'B57', description: 'Año luz (Unidad de longitud que considera la distancia a la que viaja la luz en el vacío en un año.)'},
    { tipo: 15, subtipo: 215, id: 'AK', description: 'Braza (Una braza es una unidad de longitud náutica, usada generalmente para medir la profundidad del agua. Se llama braza porque equivale a la longitud de un par de brazos extendidos, aproximadamente dos metros, ó 6 pies en el sistema de medición estadounidense. Actualmente es considerada arcaica e imprecisa.)'},
    { tipo: 15, subtipo: 215, id: 'M49', description: 'Cadena ( Es una unidad de longitud utilizada para medir terrenos. Equivale a 20,1168 metros, y en inglés se llama surveyor chain.)'},
    { tipo: 15, subtipo: 215, id: 'X1', description: 'Cadena de gunter (Empleada para medir distancias, formada por 100 eslabones metálicos, equivalentes a una longitud total de 20.1168 metros (66 pies) que aún es ampliamente usada en los países anglosajones. )'},
    { tipo: 15, subtipo: 215, id: 'AWG', description: 'Calibre de alambre americano (También conocido como el calibre del cable Brown & Sharpe , es un estandarizado calibre del cable sistema utilizado desde 1857 predominantemente en Norteamérica para los diámetros de vuelta, no ferroso,, sólido eléctricamente conductor de alambre.)'},
    { tipo: 15, subtipo: 215, id: 'CMT', description: 'Centímetro (Es una unidad de longitud. Es el segundo submúltiplo del metro y equivale a la centésima parte de él.)'},
    { tipo: 15, subtipo: 215, id: 'G04', description: 'Centímetro por bar'},
    { tipo: 15, subtipo: 215, id: 'F51', description: 'Centímetro por kelvin'},
    { tipo: 15, subtipo: 215, id: 'A45', description: 'Decámetro (Medida de longitud, de símbolo dam o Dm, que es igual a 10 metros.)'},
    { tipo: 15, subtipo: 215, id: 'DMT', description: 'Decímetro (Es una unidad de longitud. Es el primer submúltiplo del metro y equivale a la décima parte de él.)'},
    { tipo: 15, subtipo: 215, id: 'H79', description: 'Escala francesa (Unidad de longitud usada para la medición de los diámetros de pequeños tubos como instrumentos urológicos y catéteres. Sinónimos: Francés, Charrière, Charrière gauge.)'},
    { tipo: 15, subtipo: 215, id: 'M50', description: 'Estadio (Unidad comunmente usada en Gran Bretaña en distancias rurales: 1 furlong = 40 rods = 10 cadenas (UK) =1/8 de milla = 1/10 furlong = 220 yardas =660 pies.)'},
    { tipo: 15, subtipo: 215, id: 'A71', description: 'Femtómetro (Es la unidad de longitud que equivale a una milbillónesima parte del metro.)'},
    { tipo: 15, subtipo: 215, id: 'H84', description: 'Gramos milímetro'},
    { tipo: 15, subtipo: 215, id: 'HMT', description: 'Hectómetro (Es una unidad de longitud. Equivale a 100 metros. Hecto es el prefijo para 100 en el Sistema Internacional de Unidades.)'},
    { tipo: 15, subtipo: 215, id: 'KMT', description: 'Kilómetro (Es una unidad de longitud. Es el tercer múltiplo del metro, equivalente a 1000 metros. Su símbolo es km y no acepta plural ni lleva punto final —excepto cuando se encuentra como último elemento de una frase u oración— por no tratarse de una abreviatura.)'},
    { tipo: 15, subtipo: 215, id: 'MAM', description: 'Megametro (Es la unidad de longitud que equivale a un millón de metros.)'},
    { tipo: 15, subtipo: 215, id: 'MTR', description: 'Metro (El metro (símbolo m) es la principal unidad de longitud del Sistema Internacional de Unidades. Un metro es la distancia que recorre la luz en el vacío en un intervalo de 1/299 792 458 de segundo.)'},
    { tipo: 15, subtipo: 215, id: 'G05', description: 'Metro por bar'},
    { tipo: 15, subtipo: 215, id: 'F52', description: 'Metro por kelvin'},
    { tipo: 15, subtipo: 215, id: 'M53', description: 'Metro por pascal (Metro, unidad del Sistema Internacional dividido por Pascal, Unidad del Sistema Internacional)'},
    { tipo: 15, subtipo: 215, id: '4H', description: 'Micra (El micrómetro, micrón o micra es una unidad de longitud equivalente a una milésima parte de un milímetro.)'},
    { tipo: 15, subtipo: 215, id: 'F50', description: 'Micrómetro por kelvin'},
    { tipo: 15, subtipo: 215, id: 'M7', description: 'Micro-pulgada'},
    { tipo: 15, subtipo: 215, id: 'MMT', description: 'Milímetro (Es una unidad de longitud. Es el tercer submúltiplo del metro y equivale a la milésima parte de él. )'},
    { tipo: 15, subtipo: 215, id: 'G06', description: 'Milímetro por bar'},
    { tipo: 15, subtipo: 215, id: '77', description: 'Mili-pulgada'},
    { tipo: 15, subtipo: 215, id: 'M52', description: 'Milla (Unidad comunmente usada en los Estados Unidos para la inspección de artillería.)'},
    { tipo: 15, subtipo: 215, id: 'SMI', description: 'Milla (milla estatal) (Medida de longitud, especialmente utilizada en marina, que equivale a 1,852 m.)'},
    { tipo: 15, subtipo: 215, id: 'NMI', description: 'Milla náutica (Es una unidad de longitud empleada en navegación marítima y aérea.)'},
    { tipo: 15, subtipo: 215, id: 'C45', description: 'Nanómetro (Medida de longitud que equivale a la milmillonésima parte del metro.)'},
    { tipo: 15, subtipo: 215, id: 'C63', description: 'Pársec (Es una unidad de medida astronómica bastante utilizada para calcular grandes distancias entre objetos. )'},
    { tipo: 15, subtipo: 215, id: 'C52', description: 'Picómetro (Es una unidad de longitud del SI que equivale a una billonésima (0,000 000 000 001 o 1×10-12) parte de un metro.) '},
    { tipo: 15, subtipo: 215, id: 'FOT', description: 'Pie (Unidad de medida empleada por aeronautas, equivale a 30.48 cm)'},
    { tipo: 15, subtipo: 215, id: 'M51', description: 'Pie (Topografía UEA) (Unidad comunmente usada en los estados unidos para la inspección de artillería.)'},
    { tipo: 15, subtipo: 215, id: 'K13', description: 'Pie por grado fahrenheit'},
    { tipo: 15, subtipo: 215, id: 'K17', description: 'Pie por psi (libra por pulgada cuadrada)'},
    { tipo: 15, subtipo: 215, id: 'INH', description: 'Pulgada (Es una unidad de longitud antropométrica que equivale al ancho de la primera falange del pulgar, y más específicamente a su falange distal.)'},
    { tipo: 15, subtipo: 215, id: 'K45', description: 'Pulgada por grado fahrenheit'},
    { tipo: 15, subtipo: 215, id: 'K46', description: 'Pulgada por psi (libra por pulgada cuadrada)'},
    { tipo: 15, subtipo: 215, id: 'F49', description: 'Rod (Unidad de distancia) (Unidad de distancia equivalente a 5.5 yardas (16 pies 6 pulgadas))'},
    { tipo: 15, subtipo: 215, id: 'A12', description: 'Unidad astronómica (Es una unidad de longitud igual por definición a 149 597 870 700 metros, y que equivale aproximadamente a la distancia media entre el planeta Tierra y el Sol.)'},
    { tipo: 15, subtipo: 215, id: 'YRD', description: 'Yarda (Es la unidad de longitud básica en los sistemas de medida utilizados en Estados Unidos, Panamá y Reino Unido. Equivale a 91.4 centímetros.)'},
    { tipo: 15, subtipo: 215, id: 'L98', description: 'Yarda por grado fahrenheit'},
    { tipo: 15, subtipo: 215, id: 'L99', description: 'Yarda por psi (libra por pulgada cuadrada)'},
    { tipo: 15, subtipo: 340, id: 'J87', description: 'Centímetro cúbico por metro cúbico'},
    { tipo: 15, subtipo: 340, id: 'J91', description: 'Decímetro cúbico por metro cúbico'},
    { tipo: 15, subtipo: 340, id: 'K62', description: 'Litro por litro'},
    { tipo: 15, subtipo: 340, id: 'H60', description: 'Metro cúbico por metro cúbico'},
    { tipo: 15, subtipo: 340, id: 'J36', description: 'Microlitro por litro'},
    { tipo: 15, subtipo: 340, id: 'L19', description: 'Mililitro por litro'},
    { tipo: 15, subtipo: 340, id: 'L21', description: 'Milimetro cúbico por metro cúbico'},
    { tipo: 15, subtipo: 385, id: 'ANN', description: 'Año (Unidad de tiempo equivalente a 365.25 días. )'},
    { tipo: 15, subtipo: 385, id: 'L95', description: 'Año común (Es un año civil de 365 días, uno menos que el año bisiesto, por lo que tiene exactamente 52 semanas y un día. El reparto de los años civiles entre años comunes y años bisiestos se hace de acuerdo con el calendario gregoriano: en cada período de 400 años hay 303 comunes y 97 bisiestos.)'},
    { tipo: 15, subtipo: 385, id: 'L96', description: 'Año sideral (Es el tiempo que trascurre entre dos pasos consecutivos de la Tierra por un mismo punto de su órbita, tomando como referencia a las estrellas.)'},
    { tipo: 15, subtipo: 385, id: 'D42', description: 'Año tropical (Unidad de tiempo equivalente a 365.242 19 días. Sinónimo: año solar.)'},
    { tipo: 15, subtipo: 385, id: 'DAY', description: 'Día (Se denomina día (del latín dies) al lapso que tarda la Tierra desde que el Sol está en el punto más alto sobre el horizonte hasta que vuelve a estarlo.)'},
    { tipo: 15, subtipo: 385, id: 'HUR', description: 'Hora (Es una unidad de tiempo que se corresponde con la vigésimo-cuarta parte de un día solar medio.)'},
    { tipo: 15, subtipo: 385, id: 'B52', description: 'Kilosegundo'},
    { tipo: 15, subtipo: 385, id: 'MON', description: 'Mes (Unidad de tiempo equivalente a 1/12 de año de 365.25 días.)'},
    { tipo: 15, subtipo: 385, id: 'B98', description: 'Microsegundo'},
    { tipo: 15, subtipo: 385, id: 'C26', description: 'Milisegundo'},
    { tipo: 15, subtipo: 385, id: 'MIN', description: 'Minuto [unidad de tiempo] (Es una unidad de tiempo que equivale a la sexagésima parte de una hora. También comprende 60 segundos. Su símbolo es min (adviértase que no es una abreviatura: no admite mayúscula, ni punto, ni plural). )'},
    { tipo: 15, subtipo: 385, id: 'C47', description: 'Nanosegundo'},
    { tipo: 15, subtipo: 385, id: 'H70', description: 'Picosegundo'},
    { tipo: 15, subtipo: 385, id: 'SEC', description: 'Segundo [unidad de tiempo] (Supone comúnmente una sesentava parte de un minuto (1/60) y es esencial para la medición en múltiples sistemas de unidades. )'},
    { tipo: 15, subtipo: 385, id: 'WEE', description: 'Semana (Es el período de tiempo estándar utilizado para los ciclos de días de trabajo y de descanso en la mayoría de las partes del mundo.)'},
    { tipo: 15, subtipo: 385, id: 'M56', description: 'Shake (Unidad para un periódo muy corto.)'},
    { tipo: 15, subtipo: 406, id: '2A', description: 'Radián por segundo (Es la velocidad de un cuerpo que, con una rotación uniforme alrededor de un eje fijo, gira en 1 segundo, 1 radián. )'},
    { tipo: 15, subtipo: 406, id: 'M46', description: 'Revolución por minuto'},
    { tipo: 15, subtipo: 411, id: 'H49', description: 'Centímetro por hora'},
    { tipo: 15, subtipo: 411, id: '2M', description: 'Centímetro por segundo'},
    { tipo: 15, subtipo: 411, id: 'J85', description: 'Centímetro por segundo bar'},
    { tipo: 15, subtipo: 411, id: 'J84', description: 'Centímetro por segundo kelvin'},
    { tipo: 15, subtipo: 411, id: 'KMH', description: 'Kilómetro por hora'},
    { tipo: 15, subtipo: 411, id: 'M62', description: 'Kilómetro por segundo (1000 veces del medidor de unidad de base SI dividido por la segunda unidad de base SI.)'},
    { tipo: 15, subtipo: 411, id: 'M60', description: 'Metro por hora (Metro,unidad del sistema internacional dividido por la unidad de hora.)'},
    { tipo: 15, subtipo: 411, id: '2X', description: 'Metro por minuto'},
    { tipo: 15, subtipo: 411, id: 'MTS', description: 'Metro por segundo (Es la velocidad de un cuerpo que, con movimiento uniforme, recorre, una longitud de un metro en 1 segundo .)'},
    { tipo: 15, subtipo: 411, id: 'L13', description: 'Metro por segundo bar'},
    { tipo: 15, subtipo: 411, id: 'L12', description: 'Metro por segundo kelvin'},
    { tipo: 15, subtipo: 411, id: 'M59', description: 'Metro por segundo pascal (Metro dividido por el producto de SI unidad base segundo y el derivado unidad SI pascal.)'},
    { tipo: 15, subtipo: 411, id: 'H66', description: 'Milímetro por año'},
    { tipo: 15, subtipo: 411, id: 'M22', description: 'Milímetro por centímetro cuadrado minuto'},
    { tipo: 15, subtipo: 411, id: 'H67', description: 'Milímetro por hora'},
    { tipo: 15, subtipo: 411, id: 'H81', description: 'Milímetro por minuto'},
    { tipo: 15, subtipo: 411, id: 'C16', description: 'Milímetro por segundo'},
    { tipo: 15, subtipo: 411, id: 'HM', description: 'Milla por hora (milla estatal)'},
    { tipo: 15, subtipo: 411, id: 'M57', description: 'Milla por minuto (Unidad de velocidad del sistema inglés de unidades.)'},
    { tipo: 15, subtipo: 411, id: 'M58', description: 'Milla por segundo (Unidad de velocidad del sistema inglés de unidades.)'},
    { tipo: 15, subtipo: 411, id: 'KNT', description: 'Nudo (El nudo es una medida de velocidad utilizada tanto para navegación marítima como aérea, equivalente a 1852 m/hora. También se utiliza en meteorología para medir la velocidad de los vientos.)'},
    { tipo: 15, subtipo: 411, id: 'K14', description: 'Pie por hora'},
    { tipo: 15, subtipo: 411, id: 'FR', description: 'Pie por minuto'},
    { tipo: 15, subtipo: 411, id: 'FS', description: 'Pie por segundo'},
    { tipo: 15, subtipo: 411, id: 'K18', description: 'Pie por segundo grado fahrenheit'},
    { tipo: 15, subtipo: 411, id: 'K19', description: 'Pie por segundo psi (libra por pulgada cuadrada)'},
    { tipo: 15, subtipo: 411, id: 'M61', description: 'Pulgada por año (Unidad de longitud según el sistema de unidades Anglo'},
    { tipo: 15, subtipo: 411, id: 'M63', description: 'Pulgada por minuto (Unidad de pulgada de acuerdo con el sistema de unidades Anglo'},
    { tipo: 15, subtipo: 411, id: 'IU', description: 'Pulgada por segundo'},
    { tipo: 15, subtipo: 411, id: 'K47', description: 'Pulgada por segundo grado fahrenheit'},
    { tipo: 15, subtipo: 411, id: 'K48', description: 'Pulgada por segundo psi (libra por pulgada cuadrada)'},
    { tipo: 15, subtipo: 411, id: 'M66', description: 'Yarda por hora (Unidad de yarda de acuerdo con el sistema Anglo'},
    { tipo: 15, subtipo: 411, id: 'M65', description: 'Yarda por minuto (Unidad de yarda de acuerdo con el sistema de unidades Anglo'},
    { tipo: 15, subtipo: 411, id: 'M64', description: 'Yarda por segundo (Unidad de yarda de acuerdo con el sistema de unidades Anglo'},
    { tipo: 15, subtipo: 417, id: 'M67', description: 'Acre'},
    { tipo: 15, subtipo: 417, id: 'BLL', description: 'Barril (EUA) (Un barril estadounidense es igual a: 42 galones estadounidenses 168 cuartos estadounidenses 336 pintas estadounidenses 1344 gills estadounidenses 5376 onzas líquidas estadounidenses 158.987294928 litros)'},
    { tipo: 15, subtipo: 417, id: 'J57', description: 'Barril (uk petróleo) (Es el nombre de varias unidades de volumen usadas en el Reino Unido y en los Estados Unidos.)'},
    { tipo: 15, subtipo: 417, id: 'BLD', description: 'Barril seco (EUA)'},
    { tipo: 15, subtipo: 417, id: 'BUA', description: 'Bushel (EUA) (El bushel es una unidad de medida de capacidad para mercancía sólida en los países anglosajones (países de habla inglesa). Se utiliza en el comercio de granos, harinas y otros productos análogos.)'},
    { tipo: 15, subtipo: 417, id: 'BUI', description: 'Bushel (UK) (Un bushel tiene 4 pecks o 32 quarts, y equivale a 1,03205 del bushel de los Estados Unidos, que a su vez equivale a 0,35238 hectolitros. La palabra bushel está cayendo en desuso rápidamente.)'},
    { tipo: 15, subtipo: 417, id: 'CLT', description: 'Centilitro (Medida de volumen, de símbolo cl, que es igual a la centésima parte de un litro.)'},
    { tipo: 15, subtipo: 417, id: 'CMQ', description: 'Centímetro cúbico (Medida de longitud, de símbolo cm, que es igual a la centésima parte de un metro.)'},
    { tipo: 15, subtipo: 417, id: 'G94', description: 'Centímetro cúbico por bar'},
    { tipo: 15, subtipo: 417, id: 'M68', description: 'Cordón (Unidad tradicional del volumen de leña apilada que se ha medido con un cordón.)'},
    { tipo: 15, subtipo: 417, id: 'QT', description: 'Cuarto (EUA) (Utilice cuarto de galón líquido (código común QTL))'},
    { tipo: 15, subtipo: 417, id: 'QTI', description: 'Cuarto (UK) (Es una unidad de medida de volumen equivalente a media fanega. En Castilla equivalía a aproximadamente a 6 celemines. La medida real cambia según regiones o incluso localidades.)'},
    { tipo: 15, subtipo: 417, id: 'L62', description: 'Cuarto de galón (seco de los EUA) (Utilice cuarto seco (EUA) (código común QTD))'},
    { tipo: 15, subtipo: 417, id: 'QTL', description: 'Cuarto de líquido (estadis unidos)'},
    { tipo: 15, subtipo: 417, id: 'QTD', description: 'Cuarto seco (estados unidos)'},
    { tipo: 15, subtipo: 417, id: 'G24', description: 'Cucharada (estados unidos) (1/2 onzas fluidas, 3 cucharaditas, 15 mililitros)'},
    { tipo: 15, subtipo: 417, id: 'G25', description: 'Cucharilla (estados unidos) (1/6 onzas fluidas o 5 mililitros)'},
    { tipo: 15, subtipo: 417, id: 'A44', description: 'Decalitro'},
    { tipo: 15, subtipo: 417, id: 'DMA', description: 'Decámetro cúbico'},
    { tipo: 15, subtipo: 417, id: 'DLT', description: 'Decilitro'},
    { tipo: 15, subtipo: 417, id: 'DMQ', description: 'Decímetro cúbico (Medida de longitud, de símbolo dm, que es igual a la décima parte de un metro.)'},
    { tipo: 15, subtipo: 417, id: 'G26', description: 'Estere'},
    { tipo: 15, subtipo: 417, id: 'Q32', description: 'Femtolitro'},
    { tipo: 15, subtipo: 417, id: 'GLL', description: 'Galón (EUA) (Es una unidad de volumen que se emplea en los países anglófonos (especialmente Estados Unidos) o con influencia de estos (como Liberia, Guatemala, Panamá, Honduras, Nicaragua, El Salvador, Colombia y parcialmente en México), para medir volúmenes de líquidos, principalmente la gasolina y su precio. Antiguamente, el volumen de un galón dependía de lo que se midiera, y dónde. Sin embargo, en el siglo XIX existían dos definiciones de uso común: "galón de vino" (wine gallon) y "galón de cerveza británico" (ale gallon).)'},
    { tipo: 15, subtipo: 417, id: 'GLI', description: 'Galón (UK) (Es una unidad de volumen que se emplea en los países anglófonos (especialmente Estados Unidos) o con influencia de estos (como Liberia, Guatemala, Panamá, Honduras, Nicaragua, El Salvador, Colombia y parcialmente en México), para medir volúmenes de líquidos, principalmente la gasolina y su precio. Antiguamente, el volumen de un galón dependía de lo que se midiera, y dónde. Sin embargo, en el siglo XIX existían dos definiciones de uso común: "galón de vino" (wine gallon) y "galón de cerveza británico" (ale gallon). Es equivalente a 3.7854 litros.)'},
    { tipo: 15, subtipo: 417, id: 'GLD', description: 'Galón seco (EUA)'},
    { tipo: 15, subtipo: 417, id: 'HLT', description: 'Hectolitro (Es una unidad de volumen equivalente a cien litros, representado por el símbolo hl. Es el segundo múltiplo del litro y también equivale a 100 decímetros cúbicos (0,1 metros cúbicos).)'},
    { tipo: 15, subtipo: 417, id: 'H19', description: 'Hectómetro cúbico'},
    { tipo: 15, subtipo: 417, id: 'K6', description: 'Kilolitro'},
    { tipo: 15, subtipo: 417, id: 'H20', description: 'Kilómetro cúbico'},
    { tipo: 15, subtipo: 417, id: 'LTR', description: 'Litro (Es una unidad de volumen equivalente a un decímetro cúbico (1 dm³). Su uso es aceptado en el Sistema Internacional de Unidades (SI), aunque ya no pertenece estrictamente a él.)'},
    { tipo: 15, subtipo: 417, id: 'G95', description: 'Litro por bar'},
    { tipo: 15, subtipo: 417, id: 'MAL', description: 'Megalitro'},
    { tipo: 15, subtipo: 417, id: 'MTQ', description: 'Metro cúbico (Sinónimo: metro cúbico)'},
    { tipo: 15, subtipo: 417, id: 'SM3', description: 'Metro cúbico estándar (Metro cúbico estándar (temperatura 15 ° C y presión 101325 milibares))'},
    { tipo: 15, subtipo: 417, id: 'NM3', description: 'Metro cúbico normalizado (Metros cúbicos normalizados (temperatura 0 ° C y presión 101325 milibares))'},
    { tipo: 15, subtipo: 417, id: 'G96', description: 'Metro cúbico por bar'},
    { tipo: 15, subtipo: 417, id: 'M71', description: 'Metro cúbico por pascal (joules) (Unidad de Energía del sistema internacional, metro elevado al exponente 3 y dividido por la unidad pascal.)'},
    { tipo: 15, subtipo: 417, id: '4G', description: 'Microlitro'},
    { tipo: 15, subtipo: 417, id: 'MLT', description: 'Mililitro (Medida de volumen, de símbolo ml, que es igual a la milésima parte de un litro.)'},
    { tipo: 15, subtipo: 417, id: 'G97', description: 'Mililitro por bar'},
    { tipo: 15, subtipo: 417, id: 'MMQ', description: 'Milímetro cúbico (Medida de longitud, de símbolo mm, que es igual a la milésima parte de un metro.)'},
    { tipo: 15, subtipo: 417, id: 'M69', description: 'Milla cúbica (reinounido)'},
    { tipo: 15, subtipo: 417, id: 'Q34', description: 'Nanolitro'},
    { tipo: 15, subtipo: 417, id: 'OZA', description: 'Onza líquida (estados unidos)'},
    { tipo: 15, subtipo: 417, id: 'OZI', description: 'Onza líquida (UK)'},
    { tipo: 15, subtipo: 417, id: 'G23', description: 'Peck (Es una unidad de medida imperial y habitual en Estados Unidos de volumen seco.)'},
    { tipo: 15, subtipo: 417, id: 'L43', description: 'Peck (UK) (Un peck es una unidad tradicional de medida de volúmenes. Aunque puede ser utilizado para medir seco y líquido, se utiliza generalmente para las materias secas. Es igual a 1/4 bushel, 2 galones, o 8 cuartos de galón.)'},
    { tipo: 15, subtipo: 417, id: 'Q33', description: 'Picolitro'},
    { tipo: 15, subtipo: 417, id: 'FTQ', description: 'Pie cúbico'},
    { tipo: 15, subtipo: 417, id: 'K21', description: 'Pie cúbico por grado fahrenheit'},
    { tipo: 15, subtipo: 417, id: 'K23', description: 'Pie cúbico por psi (libra por pulgada cuadrada)'},
    { tipo: 15, subtipo: 417, id: '5I', description: 'Pies cúbicos estándar (Utilice estándar (código común WSD))'},
    { tipo: 15, subtipo: 417, id: 'PTI', description: 'Pint (uk) (Es una unidad de volumen inglesa en el sistema imperial y los Estados Unidos. La versión imperial usada en el UK es de 20 onzas líquidas y es equivalente a 568,26125 ml, mientras que en los EUA es de 16 onzas líquidas y es equivalente a 473,17647 ml.)'},
    { tipo: 15, subtipo: 417, id: 'L61', description: 'Pinta (US seco) (Utilice pinta seca (código común PTD))'},
    { tipo: 15, subtipo: 417, id: 'PT', description: 'Pinta (US) (Utilice pinta líquida (código común PTL))'},
    { tipo: 15, subtipo: 417, id: 'PTL', description: 'Pinta líquida (estados unidos)'},
    { tipo: 15, subtipo: 417, id: 'PTD', description: 'Pinta seca (estados unidos)'},
    { tipo: 15, subtipo: 417, id: 'INQ', description: 'Pulgada cúbica (Sinónimo: pulgada cuadrada)'},
    { tipo: 15, subtipo: 417, id: 'G21', description: 'Taza (unidad de volumen)'},
    { tipo: 15, subtipo: 417, id: 'L86', description: 'Tonelada (flota estados unidos)'},
    { tipo: 15, subtipo: 417, id: 'L84', description: 'Tonelada (flota UK)'},
    { tipo: 15, subtipo: 417, id: 'M70', description: 'Unidad tradicional de capacidad de carga'},
    { tipo: 15, subtipo: 417, id: 'YDQ', description: 'Yarda cúbica'},
    { tipo: 15, subtipo: 417, id: 'M11', description: 'Yarda cúbica por grado fahrenheit'},
    { tipo: 15, subtipo: 417, id: 'M14', description: 'Yarda cúbica por psi (libra por pulgada cuadrada)'},
    { tipo: 15, subtipo: 420, id: 'G27', description: 'Centímetro cúbico por kelvin'},
    { tipo: 15, subtipo: 420, id: 'G28', description: 'Litro por kelvin'},
    { tipo: 15, subtipo: 420, id: 'G29', description: 'Metro cúbico por kelvin'},
    { tipo: 15, subtipo: 420, id: 'G30', description: 'Mililitro por klevin'},
    { tipo: 16, subtipo: 394, id: '58', description: 'kilogramo neto (Unidad de masa que define el número total de kilogramos después de las deducciones.)'},
    { tipo: 16, subtipo: 394, id: 'Gd', description: 'Barril bruto'},
    { tipo: 16, subtipo: 394, id: 'ND', description: 'Barril neto'},
    { tipo: 16, subtipo: 394, id: 'C77', description: 'Calibre de libra'},
    { tipo: 16, subtipo: 394, id: 'LC', description: 'Centímetro lineal'},
    { tipo: 16, subtipo: 394, id: 'E90', description: 'Centímetro recíproco'},
    { tipo: 16, subtipo: 394, id: 'HKM', description: 'Cien kilogramos, masa neta (Unidad de masa que define el número de cientos de kilogramos de un producto, después de las deducciones.)'},
    { tipo: 16, subtipo: 394, id: 'HDW', description: 'Cien kilogramos, peso seco (Unidad de masa que define el número de cien kilogramos de un producto, sin tener en cuenta el contenido de agua del producto.)'},
    { tipo: 16, subtipo: 394, id: 'JPS', description: 'Cien metros (Unidad de conteo que define el número de longitudes de 100 metros.)'},
    { tipo: 16, subtipo: 394, id: 'CTG', description: 'Contenido en gramos (Unidad de masa que define el número de gramos de un elemento con nombre en un producto.)'},
    { tipo: 16, subtipo: 394, id: 'SQR', description: 'Cuadrado y techado (Unidad de conteo que conteo que define el número de plazas de materiales para techos, medida en múltiplos de 100 pies cuadrados)'},
    { tipo: 16, subtipo: 394, id: 'E91', description: 'Día recíproco'},
    { tipo: 16, subtipo: 394, id: 'TPI', description: 'Dientes por pulgada (El número de dientes por pulgada)'},
    { tipo: 16, subtipo: 394, id: 'ODE', description: 'Equvalente de agotamiento del ozona (Unidad de masa que define el potencial de agotamiento del ozono en kilogramos de un producto en relación con el agotamiento calculado para la sustancia de referencia Triclorofluorometano (CFC'},
    { tipo: 16, subtipo: 394, id: 'FL', description: 'Flake ton (Unidad de masa que define el número de toneladas de una sustancia en copos (escamas: un pequeño fragmento plano).)'},
    { tipo: 16, subtipo: 394, id: 'GN', description: 'Galón bruto'},
    { tipo: 16, subtipo: 394, id: 'PGL', description: 'Galón de prueba (Unidad de volumen igual a un galón de alcoholes de prueba, o su equivalente alcohólico. Se utiliza para medir la concentración de licores alcohólicos destilados, expresada como porcentaje del contenido en alcohol de una mezcla patrón a una temperatura específica.)'},
    { tipo: 16, subtipo: 394, id: 'WG', description: 'Galón de vino (Unidad de volumen igual a 231 pulgadas cúbicas)'},
    { tipo: 16, subtipo: 394, id: 'EQ', description: 'Galón equivalente (Unidad de volumen que define el número de galones de producto producido a partir de concentrado.)'},
    { tipo: 16, subtipo: 394, id: 'NI', description: 'Galón imperial neto'},
    { tipo: 16, subtipo: 394, id: 'NG', description: 'Galón neto (us)'},
    { tipo: 16, subtipo: 394, id: 'GIP', description: 'Grama, incluido el embalaje interior (Unidad de masa que define el número de gramos de un producto, incluyendo sus materiales de envasado interior.)'},
    { tipo: 16, subtipo: 394, id: 'GFI', description: 'Gramo de isótopo fisible (Unidad de masa que define el número de gramos de un isótopo fisible (isótopo fisible: un isótopo cuyo núcleo es capaz de ser dividido cuando se irradia con neutrones de baja energía).)'},
    { tipo: 16, subtipo: 394, id: 'GIC', description: 'Gramo, incluido el contenedor (Unidad de masa que define el número de gramos de un producto, incluyendo su recipiente.)'},
    { tipo: 16, subtipo: 394, id: 'GDW', description: 'Gramo, peso seco (Unidad de masa que define el número de gramos de un producto, sin tener en cuenta el contenido de agua del producto.)'},
    { tipo: 16, subtipo: 394, id: 'HPA', description: 'Hectolitro de alcohol puro (Unidad de volumen igual a cien litros de alcohol puro.)'},
    { tipo: 16, subtipo: 394, id: 'RH', description: 'Hora de funcionamiento (Unidad de tiempo que define el número de horas de operación)'},
    { tipo: 16, subtipo: 394, id: 'LH', description: 'Hora de trabajo (Unidad de tiempo que define el número de horas de trabajo.)'},
    { tipo: 16, subtipo: 394, id: 'OT', description: 'Hora extra (Unidad de tiempo que define el n[umero de horas extras)'},
    { tipo: 16, subtipo: 394, id: 'E4', description: 'Kilo bruto (Unidad de masa que define el número total de kilogramos antes de las deducciones.)'},
    { tipo: 16, subtipo: 394, id: 'W2', description: 'Kilo húmedo (Unidad de masa que define el número de kilogramos de un producto, incluido el contenido de agua del producto.)'},
    { tipo: 16, subtipo: 394, id: 'KNS', description: 'Kilogram sustancia nombrada (Unidad de masa igual a un kilogramo de una sustancia llamada.)'},
    { tipo: 16, subtipo: 394, id: 'KCC', description: 'Kilogramo de cloruro de colina (Unidad de masa igual a mil gramos de cloruro de colina.)'},
    { tipo: 16, subtipo: 394, id: 'KPH', description: 'Kilogramo de hidróxido de potasio (potasa cáustica) (Unidad de masa igual a mil gramos de hidróxido de potasio (potasa cáustica).)'},
    { tipo: 16, subtipo: 394, id: 'KSH', description: 'Kilogramo de hidróxido de sodio (sodio cáustica) (Unidad de masa igual a mil gramos de hidróxido sódico (sosa cáustica).)'},
    { tipo: 16, subtipo: 394, id: 'KMA', description: 'Kilogramo de metilamina (Unidad de masa igual a mil gramos de metilamina.)'},
    { tipo: 16, subtipo: 394, id: 'KNI', description: 'Kilogramo de nitrógeno (Unidad de masa igual a mil gramos de nitrógeno.)'},
    { tipo: 16, subtipo: 394, id: 'KPO', description: 'Kilogramo de óxido de potasio (Unidad de masa igual a mil gramos de óxido de potasio.)'},
    { tipo: 16, subtipo: 394, id: 'KPP', description: 'Kilogramo de pentóxido de fósforo (anhídrido fosfórico) (Unidad de masa igual a mil gramos de anhídrido fosfórico de pentóxido de fósforo.)'},
    { tipo: 16, subtipo: 394, id: 'KHY', description: 'Kilogramo de peróxido de hidrógeno (Unidad de masa igual a mil gramos de peróxido de hidrógeno.)'},
    { tipo: 16, subtipo: 394, id: 'KDW', description: 'Kilogramo de peso neto drenado (Unidad de masa que define el número neto de kilogramos de un producto, sin tener en cuenta el contenido líquido del producto.)'},
    { tipo: 16, subtipo: 394, id: 'KSD', description: 'Kilogramo de sustancia 90% seco (Unidad de masa igual a mil gramos de una sustancia llamada 90% seca.)'},
    { tipo: 16, subtipo: 394, id: 'KWO', description: 'Kilogramo de trióxido de tungsteno (A unit of mass equal to one thousand grams of tungsten trioxide.)'},
    { tipo: 16, subtipo: 394, id: 'KUR', description: 'Kilogramo de uranio (Unidad de masa igual a mil gramos de uranio.)'},
    { tipo: 16, subtipo: 394, id: 'KI', description: 'Kilogramo por milímetro de ancho'},
    { tipo: 16, subtipo: 394, id: 'KIC', description: 'Kilogramo, incluyendo el contenedor (Unidad de masa que define el número de kilogramos de un producto, incluido su contenedor.)'},
    { tipo: 16, subtipo: 394, id: 'KIP', description: 'Kilogramo, incluyendo el empaquetado interno (Unidad de masa que define el número de kilogramos de un producto, incluyendo sus materiales de embalaje internos.)'},
    { tipo: 16, subtipo: 394, id: 'MND', description: 'Kilogramo, peso seco (Unidad de masa que define el número de kilogramos de un producto, sin tener en cuenta el contenido de agua del producto.)'},
    { tipo: 16, subtipo: 394, id: 'DMO', description: 'Kiloliter norma (Unidad de volumen que define el número de kilolitros de un producto a una temperatura de 15 grados centígrados, sobre todo en relación con los aceites de hidrocarburos.)'},
    { tipo: 16, subtipo: 394, id: 'D03', description: 'Kilovatio hora por hora (Unidad de energía acumulada en mil vatios durante un período de una hora.)'},
    { tipo: 16, subtipo: 394, id: 'E47', description: 'Kilovatio hora por kelvin (Unidad de consumo de energía expresada como kilovatio hora por kelvin.)'},
    { tipo: 16, subtipo: 394, id: 'E46', description: 'Kilovatio hora por metro cúbico (Unidad de consumo de energía expresada como kilovatio hora por metro cúbico.)'},
    { tipo: 16, subtipo: 394, id: 'C79', description: 'Kilovoltios horas amperios (Unidad de energía acumulada de 1000 amperios voltioios durante un período de una hora.)'},
    { tipo: 16, subtipo: 394, id: 'BPM', description: 'Latidos por minuto (El número de latidos por minuto.)'},
    { tipo: 16, subtipo: 394, id: 'D96', description: 'Libra bruta'},
    { tipo: 16, subtipo: 394, id: 'AP', description: 'Libra de aluminio solamente'},
    { tipo: 16, subtipo: 394, id: 'B3', description: 'Libra de bateo (Unidad de masa que define el número de libras de fibra acolchada.)'},
    { tipo: 16, subtipo: 394, id: 'PE', description: 'Libra equivalente'},
    { tipo: 16, subtipo: 394, id: 'WB', description: 'Libra húmeda (Unidad de masa que define el número de libras de un material, incluyendo el contenido de agua del material )'},
    { tipo: 16, subtipo: 394, id: 'LP', description: 'Libra líquida (Unidad de masa que define el número de libras de una sustancia líquida.)'},
    { tipo: 16, subtipo: 394, id: 'D98', description: 'Libra masiva'},
    { tipo: 16, subtipo: 394, id: 'PW', description: 'Libra por pulgada de ancho'},
    { tipo: 16, subtipo: 394, id: 'DB', description: 'Libra seca (Unidad de masa que define el número de libras de un producto, sin tener en cuenta el contenido de agua del producto.)'},
    { tipo: 16, subtipo: 394, id: 'SD', description: 'Libra sólida'},
    { tipo: 16, subtipo: 394, id: '24', description: 'Libra teórica ( Unidad de masa que define la masa esperada de material expresado como el número de libras.)'},
    { tipo: 16, subtipo: 394, id: 'LPA', description: 'Litro de alcohol puro (Unidad de volumen igual a un litro de alcohol puro.)'},
    { tipo: 16, subtipo: 394, id: 'PFL', description: 'Litro de prueba (Unidad de volumen igual a un litro de alcoholes de prueba, o su equivalente alcohólico. Se utiliza para medir la concentración de licores alcohólicos destilados, expresada como porcentaje del contenido en alcohol de una mezcla patrón a una tempreatura específica.)'},
    { tipo: 16, subtipo: 394, id: 'STL', description: 'Litro estándar (Unidad de volumen que define el número de litros de un producto a una temperatura de 15 grados celsius, especialmente en relación con los aceites de hidrocarburos)'},
    { tipo: 16, subtipo: 394, id: 'NE', description: 'Litro neto'},
    { tipo: 16, subtipo: 394, id: 'E32', description: 'Litros por hora (Unidad de recuento de definir el número de litros por hora.)'},
    { tipo: 16, subtipo: 394, id: 'FBM', description: 'Medidor de fibra (Unidad de longitud que define el número de metros de fibra individual.)'},
    { tipo: 16, subtipo: 394, id: 'D78', description: 'Megajoule por segundo (Unidad de energía acumulada igual a un millón de julios por segundo.)'},
    { tipo: 16, subtipo: 394, id: 'E08', description: 'Megavatios por hertz (Unidad de energía expresada como el cambio de carga en millones de watts que provocará un desplazamiento de frecuencia de un hertz.)'},
    { tipo: 16, subtipo: 394, id: 'MAH', description: 'Megavoltio amperio reactivo hora (Define la cantidad total de potencia reactiva a través de un sistema de potencia.)'},
    { tipo: 16, subtipo: 394, id: 'E07', description: 'Megawatt hora por hora (Unidad de energía acumulado de un millón de vatios durante un período de una hora.)'},
    { tipo: 16, subtipo: 394, id: 'WM', description: 'Mes de trabajo (Unidad de tiempo que define el número de meses de trabajo.)'},
    { tipo: 16, subtipo: 394, id: 'E31', description: 'Metro cuadrado por litro (Unidad de recuento de definir el número de metros cuadrados por litro.)'},
    { tipo: 16, subtipo: 394, id: 'Q36', description: 'Metro cuadrado por metro cúbico (Unidad de la cantidad de superficie por unidad de volumen de un objeto o colección de objetos)'},
    { tipo: 16, subtipo: 394, id: 'D90', description: 'Metro cúbico (neta)'},
    { tipo: 16, subtipo: 394, id: 'LM', description: 'Metro lineal (Unidad de conteo que define el número de metros de longitud de un objeto de ancho uniforme.)'},
    { tipo: 16, subtipo: 394, id: 'MF', description: 'Miligramo por pie cuadrado por lado'},
    { tipo: 16, subtipo: 394, id: 'WW', description: 'Mililitro de agua (Unidad de volumen igual al número de mililitros de agua.)'},
    { tipo: 16, subtipo: 394, id: 'E16', description: 'Millón de btu (ti) por hora (Unidad de potencia igual a un millón de unidades térmicas británicas por hora.)'},
    { tipo: 16, subtipo: 394, id: 'OZ', description: 'Onza AV (Una unidad de medida igual a 1/16 de una libra o aproximadamente 28.3495 gramos (av = avoirdupois). Use onza (código común ONZ).)'},
    { tipo: 16, subtipo: 394, id: 'GY', description: 'Patio grueso'},
    { tipo: 16, subtipo: 394, id: 'E17', description: 'Pie cúbico por segundo (Unidad de volumen igual a un pie cúbico pasa por un punto dado en un periodo de un segundo.)'},
    { tipo: 16, subtipo: 394, id: 'LF', description: 'Pie lineal (Unidad de conteo que define el número de pies (12 pulgadas) de longitud de un objeto de ancho uniforme.)'},
    { tipo: 16, subtipo: 394, id: 'E19', description: 'Ping (Unidad de área equivalente a 3,3 metros cuadrados.)'},
    { tipo: 16, subtipo: 394, id: 'E33', description: 'Por mil pies (Unidad de recuento de definir el número de pies por mil unidades.)'},
    { tipo: 16, subtipo: 394, id: 'PM', description: 'Porcentaje de libra'},
    { tipo: 16, subtipo: 394, id: 'LI', description: 'Pulgada lineal'},
    { tipo: 16, subtipo: 394, id: 'IF', description: 'Pulgadas de agua (Use una pulgada de agua (código común F78))'},
    { tipo: 16, subtipo: 394, id: 'B82', description: 'Pulgadas por pie lineal (Unidad de longitud que define el número de pulgadas por pie lineal.)'},
    { tipo: 16, subtipo: 394, id: 'CTN', description: 'Tonelada de contenido (métrica) (Unidad de masa que define el número de toneladas métricas de un elemento con nombre en un producto.)'},
    { tipo: 16, subtipo: 394, id: 'TSD', description: 'Tonelada de sustancia 90% seca'},
    { tipo: 16, subtipo: 394, id: 'TSH', description: 'Tonelada de vapor por hora'},
    { tipo: 16, subtipo: 394, id: 'WE', description: 'Tonelada húmeda (Unidad de masa que define el número de toneladas de un material, incluyendo el contenido de agua del material.)'},
    { tipo: 16, subtipo: 394, id: 'E5', description: 'Tonelada métrica larga (Utilice ton (UK) o tonelada larga (US) (código común LTN))'},
    { tipo: 16, subtipo: 394, id: 'MD', description: 'Tonelada métrica seca al aire (Unidad de conteo que define el número de toneladas métricas de un producto, sin tener en cuenta el contenido de agua del producto.)'},
    { tipo: 16, subtipo: 394, id: 'LUB', description: 'Tonelada métrica, aceite lubricante (Unidad de masa que define el número de toneladas métricas de aceite lubricante.)'},
    { tipo: 16, subtipo: 394, id: 'TIC', description: 'Tonelada métrica, incluido el contenedor (Unidad de masa que define el número de toneladas métricas de un producto, incluyendo su contenedor)'},
    { tipo: 16, subtipo: 394, id: 'TIP', description: 'Tonelada métrica, incluido el embalaje interior (Unidad de masa que define el número de toneladas metricas de un producto, incluyendo sus materiales de embalaje interior)'},
    { tipo: 16, subtipo: 394, id: 'DT', description: 'Tonelada seca (Unidad de masa que define el número de toneladas de un producto, sin tener en cuenta el contenido de agua del producto.)'},
    { tipo: 16, subtipo: 394, id: 'E28', description: 'Tonelada seca de aire (Unidad de masa que define el número de toneladas de un producto, sin tener en cuenta el contenido de agua del producto.)'},
    { tipo: 16, subtipo: 394, id: '27', description: 'Tonelada teórica (Unidad de masa que define la masa esperada de material, expresada como el número de toneladas.)'},
    { tipo: 16, subtipo: 394, id: '2G', description: 'Voltios CA ( Una unidad de potencial eléctrico en relación a la corriente alterna (CA).)'},
    { tipo: 16, subtipo: 394, id: '2H', description: 'Voltios CD (Unidad de potencial eléctrico en relación con corriente directa (CD).)'},
    { tipo: 16, subtipo: 394, id: 'LY', description: 'Yarda lineal (Unidad de conteo que define el número de unidades de 36 pulgadas en longitud de un objeto de ancho uniforme.)'},
    { tipo: 16, subtipo: 394, id: 'LX', description: 'Yarda lineal por libra'},
    { tipo: 17, subtipo: 116, id: '97', description: 'Diez kilos de tambor'},
    { tipo: 17, subtipo: 116, id: '98', description: 'Quince kilos de tambor'},
    { tipo: 17, subtipo: 123, id: 'XLT', description: 'Lote'},
    { tipo: 17, subtipo: 123, id: 'XNA', description: 'No disponible'},
    { tipo: 17, subtipo: 123, id: 'XUC', description: 'Sin empaque'},
    { tipo: 17, subtipo: 123, id: 'XNE', description: 'Sin empaque o no empaquetado'},
    { tipo: 17, subtipo: 123, id: 'XNF', description: 'Sin empaque o no empaquetado, unidad simple'},
    { tipo: 17, subtipo: 123, id: 'XNG', description: 'Sin empaque o no empaquetado, unidades múltiples'},
    { tipo: 17, subtipo: 124, id: 'XXD', description: 'Bolsa con película de plástico'},
    { tipo: 17, subtipo: 124, id: 'X43', description: 'Bolsa de gran tamaño (Bolsa de tela de plástico o de papel que tiene las dimensiones del pallet sobre el que está construido.)'},
    { tipo: 17, subtipo: 124, id: 'XTT', description: 'Bolsa de mano (Una gran bolsa o cesto.)'},
    { tipo: 17, subtipo: 124, id: 'X5M', description: 'Bolsa de papel'},
    { tipo: 17, subtipo: 124, id: 'XXJ', description: 'Bolsa de papel multi-pared'},
    { tipo: 17, subtipo: 124, id: 'XXK', description: 'Bolsa de papel multi-pared, resistente al agua'},
    { tipo: 17, subtipo: 124, id: 'X44', description: 'Bolsa de plastico (Un tipo de bolsa de plástico, normalmente utilizada para envolver piezas promocionales, publicaciones, muestras de productos y / o catálogos.)'},
    { tipo: 17, subtipo: 124, id: 'XEC', description: 'Bolsa de plástico'},
    { tipo: 17, subtipo: 124, id: 'X5H', description: 'Bolsa de plástico tejido'},
    { tipo: 17, subtipo: 124, id: 'XXB', description: 'Bolsa de tejido plástico a prueba de filtraciones'},
    { tipo: 17, subtipo: 124, id: 'XXC', description: 'Bolsa de tejido plástico resistente al agua'},
    { tipo: 17, subtipo: 124, id: 'XXA', description: 'Bolsa de tejido plástico, sin abrigo interior ni forro'},
    { tipo: 17, subtipo: 124, id: 'XJT', description: 'Bolsa de yute'},
    { tipo: 17, subtipo: 124, id: 'XFB', description: 'Bolsa flexible (Bolsa de contención flexible hecha de plástico, usado típicamente para el transporte de mercancías no peligrosas a granel, que usan contenedores de transporte de tamaño estándar.)'},
    { tipo: 17, subtipo: 124, id: 'XFX', description: 'Bolsa flexible tipo contenedor'},
    { tipo: 17, subtipo: 124, id: 'XZB', description: 'Bolsa grande'},
    { tipo: 17, subtipo: 124, id: 'XJB', description: 'Bolsa jumbo (Bolsa de contención flexible, ampliamente utilizada para almacenamiento, transporte y manipulación de materiales en polvo, escamas o granulares. Típicamente construido a partir de tejido de polipropileno (PP) tejido en forma de bolsas cúbicas.)'},
    { tipo: 17, subtipo: 124, id: 'XPO', description: 'Bolsa pequeña'},
    { tipo: 17, subtipo: 124, id: 'XSH', description: 'Bolsa pequeña hermética'},
    { tipo: 17, subtipo: 124, id: 'X5L', description: 'Bolsa textil'},
    { tipo: 17, subtipo: 124, id: 'XXG', description: 'Bolsa textil a prueba de filtraciones'},
    { tipo: 17, subtipo: 124, id: 'XXH', description: 'Bolsa textil resistente al agua'},
    { tipo: 17, subtipo: 124, id: 'XXF', description: 'Bolsa textil sin capa ni forro interior'},
    { tipo: 17, subtipo: 124, id: 'XBG', description: 'Bolso (Recipiente de material flexible con tapa abierta o cerrada.)'},
    { tipo: 17, subtipo: 124, id: 'XFP', description: 'Caja auxiliar para película fotográfica'},
    { tipo: 17, subtipo: 124, id: 'XZA', description: 'Contenedor de granel intermedio, papel, multi-pared'},
    { tipo: 17, subtipo: 124, id: 'XWS', description: 'Contenedor intermedio para gráneles con película de plástico'},
    { tipo: 17, subtipo: 124, id: 'XWQ', description: 'Contenedor intermedio para gráneles con tejido plástico con revestimiento'},
    { tipo: 17, subtipo: 124, id: 'XWN', description: 'Contenedor intermedio para gráneles con tejido plástico sin capa con revestimiento'},
    { tipo: 17, subtipo: 124, id: 'XWP', description: 'Contenedor intermedio para gráneles con tejido plástico y recubierto'},
    { tipo: 17, subtipo: 124, id: 'XWR', description: 'Contenedor intermedio para gráneles con tejido plástico, revestido y con forro'},
    { tipo: 17, subtipo: 124, id: 'XZC', description: 'Contenedor intermedio para gráneles de papel, multi-pared y resistente al agua'},
    { tipo: 17, subtipo: 124, id: 'XWW', description: 'Contenedor intermedio para gráneles textil con revestimiento'},
    { tipo: 17, subtipo: 124, id: 'XWV', description: 'Contenedor intermedio para gráneles textil recubierto'},
    { tipo: 17, subtipo: 124, id: 'XWX', description: 'Contenedor intermedio para gráneles textil recubierto y con forro'},
    { tipo: 17, subtipo: 124, id: 'XWT', description: 'Contenedor intermedio para gráneles textil sin capa / forro'},
    { tipo: 17, subtipo: 124, id: 'XZR', description: 'Contenedor intermedio para gráneles, compuesto y de plástico flexible para líquidos'},
    { tipo: 17, subtipo: 124, id: 'XZP', description: 'Contenedor intermedio para gráneles, compuesto y de plástico flexible, presurizado'},
    { tipo: 17, subtipo: 124, id: 'XZM', description: 'Contenedor intermedio para gráneles, compuesto y de plástico flexible, sólidos'},
    { tipo: 17, subtipo: 124, id: 'XGY', description: 'Costal de Yute (Hecho de yute, usado para transportar mercancías gruesas, tales como granos, patatas, y otros productos agrícolas.)'},
    { tipo: 17, subtipo: 124, id: 'XCV', description: 'Cubierta'},
    { tipo: 17, subtipo: 124, id: 'XYY', description: 'Empaque compuesto, recipiente de vidrio en paquete de plástico expandible'},
    { tipo: 17, subtipo: 124, id: 'XVP', description: 'Envasado al vacío'},
    { tipo: 17, subtipo: 124, id: 'XIF', description: 'Envase para alimentos (Envase flexible tubular o de piel, posiblemente transparente, a menudo utilizado para contener alimentos (por ejemplo salami).)'},
    { tipo: 17, subtipo: 124, id: 'XSW', description: 'Envoltorio (Mercancías retenidas en una película de plástico transparente que ha sido envuelta alrededor y luego encogido fuertemente a las mercancías.)'},
    { tipo: 17, subtipo: 124, id: 'XBE', description: 'Flejado'},
    { tipo: 17, subtipo: 124, id: 'XSL', description: 'Hoja de deslizamiento (Láminas de plástico duro se utilizan principalmente como la base sobre la cual apilan mercancías para optimizar el espacio dentro de un contenedor. Puede utilizarse como alternativa a un embalaje paletizado.)'},
    { tipo: 17, subtipo: 124, id: 'XCZ', description: 'Lona'},
    { tipo: 17, subtipo: 124, id: 'XSY', description: 'Manga'},
    { tipo: 17, subtipo: 124, id: 'XBH', description: 'Manojo'},
    { tipo: 17, subtipo: 124, id: 'XMB', description: 'Múltiplo de bolsas'},
    { tipo: 17, subtipo: 124, id: 'XBL', description: 'Paca comprimida'},
    { tipo: 17, subtipo: 124, id: 'XBN', description: 'Paca no comprimida'},
    { tipo: 17, subtipo: 124, id: 'XCM', description: 'Paquete de tarjetas (Paquete plano normalmente hecho de cartón desde / hacia el cual el producto es a menudo colgado o unido.)'},
    { tipo: 17, subtipo: 124, id: 'XNT', description: 'Red'},
    { tipo: 17, subtipo: 124, id: 'XNU', description: 'Red de plástico con tubo'},
    { tipo: 17, subtipo: 124, id: 'XRT', description: 'Red Roja (Material de confinamiento hecho de redes de malla roja para retener artículos (por ejemplo árboles).)'},
    { tipo: 17, subtipo: 124, id: 'XNV', description: 'Red textil con tubo'},
    { tipo: 17, subtipo: 124, id: 'XSA', description: 'Saco'},
    { tipo: 17, subtipo: 124, id: 'XMS', description: 'Saco milti-pared'},
    { tipo: 17, subtipo: 124, id: 'XEN', description: 'Sobre'},
    { tipo: 17, subtipo: 124, id: 'XSV', description: 'Sobre de acero'},
    { tipo: 17, subtipo: 124, id: 'XVG', description: 'Tanque de gas (a 1,031 mbar y 15° C)'},
    { tipo: 17, subtipo: 124, id: 'XFE', description: 'Tanque flexible (Contenedor flexible de plástico, usado típicamente para el transporte de cargas no peligrosas a granel usando contenedores de transporte de tamaño estándar.)'},
    { tipo: 17, subtipo: 124, id: 'XMT', description: 'Tapete'},
    { tipo: 17, subtipo: 124, id: 'XTD', description: 'Tubo plegable'},
    { tipo: 17, subtipo: 125, id: 'XCJ', description: 'Ataúd'},
    { tipo: 17, subtipo: 125, id: 'XPL', description: 'Balde'},
    { tipo: 17, subtipo: 125, id: 'XP2', description: 'Charola (Recipiente ancho y abierto, usualmente de metal.)'},
    { tipo: 17, subtipo: 125, id: 'XAJ', description: 'Cono (Contenedor utilizado en el transporte de material lineal como el hilo.)'},
    { tipo: 17, subtipo: 125, id: 'XBJ', description: 'Cubeta'},
    { tipo: 17, subtipo: 125, id: 'XBM', description: 'Cuenco'},
    { tipo: 17, subtipo: 125, id: 'XVQ', description: 'Tanque para Gas licuado (a temperatura / presión anormal)'},
    { tipo: 17, subtipo: 125, id: 'XTB', description: 'Tina'},
    { tipo: 17, subtipo: 125, id: 'XTL', description: 'Tina con tapa'},
    { tipo: 17, subtipo: 125, id: 'XCU', description: 'Vaso'},
    { tipo: 17, subtipo: 126, id: 'XAE', description: 'Aerosol (Una unidad de cuenta que define el número de Aerosoles)'},
    { tipo: 17, subtipo: 126, id: 'XAT', description: 'Atomizador'},
    { tipo: 17, subtipo: 126, id: 'XCK', description: 'Barrica'},
    { tipo: 17, subtipo: 126, id: 'XBA', description: 'Barril'},
    { tipo: 17, subtipo: 126, id: 'X2C', description: 'Barril de madera'},
    { tipo: 17, subtipo: 126, id: 'XQJ', description: 'Barril de madera con cabeza desprendible'},
    { tipo: 17, subtipo: 126, id: 'XQH', description: 'Barril de madera con tapón'},
    { tipo: 17, subtipo: 126, id: 'XKG', description: 'Barrilete'},
    { tipo: 17, subtipo: 126, id: 'XBV', description: 'Botella de bulbo protegido (Recipiente en forma de bulbo de cuello estrecho con material de empaque protector externo.)'},
    { tipo: 17, subtipo: 126, id: 'XWB', description: 'Botella de mimbre'},
    { tipo: 17, subtipo: 126, id: 'XBS', description: 'Botella, no-protegida en forma de bulbo (Recipiente en forma de bulbo de cuello estrecho sin material de empaque protector externo.)'},
    { tipo: 17, subtipo: 126, id: 'XBU', description: 'Butt (Barril de 1/2 tonel)'},
    { tipo: 17, subtipo: 126, id: 'XPH', description: 'Cantaro'},
    { tipo: 17, subtipo: 126, id: 'XVL', description: 'Contenedor para líquidos a granel'},
    { tipo: 17, subtipo: 126, id: 'XDJ', description: 'damajuana o garrafa, no protegido'},
    { tipo: 17, subtipo: 126, id: 'XDP', description: 'damajuana o garrafa, protegido'},
    { tipo: 17, subtipo: 126, id: 'XAL', description: 'Esféra (Recipiente de contención esférico para retener sustancias o artículos.)'},
    { tipo: 17, subtipo: 126, id: 'XFI', description: 'Firkin (Barril de 41 litros)'},
    { tipo: 17, subtipo: 126, id: 'XCO', description: 'Garrafón no protegido'},
    { tipo: 17, subtipo: 126, id: 'XCP', description: 'Garrafón protegido'},
    { tipo: 17, subtipo: 126, id: 'XBF', description: 'Globo no protegido'},
    { tipo: 17, subtipo: 126, id: 'XBP', description: 'Globo protegido'},
    { tipo: 17, subtipo: 126, id: 'XHG', description: 'Hogshead (Barril de 1/4 de tonel)'},
    { tipo: 17, subtipo: 126, id: 'XJG', description: 'Jarra'},
    { tipo: 17, subtipo: 126, id: 'XPT', description: 'Maceta'},
    { tipo: 17, subtipo: 126, id: 'XFL', description: 'Matraz'},
    { tipo: 17, subtipo: 126, id: 'XJR', description: 'Tarro'},
    { tipo: 17, subtipo: 126, id: 'XTI', description: 'Tierce (Barril de 1/6 parte de tonel)'},
    { tipo: 17, subtipo: 126, id: 'XTO', description: 'Tonel (Barril con capacidad para 982 litros)'},
    { tipo: 17, subtipo: 127, id: 'XGU', description: 'Bandeja contenedor para apilar horizontalmente objetos planos (Bandeja para contiene objetos planos apilados uno encima del otro.)'},
    { tipo: 17, subtipo: 127, id: 'XDY', description: 'Bandeja de dos capas sin cubierta y de cartón'},
    { tipo: 17, subtipo: 127, id: 'XDX', description: 'Bandeja de dos capas sin cubierta y de madera'},
    { tipo: 17, subtipo: 127, id: 'XDW', description: 'Bandeja de dos capas sin tapa y con bandeja de plástico'},
    { tipo: 17, subtipo: 127, id: 'XDU', description: 'Bandeja de una capa sin cubierta y poliestireno'},
    { tipo: 17, subtipo: 127, id: 'XDV', description: 'Bandeja de una capa sin cubierta y de cartón'},
    { tipo: 17, subtipo: 127, id: 'XDT', description: 'Bandeja de una capa sin cubierta y de madera'},
    { tipo: 17, subtipo: 127, id: 'XDS', description: 'Bandeja de una capa sin cubierta y de plástico'},
    { tipo: 17, subtipo: 127, id: 'XIL', description: 'Bandeja rígida con tapa y apilable (CEN TS 14482: 2002) (Bandeja rígida apilable empotrada compatible con CEN TS 14482: 2002.)'},
    { tipo: 17, subtipo: 127, id: 'XSE', description: 'Baúl'},
    { tipo: 17, subtipo: 127, id: 'X3A', description: 'Bidón de acero'},
    { tipo: 17, subtipo: 127, id: 'XQL', description: 'Bidón de acero con cabeza desmontable'},
    { tipo: 17, subtipo: 127, id: 'XQK', description: 'Bidón de acero con cabeza no desmontable'},
    { tipo: 17, subtipo: 127, id: 'X3H', description: 'Bidón de plástico'},
    { tipo: 17, subtipo: 127, id: 'XQN', description: 'Bidón de plástico con cabeza extraíble'},
    { tipo: 17, subtipo: 127, id: 'XQM', description: 'Bidón de plástico con cabeza no desmontable'},
    { tipo: 17, subtipo: 127, id: 'XJC', description: 'Bidón rectangular'},
    { tipo: 17, subtipo: 127, id: 'XPU', description: 'Cacerola'},
    { tipo: 17, subtipo: 127, id: 'XBX', description: 'Caja'},
    { tipo: 17, subtipo: 127, id: 'X4C', description: 'Caja de madera natural'},
    { tipo: 17, subtipo: 127, id: 'X4A', description: 'Caja de acero'},
    { tipo: 17, subtipo: 127, id: 'X4B', description: 'Caja de aluminio'},
    { tipo: 17, subtipo: 127, id: 'XLU', description: 'Caja de arrastre (Caja de madera para el transporte y almacenamiento de frutas y verduras.)'},
    { tipo: 17, subtipo: 127, id: 'X4G', description: 'Caja de cartón'},
    { tipo: 17, subtipo: 127, id: 'X4D', description: 'Caja de contrachapado'},
    { tipo: 17, subtipo: 127, id: 'XQQ', description: 'Caja de madera natural con muros a prueba de filtraciones'},
    { tipo: 17, subtipo: 127, id: 'XQP', description: 'Caja de madera natural estándar'},
    { tipo: 17, subtipo: 127, id: 'X4F', description: 'Caja de madera reconstituida'},
    { tipo: 17, subtipo: 127, id: 'X4H', description: 'Caja de plástico'},
    { tipo: 17, subtipo: 127, id: 'XQR', description: 'Caja de plástico expandida'},
    { tipo: 17, subtipo: 127, id: 'XQS', description: 'Caja de plástico sólida'},
    { tipo: 17, subtipo: 127, id: 'XNS', description: 'Caja nido'},
    { tipo: 17, subtipo: 127, id: 'XBW', description: 'Caja para líquidos'},
    { tipo: 17, subtipo: 127, id: 'XTC', description: 'Caja para té'},
    { tipo: 17, subtipo: 127, id: 'XMX', description: 'Caja pequeña de cerillos'},
    { tipo: 17, subtipo: 127, id: 'XDH', description: 'Caja, Según la clasificación de la compañía (CHEP), Eurobox (Caja montada sobre una base de pallet Según la clasificación de la compañía (CHEP).)'},
    { tipo: 17, subtipo: 127, id: 'XDL', description: 'Cajas de plástico'},
    { tipo: 17, subtipo: 127, id: 'XPA', description: 'Cajetilla (Paquete pequeño.)'},
    { tipo: 17, subtipo: 127, id: 'XCR', description: 'Cajón'},
    { tipo: 17, subtipo: 127, id: 'XDK', description: 'Cajón a granel, cartón'},
    { tipo: 17, subtipo: 127, id: 'XSS', description: 'Cajón de acero'},
    { tipo: 17, subtipo: 127, id: 'X8B', description: 'Cajón de madera (Un contenedor de madera, en el que se conservan las mercancías para facilitar su manipulación mecánica durante el transporte y almacenamiento.)'},
    { tipo: 17, subtipo: 127, id: 'XDB', description: 'Cajón de varias capas de madera'},
    { tipo: 17, subtipo: 127, id: 'XFD', description: 'Cajón enmarcado'},
    { tipo: 17, subtipo: 127, id: 'XMA', description: 'Cajón metálico (Caja de contención hecha de metal para retener sustancias o artículos.)'},
    { tipo: 17, subtipo: 127, id: 'XDC', description: 'Cajón multicapa de cartón'},
    { tipo: 17, subtipo: 127, id: 'XDA', description: 'Cajón multicapa de plástico'},
    { tipo: 17, subtipo: 127, id: 'XBC', description: 'Cajón para botellas / Estante para botellas'},
    { tipo: 17, subtipo: 127, id: 'XCB', description: 'Cajón para cerveza'},
    { tipo: 17, subtipo: 127, id: 'XFC', description: 'Cajón para fruta'},
    { tipo: 17, subtipo: 127, id: 'XMC', description: 'Cajón para leche'},
    { tipo: 17, subtipo: 127, id: 'XFO', description: 'Cajón para zapatos'},
    { tipo: 17, subtipo: 127, id: 'XSC', description: 'Cajón poco profundo'},
    { tipo: 17, subtipo: 127, id: 'XCH', description: 'Cajonera'},
    { tipo: 17, subtipo: 127, id: 'XDM', description: 'Cajones a granel de madera'},
    { tipo: 17, subtipo: 127, id: 'XPJ', description: 'Canastilla'},
    { tipo: 17, subtipo: 127, id: 'XSK', description: 'Carcasa esqueleto'},
    { tipo: 17, subtipo: 127, id: 'XCT', description: 'Cartón'},
    { tipo: 17, subtipo: 127, id: 'XBK', description: 'Cesta'},
    { tipo: 17, subtipo: 127, id: 'XHC', description: 'Cesta con asa y de cartón'},
    { tipo: 17, subtipo: 127, id: 'XHB', description: 'Cesta con mango y de madera'},
    { tipo: 17, subtipo: 127, id: 'XHA', description: 'Cesta con mango y de plástico'},
    { tipo: 17, subtipo: 127, id: 'XHR', description: 'Cesto'},
    { tipo: 17, subtipo: 127, id: 'XCE', description: 'Cesto tejido'},
    { tipo: 17, subtipo: 127, id: 'XCF', description: 'Cofre'},
    { tipo: 17, subtipo: 127, id: 'XBI', description: 'Compartimiento'},
    { tipo: 17, subtipo: 127, id: 'XAB', description: 'Contenedor de fibra (Recipiente de contención de fibra, utilizado para retener sustancias o artículos.)'},
    { tipo: 17, subtipo: 127, id: 'XWA', description: 'Contenedor de granel intermedio (Recipiente reutilizable hecho de metal, plástico, textiles, madera o materiales compuestos utilizados para facilitar el transporte de sólidos y líquidos a granel en volúmenes manejables.)'},
    { tipo: 17, subtipo: 127, id: 'XAD', description: 'Contenedor de madera (Recipiente de contención de madera, para retener sustancias o artículos.)'},
    { tipo: 17, subtipo: 127, id: 'XME', description: 'Contenedor de metal (Tipo de caja de contención hecha de metal para retener sustancias o artículos, no especificado de otro modo como equipo de transporte.)'},
    { tipo: 17, subtipo: 127, id: 'XAC', description: 'Contenedor de papel (Recipiente de contención de papel, para retener sustancias o artículos.)'},
    { tipo: 17, subtipo: 127, id: 'XPR', description: 'Contenedor de plástico (Recipiente de contención de plástico para retener sustancias o artículos.)'},
    { tipo: 17, subtipo: 127, id: 'XMW', description: 'Contenedor envuelto en plástico (Recipiente de contención envuelto en plástico para retener sustancias o artículos.)'},
    { tipo: 17, subtipo: 127, id: 'XAA', description: 'Contenedor intermedio para gráneles de plástico rígido'},
    { tipo: 17, subtipo: 127, id: 'XZT', description: 'Contenedor intermedio para gráneles con tablero de fibras'},
    { tipo: 17, subtipo: 127, id: 'XWY', description: 'Contenedor intermedio para gráneles contrachapado con revestimiento interior'},
    { tipo: 17, subtipo: 127, id: 'XWU', description: 'Contenedor intermedio para gráneles de madera natural con forro interior'},
    { tipo: 17, subtipo: 127, id: 'XWZ', description: 'Contenedor intermedio para gráneles de madera reconstituida con revestimiento interior'},
    { tipo: 17, subtipo: 127, id: 'XZV', description: 'Contenedor intermedio para gráneles de metal, distinto del acero'},
    { tipo: 17, subtipo: 127, id: 'XZF', description: 'Contenedor intermedio para gráneles de plástico rígido, autoportante para sólidos'},
    { tipo: 17, subtipo: 127, id: 'XZH', description: 'Contenedor intermedio para gráneles de plástico rígido, autoportante y presurizado'},
    { tipo: 17, subtipo: 127, id: 'XZK', description: 'Contenedor intermedio para gráneles de plástico rígido, autoportante, líquidos'},
    { tipo: 17, subtipo: 127, id: 'XZJ', description: 'Contenedor intermedio para gráneles de plástico rígido, con equipo estructural para líquidos'},
    { tipo: 17, subtipo: 127, id: 'XZD', description: 'Contenedor intermedio para gráneles de plástico rígido, con equipo estructural para sólidos'},
    { tipo: 17, subtipo: 127, id: 'XZG', description: 'Contenedor intermedio para gráneles de plástico rígido, con equipo estructural, presurizado'},
    { tipo: 17, subtipo: 127, id: 'XZU', description: 'Contenedor intermedio para gráneles flexible'},
    { tipo: 17, subtipo: 127, id: 'XWC', description: 'Contenedor intermedio para gráneles y de acero'},
    { tipo: 17, subtipo: 127, id: 'XWK', description: 'Contenedor intermedio para gráneles y de acero para líquido'},
    { tipo: 17, subtipo: 127, id: 'XWG', description: 'Contenedor intermedio para gráneles y de acero presurizado menor a 10 kpa'},
    { tipo: 17, subtipo: 127, id: 'XWD', description: 'Contenedor intermedio para gráneles y de aluminio'},
    { tipo: 17, subtipo: 127, id: 'XWL', description: 'Contenedor intermedio para gráneles y de aluminio para líquido'},
    { tipo: 17, subtipo: 127, id: 'XWH', description: 'Contenedor intermedio para gráneles y de aluminio, presurizado menor a 10 kpa'},
    { tipo: 17, subtipo: 127, id: 'XWF', description: 'Contenedor intermedio para gráneles y de metal'},
    { tipo: 17, subtipo: 127, id: 'XWJ', description: 'Contenedor intermedio para gráneles y de metal con una presión de 10 kpa'},
    { tipo: 17, subtipo: 127, id: 'XWM', description: 'Contenedor intermedio para gráneles y de metal para líquido'},
    { tipo: 17, subtipo: 127, id: 'XZS', description: 'Contenedor intermedio para gráneles, compuesto'},
    { tipo: 17, subtipo: 127, id: 'XZQ', description: 'Contenedor intermedio para gráneles, compuesto y de plástico rígido, líquidos'},
    { tipo: 17, subtipo: 127, id: 'XZN', description: 'Contenedor intermedio para gráneles, compuesto y de plástico rígido, presurizado'},
    { tipo: 17, subtipo: 127, id: 'XZL', description: 'Contenedor intermedio para gráneles, compuesto y de plástico rígido, sólidos'},
    { tipo: 17, subtipo: 127, id: 'XZX', description: 'Contenedor intermedio para gráneles, de contrachapado'},
    { tipo: 17, subtipo: 127, id: 'XZW', description: 'Contenedor intermedio para gráneles, de madera natural'},
    { tipo: 17, subtipo: 127, id: 'XZY', description: 'Contenedor intermedio para gráneles, de madera reconstituida'},
    { tipo: 17, subtipo: 127, id: 'XFT', description: 'Contenedor para alimentos'},
    { tipo: 17, subtipo: 127, id: 'XVR', description: 'Contenedor para sólidos de partículas granulares a granel (Granos)'},
    { tipo: 17, subtipo: 127, id: 'XLV', description: 'Contenedor pequeño (Contenedor de madera o metal, usado para empacar artículos de uso doméstico y personales.)'},
    { tipo: 17, subtipo: 127, id: 'XAI', description: 'Contenedor tipo concha'},
    { tipo: 17, subtipo: 127, id: 'XCG', description: 'Contenedor tipo Jaula'},
    { tipo: 17, subtipo: 127, id: 'XCN', description: 'Contenedor, no especificado como equipo de transporte'},
    { tipo: 17, subtipo: 127, id: 'XPF', description: 'Corral (Pequeño recinto abierto para retener animales.)'},
    { tipo: 17, subtipo: 127, id: 'XYR', description: 'Empaque compuesto, receptáculo de vidrio en caja de aluminio'},
    { tipo: 17, subtipo: 127, id: 'XYP', description: 'Empaque compuesto, receptáculo de vidrio en caja de cajas de acero'},
    { tipo: 17, subtipo: 127, id: 'XYK', description: 'Empaque compuesto, recipiente de plástico en caja de cartón'},
    { tipo: 17, subtipo: 127, id: 'XYF', description: 'Empaque compuesto, recipiente de plástico en caja de madera'},
    { tipo: 17, subtipo: 127, id: 'XYH', description: 'Empaque compuesto, recipiente de plástico en caja de madera contrachapada'},
    { tipo: 17, subtipo: 127, id: 'XYM', description: 'Empaque compuesto, recipiente de plástico en caja de plástico sólido'},
    { tipo: 17, subtipo: 127, id: 'XYB', description: 'Empaque compuesto, recipiente de plástico en cajas de acero'},
    { tipo: 17, subtipo: 127, id: 'XYD', description: 'Empaque compuesto, recipiente de plástico en cajón de aluminio'},
    { tipo: 17, subtipo: 127, id: 'XYX', description: 'Empaque compuesto, recipiente de vidrio en caja de cartón'},
    { tipo: 17, subtipo: 127, id: 'XYS', description: 'Empaque compuesto, recipiente de vidrio en caja de madera'},
    { tipo: 17, subtipo: 127, id: 'Xyv', description: 'Empaque compuesto, recipiente de vidrio en el cesto de mimbre'},
    { tipo: 17, subtipo: 127, id: 'XYZ', description: 'Empaque compuesto, recipiente de vidrio en paquete de plástico sólido'},
    { tipo: 17, subtipo: 127, id: 'XCS', description: 'Estuche'},
    { tipo: 17, subtipo: 127, id: 'X7B', description: 'Estuche de madera (Un estuche de madera para retener sustancias o artículos.)'},
    { tipo: 17, subtipo: 127, id: 'XEI', description: 'Estuche isotérmico'},
    { tipo: 17, subtipo: 127, id: 'X7A', description: 'Estuche para carro (Tipo de contenedor portátil diseñado para almacenar equipo para el transporte en un automóvil.)'},
    { tipo: 17, subtipo: 127, id: 'XEF', description: 'Estuche, con pallet base de cartón'},
    { tipo: 17, subtipo: 127, id: 'XEE', description: 'Estuche, con pallet base de madera'},
    { tipo: 17, subtipo: 127, id: 'XEH', description: 'Estuche, con pallet base de metal'},
    { tipo: 17, subtipo: 127, id: 'XEG', description: 'Estuche, con pallet base de plástico'},
    { tipo: 17, subtipo: 127, id: 'XED', description: 'Estuche, con pallet de base'},
    { tipo: 17, subtipo: 127, id: 'XCI', description: 'Frasco'},
    { tipo: 17, subtipo: 127, id: 'XTN', description: 'Hojalata'},
    { tipo: 17, subtipo: 127, id: 'XCW', description: 'Jaula estilo rodillo'},
    { tipo: 17, subtipo: 127, id: 'XDG', description: 'Jaula, Según la clasificación de la compañía (Commonwealth Handling Equipment Pool (CHEP))'},
    { tipo: 17, subtipo: 127, id: 'XCD', description: 'Lata con mango y boquilla'},
    { tipo: 17, subtipo: 127, id: 'XCA', description: 'Lata rectangular'},
    { tipo: 17, subtipo: 127, id: 'XSU', description: 'Maleta'},
    { tipo: 17, subtipo: 127, id: 'XTR', description: 'Maletero'},
    { tipo: 17, subtipo: 127, id: 'XFR', description: 'Marco'},
    { tipo: 17, subtipo: 127, id: 'XOT', description: 'Octabin (Envase estándar de cartón de grandes dimensiones para almacenar por ejemplo verduras, gránulos de plástico u otros productos secos.)'},
    { tipo: 17, subtipo: 127, id: 'X8A', description: 'Pallet de madera (Plataforma o caja abierta de madera, en la que se conservan las mercancías para facilitar su manejo mecánico durante el transporte y almacenamiento.)'},
    { tipo: 17, subtipo: 127, id: 'XPK', description: 'Paquete (Unidad de empaque estándar.)'},
    { tipo: 17, subtipo: 127, id: 'XIB', description: 'Paquete con pantalla y de cartón'},
    { tipo: 17, subtipo: 127, id: 'XIA', description: 'Paquete con pantalla y de madera'},
    { tipo: 17, subtipo: 127, id: 'XID', description: 'Paquete con pantalla y de metal'},
    { tipo: 17, subtipo: 127, id: 'XIC', description: 'Paquete con pantalla y de plástico'},
    { tipo: 17, subtipo: 127, id: 'XIK', description: 'Paquete de cartón con los agujeros para botellas (Material de empaque realizado en cartón que facilita la separación de botellas individuales de vidrio o plástico.)'},
    { tipo: 17, subtipo: 127, id: 'XIE', description: 'Paquete de mostrador.'},
    { tipo: 17, subtipo: 127, id: 'XIG', description: 'Paquete envuelto en papel'},
    { tipo: 17, subtipo: 127, id: 'XPC', description: 'Paquete postal'},
    { tipo: 17, subtipo: 127, id: 'XMR', description: 'Recipiente de metal (Recipiente de contención de metal para retener sustancias o artículos.)'},
    { tipo: 17, subtipo: 127, id: 'X6H', description: 'Recipiente de plástico, Contenedor compuesto.'},
    { tipo: 17, subtipo: 127, id: 'XGR', description: 'Recipiente de vidrio (Recipiente de contención de vidrio para retener sustancias o artículos.)'},
    { tipo: 17, subtipo: 127, id: 'X6P', description: 'Recipiente de vidrio, Contenedor compuesto.'},
    { tipo: 17, subtipo: 127, id: 'XTK', description: 'Tanque rectangular'},
    { tipo: 17, subtipo: 127, id: 'XUN', description: 'Unidad (Tipo de paquete compuesto de un solo artículo u objeto, no especificado de otro modo como una unidad de equipo de transporte.)'},
    { tipo: 17, subtipo: 127, id: 'XLE', description: 'Valijas (Colección de bolsas, cajas y / o contenedores que contienen objetos personales para un viaje.)'},
    { tipo: 17, subtipo: 128, id: 'XAM', description: 'Ampolleta no protegida'},
    { tipo: 17, subtipo: 128, id: 'XAP', description: 'Ampolleta protegida'},
    { tipo: 17, subtipo: 128, id: 'XJY', description: 'Bidón, cilíndrico'},
    { tipo: 17, subtipo: 128, id: 'XBQ', description: 'Botella cilíndrica protegida (Recipiente de forma cilíndrica de cuello estrecho con material de empaque protector externo.)'},
    { tipo: 17, subtipo: 128, id: 'XBO', description: 'Botella no-protegida y cilíndrica (Recipiente de forma cilíndrica de cuello estrecho sin material de empaque protector externo.)'},
    { tipo: 17, subtipo: 128, id: 'XGB', description: 'Botella para gas (Cilindro metálico de cuello estrecho para la retención de gas licuado o comprimido.)'},
    { tipo: 17, subtipo: 128, id: 'XVO', description: 'Contenedor para sólido de partículas grandes a granel ("nódulos")'},
    { tipo: 17, subtipo: 128, id: 'XGL', description: 'Contenedor tipo galón (Contenedor con una capacidad de un galón.)'},
    { tipo: 17, subtipo: 128, id: 'XYN', description: 'Empaque compuesto, receptáculo de vidrio en tambor de acero'},
    { tipo: 17, subtipo: 128, id: 'XYL', description: 'Empaque compuesto, recipiente de plástico en el tambor de plástico'},
    { tipo: 17, subtipo: 128, id: 'XYA', description: 'Empaque compuesto, recipiente de plástico en tambor de acero'},
    { tipo: 17, subtipo: 128, id: 'XYC', description: 'Empaque compuesto, recipiente de plástico en tambor de aluminio'},
    { tipo: 17, subtipo: 128, id: 'XYJ', description: 'Empaque compuesto, recipiente de plástico en tambor de fibra'},
    { tipo: 17, subtipo: 128, id: 'XYG', description: 'Empaque compuesto, recipiente de plástico en tambor de madera contrachapada'},
    { tipo: 17, subtipo: 128, id: 'XYQ', description: 'Empaque compuesto, recipiente de vidrio en tambor de aluminio'},
    { tipo: 17, subtipo: 128, id: 'XYW', description: 'Empaque compuesto, recipiente de vidrio en tambor de fibra'},
    { tipo: 17, subtipo: 128, id: 'XYT', description: 'Empaque compuesto, recipiente de vidrio en tambor de madera contrachapada'},
    { tipo: 17, subtipo: 128, id: 'XVI', description: 'Frasco pequeño'},
    { tipo: 17, subtipo: 128, id: 'XCX', description: 'Lata cilíndrica'},
    { tipo: 17, subtipo: 128, id: 'XCC', description: 'Mantequera'},
    { tipo: 17, subtipo: 128, id: 'XDR', description: 'Tambor'},
    { tipo: 17, subtipo: 128, id: 'X1D', description: 'Tambor contrachapado'},
    { tipo: 17, subtipo: 128, id: 'XQB', description: 'Tambor de acero con cabeza extraíble'},
    { tipo: 17, subtipo: 128, id: 'X1A', description: 'Tambor de acero'},
    { tipo: 17, subtipo: 128, id: 'XQA', description: 'Tambor de acero con cabeza no desmontable'},
    { tipo: 17, subtipo: 128, id: 'X1B', description: 'Tambor de aluminio'},
    { tipo: 17, subtipo: 128, id: 'XQD', description: 'Tambor de aluminio con cabeza extraíble'},
    { tipo: 17, subtipo: 128, id: 'XQC', description: 'Tambor de aluminio con cabeza no extraíble'},
    { tipo: 17, subtipo: 128, id: 'X1G', description: 'Tambor de fibra'},
    { tipo: 17, subtipo: 128, id: 'XDI', description: 'Tambor de hierro'},
    { tipo: 17, subtipo: 128, id: 'X1w', description: 'Tambor de madera'},
    { tipo: 17, subtipo: 128, id: 'XIH', description: 'Tambor de plástico'},
    { tipo: 17, subtipo: 128, id: 'XQF', description: 'Tambor, plástico con cabeza no desmontable'},
    { tipo: 17, subtipo: 128, id: 'XQG', description: 'Tambor, plástico, cabeza extraíble'},
    { tipo: 17, subtipo: 128, id: 'XVA', description: 'Tanque'},
    { tipo: 17, subtipo: 128, id: 'XTY', description: 'Tanque cilíndrico'},
    { tipo: 17, subtipo: 128, id: 'XTV', description: 'Tubo con boquilla (Tubo de plástico, metal o cartón, provisto de una boquilla, que contiene un producto líquido o semilíquido, por ejemplo silicio.)'},
    { tipo: 17, subtipo: 294, id: 'XBB', description: 'Bobina'},
    { tipo: 17, subtipo: 294, id: 'XAV', description: 'Cápsula'},
    { tipo: 17, subtipo: 294, id: 'XRL', description: 'Carrete (Dispositivo giratorio cilíndrico con un reborde en cada extremo en el que se enrollan los materiales.)'},
    { tipo: 17, subtipo: 294, id: 'XSO', description: 'Carrete pequeño (Contenedor de empaque utilizado en el transporte de artículos tales como alambre, cable, cinta e hilo.)'},
    { tipo: 17, subtipo: 294, id: 'XFW', description: 'Carro de cama plana (Módulo con ruedas en el que las bandejas u otros artículos de forma regular se empacan para fines de transporte.)'},
    { tipo: 17, subtipo: 294, id: 'XCQ', description: 'Cartucho (Paquete que contiene una carga tal como un explosivo propulsor para armas de fuego o tóner de tinta para una impresora.)'},
    { tipo: 17, subtipo: 294, id: 'XB4', description: 'Cinturón (Banda utiliza para retener varios artículos juntos.)'},
    { tipo: 17, subtipo: 294, id: 'XVS', description: 'Contenedor de chatarra a granel (Chatarra suelta o sin empaquetar transportada a granel.)'},
    { tipo: 17, subtipo: 294, id: 'XTG', description: 'Contenedor de tanque genérico (Recipiente especialmente construido para el transporte de líquidos y gases a granel.)'},
    { tipo: 17, subtipo: 294, id: 'X1F', description: 'Contenedor flexible (Contenedor de empaque de construcción flexible.)'},
    { tipo: 17, subtipo: 294, id: 'XDN', description: 'Dispensador'},
    { tipo: 17, subtipo: 294, id: 'XRK', description: 'Estante'},
    { tipo: 17, subtipo: 294, id: 'XRJ', description: 'Estante, Perchero para ropa'},
    { tipo: 17, subtipo: 294, id: 'XHN', description: 'Gancho (Artefacto, cuya forma con un gancho en la parte superior, tiene el propósito de colgar artículos de un carril.)'},
    { tipo: 17, subtipo: 294, id: 'XSD', description: 'Huso'},
    { tipo: 17, subtipo: 294, id: 'XKI', description: 'Kit (Conjunto de piezas) (Conjunto de artículos o implementos utilizados para un propósito específico.)'},
    { tipo: 17, subtipo: 294, id: 'XPX', description: 'Pallet (Plataforma o caja abierta, generalmente de madera, en la que se conservan las mercancías para facilitar el manejo mecánico durante el transporte y almacenamiento.)'},
    { tipo: 17, subtipo: 294, id: 'XPD', description: 'Pallet modular con collares (80cms * 100cms) (Pallet tamaño estándar de dimensiones 80 centímetros por 100 centímetros (cms).)'},
    { tipo: 17, subtipo: 294, id: 'XPE', description: 'Pallet modular con collares (80cms * 120cms) (Pallet tamaño estándar de dimensiones 80 centímetros por 120 centímetros (cms).)'},
    { tipo: 17, subtipo: 294, id: 'XAF', description: 'Pallet modular con collares, 80cms * 60cms (Pallet de tamaño estándar con dimensiones de 80 centímetros por 60 centímetros (cms).)'},
    { tipo: 17, subtipo: 294, id: 'XAG', description: 'Pallet o empaquetado (La carga del pallet se asegura con una película de plástico transparente envuelta alrededor y luego encogido firmemente.)'},
    { tipo: 17, subtipo: 294, id: 'XTW', description: 'Pallet tricapa (Una paleta ligera hecha de cartón ondulado resistente.)'},
    { tipo: 17, subtipo: 294, id: 'XAH', description: 'Pallet, 100cms X 110cms (Pallet tamaño estándar con dimensiones de 100 centímetros por 110 centímetros (cms).)'},
    { tipo: 17, subtipo: 294, id: 'XOD', description: 'Pallet, AS 4068-1993 (Pallet con estándar australiano de dimensiones 115.5 centímetros x 116.5 centímetros.)'},
    { tipo: 17, subtipo: 294, id: 'XPB', description: 'Pallet, Caja combinada y abierta con caja y pallet.'},
    { tipo: 17, subtipo: 294, id: 'XOE', description: 'Pallet, ISO T11 (Pallet con estándar ISO de dimensiones 110 centímetros x 110 centímetros, prevalente en la región Asia-Pacífico.) '},
    { tipo: 17, subtipo: 294, id: 'XOC', description: 'Pallet, Según la clasificación de la compañía (Commonwealth Handling Equipment Pool (CHEP) 100 cm x 120 cm (Pallet de dimensiones 100 centímetros x 120 centímetros.)'},
    { tipo: 17, subtipo: 294, id: 'XOA', description: 'Pallet, Según la clasificación de la compañía (Commonwealth Handling Equipment Pool (CHEP) 40 cm x 60 cm (Pallet de dimensiones 40 centímetros x 60 centímetros.)'},
    { tipo: 17, subtipo: 294, id: 'XOB', description: 'Pallet, Según la clasificación de la compañía (Commonwealth Handling Equipment Pool (CHEP) 80 cm x 120 cm (Pallet de dimensiones 80 centímetros x 120 centímetros.)'},
    { tipo: 17, subtipo: 294, id: 'XVK', description: 'Paquete transportable (Tipo de cajón de madera.)'},
    { tipo: 17, subtipo: 294, id: 'XSI', description: 'Patín (Una plataforma o plataforma móvil baja para facilitar el manejo y transporte de mercancías.)'},
    { tipo: 17, subtipo: 294, id: 'XOF', description: 'Plataforma, peso o dimensión no especificada (Plataforma de carga equivalente del Pallet de dimensiones desconocidas o peso desconocido.)'},
    { tipo: 17, subtipo: 294, id: 'XVN', description: 'Vehículo (Medio de transporte autopropulsado.)'},
    { tipo: 17, subtipo: 367, id: 'XRG', description: 'Anillo'},
    { tipo: 17, subtipo: 367, id: 'XBR', description: 'Barra'},
    { tipo: 17, subtipo: 367, id: 'XBZ', description: 'Barras, con fleje/ agrupados/ armados'},
    { tipo: 17, subtipo: 367, id: 'XLG', description: 'Bitacora'},
    { tipo: 17, subtipo: 367, id: 'XOK', description: 'Bloque (Una pieza sólida de una sustancia dura, tal como granito, que tiene uno o más lados planos.)'},
    { tipo: 17, subtipo: 367, id: 'XCY', description: 'Cilindro'},
    { tipo: 17, subtipo: 367, id: 'XVY', description: 'Contenedor para sólido de partículas finas a granel ("polvos")'},
    { tipo: 17, subtipo: 367, id: 'XCL', description: 'Espiral'},
    { tipo: 17, subtipo: 367, id: 'XTS', description: 'Estructura'},
    { tipo: 17, subtipo: 367, id: 'XSP', description: 'Hoja de empaque de plástico'},
    { tipo: 17, subtipo: 367, id: 'XSM', description: 'Hoja de metal'},
    { tipo: 17, subtipo: 367, id: 'XSZ', description: 'Hojas con fleje/ agrupados/ armados'},
    { tipo: 17, subtipo: 367, id: 'XIN', description: 'Lingote'},
    { tipo: 17, subtipo: 367, id: 'XIZ', description: 'Lingotes con correas/ agrupados'},
    { tipo: 17, subtipo: 367, id: 'XSB', description: 'Losa'},
    { tipo: 17, subtipo: 367, id: 'X8C', description: 'Madera flejada (Piezas sueltas o sin contenedor de madera atadas o envueltas.)'},
    { tipo: 17, subtipo: 367, id: 'XPG', description: 'Placa'},
    { tipo: 17, subtipo: 367, id: 'XPY', description: 'Placas con fleje/ agrupados/ armados'},
    { tipo: 17, subtipo: 367, id: 'XPI', description: 'Pleca'},
    { tipo: 17, subtipo: 367, id: 'XLZ', description: 'Registros con fleje/ agrupados/ armados'},
    { tipo: 17, subtipo: 367, id: 'XRD', description: 'Rod'},
    { tipo: 17, subtipo: 367, id: 'XRO', description: 'Rollo'},
    { tipo: 17, subtipo: 367, id: 'XBT', description: 'Rollo de tela'},
    { tipo: 17, subtipo: 367, id: 'XBD', description: 'Tablero'},
    { tipo: 17, subtipo: 367, id: 'XBY', description: 'Tablero, con fleje/ agrupados/ armados'},
    { tipo: 17, subtipo: 367, id: 'XT1', description: 'Tableta (Artículo suelto o sin empaque en forma de barra, bloque o pieza.)'},
    { tipo: 17, subtipo: 367, id: 'XPN', description: 'Tablón'},
    { tipo: 17, subtipo: 367, id: 'XPZ', description: 'Tablones con fleje/ agrupados/ armados'},
    { tipo: 17, subtipo: 367, id: 'XTU', description: 'Tubo'},
    { tipo: 17, subtipo: 367, id: 'XTZ', description: 'Tubos con fleje/ agrupados/ armados'},
    { tipo: 17, subtipo: 367, id: 'XPV', description: 'Tubos, con fleje/ agrupados/ armados'},
    { tipo: 17, subtipo: 367, id: 'XRZ', description: 'Varillas con fleje/ agrupados/ armados'},
    { tipo: 17, subtipo: 367, id: 'XGI', description: 'Viga'},
    { tipo: 17, subtipo: 367, id: 'XGZ', description: 'Vigas con correas o agrupadas'},
    { tipo: 17, subtipo: 395, id: 'Tu', description: 'Contenedor externo (Tipo de caja de contención que sirve como contenedor de transporte externo, no especificado como equipo de transporte.)'},
    { tipo: 17, subtipo: 395, id: 'DI', description: 'dispensador'},
    { tipo: 17, subtipo: 395, id: 'WR', description: 'Envolver'},
    { tipo: 17, subtipo: 395, id: 'D99', description: 'manga'},
    { tipo: 17, subtipo: 395, id: 'SS', description: 'Medida métrica de hoja'},
    { tipo: 17, subtipo: 395, id: 'RK', description: 'Medida métrica de rollo'},
    { tipo: 17, subtipo: 395, id: 'AW', description: 'Relleno de polvo en viales'},
    { tipo: 17, subtipo: 395, id: 'AR', description: 'supositorio'},
    { tipo: 17, subtipo: 395, id: '16', description: 'Tambor de 115 kilogramos'},
    { tipo: 17, subtipo: 395, id: '17', description: 'Tambor de cien libras'},
    { tipo: 17, subtipo: 395, id: 'TE', description: 'Totalizador'},
    { tipo: 17, subtipo: 395, id: 'D79', description: 'Viga'},
    { tipo: 18, subtipo: 396, id: '21', description: 'Contenedor de cuarenta pies ( Unidad de conteo que define el número de contenedores de transporte que miden 40 pies de longitud.)'},
    { tipo: 18, subtipo: 396, id: '20', description: 'Contenedores de veinte pies ( Unidad de conteo que define el número de contenedores de transporte que miden 20 pies de longitud.)'},
    { tipo: 18, subtipo: 396, id: 'NB', description: 'barcaza'},
    { tipo: 18, subtipo: 396, id: 'TC', description: 'Camión'},
    { tipo: 18, subtipo: 396, id: 'SK', description: 'Camión cisterna con división'},
    { tipo: 18, subtipo: 396, id: 'CCT', description: 'Capacidad de carga en toneladas métricas (Unidad de masa que define la capacidad de carga, expresada como el número de toneladas métricas.)'},
    { tipo: 18, subtipo: 396, id: 'NL', description: 'Carga (Unidad de volumen que define el número de cargas(carga: cantidad de artículos transportados o procesados al mismo tiempo))'},
    { tipo: 18, subtipo: 396, id: 'SX', description: 'Envío (Unidad de conteo que define el número de envíos (embarque: una cantidad de mercancias embarcadas o transportadas))'},
    { tipo: 18, subtipo: 396, id: 'Z1', description: 'Furgoneta'},
    { tipo: 18, subtipo: 396, id: 'JB', description: 'Jumbo'},
    { tipo: 18, subtipo: 396, id: 'RT', description: 'Milla de toneladas de ingresos (Unidad de información tipicamente utilizada para propósitos de facturación, expresada como el número de toneladas en ingresos: una tonelada métrica o un metro cúbico, la que sea mayor) se movio a una distancia de una milla)'},
    { tipo: 18, subtipo: 396, id: 'DPT', description: 'Peso de desplazamiento (Unidad de masa que define el volumen de agua de mar de un barco desplaza, expresada como el número de toneladas.)'},
    { tipo: 18, subtipo: 396, id: 'E3', description: 'remolque'},
    { tipo: 18, subtipo: 396, id: 'GT', description: 'Tonelada bruta (Una unidad de masa igual a 2240 libras. Véase el Convenio internacional sobre arqueo de buques. Sinónimo: ton (UK) o tonelada larga (US) (código común LTN) )'},
    { tipo: 18, subtipo: 396, id: 'A75', description: 'Tonelada de carga (Unidad de información que normalmente se utiliza para la facturación, que se define como sea el número de toneladas métricas o el número de metros cúbicos, lo que sea mayor.)'},
    { tipo: 18, subtipo: 396, id: 'SHT', description: 'Tonelada de envíos (Unidad de masa que define el número de toneladas para el envío.)'},
    { tipo: 18, subtipo: 396, id: 'TAB', description: 'Tonelada de registro bruto (Una unidad de masa igual al total de las imágenes cúbicas antes de las deducciones, donde 1 registro t es igual a 100 pies cúbicos.Véase el Convenio internacional sobre el arqueo de los buques.)'},
    { tipo: 18, subtipo: 396, id: 'NTT', description: 'Tonelada de registro neto (Una unidad de masa igual al total de las imágenes cúbicas después de las deducciones, donde 1 registro t es igual a 100 pies cúbicos. Véase el Convenio internacional sobre el arqueo de los buques.)'},
    { tipo: 18, subtipo: 396, id: 'TKM', description: 'Tonelada kilometro (Unidad de información típicamente usada para propósitos de facturación, expresada como el número de toneladas (toneladas métricas) movidas sobre una distancia de un kilómetro)'},
    { tipo: 18, subtipo: 396, id: 'NT', description: 'Tonelada neta (Unidad de masa equivalente a 2000 libras, ver (US). Convencion Internacional referencia sobre el arqueo de los busques)'},
    { tipo: 18, subtipo: 396, id: 'A43', description: 'Tonelaje de peso muerto (Unidad de masa que define la diferencia entre el peso de un barco cuando está completamente vacío y su peso cuando está completamente cargado, expresado como el número de toneladas.)'},
    { tipo: 18, subtipo: 396, id: 'E22', description: 'Tue (Unidad de recuento para definir el número de unidades equivalentes a veinte pies (TEU) como una medida de la capacidad de carga en contenedores.)'},
    { tipo: 18, subtipo: 396, id: 'NV', description: 'vehículo'},
    { tipo: 19, subtipo: 397, id: 'J38', description: 'Baud (Es Unidad de medida utilizada en telecomunicaciones, que representa el número de símbolos por segundo en un medio de transmisión digital.1 Cada símbolo puede comprender 1 o más bits, dependiendo del esquema de modulación.)'},
    { tipo: 19, subtipo: 397, id: 'A99', description: 'Bit (Unidad de información igual a un dígito binario.)'},
    { tipo: 19, subtipo: 397, id: 'E88', description: 'Bit por metro (Unidad de información igual a 1 bit (dígito binario) por metro.)'},
    { tipo: 19, subtipo: 397, id: 'E89', description: 'Bit por metro cuadrado ( Unidad de información igual a 1 bit (dígito binario) por metro cuadrado.)'},
    { tipo: 19, subtipo: 397, id: 'F01', description: 'Bit por metro cúbico (Unidad de información igual a 1 bit (dígito binario) por metro cúbico.)'},
    { tipo: 19, subtipo: 397, id: 'B10', description: 'Bits por segundo (Unidad de información igual a un dígito binario por segundo.)'},
    { tipo: 19, subtipo: 397, id: 'AD', description: 'Byte (Unidad de información igual a 8 bits.)'},
    { tipo: 19, subtipo: 397, id: 'P93', description: 'Byte por segundo (Son utilizados para expresar la velocidad de transmisión de datos o bit rate. Con frecuencia se usa en forma ambigua como bps, que para el SI significaría "bits por segundos")'},
    { tipo: 19, subtipo: 397, id: 'M0', description: 'cinta magnética'},
    { tipo: 19, subtipo: 397, id: 'Q11', description: 'Erlang (Unidad del valor del mercado de acuerdo con la caracteristica de una sola característica como una medición estadística de la utilización existente )'},
    { tipo: 19, subtipo: 397, id: 'E58', description: 'Exabit por segundo (Unidad de información igual a 10¹8 bits (dígitos binarios) por segundo.)'},
    { tipo: 19, subtipo: 397, id: 'E65', description: 'Exbibit por metro (Unidad de información igual a 26° bits (dígitos binarios) por metro.)'},
    { tipo: 19, subtipo: 397, id: 'E66', description: 'Exbibit por metro cuadrado (Unidad de información igual a 26° bits (dígitos binarios) por metro cuadrado.)'},
    { tipo: 19, subtipo: 397, id: 'E67', description: 'Exbibit por metro cúbico (Unidad de información igual a 26° bits (dígitos binarios) por metro cúbico.)'},
    { tipo: 19, subtipo: 397, id: 'E59', description: 'Exbibyte (Unidad de información igual a 26° bytes.)'},
    { tipo: 19, subtipo: 397, id: 'B30', description: 'Gibibit (Unidad de información igual a 2³° los bits (dígitos binarios).)'},
    { tipo: 19, subtipo: 397, id: 'E69', description: 'Gibibit por metro (Unidad de información igual a 2³° bits (dígitos binarios) por metro.)'},
    { tipo: 19, subtipo: 397, id: 'E70', description: 'Gibibit por metro cuadrado (Unidad de información igual a 2³° bits (dígitos binarios) por metro cuadrado.)'},
    { tipo: 19, subtipo: 397, id: 'E71', description: 'Gibibit por metro cúbico (Unidad de información igual a 2³° bits (dígitos binarios) por metro cúbico.)'},
    { tipo: 19, subtipo: 397, id: 'E62', description: 'Gibibyte (Unidad de información igual a 2³° bytes.)'},
    { tipo: 19, subtipo: 397, id: 'B68', description: 'Gigabit'},
    { tipo: 19, subtipo: 397, id: 'B80', description: 'Gigabit por segundo'},
    { tipo: 19, subtipo: 397, id: 'E34', description: 'Gigabyte'},
    { tipo: 19, subtipo: 397, id: 'E68', description: 'Gigabyte por segundo'},
    { tipo: 19, subtipo: 397, id: 'Q15', description: 'Hartley (Unidad logarítmica para la información igual al contenido de la decisión de una oración de diez sucesos mutuamente excluyentes, expresada como un logaritmo base 10.)'},
    { tipo: 19, subtipo: 397, id: 'Q18', description: 'Hartley por segundo (Unidad logaritmica relacionada con el tiempo para información igual al contenido de la decisión de una oración de diez sucesos mutuamente excluyentes, expresada como un logartimo a la base 10. )'},
    { tipo: 19, subtipo: 397, id: 'C21', description: 'Kibibit (Unidad de información igual a 2¹° (1024) bits (dígitos binarios).)'},
    { tipo: 19, subtipo: 397, id: 'E72', description: 'Kibibit por metro (Unidad de información igual a 2¹° bits (dígitos binarios) por metro.)'},
    { tipo: 19, subtipo: 397, id: 'E73', description: 'Kibibit por metro cuadrado (Unidad de información igual a 2¹° bits (dígitos binarios) por metro cuadrado.)'},
    { tipo: 19, subtipo: 397, id: 'E64', description: 'Kibibyte (Unidad de información igual a 2¹° bytes.)'},
    { tipo: 19, subtipo: 397, id: 'E74', description: 'Kikibit por metro cúbico. ( Unidad de información igual a 2¹° bits (dígitos binarios) por metro cúbico.)'},
    { tipo: 19, subtipo: 397, id: 'K50', description: 'Kilobaud (Unidad de velocidad de transmisión de señal igual a 10³ (1000) eventos de señalización por segundo.)'},
    { tipo: 19, subtipo: 397, id: 'C37', description: 'Kilobits (Unidad de información igual a 10³(1000) bits (dígitos binarios).)'},
    { tipo: 19, subtipo: 397, id: 'C74', description: 'Kilobits por segundo (Unidad de información igual a 10 ³ (1000) bits (dígitos binarios) por segundo.)'},
    { tipo: 19, subtipo: 397, id: '2P', description: 'Kilobyte ( Unidad de información igual a 10 ³ (1000) bytes.)'},
    { tipo: 19, subtipo: 397, id: 'P94', description: 'Kilobyte por segundo (1000-veces la unidad de byte dividida por la unidad de base SI en segundo lugar.)'},
    { tipo: 19, subtipo: 397, id: 'KJ', description: 'Kilosegmento (Unidad de información igual a 10³ (1000) segmentos.)'},
    { tipo: 19, subtipo: 397, id: 'E77', description: 'Mebbit por metro cúbico. ( Unidad de información igual a 2²° bits (dígitos binarios) por metro cúbico.)'},
    { tipo: 19, subtipo: 397, id: 'E75', description: 'Mebbit por metro. ( Unidad de información igual a 2²° bits (dígitos binarios) por metro.)'},
    { tipo: 19, subtipo: 397, id: 'E76', description: 'Mebbitt por metro cuadrado. ( Unidad de información igual a 2²° bits (dígitos binarios) por metro cuadrado.)'},
    { tipo: 19, subtipo: 397, id: 'D11', description: 'Mebibit (Una unidad de información igual a 2²° (1048576) bits (dígitos binarios).)'},
    { tipo: 19, subtipo: 397, id: 'E63', description: 'Mebibyte (Unidad de información igual a 2²° bytes.)'},
    { tipo: 19, subtipo: 397, id: 'J54', description: 'Megabaud (Unidad de velocidad de transmisión de señal igual a 106 (1000000) eventos de señalización por segundo.)'},
    { tipo: 19, subtipo: 397, id: 'D36', description: 'Megabit (unidad que se usa para cuantificar un caudal de datos equivalente a 1000 kb/s.)'},
    { tipo: 19, subtipo: 397, id: 'E20', description: 'Megabit por segundo (Unidad de información igual a 10 6 (1000000) bits (dígitos binarios) por segundo.)'},
    { tipo: 19, subtipo: 397, id: 'P95', description: 'Megabite por segundo (1 000 000 veces de la unidad de byte dividida por la unidad base SI segunda)'},
    { tipo: 19, subtipo: 397, id: '4L', description: 'Megabyte (Unidad que se usa para cuantificar un caudal de datos equivalente a 1000 kb/s.)'},
    { tipo: 19, subtipo: 397, id: 'E38', description: 'Megapíxeles (Unidad de recuento igual a 10 $ 6 $ (1000000) píxeles (elementos de imagen).)'},
    { tipo: 19, subtipo: 397, id: 'Q12', description: 'Octeto (Sinónimo por byte: 1octet =8bit = 1byte)'},
    { tipo: 19, subtipo: 397, id: 'Q13', description: 'Octeto por segundo (Unidad octeto dividido por la unidad base SI segundo)'},
    { tipo: 19, subtipo: 397, id: 'E81', description: 'Pebibit por metro cuadrado. ( Unidad de información igual a 25° bits (dígitos binarios) por metro cuadrado.)'},
    { tipo: 19, subtipo: 397, id: 'E82', description: 'Pebibit por metro cúbico. ( Unidad de información igual a 25° bits (dígitos binarios) por metro cúbico.)'},
    { tipo: 19, subtipo: 397, id: 'E80', description: 'Pebibit por metro. (Unidad de información igual a 25° bits (dígitos binarios) por metro.)'},
    { tipo: 19, subtipo: 397, id: 'E79', description: 'Pebibit por segundo. ( Unidad de información igual a 10¹5 bits (dígitos binarios) por segundo.)'},
    { tipo: 19, subtipo: 397, id: 'E60', description: 'Pebibyte (Unidad de información igual a 25° bytes.)'},
    { tipo: 19, subtipo: 397, id: 'E78', description: 'Petabit ( Unidad de información igual a 10¹5 los bits (dígitos binarios).)'},
    { tipo: 19, subtipo: 397, id: 'E36', description: 'Petabyte (Unidad de información igual a 10¹5 bytes.)'},
    { tipo: 19, subtipo: 397, id: 'E37', description: 'Pixel (Unidad de recuento de definir el número de píxeles (píxel: elemento de imagen).)'},
    { tipo: 19, subtipo: 397, id: 'E39', description: 'Puntos por pulgada (Unidad de información que define el número de puntos por pulgada lineal como una medida de la resolución o nitidez de una imagen gráfica.)'},
    { tipo: 19, subtipo: 397, id: 'DQ', description: 'registro de datos'},
    { tipo: 19, subtipo: 397, id: 'Q14', description: 'Shannon (Unidad logarítmica para la información igual al contenido de la decisión de una oración de dos sucesos mutuamente excluyentes, expresada como un logaritmo a la base 2.)'},
    { tipo: 19, subtipo: 397, id: 'Q17', description: 'Shannon por segundo (Unidad logarítmica relacionada con el tiempo para información igual al contenido de la decisión de una oración de dos sucesos mutuamente excluyentes, expresada como logarítmo base 2.)'},
    { tipo: 19, subtipo: 397, id: 'E87', description: 'Tebibit por metro cuadrado ( Unidad de información igual a 24° bits (dígitos binarios) por metro cuadrado.)'},
    { tipo: 19, subtipo: 397, id: 'E86', description: 'Tebibit por metro cúbico. ( Unidad de información igual a 24° bits (dígitos binarios) por metro cúbico.)'},
    { tipo: 19, subtipo: 397, id: 'E85', description: 'Tebibit por metro. ( Unidad de información igual a 24° bits (dígitos binarios) por metro.)'},
    { tipo: 19, subtipo: 397, id: 'E84', description: 'Tebibit por segundo ( Unidad de información igual a 10¹² bits (dígitos binarios) por segundo.)'},
    { tipo: 19, subtipo: 397, id: 'E83', description: 'Tebibit. ( Unidad de información igual a 10¹² los bits (dígitos binarios).)'},
    { tipo: 19, subtipo: 397, id: 'E61', description: 'Tebibyte (Unidad de información igual a 24° bytes.)'},
    { tipo: 19, subtipo: 397, id: 'E35', description: 'Terabyte (Unidad de información igual a 10¹² bytes.)'},
    { tipo: 19, subtipo: 397, id: 'Q16', description: 'Unidad natural de información (Unidad logarítmica para información igual al contenido de la decisión de una oración de 718 281 828 459 sucesos mutuamente excluyentes, expresada como un logaritmo para basar el valor de Euler e.)'},
    { tipo: 19, subtipo: 397, id: 'Q19', description: 'Unidad natural de información por segundo (Unidad logarítmica relacionada con el tiempo para información igual al contenido de la decisión de una oración 2.718281828459 mutuamente excluyentes, expresado como un logarítmo a la base del valor de Euler)'},
    { tipo: 20, subtipo: 398, id: 'ACT', description: 'Actividad (Unidad de recuento para definir el número de actividades (actividad: una unidad de trabajo o acción).)'},
    { tipo: 20, subtipo: 398, id: 'H21', description: 'Blanco (Unidad de conteo que define el número de blancos)'},
    { tipo: 20, subtipo: 398, id: 'HBX', description: 'Ciento de cajas (Unidad de conteo que define el número de cajas en múltiplos de cien unidades de caja.)'},
    { tipo: 20, subtipo: 398, id: 'SET', description: 'Conjunto (Unidad de conteo que define el número de conjuntos (Conjunto: un número de objetos agrupados))'},
    { tipo: 20, subtipo: 398, id: 'DAD', description: 'Decena de días (Unidad de tiempo que define el número de días en múltiplos de 10.)'},
    { tipo: 20, subtipo: 398, id: 'DZP', description: 'Docena de paquete (Unidad de conteo que define el número de paquetes en múltiplos de 12 (paquete: unidad de embalaje estándar).)'},
    { tipo: 20, subtipo: 398, id: 'DRL', description: 'Docena de rodillos (Unidad de recuento de definir el número de rollos, expresado en doce unidades de rodillos.)'},
    { tipo: 20, subtipo: 398, id: 'DPR', description: 'Docenas de pares (Unidad de recuento de definir el número de pares en múltiplos de 12 (par: ítem descrito por dos de).)'},
    { tipo: 20, subtipo: 398, id: 'DPC', description: 'Docenas de piezas (Unidad de recuento para definir el número de piezas en múltiplos de 12 (pieza: un solo artículo, el artículo o ejemplar).)'},
    { tipo: 20, subtipo: 398, id: 'OP', description: 'Dos paquetes'},
    { tipo: 20, subtipo: 398, id: 'EA', description: 'Elemento (Unidad de conteo que define el número de elementos considerados como unidades separadas.)'},
    { tipo: 20, subtipo: 398, id: 'KT', description: 'Kit (Unidad de conteo que define el número de kits (kit: bañera, barril o cubo).)'},
    { tipo: 20, subtipo: 398, id: 'D04', description: 'Lot [unidad de peso] (Unidad de peso igual a aproximadamente media onza o 15 gramos.)'},
    { tipo: 20, subtipo: 398, id: 'P9', description: 'Nueve paquetes'},
    { tipo: 20, subtipo: 398, id: 'P5', description: 'Paquete de cinco (Unidad de conteo que define el número de paquetes'},
    { tipo: 20, subtipo: 398, id: 'P4', description: 'paquete de cuatro'},
    { tipo: 20, subtipo: 398, id: 'TP', description: 'Paquete de diez (Unidad de conteo que define el número de elementos en múltiplos de 10)'},
    { tipo: 20, subtipo: 398, id: 'P8', description: 'Paquete de ocho'},
    { tipo: 20, subtipo: 398, id: 'EP', description: 'Paquete de once'},
    { tipo: 20, subtipo: 398, id: 'P6', description: 'paquete de seis'},
    { tipo: 20, subtipo: 398, id: 'P7', description: 'Paquete de siete'},
    { tipo: 20, subtipo: 398, id: 'P3', description: 'Tres paquetes'},
    { tipo: 20, subtipo: 398, id: '4E', description: 'Veinte pack'},
    { tipo: 21, subtipo: 399, id: 'B1', description: 'Barril (EUA) por día'},
    { tipo: 21, subtipo: 399, id: 'B4', description: 'Barril, unidad imperial (Unidad de volumen utilizado para medir cerveza. Un barril de cerveza es igual a 36 galones imperiales.)'},
    { tipo: 21, subtipo: 399, id: 'E56', description: 'Bien (Unidad de recuento para definir el número de pozos.)'},
    { tipo: 21, subtipo: 399, id: '5J', description: 'Caballos de potencia hidráulica (Unidad de potencia de la definición de los caballos de potencia hidráulica suministrada por una bomba de fluido dependiendo de la viscosidad del fluido.)'},
    { tipo: 21, subtipo: 399, id: 'HEA', description: 'Cabeza (Unidad de conteo que define el número de cabezas (cabeza: persona o animal considerada como un número.)'},
    { tipo: 21, subtipo: 399, id: 'WCD', description: 'Cable (Unidad de volumen utilizada para medir la madera. Un pie de tabla equivale a 1/2 de un pie cúbico.)'},
    { tipo: 21, subtipo: 399, id: '1D', description: 'Caboose count'},
    { tipo: 21, subtipo: 399, id: 'BB', description: 'Caja base (Una unidad de área de 112 hojas de productos de estaño (placa de estaño, acero libre de estaño o placa negra) 14 por 20 pulgadas o 31,360 pulgadas cuadradas.)'},
    { tipo: 21, subtipo: 399, id: 'NC', description: 'Carro'},
    { tipo: 21, subtipo: 399, id: 'C2', description: 'Carset'},
    { tipo: 21, subtipo: 399, id: 'CNT', description: 'Cental (UK) (Una unidad de masa igual a cien kilos (EUA).)'},
    { tipo: 21, subtipo: 399, id: 'HE', description: 'Centésima de un quilate'},
    { tipo: 21, subtipo: 399, id: '1E', description: 'Coche vacío'},
    { tipo: 21, subtipo: 399, id: '1C', description: 'Conteo de locomotoras'},
    { tipo: 21, subtipo: 399, id: 'QTR', description: 'Cuarto (Unidad tradicional de peso igual a 1/4 de peso. En el Reino Unido 1/4 equivale a 28 libras.)'},
    { tipo: 21, subtipo: 399, id: 'A47', description: 'Decitex (Unidad de densidad de hilo. Un decitex es igual a una masa de 1 gramo por 10 kilómetros de longitud.)'},
    { tipo: 21, subtipo: 399, id: 'K1', description: 'Demanda de kilowatt (Unidad de medida que define la carga de potencia medida a intervalos predeterminados.)'},
    { tipo: 21, subtipo: 399, id: 'E10', description: 'Día de grado (Unidad de medida utilizada en la meteorología y la ingeniería para medir la demanda de calentamiento o enfriamiento durante un período determinado de días.)'},
    { tipo: 21, subtipo: 399, id: 'E49', description: 'Día de trabajo (Unidad de recuento de definir el número de días de trabajo (jornada de trabajo: un día en el que se lleva a cabo normalmente un trabajo).)'},
    { tipo: 21, subtipo: 399, id: 'Q25', description: 'Dioptría (Unidad utilizada en la declaración de índices de refracción relativos de sistemas ópticos como complemento de la longitud focal con correspondencia con: 1dpt=1/m.)'},
    { tipo: 21, subtipo: 399, id: 'DRM', description: 'Drachm (UK)'},
    { tipo: 21, subtipo: 399, id: 'DRA', description: 'Dram (EUA) (Sinónimo: dracma (Reino Unido), dram Troy)'},
    { tipo: 21, subtipo: 399, id: 'DRI', description: 'Dram (UK) (Son las siglas de la voz inglesa Dynamic Random Access Memory, que significa memoria dinámica de acceso aleatorio (o RAM dinámica), para denominar a un tipo de tecnología de memoria RAM basada en condensadores, los cuales pierden su carga progresivamente, necesitando de un circuito dinámico de refresco que, cada cierto período, revisa dicha carga y la repone en un ciclo de refresco.)'},
    { tipo: 21, subtipo: 399, id: 'SCR', description: 'Escrúpulo (El escrúpulo (en inglés apothecary scruple) era Unidad de medida utilizada en farmacia para pesar ingredientes de los medicamentos. El escrúpulo podía tener diferentes valores en cada país: en España y Portugal, un escrúpulo equivalía a 24 granos (pesando 1,55517384 gramos), mientras que en el sistema imperial inglés correspondía a 20 granos (1,2959782 gramos). En astronomía, un escrúpulo es el resultado de dividir en sesenta partes un grado de círculo.)'},
    { tipo: 21, subtipo: 399, id: 'WSD', description: 'Estándar (Unidad de volumen de madera acabada igual a 165 pies cúbicos.)'},
    { tipo: 21, subtipo: 399, id: 'SST', description: 'Estandar corto (7200 partidos)'},
    { tipo: 21, subtipo: 399, id: 'GB', description: 'Galón (EUA) por día'},
    { tipo: 21, subtipo: 399, id: '1G', description: 'Galón del uso del combustible (los EUA)'},
    { tipo: 21, subtipo: 399, id: 'GW', description: 'Galón por mil pies cúbicos'},
    { tipo: 21, subtipo: 399, id: 'A79', description: 'Gaussian CGS (Centímetro'},
    { tipo: 21, subtipo: 399, id: 'A77', description: 'Gaussian CGS (Centímetro'},
    { tipo: 21, subtipo: 399, id: 'A83', description: 'Gaussian CGS (Centímetro'},
    { tipo: 21, subtipo: 399, id: 'A81', description: 'Gaussian CGS (Centímetro'},
    { tipo: 21, subtipo: 399, id: 'A78', description: 'Gaussiano CGS (Centímetro'},
    { tipo: 21, subtipo: 399, id: 'A80', description: 'Gaussiano CGS (Centímetro'},
    { tipo: 21, subtipo: 399, id: 'A82', description: 'Gaussiano CGS (Centímetro'},
    { tipo: 21, subtipo: 399, id: 'E11', description: 'Gigacalorie (Una unidad de energía térmica equivalente a mil millones de calorías.)'},
    { tipo: 21, subtipo: 399, id: 'GII', description: 'Gill (uk) (Es Unidad de volumen inglesa en el sistema imperial y en los Estados Unidos. La versión imperial usada en el UK es de 5 onzas líquidas, y es equivalente a 142.0653125 ml; mientras que en los EUA es de 4 onzas líquidas, y es equivalente a 118.29411825 ml.)'},
    { tipo: 21, subtipo: 399, id: 'GIA', description: 'Gill (us) (Es Unidad de volumen inglesa en el sistema imperial y en los Estados Unidos. La versión imperial usada en el UK es de 5 onzas líquidas, y es equivalente a 142,0653125 ml; mientras que en los EUA es de 4 onzas líquidas, y es equivalente a 118,29411825 ml.)'},
    { tipo: 21, subtipo: 399, id: 'ASM', description: 'Grado alcohólico en masa (Unidad de masa que define el grado alcohólico de un líquido.)'},
    { tipo: 21, subtipo: 399, id: 'ASU', description: 'Grado alcohólico volumétrico (Unidad de volumen que define el grado alcohólico de un líquido (por ejemplo, alcohol, vino, cerveza, etc.), a menudo a una temperatura específica.)'},
    { tipo: 21, subtipo: 399, id: 'J13', description: 'Grado api (Unidad de densidad relativa como una medida de cuán pesado o ligero es un líquido de petróleo comparado con agua (API: American Petroleum Institute).)'},
    { tipo: 21, subtipo: 399, id: 'J17', description: 'Grado balling (Unidad de densidad como medida del contenido de azúcar, especialmente de mosto de cerveza. Nombrado en honor de Karl Balling.)'},
    { tipo: 21, subtipo: 399, id: 'J14', description: 'Grado baume (escala de origen) (Unidad tradicional de densidad relativa para líquidos. Nombrado en honor de Antoine Baumé.)'},
    { tipo: 21, subtipo: 399, id: 'J16', description: 'Grado baume (luz de los EUA) (Unidad de densidad relativa para líquidos más ligeros que el agua.)'},
    { tipo: 21, subtipo: 399, id: 'J15', description: 'Grado baume (us pesado) (Unidad de densidad relativa para líquidos más pesados que el agua.)'},
    { tipo: 21, subtipo: 399, id: 'J18', description: 'Grado brix (Unidad de proporcion utilizada para medir la proporcion de masa de azúcar disuelta a agua de un líquido. Nombrado en honor de Adolf Brix)'},
    { tipo: 21, subtipo: 399, id: 'PLA', description: 'Grado de platón (Unidad de proporción que define el contenido de azúcar de un producto, especialmente en relación con la cerveza)'},
    { tipo: 21, subtipo: 399, id: 'ISD', description: 'Grado internacional de azúcar (Unidad de medida que define el contenido de azúcar de una solución, expresada en grados.)'},
    { tipo: 21, subtipo: 399, id: 'J27', description: 'Grado oechsle (Unidad de densidad como medida del contenido de azúcar de mosto, el licor no fermentado del que se elabora el vino. Nombrado en honor de Ferdinand Oechsle.)'},
    { tipo: 21, subtipo: 399, id: 'J31', description: 'Grado twaddel. (Unidad de densidad para líquidos que son más pesados que el agua. 1 grado Twaddle representa una diferencia en gravedad específica de 0,005.)'},
    { tipo: 21, subtipo: 399, id: 'LEF', description: 'Hoja (Unidad de conteo que define el número de hojas.)'},
    { tipo: 21, subtipo: 399, id: 'NH', description: 'Hora del mensaje'},
    { tipo: 21, subtipo: 399, id: 'N3', description: 'Impresión de punto'},
    { tipo: 21, subtipo: 399, id: 'Q10', description: 'Joule por tesla (Unidad del momento dipolar magnético de la molécula como derivado de la unidad SI joule dividido por la unidad SI derivada Tesla)'},
    { tipo: 21, subtipo: 399, id: 'JNT', description: 'Junta de tubería (Un recuento del número de juntas de la tubería.)'},
    { tipo: 21, subtipo: 399, id: 'Q28', description: 'Kilograma por metro cuadrado pascal segundo (Unidad para la capacidad de un material para permitir la transición del vapor)'},
    { tipo: 21, subtipo: 399, id: 'TMS', description: 'Kilogramo de carne importada, menos despojos (Unidad de masa equivalente a mil gramos de carne importada, sin tener en cuenta los subproductos menos valiosos como las montañas)'},
    { tipo: 21, subtipo: 399, id: 'K3', description: 'Kilovoltio amperio hora reactivo (Unidad de medida que define la energía reactiva acumulada igual a un kilovoltioio de potencia reactiva por hora.)'},
    { tipo: 21, subtipo: 399, id: 'K2', description: 'Kilovoltios amperios demanda reactiva (Unidad de medida que define la demanda de potencia reactiva igual a un kilovoltioio de potencia reactiva.)'},
    { tipo: 21, subtipo: 399, id: 'P89', description: 'Libra por metro cúbico de pulgada (Unidad para el momento de rotación relacionado con la longitud según el sistema de unidades Anglo-Americanas e Imperial) '},
    { tipo: 21, subtipo: 399, id: 'RP', description: 'Libra por resma (Unidad de masa para papel, expresada en libras por resma. (Resma: una gran cantidad de papel, tipicamente 500 hojas))'},
    { tipo: 21, subtipo: 399, id: 'NY', description: 'Libra por tonelada métrica al aire seco'},
    { tipo: 21, subtipo: 399, id: 'P90', description: 'Libra'},
    { tipo: 21, subtipo: 399, id: 'AL', description: 'Línea de acceso (Unidad de recuento para definir el número de líneas de acceso telefónico.)'},
    { tipo: 21, subtipo: 399, id: 'T0', description: 'Linea de telecomunicaciones en servicio (Unidad de conteo que define el número de líneas de servicio)'},
    { tipo: 21, subtipo: 399, id: 'UB', description: 'Linea de telecomunicaciones en servicio promedio (Unida de conteo que define el número medio de líneas de servicio)'},
    { tipo: 21, subtipo: 399, id: 'C0', description: 'Llamada (Una unidad de recuento de definir el número de llamadas (call: sesión de comunicación o de visita).)'},
    { tipo: 21, subtipo: 399, id: 'QR', description: 'Mano de papel (Unidad de conteo para papel, expresada como el número de manos de papel (mano de papel: numero de papel en hojas, típicamente 25))'},
    { tipo: 21, subtipo: 399, id: 'B65', description: 'Maxwell'},
    { tipo: 21, subtipo: 399, id: 'RN', description: 'Medida métrica de Hojas (resma)'},
    { tipo: 21, subtipo: 399, id: 'E44', description: 'Metros kilogramo'},
    { tipo: 21, subtipo: 399, id: 'MBE', description: 'Mil equivalente de ladrillo estándar (Unidad de cuenta que define el número de mil unidades equivalentes de ladrillo.)'},
    { tipo: 21, subtipo: 399, id: 'MBF', description: 'Mil pies de tabla'},
    { tipo: 21, subtipo: 399, id: 'MK', description: 'Miligramo por pulgada cuadrada'},
    { tipo: 21, subtipo: 399, id: '1H', description: 'Milla de caboose'},
    { tipo: 21, subtipo: 399, id: '1A', description: 'Milla de carros'},
    { tipo: 21, subtipo: 399, id: '1F', description: 'Milla de tren'},
    { tipo: 21, subtipo: 399, id: '1K', description: 'Milla locomotora'},
    { tipo: 21, subtipo: 399, id: '1M', description: 'Milla total del coche'},
    { tipo: 21, subtipo: 399, id: 'AH', description: 'Minuto adicional (Unidad de tiempo que define el número de minutos, además de los minutos referenciados.)'},
    { tipo: 21, subtipo: 399, id: 'AI', description: 'Minuto y medio por llamada (Unidad de recuento para definir el número de minutos para el intervalo medio de una llamada.)'},
    { tipo: 21, subtipo: 399, id: 'P99', description: 'Mols por metro cúbico a la suma de potencia de los números estequimetricos (Unidad de la constante de equilibrio sobre la base de la concentración (ISO 80000'},
    { tipo: 21, subtipo: 399, id: 'A49', description: 'Negador (Unidad de densidad de hilo. Una denier es igual a una masa de 1 gramo por 9 kilómetros de longitud.)'},
    { tipo: 21, subtipo: 399, id: 'Q27', description: 'Newtons metros por metro (Unidad para el momento de rotación relacionado con la longitud como producto de la unidad SI derivada por el medidor de la unidad SI)'},
    { tipo: 21, subtipo: 399, id: 'TAN', description: 'Número de acido total (Unidad de química que define la cantidad de hidróxido de potasio (KOH) en miligramos que se necesita para neutralizar los ácidos en un gramo de aceite. Es una medida importante de la calidad del petróleo crudo)'},
    { tipo: 21, subtipo: 399, id: 'PQ', description: 'Página por pulgada (Unidad de cantidad que define el grado de espesor de una publicación enlazada, expresada como el número de de páginas por pulgada de espesor.)'},
    { tipo: 21, subtipo: 399, id: 'QB', description: 'Página, copia impresa (Unidad de conteo que define el número de copias impresas)'},
    { tipo: 21, subtipo: 399, id: 'QA', description: 'Página'},
    { tipo: 21, subtipo: 399, id: 'ZP', description: 'Páginas (Unidad de conteo que define el número de páginas)'},
    { tipo: 21, subtipo: 399, id: 'PTN', description: 'Parte (Cantidad de alimentos asignados o suficientes para una persona )'},
    { tipo: 21, subtipo: 399, id: 'C4', description: 'partido de carga'},
    { tipo: 21, subtipo: 399, id: 'P98', description: 'Pascal a la suma de potencia de los números estequimetricos (Unidad de la constante de equilibrio sobre la base de la presión (ISO 80000'},
    { tipo: 21, subtipo: 399, id: 'PZ', description: 'Peck dry (UK)'},
    { tipo: 21, subtipo: 399, id: 'DWT', description: 'Pennyweight (La unidad obsoleta pennyweight era Unidad de masa utilizada en el Reino Unido antes de 1971. Se consideraba equivalente a la masa de un penique, y equivale a la 1/240 parte de una libra troy. Se abrevia pwt.)'},
    { tipo: 21, subtipo: 399, id: 'P91', description: 'Permanente (0°c) (Unidad tradicional para la capacidad de un material para permitir la transición del vapor, definida a una temperatura de 0°C como transmitancia del vapor en la cual la masa de un grano de vapor penetra en un área de un pie cuadrado a una presión de mercurio de una pulgada por hora.)'},
    { tipo: 21, subtipo: 399, id: 'P92', description: 'Permanente (23°c) (Unidad tradicional para la capacidad de un material para permitir la transición del vapor, definida a una temperatura de 23°C como transmitancia del vapor en la cual la masa de un grano de vapor penetra en un área de un pie cuadrado a una presión de mercurio de una pulgada por hora.)'},
    { tipo: 21, subtipo: 399, id: 'R1', description: 'Pica (Unidad de conteo que define el número de picas (pica: longitud tipografia igual a 12 puntos o 4,22 mm aprox))'},
    { tipo: 21, subtipo: 399, id: 'PY', description: 'Pico seco (EUA)'},
    { tipo: 21, subtipo: 399, id: 'FE', description: 'Pie de pista'},
    { tipo: 21, subtipo: 399, id: 'LAC', description: 'Porcentaje de exceso de lactosa (Unidad de proporción que define el porcentaje de lactosa en un producto que excede un nivel de porcentaje definido.)'},
    { tipo: 21, subtipo: 399, id: 'KLK', description: 'Porcentaje de material seco láctico (Unidad de proporción que define el porcentaje de material láctico seco en un producto.)'},
    { tipo: 21, subtipo: 399, id: 'E53', description: 'Prueba (Unidad de recuento de definir el número de pruebas.)'},
    { tipo: 21, subtipo: 399, id: 'UC', description: 'Puerto de telecomunicaciones (Uindad de conteo que define el número de púertos de acceso a la red)'},
    { tipo: 21, subtipo: 399, id: 'Z6', description: 'Punto de conferencia'},
    { tipo: 21, subtipo: 399, id: 'H82', description: 'Punto grande (Unidad de longitud que define el número de puntos grandes (punto grande: el software de Adobe (EUA) define el punto grande a ser exactamente 1/72 de pulgada (0,013 888 9 pulgadas o 0,352 777 8 milímetros))'},
    { tipo: 21, subtipo: 399, id: 'CTM', description: 'Quilatage métrico (Es una unidad de masa igual a 200 mg y se utiliza para la medición de las piedras preciosas y perlas .)'},
    { tipo: 21, subtipo: 399, id: 'Q24', description: 'Reciprocidad de Pulgada (Complemento de la unidad pulgada según el sistema de unidades Anglo'},
    { tipo: 21, subtipo: 399, id: 'P97', description: 'Reciprocidad de Radian (Recíproco de la unidad radian)'},
    { tipo: 21, subtipo: 399, id: 'P96', description: 'Recíproco de la unidad si deriva voltio (Recíproco de la unidad SI deriva voltio)'},
    { tipo: 21, subtipo: 399, id: '1B', description: 'Recuento de automóviles'},
    { tipo: 21, subtipo: 399, id: '1L', description: 'Recuento total de automóviles'},
    { tipo: 21, subtipo: 399, id: 'RM', description: 'Resma (Unidad de conteo para papel, espresada como el número de resmas (resma: una gran cantidad de hojas de papel, tipicamente 500))'},
    { tipo: 21, subtipo: 399, id: 'P88', description: 'Rhe (Unidad no conforme a SI de fluidez de viscosidad dinámica)'},
    { tipo: 21, subtipo: 399, id: 'E52', description: 'Run foot (Unidad de conteo para definir la distancia (en la unidad Pie) que se dan en una carrera).)'},
    { tipo: 21, subtipo: 399, id: 'Q20', description: 'Segundo por kilogramo (Unidad de la probabilidad de transición de Einsten para emisiones espontáneas o inductoras y absorción según ISO 80000-7, expresada como unidad base SI segundo dividida por la unidad base kilogramo.) '},
    { tipo: 21, subtipo: 399, id: 'Q22', description: 'Segundo por metro cúbicos de radianes (Unidad de la densidad de estados como una expresión de la frecuencia angular como complemento del producto de hertzio y radiante y la potencia del medidor de la unidad base SI por exponente 3.)'},
    { tipo: 21, subtipo: 399, id: 'BP', description: 'Tabledo de cien pies'},
    { tipo: 21, subtipo: 399, id: 'BFT', description: 'Tablero de pies (Unidad de medida para el volumen de la madera en el Estados Unidos y Canadá . Es el volumen de una longitud de un pie de una placa de un pie de ancho y una pulgada de grosor.)'},
    { tipo: 21, subtipo: 399, id: 'D34', description: 'Tex (Enidad de medida utilizada para medir la densidad o la masa lineal de una fibra.)'},
    { tipo: 21, subtipo: 399, id: '1J', description: 'Tonelada milla'},
    { tipo: 21, subtipo: 399, id: 'PI', description: 'Tono (Unidade de conteo que define el número de caracteres que se ajustan en una pulgada horizontal)'},
    { tipo: 21, subtipo: 399, id: 'E51', description: 'Trabajo (Unidad de recuento de definir el número de puestos de trabajo.)'},
    { tipo: 21, subtipo: 399, id: 'NN', description: 'tren'},
    { tipo: 21, subtipo: 399, id: 'LBT', description: 'Troy pound'},
    { tipo: 21, subtipo: 399, id: 'E50', description: 'Unidad de cuenta (Unidad de recuento de definir el número de unidades de contabilidad.)'},
    { tipo: 21, subtipo: 399, id: 'E48', description: 'Unidad de servicio (Unidad de conteo que define el número de unidades de servicio (unidad de servicio: definido período / propiedad / centro / utilidad de alimentación).)'},
    { tipo: 21, subtipo: 399, id: 'Q26', description: 'Uno por uno (Valor del cociente de dos unidades físicas del mismo tipo que un numerador y denominador mientras que las unidades se acortan mutuamente)'},
    { tipo: 21, subtipo: 399, id: 'UF', description: 'Uso por línea de telecomunicaciones promedio'},
    { tipo: 21, subtipo: 399, id: 'E55', description: 'Utilizar (Unidad de recuento de definir el número de veces que se utiliza un objeto.)'},
    { tipo: 21, subtipo: 399, id: 'E54', description: 'Viaje (Unidad de recuento de definir el número de viajes.)'},
    { tipo: 21, subtipo: 399, id: 'Q21', description: 'Watt metro cuadrado (Unidad de las primeras constantes de radiación c1=2.p.c0, cuyo valor es 3.74177118.10 6 veces la del valor comparativo del producto de la unidad SI deriva watts multiplicada con la potencia de la SI medidor de la unidad base con el exponente 2.)'},
    { tipo: 21, subtipo: 399, id: 'Q23', description: 'Weber a la potencia menos uno (Complemento de la unidad SI deriva weber como unidad de la constante de Joshepson, cuyo valor es igual al 384,597,891 veces del valor de referencia gigahertz dividido por volt. 4)'},
    { tipo: 21, subtipo: 399, id: 'E57', description: 'Zona (Unidad de conteo que define el número de zonas.)'},
    
]