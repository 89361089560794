var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-content mt-5" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          { staticClass: "card p-4" },
          [
            _c(
              "table-component",
              {
                ref: "table",
                attrs: {
                  data: _vm.taxReturns,
                  "filter-no-results": _vm.$t("general.filterNoResults"),
                  "is-clickable": true,
                  "show-filter": false,
                  "table-class": "table",
                },
              },
              [
                _c("table-column", {
                  attrs: { label: "PERÍODO" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (row) {
                        return [
                          row.complementaria
                            ? _c(
                                "div",
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "icon",
                                    attrs: { icon: "file-invoice" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "\n                " +
                                _vm._s(row.month) +
                                "\n              "
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("table-column", { attrs: { label: "AÑO", show: "year" } }),
                _vm._v(" "),
                _c("table-column", {
                  attrs: { label: "", "cell-class": "no-click" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (row) {
                        return [
                          _c(
                            "base-button",
                            {
                              attrs: { size: "small", color: "theme" },
                              on: {
                                click: function ($event) {
                                  return _vm.verArchivos(row.id)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                Ver archivos\n              "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [
        _vm._v("\n      Estados de cuenta\n    "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }