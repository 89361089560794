  
import * as types from './mutation-types'

export default {
  [types.SET_YEAR] (state, year) {
    state.year = year;
  },

  [types.SET_MONTH] (state, month) {
    state.month = month;
  },
}
