var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", { staticClass: "item-row invoice-item-row" }, [
    _c(
      "td",
      { staticStyle: { padding: "10px !important" } },
      [
        _c("base-input", {
          attrs: { invalid: _vm.$v.item.description.$error, type: "text" },
          on: {
            input: function ($event) {
              return _vm.$v.item.description.$touch()
            },
            keyup: _vm.updateItem,
          },
          model: {
            value: _vm.item.description,
            callback: function ($$v) {
              _vm.$set(_vm.item, "description", $$v)
            },
            expression: "item.description",
          },
        }),
        _vm._v(" "),
        _vm.$v.item.description.$error
          ? _c("div", [
              !_vm.$v.item.description.required
                ? _c("span", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("validation.required")) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "td",
      {
        staticClass: "text-center",
        staticStyle: { padding: "10px !important" },
      },
      [
        _c("base-select", {
          attrs: {
            options: _vm.clavesProdServ,
            searchable: true,
            "allow-empty": false,
            "show-labels": false,
            placeholder: _vm.$t("items.select_a_unit"),
            invalid: _vm.$v.item.unitCode.$error,
            label: "description",
            "track-by": "id",
          },
          model: {
            value: _vm.item.productCode,
            callback: function ($$v) {
              _vm.$set(_vm.item, "productCode", $$v)
            },
            expression: "item.productCode",
          },
        }),
        _vm._v(" "),
        _vm.$v.item.productCode.$error
          ? _c("div", [
              !_vm.$v.item.productCode.required
                ? _c("span", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("validation.required")) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "td",
      {
        staticClass: "text-center",
        staticStyle: { padding: "10px !important" },
      },
      [
        _c("base-input", {
          attrs: {
            invalid: _vm.$v.item.quantity.$error,
            type: "text",
            small: "",
          },
          on: {
            keyup: _vm.updateItem,
            input: function ($event) {
              return _vm.$v.item.quantity.$touch()
            },
          },
          model: {
            value: _vm.item.quantity,
            callback: function ($$v) {
              _vm.$set(_vm.item, "quantity", $$v)
            },
            expression: "item.quantity",
          },
        }),
        _vm._v(" "),
        _vm.$v.item.quantity.$error
          ? _c("div", [
              !_vm.$v.item.quantity.required
                ? _c("span", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("validation.required")) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.$v.item.quantity.maxLength
                ? _c("span", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("validation.quantity_maxlength")) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "td",
      {
        staticClass: "text-center",
        staticStyle: { padding: "10px !important" },
      },
      [
        _c(
          "div",
          {
            staticClass: "btn-group discount-drop-down",
            attrs: { role: "group" },
          },
          [
            _c("base-select", {
              attrs: {
                options: _vm.unitCodeOptions,
                searchable: true,
                "allow-empty": false,
                "show-labels": false,
                placeholder: _vm.$t("items.select_a_unit"),
                invalid: _vm.$v.item.unitCode.$error,
                label: "description",
                "track-by": "id",
              },
              model: {
                value: _vm.item.unitCode,
                callback: function ($$v) {
                  _vm.$set(_vm.item, "unitCode", $$v)
                },
                expression: "item.unitCode",
              },
            }),
            _vm._v(" "),
            _vm.$v.item.unitCode.$error
              ? _c("div", [
                  !_vm.$v.item.unitCode.required
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("validation.required")) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "td",
      {
        staticClass: "text-right",
        staticStyle: { padding: "10px !important" },
      },
      [
        _c("div", { staticClass: "item-amount" }, [
          _c("span", [
            _c("div", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.$utils.formatMoney(_vm.unitPrice, _vm.defaultCurrency)
                ),
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "remove-icon-wrapper" },
            [
              _vm.showRemoveItemIcon
                ? _c("font-awesome-icon", {
                    staticClass: "remove-icon",
                    attrs: { icon: "trash-alt" },
                    on: { click: _vm.removeItem },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "td",
      {
        staticClass: "text-center",
        staticStyle: { padding: "10px !important" },
      },
      [
        _c("div", { staticClass: "invoice-amount" }, [
          _c(
            "div",
            {
              staticClass: "btn-group discount-drop-down",
              attrs: { role: "group" },
            },
            [
              _c("base-input", {
                attrs: { readOnly: "", "input-class": "item-discount" },
                model: {
                  value: _vm.item.ivaAmount,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "ivaAmount", $$v)
                  },
                  expression: "item.ivaAmount",
                },
              }),
              _vm._v(" "),
              _c(
                "v-dropdown",
                { attrs: { "show-arrow": false } },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn item-dropdown dropdown-toggle",
                      attrs: {
                        slot: "activator",
                        type: "button",
                        "data-toggle": "dropdown",
                        "aria-haspopup": "true",
                        "aria-expanded": "false",
                      },
                      slot: "activator",
                    },
                    [_vm._v("\n            " + _vm._s("%") + "\n          ")]
                  ),
                  _vm._v(" "),
                  _c("v-dropdown-item", [
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.selectPercentageValor("16")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n              " + _vm._s("16%") + "\n            "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("v-dropdown-item", [
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.selectPercentageValor("8")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n              " + _vm._s("8%") + "\n            "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("v-dropdown-item", [
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.selectPercentageValor("0")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n              " + _vm._s("0%") + "\n            "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("v-dropdown-item", [
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.selectPercentageValor("0")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s("EXENTO") +
                            "\n            "
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "td",
      { staticClass: "text-left", staticStyle: { padding: "10px !important" } },
      [
        _c("div", { staticClass: "d-flex flex-column" }, [
          _c("div", { staticClass: "flex-fillbd-highlight" }, [
            _c(
              "div",
              { staticClass: "base-input" },
              [
                _c(
                  "money",
                  _vm._b(
                    {
                      staticClass: "input-field",
                      on: {
                        input: function ($event) {
                          return _vm.$v.item.total.$touch()
                        },
                        keyup: _vm.updateItem,
                      },
                      model: {
                        value: _vm.item.total,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "total", $$v)
                        },
                        expression: "item.total",
                      },
                    },
                    "money",
                    _vm.customerCurrency,
                    false
                  )
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.$v.item.total.$error
              ? _c("div", [
                  !_vm.$v.item.total.maxLength
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("validation.price_maxlength")) +
                            "\n          "
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }