var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.disable
      ? _c("div", [_vm.showContent ? [_vm._t("default")] : _vm._e()], 2)
      : _c(
          "div",
          { style: { "pointer-events": !_vm.showContent ? "none" : "auto" } },
          [[_vm._t("default", null, { disabled: _vm.showContent })]],
          2
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }