var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "p-3" }, [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.savePago()
          },
        },
      },
      [
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "input-group mb-3" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.paquete,
                    expression: "form.paquete",
                  },
                ],
                staticClass: "custom-select",
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.form,
                        "paquete",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                    function ($event) {
                      _vm.$v.form.$touch()
                      _vm.onChange(_vm.form.paquete)
                    },
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "", selected: "" } }, [
                  _vm._v("Elegir..."),
                ]),
                _vm._v(" "),
                _vm._l(_vm.selectPaquetes, function (paquete) {
                  return _c(
                    "option",
                    { key: paquete.id, domProps: { value: paquete } },
                    [_vm._v(_vm._s(paquete.nombre))]
                  )
                }),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          !_vm.$v.form.plan.required
            ? _c("div", { staticClass: "error" }, [
                _vm._v("\n        Debe elegir un plan\n      "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("label", { attrs: { for: "" } }, [_vm._v("Periodicidad")]),
          _vm._v(" "),
          _c("div", { staticClass: "input-group mb-3" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.periodicidad_nombre,
                  expression: "form.periodicidad_nombre",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text", readonly: "" },
              domProps: { value: _vm.form.periodicidad_nombre },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form, "periodicidad_nombre", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("label", { attrs: { for: "" } }, [_vm._v("Precio Unitario")]),
          _vm._v(" "),
          _c("div", { staticClass: "input-group mb-3" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.precio,
                  expression: "form.precio",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "number", readonly: "" },
              domProps: { value: _vm.form.precio },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form, "precio", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("label", { attrs: { for: "" } }, [_vm._v("Año")]),
          _vm._v(" "),
          _c("div", { staticClass: "input-group mb-3" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.anioInicia,
                    expression: "form.anioInicia",
                  },
                ],
                staticClass: "custom-select",
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.form,
                        "anioInicia",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                    function ($event) {
                      _vm.$v.form.$touch()
                      _vm.onChangeMes(_vm.form.fechaInicia, _vm.form.anioInicia)
                    },
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "", selected: "" } }, [
                  _vm._v("Elegir..."),
                ]),
                _vm._v(" "),
                _vm._l(_vm.anios, function (anio) {
                  return _c(
                    "option",
                    { key: anio, domProps: { value: anio } },
                    [_vm._v(_vm._s(anio))]
                  )
                }),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          !_vm.$v.form.anioInicia.required
            ? _c("div", { staticClass: "error" }, [
                _vm._v("\n          El año que inicia es requerido.\n      "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("label", { attrs: { for: "" } }, [_vm._v("Periodo")]),
          _vm._v(" "),
          _c("div", { staticClass: "input-group mb-3" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.fechaTerminaNombre,
                  expression: "form.fechaTerminaNombre",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text" },
              domProps: { value: _vm.form.fechaTerminaNombre },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form, "fechaTerminaNombre", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.anioInicia,
                    expression: "form.anioInicia",
                  },
                ],
                staticClass: "custom-select",
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.form,
                        "anioInicia",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                    function ($event) {
                      _vm.$v.form.$touch()
                      _vm.onChangeMes(_vm.form.fechaInicia, _vm.form.anioInicia)
                    },
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "", selected: "" } }, [
                  _vm._v("Elegir..."),
                ]),
                _vm._v(" "),
                _vm._l(_vm.anios, function (anio) {
                  return _c(
                    "option",
                    { key: anio, domProps: { value: anio } },
                    [_vm._v(_vm._s(anio))]
                  )
                }),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          !_vm.$v.form.fechaInicia.required
            ? _c("div", { staticClass: "error" }, [
                _vm._v("\n          El periodo es requerido.\n      "),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-right" }, [
          _c(
            "button",
            { staticClass: "btn btn-primary", attrs: { type: "submit" } },
            [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("invoices.save")) + "\n      "
              ),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c(
        "label",
        {
          staticClass: "input-group-text",
          attrs: { for: "inputGroupSelect01" },
        },
        [_vm._v("Plan")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }