import * as types from './mutation-types';

export const fetchAllProdServGroups = ({commit}) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`/api/prod-serv/groups`)
    .then(response => {
      commit(types.SET_PROD_SERV_GROUPS, response.data)
      resolve(response)
    })
    .catch(err => reject(err))
  })
}

export const fetchCustomerProdServGroups = (state, customerId) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`/api/prod-serv/customer-groups`, {
      params: {
        customer_id: customerId
      }
    })
    .then(response => {
      resolve(response)
    })
    .catch(err => reject(err));
  })
}

export const featchSearchProdServGroup = ({ commit, state }, id) => {
  return new Promise((resolve, reject) => {
    const filtered = state.prodServGroups.filter(group => group.id == id);
    if (filtered.length) {
      resolve(filtered[0]);
    } else {
      window.axios.get(`/api/prod-serv/show/${id}`)
        .then(response => {
          commit(types.APPEND_PROD_SERV_GROUP, response.data.group);
          resolve(response.data.group);
        })
        .catch(reject);
    }
  });
}

export const featchSearchProdServGroupByDescription = (state, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post(`/api/prod-serv/search`, data)
      .then(resolve)
      .catch(reject);
  });
}

export const saveCategoryToInvoiceItem = (state, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post(`/api/invoice-item/change-category`, data)
      .then(resolve)
      .catch(reject);
  });
}

export const setChangeCategoryData = ({ commit }, data) => {
  commit(types.SET_CHANGE_CATEGORY_DATA, data);
}

export const updateCustomerProdServGroupTaxReturn = (state, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post(`/api/prod-serv/customer-relation`, data)
      .then(resolve)
      .catch(reject);
  });
}

