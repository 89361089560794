var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pt-4 pb-4 pl-4 pr-4" }, [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.savePaidDocument()
          },
        },
      },
      [
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v("Descripción")]),
          _vm._v(" "),
          _c("div", { staticClass: "base-input" }, [
            _c("input"),
            _vm._v(" "),
            _vm.$v.form.amount.$error
              ? _c("div", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("validation.required")) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "text-right" }, [
            _c(
              "button",
              { staticClass: "btn btn-primary", attrs: { type: "submit" } },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("invoices.save")) +
                    "\n        "
                ),
              ]
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-left" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "submit" } },
        [_vm._v("\n          Eliminar\n        ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }