<template>
  <div class="main-content">
    <div class="page-header mt-4">
      <h3 class="page-title">Mensajes</h3>
    </div>
    <vue-advanced-chat
      height="calc(100vh - 30vh)"
      show-search="false"
      show-audio="false"
      show-emojis="false"
      show-add-room="false"
      show-reaction-emojis="false"
      show-new-messages-divider="false"
      single-room="true"
      message-selection-actions="[]"
      message-actions="[]"
      :current-user-id="currentUserId"
      :rooms="getRooms()"
      :rooms-loaded="true"
      :messages="JSON.stringify(messages)"
      :messages-loaded="messagesLoaded"
      :show-files="true"
      @send-message="sendMessage($event.detail[0])"
      @fetch-messages="fetchMessages($event.detail[0])"
    />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import Pagination from '../../components/base/base-table/components/Pagination'
import { register } from 'vue-advanced-chat'
register()

export default {
  components: {
    Pagination,
  },

  data: () => ({
    isLoading: false,
    user_id: '1234',
    rooms: [
      {
        roomId: '1',
        roomName: 'Room 1',
        users: [
          { _id: '1', username: 'Ejecutivo' },
          { _id: '13', username: 'Cliente' },
        ],
      },
    ],
    messages: [],
    mensajes: [],

    messagesLoaded: [],

    mensajesDeclaracion: [],
    tituloPeriodo: '',
    textoMensaje: '',
    idDeclaraion: '',
    months: [
      { id: '01', name: 'Enero' },
      { id: '02', name: 'Febrero' },
      { id: '03', name: 'Marzo' },
      { id: '04', name: 'Abril' },
      { id: '05', name: 'Mayo' },
      { id: '06', name: 'Junio' },
      { id: '07', name: 'Julio' },
      { id: '08', name: 'Agosto' },
      { id: '09', name: 'Septiembre' },
      { id: '10', name: 'Octubre' },
      { id: '11', name: 'Noviembre' },
      { id: '12', name: 'Diciembre' },
    ],
    currentUserId: '1',
    // rooms: [
    //   {
    //     roomId: '1',
    //     roomName: 'Enero',
    //     unreadCount: 0,
    //     index: 1,

    //   },
    // ],
    // messages: [],

    messagesLoaded: false,
  }),

  computed: {
    ...mapGetters('CurrentCustomer', ['currentCustomer']),
    ...mapGetters('assigned', ['assingedSelect']),
    ...mapGetters('userProfile', ['user']),
  },

  mounted() {
    // this.loadData()
  },
  validations: {
    textoMensaje: {
      required,
    },
  },

  methods: {
    // ...mapActions('modal', ['openModal']),
    ...mapActions('assigned', [
      // 'fetchMensajes',
      'getConversacion',
      'sendMessageAssigned',
      // 'createSendMensaje',
    ]),

    ...mapActions('userProfile', ['loadData', 'editUser', 'uploadAvatar']),

    // loadMensajes() {
    //   this.user_id = this.currentCustomer.id
    //   this.getConversacion().then((response) => {
    //     this.mensajes = response.data.conversation
    //     console.log(this.mensajes)
    //     this.loadRoomsDeclara()
    //   })
    // },

    // loadData() {
    //   this.currentUserId = this.user.id
    //   this.rooms[0] = {
    //     roomId: '1',
    //     roomName: this.assingedSelect.month,
    //     users: [
    //       { _id: this.currentUserId.toString(), username: 'Ejecutivo' },
    //       { _id: this.currentCustomer.toString(), username: 'Cliente' },
    //     ],
    //   }
    // },

    getRooms() {
      this.currentUserId = this.user == null ? '1' : this.user.id.toString()

      let rooms = [
        {
          roomId: '1',
          roomName: `${this.assingedSelect.month} - ${this.assingedSelect.year}`,
          users: [
            { _id: this.currentUserId.toString(), username: 'Ejecutivo' },
            { _id: this.currentCustomer.toString(), username: 'Cliente' },
          ],
        },
      ]
      return JSON.stringify(rooms)
    },

    fetchMessages({ options = {} }) {
      setTimeout(() => {
        // if (options.reset) {
        //   this.messages = this.addMessages(true)
        // } else {
        this.getConversacion().then((response) => {
          const mensajes = response.data.conversation.reverse()

          const messages = []
          for (let i = 0; i < mensajes.length; i++) {
            const fecha = new Date(mensajes[i].created_at)
            // Expresión regular para comprobar si el mensaje es una URL
            const mensaje = mensajes[i].mensaje;

            const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i

            if (urlRegex.test(mensaje)) {

              // Si es una URL, añadir la estructura del archivo
              messages.push({
                _id: mensajes[i].id,
                content: mensaje,
                senderId: mensajes[i].id_usr.toString(),
                timestamp: fecha.toString().substring(16, 21),
                date: fecha.toDateString(),
                file: {
                  name: 'Declaracion',
                  type: 'pdf',
                  url: mensaje,
                },
              })
            } else {
              // Si no es una URL, añadir solo la estructura del mensaje
              messages.push({
                _id: mensajes[i].id,
                content: mensaje,
                senderId: mensajes[i].id_usr.toString(),
                timestamp: fecha.toString().substring(16, 21),
                date: fecha.toDateString(),
              })
            }
          }
          this.messages = messages

          this.messagesLoaded = true
        })
        // }
        // this.addNewMessage()
      })
    },

    sendMessage(message) {
      this.sendMessageAssigned({
        message: message.content,
      }).then((value) => {
        if (value.status == 200) {
          this.messages = [
            ...this.messages,
            {
              _id: this.messages.length,
              content: message.content,
              senderId: this.currentUserId,
              timestamp: new Date().toString().substring(16, 21),
              date: new Date().toDateString(),
            },
          ]
        }
      })
    },

    addNewMessage() {
      setTimeout(() => {
        this.messages = [
          ...this.messages,
          {
            _id: this.messages.length,
            content: 'NEW MESSAGE',
            senderId: '1',
            timestamp: new Date().toString().substring(16, 21),
            date: new Date().toDateString(),
          },
        ]
      }, 2000)
    },

    // loadRoomsDeclara() {
    //   const roomsDeclara = this.mensajes
    //   const rooms = []
    //   roomsDeclara.forEach((item) => {
    //     rooms.push({
    //       roomId: item.id,
    //       roomName:
    //         this.period(item.start_month, item.end_month) + ' ' + item.year,
    //       users: [
    //         { _id: 1, username: 'Taxati' },
    //         { _id: item.user_id, username: 'Cliente' },
    //       ],
    //     })
    //   })

    //   this.rooms = rooms
    // },

    // loadDeclaracionMensajes(id_declaraion) {
    //   this.user_id = this.currentCustomer.id
    //   this.fetchDeclaracionMensajes(id_declaraion).then((response) => {
    //     this.mensajesDeclaracion = response.data
    //   })
    // },

    // period(startMonth, endMonth) {
    //   if (!startMonth && !endMonth) {
    //     return ''
    //   } else if (startMonth == endMonth) {
    //     const month = this.months.find(
    //       (m) => Number(m.id) == Number(startMonth)
    //     )
    //     return month.name
    //   }
    //   const cStartMonth = this.months.find(
    //     (m) => Number(m.id) == Number(startMonth)
    //   )
    //   const cEndMonth = this.months.find(
    //     (m) => Number(m.id) == Number(endMonth)
    //   )

    //   return `${cStartMonth.name} - ${cEndMonth.name}`
    // },

    // /* getMensaje(id, periodo) {
    //   this.idDeclaraion = id;
    //   this.loadDeclaracionMensajes(this.idDeclaraion)
    //   this.tituloPeriodo = periodo
    // },
    // sendMensaje () {
    //   this.isLoading = true;
    //   this.user_id
    //   const data ={
    //     'user_id': this.user_id,
    //     'idDeclaraion': this.idDeclaraion,
    //     'mensaje': this.textoMensaje
    //   };
    //   this.createSendMensaje(data)
    //     .then(response => {
    //       console.log(response);
    //       this.isLoading = false;
    //       if (response.data.success) {
    //         window.toastr['success'](this.$t('treasury_accounts.created_message'));
    //         this.textoMensaje = '';
    //         this.loadDeclaracionMensajes(this.idDeclaracion)
    //       }
    //     })
    //     .catch(err => {
    //       this.isLoading = false;
    //       window.toastr['error'](this.$t('treasury_accounts.errors.save'));
    //       if (err.response) {
    //         window.toastr['error'](err.response.data.error);
    //       }
    //     });
    // },
    // submitForm () {
    //   this.$v.textoMensaje.$touch();
    //   if (this.$v.textoMensaje.$invalid !== false) {
    //     return;
    //   }
    //   this.sendMensaje();
    // }, */

    // fetchMessages({ room = {}, options = {} }) {
    //   setTimeout(() => {
    //     if (options.reset) {
    //       this.messages = this.addMessages(true, room.roomId)
    //     } else {
    //       this.messages = [
    //         ...this.addMessages(true, room.roomId),
    //         ...this.messages,
    //       ]
    //       this.messagesLoaded = true
    //     }
    //     this.addNewMessage()
    //   })
    // },
    // addMessages(reset, roomId) {
    //   const messages = []

    //   this.loadDeclaracionMensajes(roomId)
    //   const messagesDeclara = this.mensajesDeclaracion
    //   console.log('mensajes')
    //   console.log(roomId)
    //   console.log(this.mensajesDeclaracion)

    //   messagesDeclara.forEach((item) => {
    //     messages.push({
    //       _id: reset ? item.id : this.messages.length + item.id,
    //       content: item.mensaje,
    //       senderId: `${item.id_usr}`,
    //       username: 'Cliente',
    //       date: new Date(item.created_at).toDateString(),
    //       timestamp: new Date(item.created_at).toString().substring(16, 21),
    //     })
    //   })

    //   console.log(messages)
    //   /* for (let i = 0; i < 5; i++) {
    // 		messages.push({
    // 			_id: reset ? i : this.messages.length + i,
    // 			content: `${reset ? '' : 'paginated'} message ${i + 1}`,
    // 			senderId: '4321',
    // 			username: 'John Doe',
    // 			date: '13 November',
    // 			timestamp: '10:20'
    // 		})
    // 	} */
    //   return messages
    // },
    // sendMessage(message) {
    //   console.log(message)
    //   this.messages = [
    //     ...this.messages,
    //     {
    //       _id: this.messages.length,
    //       content: message.content,
    //       senderId: this.currentUserId,
    //       timestamp: new Date().toString().substring(16, 21),
    //       date: new Date().toDateString(),
    //     },
    //   ]
    // },
    // addNewMessage() {
    //   setTimeout(() => {
    //     this.messages = [
    //       ...this.messages,
    //       {
    //         _id: this.messages.length,
    //         content: '',
    //         senderId: '1',
    //         timestamp: new Date().toString().substring(16, 21),
    //         date: new Date().toDateString(),
    //       },
    //     ]
    //   }, 2000)
    // },
  },
}
</script>
<style lang="scss">
body {
  font-family: 'Quicksand', sans-serif;
}
</style>
