import * as types from './mutation-types'

export const searchInfoForTaxReturn = (store, params) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/tax-return/search-info`, params)
      .then(resolve)
      .catch(reject)
  })
}

export const listTaxReturns = (store, params) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/tax-return/list`, { params })
      .then(resolve)
      .catch(reject)
  })
}

export const storeTaxReturn = (store, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post(`/api/tax-return/store`, data).then(resolve).catch(reject)
  })
}

export const fetchTaxReturn = (store, id) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`/api/tax-return/${id}`).then(resolve).catch(reject)
  })
}

export const statusTaxReturns = (store, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/tax-return/status`, data)
      .then(resolve)
      .catch(reject)
  })
}

export const messageTaxReturns = (store, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/tax-return/message`, data)
      .then(resolve)
      .catch(reject)
  })
}

export const sendMessage = (store, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/customer_tarx_returns_message`, data)
      .then(resolve)
      .catch(reject)
  })
}

export const updatedModalDataRifIsr = ({ commit, dispatch, state }, data) => {
  commit(types.UPDATED_RIF_ISR, data)
}

export const updatedModalDataRifIva = ({ commit, dispatch, state }, data) => {
  commit(types.UPDATED_RIF_IVA, data)
}

export const updatedModalCompensacion = ({ commit, dispatch, state }, data) => {
  commit(types.UPDATED_COMPENSACION, data)
}
export const updatedModalAcreditamiento = (
  { commit, dispatch, state },
  data
) => {
  commit(types.UPDATED_ACREDITAMIENTO, data)
}

export const updatedModalIngresoCfdi = ({ commit, dispatch, state }, data) => {
  commit(types.UPDATED_INGRESOS_CFDI, data)
}
export const updatedModalIngresoDisminuir = (
  { commit, dispatch, state },
  data
) => {
  commit(types.UPDATED_INGRESOS_DISMINUIR, data)
}

export const updatedModalIngresoAdicional = (
  { commit, dispatch, state },
  data
) => {
  commit(types.UPDATED_INGRESOS_ADICIONAL, data)
}

export const updatedModalISRRetenido = (
  { commit, dispatch, state },
  data
) => {
  commit(types.UPDATED_ISR_RETENIDO, data)
}
