var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.invoices
    ? _c("div", { staticClass: "table-responsive" }, [
        _vm.title
          ? _c("h6", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.$t(_vm.title))),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("table", { staticClass: "table table-bordered" }, [
          _c("thead", [
            _c("tr", [
              _c("th", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectAll,
                      expression: "selectAll",
                    },
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.selectAll)
                      ? _vm._i(_vm.selectAll, null) > -1
                      : _vm.selectAll,
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$a = _vm.selectAll,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.selectAll = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.selectAll = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.selectAll = $$c
                        }
                      },
                      _vm.toggleAllSelection,
                    ],
                  },
                }),
              ]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("invoices.uuid")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("invoices.name")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("invoices.sub_total")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("invoices.iva")))]),
              _vm._v(" "),
              _vm.hasRetentionIva
                ? _c("th", [_vm._v(_vm._s(_vm.$t("expenses.retention_iva")))])
                : _vm._e(),
              _vm._v(" "),
              _vm.hasRetentionIsr
                ? _c("th", [_vm._v(_vm._s(_vm.$t("expenses.retention_isr")))])
                : _vm._e(),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("invoices.total")))]),
              _vm._v(" "),
              _vm.showNoObjectColumn
                ? _c("th", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("tax_return.no_object")) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            [
              _vm._l(_vm.invoices, function (invoice) {
                return _c("tr", { key: invoice.id }, [
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedRows,
                          expression: "selectedRows",
                        },
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        value: invoice,
                        checked: Array.isArray(_vm.selectedRows)
                          ? _vm._i(_vm.selectedRows, invoice) > -1
                          : _vm.selectedRows,
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$a = _vm.selectedRows,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = invoice,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.selectedRows = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.selectedRows = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.selectedRows = $$c
                            }
                          },
                          _vm.updateSelectedRows,
                        ],
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "invoices.view",
                              params: { id: invoice.id },
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(invoice.uuid) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    [_c("sub-string", { attrs: { string: invoice.name } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [_c("money-text", { attrs: { amount: invoice.subtotal } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [_c("money-text", { attrs: { amount: invoice.iva } })],
                    1
                  ),
                  _vm._v(" "),
                  _vm.hasRetentionIva
                    ? _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: { amount: invoice.retention_iva },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasRetentionIsr
                    ? _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: { amount: invoice.retention_isr },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [_c("money-text", { attrs: { amount: invoice.total } })],
                    1
                  ),
                  _vm._v(" "),
                  _vm.showNoObjectColumn
                    ? _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: { amount: invoice.no_object },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              }),
              _vm._v(" "),
              _vm.tableTotals
                ? _c("tr", [
                    _c("td"),
                    _vm._v(" "),
                    _c("td"),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" }, [
                      _c(
                        "strong",
                        [
                          _c("money-text", {
                            attrs: { amount: _vm.tableTotals.subtotal },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" }, [
                      _c(
                        "strong",
                        [
                          _c("money-text", {
                            attrs: { amount: _vm.tableTotals.iva },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.hasRetentionIva
                      ? _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: { amount: _vm.tableTotals.retention_iva },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasRetentionIsr
                      ? _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: { amount: _vm.tableTotals.retention_isr },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" }, [
                      _c(
                        "strong",
                        [
                          _c("money-text", {
                            attrs: { amount: _vm.tableTotals.total },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.showNoObjectColumn
                      ? _c("td", { staticClass: "text-right" }, [
                          _c(
                            "strong",
                            [
                              _c("money-text", {
                                attrs: { amount: _vm.tableTotals.no_object },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }