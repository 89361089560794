var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.taxReturn
    ? _c("div", [
        _c("div", { staticClass: "card p-4" }, [
          _c("div", { staticClass: "table-responsive" }, [
            _c("table", { staticClass: "table table-bordered" }, [
              _c("thead", [
                _c("tr", { staticClass: "table-primary" }, [
                  _c(
                    "th",
                    { staticClass: "text-center", attrs: { colspan: "2" } },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("tax_returns.isr")) +
                          "\n            "
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("tbody", [
                _c(
                  "tr",
                  {
                    staticClass: "clickable",
                    on: {
                      click: function ($event) {
                        return _vm.toggleDisplayTable("retentions")
                      },
                    },
                  },
                  [
                    _c(
                      "td",
                      [
                        _c("font-awesome-icon", {
                          staticClass: "icon",
                          attrs: { icon: "arrow-down" },
                        }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("tax_returns.revenue_digital_platforms")
                            )
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "text-right" },
                      [
                        _c("money-text", {
                          attrs: {
                            amount:
                              _vm.taxReturn.amounts.revenue_digital_platforms,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("tr", [
                  _vm.displayTables.retentions
                    ? _c(
                        "td",
                        { attrs: { colspan: "2" } },
                        [
                          _vm.taxReturn.retentions
                            ? _c("tax-return-retentions-table", {
                                attrs: {
                                  retentions: _vm.taxReturn.retentions.invoices,
                                  totals: _vm.taxReturn.retentions.totals,
                                  title: "tax_returns.retentions",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(_vm._s(_vm.$t("tax_returns.revenue_direct_users"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: {
                          amount: _vm.taxReturn.amounts.revenue_direct_users,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(_vm._s(_vm.$t("tax_returns.revenue_period"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: { amount: _vm.taxReturn.amounts.revenue_period },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(_vm._s(_vm.$t("tax_returns.isr_percentage"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: { amount: _vm.taxReturn.amounts.isr_percentage },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v(_vm._s(_vm.$t("tax_returns.isr_caused")))]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: { amount: _vm.taxReturn.amounts.isr_caused },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(_vm._s(_vm.$t("tax_returns.isr_detained"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: { amount: _vm.taxReturn.amounts.isr_detained },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(_vm._s(_vm.$t("tax_returns.isr_in_charge"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: { amount: _vm.taxReturn.amounts.isr_in_charge },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card p-4" }, [
          _c("div", { staticClass: "table-responsive" }, [
            _c("table", { staticClass: "table table-bordered" }, [
              _c("thead", [
                _c("tr", { staticClass: "table-primary" }, [
                  _c(
                    "th",
                    { staticClass: "text-center", attrs: { colspan: "2" } },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("tax_returns.iva")) +
                          "\n            "
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("tbody", [
                _c("tr", [
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm.$t("tax_returns.revenue_intermediaries"))
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: {
                          amount:
                            _vm.taxReturn.amounts.revenue_digital_platforms,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(_vm._s(_vm.$t("tax_returns.revenue_direct_users"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: {
                          amount: _vm.taxReturn.amounts.revenue_direct_users,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(_vm._s(_vm.$t("tax_returns.iva_percentage"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: { amount: _vm.taxReturn.amounts.iva_percentage },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v(_vm._s(_vm.$t("tax_returns.iva_caused")))]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: { amount: _vm.taxReturn.amounts.iva_caused },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "tr",
                  {
                    staticClass: "clickable",
                    on: {
                      click: function ($event) {
                        return _vm.toggleDisplayTable("purchasesAndDeductions")
                      },
                    },
                  },
                  [
                    _c(
                      "td",
                      [
                        _c("font-awesome-icon", {
                          staticClass: "icon",
                          attrs: { icon: "arrow-down" },
                        }),
                        _vm._v(
                          " " + _vm._s(_vm.$t("tax_returns.iva_creditable"))
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "text-right" },
                      [
                        _c("money-text", {
                          attrs: {
                            amount: _vm.taxReturn.amounts.iva_creditable,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.displayTables.purchasesAndDeductions
                  ? _c("tr", [
                      _c(
                        "td",
                        { attrs: { colspan: "2" } },
                        [
                          _vm.taxReturn.tables.deductions
                            ? _c("tax-return-invoices-table", {
                                attrs: {
                                  invoices:
                                    _vm.taxReturn.tables.deductions.invoices,
                                  totals:
                                    _vm.taxReturn.tables.deductions.totals,
                                  title: "tax_returns.deductions",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.taxReturn.tables.purchases
                            ? _c("tax-return-invoices-table", {
                                attrs: {
                                  invoices:
                                    _vm.taxReturn.tables.purchases.invoices,
                                  totals: _vm.taxReturn.tables.purchases.totals,
                                  title: "tax_returns.purchases",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(_vm._s(_vm.$t("tax_returns.iva_detained"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: { amount: _vm.taxReturn.amounts.iva_detained },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v(_vm._s(_vm.$t("tax_returns.iva_period")))]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: { amount: _vm.taxReturn.amounts.iva_period },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(_vm._s(_vm.$t("tax_returns.iva_previous_period"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: {
                          amount: _vm.taxReturn.amounts.iva_previous_period,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(_vm._s(_vm.$t("tax_returns.iva_in_charge"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: { amount: _vm.taxReturn.amounts.iva_in_charge },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }