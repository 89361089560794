var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-content" },
    [
      _c("div", { staticClass: "page-header mt-4" }, [
        _c("h3", { staticClass: "page-title" }, [_vm._v("Pagos")]),
        _vm._v(" "),
        _c("div", { staticClass: "page-actions row" }, [
          _c(
            "div",
            { staticClass: "col-4" },
            [
              _c(
                "base-button",
                {
                  attrs: { color: "theme" },
                  on: {
                    click: function ($event) {
                      return _vm.openAddPagosModal()
                    },
                  },
                },
                [_vm._v("\n          Registrar Pago\n        ")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "table-component",
        {
          ref: "table",
          attrs: {
            data: _vm.pedidos,
            "filter-no-results": _vm.$t("general.filterNoResults"),
            "is-clickable": true,
            "show-filter": false,
            "table-class": "table",
          },
          on: { columnClick: _vm.rowClicked },
        },
        [
          _c("table-column", { attrs: { label: "Plan", show: "stripe_plan" } }),
          _vm._v(" "),
          _c("table-column", {
            attrs: { label: "Precio unitario", show: "unit_price" },
          }),
          _vm._v(" "),
          _c("table-column", {
            attrs: { label: "Inicia", show: "name_month_start" },
          }),
          _vm._v(" "),
          _c("table-column", {
            attrs: { label: "Termina", show: "name_month_end" },
          }),
          _vm._v(" "),
          _c("table-column", {
            staticStyle: { width: "30px" },
            attrs: { label: "Cantidad de Folios", show: "quantity" },
          }),
          _vm._v(" "),
          _c("table-column", {
            attrs: { label: "Folios Utilizados", show: "quantity" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }