<template>
  <span>{{ result }}</span>
</template>

<script>
export default {
  props: {
    code: {
      default: '',
      type: String
    }
  },
  data: () => ({
    catalog: [
      {
        "code": "01",
        "description": "Efectivo",
      },
      {
        "code": "02",
        "description": "Cheque nominativo",
      },
      {
        "code": "03",
        "description": "Transferencia electrónica de fondos",
      },
      {
        "code": "04",
        "description": "Tarjeta de cródito",
      },
      {
        "code": "05",
        "description": "Monedero electrónico",
      },
      {
        "code": "06",
        "description": "Dinero electrónico",
      },
      {
        "code": "08",
        "description": "Vales de despensa",
      },
      {
        "code": "12",
        "description": "Dación en pago",
      },
      {
        "code": "13",
        "description": "Pago por subrogación",
      },
      {
        "code": "14",
        "description": "Pago por consignación",
      },
      {
        "code": "15",
        "description": "Condonación",
      },
      {
        "code": "17",
        "description": "Compensación",
      },
      {
        "code": "23",
        "description": "Novación",
      },
      {
        "code": "24",
        "description": "Confusión",
      },
      {
        "code": "25",
        "description": "Remisión de deuda",
      },
      {
        "code": "26",
        "description": "Prescripción o caducidad",
      },
      {
        "code": "27",
        "description": "A satisfacción del acreedor",
      },
      {
        "code": "28",
        "description": "Tarjeta de débito",
      },
      {
        "code": "29",
        "description": "Tarjeta de servicios",
      },
      {
        "code": "30",
        "description": "Aplicación de anticipos",
      },
      {
        "code": "31",
        "description": "Intermediario pagos",
      },
      {
        "code": "99",
        "description": "Por definir",
      }
    ],
  }),
  computed: {
    result () {
      const result = this.catalog.filter(t => t.code == this.code)
      if (result.length) {
        return `${result[0].code} - ${result[0].description}`
      }
      return this.code;
    }
  }
}
</script>
