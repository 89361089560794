<template>
  <div class="p-3">
    <div class="table-responsive">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>{{ $t('tax_losses.year') }}</th>
            <th class="text-right">{{ $t('tax_losses.tax_loss') }}</th>
            <th class="text-right">{{ $t('tax_losses.updates') }}</th>
            <th class="text-right">{{ $t('tax_losses.remaining') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="taxLoss in losses" :key="taxLoss.id">
            <td>
              {{ taxLoss.year }}
            </td>
            <td class="text-right">
              <money-text :amount="taxLoss.amount" />
            </td>
            <td class="text-right">
              <money-text :amount="Number(taxLoss.amount_update)" />
            </td>
            <td class="text-right">
              <money-text :amount="taxLoss.remaining_amount" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="table-responsive mt-4">
      <table class="table table-bordered">
        <tbody>
          <tr>
            <td class="text-right">{{ $t('tax_losses.tax_loss') }}</td>
            <td class="text-right">{{ $t('tax_losses.amount_to_apply') }}</td>
            <td class="text-right">{{ $t('tax_losses.remaining') }}</td>
          </tr>
          <tr>
            <td class="text-right">
              <money-text :amount="amountOfTaxLosses" />
            </td>
            <td class="text-right">
              <div class="base-input">
                <money
                  v-model="amountToApply"
                  v-bind="customerCurrency"
                  class="input-field text-right"
                />
              </div>
            </td>
            <td
              :class="{ 'text-danger': disableApplyTaxLoss }"
              class="text-right"
            >
              <money-text :amount="remainingAmount" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="mt-2 flex-content-right">
      <base-button
        :disabled="disableApplyTaxLoss"
        color="theme"
        @click="applyTaxLoss"
      >
        {{ $t('tax_losses.apply_tax_loss') }}
      </base-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data: () => ({
    amountToApply: 0,
  }),
  computed: {
    ...mapGetters('CurrentCustomer', ['currentCustomer']),
    ...mapGetters('taxLossAndBalance', ['taxLosses']),
    ...mapGetters('currency', ['defaultCurrency']),
    customerCurrency() {
      if (this.defaultCurrency) {
        return {
          decimal: this.defaultCurrency.decimal_separator,
          thousands: this.defaultCurrency.thousand_separator,
          prefix: this.defaultCurrency.symbol + ' ',
          precision: this.defaultCurrency.precision,
          masked: false,
        }
      } else {
        return this.defaultCurrenctForInput
      }
    },
    losses() {
      return this.taxLosses.filter((item) => item.periodicity == 'yearly')
    },
    amountOfTaxLosses() {
      return this.losses.reduce((accumulated, item) => {
        return accumulated + item.remaining_amount
      }, 0)
    },
    remainingAmount() {
      const toApply = this.amountToApply * 100
      return this.amountOfTaxLosses - toApply
    },
    disableApplyTaxLoss() {
      if (this.remainingAmount < 0) {
        return true
      }
      return false
    },
  },
  created() {
    this.loadTaxLosses()
  },
  methods: {
    ...mapActions('taxLossAndBalance', ['fetchTaxLosses']),
    ...mapActions('modal', ['closeModal']),
    loadTaxLosses() {
      const params = {
        customer_id: this.currentCustomer.id,
      }
      this.fetchTaxLosses(params).then()
    },
    useTaxLoss(taxLoss) {
      window.hub.$emit('applyTaxLoss', { taxLoss })
      this.closeModal()
    },
    applyTaxLoss() {
      this.useTaxLoss(this.amountToApply)
    },
  },
}
</script>
