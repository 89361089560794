<template>
  <div class="sidebar-left">
    <div class="sidebar-body scroll-pane">
      <div class="side-nav">
        <div class="current-customer">
          <small>{{ $t('customers.current_customer') }}</small><br />
          {{ currentCustomer.rfc }}
          <font-awesome-icon :icon="!currentCustomer.status ? 'exclamation-triangle' : ''" class="right-icon"
            color="#eed202" v-tooltip="{ content: currentCustomer.textstatus }" />
          <br />
          {{ currentCustomer.name }}

          <br />
          <font-awesome-icon :icon="'user-minus'" class="right-icon" @click="deselectCustomer" color="red"
            v-tooltip="{ content: 'Quitar Usuario' }" />
          <br />
        </div>
        <template>
          <div v-for="(menuItems, index) in menu" :key="index + 'a'" class="menu-group">
            <router-link v-for="(item, index1) in menuItems" :key="index1 + 'a'" :to="item.route"
              :class="{ disabled: item.require_customer && !currentCustomer }" class="menu-item" @click.native="Toggle">
              <div class="menu-content">
                <font-awesome-icon :icon="item.icon" class="icon menu-icon" />
                <!-- Contenedor para el texto -->
                <div class="menu-text-container">
                  <!-- Agrega la clase "text-center" para centrar el texto -->
                  <span class="ml-3 text-center">{{ $t(item.title) }}</span>
                </div>
              </div>
            </router-link>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      currentRoute: '',
      sidebar: 'sidebar',
      collapsed: {
        'home-collapse': false, // Inicialmente colapsado
        'cliente-collapse': true,
        // Otros elementos colapsables aquí
      },
      menu: [
        [
          {
            title: 'Períodos Contratados' /* 'navigation.payments' */,
            icon: 'box',
            route: '/general/customer/contracted',
            require_customer: true,
          },
          {
            title: 'Declaraciones Presentadas' /* 'navigation.payments' */,
            icon: 'file-alt',
            route: '/general/customer/tax-return',
            require_customer: true,
          },
          {
            title: 'Declaraciones no presentadas por taxati.' /* 'navigation.payments' */,
            icon: 'file-alt',
            route: '/general/customer/tax-return',
            require_customer: true,
          },
          {
            title: 'Estados de cuenta' /* 'navigation.payments' */,
            icon: 'file-pdf',
            route: '/general/customer/statement-account',
            require_customer: true,
          },
        ],
      ],
    }
  },
  computed: {
    ...mapGetters('CurrentCustomer', ['currentCustomer']),
  },

  methods: {
    ...mapActions('CurrentCustomer', ['setCurrentCustomer']),
    Toggle() {
      this.$utils.toggleSidebar()
    },
    toggleCollapse(target) {
      this.collapsed[target] = !this.collapsed[target]
    },
    deselectCustomer() {
      this.setCurrentCustomer(null)
      this.$router.push('/general/customers/pending')
    },
  },
  mounted() {
    this.currentRoute = this.$route.path
    if (this.currentRoute.includes('customers')) {
      this.collapsed['cliente-collapse'] = true
    } else {
      this.collapsed['cliente-collapse'] = false
    }
  },
  watch: {
    $route(to, from) {
      this.currentRoute = to.path
      if (currentRoute.includes('customers')) {
        this.collapsed['cliente-collapse'] = true
      } else {
        this.collapsed['cliente-collapse'] = false
      }
    },
  },
}
</script>
<style scoped>
.menu-content {
  display: flex; /* Utilizamos flexbox */
  align-items: center; /* Centramos verticalmente */
}

.menu-text-container {
  flex-grow: 1; /* El texto ocupará todo el espacio disponible */
  text-align: center; /* Centramos el texto horizontalmente */
}

.icon {
  margin-right: 10px; /* Ajusta el espacio entre el icono y el texto según sea necesario */
}
</style>