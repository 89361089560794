<template>
  <div class="main-content">
    <div class="page-header">
      <h3 class="page-title">
        {{ $t('tax_returns.tax_return') }}
      </h3>
    </div>

    <div class="card p2">
      <rif :tax-return="taxReturn" />
    </div>

    <div class="mt-3 text-center">
      <button class="btn btn-success" @click="approveTaxReturn">
        {{ $t('tax_returns.approve_tax_return') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Rif from './components/RIF'

export default {
  components: { Rif },
  data: () => ({
    taxReturnId: null,
    taxReturn: null,
  }),
  created() {
    this.taxReturnId = this.$route.params.id
  },
  mounted() {
    this.loadTaxReturn()
  },
  methods: {
    ...mapActions('customerTaxReturns', ['fetchTaxReturn']),
    loadTaxReturn() {
      this.fetchTaxReturn(this.taxReturnId).then((response) => {
        this.taxReturn = response.data.tax_return
      })
    },
    approveTaxReturn() {
      window.toastr.success(this.$t('tax_returns.messages.tax_return_approved'))
    },
  },
}
</script>
