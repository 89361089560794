var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.taxReturn
    ? _c("div", [
        _c("div", { staticClass: "card p-2" }, [
          _c("table", { staticClass: "table table-bordered" }, [
            _c("thead", [
              _c("tr", { staticClass: "table-primary" }, [
                _c(
                  "th",
                  { staticClass: "text-center", attrs: { colspan: "2" } },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("tax_returns.amounts")) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("tbody", [
              _c(
                "tr",
                {
                  staticClass: "clickable",
                  on: {
                    click: function ($event) {
                      return _vm.toggleDisplayTable("revenue")
                    },
                  },
                },
                [
                  _c(
                    "td",
                    [
                      _c("font-awesome-icon", {
                        staticClass: "icon",
                        attrs: { icon: "arrow-down" },
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("tax_returns.revenue"))),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: { amount: _vm.taxReturn.amounts.revenue_period },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.displayTables.revenue
                ? _c("tr", [
                    _c(
                      "td",
                      { attrs: { colspan: "2" } },
                      [
                        _vm.taxReturn.tables.revenue
                          ? _c("tax-return-invoices-table", {
                              attrs: {
                                invoices: _vm.taxReturn.tables.revenue.invoices,
                                totals: _vm.taxReturn.tables.revenue.totals,
                                title: "tax_returns.revenue",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "tr",
                {
                  staticClass: "clickable",
                  on: {
                    click: function ($event) {
                      return _vm.toggleDisplayTable("taxDeductions")
                    },
                  },
                },
                [
                  _c(
                    "td",
                    [
                      _c("font-awesome-icon", {
                        staticClass: "icon",
                        attrs: { icon: "arrow-down" },
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("tax_returns.deductions"))),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: {
                          amount: _vm.taxReturn.amounts.deductions_period,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.displayTables.taxDeductions
                ? _c("tr", [
                    _c(
                      "td",
                      { attrs: { colspan: "2" } },
                      [
                        _vm.taxReturn.tables.tax_deductions
                          ? _c("tax-return-invoices-table", {
                              attrs: {
                                invoices:
                                  _vm.taxReturn.tables.tax_deductions.invoices,
                                totals:
                                  _vm.taxReturn.tables.tax_deductions.totals,
                                title: "tax_returns.deductions",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card p-2" }, [
          _c("table", { staticClass: "table table-bordered" }, [
            _c("thead", [
              _c("tr", { staticClass: "table-primary" }, [
                _c(
                  "th",
                  { staticClass: "text-center", attrs: { colspan: "2" } },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("tax_returns.isr")) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$t("tax_returns.revenue")))]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: {
                        amount: _vm.taxReturn.amounts.revenue_accumulated,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$t("tax_returns.deductions")))]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: {
                        amount: _vm.taxReturn.amounts.deductions_accumulated,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$t("tax_returns.taxed_income")))]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: { amount: _vm.taxReturn.amounts.taxed_income },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$t("tax_returns.ptu_paid")))]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: { amount: _vm.taxReturn.amounts.ptu_paid },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$t("tax_returns.tax_loss")))]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: { amount: _vm.taxReturn.amounts.tax_lost },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v(_vm._s(_vm.$t("tax_returns.taxable_base")))]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: { amount: _vm.taxReturn.amounts.taxable_base },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("rate_tables.lower_limit")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: { amount: _vm.taxReturn.amounts.lower_limit },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("tax_returns.surplus_lower_limit")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: {
                        amount: _vm.taxReturn.amounts.surplus_lower_limit,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("tax_returns.rate_on_surplus")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "text-right" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.taxReturn.amounts.rate_on_surplus) +
                      " %\n          "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("tax_returns.marginal_amount")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: { amount: _vm.taxReturn.amounts.marginal_amount },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("rate_tables.fixed_fee")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: { amount: _vm.taxReturn.amounts.fixed_fee },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("tax_returns.isr_caused")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: { amount: _vm.taxReturn.amounts.isr_caused },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("tax_returns.isr_detained")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: { amount: _vm.taxReturn.amounts.isr_detained },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("tax_returns.isr_total")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: { amount: _vm.taxReturn.amounts.isr_total },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("tax_returns.isr_in_favor")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: { amount: _vm.taxReturn.amounts.isr_in_favor },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("tax_returns.previous_provisional_paid")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: {
                        amount: _vm.taxReturn.amounts.isr_previous_paid,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("tax_returns.isr_to_pay")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: { amount: _vm.taxReturn.amounts.isr_to_pay },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card p-2" }, [
          _c("table", { staticClass: "table table-bordered" }, [
            _c("thead", [
              _c("tr", { staticClass: "table-primary" }, [
                _c(
                  "th",
                  { staticClass: "text-center", attrs: { colspan: "2" } },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("tax_returns.iva")) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("tbody", [
              _c(
                "tr",
                {
                  staticClass: "clickable",
                  on: {
                    click: function ($event) {
                      return _vm.toggleDisplayTable("revenue")
                    },
                  },
                },
                [
                  _c(
                    "td",
                    [
                      _c("font-awesome-icon", {
                        staticClass: "icon",
                        attrs: { icon: "arrow-down" },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("tax_returns.income_which_to_charge_iva_16")
                          )
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: { amount: _vm.taxReturn.amounts.revenue_period },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.displayTables.revenue
                ? _c("tr", [
                    _c(
                      "td",
                      { attrs: { colspan: "2" } },
                      [
                        _vm.taxReturn.tables.revenue
                          ? _c("tax-return-invoices-table", {
                              attrs: {
                                invoices: _vm.taxReturn.tables.revenue.invoices,
                                totals: _vm.taxReturn.tables.revenue.totals,
                                title:
                                  "tax_returns.income_which_to_charge_iva_16",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("tax_returns.iva_charged")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: { amount: _vm.taxReturn.amounts.iva_charged },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("tax_returns.iva_detained")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: { amount: _vm.taxReturn.amounts.iva_detained },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.$t("tax_returns.iva_creditable_for_expenses")
                      ) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: { amount: _vm.taxReturn.amounts.iva_expenses },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("tax_returns.amount_in_charge")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: { amount: _vm.taxReturn.amounts.iva_in_charge },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("tax_returns.iva_in_favor")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: { amount: _vm.taxReturn.amounts.iva_in_favor },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("tax_returns.iva_total")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: { amount: _vm.taxReturn.amounts.iva_total },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("tax_returns.iva_creditable")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: { amount: _vm.taxReturn.amounts.iva_creditable },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("tax_returns.iva_to_pay")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: { amount: _vm.taxReturn.amounts.iva_to_pay },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }