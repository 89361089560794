var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-content" },
    [
      _c("div", { staticClass: "page-header pt-4" }, [
        _c("h3", { staticClass: "page-title" }, [
          _vm._v(
            "\n      " + _vm._s(_vm.$t("tax_returns.tax_return")) + "\n    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.periodTaxReturn
        ? [_c("h4", [_vm._v(_vm._s(_vm.periodTaxReturn))])]
        : _vm._e(),
      _vm._v(" "),
      _vm.currentCustomer.tax_regime_b
        ? [
            _c("div", { staticClass: "form-group row" }, [
              _c("label", { staticClass: "col-form-label col-2" }, [
                _vm._v(" Régimen "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-10" },
                [
                  _c("base-select", {
                    attrs: {
                      options: _vm.optionsRegime,
                      searchable: true,
                      "show-labels": false,
                      placeholder: "Régimen",
                      label: "name",
                    },
                    model: {
                      value: _vm.filters.regimen,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "regimen", $$v)
                      },
                      expression: "filters.regimen",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showingDetails,
              expression: "!showingDetails",
            },
          ],
          staticClass: "row",
        },
        [
          _c("div", { staticClass: "col-12 col-md-6" }, [
            _vm.showTaxRegimeForm
              ? _c(
                  "div",
                  { staticClass: "card p-2" },
                  [
                    _vm.filters.regimen.id == 612
                      ? [
                          _c("div", { staticClass: "form-group row" }, [
                            _c(
                              "label",
                              {
                                staticClass: "col-3 col-form-label",
                                attrs: { for: "" },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("tax_returns.isr_in_favor")) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-9 base-input" },
                              [
                                _c(
                                  "money",
                                  _vm._b(
                                    {
                                      staticClass: "input-field text-right",
                                      model: {
                                        value: _vm.filters.isrInFavor,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filters,
                                            "isrInFavor",
                                            $$v
                                          )
                                        },
                                        expression: "filters.isrInFavor",
                                      },
                                    },
                                    "money",
                                    _vm.customerCurrency,
                                    false
                                  )
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group row" }, [
                            _c(
                              "label",
                              {
                                staticClass: "col-3 col-form-label",
                                attrs: { for: "" },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t("tax_returns.iva_creditable")
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-9 base-input" },
                              [
                                _c(
                                  "money",
                                  _vm._b(
                                    {
                                      staticClass: "input-field text-right",
                                      model: {
                                        value: _vm.filters.ivaCreditable,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filters,
                                            "ivaCreditable",
                                            $$v
                                          )
                                        },
                                        expression: "filters.ivaCreditable",
                                      },
                                    },
                                    "money",
                                    _vm.customerCurrency,
                                    false
                                  )
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group row" }, [
                            _c(
                              "label",
                              {
                                staticClass: "col-3 col-form-label",
                                attrs: { for: "" },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t("tax_returns.tax_loss_update")
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-9 base-input" },
                              [
                                _c(
                                  "money",
                                  _vm._b(
                                    {
                                      staticClass: "input-field text-right",
                                      model: {
                                        value: _vm.filters.taxLossUpdate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filters,
                                            "taxLossUpdate",
                                            $$v
                                          )
                                        },
                                        expression: "filters.taxLossUpdate",
                                      },
                                    },
                                    "money",
                                    _vm.customerCurrency,
                                    false
                                  )
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.filters.regimen.id == 626
              ? _c("div", { staticClass: "card p-2" }, [
                  _vm.yearlyTaxReturnSelected == false
                    ? _c("div", [
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            {
                              staticClass: "col-3 col-form-label",
                              attrs: { for: "" },
                            },
                            [
                              _vm._v(
                                "\n              Actualizaciones\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-9 base-input" },
                            [
                              _c(
                                "money",
                                _vm._b(
                                  {
                                    staticClass: "input-field text-right",
                                    model: {
                                      value: _vm.filters.resicoUpdates,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filters,
                                          "resicoUpdates",
                                          $$v
                                        )
                                      },
                                      expression: "filters.resicoUpdates",
                                    },
                                  },
                                  "money",
                                  _vm.customerCurrency,
                                  false
                                )
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            {
                              staticClass: "col-3 col-form-label",
                              attrs: { for: "" },
                            },
                            [_vm._v(" Recargos ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-9 base-input" },
                            [
                              _c(
                                "money",
                                _vm._b(
                                  {
                                    staticClass: "input-field text-right",
                                    model: {
                                      value: _vm.filters.resicoRecargos,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filters,
                                          "resicoRecargos",
                                          $$v
                                        )
                                      },
                                      expression: "filters.resicoRecargos",
                                    },
                                  },
                                  "money",
                                  _vm.customerCurrency,
                                  false
                                )
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            {
                              staticClass: "col-3 col-form-label",
                              attrs: { for: "" },
                            },
                            [_vm._v(" Compensación ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-9 base-input" },
                            [
                              _c(
                                "money",
                                _vm._b(
                                  {
                                    staticClass: "input-field text-right",
                                    model: {
                                      value: _vm.filters.resicoCompensaciones,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filters,
                                          "resicoCompensaciones",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "filters.resicoCompensaciones",
                                    },
                                  },
                                  "money",
                                  _vm.customerCurrency,
                                  false
                                )
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            {
                              staticClass: "col-3 col-form-label",
                              attrs: { for: "" },
                            },
                            [
                              _vm._v(
                                "\n              IVA Actualizaciones\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-9 base-input" },
                            [
                              _c(
                                "money",
                                _vm._b(
                                  {
                                    staticClass: "input-field text-right",
                                    model: {
                                      value: _vm.filters.resicoUpdatesIva,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filters,
                                          "resicoUpdatesIva",
                                          $$v
                                        )
                                      },
                                      expression: "filters.resicoUpdatesIva",
                                    },
                                  },
                                  "money",
                                  _vm.customerCurrency,
                                  false
                                )
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            {
                              staticClass: "col-3 col-form-label",
                              attrs: { for: "" },
                            },
                            [_vm._v(" IVA Recargos ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-9 base-input" },
                            [
                              _c(
                                "money",
                                _vm._b(
                                  {
                                    staticClass: "input-field text-right",
                                    model: {
                                      value: _vm.filters.resicoRecargosIva,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filters,
                                          "resicoRecargosIva",
                                          $$v
                                        )
                                      },
                                      expression: "filters.resicoRecargosIva",
                                    },
                                  },
                                  "money",
                                  _vm.customerCurrency,
                                  false
                                )
                              ),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.filters.regimen.id
            ? [
                _c("div", { staticClass: "col-12 col-md-6" }, [
                  _c(
                    "div",
                    { staticClass: "card p-2" },
                    [
                      !_vm.complementaria
                        ? [
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                { staticClass: "col-form-label col-3" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("tax_returns.period")) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-9" },
                                [
                                  _c("base-select", {
                                    attrs: {
                                      options: _vm.optionsPeriodPerTaxRegime,
                                      searchable: true,
                                      "show-labels": false,
                                      placeholder: _vm.$t("tax_returns.period"),
                                      value: null,
                                      label: "name",
                                    },
                                    model: {
                                      value: _vm.filters.period,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filters, "period", $$v)
                                      },
                                      expression: "filters.period",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "label",
                                { staticClass: "col-form-label col-3" },
                                [_vm._v(_vm._s(_vm.$t("general.year")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-9" },
                                [
                                  _c("base-input", {
                                    attrs: { type: "number", name: "year" },
                                    model: {
                                      value: _vm.filters.year,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filters, "year", $$v)
                                      },
                                      expression: "filters.year",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.yearlyTaxReturnSelected
                        ? _c("div", [
                            _vm.taxReturn.arrendamiento
                              ? _c("div", { staticClass: "form-group" }, [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "col-10 flex-vertical-end",
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$t(
                                                "customers.sat_settings.tax_return_at_35_percent"
                                              )
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-2" },
                                      [
                                        _c("base-switch", {
                                          model: {
                                            value:
                                              _vm.filters.additional
                                                .blindTaxReturn,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.filters.additional,
                                                "blindTaxReturn",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "filters.additional.blindTaxReturn",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group flex-content-right" },
                        [
                          _c(
                            "base-button",
                            {
                              attrs: { color: "theme", size: "small" },
                              on: { click: _vm.loadInfo },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t("tax_returns.search_information")
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.taxReturn.rif
        ? _c("tax-return-rif", { attrs: { "tax-return": _vm.taxReturn.rif } })
        : _vm._e(),
      _vm._v(" "),
      _vm.taxReturn.arrendamiento
        ? _c("tax-return-arrendamiento", {
            attrs: { "tax-return": _vm.taxReturn.arrendamiento },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.taxReturn.digital_platforms
        ? _c("tax-return-digital-platform", {
            attrs: { "tax-return": _vm.taxReturn.digital_platforms },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.taxReturn.resico
        ? _c("tax-return-resico", {
            attrs: { "tax-return": _vm.taxReturn.resico },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.taxReturn.professional_activity
        ? _c("tax-return-professional-activity", {
            attrs: { "tax-return": _vm.taxReturn.professional_activity },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.taxReturn.yearly
        ? _c("tax-return-yearly", {
            attrs: {
              "tax-return": _vm.taxReturn.yearly,
              "blind-tax-return": _vm.filters.additional.blindTaxReturn,
            },
            on: {
              applyInterests: _vm.applyInterests,
              applyTaxLoss: _vm.applyTaxLoss,
              applyPersonalDeductions: _vm.applyPersonalDeductions,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.taxReturn.yearly_digitales
        ? _c("tax-return-yearly-digitales", {
            attrs: {
              "tax-return": _vm.taxReturn.yearly_digitales,
              "blind-tax-return": _vm.filters.additional.blindTaxReturn,
            },
            on: {
              applyInterests: _vm.applyInterests,
              applyTaxLoss: _vm.applyTaxLoss,
              applyPersonalDeductions: _vm.applyPersonalDeductions,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.taxReturn.yearly_resico
        ? _c("tax-return-yearly-resico", {
            attrs: {
              "tax-return": _vm.taxReturn.yearly_resico,
              "blind-tax-return": _vm.filters.additional.blindTaxReturn,
            },
            on: {
              applyInterests: _vm.applyInterests,
              applyTaxLoss: _vm.applyTaxLoss,
              applyPersonalDeductions: _vm.applyPersonalDeductions,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.haveTaxReturn && !_vm.showingDetails
        ? _c("div", { staticClass: "mt-4 mb-4 text-center" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                on: { click: _vm.saveTaxReturn },
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("tax_returns.save_tax_return")) +
                    "\n    "
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.taxReturn.status == "aceptado"
        ? _c(
            "div",
            { staticClass: "mt-4 mb-4 text-center" },
            [
              _c(
                "base-button",
                {
                  attrs: { color: "theme", icon: "plus", size: "large" },
                  on: { click: _vm.goComplementaria },
                },
                [_vm._v("> Agregar Declaración Complementaria\n    ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }