<template>
  <div class="p-3">
    <form @submit.prevent="saveConceptSubcategory()">

      <div class="form-group">
        <label>{{ $t('concept_categories.subcategory_name') }}</label>
        <base-input
          v-model="form.name"
          @change="$v.form.$touch()"
        />
          <div v-if="$v.form.name.$error" class="text-danger">
          {{ $t('concept_categories.validations.subcategory_name_required') }}
        </div>
      </div>

      <div class="form-group">
        <label>{{ $t('concept_categories.in_tax_return') }}</label>
        <base-switch
          v-model="form.in_tax_return"
          class="btn-switch"
        />
      </div>

      <div class="text-right">
        <button type="submit" class="btn btn-primary">
          {{ $t('invoices.save') }}
        </button>
      </div>

    </form>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';

export default {
  mixins: [validationMixin],

  data: () => ({
    form: {
      name: '',
      in_tax_return: false,
    }
  }),

  computed: {
    ...mapGetters('conceptCategories', [
      'modalSubcategoryData',
    ]),
  },

  validations: {
    form: {
      name: {
        required,
      }
    }
  },

  methods: {
    ...mapActions('conceptCategories', [
      'fetchCreateConceptSubcategory',
    ]),
    ...mapActions('modal', [
      'closeModal',
    ]),

    saveConceptSubcategory ()
    {
      this.$v.form.$touch();
      if (this.$v.form.$invalid !== false) {
        return;
      }

      const data = {
        ...this.form,
        concept_category_id: this.modalSubcategoryData.concept_category_id,
      }

      this.fetchCreateConceptSubcategory(data)
        .then(response => {
          this.closeModal();
          window.toastr['success'](this.$t('concept_categories.messages.subcategory_created'));
          window.hub.$emit('concept_categories.category.created');
        })
        .catch(err => {
          window.toastr['error'](this.$t('concept_categories.errors.category_create'));
        })
    }

  }
}
</script>
