var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "payments main-content" }, [
    _c("div", { staticClass: "page-header mt-5" }, [
      _c("h3", { staticClass: "page-title" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$t("payments.title")) +
            "\n      | " +
            _vm._s(_vm.filters.month["name"]) +
            ",\n      " +
            _vm._s(_vm.filters.year) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page-actions row" },
        [
          _c(
            "div",
            { staticClass: "col-xs-2 mr-4" },
            [
              _c(
                "base-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: false,
                      expression: "false",
                    },
                  ],
                  attrs: {
                    outline: true,
                    icon: _vm.filterIcon,
                    color: "theme",
                    "right-icon": "",
                    size: "large",
                  },
                  on: { click: _vm.toggleFilter },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("general.filter")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-xs-2 mr-2" },
            [
              _c(
                "router-link",
                {
                  staticClass: "col-xs-2",
                  attrs: {
                    slot: "item-title",
                    to: {
                      name: "general-public-invocie.index",
                      params: { type: "payments" },
                    },
                  },
                  slot: "item-title",
                },
                [
                  _c(
                    "base-button",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.top-center",
                          value: _vm.$t(
                            "payments.general_public_invoices.title"
                          ),
                          expression:
                            "\n              $t('payments.general_public_invoices.title')\n            ",
                          modifiers: { "top-center": true },
                        },
                      ],
                      attrs: {
                        outline: true,
                        color: "theme",
                        icon: "file-alt",
                        size: "large",
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t("payments.general_public_invoices.button")
                          ) +
                          "\n            " +
                          _vm._s(
                            _vm.currentCustomer.csd_expires == 1
                              ? "CSD EXPIRÓ"
                              : ""
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-xs-2 mr-2" },
            [
              _c(
                "router-link",
                {
                  staticClass: "col-xs-2",
                  attrs: {
                    slot: "item-title",
                    to: {
                      name: "create-invocie.index",
                      params: { type: "payments" },
                    },
                  },
                  slot: "item-title",
                },
                [
                  _c(
                    "base-button",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.top-center",
                          value: _vm.$t("payments.invoice"),
                          expression: "$t('payments.invoice')",
                          modifiers: { "top-center": true },
                        },
                      ],
                      attrs: {
                        outline: true,
                        color: "theme",
                        icon: "file-alt",
                        size: "large",
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("payments.invoice")) +
                          "\n            " +
                          _vm._s(
                            _vm.currentCustomer.csd_expires == 1
                              ? "CSD EXPIRÓ"
                              : ""
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "col-xs-2",
              attrs: { slot: "item-title", to: "payments/create" },
              slot: "item-title",
            },
            [
              _c(
                "base-button",
                { attrs: { color: "theme", icon: "plus", size: "large" } },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("payments.add_payment")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showEmptyScreen,
            expression: "showEmptyScreen",
          },
        ],
        staticClass: "col-xs-1 no-data-info",
        attrs: { align: "center" },
      },
      [
        _c("capsule-icon", { staticClass: "mt-5 mb-4" }),
        _vm._v(" "),
        _c("div", { staticClass: "row", attrs: { align: "center" } }, [
          _c("label", { staticClass: "col title" }, [
            _vm._v(_vm._s(_vm.$t("payments.no_payments"))),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "label",
            { staticClass: "description col mt-1", attrs: { align: "center" } },
            [_vm._v(_vm._s(_vm.$t("payments.list_of_payments")))]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "btn-container" },
          [
            _c(
              "base-button",
              {
                staticClass: "mt-3",
                attrs: { outline: true, color: "theme", size: "large" },
                on: {
                  click: function ($event) {
                    return _vm.$router.push("payments/create")
                  },
                },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("payments.add_new_payment")) +
                    "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col col-md-8" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col col-md-6" }, [
            _c(
              "div",
              { staticClass: "text-right" },
              [
                _c("strong", [_vm._v(_vm._s(_vm.$t("payments.sub_total")))]),
                _vm._v(" "),
                _c("money-text", {
                  attrs: { amount: Number(_vm.paymentsTotals.subtotal) },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "text-right" },
              [
                _c("strong", [
                  _vm._v(_vm._s(_vm.$t("payments.retention_isr"))),
                ]),
                _vm._v(" "),
                _c("money-text", {
                  attrs: { amount: Number(_vm.paymentsTotals.retention_isr) },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col col-md-6" }, [
            _c(
              "div",
              { staticClass: "text-right" },
              [
                _c("strong", [_vm._v(_vm._s(_vm.$t("payments.iva")))]),
                _vm._v(" "),
                _c("money-text", {
                  attrs: { amount: Number(_vm.paymentsTotals.iva) },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "text-right" },
              [
                _c("strong", [
                  _vm._v(_vm._s(_vm.$t("payments.retention_iva"))),
                ]),
                _vm._v(" "),
                _c("money-text", {
                  attrs: { amount: Number(_vm.paymentsTotals.retention_iva) },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "text-right" },
              [
                _c("strong", [_vm._v(_vm._s(_vm.$t("payments.total")))]),
                _vm._v(" "),
                _c("money-text", {
                  attrs: { amount: Number(_vm.paymentsTotals.total) },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col col-md-4" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("general.name_or_rfc")) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("base-input", {
              attrs: { type: "text" },
              model: {
                value: _vm.filters.nameOrRFC,
                callback: function ($$v) {
                  _vm.$set(_vm.filters, "nameOrRFC", $$v)
                },
                expression: "filters.nameOrRFC",
              },
            }),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.showEmptyScreen,
            expression: "!showEmptyScreen",
          },
        ],
        staticClass: "table-container",
      },
      [
        _c(
          "div",
          { staticClass: "table-actions mt-5" },
          [
            _c("p", { staticClass: "table-stats" }, [
              _vm._v("\n        " + _vm._s(_vm.$t("general.showing")) + ": "),
              _c("b", [_vm._v(_vm._s(_vm.payments.length))]),
              _vm._v("\n        " + _vm._s(_vm.$t("general.of")) + " "),
              _c("b", [_vm._v(_vm._s(_vm.totalPayments))]),
            ]),
            _vm._v(" "),
            _c(
              "transition",
              { attrs: { name: "fade" } },
              [
                _vm.selectedPayments.length
                  ? _c(
                      "v-dropdown",
                      { attrs: { "show-arrow": false } },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "table-actions-button dropdown-toggle",
                            attrs: { slot: "activator", href: "#" },
                            slot: "activator",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("general.actions")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("v-dropdown-item", [
                          _c(
                            "div",
                            {
                              staticClass: "dropdown-item",
                              on: { click: _vm.removeMultiplePayments },
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "dropdown-item-icon",
                                attrs: { icon: ["fas", "trash"] },
                              }),
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("general.delete")) +
                                  "\n            "
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "table-component",
          {
            ref: "table",
            attrs: {
              data: _vm.fetchData,
              "filter-no-results": _vm.$t("general.filterNoResults"),
              "is-clickable": true,
              "show-filter": false,
              "table-class": "table",
            },
            on: { columnClick: _vm.rowClicked },
          },
          [
            _c("table-column", {
              attrs: {
                sortable: false,
                filterable: false,
                "cell-class": "no-click",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return [
                      row.cfdi_type == "N"
                        ? _c("div", {}, [
                            _c("img", {
                              staticClass: "nomina-icon",
                              attrs: { src: "/images/nomina_icon.svg" },
                            }),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: {
                filterable: true,
                label: _vm.$t("payments.date"),
                show: "date",
              },
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: {
                label: _vm.$t("payments.payment_number"),
                show: "invoice_number",
              },
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: {
                label: _vm.$t("expenses.provider"),
                show: "receiver_name",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return [
                      _c("span", [_vm._v(_vm._s(_vm.$t("expenses.provider")))]),
                      _vm._v(" "),
                      row.receiver_rfc == _vm.currentCustomer.rfc
                        ? _c("sub-string", {
                            attrs: { string: row.issuing_name },
                          })
                        : _c("sub-string", {
                            attrs: { string: row.receiver_name },
                          }),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: {
                label: _vm.$t("expenses.provider_rfc"),
                show: "receiver_rfc",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("expenses.provider_rfc"))),
                      ]),
                      _vm._v(" "),
                      row.receiver_rfc == _vm.currentCustomer.rfc
                        ? _c("sub-string", {
                            attrs: { string: row.issuing_rfc },
                          })
                        : _c("sub-string", {
                            attrs: { string: row.receiver_rfc },
                          }),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: { label: _vm.$t("payments.type"), show: "type" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return [
                      _c("span", [_vm._v(_vm._s(_vm.$t("payments.type")))]),
                      _vm._v(
                        "\n         " +
                          _vm._s(row.type) +
                          " - " +
                          _vm._s(row.cfdi_type) +
                          "\n        "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: { label: _vm.$t("payments.sub_total"), show: "sub_total" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("payments.sub_total"))),
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$utils.formatMoney(
                              row.sub_total,
                              _vm.defaultCurrency
                            )
                          ),
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: { label: _vm.$t("payments.iva"), show: "iva" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return [
                      _c("span", [_vm._v(_vm._s(_vm.$t("payments.iva")))]),
                      _vm._v(" "),
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$utils.formatMoney(row.iva, _vm.defaultCurrency)
                          ),
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: {
                label: _vm.$t("payments.retention_iva"),
                show: "retention_iva",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("payments.retention_iva"))),
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$utils.formatMoney(
                              row.retention_iva,
                              _vm.defaultCurrency
                            )
                          ),
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: {
                label: _vm.$t("payments.retention_isr"),
                show: "retention_isr",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("payments.retention_isr"))),
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$utils.formatMoney(
                              row.retention_isr,
                              _vm.defaultCurrency
                            )
                          ),
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: { label: _vm.$t("payments.total"), show: "amount" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return [
                      _c("span", [_vm._v(_vm._s(_vm.$t("payments.total")))]),
                      _vm._v(" "),
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$utils.formatMoney(
                              row.amount,
                              _vm.defaultCurrency
                            )
                          ),
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: {
                label: _vm.$t("invoices.account_statement_abbr"),
                show: "account_statement",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("invoices.account_statement_abbr"))
                        ),
                      ]),
                      _vm._v(" "),
                      row.type != "Efectivo"
                        ? _c("icon-in-tax-return", {
                            attrs: {
                              status: row.account_statement,
                              module: "account_statement",
                            },
                          })
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: {
                label: _vm.$t("payments.general_public_invoices.abbr"),
                sortable: false,
                filterable: false,
                show: "general_public_invoice",
                "cell-class": "no-click",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return [
                      _c("span", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("payments.general_public_invoices.abbr")
                            ) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      row.type == "Efectivo"
                        ? _c("div", { staticClass: "text-center" }, [
                            _c("input", {
                              staticClass: "form-check-input",
                              attrs: { type: "checkbox", value: "1" },
                              domProps: {
                                checked: row.general_public_invoice == "1",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.toggleRowGeneralPublicInvoice(row)
                                },
                              },
                            }),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: {
                sortable: false,
                filterable: false,
                "cell-class": "action-dropdown no-click",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (row) {
                      return row.type == "Efectivo"
                        ? [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("payments.action"))),
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-dropdown",
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: { slot: "activator", href: "#" },
                                    slot: "activator",
                                  },
                                  [_c("dot-icon")],
                                  1
                                ),
                                _vm._v(" "),
                                _c("v-dropdown-item", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "dropdown-item",
                                      on: {
                                        click: function ($event) {
                                          return _vm.removePayment(row.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "dropdown-item-icon",
                                        attrs: { icon: ["fas", "trash"] },
                                      }),
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("general.delete")) +
                                          "\n              "
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]
                        : undefined
                    },
                  },
                ],
                null,
                true
              ),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("h5", [_vm._v("Facturas anteriores sin aplicar cobro")]),
        _vm._v(" "),
        _c(
          "table-component",
          {
            ref: "tableWithoutPayment",
            attrs: {
              data: _vm.fetchDataWithoutPayment,
              "filter-no-results": _vm.$t("general.filterNoResults"),
              "is-clickable": true,
              "show-filter": false,
              "table-class": "table",
            },
            on: { columnClick: _vm.rowClicked },
          },
          [
            _c("table-column", {
              attrs: { label: _vm.$t("payments.date"), show: "date" },
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: {
                label: _vm.$t("payments.payment_number"),
                show: "invoice_number",
              },
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: {
                label: _vm.$t("expenses.provider"),
                show: "receiver_name",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return [
                      _c("span", [_vm._v(_vm._s(_vm.$t("expenses.provider")))]),
                      _vm._v(" "),
                      row.receiver_rfc == _vm.currentCustomer.rfc
                        ? _c("sub-string", {
                            attrs: { string: row.issuing_name },
                          })
                        : _c("sub-string", {
                            attrs: { string: row.receiver_name },
                          }),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: {
                label: _vm.$t("expenses.provider_rfc"),
                show: "receiver_rfc",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("expenses.provider_rfc"))),
                      ]),
                      _vm._v(" "),
                      row.receiver_rfc == _vm.currentCustomer.rfc
                        ? _c("sub-string", {
                            attrs: { string: row.issuing_rfc },
                          })
                        : _c("sub-string", {
                            attrs: { string: row.receiver_rfc },
                          }),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: { label: _vm.$t("payments.type"), show: "type" },
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: { label: _vm.$t("payments.sub_total"), show: "sub_total" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("payments.sub_total"))),
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$utils.formatMoney(
                              row.sub_total,
                              _vm.defaultCurrency
                            )
                          ),
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: { label: _vm.$t("payments.iva"), show: "iva" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return [
                      _c("span", [_vm._v(_vm._s(_vm.$t("payments.iva")))]),
                      _vm._v(" "),
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$utils.formatMoney(row.iva, _vm.defaultCurrency)
                          ),
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: {
                label: _vm.$t("payments.retention_iva"),
                show: "retention_iva",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("payments.retention_iva"))),
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$utils.formatMoney(
                              row.retention_iva,
                              _vm.defaultCurrency
                            )
                          ),
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: {
                label: _vm.$t("payments.retention_isr"),
                show: "retention_isr",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("payments.retention_isr"))),
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$utils.formatMoney(
                              row.retention_isr,
                              _vm.defaultCurrency
                            )
                          ),
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: { label: _vm.$t("payments.total"), show: "amount" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return [
                      _c("span", [_vm._v(_vm._s(_vm.$t("payments.total")))]),
                      _vm._v(" "),
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$utils.formatMoney(
                              row.amount,
                              _vm.defaultCurrency
                            )
                          ),
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: {
                label: _vm.$t("invoices.account_statement_abbr"),
                show: "account_statement",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("invoices.account_statement_abbr"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("icon-in-tax-return", {
                        attrs: {
                          status: row.account_statement,
                          module: "account_statement",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }