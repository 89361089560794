var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.invoiceId
    ? _c(
        "router-link",
        {
          attrs: {
            to: { name: "invoices.view", params: { id: _vm.invoiceId } },
            title: _vm.invoiceId,
          },
        },
        [_vm._v("\n  " + _vm._s(_vm.uuid) + "\n")]
      )
    : _c("div", [_vm._v("\n  " + _vm._s(_vm.uuid) + "\n")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }