var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-content" }, [
    _c("div", { staticClass: "page-header mt-4" }, [
      _c("h3", { staticClass: "page-title" }, [
        _vm._v("\n      " + _vm._s(_vm.$t("tax_losses.title")) + "\n    "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page-actions row" },
        [
          _c(
            "base-button",
            {
              staticClass: "col-xs-2",
              attrs: { color: "theme", icon: "plus", size: "large" },
              on: { click: _vm.showAddModal },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("tax_losses.add_tax_loss")) +
                  "\n      "
              ),
            ]
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card p-3" }, [
      _c("div", { staticClass: "table-responsive" }, [
        _c("table", { staticClass: "table table-bordered" }, [
          _c("thead", [
            _c("tr", [
              _c("th", { staticClass: "text-center" }, [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.$t("tax_losses.year")) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c("th", { staticClass: "text-center" }, [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.$t("tax_losses.tax_return_type")) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c("th", { staticClass: "text-center" }, [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.$t("tax_losses.operation_number")) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c("th", { staticClass: "text-center" }, [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.$t("tax_losses.date_of_presentation")) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c("th", { staticClass: "text-right" }, [
                _vm._v(_vm._s(_vm.$t("tax_losses.amount"))),
              ]),
              _vm._v(" "),
              _c("th", { staticClass: "text-right" }, [
                _vm._v(_vm._s(_vm.$t("tax_losses.isr_in_favor"))),
              ]),
              _vm._v(" "),
              _c("th", { staticClass: "text-right" }, [
                _vm._v(_vm._s(_vm.$t("tax_losses.iva_in_favor"))),
              ]),
              _vm._v(" "),
              _c("th"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.taxLosses, function (taxLoss) {
              return _c("tr", { key: taxLoss.id }, [
                _c("td", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(taxLoss.year)),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "text-center" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.$t(`tax_losses.${taxLoss.tax_return_type}`)) +
                      "\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(taxLoss.operation_number)),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(taxLoss.presentation_date)),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [_c("money-text", { attrs: { amount: taxLoss.amount } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: { amount: taxLoss.isr_in_favor },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-right" },
                  [
                    _c("money-text", {
                      attrs: { amount: taxLoss.iva_in_favor },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "flex-content-right" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "base-button btn btn-sm btn-primary mr-2",
                        attrs: {
                          to: {
                            name: "tax-return.tax-loss.details",
                            params: { id: taxLoss.id },
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("tax_losses.details")) +
                            "\n              "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "base-button",
                      {
                        attrs: {
                          color: "danger",
                          size: "small",
                          icon: "trash",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.removeTaxLoss(taxLoss)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("general.delete")) +
                            "\n              "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            }),
            0
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }