<template>
  <div class="category-button" @click="showModal">
    <sub-string :string="categoryString" :length="45" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
const quickAssignmentEventCategory = 'quick_assigment_use_category'
export default {
  props: {
    concept: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters('invoice', ['quickAssignmentMatches']),
    categoryString() {
      if (this.concept.quickAssignment.subcategory) {
        return (
          this.concept.quickAssignment.subcategory.concept_category.name +
          '/' +
          this.concept.quickAssignment.subcategory.name
        )
      }
      return this.$t('concept_categories.uncategorized')
    },
  },
  mounted() {
    window.hub.$on(quickAssignmentEventCategory, (data) => {
      if (this.concept.id == data.invoice_item_id) {
        this.quickAssignmentChangeCategoryForProdServ({
          conceptId: this.concept.id,
          prodServ: this.concept.clave_prod_serv,
          subcategory: data.subcategory,
        })
      }
    })
  },
  methods: {
    ...mapActions('modal', ['openModal']),
    ...mapActions('conceptCategories', [
      'fetchSubcategory',
      'setModalChangeSubcategoryData',
    ]),
    ...mapActions('invoice', ['quickAssignmentChangeCategoryForProdServ']),
    showModal() {
      this.setModalChangeSubcategoryData({
        invoice_id: null,
        invoice_item_id: this.concept.id,
        event: quickAssignmentEventCategory,
      })
      this.openModal({
        title: this.$t('concept_categories.modal.change_category'),
        componentName: 'ChangeCategoryToInvoiceConceptModal',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.category-button {
  cursor: pointer;
  color: #0966c3;
}
</style>
