var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.taxLoss
    ? _c("div", { staticClass: "main-content mt-5" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 col-md-6" }, [
            _c("div", { staticClass: "card p-2" }, [
              _c("div", { staticClass: "table-responsive" }, [
                _c("table", { staticClass: "table" }, [
                  _c("thead"),
                  _vm._v(" "),
                  _c("tbody", [
                    _c("tr", [
                      _c("th", [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.$t("tax_losses.year")) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.taxLoss.year) +
                            "\n                "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("th", [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.$t("tax_losses.tax_return_type")) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.$t(
                                `tax_losses.${_vm.taxLoss.tax_return_type}`
                              )
                            ) +
                            "\n                "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("th", [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.$t("tax_losses.periodicity")) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.$t(`tax_losses.${_vm.taxLoss.periodicity}`)
                            ) +
                            "\n                "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t("tax_losses.operation_number"))),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm.taxLoss.operation_number)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("th", [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.$t("tax_losses.date_of_presentation")) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm.taxLoss.presentation_date)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t("tax_losses.amount")))]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: { amount: _vm.taxLoss.amount },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t("tax_losses.isr_in_favor"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: { amount: _vm.taxLoss.isr_in_favor },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("th", [
                        _vm._v(_vm._s(_vm.$t("tax_losses.iva_in_favor"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: { amount: _vm.taxLoss.iva_in_favor },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-md-6" }, [
            _c("div", { staticClass: "card p-2" }, [
              _c("div", { staticClass: "table-responsive" }, [
                _c("table", { staticClass: "table table-bordered" }, [
                  _c("thead", [
                    _c("tr", [
                      _c(
                        "th",
                        { staticClass: "text-center", attrs: { colspan: "4" } },
                        [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.$t("tax_losses.updates")) +
                              "\n                "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("th", { staticClass: "text-center" }, [
                        _vm._v(_vm._s(_vm.$t("general.month"))),
                      ]),
                      _vm._v(" "),
                      _c("th", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm.$t("tax_losses.tax_loss"))),
                      ]),
                      _vm._v(" "),
                      _c("th", { staticClass: "text-right" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.$t("tax_losses.total_updated")) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("th", { staticClass: "text-right" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.$t("tax_losses.isr_in_favor")) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("th", { staticClass: "text-right" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.$t("tax_losses.iva_in_favor")) +
                            "\n                "
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.taxLossUpdates, function (update) {
                      return _c("tr", { key: update.id }, [
                        _c("td", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(_vm.getMonth(update.month))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: { amount: update.amount },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: { amount: update.total_amount },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: { amount: update.isr_amount },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: { amount: update.iva_amount },
                            }),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }