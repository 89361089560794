var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "invoice-index-page invoices main-content" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c("h3", { staticClass: "page-title" }, [
          _vm._v(_vm._s(_vm.$t("Descarga masiva"))),
        ]),
        _vm._v(" "),
        _vm._m(0),
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showFilters,
                expression: "showFilters",
              },
            ],
            staticClass: "filter-section",
          },
          [
            _c("div", { staticClass: "filter-container" }, [
              _c(
                "div",
                { staticClass: "filter-customer" },
                [
                  _c("label", [
                    _vm._v(_vm._s(_vm.$tc("customers.customer", 1)) + " "),
                  ]),
                  _vm._v(" "),
                  _c("base-customer-select", {
                    ref: "customerSelect",
                    on: {
                      select: _vm.onSelectCustomer,
                      deselect: _vm.clearCustomerSearch,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "filter-date" }, [
                _c(
                  "div",
                  { staticClass: "from pr-3" },
                  [
                    _c("label", [_vm._v(_vm._s(_vm.$t("general.from")))]),
                    _vm._v(" "),
                    _c("base-date-picker", {
                      attrs: {
                        "calendar-button": true,
                        "calendar-button-icon": "calendar",
                      },
                      model: {
                        value: _vm.filters.from_date,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "from_date", $$v)
                        },
                        expression: "filters.from_date",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "dashed" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "to pl-3" },
                  [
                    _c("label", [_vm._v(_vm._s(_vm.$t("general.to")))]),
                    _vm._v(" "),
                    _c("base-date-picker", {
                      attrs: {
                        "calendar-button": true,
                        "calendar-button-icon": "calendar",
                      },
                      model: {
                        value: _vm.filters.to_date,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "to_date", $$v)
                        },
                        expression: "filters.to_date",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "filter-invoice" },
                [
                  _c("label", [
                    _vm._v(_vm._s(_vm.$t("invoices.invoice_number"))),
                  ]),
                  _vm._v(" "),
                  _c("base-input", {
                    attrs: { icon: "hashtag" },
                    model: {
                      value: _vm.filters.invoice_number,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "invoice_number", $$v)
                      },
                      expression: "filters.invoice_number",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showEmptyScreen,
              expression: "showEmptyScreen",
            },
          ],
          staticClass: "col-xs-1 no-data-info",
          attrs: { align: "center" },
        },
        [
          _c("moon-walker-icon", { staticClass: "mt-5 mb-4" }),
          _vm._v(" "),
          _c("div", { staticClass: "row", attrs: { align: "center" } }, [
            _c("label", { staticClass: "col title" }, [
              _vm._v(_vm._s(_vm.$t("invoices.no_invoices"))),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "label",
              {
                staticClass: "description col mt-1",
                attrs: { align: "center" },
              },
              [_vm._v(_vm._s(_vm.$t("invoices.list_of_invoices")))]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btn-container" },
            [
              _c(
                "base-button",
                {
                  staticClass: "mt-3",
                  attrs: { outline: true, color: "theme", size: "large" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push("invoices/create")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("invoices.new_invoice")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showEmptyScreen,
              expression: "!showEmptyScreen",
            },
          ],
          staticClass: "table-container",
        },
        [
          _c(
            "div",
            { staticClass: "table-actions mt-5" },
            [
              _c(
                "transition",
                { attrs: { name: "fade" } },
                [
                  _vm.selectedInvoices.length
                    ? _c(
                        "v-dropdown",
                        { attrs: { "show-arrow": false } },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "table-actions-button dropdown-toggle",
                              attrs: { slot: "activator", href: "#" },
                              slot: "activator",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("general.actions")) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-dropdown-item", [
                            _c(
                              "div",
                              {
                                staticClass: "dropdown-item",
                                on: { click: _vm.removeMultipleInvoices },
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "dropdown-item-icon",
                                  attrs: { icon: ["fas", "trash"] },
                                }),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("general.delete")) +
                                    "\n              "
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "table-component",
            {
              ref: "table",
              attrs: {
                "show-filter": false,
                data: _vm.fetchData,
                "table-class": "table",
              },
            },
            [
              _c("table-column", {
                attrs: {
                  label: _vm.$t("invoices.date"),
                  "sort-as": "created_at",
                  show: "created_at",
                },
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  label: _vm.$t("invoices.customer"),
                  width: "20%",
                  show: "user.name",
                },
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  label: _vm.$t("Fecha inicial Solicitada"),
                  show: "start_date_request",
                },
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  label: _vm.$t("Fecha final Solicitada"),
                  show: "end_date_request",
                },
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: { label: _vm.$t("Estatus"), show: "status" },
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: { label: _vm.$t("Tipo"), show: "request_type" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page-actions row" }, [
      _c("div", { staticClass: "col-xs-2 mr-4" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }