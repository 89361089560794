export const wayToPay = [
  {
    id: '01',
    description: 'Efectivo',
  },
  {
    id: '02',
    description: 'Cheque nominativo',
  },
  {
    id: '03',
    description: 'Transferencia electrónica de fondos',
  },
  {
    id: '04',
    description: 'Tarjeta de crédito',
  },
  {
    id: '05',
    description: 'Monedero electrónico',
  },
  {
    id: '06',
    description: 'Dinero electrónico',
  },
  {
    id: '08',
    description: 'Vales de despensa',
  },
  {
    id: '12',
    description: 'Dación en pago',
  },
  {
    id: '13',
    description: 'Pago por subrogación',
  },
  {
    id: '14',
    description: 'Pago por consignación',
  },
  {
    id: '15',
    description: 'Condonación',
  },
  {
    id: '17',
    description: 'Compensación',
  },
  {
    id: '23',
    description: 'Novación',
  },
  {
    id: '24',
    description: 'Confusión',
  },
  {
    id: '25',
    description: 'Remisión de deuda',
  },
  {
    id: '26',
    description: 'Prescripción o caducidad',
  },
  {
    id: '27',
    description: 'A satisfacción del acreedor',
  },
  {
    id: '28',
    description: 'Tarjeta de débito',
  },
  {
    id: '29',
    description: 'Tarjeta de servicios',
  },
  {
    id: '30',
    description: 'Aplicación de anticipos',
  },
  {
    id: '31',
    description: 'Intermediario pagos',
  },
  {
    id: '99',
    description: 'Por definir',
  },
]
