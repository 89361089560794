var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.taxReturn.rif
        ? _c("tax-return-rif", {
            attrs: {
              "tax-return": _vm.taxReturn.rif,
              "tax-load": _vm.loadInfoPass,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.taxReturn.arrendamiento
        ? _c("tax-return-arrendamiento", {
            attrs: {
              "tax-return": _vm.taxReturn.arrendamiento,
              "tax-load": _vm.loadInfoPass,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.taxReturn.digital_platforms
        ? _c("tax-return-digital-platform", {
            attrs: { "tax-return": _vm.taxReturn.digital_platforms },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.taxReturn.resico
        ? _c("tax-return-resico", {
            attrs: {
              "tax-return": _vm.taxReturn.resico,
              "tax-load": _vm.loadInfoPass,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.taxReturn.professional_activity
        ? _c("tax-return-professional-activity", {
            attrs: { "tax-return": _vm.taxReturn.professional_activity },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.taxReturn.yearly
        ? _c("tax-return-yearly", {
            attrs: {
              "tax-return": _vm.taxReturn.yearly,
              "blind-tax-return": _vm.filters.additional.blindTaxReturn,
            },
            on: {
              applyInterests: _vm.applyInterests,
              applyTaxLoss: _vm.applyTaxLoss,
              applyPersonalDeductions: _vm.applyPersonalDeductions,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.taxReturn.yearly_digitales
        ? _c("tax-return-yearly-digitales", {
            attrs: {
              "tax-return": _vm.taxReturn.yearly_digitales,
              "blind-tax-return": _vm.filters.additional.blindTaxReturn,
            },
            on: {
              applyInterests: _vm.applyInterests,
              applyTaxLoss: _vm.applyTaxLoss,
              applyPersonalDeductions: _vm.applyPersonalDeductions,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.taxReturn.yearly_resico
        ? _c("tax-return-yearly-resico", {
            attrs: {
              "tax-return": _vm.taxReturn.yearly_resico,
              "blind-tax-return": _vm.filters.additional.blindTaxReturn,
            },
            on: {
              applyInterests: _vm.applyInterests,
              applyTaxLoss: _vm.applyTaxLoss,
              applyPersonalDeductions: _vm.applyPersonalDeductions,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.haveTaxReturn && !_vm.showingDetails
        ? _c("div", { staticClass: "mt-4 mb-4 text-center" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                on: { click: _vm.saveTaxReturn },
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("tax_returns.save_tax_return")) +
                    "\n    "
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.taxReturn.status == "aceptado"
        ? _c(
            "div",
            { staticClass: "mt-4 mb-4 text-center" },
            [
              _c(
                "base-button",
                {
                  attrs: { color: "theme", icon: "plus", size: "large" },
                  on: { click: _vm.goComplementaria },
                },
                [_vm._v("> Agregar Declaración Complementaria\n    ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }