<template>
  <div class="main-content mt-5">
    <div class="page-header">
      <h3 class="page-title">
        Estados de cuenta
      </h3>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card p-4">
          <table-component ref="table" :data="taxReturns" :filter-no-results="$t('general.filterNoResults')"
            :is-clickable="true" :show-filter="false" table-class="table">
            <table-column label="PERÍODO">
              <template slot-scope="row">
                <div v-if="row.complementaria">
                  <font-awesome-icon class="icon" icon="file-invoice" />
                </div>
                <div>
                  {{ row.month }}
                </div>
              </template>
            </table-column>
            <table-column label="AÑO" show="year" />
            <table-column label="" cell-class="no-click">

              <template slot-scope="row">
                <base-button size="small" color="theme" @click="verArchivos(row.id)">
                  Ver archivos
                </base-button>
              </template>
            </table-column>
          </table-component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MonthPeriod from '../components/MonthPeriod.vue'
import TaxRegimeCatalog from '../components/TaxRegimeCatalog.vue'
import IconInCustomerTaxReturn from '../components/IconInCustomerTaxReturn'
import TableColumn from '../../components/base/base-table/components/TableColumn.vue'
export default {
  components: {
    MonthPeriod,
    TaxRegimeCatalog,
    IconInCustomerTaxReturn,
    TableColumn,
  },
  data: () => ({
    taxReturns: [],
    months: [
      { id: '01', name: 'Enero' },
      { id: '02', name: 'Febrero' },
      { id: '03', name: 'Marzo' },
      { id: '04', name: 'Abril' },
      { id: '05', name: 'Mayo' },
      { id: '06', name: 'Junio' },
      { id: '07', name: 'Julio' },
      { id: '08', name: 'Agosto' },
      { id: '09', name: 'Septiembre' },
      { id: '10', name: 'Octubre' },
      { id: '11', name: 'Noviembre' },
      { id: '12', name: 'Diciembre' },
    ],
  }),
  computed: {
    ...mapGetters('CurrentCustomer', ['currentCustomer']),
    ...mapGetters('currency', ['defaultCurrency']),
  },
  mounted() {
    this.loadTaxReturns()
    sessionStorage.removeItem('idChatDeclaracion')
    sessionStorage.removeItem('periodoChatDeclaracion')
    sessionStorage.removeItem('periodTaxReturn')
    sessionStorage.removeItem('yearTaxReturn')
  },

  methods: {
    ...mapActions('statementAccount', [
      'listStatementAccount',
    ]),

    period(startMonth, endMonth) {
      if (!startMonth && !endMonth) {
        return ''
      } else if (startMonth == endMonth) {
        const month = this.months.find(
          (m) => Number(m.id) == Number(startMonth)
        )
        return month.name
      }
      const cStartMonth = this.months.find(
        (m) => Number(m.id) == Number(startMonth)
      )
      const cEndMonth = this.months.find(
        (m) => Number(m.id) == Number(endMonth)
      )

      return `${cStartMonth.name} - ${cEndMonth.name}`
    },
    loadTaxReturns() {
      const params = {
        customer: this.currentCustomer.id,
      }
      this.listStatementAccount(params).then((response) => {
        console.log(response.data.assigned);
        this.taxReturns = (response.data.assigned)
      })
    },
    enviaDeclaracion(id, statusIcon, periodo) {
      if (!statusIcon) {
        this.sendMessage(
          id,
          'Hola, le dejo su declaración de ' +
          periodo +
          ' para que la revise y en su caso autorice el envío al SAT.'
        )
      } else if (statusIcon == 'comentarios') {
        sessionStorage.setItem('idChatDeclaracion', id)
        sessionStorage.setItem('periodoChatDeclaracion', periodo)
        this.$router.push({
          name: 'chat.index',
        })
      }
    },
    async sendMessage(idTaxReturn, message) {
      window
        .swal({
          title: this.$t('general.are_you_sure'),
          text: this.$t('tax_returns.confirm_send_declaracion'),
          icon: '/assets/icon/paper-plane-solid.svg',
          buttons: true,
          dangerMode: true,
        })
        .then(async (value) => {
          if (value) {
            await this.messageTaxReturns({
              idTaxReturn: idTaxReturn,
              message: message,
            })
            this.loadTaxReturns()
          }
        })
    },
    rowClicked(rowData) {
      this.$router.push({
        name: 'tax-return.show',
        params: {
          id: rowData.row.id,
        },
      })
    },

    goChat(id, periodo) {
      sessionStorage.setItem('idChatDeclaracion', id)
      sessionStorage.setItem('periodoChatDeclaracion', periodo)
      this.$router.push({
        name: 'chat.index',
      })
    },

    verArchivos(id) {
      this.$router.push(
        `/general/customer/statement-account-list/${id}`,
      )

    }
  },
}
</script>
