var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.customer
    ? _c("div", { staticClass: "main-content" }, [
        _c("div", { staticClass: "page-content pt-5" }, [
          _c("h3", { staticClass: "page-title" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("customers.sat_settings.page_title")) +
                "\n    "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3" },
            [
              _c("div", { staticClass: "card p-3" }, [
                _c("div", { staticClass: "form-group text-center" }, [
                  _c("div", [_vm._v(_vm._s(_vm.customer.name))]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.customer.rfc))]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("tax-regime-catalog", {
                        attrs: { "tax-regime": _vm.customer.tax_regime },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "ul",
                  {
                    staticClass: "nav nav-pills nav-fill",
                    attrs: { id: "pills-tab", role: "tablist" },
                  },
                  [
                    _c("li", { staticClass: "nav-item" }, [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link active",
                          attrs: {
                            id: "pills-general-tab",
                            "data-toggle": "pill",
                            href: "#navGeneral",
                            role: "tab",
                            "aria-controls": "pills-general",
                            "aria-selected": "true",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("customers.sat_settings.general"))
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._m(0),
                    _vm._v(" "),
                    _vm._m(1),
                  ]
                ),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "tab-content",
                    attrs: { id: "pills-tabContent" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "tab-pane fade show active",
                        attrs: {
                          id: "navGeneral",
                          role: "tabpanel",
                          "aria-labelledby": "pills-general-tab",
                        },
                      },
                      [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return _vm.submit.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.$t(
                                          "customers.sat_settings.sat_registration_date"
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("base-date-picker", {
                                  attrs: {
                                    "calendar-button": true,
                                    "calendar-button-icon": "calendar",
                                  },
                                  model: {
                                    value: _vm.form.satRegistrationDate,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "satRegistrationDate",
                                        $$v
                                      )
                                    },
                                    expression: "form.satRegistrationDate",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t(
                                        "customers.sat_settings.prodserv_for_general_invoice"
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.prodservInvoicing,
                                    expression: "form.prodservInvoicing",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: { value: _vm.form.prodservInvoicing },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.form,
                                      "prodservInvoicing",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t("customers.sat_settings.concept")
                                    ) +
                                    "\n                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.concept,
                                    expression: "form.concept",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: { value: _vm.form.concept },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.form,
                                      "concept",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t(
                                        "customers.sat_settings.expedition_place"
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.cp,
                                    expression: "form.cp",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: { value: _vm.form.cp },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.form,
                                      "cp",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.$t(
                                          "customers.sat_settings.use_cfdi"
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("base-select", {
                                  attrs: {
                                    options: _vm.useCFDIOptions,
                                    searchable: true,
                                    "allow-empty": false,
                                    "show-labels": false,
                                    placeholder: _vm.$t(
                                      "customers.sat_settings.use_cfdi"
                                    ),
                                    label: "description",
                                    "track-by": "id",
                                  },
                                  model: {
                                    value: _vm.form.useCFDI,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "useCFDI", $$v)
                                    },
                                    expression: "form.useCFDI",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.customer.tax_regime == 606
                              ? _c("div", [
                                  _c(
                                    "h6",
                                    { staticClass: "text-center mb-0" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "customers.sat_settings.606_settings"
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "form-group" }, [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-8 flex-vertical-end",
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.$t(
                                                  "customers.sat_settings.tax_return_at_35_percent"
                                                )
                                              ) +
                                              "\n                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-4" },
                                        [
                                          _c("base-switch", {
                                            model: {
                                              value: _vm.form.taxReturnBlind606,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "taxReturnBlind606",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.taxReturnBlind606",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.customer.tax_regime == 625
                              ? _c("div", [
                                  _c(
                                    "h6",
                                    { staticClass: "text-center mb-0" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "customers.sat_settings.625_settings"
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c("label", [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.$t(
                                                "customers.sat_settings.625_digital_platform_type"
                                              )
                                            ) +
                                            "\n                  "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("base-select", {
                                        attrs: {
                                          options: _vm.digitalPlatformOptions,
                                          searchable: true,
                                          "allow-empty": false,
                                          "show-labels": false,
                                          placeholder: _vm.$t(
                                            "customers.select_currency"
                                          ),
                                          label: "description",
                                          "track-by": "key",
                                        },
                                        model: {
                                          value:
                                            _vm.form.typeDigitalPlatform625,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "typeDigitalPlatform625",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.typeDigitalPlatform625",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("hr"),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group text-center mt-4" },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    attrs: { type: "submit" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.$t("general.save")) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "tab-pane fade",
                        attrs: {
                          id: "pills-medida",
                          role: "tabpanel",
                          "aria-labelledby": "pills-medida-tab",
                        },
                      },
                      [
                        _c(
                          "table",
                          {
                            staticClass: "table table-striped table-responsive",
                          },
                          [
                            _vm._m(2),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              _vm._l(
                                _vm.form.unitCodes,
                                function (unit, index) {
                                  return _c(
                                    "tr",
                                    { key: unit.id, attrs: { index: index } },
                                    [
                                      _c(
                                        "td",
                                        {
                                          staticStyle: { "font-size": "12px" },
                                          attrs: { scope: "row" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(unit.id) +
                                              " - " +
                                              _vm._s(unit.description) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("td", [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "add-item-action",
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeItem(unit.id)
                                              },
                                            },
                                          },
                                          [
                                            _c("base-button", {
                                              attrs: {
                                                icon: "trash",
                                                color: "danger",
                                                size: "small",
                                                iconButton: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("base-select", {
                          staticStyle: { "font-size": "12px" },
                          attrs: {
                            options: _vm.unitCodeOptions,
                            searchable: true,
                            "allow-empty": false,
                            "show-labels": false,
                            label: "label",
                            "track-by": "id",
                          },
                          model: {
                            value: _vm.selectedUnit,
                            callback: function ($$v) {
                              _vm.selectedUnit = $$v
                            },
                            expression: "selectedUnit",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "add-item-action",
                            on: { click: _vm.addItemRow },
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-block btn-outline-success",
                                attrs: { type: "button" },
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "mr-2",
                                  attrs: { icon: "shopping-basket" },
                                }),
                                _vm._v("Agregar Unidad Medida\n              "),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "tab-pane fade",
                        attrs: {
                          id: "pills-productos",
                          role: "tabpanel",
                          "aria-labelledby": "pills-productos-tab",
                        },
                      },
                      [
                        _c(
                          "table",
                          {
                            staticClass: "table table-striped table-responsive",
                          },
                          [
                            _vm._m(3),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              _vm._l(
                                _vm.form.productServCodes,
                                function (prod, index) {
                                  return _c(
                                    "tr",
                                    { key: prod.id, attrs: { index: index } },
                                    [
                                      _c(
                                        "td",
                                        {
                                          staticStyle: { "font-size": "12px" },
                                          attrs: { scope: "row" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(prod.Value) +
                                              " - " +
                                              _vm._s(prod.Name) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("td", [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "add-item-action",
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeItemProdServ(
                                                  prod.Value
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("base-button", {
                                              attrs: {
                                                icon: "trash",
                                                color: "danger",
                                                size: "small",
                                                iconButton: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm._m(4),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-10" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.keyWord,
                                    expression: "keyWord",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: { value: _vm.keyWord },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.keyWord = $event.target.value
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-2" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    return _vm.searchProdServ()
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "right-icon",
                                  attrs: { icon: "search", color: "#88c349" },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm.clavesProdServ.length > 0
                          ? _c("div", { attrs: { id: "app" } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "text-uppercase text-bold",
                                  staticStyle: { "font-size": "12px" },
                                },
                                [
                                  _vm._v(
                                    "\n                Claves para guardar:\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "table",
                                {
                                  staticClass:
                                    "table table-striped table-hover",
                                },
                                [
                                  _vm._m(5),
                                  _vm._v(" "),
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      _vm.clavesProdServ,
                                      function (item, index) {
                                        return _c(
                                          "tr",
                                          {
                                            key: item.id,
                                            attrs: { index: index },
                                          },
                                          [
                                            _c("td", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "add-item-action",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addClaveProdServ(
                                                        item.elemento
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-block btn-outline-success",
                                                      attrs: { type: "button" },
                                                    },
                                                    [
                                                      _c("font-awesome-icon", {
                                                        staticClass: "mr-2",
                                                        attrs: {
                                                          icon: "fa-regular fa-cart-circle-plus",
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "Agregar\n                        "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-size": "12px",
                                                },
                                              },
                                              [_vm._v(_vm._s(item.Value))]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "font-size": "12px",
                                                },
                                              },
                                              [_vm._v(_vm._s(item.Name))]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "nav-item" }, [
      _c(
        "a",
        {
          staticClass: "nav-link",
          attrs: {
            id: "pills-medida-tab",
            "data-toggle": "pill",
            href: "#pills-medida",
            role: "tab",
            "aria-controls": "pills-medida",
            "aria-selected": "false",
          },
        },
        [_vm._v("Unidad Medida")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "nav-item" }, [
      _c(
        "a",
        {
          staticClass: "nav-link",
          attrs: {
            id: "pills-productos-tab",
            "data-toggle": "pill",
            href: "#pills-productos",
            role: "tab",
            "aria-controls": "pills-productos",
            "aria-selected": "false",
          },
        },
        [_vm._v("Productos/Servicios")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { "font-size": "12px" } }, [
          _vm._v("Claves de Unidad de Medida"),
        ]),
        _vm._v(" "),
        _c("th"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { "font-size": "12px" } }, [
          _vm._v(
            "\n                    Claves de Productos y Servicios\n                  "
          ),
        ]),
        _vm._v(" "),
        _c("th"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-group" }, [
      _c("label", { staticStyle: { "font-size": "12px" } }, [
        _vm._v(
          "\n                Palabra clave para buscar productos/servicios\n              "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _vm._v(" "),
        _c("th", { staticStyle: { "font-size": "12px" } }, [_vm._v("Clave")]),
        _vm._v(" "),
        _c("th", { staticStyle: { "font-size": "12px" } }, [_vm._v("Nombre")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }