var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card-body" },
    [
      _c("p", { staticClass: "form-title" }, [
        _vm._v(_vm._s(_vm.$t("wizard.req.system_req"))),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "form-desc" }, [
        _vm._v(_vm._s(_vm.$t("wizard.req.system_req_desc"))),
      ]),
      _vm._v(" "),
      _vm.phpSupportInfo
        ? _c("div", { staticClass: "d-flex justify-content-start" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c("div", { staticClass: "row list-items" }, [
                _c("div", { staticClass: "col-md-9 left-item" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("wizard.req.php_req_version", {
                          version: _vm.phpSupportInfo.minimum,
                        })
                      ) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-3 right-item justify-content-end" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.phpSupportInfo.current) +
                        "\n          "
                    ),
                    _vm.phpSupportInfo.supported
                      ? _c("span", { staticClass: "verified" })
                      : _c("span", { staticClass: "not-verified" }),
                  ]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.requirements
        ? _c("div", { staticClass: "d-flex justify-content-start" }, [
            _c(
              "div",
              { staticClass: "col-md-6" },
              _vm._l(_vm.requirements, function (requirement, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "row list-items" },
                  [
                    _c("div", { staticClass: "col-md-9 left-item" }, [
                      _vm._v("\n          " + _vm._s(index) + "\n        "),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "col-md-3 right-item justify-content-end",
                      },
                      [
                        requirement
                          ? _c("span", { staticClass: "verified" })
                          : _c("span", { staticClass: "not-verified" }),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasNext
        ? _c(
            "base-button",
            {
              staticClass: "pull-right mt-4",
              attrs: {
                loading: _vm.loading,
                icon: "arrow-right",
                color: "theme",
                "right-icon": "",
              },
              on: { click: _vm.next },
            },
            [_vm._v("\n    " + _vm._s(_vm.$t("wizard.continue")) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.requirements
        ? _c(
            "base-button",
            {
              staticClass: "pull-right mt-4",
              attrs: { loading: _vm.loading, color: "theme" },
              on: { click: _vm.getRequirements },
            },
            [_vm._v("\n    " + _vm._s(_vm.$t("wizard.req.check_req")) + "\n  ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }