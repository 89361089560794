var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "relate-invoice-to-treasury-account" }, [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _vm.dataToRelateToTreasuryAccount.invoice
          ? _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col-6 col-sm-6 col-md-4 col-lg-4 text-center mt-2",
                },
                [
                  _c("strong", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$t("invoices.serie"))),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.dataToRelateToTreasuryAccount.invoice.serie) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col-6 col-sm-6 col-md-4 col-lg-4 text-center mt-2",
                },
                [
                  _c("strong", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$t("invoices.invoice_number"))),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.dataToRelateToTreasuryAccount.invoice.invoice_number
                      ) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col-12 col-sm-6 col-md-4 col-lg-4 text-center mt-2",
                },
                [
                  _c("strong", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$t("invoices.uuid"))),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.dataToRelateToTreasuryAccount.invoice.uuid) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.dataToRelateToTreasuryAccount.typeRelate == "payments"
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-6 col-sm-6 col-md-4 col-lg-4 text-center mt-2",
                    },
                    [
                      _c("strong", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.$t("payments.customer"))),
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.dataToRelateToTreasuryAccount.invoice
                              .receiver_name
                          ) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dataToRelateToTreasuryAccount.typeRelate == "payments"
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-6 col-sm-6 col-md-4 col-lg-4 text-center mt-2",
                    },
                    [
                      _c("strong", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.$t("payments.customer_rfc"))),
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.dataToRelateToTreasuryAccount.invoice
                              .receiver_rfc
                          ) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dataToRelateToTreasuryAccount.typeRelate == "expenses"
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-6 col-sm-6 col-md-4 col-lg-4 text-center mt-2",
                    },
                    [
                      _c("strong", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.$t("expenses.provider"))),
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.dataToRelateToTreasuryAccount.invoice
                              .issuing_name
                          ) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dataToRelateToTreasuryAccount.typeRelate == "expenses"
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-6 col-sm-6 col-md-4 col-lg-4 text-center mt-2",
                    },
                    [
                      _c("strong", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.$t("expenses.provider_rfc"))),
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.dataToRelateToTreasuryAccount.invoice
                              .issuing_rfc
                          ) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col-6 col-sm-6 col-md-4 col-lg-4 text-center mt-2",
                },
                [
                  _c("strong", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$t("invoices.total"))),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$utils.formatMoney(
                          _vm.dataToRelateToTreasuryAccount.invoice.total,
                          _vm.defaultCurrency
                        )
                      ),
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col-6 col-sm-6 col-md-4 col-lg-4 text-center mt-2",
                },
                [
                  _c("strong", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$t("treasury_accounts.remainder"))),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$utils.formatMoney(
                          _vm.dataToRelateToTreasuryAccount.invoice.total -
                            _vm.dataToRelateToTreasuryAccount.invoice
                              .related_amount,
                          _vm.defaultCurrency
                        )
                      ),
                    },
                  }),
                ]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "row mt-4" }, [
          _c("div", { staticClass: "col invoice-input" }, [
            _c(
              "div",
              { staticClass: "base-input" },
              [
                _c("label", [
                  _vm._v(
                    _vm._s(_vm.$t("treasury_accounts.relate_invoice_amount")) +
                      ":"
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "money",
                  _vm._b(
                    {
                      staticClass: "input-field",
                      on: {
                        input: function ($event) {
                          return _vm.$v.form.amount.$touch()
                        },
                      },
                      model: {
                        value: _vm.form.amount,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "amount", $$v)
                        },
                        expression: "form.amount",
                      },
                    },
                    "money",
                    _vm.customerCurrency,
                    false
                  )
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.$v.form.amount.$error
              ? _c("div", [
                  !_vm.$v.form.amount.between
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "validation.amount_greater_than_total_amount"
                            )
                          )
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mt-4 btn-container" },
          [
            _c(
              "base-button",
              {
                staticClass: "invoice-action-btn",
                attrs: {
                  loading: _vm.isLoading,
                  disabled: _vm.isLoading,
                  icon: "save",
                  color: "theme",
                  type: "submit",
                },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("treasury_accounts.relate_invoice")) +
                    "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }