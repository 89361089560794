<template>
  <div>
    <h6>
      <status-icon :status="expense.in_tax_return" module="in_tax_return" />
      {{ expense.serie }} {{ expense.invoice_number }}
    </h6>

    <div class="card p-1">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>{{ $t('invoices.item.description') }}</th>
              <th>{{ $t('invoices.item.clave_prod_serv') }}</th>
              <th class="text-center">{{ $t('invoices.item.quantity') }}</th>
              <th class="text-center amount-cell">
                {{ $t('invoices.item.amount') }}
              </th>
              <th class="text-center amount-cell">
                {{ $t('invoices.discount') }}
              </th>
              <th class="text-center amount-cell">{{ $t('invoices.iva') }}</th>
              <th class="text-center amount-cell">
                {{ $t('invoices.item.total') }}
              </th>
              <th>{{ $tc('invoices.category', 1) }}</th>
              <th class="text-center">{{ $tc('invoices.tax', 1) }}</th>
            </tr>
          </thead>
          <tbody>
            <expense-concept
              v-for="concept in expense.items"
              :concept="concept"
              :key="concept.id"
              :ref="`concept_${concept.id}`"
            />
          </tbody>
        </table>
      </div>

      <div class="text-right mt-2">
        <button
          :disabled="expense.confirmed"
          class="btn btn-primary btn-sm"
          @click="serializeInvoice"
        >
          {{ $t('general.confirm') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ExpenseConcept from './ExpenseConcept.vue'
import StatusIcon from './StatusIcon.vue'
export default {
  components: {
    StatusIcon,
    ExpenseConcept,
  },
  props: {
    expense: {
      type: Object,
      default: null,
    },
  },
  data: () => ({}),
  mounted() {},
  methods: {
    ...mapActions('invoice', [
      'quickAssignmentSerializeInvoice',
      'quickAssignmentSave',
      'quickAssignmentUpdateInvoiceInTaxReturnStatus',
    ]),
    serializeInvoice() {
      this.quickAssignmentSerializeInvoice(this.expense.id).then(
        (serialized) => {
          this.quickAssignmentSave(serialized)
            .then((response) => {
              if (response.data.success) {
                this.quickAssignmentUpdateInvoiceInTaxReturnStatus({
                  invoiceId: this.expense.id,
                  inTaxReturn: response.data.in_tax_return,
                })
                window.toastr.success(this.$t('expenses.expense_updated'))
              }
            })
            .catch((err) => {})
        }
      )
    },
  },
}
</script>

<style lang="scss" scoped>
th,
td {
  font-size: 12px;
}
</style>
