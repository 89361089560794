<template>
  <div class="category-modal">
    <form action="" @submit.prevent="submitUploadData">
      <div class="card-body">
        <div class="form-group row">
          <label class="col-sm-4 col-form-label input-label">Archivo a importar<span class="required text-danger">*</span></label>
          <div class="col-sm-7">
            <div class="image-upload-box">
                    <input ref="digital_stamp_key" class="" type="file" @change="onFileChange">
                    <!-- <img v-if="previewESignCer" :src="previewESignCer" class="preview-logo">
                    <div v-else class="upload-content">
                      <font-awesome-icon class="upload-icon" icon="cloud-upload-alt"/>
                      <p class="upload-text"> {{ $t('general.choose_file') }} </p>
                    </div> -->
                  </div>

            <!-- <div v-if="$v.formData.name.$error">
              <span v-if="!$v.formData.name.required" class="text-danger">{{ $tc('validation.required') }}</span>
              <span v-if="!$v.formData.name.minLength" class="text-danger"> {{ $tc('validation.name_min_length', $v.formData.name.$params.minLength.min, { count: $v.formData.name.$params.minLength.min }) }} </span>
            </div> -->
          </div>
        </div>
        
      </div>
      <div class="card-footer">
        <base-button
          :outline="true"
          class="mr-3"
          color="theme"
          @click="closeUploadModal"
        >
          {{ $t('general.cancel') }}
        </base-button>
        <base-button
          :loading="isLoading"
          icon="save"
          color="theme"
          type="submit"
        >
         Importar
        </base-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
const { required, minLength, maxLength } = require('vuelidate/lib/validators')
export default {
  mixins: [validationMixin],
  data () {
    return {
      isEdit: false,
      isLoading: false,
      formData: {
         excel_file: null
      }
    }
  },
  computed: {
    ...mapGetters('modal', [
      'modalDataID',
      'modalData',
      'modalActive'
    ])
  },
  validations: {
    formData: {
      excel_file: {
        required        
      }
    }
  },
  watch: {
    'modalDataID' (val) {
      if (val) {
        this.isEdit = true
        this.setData()
      } else {
        this.isEdit = false
      }
    },
    'modalActive' (val) {
      if (!this.modalActive) {
        this.resetFormData()
      }
    }
  },
  mounted () {
    
    if (this.modalDataID) {
      this.isEdit = true
      this.setData()
    }
  },
  destroyed () {

  },
  methods: {
    ...mapActions('modal', [
      'closeModal',
      'resetModalData'
    ]),
    ...mapActions('expense', [
      'importExpense',
      'fetchExpenses'
    ]),
    resetFormData () {
      this.formData = {
        excel_file: null
      }
      this.$v.formData.$reset()
    },
    async submitUploadData () {
      this.$v.formData.$touch()

      let data = new FormData()

      if (this.excel_file) {
        data.append('excel_file', this.excel_file)
      }
      this.isLoading = true

      let response    
      response = await this.importExpense(data)
     
      if (response.data.success) {       
        window.toastr['success'](response.data.success)       
        window.hub.$emit('importExpense', response.data.expense)
        this.closeUploadModal()
        this.isLoading = false
        this.fetchExpenses()
        
        return true
      }
      window.toastr['error'](response.data.error)
    },   
    closeUploadModal () {
      this.resetFormData()
      this.closeModal()
    },
    onFileChange (e) {
      var input = event.target
      this.excel_file = input.files[0]
      
      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = (e) => {
          this.previewESignCer = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      }
    }
  }
}
</script>
