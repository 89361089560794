export const paymentMethods = [
  {
    id: 'PUE',
    description: 'Pago en una sola exhibición',
  },
  {
    id: 'PPD',
    description: 'Pago en parcialidades o diferido',
  },
]
