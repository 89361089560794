var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: { maxHeight: _vm.stylesHeight, overflowY: "scroll" } },
    [
      _c("div", { staticClass: "row mt-1" }, [
        _c(
          "div",
          { staticClass: "col col-sm-8 offset-sm-2" },
          [
            _c("base-input", {
              model: {
                value: _vm.form.search,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "search", $$v)
                },
                expression: "form.search",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-responsive" },
        [
          _c(
            "table-component",
            {
              ref: "table",
              attrs: {
                "show-filter": false,
                data: _vm.fetchData,
                "table-class": "table",
                "is-clickable": _vm.click,
              },
              on: { columnClick: _vm.useCategory },
            },
            [
              _c("table-column", {
                attrs: { label: _vm.$t("concept_categories.category") },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(row.concept_category.name) +
                              "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: { label: _vm.$t("concept_categories.subcategory") },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("div", [
                          _vm._v(
                            "\n            " + _vm._s(row.name) + "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }