<template>
  <div class="h-100" style="background-color: #1b144a">
    <div class="row h-100">
      <div class="col-lg-6 d-none d-lg-block" style="background-color: #e2f0d9">
        <div
          class="w-100 h-100 d-flex justify-content-center align-items-center"
        >
          <img
            src="/assets/img/logo-taxati.png"
            alt="Crater Logo"
            class="w-50"
          />
        </div>
      </div>
      <div class="col-lg-6">
        <div
          class="h-100 w-100 p-5 d-flex flex-column justify-content-center align-items-center"
        >
          <h1 class="text-center text-white">Registro</h1>
          <form
            id="registerForm"
            @submit.prevent="validateBeforeSubmit"
            class="w-100 px-5"
          >
            <div class="d-flex flex-column my-3">
              <div class="flex flex-row" :class="{ 'form-group': true }">
                <h6 class="input-label text-white w-50">
                  RFC <span class="text-danger"> * </span>
                </h6>
                <div style="width: 100%">
                  <base-input
                    :invalid="$v.registroData.rfc.$error"
                    v-model="registroData.rfc"
                    focus
                    type="text"
                    name="rfc"
                    @input="$v.registroData.rfc.$touch()"
                  />

                  <div v-if="$v.registroData.rfc.$error">
                    <span
                      v-if="!$v.registroData.rfc.required"
                      class="w-50 text-danger"
                    >
                      {{ $tc('validation.required') }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column my-3">
              <div class="flex flex-row" :class="{ 'form-group': true }">
                <h6 class="input-label text-white w-50">
                  Nombre o Razón Social <span class="text-danger"> * </span>
                </h6>
                <div style="width: 100%">
                  <base-input
                    :invalid="$v.registroData.razonSocial.$error"
                    v-model="registroData.razonSocial"
                    focus
                    type="text"
                    name="razonSocial"
                    @input="$v.registroData.razonSocial.$touch()"
                  />

                  <div v-if="$v.registroData.razonSocial.$error">
                    <span
                      v-if="!$v.registroData.razonSocial.required"
                      class="w-50 text-danger"
                    >
                      {{ $tc('validation.required') }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column my-3">
              <div class="flex flex-row" :class="{ 'form-group': true }">
                <h6 class="input-label text-white w-50">
                  Número telefónico <span class="text-danger"> * </span>
                </h6>
                <div style="width: 100%">
                  <base-input
                    :invalid="$v.registroData.telefono.$error"
                    v-model="registroData.telefono"
                    focus
                    type="text"
                    name="telefono"
                    @input="$v.registroData.telefono.$touch()"
                  />

                  <div v-if="$v.registroData.telefono.$error">
                    <span
                      v-if="!$v.registroData.telefono.required"
                      class="w-50 text-danger"
                    >
                      {{ $tc('validation.required') }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column my-3">
              <div class="flex flex-row" :class="{ 'form-group': true }">
                <h6 class="input-label text-white w-50">
                  Correo Electrónico <span class="text-danger"> * </span>
                </h6>
                <div style="width: 100%">
                  <base-input
                    :invalid="$v.registroData.correo.$error"
                    v-model="registroData.correo"
                    focus
                    type="email"
                    name="correo"
                    @input="$v.registroData.correo.$touch()"
                  />

                  <div v-if="$v.registroData.correo.$error">
                    <span
                      v-if="!$v.registroData.correo.required"
                      class="w-50 text-danger"
                    >
                      {{ $tc('validation.required') }}
                    </span>
                    <span
                      v-if="!$v.registroData.correo.email"
                      class="w-50 text-danger"
                    >
                      {{ $tc('validation.email_incorrect') }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column my-3">
              <div class="flex flex-row" :class="{ 'form-group': true }">
                <h6 class="input-label text-white w-50">
                  Contraseña <span class="text-danger"> * </span>
                </h6>
                <div style="width: 100%">
                  <base-input
                    v-model="registroData.password"
                    :invalid="$v.registroData.password.$error"
                    type="password"
                    name="password"
                    show-password
                    @input="$v.registroData.password.$touch()"
                  />

                  <div v-if="$v.registroData.password.$error">
                    <span
                      v-if="!$v.registroData.password.required"
                      class="w-50 text-danger"
                    >
                      {{ $tc('validation.required') }}
                    </span>
                    <span
                      v-if="!$v.registroData.password.minLength"
                      class="w-50 text-danger"
                    >
                      {{
                        $tc(
                          'validation.password_min_length',
                          $v.registroData.password.$params.minLength.min,
                          {
                            count:
                              $v.registroData.password.$params.minLength.min,
                          }
                        )
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-center mt-4">
              <base-button :loading="isLoading" type="submit" color="theme">
                Siguiente
                <font-awesome-icon
                  icon="arrow-right"
                  class="icon menu-icon ml-2"
                />
              </base-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import { mapActions } from 'vuex'

import { validationMixin } from 'vuelidate'
const { required, email, minLength } = require('vuelidate/lib/validators')

export default {
  components: {},
  mixins: [validationMixin],
  data() {
    return {
      registroData: {
        rfc: '',
        razonSocial: '',
        correo: '',
        telefono: '',
        password: '',
      },
      submitted: false,
      isLoading: false,
    }
  },
  validations: {
    registroData: {
      correo: {
        required,
        email,
      },
      rfc: {
        required,
      },
      razonSocial: {
        required,
      },
      telefono: {
        required,
      },
      password: {
        required,
        minLength: minLength(8),
      },
    },
  },
  methods: {
    ...mapActions('auth', ['register']),
    async validateBeforeSubmit() {
      this.$v.registroData.$touch()
      if (this.$v.$invalid) {
        return true
      }
      this.isLoading = true
      this.register(this.registroData)
        .then((res) => {
          if (res.status == 200) {
            this.$router.push('/wizard/contratacion')
          }
          this.isLoading = false
        })
        .catch((er) => {
          this.isLoading = false
        })
    },
  },
}
</script>
