var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "expenses main-content" }, [
    _c("div", { staticClass: "page-header mt-5" }, [
      _c("h3", { staticClass: "page-title" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$t("expenses.title")) +
            " | " +
            _vm._s(_vm.filters.month["name"]) +
            ",\n      " +
            _vm._s(_vm.filters.year) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page-actions row" },
        [
          _c(
            "div",
            { staticClass: "col-xs-2 mr-4" },
            [
              _c(
                "base-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: false,
                      expression: "false",
                    },
                  ],
                  attrs: {
                    outline: true,
                    icon: _vm.filterIcon,
                    size: "large",
                    "right-icon": "",
                    color: "theme",
                  },
                  on: { click: _vm.toggleFilter },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("general.filter")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "col-xs-2",
              attrs: { slot: "item-title", to: "expenses/create" },
              slot: "item-title",
            },
            [
              _c(
                "base-button",
                { attrs: { size: "large", icon: "plus", color: "theme" } },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("expenses.add_expense")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "co-xs-2",
              attrs: { to: { name: "expenses.quick-assign" } },
            },
            [
              _c(
                "base-button",
                { attrs: { size: "large", icon: "list-ol", color: "theme" } },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("expenses.list_invoices")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showEmptyScreen,
            expression: "showEmptyScreen",
          },
        ],
        staticClass: "col-xs-1 no-data-info",
        attrs: { align: "center" },
      },
      [
        _c("observatory-icon", { staticClass: "mt-5 mb-4" }),
        _vm._v(" "),
        _c("div", { staticClass: "row", attrs: { align: "center" } }, [
          _c("label", { staticClass: "col title" }, [
            _vm._v(_vm._s(_vm.$t("expenses.no_expenses"))),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "label",
            { staticClass: "description col mt-1", attrs: { align: "center" } },
            [_vm._v(_vm._s(_vm.$t("expenses.list_of_expenses")))]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col" },
            [
              _c(
                "base-button",
                {
                  staticClass: "mt-3",
                  attrs: { outline: true, color: "theme", size: "large" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push("expenses/create")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("expenses.add_new_expense")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.showEmptyScreen,
            expression: "!showEmptyScreen",
          },
        ],
        staticClass: "table-container",
      },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col col-md-8" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col col-md-6" }, [
                _c(
                  "div",
                  { staticClass: "text-right" },
                  [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$t("expenses.sub_total"))),
                    ]),
                    _vm._v(" "),
                    _c("money-text", {
                      attrs: { amount: Number(_vm.expenseTotals.subtotal) },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "text-right" },
                  [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$t("expenses.retention_isr"))),
                    ]),
                    _vm._v(" "),
                    _c("money-text", {
                      attrs: {
                        amount: Number(_vm.expenseTotals.retention_isr),
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col col-md-6" }, [
                _c(
                  "div",
                  { staticClass: "text-right" },
                  [
                    _c("strong", [_vm._v(_vm._s(_vm.$t("expenses.iva")))]),
                    _vm._v(" "),
                    _c("money-text", {
                      attrs: { amount: Number(_vm.expenseTotals.iva) },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "text-right" },
                  [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$t("expenses.retention_iva"))),
                    ]),
                    _vm._v(" "),
                    _c("money-text", {
                      attrs: {
                        amount: Number(_vm.expenseTotals.retention_iva),
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "text-right" },
                  [
                    _c("strong", [_vm._v(_vm._s(_vm.$t("expenses.total")))]),
                    _vm._v(" "),
                    _c("money-text", {
                      attrs: { amount: Number(_vm.expenseTotals.total) },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col col-md-4" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("general.name_or_rfc")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("base-input", {
                  attrs: { type: "text" },
                  model: {
                    value: _vm.filters.nameOrRFC,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "nameOrRFC", $$v)
                    },
                    expression: "filters.nameOrRFC",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "table-actions" },
          [
            _c("p", { staticClass: "table-stats" }, [
              _vm._v("\n        " + _vm._s(_vm.$t("general.showing")) + ": "),
              _c("b", [_vm._v(_vm._s(_vm.expenses.length))]),
              _vm._v("\n        " + _vm._s(_vm.$t("general.of")) + " "),
              _c("b", [_vm._v(_vm._s(_vm.totalExpenses))]),
            ]),
            _vm._v(" "),
            _c(
              "transition",
              { attrs: { name: "fade" } },
              [
                _vm.selectedExpenses.length
                  ? _c(
                      "v-dropdown",
                      {
                        staticClass: "action mr-5",
                        attrs: { "show-arrow": false, "theme-light": "" },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "table-actions-button dropdown-toggle",
                            attrs: { slot: "activator", href: "#" },
                            slot: "activator",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("general.actions")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("v-dropdown-item", [
                          _c(
                            "div",
                            {
                              staticClass: "dropdown-item",
                              on: { click: _vm.removeMultipleExpenses },
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "dropdown-item-icon",
                                attrs: { icon: ["fas", "trash"] },
                              }),
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("general.delete")) +
                                  "\n            "
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "table-component",
          {
            ref: "table",
            attrs: {
              data: _vm.fetchData,
              "filter-no-results": _vm.$t("general.filterNoResults"),
              "is-clickable": true,
              "show-filter": false,
              "table-class": "table",
            },
            on: { columnClick: _vm.rowClick },
          },
          [
            _c("table-column", {
              attrs: {
                sortable: false,
                filterable: false,
                "cell-class": "no-click",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return [
                      row.cfdi_type == "N"
                        ? _c("div", {}, [
                            _c("img", {
                              staticClass: "nomina-icon",
                              attrs: { src: "/images/nomina_icon.svg" },
                            }),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: { label: _vm.$t("expenses.date"), show: "date" },
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: {
                label: _vm.$t("expenses.expense_number"),
                show: "invoice_number",
              },
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: {
                label: _vm.$t("expenses.provider"),
                show: "issuing_name",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return [
                      _c("span", [_vm._v(_vm._s(_vm.$t("expenses.provider")))]),
                      _vm._v(" "),
                      row.issuing_rfc == _vm.currentCustomer.rfc
                        ? _c("sub-string", {
                            attrs: { string: row.receiver_name },
                          })
                        : _c("sub-string", {
                            attrs: { string: row.issuing_name },
                          }),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: {
                label: _vm.$t("expenses.provider_rfc"),
                show: "issuing_rfc",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("expenses.provider_rfc"))),
                      ]),
                      _vm._v(" "),
                      row.issuing_rfc == _vm.currentCustomer.rfc
                        ? _c("sub-string", {
                            attrs: { string: row.receiver_rfc },
                          })
                        : _c("sub-string", {
                            attrs: { string: row.issuing_rfc },
                          }),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: { label: _vm.$t("expenses.type"), show: "type" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return [
                      _c("span", [_vm._v(_vm._s(_vm.$t("expenses.type")))]),
                      _vm._v(
                        "\n         " +
                          _vm._s(row.type) +
                          " - " +
                          _vm._s(row.cfdi_type) +
                          "\n        "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: { label: _vm.$t("expenses.sub_total"), show: "sub_total" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("expenses.sub_total"))),
                      ]),
                      _vm._v(" "),
                      _c("money-text", {
                        attrs: { amount: Number(row.sub_total) },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: { label: _vm.$t("payments.iva"), show: "iva" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return [
                      _c("span", [_vm._v(_vm._s(_vm.$t("payments.iva")))]),
                      _vm._v(" "),
                      _c("money-text", { attrs: { amount: Number(row.iva) } }),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: { label: _vm.$t("expenses.total"), show: "amount" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return [
                      _c("span", [_vm._v(_vm._s(_vm.$t("expenses.total")))]),
                      _vm._v(" "),
                      _c("money-text", {
                        attrs: { amount: Number(row.amount) },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: {
                sortable: false,
                label: _vm.$t("invoices.in_tax_return_abbr"),
                show: "in_tax_return",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("invoices.in_tax_return_abbr"))),
                      ]),
                      _vm._v(" "),
                      _c("icon-in-tax-return", {
                        attrs: {
                          status: row.in_tax_return,
                          module: "in_tax_return",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: {
                sortable: false,
                label: _vm.$t("invoices.account_statement_abbr"),
                show: "account_statement",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("invoices.account_statement_abbr"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("icon-in-tax-return", {
                        attrs: {
                          status: row.account_statement,
                          module: "account_statement",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: {
                sortable: false,
                filterable: false,
                "cell-class": "action-dropdown no-click",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (row) {
                      return row.type == "Pago"
                        ? [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("expenses.action"))),
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-dropdown",
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: { slot: "activator", href: "#" },
                                    slot: "activator",
                                  },
                                  [_c("dot-icon")],
                                  1
                                ),
                                _vm._v(" "),
                                _c("v-dropdown-item", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "dropdown-item",
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeExpense(row.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "dropdown-item-icon",
                                        attrs: { icon: ["fas", "trash"] },
                                      }),
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("general.delete")) +
                                          "\n              "
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]
                        : undefined
                    },
                  },
                ],
                null,
                true
              ),
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }