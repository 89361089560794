var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "table-responsive" }, [
    _c("h6", { staticClass: "text-center" }, [
      _vm._v(_vm._s(_vm.$t(_vm.title))),
    ]),
    _vm._v(" "),
    _c("table", { staticClass: "table table-bordered table-active" }, [
      _c("tbody", [
        _c(
          "tr",
          {
            staticClass: "clickable",
            on: {
              click: function ($event) {
                return _vm.toggleDisplayTable("iva_pagado_gastos_adquisiciones")
              },
            },
          },
          [
            _c(
              "td",
              [
                _c("font-awesome-icon", {
                  staticClass: "icon",
                  attrs: { icon: "arrow-down" },
                }),
                _vm._v(
                  "\n          IVA pagado en gastos y adquisiciones\n        "
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "td",
              { staticClass: "text-right" },
              [
                _c("money-text", {
                  attrs: {
                    amount:
                      _vm.taxReturn.amounts.iva_pagado_gastos_adquisiciones,
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _vm.displayTables.iva_pagado_gastos_adquisiciones
          ? _c("tr", [
              _c(
                "td",
                { attrs: { colspan: "2" } },
                [
                  _vm.taxReturn.tables.purchases
                    ? _c("tax-return-invoices-table", {
                        attrs: {
                          invoices: _vm.taxReturn.tables.purchases.invoices,
                          title: "IVA pagado en gastos y adquisiciones",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "tr",
          {
            staticClass: "clickable",
            on: {
              click: function ($event) {
                return _vm.toggleDisplayTable("iva_acreditable")
              },
            },
          },
          [
            _c(
              "td",
              [
                _c("font-awesome-icon", {
                  staticClass: "icon",
                  attrs: { icon: "arrow-down" },
                }),
                _vm._v(
                  "\n          IVA acreditable por actividades gravadas a tasa 16% u 8% y tasa 0%\n        "
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "td",
              { staticClass: "text-right" },
              [
                _c("money-text", {
                  attrs: { amount: _vm.taxReturn.amounts.iva_acreditable },
                }),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _vm.displayTables.iva_acreditable
          ? _c("tr", [
              _c("td", { attrs: { colspan: "2" } }, [
                _c(
                  "table",
                  { staticClass: "table table-bordered table-info" },
                  [
                    _c("tbody", [
                      _c("tr", [
                        _c("td", [
                          _vm._v("Total IVA pagado en gastos y adquisiones"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: {
                                amount:
                                  _vm.taxReturn.amounts
                                    .iva_pagado_gastos_adquisiciones,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "tr",
                        {
                          staticClass: "clickable",
                          on: {
                            click: function ($event) {
                              return _vm.toggleDisplayTable("proporcion_iva")
                            },
                          },
                        },
                        [
                          _c(
                            "td",
                            [
                              _c("font-awesome-icon", {
                                staticClass: "icon",
                                attrs: { icon: "arrow-down" },
                              }),
                              _vm._v(
                                "\n                  Proporción de IVA\n                "
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-right" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.taxReturn.amounts.proporcion_iva) +
                                "\n                "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.displayTables.proporcion_iva
                        ? _c("tr", [
                            _c("td", { attrs: { colspan: "2" } }, [
                              _c(
                                "table",
                                {
                                  staticClass:
                                    "table table-bordered table-success",
                                },
                                [
                                  _c("tbody", [
                                    _c("tr", [
                                      _c("td", [
                                        _vm._v(
                                          "Actos gravados 16% + Actos gravados 0%"
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "text-right" },
                                        [
                                          _c("money-text", {
                                            attrs: {
                                              amount:
                                                _vm.taxReturn.amounts
                                                  .actos_gravados_16_actos_gravados_0,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("td", [
                                        _vm._v(
                                          "\n                          Actos gravados 16% + Actos gravados 0%+ Actos\n                          exentos\n                        "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "text-right" },
                                        [
                                          _c("money-text", {
                                            attrs: {
                                              amount:
                                                _vm.taxReturn.amounts
                                                  .actos_gravados_16_actos_gravados_0_actos_excentros,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [_vm._v("IVA acreditable")]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: {
                                amount: _vm.taxReturn.amounts.iva_acreditable,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v("IVA acreditable del periodo")]),
          _vm._v(" "),
          _c(
            "td",
            { staticClass: "text-right" },
            [
              _c("money-text", {
                attrs: { amount: _vm.taxReturn.amounts.iva_acreditable },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("tr", [
          _c("td", [_vm._v("IVA no acreditable")]),
          _vm._v(" "),
          _c(
            "td",
            { staticClass: "text-right" },
            [
              _c("money-text", {
                attrs: { amount: _vm.taxReturn.amounts.iva_no_acreditable },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [_vm._v("*IVA acreditable por actividades mixtas")]),
      _vm._v(" "),
      _c("td", { staticClass: "text-right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }