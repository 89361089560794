export const catalogs = (state) => state.catalogs
export const rifIsr = (state) => state.rifIsr
export const rifIva = (state) => state.rifIva

export const compensacion = (state) => state.compensacion
export const acreditamiento = (state) => state.acreditamiento

export const ingresosCfdi = (state)=>state.ingresosCfdi
export const ingresoDisminuir = (state) => state.ingresoDisminuir
export const  ingresoAdicional = (state) => state.ingresoAdicional
export const  isrRetenido = (state) => state.isrRetenido




