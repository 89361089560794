<template>
  <div class="p-3">
    <form @submit.prevent="saveConceptCategory()">

      <div class="form-group">
        <label>{{ $t('concept_categories.category_name') }}</label>
        <base-input
          v-model="form.name"
          @change="$v.form.$touch()"
        />
          <div v-if="$v.form.name.$error" class="text-danger">
          {{ $t('concept_categories.validations.category_name_required') }}
        </div>
      </div>

      <div class="text-right">
        <button type="submit" class="btn btn-primary">
          {{ $t('invoices.save') }}
        </button>
      </div>

    </form>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

export default {
  mixins: [validationMixin],

  data: () => ({
    form: {
      name: '',
    }
  }),

  validations: {
    form: {
      name: {
        required,
      }
    }
  },

  methods: {
    ...mapActions('conceptCategories', [
      'fetchCreateConceptCategory',
    ]),
    ...mapActions('modal', [
      'closeModal',
    ]),

    saveConceptCategory ()
    {
      this.$v.form.$touch();
      if (this.$v.form.$invalid !== false) {
        return;
      }

      this.fetchCreateConceptCategory(this.form)
        .then(response => {
          this.closeModal();
          window.toastr['success'](this.$t('concept_categories.messages.category_created'));
          window.hub.$emit('concept_categories.category.created');
        })
        .catch(err => {
          window.toastr['error'](this.$t('concept_categories.errors.category_create'));
        })
    }

  }
}
</script>
