<template>
  <div v-if="taxReturn" class="table-responsive">
    <!-- Tabla isr -->
    <table class="table table-bordered">
      <thead>
        <tr class="table-primary">
          <th colspan="2" class="text-center">
            {{ $t('tax_returns.isr') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <!-- Ingresos cobrados -->
        <tr>
          <td>{{ $t('tax_returns.revenue_collected') }}</td>
          <td class="text-right">
            <money-text :amount="taxReturn.revenue_collected" />
          </td>
        </tr>
        <!-- Sueldos pagados -->
        <tr>
          <td>{{ $t('tax_returns.paid_salaries') }}</td>
          <td class="text-right">
            <money-text :amount="taxReturn.paid_salaries" />
          </td>
        </tr>
        <!-- Compras -->
        <tr>
          <td>{{ $t('tax_returns.purchases') }}</td>
          <td class="text-right">
            <money-text :amount="taxReturn.purchases_subtotal" />
          </td>
        </tr>
        <!-- Deducciones -->
        <tr>
          <td>{{ $t('tax_returns.deductions') }}</td>
          <td class="text-right">
            <money-text :amount="taxReturn.isr_in_tax_return" />
          </td>
        </tr>
        <!-- Exceso de gastos sobre ingresos  -->
        <tr>
          <td>
            {{ $t('tax_returns.excess_of_expenses_over_revenue') }}
          </td>
          <td class="text-right">
            <money-text :amount="taxReturn.excess_expenses_over_revenue" />
          </td>
        </tr>
        <!-- Exceso de gastos periodos anteriores  -->
        <tr>
          <td>
            {{ $t('tax_returns.excess_of_expenses_previous_period') }}
          </td>
          <td class="text-right">
            <money-text :amount="taxReturn.excess_expenses_previous_periods" />
          </td>
        </tr>
        <!-- Base para isr  -->
        <tr>
          <td>
            {{ $t('tax_returns.isr_base') }}
          </td>
          <td class="text-right">
            <money-text :amount="taxReturn.isr_base" />
          </td>
        </tr>
        <!-- Base Gravable  -->
        <tr>
          <td>
            {{ $t('tax_returns.taxable_base') }}
          </td>
          <td class="text-right">
            <money-text :amount="taxReturn.taxable_base" />
          </td>
        </tr>
        <!-- Limite inferior -->
        <tr>
          <td>
            {{ $t('rate_tables.lower_limit') }}
          </td>
          <td class="text-right">
            <money-text :amount="taxReturn.lower_limit" />
          </td>
        </tr>
        <!-- Excedente limite inferior -->
        <tr>
          <td>
            {{ $t('tax_returns.surplus_lower_limit') }}
          </td>
          <td class="text-right">
            <money-text :amount="taxReturn.surplus_lower_limit" />
          </td>
        </tr>
        <!-- Tasa S/Excedente -->
        <tr>
          <td>
            {{ $t('tax_returns.rate_on_surplus') }}
          </td>
          <td class="text-right">
            {{ taxReturn.rate_on_surplus }} %
            <!-- <money-text :amount="taxReturn.rate_on_surplus" /> -->
          </td>
        </tr>
        <!-- Importe marginal -->
        <tr>
          <td>
            {{ $t('tax_returns.marginal_amount') }}
          </td>
          <td class="text-right">
            <money-text :amount="taxReturn.marginal_amount" />
          </td>
        </tr>
        <!-- Cuota fija -->
        <tr>
          <td>
            {{ $t('rate_tables.fixed_fee') }}
          </td>
          <td class="text-right">
            <money-text :amount="taxReturn.fixed_fee" />
          </td>
        </tr>
        <!-- ISR Causado -->
        <tr>
          <td>
            {{ $t('tax_returns.isr_caused') }}
          </td>
          <td class="text-right">
            <money-text :amount="taxReturn.isr_caused" />
          </td>
        </tr>
        <!-- % Condonacion -->
        <tr>
          <td>
            {{ $t('tax_returns.condonation_percentage') }}
          </td>
          <td class="text-right">{{ taxReturn.condonation_percentage }} %</td>
        </tr>
        <!-- Cantidad condonada -->
        <tr>
          <td>
            {{ $t('tax_returns.amount_forgiven') }}
          </td>
          <td class="text-right">
            <money-text :amount="taxReturn.amount_forgiven" />
          </td>
        </tr>
        <!-- ISR a pagar -->
        <tr>
          <td>
            {{ $t('tax_returns.isr_to_pay') }}
          </td>
          <td class="text-right">
            <money-text :amount="taxReturn.isr_to_pay" />
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Tabla iva -->
    <table class="table table-bordered">
      <thead>
        <tr class="table-primary">
          <th colspan="2" class="text-center">
            {{ $t('tax_returns.iva') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <!-- INGRESOS POR VENTA AL PUBLICO EN GENERAL -->
        <tr>
          <td>
            {{ $t('tax_returns.revenue_general_public_sales') }}
          </td>
          <td class="text-right">
            <money-text :amount="taxReturn.revenue_general_public_sales" />
          </td>
        </tr>
        <!-- INGRESOS FACTURADOS CLIENTES INDIVIDUALES AL 16% -->
        <tr>
          <td>{{ $t('tax_returns.revenue_individual_sales') }}</td>
          <td class="text-right">
            <money-text :amount="taxReturn.revenue_individual_sales" />
          </td>
        </tr>
        <!-- TOTAL DE INGRESOS -->
        <tr>
          <td>{{ $t('tax_returns.total_revenue') }}</td>
          <td class="text-right">
            <money-text :amount="taxReturn.total_revenue" />
          </td>
        </tr>
        <!-- % DE IVA CAUSADO SOBRE VENTAS AL PUBLICO EN GENERAL -->
        <tr>
          <td>{{ $t('tax_returns.percent_iva_general_public') }}</td>
          <td class="text-right">
            {{ taxReturn.percentage_iva_general_public }} %
          </td>
        </tr>
        <!-- IVA POR VENTA AL PUBLICO EN GENERAL -->
        <tr>
          <td>{{ $t('tax_returns.iva_general_public_sales') }}</td>
          <td class="text-right">
            <money-text :amount="taxReturn.iva_general_public" />
          </td>
        </tr>
        <!-- % REDUCCION DE IVA -->
        <tr>
          <td>{{ $t('tax_returns.percentage_iva_reduction') }}</td>
          <td class="text-right">{{ taxReturn.percentage_iva_reduction }} %</td>
        </tr>
        <!-- REDUCCION DE IVA -->
        <tr>
          <td>{{ $t('tax_returns.iva_reduction') }}</td>
          <td class="text-right">
            <money-text :amount="taxReturn.iva_reduction" />
          </td>
        </tr>
        <!-- IVA CAUSADO DE VENTAS AL PUBLICO EN GENERAL -->
        <tr>
          <td>{{ $t('tax_returns.iva_caused_general_public_sales') }}</td>
          <td class="text-right">
            <money-text :amount="taxReturn.iva_caused_general_public_sales" />
          </td>
        </tr>
        <!-- IVA CAUSADO DE VENTAS INDIVIDUALES AL 16% -->
        <tr>
          <td>{{ $t('tax_returns.iva_caused_individual_sales') }}</td>
          <td class="text-right">
            <money-text :amount="taxReturn.iva_caused_induvidual_sales" />
          </td>
        </tr>
        <!-- TOTAL DE IVA CAUSADO -->
        <tr>
          <td>{{ $t('tax_returns.total_iva_caused') }}</td>
          <td class="text-right">
            <money-text :amount="taxReturn.total_iva_caused" />
          </td>
        </tr>
        <!-- Compras y gastos pagados 16% -->
        <tr>
          <td>
            {{ $t('tax_returns.purchases_and_expenses_paid_16_percent') }}
          </td>
          <td class="text-right">
            <money-text :amount="taxReturn.purchases_and_expenses_paid16" />
          </td>
        </tr>
        <!-- Compras y gastos no objeto -->
        <tr>
          <td>
            {{ $t('tax_returns.purchases_and_expenses_no_object') }}
          </td>
          <td class="text-right">
            <money-text :amount="taxReturn.purchases_and_expenses_no_object" />
          </td>
        </tr>
        <!-- Total de compras -->
        <tr>
          <td>
            {{ $t('tax_returns.total_purchases') }}
          </td>
          <td class="text-right">
            <money-text :amount="taxReturn.total_purchases" />
          </td>
        </tr>
        <!-- IVA pagado del bimestre -->
        <tr>
          <td>
            {{ $t('tax_returns.iva_paid_bimester') }}
          </td>
          <td class="text-right">
            <money-text :amount="taxReturn.iva_paid_bimester" />
          </td>
        </tr>
        <!-- Proporcion  (%) de IVA acreditable -->
        <tr>
          <td>
            {{ $t('tax_returns.proportion_of_iva_creditable') }}
          </td>
          <td class="text-right">
            {{ taxReturn.proportion_of_iva_creditable }} %
          </td>
        </tr>
        <!-- IVA acreditable -->
        <tr>
          <td>
            {{ $t('tax_returns.iva_creditable') }}
          </td>
          <td class="text-right">
            <money-text :amount="taxReturn.iva_creditable" />
          </td>
        </tr>
        <!-- IVA a cargo (FAVOR) -->
        <tr>
          <td>
            {{ $t('tax_returns.iva_in_charge') }}
          </td>
          <td class="text-right">
            <money-text :amount="taxReturn.iva_in_charge" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    taxReturn: {
      type: Object,
      default: null,
    },
  },
}
</script>
