import * as types from './mutation-types'

export default {
  [types.SET_TAX_LOSSES](state, data) {
    state.tax_losses = data
  },

  [types.SET_TAX_LOSS](state, data) {
    state.tax_loss = data
  },

  [types.REMOVE_TAX_LOSS](state, id) {
    state.tax_losses = state.tax_losses.filter((item) => {
      return item.id != id
    })
  },
}
