var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sidebar-left" }, [
    _c("div", { staticClass: "sidebar-body scroll-pane" }, [
      _c(
        "div",
        { staticClass: "side-nav" },
        [
          _c(
            "div",
            { staticClass: "current-customer" },
            [
              _c("small", [
                _vm._v(_vm._s(_vm.$t("customers.current_customer"))),
              ]),
              _c("br"),
              _vm._v(
                "\n        " + _vm._s(_vm.currentCustomer.rfc) + "\n        "
              ),
              _c("font-awesome-icon", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: { content: _vm.currentCustomer.textstatus },
                    expression: "{ content: currentCustomer.textstatus }",
                  },
                ],
                staticClass: "right-icon",
                attrs: {
                  icon: !_vm.currentCustomer.status
                    ? "exclamation-triangle"
                    : "",
                  color: "#eed202",
                },
              }),
              _vm._v(" "),
              _c("br"),
              _vm._v(
                "\n        " + _vm._s(_vm.currentCustomer.name) + "\n\n        "
              ),
              _c("br"),
              _vm._v(
                "\n        Periodo: " +
                  _vm._s(_vm.defaultMonth["name"]) +
                  " | " +
                  _vm._s(_vm.defaultYear) +
                  "\n        "
              ),
              _c("br"),
              _vm._v(" "),
              _c("font-awesome-icon", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: { content: "Quitar Usuario" },
                    expression: "{ content: 'Quitar Usuario' }",
                  },
                ],
                staticClass: "right-icon",
                attrs: { icon: "user-minus", color: "red" },
                on: { click: _vm.deselectCustomer },
              }),
              _vm._v(" "),
              _c("font-awesome-icon", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: { content: "Cambiar Periodo" },
                    expression: "{ content: 'Cambiar Periodo' }",
                  },
                ],
                staticClass: "right-icon ml-5",
                attrs: { icon: "box", color: "theme" },
                on: { click: _vm.changePeriodo },
              }),
              _vm._v(" "),
              _c("br"),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.menu, function (menuItems, index) {
            return _c(
              "div",
              { key: index + "a", staticClass: "menu-group" },
              _vm._l(menuItems, function (item, index1) {
                return _c(
                  "router-link",
                  {
                    key: index1 + "a",
                    staticClass: "menu-item",
                    class: {
                      disabled: item.require_customer && !_vm.currentCustomer,
                    },
                    attrs: { to: item.route },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.Toggle.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "icon menu-icon",
                      attrs: { icon: item.icon },
                    }),
                    _c("span", { staticClass: "ml-3 menu-text" }, [
                      _vm._v(_vm._s(_vm.$t(item.title))),
                    ]),
                  ],
                  1
                )
              }),
              1
            )
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }