import * as actions from './actions'

const initialState = {}

export default {
  namespaced: true,

  state: initialState,

  getters: {},

  actions: actions,

  mutations: {},
}
