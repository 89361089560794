<template>
  <div class="main-content">
    <div class="page-header mt-4">
      <h3 class="page-title">
        {{ $t('tax_losses.title') }}
      </h3>
      <div class="page-actions row">
        <base-button
          class="col-xs-2"
          color="theme"
          icon="plus"
          size="large"
          @click="showAddModal"
        >
          {{ $t('tax_losses.add_tax_loss') }}
        </base-button>
      </div>
    </div>

    <div class="card p-3">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th class="text-center">
                {{ $t('tax_losses.year') }}
              </th>
              <th class="text-center">
                {{ $t('tax_losses.tax_return_type') }}
              </th>
              <th class="text-center">
                {{ $t('tax_losses.operation_number') }}
              </th>
              <th class="text-center">
                {{ $t('tax_losses.date_of_presentation') }}
              </th>
              <th class="text-right">{{ $t('tax_losses.amount') }}</th>
              <th class="text-right">{{ $t('tax_losses.isr_in_favor') }}</th>
              <th class="text-right">{{ $t('tax_losses.iva_in_favor') }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="taxLoss in taxLosses" :key="taxLoss.id">
              <td class="text-center">{{ taxLoss.year }}</td>
              <td class="text-center">
                {{ $t(`tax_losses.${taxLoss.tax_return_type}`) }}
              </td>
              <td class="text-center">{{ taxLoss.operation_number }}</td>
              <td class="text-center">{{ taxLoss.presentation_date }}</td>
              <td class="text-right">
                <money-text :amount="taxLoss.amount" />
              </td>
              <td class="text-right">
                <money-text :amount="taxLoss.isr_in_favor" />
              </td>
              <td class="text-right">
                <money-text :amount="taxLoss.iva_in_favor" />
              </td>
              <td class="flex-content-right">
                <router-link
                  :to="{
                    name: 'tax-return.tax-loss.details',
                    params: { id: taxLoss.id },
                  }"
                  class="base-button btn btn-sm btn-primary mr-2"
                >
                  {{ $t('tax_losses.details') }}
                </router-link>
                <base-button
                  color="danger"
                  size="small"
                  icon="trash"
                  @click.prevent="removeTaxLoss(taxLoss)"
                >
                  {{ $t('general.delete') }}
                </base-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseButton from '../../components/base/BaseButton.vue'
export default {
  components: { BaseButton },
  data: () => ({}),
  computed: {
    ...mapGetters('CurrentCustomer', ['currentCustomer']),
    ...mapGetters('taxLossAndBalance', ['taxLosses']),
  },
  created() {
    this.loadTaxLosses()
  },
  mounted() {},
  methods: {
    ...mapActions('taxLossAndBalance', ['fetchTaxLosses', 'deleteTaxLoss']),
    ...mapActions('modal', ['openModal']),

    loadTaxLosses() {
      const params = {
        customer_id: this.currentCustomer.id,
      }
      this.fetchTaxLosses(params).then()
    },

    removeTaxLoss(taxLoss) {
      window
        .swal({
          title: this.$t('general.are_you_sure'),
          text: this.$tc('tax_losses.messages.confirm_delete'),
          icon: '/assets/icon/trash-solid.svg',
          buttons: true,
          dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
            this.deleteTaxLoss(taxLoss.id)
              .then((response) => {
                if (response.data.success) {
                  window.toastr.success(
                    this.$t('tax_losses.messages.deleted_message')
                  )
                } else {
                  window.toastr.error(
                    this.$t('tax_losses.errors.tax_loss_delete')
                  )
                }
              })
              .catch(() => {
                window.toastr.error(
                  this.$t('tax_losses.errors.tax_loss_delete')
                )
              })
          }
        })
    },

    showAddModal() {
      this.openModal({
        title: this.$t('tax_losses.title'),
        componentName: 'AddTaxLossModal',
      })
    },
  },
}
</script>
