<style>
li {
  z-index: 10;
}
</style>

<template>
  <div class="main-content">
    <div class="page-header mt-4" style="z-index: 1">
      <h3 class="page-title">Pendientes del mes</h3>
      <h6 class="page-subtitle">
        <font-awesome-icon
          v-if="filters.icon != ''"
          :icon="filters.icon"
          class="icon menu-icon"
          :style="{ color: '#88C349' }"
        />
        {{ filtersAppliedName }}
      </h6>

      <div class="page-actions row">
        <div class="col-xs-2 mr-2">
          <div class="btn-group" role="group">
            <v-dropdown :show-arrow="true">
              <button
                slot="activator"
                type="button"
                class="btn item-dropdown dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ 'Status' }}
              </button>
              <v-dropdown-item
                v-for="(item, index) in statusMenu"
                :key="index"
                style="width: 350px"
              >
                <a class="dropdown-item" @click="setFilter(item)">
                  <font-awesome-icon
                    :icon="item.icon"
                    class="icon menu-icon"
                    :style="{ color: '#88C349' }"
                  /><span class="ml-3 menu-text">{{ item.name }}</span>
                </a>
              </v-dropdown-item>
            </v-dropdown>
          </div>
        </div>
        <conditional-wrapper
          :show-for-admin="true"
          :show-for-executives="false"
          :show-for-customers="false"
        >
          <router-link class="col-xs-2" to="/general/customers/create">
            <base-button size="small" icon="plus" color="theme">
              {{ $t('customers.new_customer') }}
            </base-button>
          </router-link>
        </conditional-wrapper>
      </div>
    </div>
    <div class="">
      <div class="row">
        <div class="col-sm-3">
          <label class="form-label">Búsqueda</label>
          <base-input
            v-model="filters.search"
            type="text"
            name="search"
            autocomplete="on"
          />
        </div>
      </div>
    </div>
    <table-component
      ref="table"
      :show-filter="false"
      :data="fetchData"
      table-class="table"
      :is-clickable="true"
      @columnClick="rowClicked"
    >
      <table-column label="No. de cliente" show="id" />

      <table-column :label="$t('customers.name')" show="name">
        <template slot-scope="row">
          <span>{{ $t('customers.name') }}</span>
          <div>
            {{ row.name }}
            <certificate-warning
              :csd-expires-at="row.csd_expires_at"
              :fiel-expires-at="row.fiel_expires_at"
            />
            <span v-if="isWithinTwoWeeks(row.created_at)">
              <NewIcon />
            </span>
          </div>
        </template>
      </table-column>
      <table-column label="RFC" show="rfc" />
      <table-column label="Regimen">
        <template slot-scope="row">
          {{ searchTaxRegime(row.tax_regime).description }}
        </template>
      </table-column>
      <table-column label="Encargado">
        <template slot-scope="row">
          <!-- <conditional-wrapper :show-for-admin="true"> -->
          <conditional-wrapper
            :show-for-admin="true"
            :show-for-executives="false"
            :show-for-customers="false"
            :disable="true"
          >
            <v-dropdown :show-arrow="true">
              <button
                slot="activator"
                type="button"
                class="btn item-dropdown dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{
                  row.assigned_to ? row.assigned_to.user_b.name : 'Sin asignar'
                }}
              </button>
              <v-dropdown-item
                v-for="(item, index) in ejecutivos"
                :key="index"
                style="width: 250px"
              >
                <a class="dropdown-item" @click="setEjecutivo(row.id, item.id)">
                  <span class="ml-3 menu-text">{{ item.name }}</span>
                </a>
              </v-dropdown-item>
            </v-dropdown>
          </conditional-wrapper>
          <!-- </conditional-wrapper> -->
        </template>
      </table-column>

      <table-column label="Status">
        <template slot-scope="row">
          <font-awesome-icon
            :icon="filters.icon !== '' ? filters.icon : row.icon"
            class="icon menu-icon"
            v-tooltip="{
              content: filters.icon !== '' ? filtersAppliedName : row.status,
            }"
            :style="{
              color: '#ABC193',
              fontSize: '22px',
            }"
          />
        </template>
      </table-column>

      <!-- <table-column label="Acciones" show="cantidad_folios">
        <template slot-scope="row">
          <div class="btn-group" role="group">

            <base-button
              size="small"
              color="theme"
              @click="setCustomerToViewDetails(row)"
            >
              Seleccionar cliente
            </base-button>
          </div>
        </template>
      </table-column> -->
      <table-column
        :sortable="false"
        :filterable="false"
        cell-class="action-dropdown"
      >
        <template slot-scope="row">
          <span> {{ $t('customers.action') }} </span>
          <v-dropdown>
            <a slot="activator" href="#">
              <dot-icon />
            </a>

            <!-- <v-dropdown-item>
              <div
                @click="giveAccessToCustomerPortal(row)"
                class="dropdown-item"
              >
                {{ $t('customers.give_access_customer_portal') }}
              </div>
            </v-dropdown-item> -->

            <v-dropdown-item>
              <router-link
                :to="{
                  name: 'customer.sat-settings',
                  params: { id: row.id },
                }"
                class="dropdown-item"
              >
                <font-awesome-icon
                  :icon="['fas', 'file-alt']"
                  class="dropdown-item-icon"
                />
                {{ $t('customers.sat_settings.page_title') }}
              </router-link>
            </v-dropdown-item>

            <v-dropdown-item>
              <router-link
                :to="{
                  name: 'customer.previous-invoices',
                  params: { id: row.id },
                }"
                class="dropdown-item"
              >
                <font-awesome-icon
                  :icon="['fas', 'file-alt']"
                  class="dropdown-item-icon"
                />
                {{ $t('customers.previous_invoices') }}
              </router-link>
            </v-dropdown-item>

            <v-dropdown-item>
              <router-link
                :to="`/general/customers/${row.id}/edit`"
                class="dropdown-item"
              >
                <font-awesome-icon
                  :icon="['fas', 'pencil-alt']"
                  class="dropdown-item-icon"
                />
                {{ $t('general.edit') }}
              </router-link>
            </v-dropdown-item>

            <!-- <v-dropdown-item>
                <div class="dropdown-item" @click="removeCustomer(row.id)">
                  <font-awesome-icon
                    :icon="['fas', 'trash']"
                    class="dropdown-item-icon"
                  />
                  {{ $t('general.delete') }}
                </div>
              </v-dropdown-item> -->
          </v-dropdown>
        </template>
      </table-column>
    </table-component>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Pagination from '../../components/base/base-table/components/Pagination'
import CertificateWarning from '../components/CertificateWarning'
import NewIcon from '../../components/icon/NewIcon'
import TaxReturnRif from '../components/TaxReturnRif.vue'
import ConditionalWrapper from '../components/ConditionalWrapper.vue'

export default {
  components: {
    Pagination,
    CertificateWarning,
    NewIcon,
    ConditionalWrapper,
  },

  data: () => ({
    statusMenu: [
      {
        icon: 'file',
        name: 'Todos',
        key: 'todos',
      },
    ],
    filters: {
      display_name: '',
      search: '',
      rfc: '',
      phone: '',
      taxRegime: '',
      limit_create: null,
      status: '',
      icon: '',
    },
    ejecutivos: [],
    filtersAppliedName: '',
    label_one_previous_tax_regime: null,
    label_two_previous_tax_regime: null,
    showFilters: false,
    filtersApplied: false,
    isRequestOngoing: true,
  }),
  computed: {
    ...mapGetters('catalogs', ['searchTaxRegime']),
  },
  mounted() {
    this.fetchStatus()
  },
  created() {
    this.fetchEjecutivos()
  },
  watch: {
    filters: {
      handler: 'setFilters',
      deep: true,
    },
  },
  methods: {
    ...mapActions('modal', ['openModal']),
    ...mapActions('payment', ['fetchPaquetes', 'eliminarPaquete']),
    ...mapActions('customer', ['fetchCustomers']),
    ...mapActions('CurrentCustomer', ['setCurrentCustomer']),
    ...mapActions('user', ['getEjecutivos', 'setEjecutivos']),
    ...mapActions('userProfile', ['validateRole']),

    ...mapActions('assigned', ['fetchDeclarationStatus']),

    fetchStatus() {
      this.fetchDeclarationStatus().then((value) => {
        this.statusMenu = this.statusMenu.concat(value).concat([
          {
            icon: 'user',
            name: 'Nuevos Usuarios',
            key: 'nuevos',
          },
        ])
      })
    },
    fetchEjecutivos() {
      this.getEjecutivos().then((value) => {
        this.ejecutivos = value
      })
    },
    refreshTable() {
      this.$refs.table.refresh()
    },
    isWithinTwoWeeks(createdAt) {
      const now = new Date()
      const twoWeeksAgo = new Date(now.getTime() - 14 * 24 * 60 * 60 * 1000) // Calculating two weeks ago

      const createAttDate = new Date(createdAt)

      return createAttDate >= twoWeeksAgo
    },
    async fetchData({ page, sort }) {
      const fecha = new Date()
      this.label_one_previous_tax_regime = fecha.getFullYear() - 1
      this.label_one_previous_tax_regime =
        this.label_one_previous_tax_regime.toString()
      this.label_two_previous_tax_regime = fecha.getFullYear() - 2
      this.label_two_previous_tax_regime =
        this.label_two_previous_tax_regime.toString()

      let data = {
        display_name: this.filters.display_name,
        search: this.filters.search,
        phone: this.filters.phone,
        rfc: this.filters.rfc,
        taxRegime: this.filters.taxRegime.id,
        orderByField: sort.fieldName || 'created_at',
        orderBy: sort.order || 'desc',
        limit_create: this.filters.limit_create,
        status: this.filters.status,
        page,
      }

      this.isRequestOngoing = true
      let response = await this.fetchCustomers(data)
      this.isRequestOngoing = false
      console.log(response.data)
      return {
        data: response.data.customers.data,
        pagination: {
          totalPages: response.data.customers.last_page,
          currentPage: page,
        },
      }
    },
    setCustomerToViewDetails(customer) {
      this.setCurrentCustomer(customer)
      this.$router.push({
        path: '/general/customer',
        // query: { customer: JSON.stringify(customer) }, // Convierte 'customer' a una cadena JSON
      })
    },
    setFilters() {
      this.filtersApplied = true
      this.refreshTable()
    },
    clearFilter() {
      this.filters = {
        display_name: '',
        rfc: '',
        phone: '',
        taxRegime: '',
        limit_create: null,
        status: '',
        icon: '',
      }

      this.$nextTick(() => {
        this.filtersApplied = false
      })
    },
    setEjecutivo(user, ejecutivo) {
      const data = {
        user,
        ejecutivo,
      }
      this.setEjecutivos(data).then((value) => {
        if (value != null) {
          this.refreshTable()
        }
      })
    },
    rowClicked(rowData) {
      this.setCustomerToViewDetails(rowData.row)
      console.log('esto es un clik',rowData.row.id)
    },
    setFilter(filter) {
      this.clearFilter()
      this.filtersAppliedName = filter['name']
      if (filter['key'] == 'todos') {
        return
      }

      if (filter['key'] == 'nuevos') {
        this.filters['icon'] = filter['icon']
        this.filters['limit_create'] = new Date(
          Date.now() - 14 * 24 * 60 * 60 * 1000
        )

        return
      }

      this.filters['status'] = filter['name']
      this.filters['icon'] = filter['icon']
    },
  },
}
</script>
