<template>
  <span>{{ catalogString }}</span>
</template>

<script>
export default {
  props: {
    code: {
      default: '',
      type: String
    }
  },
  data: () => ({
    catalog: [
      {
        code: 'PUE',
        description: 'Pago en una sola exhibición',
      },
      {
        code: 'PPD',
        description: 'Pago en parcialidades o diferido',
      },
    ],
  }),
  computed: {
    catalogString () {
      const result = this.catalog.filter(t => t.code == this.code)
      if (result.length) {
        return `${result[0].code} - ${result[0].description}`
      }
      return this.code
    }
  }
}
</script>
