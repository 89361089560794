<template>
  <div v-if="taxLoss" class="main-content mt-5">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="card p-2">
          <div class="table-responsive">
            <table class="table">
              <thead></thead>
              <tbody>
                <tr>
                  <th>
                    {{ $t('tax_losses.year') }}
                  </th>
                  <td class="text-right">
                    {{ taxLoss.year }}
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ $t('tax_losses.tax_return_type') }}
                  </th>
                  <td class="text-right">
                    {{ $t(`tax_losses.${taxLoss.tax_return_type}`) }}
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ $t('tax_losses.periodicity') }}
                  </th>
                  <td class="text-right">
                    {{ $t(`tax_losses.${taxLoss.periodicity}`) }}
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('tax_losses.operation_number') }}</th>
                  <td class="text-right">{{ taxLoss.operation_number }}</td>
                </tr>
                <tr>
                  <th>
                    {{ $t('tax_losses.date_of_presentation') }}
                  </th>
                  <td class="text-right">{{ taxLoss.presentation_date }}</td>
                </tr>
                <tr>
                  <th>{{ $t('tax_losses.amount') }}</th>
                  <td class="text-right">
                    <money-text :amount="taxLoss.amount" />
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('tax_losses.isr_in_favor') }}</th>
                  <td class="text-right">
                    <money-text :amount="taxLoss.isr_in_favor" />
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('tax_losses.iva_in_favor') }}</th>
                  <td class="text-right">
                    <money-text :amount="taxLoss.iva_in_favor" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6">
        <div class="card p-2">
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th colspan="4" class="text-center">
                    {{ $t('tax_losses.updates') }}
                  </th>
                </tr>
                <tr>
                  <th class="text-center">{{ $t('general.month') }}</th>
                  <th class="text-right">{{ $t('tax_losses.tax_loss') }}</th>
                  <th class="text-right">
                    {{ $t('tax_losses.total_updated') }}
                  </th>
                  <th class="text-right">
                    {{ $t('tax_losses.isr_in_favor') }}
                  </th>
                  <th class="text-right">
                    {{ $t('tax_losses.iva_in_favor') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="update in taxLossUpdates" :key="update.id">
                  <td class="text-center">{{ getMonth(update.month) }}</td>
                  <td class="text-right">
                    <money-text :amount="update.amount" />
                  </td>
                  <td class="text-right">
                    <money-text :amount="update.total_amount" />
                  </td>
                  <td class="text-right">
                    <money-text :amount="update.isr_amount" />
                  </td>
                  <td class="text-right">
                    <money-text :amount="update.iva_amount" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data: () => ({
    taxLossId: null,
  }),
  computed: {
    ...mapGetters('taxLossAndBalance', ['taxLoss']),
    ...mapGetters('calendar', ['getMonthByNumer']),
    taxLossUpdates() {
      if (!this.taxLoss) {
        return []
      }
      let accumulated = this.taxLoss.amount
      return this.taxLoss.tax_updates.map((update) => {
        accumulated += update.amount
        return {
          ...update,
          total_amount: accumulated,
        }
      })
    },
  },
  created() {
    this.taxLossId = this.$route.params.id
    this.loadTaxLoss()
  },
  destroyed() {
    this.cleanTaxLoss()
  },
  methods: {
    ...mapActions('taxLossAndBalance', ['fetchTaxLoss', 'cleanTaxLoss']),

    loadTaxLoss() {
      this.fetchTaxLoss(this.taxLossId).then()
    },

    getMonth(m) {
      const month = this.getMonthByNumer(m)
      if (month) {
        return month.name
      }
      return ''
    },
  },
}
</script>
