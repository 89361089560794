var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "error-box" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-sm-12 text-sm-center" },
        [
          _c("h1", [_vm._v(_vm._s(_vm.$t("general.four_zero_four")))]),
          _vm._v(" "),
          _c("h5", [_vm._v(_vm._s(_vm.$t("general.you_got_lost")))]),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "btn btn-lg bg-yellow text-white",
              attrs: { to: "/" },
            },
            [
              _c("font-awesome-icon", {
                staticClass: "icon text-white mr-2",
                attrs: { icon: "arrow-left" },
              }),
              _vm._v(" " + _vm._s(_vm.$t("general.go_home")) + "\n      "),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }