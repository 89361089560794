var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", { staticClass: "site-header" }, [
      _c("a", { staticClass: "brand-main", attrs: { href: "/" } }, [
        _c("img", {
          staticClass: "d-none d-md-inline",
          attrs: {
            id: "logo-white",
            src: "/assets/img/logo-taxati.png",
            alt: "Crater Logo",
          },
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "d-md-none",
          attrs: {
            id: "logo-mobile",
            src: "/assets/img/logo-taxati.png",
            alt: "Laraspace Logo",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }