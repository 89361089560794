var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "97",
        height: "110",
        viewBox: "0 0 97 110",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0)" } }, [
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M41.25 104.844H55V84.2188H41.25V104.844ZM42.9688 103.125H53.2813V85.9375H42.9688V103.125Z",
            fill: "#55547A",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M0 110H96.25V103.125H0V110ZM1.71875 108.281H94.5312V104.844H1.71875V108.281Z",
            fill: "#b9e18e",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M34.375 8.59375H61.875V6.875H34.375V8.59375Z",
            fill: "#55547A",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M48.125 15.4688C42.4291 15.4688 37.8125 20.0853 37.8125 25.7812C37.8125 31.4772 42.4291 36.0938 48.125 36.0938C53.8209 36.0938 58.4375 31.4772 58.4375 25.7812C58.4375 20.0853 53.8209 15.4688 48.125 15.4688ZM48.125 17.1875C52.8636 17.1875 56.7188 21.0427 56.7188 25.7812C56.7188 30.5198 52.8636 34.375 48.125 34.375C43.3864 34.375 39.5312 30.5198 39.5312 25.7812C39.5312 21.0427 43.3864 17.1875 48.125 17.1875Z",
            fill: "#55547A",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M12.8906 63.5938C12.418 63.5938 12.0312 63.207 12.0312 62.7344V55.8594C12.0312 55.3867 12.418 55 12.8906 55C13.3633 55 13.75 55.3867 13.75 55.8594V62.7344C13.75 63.207 13.3633 63.5938 12.8906 63.5938Z",
            fill: "#55547A",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M21.4844 63.5938C21.0117 63.5938 20.625 63.207 20.625 62.7344V55.8594C20.625 55.3867 21.0117 55 21.4844 55C21.957 55 22.3438 55.3867 22.3438 55.8594V62.7344C22.3438 63.207 21.957 63.5938 21.4844 63.5938Z",
            fill: "#55547A",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M30.0781 63.5938C29.6055 63.5938 29.2188 63.207 29.2188 62.7344V55.8594C29.2188 55.3867 29.6055 55 30.0781 55C30.5508 55 30.9375 55.3867 30.9375 55.8594V62.7344C30.9375 63.207 30.5508 63.5938 30.0781 63.5938Z",
            fill: "#55547A",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M38.6719 63.5938C38.1992 63.5938 37.8125 63.207 37.8125 62.7344V55.8594C37.8125 55.3867 38.1992 55 38.6719 55C39.1445 55 39.5312 55.3867 39.5312 55.8594V62.7344C39.5312 63.207 39.1445 63.5938 38.6719 63.5938Z",
            fill: "#55547A",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M47.2656 63.5938C46.793 63.5938 46.4062 63.207 46.4062 62.7344V55.8594C46.4062 55.3867 46.793 55 47.2656 55C47.7383 55 48.125 55.3867 48.125 55.8594V62.7344C48.125 63.207 47.7383 63.5938 47.2656 63.5938Z",
            fill: "#55547A",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M55.8594 63.5938C55.3867 63.5938 55 63.207 55 62.7344V55.8594C55 55.3867 55.3867 55 55.8594 55C56.332 55 56.7187 55.3867 56.7187 55.8594V62.7344C56.7187 63.207 56.332 63.5938 55.8594 63.5938Z",
            fill: "#55547A",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M64.4531 63.5938C63.9805 63.5938 63.5938 63.207 63.5938 62.7344V55.8594C63.5938 55.3867 63.9805 55 64.4531 55C64.9258 55 65.3125 55.3867 65.3125 55.8594V62.7344C65.3125 63.207 64.9258 63.5938 64.4531 63.5938Z",
            fill: "#55547A",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M73.0469 63.5938C72.5742 63.5938 72.1875 63.207 72.1875 62.7344V55.8594C72.1875 55.3867 72.5742 55 73.0469 55C73.5195 55 73.9062 55.3867 73.9062 55.8594V62.7344C73.9062 63.207 73.5195 63.5938 73.0469 63.5938Z",
            fill: "#55547A",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M81.6406 63.5938C81.168 63.5938 80.7812 63.207 80.7812 62.7344V55.8594C80.7812 55.3867 81.168 55 81.6406 55C82.1133 55 82.5 55.3867 82.5 55.8594V62.7344C82.5 63.207 82.1133 63.5938 81.6406 63.5938Z",
            fill: "#55547A",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M3.4375 103.125H5.15625V56.7188H3.4375V103.125Z",
            fill: "#55547A",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M91.0938 103.125H92.8125V56.7188H91.0938V103.125Z",
            fill: "#55547A",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M34.375 0C17.2098 0.9075 3.4375 15.2745 3.4375 32.6562V51.5625H34.375V0ZM32.6562 1.86484V49.8438H5.15625V32.6562C5.15625 16.7853 17.0947 3.59391 32.6562 1.86484Z",
            fill: "#55547A",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M61.875 0V51.5625H92.8125V32.6562C92.8125 15.2745 79.0402 0.9075 61.875 0ZM63.5938 1.86484C79.1553 3.59391 91.0938 16.7853 91.0938 32.6562V49.8438H63.5938V1.86484Z",
            fill: "#55547A",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M9.45312 34.375C8.97875 34.375 8.59375 33.99 8.59375 33.5157C8.59375 22.9316 13.6262 14.1247 22.7648 8.71238C23.1756 8.47347 23.7033 8.60925 23.9422 9.01488C24.1845 9.42222 24.0487 9.9516 23.6414 10.1939C14.9222 15.3553 10.3125 23.4197 10.3125 33.5157C10.3125 33.99 9.9275 34.375 9.45312 34.375Z",
            fill: "#b9e18e",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M54.1406 25.7812C53.6663 25.7812 53.2813 25.3962 53.2813 24.9219C53.2813 22.8748 51.0314 20.625 48.9844 20.625C48.51 20.625 48.125 20.24 48.125 19.7656C48.125 19.2913 48.51 18.9062 48.9844 18.9062C51.963 18.9062 55 21.9433 55 24.9219C55 25.3962 54.615 25.7812 54.1406 25.7812Z",
            fill: "#b9e18e",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M0 56.7188H96.25V49.8438H0V56.7188ZM1.71875 55H94.5312V51.5625H1.71875V55Z",
            fill: "#b9e18e",
          },
        }),
      ]),
      _vm._v(" "),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0" } }, [
          _c("rect", {
            attrs: { width: "96.25", height: "110", fill: "white" },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }