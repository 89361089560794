<template>
  <span v-html="$utils.formatMoney(amount, defaultCurrency)" />
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    amount: {
      default: 0,
      type: Number,
    }
  },
  computed: {
    ...mapGetters('currency', [
      'defaultCurrency',
    ]),
  }
}
</script>