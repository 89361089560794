var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("CustomerHeaderBasic"),
      _vm._v(" "),
      _c("div", { staticClass: "contracted main-content" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", [
          _vm._m(1),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c(
                    "base-button",
                    {
                      staticStyle: { "background-color": "#f13100" },
                      attrs: { loading: _vm.isLoading, color: "F13100" },
                    },
                    [
                      _vm._v("\n              Rechazar\n              "),
                      _c("font-awesome-icon", {
                        staticClass: "icon menu-icon ml-2",
                        attrs: { icon: ["fas", "ban"] },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c(
                    "base-button",
                    {
                      attrs: { loading: _vm.isLoading, color: "#1B144A" },
                      on: {
                        click: function ($event) {
                          return _vm.payment()
                        },
                      },
                    },
                    [
                      _vm._v("\n              Aceptar\n              "),
                      _c("font-awesome-icon", {
                        staticClass: "icon menu-icon ml-2",
                        attrs: { icon: ["fas", "check"] },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "page-header mt-4", staticStyle: { "z-index": "1" } },
      [
        _c("h3", { staticClass: "page-title" }, [
          _vm._v("Contrato de uso de la Plataforma"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("h4", [_vm._v("1. Objeto del contrato")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            '\n            Taxai proporciona una plataforma en línea que permite a los\n            Usuarios generar declaraciones de impuestos y, si es necesario,\n            utilizar servicios de folios para facturación (en adelante, los\n            "Servicios").\n          '
          ),
        ]),
        _vm._v(" "),
        _c("h4", [_vm._v("2. Términos y condiciones de uso")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            El Usuario se compromete a utilizar los Servicios de Taxai de\n            acuerdo con los términos y condiciones establecidos en este\n            Contrato y cualquier otro acuerdo o política relacionada. El\n            Usuario es el único responsable de cualquier contenido o\n            información proporcionada a través de la plataforma de Taxai.\n          "
          ),
        ]),
        _vm._v(" "),
        _c("h4", [_vm._v("3. Obligaciones de Taxai")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Taxai se compromete a proporcionar acceso a la plataforma en\n            línea, mantener la confidencialidad de la información del Usuario\n            según lo establecido en la política de privacidad, y brindar\n            asistencia técnica en caso de problemas relacionados con la\n            plataforma.\n          "
          ),
        ]),
        _vm._v(" "),
        _c("h4", [_vm._v("4. Obligaciones del Usuario")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [
            _vm._v(
              "\n              Proveer información precisa y actualizada al utilizar la\n              plataforma de Taxai.\n            "
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v(
              "\n              Cumplir con todas las leyes y regulaciones aplicables al\n              utilizar los Servicios de Taxai.\n            "
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v(
              "\n              No utilizar los Servicios para actividades ilegales o\n              fraudulentas.\n            "
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v(
              "\n              Respetar la propiedad intelectual de Taxai y terceros\n              relacionados con la plataforma.\n            "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("h4", [_vm._v("5. Tarifas y pago")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            El uso de la plataforma de Taxai puede estar sujeto a tarifas.\n            Taxai se reserva el derecho de modificar las tarifas en cualquier\n            momento y notificará al Usuario con anticipación. El Usuario se\n            compromete a pagar todas las tarifas aplicables según las\n            políticas de facturación de Taxai.\n          "
          ),
        ]),
        _vm._v(" "),
        _c("h4", [_vm._v("6. Limitación de responsabilidad")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Taxai no será responsable de ningún daño directo o indirecto\n            causado por el uso de la plataforma, incluyendo, pero no limitado\n            a, la pérdida de datos o la interrupción de los Servicios.\n          "
          ),
        ]),
        _vm._v(" "),
        _c("h4", [_vm._v("7. Terminación")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Este Contrato podrá ser terminado por cualquiera de las Partes en\n            cualquier momento, previa notificación por escrito. Taxai se\n            reserva el derecho de suspender o terminar la cuenta del Usuario\n            en caso de incumplimiento de este Contrato.\n          "
          ),
        ]),
        _vm._v(" "),
        _c("h4", [_vm._v("8. Ley aplicable")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n            Este Contrato se rige por las leyes del [jurisdicción] y cualquier\n            disputa derivada de este Contrato se someterá a la jurisdicción de\n            los tribunales de [jurisdicción].\n          "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }