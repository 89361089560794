import * as types from './mutation-types'
import ls from '../../../services/ls'
export const setAssignedSelect = ({ commit, dispatch, state }, data) => {
  console.log('estoy aqui', data)
  commit(types.SET_ASSIGNED_SELECT, data)
  ls.setObject(types.LOCAL_ASSIGNED_SELECTE, data)
}

export const fetchAssignedDeclaration = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/assigned/get/${data}`)
      .then((response) => {
        const data = response.data.declarations
        const taxReturn = {}

        function assignedDeclarationModelFromJson(json) {
          return {
            id: json.id,
            month: json.month,
            year: parseInt(json.year),
            status: statusFromJson(json.status),
          }
        }

        function statusFromJson(json) {
          return {
            name: json.name,
            slug: json.slug,
            icon: json.icon,
          }
        }

        for (var item in data) {


          var declaration = assignedDeclarationModelFromJson(data[item])
          var year = declaration.year

          if (!taxReturn.hasOwnProperty(year)) {
            taxReturn[year] = {}
          }

          var statusName = declaration.status.name

          if (!taxReturn[year].hasOwnProperty(statusName)) {
            taxReturn[year][statusName] = []
          }

          taxReturn[year][statusName].push(declaration)
        }

        resolve(taxReturn)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const fetchDeclarationStatus = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/assigned/get/status`)
      .then((response) => {
        const data = response.data.status
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const updateStatusAssignedDeclaration = (
  { commit, dispatch, state },
  data
) => {
  return new Promise((resolve, reject) => {
    window.axios
      .put(`/api/assigned/put/${data.id}`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getConversacion = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    let assinged =
      state.assingedSelect != null
        ? state.assingedSelect
        : ls.getObject(types.LOCAL_ASSIGNED_SELECTE)
    window.axios
      .get(`/api/assigned/conversation/${assinged.id}`)
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getPdfs = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    let assinged =
      state.assingedSelect != null
        ? state.assingedSelect
        : ls.getObject(types.LOCAL_ASSIGNED_SELECTE)
    window.axios
      .get(`/api/assigned/${assinged.id}/pdfs`)
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
export const sendMessageAssigned = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    let assinged =
      state.assingedSelect != null
        ? state.assingedSelect
        : ls.getObject(types.LOCAL_ASSIGNED_SELECTE)
    window.axios
      .post(`/api/assigned/conversation/${assinged.id}`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
