<template>
  <div v-if="taxReturn">
    <!-- ISR -->
    <div class="card p-2">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr class="table-primary">
              <th colspan="2" class="text-center">
                {{ $t('tax_returns.isr') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <!-- Ingresos percibidos -->
            <tr class="clickable" @click="toggleDisplayTable('revenue')">
              <td>
                <font-awesome-icon class="icon" icon="arrow-down" />
                {{ $t('tax_returns.revenue_perceived') }}
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.revenue" />
              </td>
            </tr>
            <!-- Tablas de ingresos -->
            <tr v-if="displayTables.revenue">
              <td colspan="2">
                <tax-return-invoices-table
                  v-if="taxReturn.tables.revenue"
                  :invoices="taxReturn.tables.revenue.invoices"
                  :totals="taxReturn.tables.revenue.totals"
                  title="tax_returns.revenue_perceived"
                />
              </td>
            </tr>
            <!-- Deducciones -->
            <tr v-if="taxReturnBlind">
              <td>{{ $t('tax_returns.tax_return_blind_35_percent') }}</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.deductions" />
              </td>
            </tr>
            <tr v-if="taxReturnBlind">
              <td>Deducción impuesto Predial</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.predio" />
              </td>
            </tr>
            <tr
              v-if="!taxReturnBlind"
              class="clickable"
              @click="toggleDisplayTable('deductions')"
            >
              <td>
                <font-awesome-icon class="icon" icon="arrow-down" />
                {{ $t('tax_returns.deductions') }}
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.deductions" />
              </td>
            </tr>
            <!-- Tabla de deducciones -->
            <tr v-if="displayTables.deductions && !taxReturnBlind">
              <td colspan="2">
                <tax-return-invoices-table
                  v-if="taxReturn.tables.deductions"
                  :invoices="taxReturn.tables.deductions.invoices"
                  :totals="taxReturn.tables.deductions.totals"
                  title="tax_returns.deductions"
                />
              </td>
            </tr>

            <!-- Base gravable -->
            <tr>
              <td>{{ $t('tax_returns.taxable_base') }}</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.taxable_base" />
              </td>
            </tr>
            <!-- ISR CAUSADO -->
            <tr class="clickable" @click="toggleDisplayTable('causado')">
              <td>
                <font-awesome-icon class="icon" icon="arrow-down" />
                {{ $t('tax_returns.isr_caused') }}
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.isr_caused" />
              </td>
            </tr>
            <!-- Tablas de ingresos -->
            <tr v-if="displayTables.causado">
              <td colspan="2">
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td>
                          {{ $t('rate_tables.lower_limit') }}
                        </td>
                        <td class="text-right">
                          <money-text :amount="taxReturn.amounts.lower_limit" />
                        </td>
                      </tr>
                      <!-- Tasa S/Excedente -->
                      <tr>
                        <td>
                          {{ $t('tax_returns.rate_on_surplus') }}
                        </td>
                        <td class="text-right">
                          {{ taxReturn.amounts.rate_on_surplus }} %
                          <!-- <money-text :amount="taxReturn.amounts.rate_on_surplus" /> -->
                        </td>
                      </tr>
                      <!-- Importe marginal -->
                      <tr>
                        <td>
                          {{ $t('tax_returns.marginal_amount') }}
                        </td>
                        <td class="text-right">
                          <money-text
                            :amount="taxReturn.amounts.marginal_amount"
                          />
                        </td>
                      </tr>
                      <!-- Cuota fija -->
                      <tr>
                        <td>
                          {{ $t('rate_tables.fixed_fee') }}
                        </td>
                        <td class="text-right">
                          <money-text :amount="taxReturn.amounts.fixed_fee" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
            <!-- Limite inferior -->
            <!-- <tr>
              <td>
                {{ $t('rate_tables.lower_limit') }}
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.lower_limit" />
              </td>
            </tr> -->
            <!-- Excedente limite inferior -->
            <!-- <tr>
              <td>
                {{ $t('tax_returns.surplus_lower_limit') }}
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.surplus_lower_limit" />
              </td>
            </tr> -->
            <!-- Tasa S/Excedente -->
            <!-- <tr>
              <td>
                {{ $t('tax_returns.rate_on_surplus') }}
              </td>
              <td class="text-right">
                {{ taxReturn.amounts.rate_on_surplus }} %

              </td>
            </tr> -->
            <!-- Importe marginal -->
            <!-- <tr>
              <td>
                {{ $t('tax_returns.marginal_amount') }}
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.marginal_amount" />
              </td>
            </tr> -->
            <!-- Cuota fija -->
            <!-- <tr>
              <td>
                {{ $t('rate_tables.fixed_fee') }}
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.fixed_fee" />
              </td>
            </tr> -->
            <!-- ISR Causado -->
            <!-- <tr>
              <td>
                {{ $t('tax_returns.isr_caused') }}
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.isr_caused" />
              </td>
            </tr> -->
            <!-- ISR Retenido -->
            <tr>
              <td>
                {{ $t('tax_returns.isr_withheld') }}
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.isr_withheld" />
              </td>
            </tr>
            <!-- ISR a cargo -->
            <tr>
              <td>
                {{ $t('tax_returns.isr_in_charge') }}
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.isr_in_charge" />
              </td>
            </tr>
            <!-- ISR actualización -->
            <tr>
              <td>
                {{ $t('tax_returns.update') }}
              </td>
              <td class="text-right">
                <!-- <money-text :amount="taxReturn.amounts.isr_update" /> -->
                <input
                  type="number"
                  class="w-50"
                  v-model="taxReturn.amounts.isr_update_value"
                  @keydown="onKeyDown"
                  @keyup.enter="actualizacion"
                />
              </td>
            </tr>
            <!-- ISR recargos -->
            <tr>
              <td>
                {{ $t('tax_returns.surcharges') }}
              </td>
              <td class="text-right">
                <input
                  type="number"
                  class="w-50"
                  v-model="taxReturn.amounts.isr_surcharge_value"
                  @keydown="onKeyDown"
                  @keyup.enter="actualizacion"
                />
                <!-- <money-text :amount="taxReturn.amounts.isr_surcharges" /> -->
              </td>
            </tr>
            <!--Total de contribuciones  -->
            <tr class="clickable">
              <td>Total de contribuciones</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.total_contribuciones" />
              </td>
            </tr>
            <tr>
              <td>Compensaciones</td>
              <td class="text-right">
                <input
                  type="number"
                  class="w-50"
                  v-model="taxReturn.amounts.compensations_value"
                  @click="toggleModalCompensacion()"
                />
              </td>
            </tr>
            <!-- ISR Total -->
            <tr>
              <td>
                {{ $t('tax_returns.isr_total') }}
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.total_isr" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- IVA -->
    <div class="card p-2">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr class="table-primary">
              <th colspan="2" class="text-center">
                {{ $t('tax_returns.iva') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <!-- Ingresos por los que cobro iva al 16% -->
            <tr
              class="clickable"
              @click="toggleDisplayTable('revenueAndOtherIncomes')"
            >
              <td>
                <font-awesome-icon class="icon" icon="arrow-down" />
                <!-- {{ $t('tax_returns.income_which_to_charge_iva_16') }} -->
                IVA cobrado a la tada del 16%
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.revenue_iva_charged" />
              </td>
            </tr>
            <!-- Tablas de ingresos y otros ingresos -->
            <tr v-if="displayTables.revenueAndOtherIncomes">
              <td colspan="2">
                <tax-return-invoices-table
                  v-if="taxReturn.tables.revenue"
                  :invoices="taxReturn.tables.revenue.invoices"
                  :totals="taxReturn.tables.revenue.totals"
                  title="tax_returns.revenue"
                />
                <tax-return-invoices-table
                  v-if="taxReturn.tables.other_incomes"
                  :invoices="taxReturn.tables.other_incomes.invoices"
                  :totals="taxReturn.tables.other_incomes.totals"
                  title="tax_returns.other_incomes"
                />
              </td>
            </tr>
            <!-- IVA cobrado -->
            <tr>
              <td>
                {{ $t('tax_returns.iva_charged') }}
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.iva_charged" />
              </td>
            </tr>
            <!-- IVA Retenido -->
            <tr>
              <td>
                {{ $t('tax_returns.iva_detained') }}
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.iva_detained" />
              </td>
            </tr>
            <!-- IVA acreditable por compras y gastos -->
            <tr
              class="clickable"
              @click="toggleDisplayTable('purchasesAndDeductions')"
            >
              <td>
                <font-awesome-icon class="icon" icon="arrow-down" />
                <!-- {{ $t('tax_returns.iva_creditable_for_expenses') }} -->
                IVA Acreditable / Pagado por compras y gastos
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.iva_creditable" />
              </td>
            </tr>
            <!-- Tablas de compras y deduciones -->
            <tr v-if="displayTables.purchasesAndDeductions">
              <td colspan="2">
                <tax-return-invoices-table
                  v-if="taxReturn.tables.deductions"
                  :invoices="taxReturn.tables.deductions.invoices"
                  :totals="taxReturn.tables.deductions.totals"
                  title="tax_returns.deductions"
                />
                <tax-return-invoices-table
                  v-if="taxReturn.tables.purchases"
                  :invoices="taxReturn.tables.purchases.invoices"
                  :totals="taxReturn.tables.purchases.totals"
                  title="tax_returns.purchases"
                />
              </td>
            </tr>
            <!-- IVA Retenido -->
            <tr>
              <td>
                {{ $t('tax_returns.amount_in_charge') }}
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.amount_in_charge" />
              </td>
            </tr>
            <!-- IVA a cargo -->
            <tr>
              <td>
                {{ $t('tax_returns.iva_in_charge') }}
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.iva_in_charge" />
              </td>
            </tr>
            <!-- Acreditamiento -->
            <tr>
              <td>Acreditamiento</td>
              <td class="text-right">
                <input
                  type="number"
                  class="w-50"
                  v-model="taxReturn.amounts.accreditation_value"
                  @click="toggleModalAcreditamiento()"
                />
              </td>
            </tr>

            <!--Impuesto a Cargo -->
            <tr>
              <td>Impuesto a Cargo</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.inpueto_cargo" />
              </td>
            </tr>
            <!-- IVA actualización -->
            <tr>
              <td>
                {{ $t('tax_returns.update') }}
              </td>
              <td class="text-right">
                <!-- <money-text :amount="taxReturn.amounts.isr_update" /> -->
                <input
                  type="number"
                  v-model="taxReturn.amounts.iva_update_value"
                  @keydown="onKeyDown"
                  @keyup.enter="actualizacion"
                />
              </td>
            </tr>
            <!-- IVA recargos -->
            <tr>
              <td>
                {{ $t('tax_returns.surcharges') }}
              </td>
              <td class="text-right">
                <input
                  type="number"
                  v-model.number="taxReturn.amounts.iva_surcharge_value"
                  @keydown="onKeyDown"
                  @keyup.enter="actualizacion"
                />
                <!-- <money-text :amount="taxReturn.amounts.isr_surcharges" /> -->
              </td>
            </tr>
            <!-- IVA total -->
            <tr>
              <td>Total a pagar de IVA</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.total_iva" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TaxReturnInvoicesTable from './TaxReturnInvoicesTable.vue'

export default {
  components: { TaxReturnInvoicesTable },
  props: {
    taxReturn: {
      type: Object,
      default: null,
    },
    taxLoad: Function,
  },
  // created: () => {
  //   this.taxReturn.amounts.isr_update =
  //     this.taxReturn.amounts.isr_update != undefined
  //       ? parseFloat(taxReturn.amounts.isr_update) / 100
  //       : 0
  // },
  data: () => ({
    displayTables: {
      causado: false,
      revenue: false,
      deductions: false,
      purchasesAndDeductions: false,
      revenueAndOtherIncomes: false,
      contribuciones: false,
    },
  }),
  watch: {
    compensacion() {
      this.taxReturn.amounts.compensations_value =
        this.compensacion['saldoAplicar']
      this.taxReturn.amounts.compensations_object = this.compensacion
      this.actualizacion()
    },
    acreditamiento() {
      this.taxReturn.amounts.accreditation_value =
        this.acreditamiento['saldoAplicar']
      this.taxReturn.amounts.accreditation_object = this.acreditamiento
      this.actualizacion()
    },
  },
  computed: {
    ...mapGetters('taxReturns', ['compensacion', 'acreditamiento']),
    taxReturnBlind() {
      if (this.taxReturn.amounts.hasOwnProperty('info')) {
        if (this.taxReturn.amounts.info.tax_return_blind) {
          return true
        }
      }
      return false
    },
  },
  methods: {
    ...mapActions('modal', ['openModal']),
    ...mapActions('taxReturns', [
      'updatedModalCompensacion',
      'updatedModalAcreditamiento',
      'updatedModalIngresoCfdi',
      'updatedModalIngresoDisminuir',
      'updatedModalIngresoAdicional',
      'updatedModalISRRetenido',
    ]),

    toggleDisplayTable(table) {
      this.displayTables[table] = !this.displayTables[table]
    },

    toggleModalCompensacion() {
      if (this.taxReturn.amounts.compensations_object != null) {
        this.updatedModalCompensacion(
          this.taxReturn.amounts.compensations_object
        )
      }
      this.openModal({
        title: 'Detalle de Compensación de ISR',
        componentName: 'DetalleCompensacionModal',
        size: 'xl',
      })
    },

    toggleModalAcreditamiento() {
      if (this.taxReturn.amounts.accreditation_object != null) {
        this.updatedModalAcreditamiento(
          this.taxReturn.amounts.accreditation_object
        )
      }
      this.openModal({
        title: 'Detalle de Acreditamiento de IVA',
        componentName: 'DetalleAcreditamientoModal',
        size: 'lg',
      })
    },

    actualizacionISR() {
      this.taxLoad(
        this.taxReturn.amounts.isr_update_value,
        this.taxReturn.amounts.isr_surcharge_value,
        this.taxReturn.amounts.iva_update_value,
        this.taxReturn.amounts.iva_surcharge_value
      )
    },

    actualizacionIVA() {
      this.taxLoad(
        this.taxReturn.amounts.isr_update_value,
        this.taxReturn.amounts.isr_surcharge_value,
        this.taxReturn.amounts.iva_update_value,
        this.taxReturn.amounts.iva_surcharge_value
      )
    },
    actualizacion() {
      if (this.taxReturn.amounts.condonation_percentage > 100) {
        this.taxReturn.amounts.condonation_percentage = 100
      }
      this.taxLoad(this.taxReturn)
    },

    onKeyDown(event) {
      const regex = /^[0-9]+|^$/

      // Verificar si la tecla presionada es 'Backspace'
      if (event.key === 'Backspace') {
        return // Permitir la eliminación
      }

      if (!regex.test(event.key)) {
        event.preventDefault()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.clickable {
  td {
    font-weight: 500;
  }
}
</style>
