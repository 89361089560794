export const otherPaymentTypes = [
  {
    id: '001',
    description:
      'Reintegro de ISR pagado en exceso (siempre que no haya sido enterado al SAT).',
  },
  {
    id: '002',
    description:
      'Subsidio para el empleo (efectivamente entregado al trabajador).',
  },
  {
    id: '003',
    description: 'Viáticos (entregados al trabajador).',
  },
  {
    id: '004',
    description: 'Aplicación de saldo a favor por compensación anual.',
  },
  {
    id: '005',
    description:
      'Reintegro de ISR retenido en exceso de ejercicio anterior (siempre que no haya sido enterado al SAT).',
  },
  {
    id: '006',
    description:
      'Alimentos en bienes (Servicios de comedor y comida) Art 94 último párrafo LISR.',
  },
  {
    id: '007',
    description: 'ISR ajustado por subsidio.',
  },
  {
    id: '008',
    description:
      'Subsidio efectivamente entregado que no correspondía (Aplica sólo cuando haya ajuste al cierre de mes en relación con el Apéndice 7 de la guía de llenado de nómina).',
  },
  {
    id: '009',
    description:
      'Reembolso de descuentos efectuados para el crédito de vivienda.',
  },
  {
    id: '999',
    description:
      'Pagos distintos a los listados y que no deben considerarse como ingreso por sueldos, salarios o ingresos asimilados.',
  },
]
