import * as types from './mutation-types'


export const listStatementAccount = (store, params) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/treasury/periodos`, { params })
      .then(resolve)
      .catch(reject)
  })
}

export const listFileStatementAccount = (store, id) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/treasury-accounts/files/${id}`,)
      .then(resolve)
      .catch(reject)
  })
}
