<template>
  <div class="main-content">
    <div class="page-header mt-5">
      <h3 class="page-title">{{ $t('retenciones.page_title') }}</h3>
    </div>

    <!-- Retenciones table -->
    <table-component
      ref="table"
      :data="getRetenciones"
      :filter-no-results="$t('general.filterNoResults')"
      :show-filter="false"
      table-class="table"
    >
      <table-column :label="$t('retenciones.date')" show="retention_date" />
      <table-column :label="$t('retenciones.retention_number')" show="retention_number" />
      <table-column :label="$t('retenciones.issuing_name')" show="issuing_name">
        <template slot-scope="row">
          <span>{{ $t('retenciones.issuing_name') }}</span>
          <sub-string :string="row.issuing_name" />
        </template>
      </table-column>
      <table-column :label="$t('retenciones.issuing_rfc')" show="issuing_rfc" />

      <table-column :label="$t('retenciones.base')">
        <template slot-scope="row">
          <span>{{ $t('retenciones.base') }}</span>
          <money-text :amount="row.total_transaction_amount" />
        </template>
      </table-column>
      <table-column :label="$t('retenciones.retention_iva')">
        <template slot-scope="row">
          <span>{{ $t('retenciones.retention_iva') }}</span>
          <filter-retention-tax :taxes="row.taxes" tax-code="02"/>
        </template>
      </table-column>
      <table-column :label="$t('retenciones.retention_isr')">
        <template slot-scope="row">
          <span>{{ $t('retenciones.retention_isr') }}</span>
          <filter-retention-tax :taxes="row.taxes" tax-code="01"/>
        </template>
      </table-column>
      <table-column :label="$t('retenciones.total_retention_amount')" show="total_retention_amount">
        <template slot-scope="row">
          <span>{{ $t('retenciones.total_retention_amount') }}</span>
          <money-text :amount="row.total_retention_amount"/>
        </template>
      </table-column>

      <table-column
        :sortable="false"
        :filterable="false"
        cell-class="action-dropdown no-click"
      >
        <template slot-scope="row">
          <span>{{ $t('retenciones.actions') }}</span>
          <v-dropdown>
            <a slot="activator" href="#">
              <dot-icon />
            </a>
            <v-dropdown-item>
              <router-link
                :to="{ name: 'retenciones.view', params: {id: row.id} }"
                class="dropdown-item"
              >
                <font-awesome-icon icon="eye" class="dropdown-item-icon" />
                {{ $t('retenciones.view') }}
              </router-link>
            </v-dropdown-item>
          </v-dropdown>
        </template>
      </table-column>
    </table-component>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FilterRetentionTax from '../components/FilterRetentionTax';
export default {
  components: {
    FilterRetentionTax,
  },
  computed: {
    ...mapGetters('CurrentCustomer', [
      'currentCustomer',
    ]),
    ...mapGetters('currency', [
      'defaultCurrency'
    ]),
  },
  methods: {
    ...mapActions('retenciones', [
      'fetchRetenciones',
    ]),
    async getRetenciones ({page, sort}) {
      const params = {
        customer_id: this.currentCustomer.id,
        // month: this.filters.month ? this.filters.month.id : this.defaultMonth.id,
        orderByField: sort.fieldName || 'retention_date',
        orderBy: sort.order || 'desc',
        page,
      }

      const response = await this.fetchRetenciones(params);
      return {
        data: response.data.data,
        pagination: {
          totalPages: response.data.last_page,
          currentPage: response.data.current_page,
          count: response.data.scount
        }
      };
    }
  }
}
</script>