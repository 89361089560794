import * as actions from './actions'
import * as getters from './getters'

const initialState = {}

export default {
  namespaced: true,

  state: initialState,

  getters: getters,

  actions: actions,

  mutations: {},
}
