<template>
  <div class="main-content">
    <div class="page-header pt-5">
      <h3 class="page-title">
        {{ $t('customers.previous_invoices') }}
      </h3>
    </div>

    <div class="mt-3">
      <div class="row">
        <div class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
          <form @submit.prevent="submit">
            <div class="card p-3">
              <div class="form-group">
                <label>{{ $t('general.start_date') }}</label>
                <base-date-picker
                  v-model="form.startDate"
                  :invalid="$v.form.startDate.$error"
                  :calendar-button="true"
                  calendar-button-icon="calendar"
                />
              </div>

              <div class="form-group">
                <label>{{ $t('general.end_date') }}</label>
                <base-date-picker
                  v-model="form.endDate"
                  :invalid="$v.form.endDate.$error"
                  :calendar-button="true"
                  calendar-button-icon="calendar"
                />
              </div>

              <div class="form-group text-center font-italic">
                {{ $t('customers.this_process_may_take_a_while') }}
              </div>

              <div class="form-group text-center">
                <button :disabled="loading" class="btn btn-primary">
                  {{ $t('general.save') }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [validationMixin],
  data: () => ({
    loading: false,
    form: {
      startDate: null,
      endDate: null,
      customer_id: null,
    },
  }),
  computed: {
    ...mapGetters('CurrentCustomer', ['currentCustomer']),
  },
  validations: {
    form: {
      startDate: {
        required,
      },
      endDate: {
        required,
      },
    },
  },
  created() {
    const date = new Date()
    this.form.endDate = date.toISOString()
    date.setFullYear(date.getFullYear() - 1)
    this.form.startDate = date.toISOString()
    this.form.customer_id = this.$route.params.id
  },
  methods: {
    ...mapActions('customer', ['sendRequestPreviousInvoices']),
    submit() {
      this.loading = true
      const data = {
        ...this.form,
      }
      this.sendRequestPreviousInvoices(data)
        .then((response) => {
          this.loading = false
          if (response.data.success) {
            window.toastr.success(
              this.$t('customers.request_previous_invoices_request_started')
            )
          } else {
            window.toastr.success(response.data.message)
          }
        })
        .catch((err) => {
          this.loading = false
        })
    },
  },
}
</script>
