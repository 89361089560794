export default {
  functional: true,

  props: ['column', 'row', 'responsiveLabel'],

  render (createElement, { props, listeners }) {
    const data = {}
    const self = this;

    if (props.column.cellClass) {
      data.class = props.column.cellClass
    }

    data.on = {
      click() {
          listeners.click(props.column.show)
      }
    }

    if (props.column.template) {
      return createElement('td', data, props.column.template(props.row.data))
    }

    data.domProps = {}
    data.domProps.innerHTML = props.column.formatter(props.row.getValue(props.column.show), props.row.data)

    return createElement('td',{
      on: {
        click() {
          listeners.click(props.column.show)
        }
      }
    }, [
      createElement('span', props.responsiveLabel), data.domProps.innerHTML
    ])
  }
}
