var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "category-modal" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitUploadData.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "form-group row" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-7" }, [
              _c("div", { staticClass: "image-upload-box" }, [
                _c("input", {
                  ref: "digital_stamp_key",
                  attrs: { type: "file" },
                  on: { change: _vm.onFileChange },
                }),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-footer" },
          [
            _c(
              "base-button",
              {
                staticClass: "mr-3",
                attrs: { outline: true, color: "theme" },
                on: { click: _vm.closeUploadModal },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("general.cancel")) + "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "base-button",
              {
                attrs: {
                  loading: _vm.isLoading,
                  icon: "save",
                  color: "theme",
                  type: "submit",
                },
              },
              [_vm._v("\n       Importar\n      ")]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-sm-4 col-form-label input-label" }, [
      _vm._v("Archivo a importar"),
      _c("span", { staticClass: "required text-danger" }, [_vm._v("*")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }