<template >
  <div>
    <div class="card-body">
      <form class="pt-4 pb-4 pl-4 pr-4">
        <div class="row mt-3">
          <div class="col-4">
            <label for="input1">ISR Retenido a disminuir</label>
            <base-select v-model="form.retenido" :options="retenidoOptions" :searchable="true" :allow-empty="false"
              :show-labels="false" label="label" track-by="id" style="font-size: 12px" />
          </div>
        </div>

        <div v-if="form.retenido?.id == '0'">

          <!-- <div class="row mt-3">
            <div class=" col-12">
              <h6 class="my-2">Total ISR retenido
                <money-text :amount="form.totalDisminuir" />
              </h6>
            </div>
          </div> -->

          <div class="row mt-3">
            <div class=" col-12">
              <h6 class="my-2">ISR Retenido proveniente de CFDI's:
                <money-text :amount="form.ingresoDisminuircfdi" />
              </h6>
            </div>
          </div>


          <div class="row mt-3">
            <div class="col-12">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <div class="pl-2" v-b-toggle="'collapse'" variant="primary">
                    <font-awesome-icon class="icon" icon="arrow-down" />
                    CFDI's
                  </div>
                </b-card-header>

                <b-collapse :id="'collapse'" class="mt-2">
                  <tax-return-invoices-table-modal :invoices="ingresosCfdi.invoices" title="Ingresos"
                    :setInvoices="invoiceSelect" :invoicesSelect="form.invoices" />
                </b-collapse>
              </b-card>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12">
              <h6 class="my-2">Total Monto a disminuir proveniente de CFDI:
                <money-text :amount="form.ingresoDisminuircfdi" />
              </h6>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-6">
              <label for="input1">Nota</label>
              <base-text-area v-model="form.nota" />

            </div>
          </div>

          <div class="row mt-3">
            <div class="col-6">
              <label for="input1">Monto que desea disminuir sin un CFDI</label>
              <base-input type="number" v-model="form.otroDisminuir" />

            </div>
          </div>

          <div class="row mt-3">
            <div class="col-6">
              <label for="input1">Nota</label>
              <base-text-area v-model="form.nota" />

            </div>
          </div>


        </div>
        <hr>
        <div class="row mt-3">
          <div class="col-4">
            <label for="inputAdicionar">ISR Retenido a Adicionar</label>
            <base-select v-model="form.retenidoAdicionar" :options="retenidoOptions" :searchable="true"
              :allow-empty="false" :show-labels="false" label="label" track-by="id" style="font-size: 12px" />
          </div>
        </div>

        <div v-if="form.retenidoAdicionar?.id == '0'">
          <!--  este ig llevara la condicon -->
          <div class="row mt-3">
            <div class="col-6">
              <label for="input1">Monto que desea disminuir sin un CFDI</label>
              <base-input type="number" v-model="form.montoAdicionar" />

            </div>
          </div>


          <div class="row mt-3">
            <div class="col-6">
              <label for="input1">Nota</label>
              <base-text-area v-model="form.notaAdicionar" />

            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="card-footer">
      <div class="mt-2 flex-content-right">
        <base-button color="theme" @click="saveIngresoDisminuir">
          Guardar
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import TaxReturnInvoicesTableModal from './../../../views/components/TaxReturnInvoicesTableModal'

export default {
  components: {
    TaxReturnInvoicesTableModal,
  },
  mixins: [validationMixin],

  data: () => ({
    form: {
      retenido: null,
      ingresoDisminuircfdi: 0,
      invoices: [],
      nota: '',
      monto: 0,
      notaAdicional: '',

      retenidoAdicionar: null,
      montoAdicionar: 0,
      notaAdicionar: '',


    },

    disminuirOption: [
      { id: 0, name: 'SI' },
      { id: 1, name: 'NO' },
    ],

  }),
  created() {

  },
  watch: {

    // otroDisminuir() {
    //   this.form.totalDisminuir = Number(this.form.otroDisminuir) * 100 + Number(this.form.otroDisminuirAdicional) * 100 + Number(this.form.ingresoDisminuircfdi) + Number(this.form.ingresoDisminuircfdiAdicional)
    // },
    // otroDisminuirAdiconal() {
    //   this.form.totalDisminuir = Number(this.form.otroDisminuirAdicional) * 100 + Number(this.form.otroDisminuir) * 100 + Number(this.form.ingresoDisminuircfdi) + Number(this.form.ingresoDisminuircfdiAdicional)
    // },
    // ingresoDisminuircfdi() {
    //   this.form.totalDisminuir = Number(this.form.otroDisminuirAdicional) * 100 + Number(this.form.otroDisminuir) * 100 + Number(this.form.ingresoDisminuircfdi) + Number(this.form.ingresoDisminuircfdiAdicional)
    // },
    // ingresoDisminuircfdiAdicional() {
    //   this.form.totalDisminuir = Number(this.form.otroDisminuirAdicional) * 100 + Number(this.form.otroDisminuir) * 100 + Number(this.form.ingresoDisminuircfdi) + Number(this.form.ingresoDisminuircfdiAdicional)
    // },

    // conceptoDisminuir() {
    //   if (this.form.conceptoDisminuir?.id == '2') {
    //     this.form.ingresoDisminuircfdi = 0
    //   } else {
    //     this.form.otroDisminuir = 0
    //     this.form.ingresoDisminuircfdi = this.form.invoices.reduce((total, invoice) => {
    //       return total + invoice.total
    //     }, 0)
    //   }
    // },
    // conceptoDisminuirAdicional() {
    //   if (this.form.conceptoDisminuirAdicional?.id == '2') {
    //     this.form.ingresoDisminuircfdiAdicional = 0
    //   } else {
    //     this.form.otroDisminuirAdicional = 0
    //     this.form.ingresoDisminuircfdiAdicional = this.form.invoicesAdicional.reduce((total, invoice) => {
    //       return total + invoice.total
    //     }, 0)
    //   }
    // }
  },
  computed: {
    ...mapGetters('taxReturns', ['ingresosCfdi', 'isrRetenido']),

    // otroDisminuir() {
    //   return this.form.otroDisminuir;
    // },
    // otroDisminuirAdiconal() {
    //   return this.form.otroDisminuirAdicional;
    // },
    // ingresoDisminuircfdi() {
    //   return this.form.ingresoDisminuircfdi;
    // },
    // ingresoDisminuircfdiAdicional() {
    //   return this.form.ingresoDisminuircfdiAdicional;
    // },
    // conceptoDisminuir() {
    //   return this.form.conceptoDisminuir
    // },
    // conceptoDisminuirAdicional() {
    //   return this.form.conceptoDisminuirAdicional
    // },

    retenidoOptions() {
      return this.disminuirOption.map((item) => {
        return {
          ...item,
          id: item.id,
          label: `${item.name}`,
        }
      })
    },

  },

  mounted() {
    this.form = JSON.parse(JSON.stringify(this.isrRetenido));
  },


  methods: {
    ...mapActions('taxReturns', ['updatedModalISRRetenido']),
    ...mapActions('modal', ['closeModal']),

    saveIngresoDisminuir() {
      this.updatedModalISRRetenido(this.form)
      this.closeCategoryModal()
    },

    closeCategoryModal() {
      this.closeModal()
    },


    invoiceSelect(invoices) {
      this.form.invoices = invoices

      this.form.ingresoDisminuircfdi = invoices.reduce((total, invoice) => {
        return total + invoice.retention_isr
      }, 0)
    },

  },
}
</script>
