var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-content" },
    [
      _c("div", { staticClass: "page-header mt-4" }, [
        _c("h3", { staticClass: "page-title" }, [_vm._v("Paquetes")]),
        _vm._v(" "),
        _c("div", { staticClass: "page-actions row" }, [
          _c(
            "div",
            { staticClass: "col-xs-2 mr-2" },
            [
              _c(
                "base-button",
                {
                  attrs: { icon: "plus", color: "theme" },
                  on: {
                    click: function ($event) {
                      return _vm.openAddPaquetesModal()
                    },
                  },
                },
                [_vm._v("\n          Declaraciones\n        ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-xs-2 mr-2" },
            [
              _c(
                "base-button",
                {
                  attrs: { icon: "plus", color: "theme" },
                  on: {
                    click: function ($event) {
                      return _vm.openAddPaqueteFoliosModal()
                    },
                  },
                },
                [_vm._v("\n          Folios\n        ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-xs-2 mr-2" },
            [
              _c(
                "base-button",
                {
                  attrs: { icon: "plus", color: "theme" },
                  on: {
                    click: function ($event) {
                      return _vm.openAddPaqueteDeclaraFoliosModal()
                    },
                  },
                },
                [_vm._v("\n          Declaraciones/Folios\n        ")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "table-component",
        {
          ref: "table",
          attrs: {
            data: _vm.paquetes,
            "filter-no-results": _vm.$t("general.filterNoResults"),
            "is-clickable": true,
            "show-filter": false,
            "table-class": "table",
          },
        },
        [
          _c("table-column", { attrs: { label: "Paquete", show: "nombre" } }),
          _vm._v(" "),
          _c("table-column", { attrs: { label: "Precio", show: "precio" } }),
          _vm._v(" "),
          _c("table-column", {
            attrs: { label: "Periodicidad", show: "periodicidad_nombre" },
          }),
          _vm._v(" "),
          _c("table-column", {
            attrs: { label: "Cantidad Folios", show: "cantidad_folios" },
          }),
          _vm._v(" "),
          _c("table-column", { attrs: { label: "Régimen", show: "regimen" } }),
          _vm._v(" "),
          _c("table-column", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c(
                      "div",
                      { staticClass: "remove-icon-wrapper" },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "remove-icon",
                          staticStyle: { color: "red" },
                          attrs: { icon: "trash-alt" },
                          on: {
                            click: function ($event) {
                              return _vm.removeItem(row.id)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }