export const fetchPaymentForInvoicing = (store, params) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/general-public-invoice/payments`, { params })
      .then(resolve)
      .catch(reject)
  })
}
export const fetchTransactionsForInvoicing = (store, params) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/general-public-invoice/transactions`, { params })
      .then(resolve)
      .catch(reject)
  })
}

export const createInvoice = (store, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/general-public-invoice/invoicing`, data)
      .then(resolve)
      .catch(reject)
  })
}


export const descargarFactura = (store, data) => {
  console.log("debe descargar PDF");
}