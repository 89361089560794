<template>
  <div class="customer-create main-content mt-5">
    <form action="" @submit.prevent="submitCustomerData">
      <div class="page-header">
        <h3 class="page-title">
          {{
            isEdit
              ? $t('customers.edit_customer')
              : $t('customers.new_customer')
          }}
        </h3>
        <!-- <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link slot="item-title" to="/general/dashboard">{{ $t('general.home') }}</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link slot="item-title" to="/general/customers">{{ $tc('customers.customer', 2) }}</router-link>
          </li>
          <li class="breadcrumb-item">
            {{ isEdit ? $t('customers.edit_customer') : $t('customers.new_customer') }}
          </li>
        </ol> -->
        <div class="page-actions header-button-container">
          <base-button
            :loading="isLoading"
            :disabled="isLoading"
            :tabindex="23"
            icon="save"
            color="theme"
            type="submit"
          >
            {{
              isEdit
                ? $t('customers.update_customer')
                : $t('customers.save_customer')
            }}
          </base-button>
        </div>
      </div>
      <div class="customer-card card">
        <div class="card-body">
          <div class="row">
            <div class="section-title col-sm-2">
              {{ $t('customers.basic_info') }}
            </div>
            <div class="col-sm-5">
              <div class="form-group">
                <label class="form-label">{{ $t('customers.rfc') }}</label
                ><span class="text-danger"> *</span>
                <base-input
                  :invalid="$v.formData.rfc.$error"
                  v-model="formData.rfc"
                  focus
                  type="text"
                  name="rfc"
                  tab-index="1"
                  @input="$v.formData.rfc.$touch()"
                />
                <div v-if="$v.formData.rfc.$error">
                  <span v-if="!$v.formData.rfc.required" class="text-danger">
                    {{ $tc('validation.required') }}
                  </span>
                  <span v-if="!$v.formData.rfc.minLength" class="text-danger">
                    {{
                      $tc(
                        'validation.name_min_length',
                        $v.formData.rfc.$params.minLength.min,
                        { count: $v.formData.rfc.$params.minLength.min }
                      )
                    }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label class="form-label">{{
                  $t('customers.display_name')
                }}</label
                ><span class="text-danger"> *</span>
                <base-input
                  :invalid="$v.formData.name.$error"
                  v-model="formData.name"
                  focus
                  type="text"
                  name="name"
                  tab-index="1"
                  @input="$v.formData.name.$touch()"
                />
                <div v-if="$v.formData.name.$error">
                  <span v-if="!$v.formData.name.required" class="text-danger">
                    {{ $tc('validation.required') }}
                  </span>
                  <span v-if="!$v.formData.name.minLength" class="text-danger">
                    {{
                      $tc(
                        'validation.name_min_length',
                        $v.formData.name.$params.minLength.min,
                        { count: $v.formData.name.$params.minLength.min }
                      )
                    }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label class="form-label">{{ $t('customers.email') }}</label>
                <span class="text-danger"> *</span
                ><base-input
                  :invalid="$v.formData.email.$error"
                  v-model.trim="formData.email"
                  type="text"
                  name="email"
                  tab-index="3"
                  @input="$v.formData.email.$touch()"
                />
                <div v-if="$v.formData.email.$error">
                  <span v-if="!$v.formData.email.required" class="text-danger">
                    {{ $tc('validation.required') }}
                  </span>
                  <span v-if="!$v.formData.email.email" class="text-danger">
                    {{ $tc('validation.email_incorrect') }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label class="form-label">{{
                  $t('customers.primary_currency')
                }}</label>
                <base-select
                  v-model="currency"
                  :options="currencies"
                  :custom-label="currencyNameWithCode"
                  :allow-empty="false"
                  :searchable="true"
                  :show-labels="false"
                  :tabindex="5"
                  :placeholder="$t('customers.select_currency')"
                  label="name"
                  track-by="id"
                  :disabled="true"
                />
              </div>
            </div>
            <div class="col-sm-5">
              <div class="form-group">
                <label class="form-label">{{
                  $t('customers.tax_regime')
                }}</label>
                <span class="text-danger"> *</span>
                <base-select
                  :invalid="$v.tax_regimes.$error"
                  v-model="tax_regimes"
                  :options="taxRegimes"
                  :searchable="true"
                  :show-labels="false"
                  :allow-empty="true"
                  :tabindex="4"
                  :placeholder="$t('customers.select_tax_regime')"
                  label="description"
                  track-by="id"
                />
                <div v-if="$v.tax_regimes.$error">
                  <span v-if="!$v.tax_regimes.required" class="text-danger">
                    {{ $tc('validation.required') }}
                  </span>
                </div>
              </div>

              <div class="form-group">
                <label class="form-label"
                  >Segundo {{ $t('customers.tax_regime') }}</label
                >
                <base-select
                  v-model="second_tax_regimes"
                  :options="taxRegimes"
                  :searchable="true"
                  :show-labels="false"
                  :allow-empty="true"
                  :tabindex="4"
                  :placeholder="$t('customers.select_tax_regime')"
                  label="description"
                  track-by="id"
                />
              </div>

              <div class="form-group">
                <label class="form-label">{{
                  $t('customers.primary_contact_name')
                }}</label>
                <base-input
                  v-model.trim="formData.contact_name"
                  :label="$t('customers.contact_name')"
                  type="text"
                  tab-index="2"
                />
              </div>
              <div class="form-group">
                <label class="form-label">{{ $t('customers.phone') }}</label>
                <base-input
                  v-model.trim="formData.phone"
                  type="text"
                  name="phone"
                  tab-index="4"
                />
              </div>
              <div class="form-group">
                <label class="form-label">{{ $t('customers.website') }}</label>
                <base-input
                  v-model="formData.website"
                  :invalid="$v.formData.website.$error"
                  type="url"
                  tab-index="6"
                  @input="$v.formData.website.$touch()"
                />
                <div v-if="$v.formData.website.$error">
                  <span v-if="!$v.formData.website.url" class="text-danger">
                    {{ $tc('validation.invalid_url') }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-2 form-group">
              <label class="form-label">{{
                $t('customers.previou_tax_regime')
              }}</label>
            </div>
            <div class="col-sm-5 form-group">
              <label class="form-label">{{
                label_one_previous_tax_regime
              }}</label>
              <base-select
                v-model="one_previous_tax_regime"
                :options="taxRegimes"
                :searchable="true"
                :show-labels="false"
                :allow-empty="true"
                :tabindex="4"
                :placeholder="$t('customers.select_tax_regime')"
                label="description"
                track-by="id"
              />
            </div>

            <div class="col-sm-5 form-group">
              <label class="form-label">{{
                label_two_previous_tax_regime
              }}</label>
              <base-select
                v-model="two_previous_tax_regime"
                :options="taxRegimes"
                :searchable="true"
                :show-labels="false"
                :allow-empty="true"
                :tabindex="4"
                :placeholder="$t('customers.select_tax_regime')"
                label="description"
                track-by="id"
              />
            </div>
          </div>
          <div class="row">
            <template v-for="item in formData.previous_tax_regime">
              <div class="col-sm-6">
                <div class="form-group">
                  <button
                    type="button"
                    class="form-control btn btn-outline-primary"
                  >
                    {{ item.anio }}-{{ item.tax_regime }}
                  </button>
                </div>
              </div>
            </template>
          </div>

          <hr />
          <!-- first row complete  -->

          <div class="row">
            <div class="section-title col-sm-2">
              {{ $t('customers.billing_address') }}
            </div>
            <div class="col-sm-5">
              <div class="form-group">
                <label class="form-label">{{ $t('customers.name') }}</label>
                <span class="text-danger"> *</span>
                <base-input
                  :invalid="$v.billing.name.$error"
                  v-model.trim="billing.name"
                  type="text"
                  name="address_name"
                  tab-index="7"
                />
                <div v-if="$v.billing.name.$error">
                  <span v-if="!$v.billing.name.required" class="text-danger">
                    {{ $tc('validation.required') }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label class="form-label">{{ $t('customers.state') }}</label>
                <span class="text-danger"> *</span>
                <base-select
                  :invalid="$v.billing.state.$error"
                  v-model="billing.state"
                  :options="billingStates"
                  :searchable="true"
                  :show-labels="false"
                  :allow-empty="true"
                  :tabindex="8"
                  :placeholder="'Selecciona el estado'"
                />
                <div v-if="$v.billing.state.$error">
                  <span v-if="!$v.billing.state.required" class="text-danger">
                    {{ $tc('validation.required') }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label class="form-label">{{ $t('customers.address') }}</label>
                <span class="text-danger"> *</span>
                <base-text-area
                  v-model.trim="billing.address_street_1"
                  :invalid="$v.billing.address_street_1.$error"
                  :tabindex="11"
                  :placeholder="$t('general.street_1')"
                  type="text"
                  name="billing_street1"
                  rows="2"
                  @input="$v.billing.address_street_1.$touch()"
                />
                <div v-if="$v.billing.address_street_1.$error">
                  <span
                    v-if="!$v.billing.address_street_1.maxLength"
                    class="text-danger"
                  >
                    {{ $t('validation.address_maxlength') }}
                  </span>
                  <span
                    v-if="!$v.billing.address_street_1.required"
                    class="text-danger"
                  >
                    {{ $tc('validation.required') }}
                  </span>
                </div>
                <base-text-area
                  :tabindex="12"
                  v-model.trim="billing.address_street_2"
                  :invalid="$v.billing.address_street_2.$error"
                  :placeholder="$t('general.street_2')"
                  type="text"
                  name="billing_street2"
                  rows="2"
                  @input="$v.billing.address_street_2.$touch()"
                />
                <div v-if="$v.billing.address_street_2.$error">
                  <span
                    v-if="!$v.billing.address_street_2.maxLength"
                    class="text-danger"
                  >
                    {{ $t('validation.address_maxlength') }}
                  </span>
                  <span
                    v-if="!$v.billing.address_street_2.required"
                    class="text-danger"
                  >
                    {{ $tc('validation.required') }}
                  </span>
                </div>
                <base-text-area
                  :tabindex="12"
                  v-model.trim="billing.colony"
                  :invalid="$v.billing.colony.$error"
                  :placeholder="$t('general.colony')"
                  type="text"
                  name="billing_colony"
                  rows="2"
                  @input="$v.billing.colony.$touch()"
                />
                <div v-if="$v.billing.colony.$error">
                  <span v-if="!$v.billing.colony.maxLength" class="text-danger">
                    {{ $t('validation.address_maxlength') }}
                  </span>
                  <span v-if="!$v.billing.colony.required" class="text-danger">
                    {{ $tc('validation.required') }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-sm-5">
              <div class="form-group">
                <label class="form-label">{{ $t('customers.country') }}</label>
                <base-select
                  v-model="billing_country"
                  :disabled="true"
                  :options="billingCountries"
                  :searchable="true"
                  :show-labels="false"
                  :allow-empty="true"
                  :tabindex="8"
                  :placeholder="$t('general.select_country')"
                  label="name"
                  track-by="id"
                />
              </div>
              <div class="form-group">
                <label class="form-label">{{ $t('customers.city') }}</label>
                <span class="text-danger"> *</span>
                <base-input
                  v-model="billing.city"
                  :invalid="$v.billing.city.$error"
                  name="billing.city"
                  type="text"
                  tab-index="10"
                />
                <div v-if="$v.billing.city.$error">
                  <span v-if="!$v.billing.city.required" class="text-danger">
                    {{ $tc('validation.required') }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label class="form-label">{{ $t('customers.phone') }}</label>
                <base-input
                  v-model.trim="billing.phone"
                  type="text"
                  name="phone"
                  tab-index="13"
                />
              </div>
              <div class="form-group">
                <label class="form-label">{{ $t('customers.zip_code') }}</label>
                <span class="text-danger"> *</span>
                <base-input
                  v-model.trim="billing.zip"
                  :invalid="$v.billing.zip.$error"
                  type="text"
                  name="zip"
                  tab-index="14"
                />
                <div v-if="$v.billing.zip.$error">
                  <span v-if="!$v.billing.zip.required" class="text-danger">
                    {{ $tc('validation.required') }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <!-- second row complete  -->

          <!-- Archivos del sat -->
          <div class="row mb-5">
            <div class="section-title text-center" style="width: 100%">
              {{ $t('customers.sat_files') }}
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-4">
              <label for="description"
                >{{ $t('customers.access_file_cer') }} :
              </label>
              <div class="image-upload-box">
                <input
                  ref="e_sign_cer"
                  class=""
                  type="file"
                  accept=".cer"
                  @change="onESignCerChange"
                />
                <!-- <img v-if="previewESignCer" :src="previewESignCer" class="preview-logo">
                <div v-else class="upload-content">
                  <font-awesome-icon class="upload-icon" icon="cloud-upload-alt"/>
                  <p class="upload-text"> {{ $t('general.choose_file') }} </p>
                </div> -->
              </div>
            </div>
            <div class="form-group col-md-4">
              <label for="description"
                >{{ $t('customers.access_file_key') }} :
              </label>
              <div class="image-upload-box">
                <input
                  ref="e_sign_key"
                  class=""
                  type="file"
                  accept=".key"
                  @change="onESignKeyChange"
                />
                <!-- <img v-if="previewESignCer" :src="previewESignCer" class="preview-logo">
                <div v-else class="upload-content">
                  <font-awesome-icon class="upload-icon" icon="cloud-upload-alt"/>
                  <p class="upload-text"> {{ $t('general.choose_file') }} </p>
                </div> -->
              </div>
            </div>
            <div class="form-group col-md-4">
              <label class="form-label">{{
                $t('customers.fiel_password')
              }}</label>
              <base-input
                :invalid="$v.formData.fiel_password.$error"
                v-model="formData.fiel_password"
                focus
                type="text"
                name="fiel_password"
                tab-index="1"
                @input="$v.formData.fiel_password.$touch()"
              />
              <div v-if="$v.formData.fiel_password.$error">
                <span
                  v-if="!$v.formData.fiel_password.required"
                  class="text-danger"
                >
                  {{ $tc('validation.required') }}
                </span>
                <span
                  v-if="!$v.formData.fiel_password.minLength"
                  class="text-danger"
                >
                  {{
                    $tc(
                      'validation.name_min_length',
                      $v.formData.fiel_password.$params.minLength.min,
                      { count: $v.formData.fiel_password.$params.minLength.min }
                    )
                  }}
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-md-4">
              <label for="description"
                >{{ $t('customers.digital_stamp_cer') }} :
              </label>
              <div class="image-upload-box">
                <input
                  ref="digital_stamp_cer"
                  class=""
                  type="file"
                  accept=".cer"
                  @change="onDigitalCerChange"
                />
                <!-- <img v-if="previewESignCer" :src="previewESignCer" class="preview-logo">
                <div v-else class="upload-content">
                  <font-awesome-icon class="upload-icon" icon="cloud-upload-alt"/>
                  <p class="upload-text"> {{ $t('general.choose_file') }} </p>
                </div> -->
              </div>
            </div>
            <div class="form-group col-md-4">
              <label for="description"
                >{{ $t('customers.digital_stamp_key') }} :
              </label>
              <div class="image-upload-box">
                <input
                  ref="digital_stamp_key"
                  class=""
                  type="file"
                  accept=".key"
                  @change="onDigitalKeyChange"
                />
                <!-- <img v-if="previewESignCer" :src="previewESignCer" class="preview-logo">
                <div v-else class="upload-content">
                  <font-awesome-icon class="upload-icon" icon="cloud-upload-alt"/>
                  <p class="upload-text"> {{ $t('general.choose_file') }} </p>
                </div> -->
              </div>
            </div>
            <div class="form-group col-md-4">
              <label class="form-label">{{
                $t('customers.csd_password')
              }}</label>
              <base-input
                :invalid="$v.formData.csd_password.$error"
                v-model="formData.csd_password"
                focus
                type="text"
                name="csd_password"
                tab-index="1"
                @input="$v.formData.csd_password.$touch()"
              />
              <div v-if="$v.formData.csd_password.$error">
                <span
                  v-if="!$v.formData.csd_password.required"
                  class="text-danger"
                >
                  {{ $tc('validation.required') }}
                </span>
                <span
                  v-if="!$v.formData.csd_password.minLength"
                  class="text-danger"
                >
                  {{
                    $tc(
                      'validation.name_min_length',
                      $v.formData.csd_password.$params.minLength.min,
                      { count: $v.formData.csd_password.$params.minLength.min }
                    )
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MultiSelect from 'vue-multiselect'
import { validationMixin } from 'vuelidate'
import AddressStub from '../../stub/address'
const {
  required,
  minLength,
  email,
  url,
  maxLength,
} = require('vuelidate/lib/validators')

export default {
  components: { MultiSelect },
  mixins: [validationMixin],
  data() {
    return {
      isCopyFromBilling: false,
      isLoading: false,
      formData: {
        name: null,
        contact_name: null,
        email: null,
        phone: null,
        currency_id: null,
        website: null,
        addresses: [],
        rfc: null,
        tax_regime: null,
        e_sign_cer: null,
        e_sign_key: null,
        previewESignCer: null,
        digital_stamp_cer: null,
        digital_stamp_key: null,
        fiel_password: null,
        csd_password: null,
        previous_tax_regime: null,
      },
      currency: null,
      billing: {
        name: null,
        country_id: 142,
        state: null,
        city: null,
        phone: null,
        zip: null,
        address_street_1: null,
        address_street_2: null,
        colony: null,
        type: 'billing',
      },
      shipping: {
        name: null,
        country_id: 142,
        state: null,
        city: null,
        phone: null,
        zip: null,
        address_street_1: null,
        address_street_2: null,
        colony: null,
        type: 'shipping',
      },
      currencyList: [],

      billing_country: { id: 142, code: 'MX', name: 'Mexico', phonecode: 52 },
      shipping_country: null,

      billingCountries: [],
      billingStates: [],
      shippingCountries: [],

      tax_regimes: null,
      second_tax_regimes: null,
      one_previous_tax_regime: null,
      two_previous_tax_regime: null,
      label_one_previous_tax_regime: null,
      label_two_previous_tax_regime: null,
    }
  },
  validations: {
    tax_regimes: {
      required,
    },
    formData: {
      rfc: {
        required,
        minLength: minLength(12),
      },
      name: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
      website: {
        url,
      },
      fiel_password: {
        minLength: minLength(8),
      },
      csd_password: {
        minLength: minLength(8),
      },
    },
    billing: {
      name: {
        required,
      },
      zip: {
        required,
      },
      city: {
        required,
      },
      state: {
        required,
      },
      address_street_1: {
        maxLength: maxLength(255),
        required,
      },
      address_street_2: {
        maxLength: maxLength(255),
        required,
      },
      colony: {
        maxLength: maxLength(255),
        required,
      },
    },
    shipping: {
      address_street_1: {
        maxLength: maxLength(255),
      },
      address_street_2: {
        maxLength: maxLength(255),
      },
    },
  },
  computed: {
    ...mapGetters('currency', ['defaultCurrency', 'currencies']),
    ...mapGetters('catalogs', ['taxRegimes']),
    isEdit() {
      if (this.$route.name === 'customers.edit') {
        return true
      }
      return false
    },
    hasBillingAdd() {
      let billing = this.billing
      if (
        billing.name ||
        billing.country_id ||
        billing.state ||
        billing.city ||
        billing.phone ||
        billing.zip ||
        billing.address_street_1 ||
        billing.address_street_2 ||
        billing.colony
      ) {
        return true
      }
      return false
    },
    hasShippingAdd() {
      let shipping = this.shipping
      if (
        shipping.name ||
        shipping.country_id ||
        shipping.state ||
        shipping.city ||
        shipping.phone ||
        shipping.zip ||
        shipping.address_street_1 ||
        shipping.address_street_2
      ) {
        return true
      }
      return false
    },
  },
  watch: {
    billing_country(newCountry) {
      console.log(this.billing_country)
      if (newCountry) {
        this.billing.country_id = newCountry.id
        this.isDisabledBillingState = false
      } else {
        this.billing.country_id = null
      }
    },
    shipping_country(newCountry) {
      if (newCountry) {
        this.shipping.country_id = newCountry.id
        return true
      } else {
        this.shipping.country_id = null
      }
    },
  },
  mounted() {
    this.fetchCountry()
    this.fetchStates()
    const fecha = new Date()
    this.label_one_previous_tax_regime = fecha.getFullYear() - 1
    this.label_two_previous_tax_regime = fecha.getFullYear() - 2
    if (this.isEdit) {
      this.loadCustomer()
    } else {
      this.currency = this.defaultCurrency
    }
  },
  methods: {
    currencyNameWithCode({ name, code }) {
      return `${code} - ${name}`
    },
    ...mapActions('customer', [
      'addCustomer',
      'fetchCustomer',
      'updateCustomer',
    ]),
    async loadCustomer() {
      let {
        data: { customer, currencies, currency },
      } = await this.fetchCustomer(this.$route.params.id)

      this.formData.id = customer.id
      this.formData.rfc = customer.rfc
      this.formData.tax_regime = customer.tax_regime
      this.formData.name = customer.name
      this.formData.contact_name = customer.contact_name
      this.formData.email = customer.email
      this.formData.phone = customer.phone
      this.formData.currency_id = customer.currency_id
      this.formData.website = customer.website

      if (customer.billing_address) {
        this.billing = customer.billing_address

        if (customer.billing_address.country_id) {
          this.billing_country = this.billingCountries.find(
            (c) => c.id === customer.billing_address.country_id
          )
        }
      }

      if (customer.shipping_address) {
        this.shipping = customer.shipping_address

        if (customer.shipping_address.country_id) {
          this.shipping_country = this.shippingCountries.find(
            (c) => c.id === customer.shipping_address.country_id
          )
        }
      }

      this.currencyList = currencies
      this.formData.currency_id = customer.currency_id
      this.currency = currency
      const taxRegime = this.taxRegimes.filter(
        (regime) => regime.id == customer.tax_regime
      )
      this.tax_regimes = taxRegime.length ? taxRegime[0] : null

      const secondTaxRegime = this.taxRegimes.filter(
        (regime) => regime.id == customer.tax_regime_b
      )
      this.second_tax_regimes = secondTaxRegime.length
        ? secondTaxRegime[0]
        : null

      this.previous_tax_regime = customer.previous_tax_regime
        ? JSON.parse(customer.previous_tax_regime)
        : []

      this.previous_tax_regime.forEach((item) => {
        switch (item.anio) {
          case this.label_one_previous_tax_regime:
            const onetaxRegime = this.taxRegimes.filter(
              (regime) => regime.id == item.tax_regime
            )
            this.one_previous_tax_regime = onetaxRegime.length
              ? onetaxRegime[0]
              : null
            break

          case this.label_two_previous_tax_regime:
            const twotaxRegime = this.taxRegimes.filter(
              (regime) => regime.id == item.tax_regime
            )
            this.two_previous_tax_regime = twotaxRegime.length
              ? twotaxRegime[0]
              : null
            break
        }
      })
    },
    async fetchCountry() {
      let res = await window.axios.get('/api/countries')
      if (res) {
        this.billingCountries = res.data.countries
        this.shippingCountries = res.data.countries
      }
    },
    async fetchStates() {
      let res = await window.axios.get('/api/states')
      if (res) {
        this.billingStates = res.data.states
      }
    },
    copyAddress(val) {
      if (val === true) {
        this.isCopyFromBilling = true
        this.shipping = { ...this.billing, type: 'shipping' }
        this.shipping_country = this.billing_country
        this.shipping_state = this.billing_state
        this.shipping_city = this.billing_city
      } else {
        this.shipping = { ...AddressStub, type: 'shipping' }
        this.shipping_country = null
        this.shipping_state = null
        this.shipping_city = null
      }
    },
    async submitCustomerData() {
      this.$v.formData.$touch()
      this.$v.billing.$touch()
      this.$v.tax_regimes.$touch()

      let data = new FormData()
      if (this.$v.$invalid) {
        return true
      }
      if (this.hasBillingAdd && this.hasShippingAdd) {
        this.formData.addresses = [{ ...this.billing }, { ...this.shipping }]
      } else {
        if (this.hasBillingAdd) {
          this.formData.addresses = [{ ...this.billing }]
        }
        if (this.hasShippingAdd) {
          this.formData.addresses = [{ ...this.shipping }]
        }
      }
      if (this.formData.rfc) {
        data.append('rfc', this.formData.rfc)
      }
      if (this.formData.name) {
        data.append('name', this.formData.name)
      }
      if (this.formData.contact_name) {
        data.append('contact_name', this.formData.contact_name)
      }
      if (this.formData.email) {
        data.append('email', this.formData.email)
      }
      if (this.formData.phone) {
        data.append('phone', this.formData.phone)
      }
      if (this.tax_regimes) {
        data.append('tax_regime', this.tax_regimes.id)
      }
      if (this.second_tax_regimes) {
        data.append('tax_regime_b', this.second_tax_regimes.id)
        console.log(this.second_tax_regimes.id)
      }
      if (this.formData.website) {
        data.append('website', this.formData.website)
      }
      if (this.formData.currency_id) {
        data.append('currency_id', this.formData.currency_id)
      }
      if (this.formData.addresses) {
        data.append('addresses', JSON.stringify(this.formData.addresses))
      }

      //agregar regimen anteriores
      let oneOk = 0
      let twoOk = 0
      let i = 0

      if (this.previous_tax_regime) {
        for (i = 0; i < this.previous_tax_regime.length; i++) {
          switch (this.previous_tax_regime[i].anio) {
            case this.label_one_previous_tax_regime:
              this.previous_tax_regime[i].tax_regime =
                this.one_previous_tax_regime.id
              oneOk = 1
              break

            case this.label_two_previous_tax_regime:
              this.previous_tax_regime[i].tax_regime =
                this.two_previous_tax_regime.id
              twoOk = 1
              break
          }
        }
        if (oneOk == 0 && this.one_previous_tax_regime)
          this.previous_tax_regime[i] = {
            anio: this.label_one_previous_tax_regime,
            tax_regime: this.one_previous_tax_regime.id,
          }
        if (twoOk == 0 && this.two_previous_tax_regime)
          this.previous_tax_regime[i] = {
            anio: this.label_two_previous_tax_regime,
            tax_regime: this.two_previous_tax_regime.id,
          }

        data.append(
          'previous_tax_regime',
          JSON.stringify(this.previous_tax_regime)
        )
      }

      if (this.e_sign_cer) {
        data.append('e_sign_cer', this.e_sign_cer)
      }
      if (this.e_sign_key) {
        data.append('e_sign_key', this.e_sign_key)
      }
      if (this.digital_stamp_cer) {
        data.append('digital_stamp_cer', this.digital_stamp_cer)
      }
      if (this.digital_stamp_key) {
        data.append('digital_stamp_key', this.digital_stamp_key)
      }
      if (this.formData.fiel_password) {
        data.append('fiel_password', this.formData.fiel_password)
      }
      if (this.formData.csd_password) {
        data.append('csd_password', this.formData.csd_password)
      }

      if (this.isEdit) {
        data.append('id', this.formData.id)
        if (this.currency) {
          this.formData.currency_id = this.currency.id
          data.append('currency_id', this.formData.currency_id)
        }
        this.isLoading = true
        try {
          data.append('_method', 'PUT')
          let response = await this.updateCustomer({
            id: this.$route.params.id,
            editData: data,
          })
          if (response.data.success) {
            window.toastr['success'](this.$t('customers.updated_message'))
            this.$router.push('/general/customers/pending')
            this.isLoading = false
            return true
          } else {
            this.isLoading = false
            if (response.data.error) {
              window.toastr['error'](this.$t('validation.email_already_taken'))
            }
          }
        } catch (err) {
          if (err.response.data.toast) {
            this.isLoading = false
            window.toastr['error'](err.response.data.toast)
          }
          if (err.response.data.errors.email) {
            this.isLoading = false
            window.toastr['error'](this.$t('validation.email_already_taken'))
          }
        }
      } else {
        this.isLoading = true
        if (this.currency) {
          this.isLoading = true
          this.formData.currency_id = this.currency.id
          data.append('currency_id', this.formData.currency_id)
        }
        try {
          let response = await this.addCustomer(data) //this.formData)
          if (response.data.success) {
            window.toastr['success'](this.$t('customers.created_message'))
            this.$router.push('/general/customers/pending')
            this.isLoading = false
            return true
          }
        } catch (err) {
          if (err.response.data.toast) {
            this.isLoading = false
            window.toastr['error'](err.response.data.toast)
          }
          if (err.response.data.errors.email) {
            this.isLoading = false
            window.toastr['error'](this.$t('validation.email_already_taken'))
          }
        }
      }
    },
    onESignCerChange(e) {
      var input = event.target
      var filePath = input.value

      var allowedExtensions = /(\.cer)$/i
      if (!allowedExtensions.exec(filePath)) {
        swal('Revise que el archivo sea .CER ')
        input.value = ''
        return false
      }

      this.e_sign_cer = input.files[0]

      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = (e) => {
          this.previewESignCer = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      }
    },
    onESignKeyChange(e) {
      var input = event.target
      var filePath = input.value

      var allowedExtensions = /(\.key)$/i
      if (!allowedExtensions.exec(filePath)) {
        swal('Revise que el archivo sea .KEY ')
        input.value = ''
        return false
      }
      var input = event.target
      this.e_sign_key = input.files[0]

      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = (e) => {
          this.previewESignCer = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      }
    },
    onDigitalCerChange(e) {
      var input = event.target
      var filePath = input.value

      var allowedExtensions = /(\.cer)$/i
      if (!allowedExtensions.exec(filePath)) {
        swal('Revise que el archivo sea .CER ')
        input.value = ''
        return false
      }
      var input = event.target
      this.digital_stamp_cer = input.files[0]

      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = (e) => {
          this.previewESignCer = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      }
    },
    onDigitalKeyChange(e) {
      var input = event.target
      var filePath = input.value

      var allowedExtensions = /(\.key)$/i
      if (!allowedExtensions.exec(filePath)) {
        swal('Revise que el archivo sea .KEY ')
        input.value = ''
        return false
      }
      var input = event.target
      this.digital_stamp_key = input.files[0]

      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = (e) => {
          this.previewESignCer = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      }
    },
  },
}
</script>
