export const SET_INVOICES = 'SET_INVOICES'
export const ADD_INVOICE = 'ADD_INVOICE'
export const UPDATE_INVOICE = 'UPDATE_INVOICE'
export const DELETE_INVOICE = 'DELETE_INVOICE'
export const DELETE_MULTIPLE_INVOICES = 'DELETE_MULTIPLE_INVOICES'
export const SET_SELECTED_INVOICES = 'SET_SELECTED_INVOICES'
export const SET_TOTAL_INVOICES = 'SET_TOTAL_INVOICES'
export const SET_TOTAL_REQUESTS = 'SET_TOTAL_REQUESTS'
export const RESET_CUSTOMER = 'RESET_CUSTOMER'
export const RESET_ITEM = 'RESET_ITEM'
export const SET_CUSTOMER = 'SET_CUSTOMER'
export const SET_ITEM = 'SET_ITEM'
export const SET_TEMPLATE_ID = 'SET_TEMPLATE_ID'
export const SELECT_CUSTOMER = 'SELECT_CUSTOMER'
export const RESET_SELECTED_CUSTOMER = 'RESET_SELECTED_CUSTOMER'
export const SET_SELECT_ALL_STATE = 'SET_SELECT_ALL_STATE'
export const RESET_SELECTED_INVOICES = 'RESET_SELECTED_INVOICES'
export const UPDATE_INVOICE_STATUS = 'UPDATE_INVOICE_STATUS'
export const SET_DATA_MODAL_ADD_PAID_DOCUMENT = 'SET_DATA_MODAL_ADD_PAID_DOCUMENT'
export const SET_INVOICE_ID = 'SET_INVOICE_ID'
export const QUICK_ASSIGMENT_APPEND_INVOICES = 'QUICK_ASSIGMENT_APPEND_INVOICES'
export const QUICK_ASSIGMENT_CLEAR = 'QUICK_ASSIGMENT_CLEAR'
export const QUICK_ASSIGMENT_CHANGE_PRODSERV_CATEGORY =
  'QUICK_ASSIGMENT_CHANGE_PRODSERV_CATEGORY'
export const QUICK_ASSIGMENT_TOGGLE_IN_TAX_RETURN =
  'QUICK_ASSIGMENT_TOGGLE_IN_TAX_RETURN'
export const QUICK_ASSIGMENT_SET_CONFIRMED_INVOICE =
  'QUICK_ASSIGMENT_SET_CONFIRMED_INVOICE'
export const QUICK_ASSIGMENT_UPDATE_IN_TAX_RETURN_STATUS =
  'QUICK_ASSIGMENT_UPDATE_IN_TAX_RETURN_STATUS'
