<template>
  <div class="pt-4 pb-4 pl-4 pr-4">
    <form @submit.prevent="savePaidDocument()">





      <div class="form-group">
        <label>Descripción</label>
        <div class="base-input">
          <!-- <money
            v-model="form.amount"
            v-bind="customerCurrency"
            class="input-field"
            @input="$v.form.amount.$touch()"
            :disabled="this.dataModalAddPaidDocument.edit"
          /> -->
          <input/>
          <div v-if="$v.form.amount.$error">
            {{ $t('validation.required') }}
          </div>
        </div>
      </div>
      <div>
        <div class="text-left">
          <button type="submit" class="btn btn-primary">
            Eliminar
          </button>
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-primary">
            {{ $t('invoices.save') }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import decimalValidation from 'vuelidate/lib/validators/decimal'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [validationMixin],

  data: () => ({
    form: {
      document_type: null,
      payment_date: '',
      amount: 0,
      uuid: null,
    },
  }),

  computed: {
    ...mapGetters('currency', ['defaultCurrency']),
    ...mapGetters('invoice', ['dataModalAddPaidDocument']),
    ...mapGetters('catalogs', [
      'paidDocumentOptions',
      'searchPaidDocumentOption',
    ]),

    customerCurrency() {
      if (this.defaultCurrency) {
        return {
          decimal: this.defaultCurrency.decimal_separator,
          thousands: this.defaultCurrency.thousand_separator,
          prefix: this.defaultCurrency.symbol + ' ',
          precision: this.defaultCurrency.precision,
          masked: false,
        }
      } else {
        return this.defaultCurrenctForInput
      }
    },
  },

  validations: {
    form: {
      document_type: {
        required,
      },
      payment_date: {
        required,
      },
      amount: {
        required,
        decimal: decimalValidation,
      },
    },
  },

  created() {
    this.form.document_type = this.searchPaidDocumentOption('direct_collection')
  },
  mounted() {
    this.form.payment_date =
      this.dataModalAddPaidDocument.date != undefined
        ? this.dataModalAddPaidDocument.date
        : moment().toISOString()

    this.form.amount = this.dataModalAddPaidDocument.amount
    this.form.document_type = this.searchPaidDocumentOption(
      this.dataModalAddPaidDocument.document_type
    )
  },

  methods: {
    ...mapActions('invoice', [
      'fetchSavePaidDocument',
      'fetchUpdatePaidDocument',
    ]),
    ...mapActions('modal', ['closeModal']),

    savePaidDocument() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid !== false) {
        return
      }

      const data = {
        ...this.form,
        document_type: this.form.document_type.name,
        amount: Number(this.form.amount) * 100,
        invoice_id: this.dataModalAddPaidDocument.invoiceId,
        id:this.dataModalAddPaidDocument.id
      }
      if (this.dataModalAddPaidDocument.edit) {
        this.fetchUpdatePaidDocument(data)
          .then((response) => {
            if (response.data.success) {
              window.toastr['success'](
               'Documento Actualizado'
              )
              window.hub.$emit('paid_document_added')
              this.closeModal()
            } else {
              window.toastr['error'](
                this.$t('invoices.errors.cant_apply_payment')
              )
            }
          })
          .catch((err) => {
            window.toastr['error'](this.$t('invoices.errors.paid_document_add'))
          })
      } else {
        this.fetchSavePaidDocument(data)
          .then((response) => {
            if (response.data.success) {
              window.toastr['success'](
                this.$t('invoices.messages.paid_document_added')
              )
              window.hub.$emit('paid_document_added')
              this.closeModal()
            } else {
              window.toastr['error'](
                this.$t('invoices.errors.cant_apply_payment')
              )
            }
          })
          .catch((err) => {
            window.toastr['error'](this.$t('invoices.errors.paid_document_add'))
          })
      }
    },
  },
}
</script>
