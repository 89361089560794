<style>
li {
  z-index: 10;
}
</style>

<template>
  <div class="main-content">
    <div class="page-header mt-4" style="z-index: 1">
      <h3 class="page-title">Usuarios</h3>

      <div class="page-actions row">
        <conditional-wrapper
          :show-for-admin="true"
          :show-for-executives="false"
          :show-for-customers="false"
        >
          <router-link class="col-xs-2" to="/general/users/create">
            <base-button size="small" icon="plus" color="theme">
              Nuevo usuario
            </base-button>
          </router-link>
        </conditional-wrapper>
      </div>
    </div>
    <div class="">
      <div class="row">
        <div class="col-sm-3">
          <label class="form-label">Búsqueda</label>
          <base-input
            v-model="filters.search"
            type="text"
            name="search"
            autocomplete="on"
          />
        </div>
      </div>
    </div>
    <table-component
      ref="table"
      :show-filter="false"
      :data="fetchData"
      table-class="table"
    >
      <table-column label="ID" show="id" />

      <table-column :label="$t('customers.name')" show="name">
        <template slot-scope="row">
          <span>{{ $t('customers.name') }}</span>
          <div>
            {{ row.name }}
           
          </div>
        </template>
      </table-column>
      <table-column label="Email" show="email" />
      <table-column label="Role" show="role" />


      <table-column
        :sortable="false"
        :filterable="false"
        cell-class="action-dropdown"
      >
        <template slot-scope="row">
          <span> {{ $t('customers.action') }} </span>
          <v-dropdown>
            <a slot="activator" href="#">
              <dot-icon />
            </a>

            <v-dropdown-item>
              <router-link
                :to="`/general/users/${row.id}/edit`"
                class="dropdown-item"
              >
                <font-awesome-icon
                  :icon="['fas', 'pencil-alt']"
                  class="dropdown-item-icon"
                />
                {{ $t('general.edit') }}
              </router-link>
            </v-dropdown-item>

            <v-dropdown-item>
              <div class="dropdown-item" @click="removeCustomer(row.id)">
                <font-awesome-icon
                  :icon="['fas', 'trash']"
                  class="dropdown-item-icon"
                />
                {{ $t('general.delete') }}
              </div>
            </v-dropdown-item>
          </v-dropdown>
        </template>
      </table-column>
    </table-component>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Pagination from '../../components/base/base-table/components/Pagination'
import CertificateWarning from '../components/CertificateWarning'
import NewIcon from '../../components/icon/NewIcon'
import TaxReturnRif from '../components/TaxReturnRif.vue'
import ConditionalWrapper from '../components/ConditionalWrapper.vue'

export default {
  components: {
    Pagination,
    CertificateWarning,
    NewIcon,
    ConditionalWrapper,
  },

  data: () => ({
    statusMenu: [
      {
        icon: 'file',
        name: 'Todos',
        key: 'todos',
      },
    ],
    filters: {
      display_name: '',
      search: '',
      rfc: '',
      phone: '',
      taxRegime: '',
      limit_create: null,
      status: '',
      icon: '',
    },
    ejecutivos: [],
    filtersAppliedName: '',
    label_one_previous_tax_regime: null,
    label_two_previous_tax_regime: null,
    showFilters: false,
    filtersApplied: false,
    isRequestOngoing: true,
  }),
  computed: {
    ...mapGetters('catalogs', ['searchTaxRegime']),
  },
  mounted() {
    this.fetchStatus()
  },
  created() {
    this.fetchEjecutivos()
  },
  watch: {
    filters: {
      handler: 'setFilters',
      deep: true,
    },
  },
  methods: {
    ...mapActions('modal', ['openModal']),
    ...mapActions('payment', ['fetchPaquetes', 'eliminarPaquete']),
    ...mapActions('customer', ['fetchCustomers']),
    ...mapActions('CurrentCustomer', ['setCurrentCustomer']),
    ...mapActions('user', ['getEjecutivos', 'setEjecutivos','fetchUsers']),
    ...mapActions('userProfile', ['validateRole']),

    ...mapActions('assigned', ['fetchDeclarationStatus']),

    fetchStatus() {
      this.fetchDeclarationStatus().then((value) => {
        this.statusMenu = this.statusMenu.concat(value).concat([
          {
            icon: 'user',
            name: 'Nuevos Usuarios',
            key: 'nuevos',
          },
        ])
      })
    },
    fetchEjecutivos() {
      this.getEjecutivos().then((value) => {
        this.ejecutivos = value
      })
    },
    refreshTable() {
      this.$refs.table.refresh()
    },
    isWithinTwoWeeks(createdAt) {
      const now = new Date()
      const twoWeeksAgo = new Date(now.getTime() - 14 * 24 * 60 * 60 * 1000) // Calculating two weeks ago

      const createAttDate = new Date(createdAt)

      return createAttDate >= twoWeeksAgo
    },
    async fetchData({ page, sort }) {
      const fecha = new Date()
      this.label_one_previous_tax_regime = fecha.getFullYear() - 1
      this.label_one_previous_tax_regime =
        this.label_one_previous_tax_regime.toString()
      this.label_two_previous_tax_regime = fecha.getFullYear() - 2
      this.label_two_previous_tax_regime =
        this.label_two_previous_tax_regime.toString()

      let data = {
        display_name: this.filters.display_name,
        search: this.filters.search,
        phone: this.filters.phone,
        rfc: this.filters.rfc,
        taxRegime: this.filters.taxRegime.id,
        orderByField: sort.fieldName || 'created_at',
        orderBy: sort.order || 'desc',
        limit_create: this.filters.limit_create,
        status: this.filters.status,
        page,
      }

      this.isRequestOngoing = true
      let response = await this.fetchUsers(data)
      this.isRequestOngoing = false
      console.log(response.data)
      return {
        data: response.data.users.data,
        pagination: {
          totalPages: response.data.users.last_page,
          currentPage: page,
        },
      }
    },
    setCustomerToViewDetails(customer) {
      this.setCurrentCustomer(customer)
      this.$router.push({
        path: '/general/customer',
        // query: { customer: JSON.stringify(customer) }, // Convierte 'customer' a una cadena JSON
      })
    },
    setFilters() {
      this.filtersApplied = true
      this.refreshTable()
    },
    clearFilter() {
      this.filters = {
        display_name: '',
        rfc: '',
        phone: '',
        taxRegime: '',
        limit_create: null,
        status: '',
        icon: '',
      }

      this.$nextTick(() => {
        this.filtersApplied = false
      })
    },
    setEjecutivo(user, ejecutivo) {
      const data = {
        user,
        ejecutivo,
      }
      this.setEjecutivos(data).then((value) => {
        if (value != null) {
          this.refreshTable()
        }
      })
    },
    setFilter(filter) {
      this.clearFilter()
      this.filtersAppliedName = filter['name']
      if (filter['key'] == 'todos') {
        return
      }

      if (filter['key'] == 'nuevos') {
        this.filters['icon'] = filter['icon']
        this.filters['limit_create'] = new Date(
          Date.now() - 14 * 24 * 60 * 60 * 1000
        )

        return
      }

      this.filters['status'] = filter['name']
      this.filters['icon'] = filter['icon']
    },
  },
}
</script>
