<template>
  <tr class="item-row invoice-item-row">
    <!-- Unit Code -->
    <td class="text-left" style="padding: 10px !important;">
       <div class="btn-group discount-drop-down" role="group">
          <base-select
            v-model="item.id"
            :options="unitCodeOptions"
            :searchable="true"
            :allow-empty="false"
            :show-labels="false"
            :placeholder="$t('items.select_a_unit')"
            :invalid="$v.item.id.$error"
            label="description"
            track-by="id"
          />
        <div v-if="$v.item.id.$error">
          <span v-if="!$v.item.id.required" class="text-danger">
            {{ $t('validation.required') }}
          </span>
        </div>
      </div> 
    </td>
  </tr>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { mapGetters } from 'vuex'
export default {
  props: {
    itemData: {
      type: Object,
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
  },
  validationMixin: [validationMixin],
  data: () => ({
    item: {},
  }),
  validations: () => ({
    item: {
      id: {
        required,
      },
      description: {
        required,
      },
    },
  }),
  computed: {
    ...mapGetters('catalogs', [
      'unitCodeSelect',
    ]),
    unitCodeOptions() {
      return this.unitCodeSelect.map(( item ) => {
        return {
          ...item,
          id: item.id,
          description: `${item.id} - ${item.description}`,
        }
      })
    },
    showRemoveItemIcon() {
      if (this.items.length == 1) {
        return false
      }
      return true
    },
  },
  watch: {
    item: {
      handler: 'updateItem',
      deep: true,
    },
  },
  created() {
    this.item = {
      ...this.itemData,
    }
  },
  methods: {
    selectUnitCode(unidad) {
      this.item.unitCode = unidad
    },
    removeItem() {
      this.$emit('remove', this.index)
    },
    selectFixed() {
      this.item.ivaType = 'fixed'
    },
    updateItem() {
      this.$emit('update', {
        index: this.index,
        item: {
          ...this.item,
        },
      })
    },
    validateItem() {
      this.$v.item.$touch()
      if (this.item !== null) {
        this.$emit('itemValidate', this.index, !this.$v.$invalid)
      } else {
        this.$emit('itemValidate', this.index, false)
      }
    },
  },
}
</script>
