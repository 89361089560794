var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "dot-icon", on: { click: _vm.checktoggle } }, [
      _c("span", {
        staticClass: "dot dot1",
        class: { "move-right": _vm.toggle },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "dot dot2" }),
      _vm._v(" "),
      _c("span", {
        staticClass: "dot dot3",
        class: { "move-left": _vm.toggle },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }