<template>
  <header class="site-header">
    <a href="/" class="brand-main">
      <img
        id="logo-white"
        src="/assets/img/logo-taxati.png"
        alt="Crater Logo"
        class="d-none d-md-inline"
      />
      <img
        id="logo-mobile"
        src="/assets/img/logo-taxati.png"
        alt="Laraspace Logo"
        class="d-md-none"
      />
    </a>

    <a href="#" class="nav-toggle" @click="onNavToggle">
      <div class="hamburger hamburger--arrowturn">
        <div class="hamburger-box">
          <div class="hamburger-inner" />
        </div>
      </div>
    </a>
    <ul class="action-list">
      <!-- <li>
        <v-dropdown :show-arrow="false">
          <a slot="activator" href="#">
            <font-awesome-icon icon="plus" />
          </a>
          <v-dropdown-item>
            <router-link class="dropdown-item" to="/general/invoices/create">
              <font-awesome-icon icon="file-alt" class="dropdown-item-icon" /> <span> {{ $t('invoices.new_invoice') }} </span>
            </router-link>
          </v-dropdown-item>
          <v-dropdown-item>
            <router-link class="dropdown-item" to="/general/estimates/create">
              <font-awesome-icon class="dropdown-item-icon" icon="file" /> <span> {{ $t('estimates.new_estimate') }} </span>
            </router-link>
          </v-dropdown-item>
          <v-dropdown-item>
            <router-link class="dropdown-item" to="/general/customers/create">
              <font-awesome-icon class="dropdown-item-icon" icon="user" />  <span> {{ $t('customers.new_customer') }} </span>
            </router-link>
          </v-dropdown-item>
        </v-dropdown>
      </li> -->
      <li>
        <v-dropdown :show-arrow="false">
          <a
            slot="activator"
            href="#"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            class="avatar"
          >
            <img :src="profilePicture" alt="Avatar" />
          </a>
          <v-dropdown-item>
            <router-link class="dropdown-item" to="/general/settings">
              <font-awesome-icon icon="cogs" class="dropdown-item-icon" />
              <span> {{ $t('navigation.settings') }} </span>
            </router-link>
          </v-dropdown-item>
          <v-dropdown-item>
            <a href="#" class="dropdown-item" @click.prevent="logout">
              <font-awesome-icon
                icon="sign-out-alt"
                class="dropdown-item-icon"
              />
              <span> {{ $t('navigation.logout') }} </span>
            </a>
          </v-dropdown-item>
        </v-dropdown>
      </li>
    </ul>
    <!--<nav class="navbar">
      <ol class="breadcrumb" style="background-color: #F9FBFF; margin-left: 17.5%;">
        <li v-for="item in crumbs" class="breadcrumb-item">
          <router-link slot="item-title" to="#">
            {{ item.text }}
          </router-link>
        </li>
      </ol>
    </nav>-->
    <!-- <nav style="margin-left: 17.5%; margin-top: 0.250%;" aria-label="breadcrumb">
      <ol class="breadcrumb" style="background-color: #F9FBFF;">
        <li v-for="item in crumbs" class="breadcrumb-item active">{{ item.text }}</li>
      </ol>
    </nav> -->
  </header>
</template>

<script type="text/babel">
import { mapGetters, mapActions } from 'vuex'

export default {
  /*data: () => ({
    breadcrumb: [],
  }),*/
  computed: {
    ...mapGetters('userProfile', ['user']),
    profilePicture() {
      /*let array = this.$route.path.split('/')
      this.breadcrumb.push(array)
      console.log(this.breadcrumb)*/
      if (this.user && this.user.avatar !== null) {
        return this.user.avatar
      } else {
        return '/images/default-avatar.jpg'
      }
    },
    // crumbs() {
    //   let array = this.$route.path.split('/')
    //   array.shift()
    //   let breadcrumbs = array.reduce((breadcrumbArray, path, idx) => {
    //     breadcrumbArray.push({
    //       path: path,
    //       to: breadcrumbArray[idx - 1]
    //         ? breadcrumbArray[idx - 1].path + "/" + path
    //         : path,
    //       text: this.$route.matched[idx].meta.breadCrumb || path,
    //     });
    //     return breadcrumbArray;
    //   }, [])
    //   return breadcrumbs;
    // }
  },
  created() {
    this.loadData()
  },
  methods: {
    ...mapActions('userProfile', ['loadData']),
    ...mapActions({
      companySelect: 'changeCompany',
    }),
    ...mapActions('auth', ['logout']),
    onNavToggle() {
      this.$utils.toggleSidebar()
    },
  },
}
</script>
