import * as types from './mutation-types'
import * as dashboardTypes from '../dashboard/mutation-types'

export const fetchInvoices = ({ commit, dispatch, state }, params) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`/api/invoices`, {params}).then((response) => {
      commit(types.SET_INVOICES, response.data.invoices.data)
      commit(types.SET_TOTAL_INVOICES, response.data.invoiceTotalCount)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const fetchCreateInvoice = ({ commit, dispatch, state }) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`/api/invoices/create`).then((response) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const fetchInvoice = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`/api/invoices/${id}/edit`).then((response) => {
      commit(types.SET_TEMPLATE_ID, response.data.invoice.invoice_template_id)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const fetchViewInvoice = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`/api/invoices/${id}`).then((response) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const sendEmail = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post(`/api/invoices/send`, data).then((response) => {
      if (response.data.success) {
        commit(types.UPDATE_INVOICE_STATUS, {id: data.id, status: 'SENT'})
        commit('dashboard/' + dashboardTypes.UPDATE_INVOICE_STATUS, {id: data.id, status: 'SENT'}, { root: true })
      }
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

// export const SentEmail = ({ commit, dispatch, state }, invoiceId) => {
//   return new Promise((resolve, reject) => {
//     window.axios.post(`/api/invoices/sent/${invoiceId}`).then((response) => {
//       resolve(response)
//     }).catch((err) => {
//       reject(err)
//     })
//   })
// }

export const getMassiveDownloadRequests = ({ commit, dispatch, state }, params) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`/api/invoices/from-sat`, { params }).then((response) => {
      commit(types.SET_TOTAL_REQUESTS, response.data.massive_requests_count)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const generateMassiveDownloadRequest = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post('/api/invoices/from-sat', data).then((response) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const addInvoice = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post('/api/invoices', data).then((response) => {
      commit(types.ADD_INVOICE, response.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const deleteInvoice = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios.delete(`/api/invoices/${id}`).then((response) => {
      if (response.data.error) {
        resolve(response)
      } else {
        commit(types.DELETE_INVOICE, id)
        commit('dashboard/' + dashboardTypes.DELETE_INVOICE, id, { root: true })
        resolve(response)
      }
    }).catch((err) => {
      reject(err)
    })
  })
}

export const deleteMultipleInvoices = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios.post(`/api/invoices/delete`, {'id': state.selectedInvoices}).then((response) => {
      if (response.data.error) {
        resolve(response)
      } else {
        commit(types.DELETE_MULTIPLE_INVOICES, state.selectedInvoices)
        resolve(response)
      }
    }).catch((err) => {
      reject(err)
    })
  })
}

export const updateInvoice = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.put(`/api/invoices/${data.id}`, data).then((response) => {
      if (response.data.invoice) {
        commit(types.UPDATE_INVOICE, response.data)
      }
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const markAsSent = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post(`/api/invoices/mark-as-sent`, data).then((response) => {
      commit(types.UPDATE_INVOICE_STATUS, {id: data.id, status: 'SENT'})
      commit('dashboard/' + dashboardTypes.UPDATE_INVOICE_STATUS, {id: data.id, status: 'SENT'}, { root: true })
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const cloneInvoice = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post(`/api/invoices/clone`, data).then((response) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const searchInvoice = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`/api/invoices?${data}`).then((response) => {
      // commit(types.UPDATE_INVOICE, response.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const selectInvoice = ({ commit, dispatch, state }, data) => {
  commit(types.SET_SELECTED_INVOICES, data)
  if (state.selectedInvoices.length === state.invoices.length) {
    commit(types.SET_SELECT_ALL_STATE, true)
  } else {
    commit(types.SET_SELECT_ALL_STATE, false)
  }
}

export const setSelectAllState = ({ commit, dispatch, state }, data) => {
  commit(types.SET_SELECT_ALL_STATE, data)
}

export const selectAllInvoices = ({ commit, dispatch, state }) => {
  if (state.selectedInvoices.length === state.invoices.length) {
    commit(types.SET_SELECTED_INVOICES, [])
    commit(types.SET_SELECT_ALL_STATE, false)
  } else {
    let allInvoiceIds = state.invoices.map(inv => inv.id)
    commit(types.SET_SELECTED_INVOICES, allInvoiceIds)
    commit(types.SET_SELECT_ALL_STATE, true)
  }
}

export const resetSelectedInvoices = ({ commit, dispatch, state }) => {
  commit(types.RESET_SELECTED_INVOICES)
}
export const setCustomer = ({ commit, dispatch, state }, data) => {
  commit(types.RESET_CUSTOMER)
  commit(types.SET_CUSTOMER, data)
}

export const resetCustomer = ({ commit, dispatch, state }) => {
  commit(types.RESET_CUSTOMER)
}

export const setTemplate = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    commit(types.SET_TEMPLATE_ID, data)
    resolve({})
  })
}

export const selectCustomer = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`/api/customers/${id}`).then((response) => {
      commit(types.RESET_SELECTED_CUSTOMER)
      commit(types.SELECT_CUSTOMER, response.data.customer)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const resetSelectedCustomer = ({ commit, dispatch, state }, data) => {
  commit(types.RESET_SELECTED_CUSTOMER)
}

export const saveInTaxReturn = (state, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post(`/api/invoice-item/save-in-tax-return`, data)
      .then(response => resolve(response))
      .catch((err) => reject(err));
  });
}

export const saveInTaxReturnAll = (state, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post(`/api/invoice-item/save-in-tax-return/all`, data)
      .then(response => resolve(response))
      .catch((err) => reject(err));
  });
}

export const fetchChangeAllInTaxReturn = (state, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post(`/api/invoice-item/change-all-tax-return`, data)
      .then(resolve)
      .catch(reject);
  });
}

export const setDataModalAddPaidDocument = ({ commit }, data) => {
  commit(types.SET_DATA_MODAL_ADD_PAID_DOCUMENT, data);
}

export const fetchSavePaidDocument = (state, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post(`/api/paid-documents/create`, data)
      .then(resolve)
      .catch(reject);
  });
}

export const fetchUpdatePaidDocument = (state, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post(`/api/paid-documents/update`, data)
      .then(resolve)
      .catch(reject);
  });
}

export const fetchDeletePaidDocument = (state, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post(`/api/paid-documents/delete`, data)
      .then(resolve)
      .catch(reject);
  });
}

export const fetchInvoicePaidDocuments = (state, invoiceId) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`/api/paid-documents/list/${invoiceId}`)
      .then(resolve)
      .catch(reject);
  });
}

export const fetchDocumentSaveInTaxReturn = (state, data) =>{
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/documents/save-in-tax-return`, data)
      .then(resolve)
      .catch(reject)
  })
}

export const setCategoryToConcept = (store, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/invoice-methods/${data.id}/set-category`, data.form)
      .then(resolve)
      .catch(reject)
  })
}

export const setCategoryToMultipleConcepts = (store, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/invoice-methods/${data.id}/set-category-multiple`, data.form)
      .then(resolve)
      .catch(reject)
  })
}

export const checkInvoiceIdExitst = ({ state, commit }, params) => {
  return new Promise((resolve, reject) => {
    const filtered = state.invoicesId.filter((item) => item.uuid == params.uuid)
    if (filtered.length) {
      return resolve(filtered[0].id)
    }

    window.axios
      .get('/api/invoice-methods/uuid-exists', { params })
      .then((response) => {
        if (response.data.success) {
          commit(types.SET_INVOICE_ID, {
            uuid: params.uuid,
            id: response.data.id,
          })
          resolve(response.data.id)
        } else {
          reject(null)
        }
      })
      .catch(reject)
  });
}

export const invoicesForQuickAssignment = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/quick-assignment/invoices`, { params })
      .then((response) => {
        commit(
          types.QUICK_ASSIGMENT_APPEND_INVOICES,
          response.data.invoices.data
        )
        resolve(response)
      })
      .catch(reject)
  })
}

export const clearQuickAssigment = ({ commit }) => {
  commit(types.QUICK_ASSIGMENT_CLEAR)
}

export const quickAssignmentChangeCategoryForProdServ = ({ commit }, data) => {
  commit(types.QUICK_ASSIGMENT_CHANGE_PRODSERV_CATEGORY, data)
}

export const quickAssignmentToggleInTaxReturn = ({ commit }, data) => {
  commit(types.QUICK_ASSIGMENT_TOGGLE_IN_TAX_RETURN, data)
}

export const quickAssignmentSerializeInvoice = (
  { state, commit },
  invoiceId
) => {
  let invoice = state.quickAssignment.invoices.filter(
    (invoice) => invoice.id == invoiceId
  )
  if (invoice.length == 0) {
    return null
  }
  invoice = invoice[0]
  commit(types.QUICK_ASSIGMENT_SET_CONFIRMED_INVOICE, invoice.id)
  return {
    invoiceId: invoice.id,
    items: invoice.items.map((concept) => {
      let subcategoryId = null
      if (concept.quickAssignment.subcategory) {
        subcategoryId = concept.quickAssignment.subcategory.id
      }
      return {
        id: concept.id,
        in_tax_return: concept.quickAssignment.inTaxReturn,
        subcategory_id: subcategoryId,
      }
    }),
  }
}

export const quickAssignmentSave = (store, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/quick-assignment/invoices/${data.invoiceId}`, {
        items: data.items,
      })
      .then(resolve)
      .catch(reject)
  })
}

export const quickAssignmentUpdateInvoiceInTaxReturnStatus = (
  { commit },
  data
) => {
  commit(types.QUICK_ASSIGMENT_UPDATE_IN_TAX_RETURN_STATUS, data)
}

export const saveCommentInTaxReturn = (store, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/invoices/invoices_item`, {
        id: data.id,
        comment: data.comment
      })
      .then(resolve)
      .catch(reject)
  })
}


