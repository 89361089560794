<template>
  <div class="p-3">
    <form @submit.prevent="savePago()">
      <div class="form-group">

        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="inputGroupSelect01"
              >Plan</label
            >
          </div>
          <select
            class="custom-select"
            v-model="form.paquete"
            @change="$v.form.$touch(); onChange(form.paquete)"
          >
            <option value="" selected>Elegir...</option>
            <option v-for="paquete in selectPaquetes" :value="paquete" :key="paquete.id" >{{paquete.nombre}}</option>
          </select>
        </div>
        <div class="error" v-if="!$v.form.plan.required">
          Debe elegir un plan
        </div>
        <label for="">Periodicidad</label>
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            v-model="form.periodicidad_nombre"
            readonly
          />
        </div>
        <label for="">Precio Unitario</label>
        <div class="input-group mb-3">
          <input
            type="number"
            class="form-control"
            v-model="form.precio"
            readonly
          />
        </div>

        <label for="">Año</label>
        <div class="input-group mb-3">
          <select
            class="custom-select"
            v-model="form.anioInicia"
            @change="$v.form.$touch(); onChangeMes(form.fechaInicia, form.anioInicia)"
          >
            <option value="" selected>Elegir...</option>
            <option v-for="anio in anios" :value="anio" :key="anio" >{{anio}}</option>
          </select>
        </div>
        <div class="error" v-if="!$v.form.anioInicia.required">
            El año que inicia es requerido.
        </div>

        <label for="">Periodo</label>
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            v-model="form.fechaTerminaNombre"
          />

          <select
            class="custom-select"
            v-model="form.anioInicia"
            @change="$v.form.$touch(); onChangeMes(form.fechaInicia, form.anioInicia)"
          >
            <option value="" selected>Elegir...</option>
            <option v-for="anio in anios" :value="anio" :key="anio" >{{anio}}</option>
          </select>

        </div>
        <div class="error" v-if="!$v.form.fechaInicia.required">
            El periodo es requerido.
        </div>
       
      </div>

      <div class="text-right">
        <button type="submit" class="btn btn-primary">
          {{ $t('invoices.save') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minValue } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [validationMixin],

  data: () => ({
    form: {
      idCliente: '',
      precio: 0,
      plan: '',
      paquete: '',
      periodicidad_mes: 0,
      periodicidad_nombre: '',
      fechaInicia: '',
      anioInicia: '',
      fechaTermina: '',
      fechaTerminaNombre: '',
      anioTermina: '',
    },
    selectPaquetes: [],
    nombreMes: [
        { id: 1, mes: 'Enero' },
        { id: 2, mes: 'Febrero' }, 
        { id: 3, mes: 'Marzo'}, 
        { id: 4, mes: 'Abril'}, 
        { id: 5, mes: 'Mayo'}, 
        { id: 6, mes: 'Junio'}, 
        { id: 7, mes: 'Julio'}, 
        { id: 8, mes: 'Agosto'}, 
        { id: 9, mes: 'Septiembre'}, 
        { id: 10, mes: 'Octubre'}, 
        { id: 11, mes: 'Noviembre' }, 
        { id: 12, mes: 'Diciembre' }
        ],
    anios: [],
  }),

  validations: {
    form: {
      precio: {
        required,
        minValueValue: minValue(1),
      },
      plan: {
        required,
      },
      cantidad: {
        required,
        minValueValue: minValue(1),
      },
      fechaInicia: {
        required,
      },
      anioInicia: {
        required,
      },
    },
  },

  computed: {
    ...mapGetters('CurrentCustomer', ['currentCustomer',]),
  },

  mounted() {
    this.form.idCliente = this.currentCustomer.id;
    let regimeCliente = this.currentCustomer.tax_regime_b ? [this.currentCustomer.tax_regime, this.currentCustomer.tax_regime_b] : [this.currentCustomer.tax_regime];

    this.loadPaquetes(regimeCliente)
  },

  methods: {
    ...mapActions('modal', ['closeModal']),
    ...mapActions('customer', ['fetchCustomers', 'fetchCreatePago']),
    ...mapActions('payment', ['fetchPaquetesRegimen']),

    async loadPaquetes(regimeCliente) {
      let response = await this.fetchPaquetesRegimen(regimeCliente)
      this.selectPaquetes = response.data

      let anioHoy = new Date().getFullYear();
      this.anios=[anioHoy-1,anioHoy,anioHoy+1];

    },

    onChange(data){
      this.form.precio = data.precio;
      this.form.plan = data.nombre;
      this.form.periodicidad_mes = data.periodicidad_mes;
      this.form.periodicidad_nombre = data.periodicidad_nombre;
    },

    onChangeMes(data, anio){
      if(data && anio && this.form.cantidad > 0 ){
        this.fechaInicia = data.nombre;

        let dateFechaInicia = new Date(anio, data.id-1, 1); 
        let dateFechaTermina = new Date(anio, data.id-1, 1); 
        dateFechaTermina.setMonth( (dateFechaTermina.getMonth() + (this.form.periodicidad_mes * (this.form.cantidad) )) -1 )

        this.form.fechaTermina = dateFechaTermina.getMonth() + 1;
        this.form.fechaTerminaNombre = this.nombreMes[dateFechaTermina.getMonth()].mes;
        this.form.anioTermina = dateFechaTermina.getFullYear(); 


        console.log(dateFechaTermina.getMonth() + 1);
        console.log(dateFechaTermina);
      }
     // this.fechaTermina = 
    },

    savePago() {
      this.$v.form.$touch()
      console.log( this.form)
      if (this.$v.form.$invalid !== false) {
        return
      }       
      console.log(this.form)
      this.fetchCreatePago(this.form)
        .then((response) => {
          this.closeModal()
          window.toastr['success'](
            'El pago fue registrado con éxito!.'
          );
          window.hub.$emit('concept_categories.category.created');
        })
        .catch((err) => {
          window.toastr['error'](
            'Hubo un error al registrar el pago!'
          )
        })
    },
  },
}
</script>
