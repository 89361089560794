<template>
  <div class="main-content">
    <div class="page-header mt-4">
      <h3 class="page-title">
        {{ $t('rate_tables.title') }}
      </h3>

      <div class="page-actions row">
        <router-link :to="{ name: 'rate-tables.generate-year-tables' }">
          <base-button color="theme" icon="plus" size="large">
            {{ $t('rate_tables.add_year') }}
          </base-button>
        </router-link>
      </div>
    </div>

    <ul class="nav nav-pills mb-3">
      <li v-for="regime of taxRegimes" :key="regime.id" class="nav-item">
        <router-link
          v-tooltip.top-center="regime.description"
          :to="{
            name: 'rate-tables.table-regime',
            params: { regime: regime.id },
          }"
          class="nav-link"
        >
          <sub-string :string="regime.description" />
        </router-link>
      </li>
      <li v-for="segment of segments" :key="segment.segment">
        <router-link
          v-tooltip.top-center="segment.title"
          :to="{
            name: 'rate-tables.table-regime',
            params: { regime: segment.segment },
          }"
          class="nav-link"
        >
          <sub-string :string="segment.title" />
        </router-link>
      </li>
    </ul>
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data: () => ({
    segments: [],
  }),
  computed: {
    ...mapGetters('catalogs', ['taxRegimes']),
  },
  mounted() {
    this.loadSegments()
  },
  methods: {
    ...mapActions('rateTables', ['fetchSegments']),
    loadSegments() {
      this.fetchSegments()
        .then((response) => {
          this.segments = response.data.segments
        })
        .catch(err => {})
    },
  },
}
</script>
