var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "card-body" }, [
      _c("form", { staticClass: "pt-4 pb-4 pl-4 pr-4" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("label", { attrs: { for: "input2" } }, [_vm._v("Bimestre")]),
              _vm._v(" "),
              _c("base-select", {
                staticStyle: { "font-size": "12px" },
                attrs: {
                  options: _vm.bimestreOptions,
                  searchable: true,
                  "allow-empty": false,
                  "show-labels": false,
                  label: "label",
                  "track-by": "id",
                },
                model: {
                  value: _vm.form.bimestre,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "bimestre", $$v)
                  },
                  expression: "form.bimestre",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("label", { attrs: { for: "input3" } }, [_vm._v("Ejercicio")]),
              _vm._v(" "),
              _c("base-select", {
                staticStyle: { "font-size": "12px" },
                attrs: {
                  options: _vm.ejercicioOptions,
                  searchable: true,
                  "allow-empty": false,
                  "show-labels": false,
                  label: "label",
                  "track-by": "id",
                },
                model: {
                  value: _vm.form.ejercicio,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "ejercicio", $$v)
                  },
                  expression: "form.ejercicio",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("label", { attrs: { for: "input4" } }, [
                _vm._v("Número de Operación"),
              ]),
              _vm._v(" "),
              _c("base-input", {
                attrs: { type: "number" },
                model: {
                  value: _vm.form.numeroOperacion,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "numeroOperacion", $$v)
                  },
                  expression: "form.numeroOperacion",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mt-3" }, [
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("label", { attrs: { for: "input5" } }, [
                _vm._v("Saldo a Aplicar"),
              ]),
              _vm._v(" "),
              _c("base-input", {
                attrs: { type: "number" },
                model: {
                  value: _vm.form.saldoAplicar,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "saldoAplicar", $$v)
                  },
                  expression: "form.saldoAplicar",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("label", { attrs: { for: "input7" } }, [
                _vm._v("Monto Original del Saldo"),
              ]),
              _vm._v(" "),
              _c("base-input", {
                attrs: { type: "number" },
                model: {
                  value: _vm.form.montoOriginal,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "montoOriginal", $$v)
                  },
                  expression: "form.montoOriginal",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col form-group" },
            [
              _c("label", [_vm._v("Fecha en que se presento")]),
              _vm._v(" "),
              _c("base-date-picker", {
                attrs: {
                  "calendar-button": true,
                  "calendar-button-icon": "calendar",
                },
                model: {
                  value: _vm.form.fecha,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "fecha", $$v)
                  },
                  expression: "form.fecha",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("label", { attrs: { for: "input11" } }, [
                _vm._v("Remanente por aplicar "),
              ]),
              _vm._v(" "),
              _c("base-input", {
                attrs: { type: "number" },
                model: {
                  value: _vm.form.remanente,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remanente", $$v)
                  },
                  expression: "form.remanente",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card-footer" }, [
      _c(
        "div",
        { staticClass: "mt-2 flex-content-right" },
        [
          _c(
            "base-button",
            { attrs: { color: "theme" }, on: { click: _vm.saveExceso } },
            [_vm._v("\n        Aplicar Saldo\n      ")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }