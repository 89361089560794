var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card-body permissions" },
    [
      _c("p", { staticClass: "form-title" }, [
        _vm._v(_vm._s(_vm.$t("wizard.permissions.permissions"))),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "form-desc" }, [
        _vm._v(_vm._s(_vm.$t("wizard.permissions.permission_desc"))),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex justify-content-start" }, [
        _c(
          "div",
          { staticClass: "lists col-md-6" },
          _vm._l(_vm.permissions, function (permission, index) {
            return _c("div", { key: index, staticClass: "row list-items" }, [
              _c("div", { staticClass: "col-sm-9 left-item" }, [
                _vm._v(
                  "\n          " + _vm._s(permission.folder) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-3 right-item" }, [
                permission.isSet
                  ? _c("span", { staticClass: "verified" })
                  : _c("span", { staticClass: "not-verified" }),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(permission.permission))]),
              ]),
            ])
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _vm.isContinue
        ? _c(
            "base-button",
            {
              staticClass: "pull-right mt-5",
              attrs: { icon: "arrow-right", "right-icon": "", color: "theme" },
              on: { click: _vm.next },
            },
            [_vm._v("\n    " + _vm._s(_vm.$t("wizard.continue")) + "\n  ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }