<template>
  <div>
    <div class="card-body">
      <form class="pt-4 pb-4 pl-4 pr-4">
        <div class="row">
          <div class="col">
            <label for="input1">Tipo</label>
            <base-select v-model="form.tipo" :options="tipoOptions" :searchable="true" :allow-empty="false"
              :show-labels="false" label="label" track-by="id" style="font-size: 12px" />
          </div>
          <div class="col">
            <label for="input2">Periodicidad</label>
            <base-select v-model="form.periodicidad" :options="periodicidadOptions" :searchable="true"
              :allow-empty="false" :show-labels="false" label="label" track-by="id" style="font-size: 12px" />
          </div>
          <div class="col">
            <label for="input3">Periodo</label>
            <base-input v-model="form.periodo" />
          </div>
          <div class="col">
            <label for="input4">Ejercicio</label>
            <base-select v-model="form.ejercicio" :options="ejercicioOptions" :searchable="true" :allow-empty="false"
              :show-labels="false" label="label" track-by="id" style="font-size: 12px" />
          </div>
        </div>

        <!-- segunda fila -->
        <div class="row">
          <div class="col">
            <label for="input1">Saldo a Aplicar</label>
            <base-input type="number" v-model="form.saldoAplicar" />

          </div>
          <div class="col">
            <label for="input2">Tipo de Declaración ˅</label>
            <base-select v-model="form.tipoDeclaracion" :options="declaracionOptions" :searchable="true"
              :allow-empty="false" :show-labels="false" label="label" track-by="id" style="font-size: 12px" />
          </div>
          <div class="col">
            <label for="input3">Número de Operación</label>
            <base-input type="number" v-model="form.numeroOperacion" />
          </div>
        </div>

        <!-- Tercera fila con 2 inputs -->
        <div class="row mt-3">
          <div class="col">
            <label for="input5">Monto Original del Saldo a Favor</label>
            <base-input type="number" v-model="form.montoOriginal" />
          </div>
          <div class="col">
            <label for="input7">Remanente Historico Antes de la Aplicación</label>
            <base-input type="number" v-model="form.remanenteHistorico" />
          </div>
        </div>

        <!-- cuarta con 2 inputs -->
        <div class="row mt-3">
          <div class="col">
            <label for="input5">Fecha en que se presento el Saldo a Favor</label>
            <base-date-picker v-model="form.fecha" :calendar-button="true" calendar-button-icon="calendar"
              :typeable='true' />
          </div>
          <div class="col">
            <label for="input7">Remanente Actualizado Antes de la Aplicación</label>
            <base-input type="number" v-model="form.remanenteActualizado" />
          </div>
        </div>
      </form>
    </div>
    <div class="card-footer">
      <div class="mt-2 flex-content-right">
        <base-button color="theme" @click="saveCompensacion">
          Aplicar Saldo
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [validationMixin],

  data: () => ({
    form: {
      tipo: null,
      periodicidad: null,
      periodo: '',
      ejercicio: null,
      saldoAplicar: 0,
      tipoDeclaracion: null,
      numeroOperacion: 0,
      montoOriginal: 0,
      remanenteHistorico: 0,
      fecha: null,
      remanenteActualizado: 0,
    },

    tipoOption: [
      { id: 0, name: 'Saldo a  Favor' },
      { id: 1, name: 'Pago de lo Indebido' },
    ],

    declaracionOption: [
      { id: 0, name: 'Normal' },
      { id: 1, name: 'Complementaria' },
      { id: 2, name: 'Normal por Correción Fiscal' },
      { id: 3, name: 'Complementaria por Correción Fiscal' },
      { id: 4, name: 'Complementaria por Dictamen' },
      { id: 5, name: 'Complementaria Esquema Anterior' },
    ],

    ejercicioOption: [],

    periodicidadOption: [
      { id: 0, name: 'Mensual' },
      { id: 1, name: 'Bimestral' },
      { id: 2, name: 'Trimestral' },
      { id: 3, name: 'Semestral' },
      { id: 4, name: 'Ajuste' },
      { id: 5, name: 'Del Ejercicio' },
      { id: 6, name: 'Sin Ejercicio' },
    ],
  }),
  created() {
    // Método para generar las opciones de ejercicio automáticamente
    this.generateEjercicioOptions();
  },
  computed: {
    ...mapGetters('currency', ['defaultCurrency']),
    ...mapGetters('invoice', ['dataModalAddPaidDocument']),
    ...mapGetters('catalogs', [
      'paidDocumentOptions',
      'searchPaidDocumentOption',
    ]),
    ...mapGetters('taxReturns', ['compensacion']),

    declaracionOptions() {
      return this.declaracionOption.map((item) => {
        return {
          ...item,
          id: item.id,
          label: `${item.name}`,
        }
      })
    },
    ejercicioOptions() {
      return this.ejercicioOption.map((item) => {
        return {
          ...item,
          id: item.id,
          label: `${item.name}`,
        }
      })
    },
    tipoOptions() {
      return this.tipoOption.map((item) => {
        return {
          ...item,
          id: item.id,
          label: `${item.name}`,
        }
      })
    },
    periodicidadOptions() {
      return this.periodicidadOption.map((item) => {
        return {
          ...item,
          id: item.id,
          label: `${item.name}`,
        }
      })
    },

    customerCurrency() {
      if (this.defaultCurrency) {
        return {
          decimal: this.defaultCurrency.decimal_separator,
          thousands: this.defaultCurrency.thousand_separator,
          prefix: this.defaultCurrency.symbol + ' ',
          precision: this.defaultCurrency.precision,
          masked: false,
        }
      } else {
        return this.defaultCurrenctForInput
      }
    },
  },

  mounted() {
    this.form = JSON.parse(JSON.stringify(this.compensacion));
  },

  methods: {
    ...mapActions('taxReturns', ['updatedModalCompensacion']),
    ...mapActions('modal', ['closeModal']),

    saveCompensacion() {
      this.updatedModalCompensacion(this.form)
      this.closeCategoryModal()
    },

    closeCategoryModal() {
      this.closeModal()
    },

    generateEjercicioOptions() {
      const currentYear = new Date().getFullYear();
      for (let i = 0; i < 5; i++) {
        this.ejercicioOption.push({
          id: i,
          name: (currentYear - i).toString(),
        });
      }
    },
  },
}
</script>
