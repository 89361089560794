import * as types from './mutation-types'

export const fetchTaxLosses = ({ commit }, params) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/tax-losses`, { params })
      .then((response) => {
        commit(types.SET_TAX_LOSSES, response.data.tax_losses)
        resolve(response)
      })
      .catch(reject)
  })
}

export const fetchTaxLoss = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/tax-losses/${id}`)
      .then((response) => {
        commit(types.SET_TAX_LOSS, response.data.tax_loss)
        resolve(response)
      })
      .catch(reject)
  })
}

export const cleanTaxLoss = ({ commit }) => {
  commit(types.SET_TAX_LOSS, null)
}

export const saveTaxLoss = (state, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post(`/api/tax-losses`, data).then(resolve).catch(reject)
  })
}

export const deleteTaxLoss = ({ commit }, taxLossId) => {
  return new Promise((resolve, reject) => {
    window.axios
      .delete(`/api/tax-losses/${taxLossId}`)
      .then((response) => {
        if (response.data.success) {
          commit(types.REMOVE_TAX_LOSS, taxLossId)
        }
        resolve(response)
      })
      .catch(reject)
  })
}
