<template>
  <div>
    <CustomerHeaderBasic />

    <div class="contracted main-content">
      <div class="page-header mt-4" style="z-index: 1">
        <h3 class="page-title">Contrato de uso de la Plataforma</h3>
      </div>

      <div>
        <div class="card">
          <div class="card-body">
            <h4>1. Objeto del contrato</h4>

            <p>
              Taxai proporciona una plataforma en línea que permite a los
              Usuarios generar declaraciones de impuestos y, si es necesario,
              utilizar servicios de folios para facturación (en adelante, los
              "Servicios").
            </p>

            <h4>2. Términos y condiciones de uso</h4>

            <p>
              El Usuario se compromete a utilizar los Servicios de Taxai de
              acuerdo con los términos y condiciones establecidos en este
              Contrato y cualquier otro acuerdo o política relacionada. El
              Usuario es el único responsable de cualquier contenido o
              información proporcionada a través de la plataforma de Taxai.
            </p>

            <h4>3. Obligaciones de Taxai</h4>

            <p>
              Taxai se compromete a proporcionar acceso a la plataforma en
              línea, mantener la confidencialidad de la información del Usuario
              según lo establecido en la política de privacidad, y brindar
              asistencia técnica en caso de problemas relacionados con la
              plataforma.
            </p>

            <h4>4. Obligaciones del Usuario</h4>

            <ul>
              <li>
                Proveer información precisa y actualizada al utilizar la
                plataforma de Taxai.
              </li>
              <li>
                Cumplir con todas las leyes y regulaciones aplicables al
                utilizar los Servicios de Taxai.
              </li>
              <li>
                No utilizar los Servicios para actividades ilegales o
                fraudulentas.
              </li>
              <li>
                Respetar la propiedad intelectual de Taxai y terceros
                relacionados con la plataforma.
              </li>
            </ul>

            <h4>5. Tarifas y pago</h4>

            <p>
              El uso de la plataforma de Taxai puede estar sujeto a tarifas.
              Taxai se reserva el derecho de modificar las tarifas en cualquier
              momento y notificará al Usuario con anticipación. El Usuario se
              compromete a pagar todas las tarifas aplicables según las
              políticas de facturación de Taxai.
            </p>

            <h4>6. Limitación de responsabilidad</h4>

            <p>
              Taxai no será responsable de ningún daño directo o indirecto
              causado por el uso de la plataforma, incluyendo, pero no limitado
              a, la pérdida de datos o la interrupción de los Servicios.
            </p>

            <h4>7. Terminación</h4>

            <p>
              Este Contrato podrá ser terminado por cualquiera de las Partes en
              cualquier momento, previa notificación por escrito. Taxai se
              reserva el derecho de suspender o terminar la cuenta del Usuario
              en caso de incumplimiento de este Contrato.
            </p>

            <h4>8. Ley aplicable</h4>

            <p>
              Este Contrato se rige por las leyes del [jurisdicción] y cualquier
              disputa derivada de este Contrato se someterá a la jurisdicción de
              los tribunales de [jurisdicción].
            </p>
          </div>
        </div>
        <div>
          <div class="row">
            <div class="col-6">
              <base-button
                :loading="isLoading"
                style="background-color: #f13100"
                color="F13100"
              >
                Rechazar
                <font-awesome-icon
                  :icon="['fas', 'ban']"
                  class="icon menu-icon ml-2"
                />
              </base-button>
            </div>
            <div class="col-6">
              <base-button
                :loading="isLoading"
                @click="payment()"
                color="#1B144A"
              >
                Aceptar
                <font-awesome-icon
                  :icon="['fas', 'check']"
                  class="icon menu-icon ml-2"
                />
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomerHeaderBasic from '../layouts/partials/CustomerHeaderBasic.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: { CustomerHeaderBasic },

  data: () => ({
    currentYear: new Date().getFullYear(),
    years: [], // Inicializa el array de años vacío
    meses: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ],
    selectIndex: 0,
    customer: null,

    declaracionesSeleccioandas: [],
  }),

  mounted() {},
  created() {
    // Crea el array de años en el método created
    this.years = Array.from({ length: 4 }, (_, index) =>
      (this.currentYear - index).toString()
    )
  },
  watch: {},
  methods: {
    ...mapActions('calendar', ['setYear', 'setMonth']),
    ...mapGetters('calendar', ['months', 'defaultMonth', 'defaultYear']),
    setCustomerToViewDetails(year, month) {},
    cardSelect(index) {
      if (this.selectIndex == index) {
        this.selectIndex = -1
      } else {
        this.selectIndex = index
      }
    },
    payment() {
      this.$router.push('/wizard/payment')
    },
    setAllDeclaracionesYear(year) {
      // Obtener la lista de meses
      const months = this.meses

      // Verificar si ya existen todas las declaraciones para el año dado
      const declarationsForYear = this.declaracionesSeleccioandas.filter(
        (option) => option.year === year
      )
      const allMonthsExist = months.every((month) =>
        declarationsForYear.some((option) => option.month === month)
      )

      if (allMonthsExist) {
        // Si ya existen todas las declaraciones, elimínalas
        this.declaracionesSeleccioandas =
          this.declaracionesSeleccioandas.filter(
            (option) => option.year !== year
          )
      } else {
        // Si no existen todas las declaraciones, agrégalas todas
        months.forEach((month) => {
          if (!declarationsForYear.some((option) => option.month === month)) {
            this.declaracionesSeleccioandas.push({
              year: year,
              month: month,
            })
          }
        })
      }
      console.log(this.declaracionesSeleccioandas)
    },
    setMesDeclaracion(optionToCheck) {
      // Verificar si optionToCheck ya está en declaracionesSeleccioandas
      const index = this.declaracionesSeleccioandas.findIndex((option) => {
        return (
          option.year === optionToCheck.year &&
          option.month === optionToCheck.month
        )
      })

      if (index !== -1) {
        // Si se encuentra en el array, elimínalo
        this.declaracionesSeleccioandas.splice(index, 1)
      } else {
        // Si no se encuentra en el array, agrégalo
        this.declaracionesSeleccioandas.push(optionToCheck)
      }

      console.log(this.declaracionesSeleccioandas)
    },
    isSelected(optionToCheck) {
      // Verificar si optionToCheck está en declaracionesSeleccioandas
      const isSelected = this.declaracionesSeleccioandas.some((option) => {
        return (
          option.year === optionToCheck.year &&
          option.month === optionToCheck.month
        )
      })

      return isSelected
    },

    isSelectedYear(year) {
      // Obtener la lista de meses
      const months = this.meses

      // Verificar si ya existen todas las declaraciones para el año dado
      const declarationsForYear = this.declaracionesSeleccioandas.filter(
        (option) => option.year === year
      )
      const allMonthsExist = months.every((month) =>
        declarationsForYear.some((option) => option.month === month)
      )
      return allMonthsExist
    },
  },
}
</script>
