import * as types from './mutation-types'
import ls from '../../../../services/ls'

export const loadData = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    if (state.user == null) {
      window.axios
        .get(`/api/settings/profile`)
        .then((response) => {
          commit(types.SET_USER, response.data)
          ls.setObject(types.SET_USER, response.data)
          console.log(response.data)
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    } else {
      resolve({})
    }
  })
}

export const editUser = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .put('/api/settings/profile', data)
      .then((response) => {
        commit(types.UPDATE_USER, response.data)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const uploadOnboardAvatar = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/admin/profile/upload-avatar`, data)
      .then((response) => {
        commit(types.UPDATE_USER, response.data.user)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const uploadAvatar = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post('/api/settings/profile/upload-avatar', data)
      .then((response) => {
        commit(types.UPDATE_USER, response.data.user)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
export const validateRole =  (
  { commit, dispatch, state },
  { admin, ejecutivo, supervisor }
) =>
  // data
  {
  return new Promise((resolve, reject) => {
    switch (state.user.role) {
      case 'ejecutivo':
        console.log('soy ejecutivo')
        resolve(ejecutivo)
      case 'supervisor':
        resolve(supervisor)
      case 'admin':
        console.log('soy admin', admin)

        resolve(admin)
      default:
        resolve(false)

    }
  
  });

    switch (state.user.role) {
      case 'ejecutivo':
        console.log('ejecutivo ', state.user)
        console.log('entre a ejecutivo ', ejecutivo)

        return ejecutivo
      case 'supervisor':
        return supervisor
      case 'admin':
        return admin
      default:
        return false
    }
  }
