var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "item-modal" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitItemData.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "label",
              { staticClass: "col-sm-4 col-form-label input-label" },
              [
                _vm._v("\n          " + _vm._s(_vm.$t("items.name"))),
                _c("span", { staticClass: "required" }, [_vm._v("*")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-7" },
              [
                _c("base-input", {
                  ref: "name",
                  attrs: { invalid: _vm.$v.formData.name.$error, type: "text" },
                  on: {
                    input: function ($event) {
                      return _vm.$v.formData.name.$touch()
                    },
                  },
                  model: {
                    value: _vm.formData.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "name", $$v)
                    },
                    expression: "formData.name",
                  },
                }),
                _vm._v(" "),
                _vm.$v.formData.name.$error
                  ? _c("div", [
                      !_vm.$v.formData.name.required
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(_vm._s(_vm.$tc("validation.required"))),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.$v.formData.name.minLength
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$tc(
                                    "validation.name_min_length",
                                    _vm.$v.formData.name.$params.minLength.min,
                                    {
                                      count:
                                        _vm.$v.formData.name.$params.minLength
                                          .min,
                                    }
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "label",
              { staticClass: "col-sm-4 col-form-label input-label" },
              [
                _vm._v(_vm._s(_vm.$t("items.price"))),
                _c("span", { staticClass: "required" }, [_vm._v("*")]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-7" }, [
              _c(
                "div",
                { staticClass: "base-input" },
                [
                  _c(
                    "money",
                    _vm._b(
                      {
                        staticClass: "input-field",
                        class: { invalid: _vm.$v.formData.price.$error },
                        model: {
                          value: _vm.price,
                          callback: function ($$v) {
                            _vm.price = $$v
                          },
                          expression: "price",
                        },
                      },
                      "money",
                      _vm.defaultCurrencyForInput,
                      false
                    )
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.$v.formData.price.$error
                ? _c("div", [
                    !_vm.$v.formData.price.required
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.$tc("validation.required"))),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.$v.formData.price.numeric
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.$tc("validation.numbers_only"))),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.$v.formData.price.maxLength
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.$t("validation.price_maxlength"))),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.$v.formData.price.minValue
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.$t("validation.price_minvalue"))),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "label",
              { staticClass: "col-sm-4 col-form-label input-label" },
              [_vm._v(_vm._s(_vm.$t("items.unit")))]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-7" },
              [
                _c(
                  "base-select",
                  {
                    attrs: {
                      options: _vm.itemUnits,
                      searchable: true,
                      "show-labels": false,
                      label: "name",
                    },
                    model: {
                      value: _vm.formData.unit,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "unit", $$v)
                      },
                      expression: "formData.unit",
                    },
                  },
                  [
                    _c(
                      "div",
                      { attrs: { slot: "afterList" }, slot: "afterList" },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "list-add-button",
                            attrs: { type: "button" },
                            on: { click: _vm.addItemUnit },
                          },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "icon",
                              attrs: { icon: "cart-plus" },
                            }),
                            _vm._v(" "),
                            _c("label", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "settings.customization.items.add_item_unit"
                                  )
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.isTexPerItem
            ? _c("div", { staticClass: "form-group row" }, [
                _c(
                  "label",
                  { staticClass: "col-sm-4 col-form-label input-label" },
                  [_vm._v(_vm._s(_vm.$t("items.taxes")))]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-7" },
                  [
                    _c("base-select", {
                      attrs: {
                        options: _vm.getTaxTypes,
                        searchable: true,
                        "show-labels": false,
                        "allow-empty": true,
                        multiple: true,
                        label: "tax_name",
                      },
                      model: {
                        value: _vm.formData.taxes,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "taxes", $$v)
                        },
                        expression: "formData.taxes",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "label",
              { staticClass: "col-sm-4 col-form-label input-label" },
              [_vm._v(_vm._s(_vm.$t("items.description")))]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-7" },
              [
                _c("base-text-area", {
                  attrs: { rows: "4", cols: "50" },
                  on: {
                    input: function ($event) {
                      return _vm.$v.formData.description.$touch()
                    },
                  },
                  model: {
                    value: _vm.formData.description,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "description", $$v)
                    },
                    expression: "formData.description",
                  },
                }),
                _vm._v(" "),
                _vm.$v.formData.description.$error
                  ? _c("div", [
                      !_vm.$v.formData.description.maxLength
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(
                              _vm._s(_vm.$t("validation.description_maxlength"))
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-footer" },
          [
            _c(
              "base-button",
              {
                staticClass: "mr-3",
                attrs: { outline: true, color: "theme", type: "button" },
                on: { click: _vm.closeItemModal },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("general.cancel")) + "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _vm.isEdit
              ? _c(
                  "base-button",
                  {
                    attrs: { loading: _vm.isLoading, color: "theme" },
                    on: { click: _vm.submitItemData },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("general.update")) +
                        "\n      "
                    ),
                  ]
                )
              : _c(
                  "base-button",
                  {
                    attrs: {
                      loading: _vm.isLoading,
                      icon: "save",
                      color: "theme",
                      type: "submit",
                    },
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("general.save")) + "\n      "
                    ),
                  ]
                ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }