<template>
  <div class="main-content">
    <div class="page-header">
      <h3 class="page-title">
        {{ $t('rate_tables.add_year') }}
      </h3>
    </div>

    <div class="row">
      <div class="col-12 col-sm-8 col-lg-6 offset-md-2 offset-lg-3">
        <div class="card p-2">
          <form @submit.prevent="sendYearToGenerateTables">
            <div class="form-group">
              <label>{{ $t('general.year') }}</label>
              <input v-model="form.year" type="number" class="form-control" />
            </div>

            <div class="form-group text-center">
              <button class="btn btn-primary" type="submit">
                {{ $t('rate_tables.create_tables') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data: () => ({
    form: {
      year: null,
    },
  }),
  computed: {
    ...mapGetters('calendar', ['defaultYear']),
  },
  created() {
    this.form.year = this.defaultYear
  },
  methods: {
    ...mapActions('rateTables', ['createRateTables']),
    sendYearToGenerateTables() {
      this.createRateTables(this.form)
        .then((response) => {
          window.toastr.success(this.$t('rate_tables.messages.tables_created'))
          this.$router.push({
            name: 'rate-tables.table-regime',
            params: { regime: 621 },
          })
        })
        .catch((err) => {
          window.toastr.error(this.$t('rate_tables.errors.create_tables'))
        })
    },
  },
}
</script>
