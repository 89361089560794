var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "setting-main-container update-container" }, [
    _c("div", { staticClass: "card setting-card" }, [
      _c(
        "div",
        { staticClass: "page-header" },
        [
          _c("h3", { staticClass: "page-title" }, [
            _vm._v(_vm._s(_vm.$t("settings.update_app.title"))),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "page-sub-title" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("settings.update_app.description")) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("label", { staticClass: "input-label" }, [
            _vm._v(_vm._s(_vm.$t("settings.update_app.current_version"))),
          ]),
          _c("br"),
          _vm._v(" "),
          _c("label", { staticClass: "version mb-4" }, [
            _vm._v(_vm._s(_vm.currentVersion)),
          ]),
          _vm._v(" "),
          _c(
            "base-button",
            {
              staticClass: "mb-4",
              attrs: {
                outline: true,
                disabled: _vm.isCheckingforUpdate || _vm.isUpdating,
                size: "large",
                color: "theme",
              },
              on: { click: _vm.checkUpdate },
            },
            [
              _c("font-awesome-icon", {
                class: { update: _vm.isCheckingforUpdate },
                staticStyle: { "margin-right": "10px" },
                attrs: { icon: "sync-alt" },
              }),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("settings.update_app.check_update")) +
                  "\n      "
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _vm.isUpdateAvailable
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isUpdating,
                      expression: "!isUpdating",
                    },
                  ],
                  staticClass: "mt-4 content",
                },
                [
                  _c("h3", { staticClass: "page-title mb-3" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("settings.update_app.avail_update")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "input-label" }, [
                    _vm._v(_vm._s(_vm.$t("settings.update_app.next_version"))),
                  ]),
                  _c("br"),
                  _vm._v(" "),
                  _c("label", { staticClass: "version" }, [
                    _vm._v(_vm._s(_vm.updateData.version)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "page-sub-title",
                      staticStyle: { "white-space": "pre-wrap" },
                    },
                    [_vm._v(_vm._s(_vm.description))]
                  ),
                  _vm._v(" "),
                  _c(
                    "base-button",
                    {
                      attrs: { size: "large", icon: "rocket", color: "theme" },
                      on: { click: _vm.onUpdateApp },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("settings.update_app.update")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isUpdating
            ? _c("div", { staticClass: "mt-4 content" }, [
                _c(
                  "div",
                  { staticClass: "d-flex flex-row justify-content-between" },
                  [
                    _c("div", [
                      _c("h3", { staticClass: "page-title" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t("settings.update_app.update_progress")
                            ) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "page-sub-title" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t("settings.update_app.progress_text")
                            ) +
                            "\n            "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("font-awesome-icon", {
                      staticClass: "update-spinner fa-spin",
                      attrs: { icon: "spinner" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "update-steps-container" },
                  _vm._l(_vm.updateSteps, function (step) {
                    return _c("li", { staticClass: "update-step" }, [
                      _c("p", { staticClass: "update-step-text" }, [
                        _vm._v(_vm._s(_vm.$t(step.translationKey))),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "update-status-container" }, [
                        step.time
                          ? _c("span", { staticClass: "update-time" }, [
                              _vm._v(_vm._s(step.time)),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            class:
                              "update-status status-" + _vm.getStatus(step),
                          },
                          [_vm._v(_vm._s(_vm.getStatus(step)))]
                        ),
                      ]),
                    ])
                  }),
                  0
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }