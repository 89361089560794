export const perceptionTypes = [
  {
    id: '001',
    description: 'Sueldos, Salarios  Rayas y Jornales',
  },
  {
    id: '002',
    description: 'Gratificación Anual (Aguinaldo)',
  },
  {
    id: '003',
    description: 'Participación de los Trabajadores en las Utilidades PTU',
  },
  {
    id: '004',
    description: 'Reembolso de Gastos Médicos Dentales y Hospitalarios',
  },
  {
    id: '005',
    description: 'Fondo de Ahorro',
  },
  {
    id: '006',
    description: 'Caja de ahorro',
  },
  {
    id: '009',
    description: 'Contribuciones a Cargo del Trabajador Pagadas por el Patrón',
  },
  {
    id: '010',
    description: 'Premios por puntualidad',
  },
  {
    id: '011',
    description: 'Prima de Seguro de vida',
  },
  {
    id: '012',
    description: 'Seguro de Gastos Médicos Mayores',
  },
  {
    id: '013',
    description: 'Cuotas Sindicales Pagadas por el Patrón',
  },
  {
    id: '014',
    description: 'Subsidios por incapacidad',
  },
  {
    id: '015',
    description: 'Becas para trabajadores y/o hijos',
  },
  {
    id: '019',
    description: 'Horas extra',
  },
  {
    id: '020',
    description: 'Prima dominical',
  },
  {
    id: '021',
    description: 'Prima vacacional',
  },
  {
    id: '022',
    description: 'Prima por antigüedad',
  },
  {
    id: '023',
    description: 'Pagos por separación',
  },
  {
    id: '024',
    description: 'Seguro de retiro',
  },
  {
    id: '025',
    description: 'Indemnizaciones',
  },
  {
    id: '026',
    description: 'Reembolso por funeral',
  },
  {
    id: '027',
    description: 'Cuotas de seguridad social pagadas por el patrón',
  },
  {
    id: '028',
    description: 'Comisiones',
  },
  {
    id: '029',
    description: 'Vales de despensa',
  },
  {
    id: '030',
    description: 'Vales de restaurante',
  },
  {
    id: '031',
    description: 'Vales de gasolina',
  },
  {
    id: '032',
    description: 'Vales de ropa',
  },
  {
    id: '033',
    description: 'Ayuda para renta',
  },
  {
    id: '034',
    description: 'Ayuda para artículos escolares',
  },
  {
    id: '035',
    description: 'Ayuda para anteojos',
  },
  {
    id: '036',
    description: 'Ayuda para transporte',
  },
  {
    id: '037',
    description: 'Ayuda para gastos de funeral',
  },
  {
    id: '038',
    description: 'Otros ingresos por salarios',
  },
  {
    id: '039',
    description: 'Jubilaciones, pensiones o haberes de retiro',
  },
  {
    id: '044',
    description: 'Jubilaciones, pensiones o haberes de retiro en parcialidades',
  },
  {
    id: '045',
    description: 'Ingresos en acciones o títulos valor que representan bienes',
  },
  {
    id: '046',
    description: 'Ingresos asimilados a salarios',
  },
  {
    id: '047',
    description:
      'Alimentación diferentes a los establecidos en el Art 94 último párrafo LISR',
  },
  {
    id: '048',
    description: 'Habitación',
  },
  {
    id: '049',
    description: 'Premios por asistencia',
  },
  {
    id: '050',
    description: 'Viáticos',
  },
  {
    id: '051',
    description:
      'Pagos por gratificaciones, primas, compensaciones, recompensas u otros a extrabajadores derivados de jubilación en parcialidades',
  },
  {
    id: '052',
    description:
      'Pagos que se realicen a extrabajadores que obtengan una jubilación en parcialidades derivados de la ejecución de resoluciones judicial o de un laudo',
  },
  {
    id: '053',
    description:
      'Pagos que se realicen a extrabajadores que obtengan una jubilación en una sola exhibición derivados de la ejecución de resoluciones judicial o de un laudo',
  },
]
