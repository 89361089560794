<template>
  <div v-if="retentions" class="table-responsive">
    <h6 class="text-center">{{ $t(title) }}</h6>

    <table class="table table-bordered">
      <thead>
        <tr>
          <th>{{ $t('invoices.uuid') }}</th>
          <th>{{ $t('invoices.rfc') }}</th>
          <th>{{ $t('invoices.name') }}</th>
          <th>{{ $t('retenciones.date') }}</th>
          <th>{{ $t('invoices.iva') }}</th>
          <th>{{ $t('expenses.retention_iva') }}</th>
          <th>{{ $t('expenses.retention_isr') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="retention in retentions" :key="retention.id">
          <td>{{ retention.uuid }}</td>
          <td>{{ retention.rfc }}</td>
          <td><sub-string :string="retention.name" /></td>
          <td>{{ retention.date }}</td>
          <td>
            <money-text :amount="retention.iva_transferred" />
          </td>
          <td>
            <money-text :amount="retention.retention_iva" />
          </td>
          <td>
            <money-text :amount="retention.retention_isr" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    retentions: {
      type: Array,
      default: null,
    },
    totals: {
      type: Object,
      default: null,
    },
  },
  computed: {},
}
</script>

<style lang="sass" scoped>
.table {
  border: 1px solid #d7d8da;
  th, td {
    font-size: 12px;
  }
}
</style>