<template>
  <div v-if="customer" class="main-content">
    <div class="page-content pt-5">
      <h3 class="page-title">
        {{ $t('customers.sat_settings.page_title') }}
      </h3>
    </div>

    <div class="row">
      <div class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
        <div class="card p-3">
          <div class="form-group text-center">
            <div>{{ customer.name }}</div>
            <div>{{ customer.rfc }}</div>
            <div>
              <tax-regime-catalog :tax-regime="customer.tax_regime" />
            </div>
          </div>

          <ul class="nav nav-pills nav-fill" id="pills-tab" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active"
                id="pills-general-tab"
                data-toggle="pill"
                href="#navGeneral"
                role="tab"
                aria-controls="pills-general"
                aria-selected="true"
                >{{ $t('customers.sat_settings.general') }}</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="pills-medida-tab"
                data-toggle="pill"
                href="#pills-medida"
                role="tab"
                aria-controls="pills-medida"
                aria-selected="false"
                >Unidad Medida</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="pills-productos-tab"
                data-toggle="pill"
                href="#pills-productos"
                role="tab"
                aria-controls="pills-productos"
                aria-selected="false"
                >Productos/Servicios</a
              >
            </li>
          </ul>
          <br />
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="navGeneral"
              role="tabpanel"
              aria-labelledby="pills-general-tab"
            >
              <form @submit.prevent="submit">
                <div class="form-group">
                  <label>
                    {{ $t('customers.sat_settings.sat_registration_date') }}
                  </label>
                  <base-date-picker
                    v-model="form.satRegistrationDate"
                    :calendar-button="true"
                    calendar-button-icon="calendar"
                  />
                </div>

                <div class="form-group">
                  <label>
                    {{
                      $t('customers.sat_settings.prodserv_for_general_invoice')
                    }}
                  </label>
                  <input
                    v-model="form.prodservInvoicing"
                    type="text"
                    class="form-control"
                  />
                </div>

                <div class="form-group">
                  <label>
                    {{ $t('customers.sat_settings.concept') }}
                  </label>
                  <input
                    v-model="form.concept"
                    type="text"
                    class="form-control"
                  />
                </div>

                <div class="form-group">
                  <label>
                    {{ $t('customers.sat_settings.expedition_place') }}
                  </label>
                  <input v-model="form.cp" type="text" class="form-control" />
                </div>

                <div class="form-group">
                  <label>
                    {{ $t('customers.sat_settings.use_cfdi') }}
                  </label>
                  <base-select
                    v-model="form.useCFDI"
                    :options="useCFDIOptions"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="false"
                    :placeholder="$t('customers.sat_settings.use_cfdi')"
                    label="description"
                    track-by="id"
                  />
                </div>

                <!-- Config Arrendamiento -->
                <div v-if="customer.tax_regime == 606">
                  <h6 class="text-center mb-0">
                    {{ $t('customers.sat_settings.606_settings') }}
                  </h6>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-8 flex-vertical-end">
                        {{
                          $t('customers.sat_settings.tax_return_at_35_percent')
                        }}
                      </div>
                      <div class="col-4">
                        <base-switch v-model="form.taxReturnBlind606" />
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Config  Plataformas digitales -->
                <div v-if="customer.tax_regime == 625">
                  <h6 class="text-center mb-0">
                    {{ $t('customers.sat_settings.625_settings') }}
                  </h6>

                  <div class="form-group">
                    <label>
                      {{
                        $t('customers.sat_settings.625_digital_platform_type')
                      }}
                    </label>
                    <base-select
                      v-model="form.typeDigitalPlatform625"
                      :options="digitalPlatformOptions"
                      :searchable="true"
                      :allow-empty="false"
                      :show-labels="false"
                      :placeholder="$t('customers.select_currency')"
                      label="description"
                      track-by="key"
                    />
                  </div>
                </div>

                <hr />
                <div class="form-group text-center mt-4">
                  <button class="btn btn-primary" type="submit">
                    {{ $t('general.save') }}
                  </button>
                </div>
              </form>
            </div>
            <div
              class="tab-pane fade"
              id="pills-medida"
              role="tabpanel"
              aria-labelledby="pills-medida-tab"
            >
              <table class="table table-striped table-responsive">
                <thead>
                  <tr>
                    <th style="font-size: 12px">Claves de Unidad de Medida</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(unit, index) in form.unitCodes"
                    :key="unit.id"
                    :index="index"
                  >
                    <td scope="row" style="font-size: 12px">
                      {{ unit.id }} - {{ unit.description }}
                    </td>
                    <td>
                      <div class="add-item-action" @click="removeItem(unit.id)">
                        <base-button
                          icon="trash"
                          color="danger"
                          size="small"
                          iconButton
                        ></base-button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <base-select
                v-model="selectedUnit"
                :options="unitCodeOptions"
                :searchable="true"
                :allow-empty="false"
                :show-labels="false"
                label="label"
                track-by="id"
                style="font-size: 12px"
              />

              <div class="add-item-action" @click="addItemRow">
                <button type="button" class="btn btn-block btn-outline-success">
                  <font-awesome-icon
                    icon="shopping-basket"
                    class="mr-2"
                  />Agregar Unidad Medida
                </button>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="pills-productos"
              role="tabpanel"
              aria-labelledby="pills-productos-tab"
            >
              <table class="table table-striped table-responsive">
                <thead>
                  <tr>
                    <th style="font-size: 12px">
                      Claves de Productos y Servicios
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(prod, index) in form.productServCodes"
                    :key="prod.id"
                    :index="index"
                  >
                    <td scope="row" style="font-size: 12px">
                      {{ prod.Value }} - {{ prod.Name }}
                    </td>
                    <td>
                      <div
                        class="add-item-action"
                        @click="removeItemProdServ(prod.Value)"
                      >
                        <base-button
                          icon="trash"
                          color="danger"
                          size="small"
                          iconButton
                        ></base-button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="form-group">
                <label style="font-size: 12px">
                  Palabra clave para buscar productos/servicios
                </label>
              </div>
              <div class="row">
                <div class="col-10">
                  <div class="form-group">
                    <input v-model="keyWord" type="text" class="form-control" />
                  </div>
                </div>
                <div class="col-2">
                  <a href="#" @click="searchProdServ()">
                    <font-awesome-icon
                      icon="search"
                      class="right-icon"
                      color="#88c349"
                    />
                  </a>
                </div>
              </div>
              <div v-if="clavesProdServ.length > 0" id="app">
                <div class="text-uppercase text-bold" style="font-size: 12px">
                  Claves para guardar:
                </div>
                <table class="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th></th>
                      <th style="font-size: 12px">Clave</th>
                      <th style="font-size: 12px">Nombre</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in clavesProdServ"
                      :key="item.id"
                      :index="index"
                    >
                      <td>
                        <div
                          class="add-item-action"
                          @click="addClaveProdServ(item.elemento)"
                        >
                          <button
                            type="button"
                            class="btn btn-block btn-outline-success"
                          >
                            <font-awesome-icon
                              icon="fa-regular fa-cart-circle-plus"
                              class="mr-2"
                            />Agregar
                          </button>
                        </div>
                        <!--<label class="form-checkbox">
                          <input
                            type="checkbox"
                            :value="item.elemento"
                            v-model="selected"
                            v-on:change="addClaveProdServ"
                          />
                          <i class="form-icon"></i>
                        </label>-->
                      </td>
                      <td style="font-size: 12px">{{ item.Value }}</td>
                      <td style="font-size: 12px">{{ item.Name }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <!--<div class="add-item-action" @click="addClaveProdServ">
                <button type="button" class="btn btn-block btn-outline-success">
                  <font-awesome-icon
                    icon="shopping-basket"
                    class="mr-2"
                  />Agregar Producto/Servicio
                </button>
              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TaxRegimeCatalog from '../components/TaxRegimeCatalog'
import UnitCodeItem from './UnitCodeItem'
import Guid from 'guid'

const defaultProServ = '01010101'
const defaultUseCFDI = 'P01'
export default {
  components: { TaxRegimeCatalog, UnitCodeItem },
  data: () => ({
    customerId: null,
    customer: null,
    form: {
      prodservInvoicing: defaultProServ,
      taxReturnBlind606: false,
      typeDigitalPlatform625: null,
      concept: '',
      useCFDI: null,
      cp: '',
      satRegistrationDate: null,
      unitCodes: null,
      productServCodes: null,
      sat_settings: false,
    },
    unitCodeForm: {
      items: [],
    },
    selectedUnit: '',
    keyWord: '',
    clavesProdServ: [],
    selectAll: false,
  }),
  computed: {
    ...mapGetters('catalogs', [
      'taxRegimes',
      'digitalPlatformOptions',
      'useCFDI',
      'searchUseCFDI',
      'searchDigitalPlatformOption',
      'unitCodeSelect',
    ]),
    ...mapGetters('calendar', ['defaultYear']),
    ...mapGetters('currency', ['defaultCurrency']),
    customerCurrency() {
      if (this.defaultCurrency) {
        return {
          decimal: this.defaultCurrency.decimal_separator,
          thousands: this.defaultCurrency.thousand_separator,
          prefix: this.defaultCurrency.symbol + ' ',
          precision: this.defaultCurrency.precision,
          masked: false,
        }
      } else {
        return this.defaultCurrenctForInput
      }
    },
    unitCodeOptions() {
      return this.unitCodeSelect.map((item) => {
        return {
          ...item,
          id: item.id,
          label: `${item.id} - ${item.description}`,
        }
      })
    },
    useCFDIOptions() {
      if (!this.customer) {
        return []
      }
      const typePerson = this.customer.rfc.length == 12 ? 'moral' : 'physical'
      const useCFIDCatalog = this.useCFDI.filter((item) => {
        if (typePerson == 'moral') {
          return item.moral
        }
        if (typePerson == 'physical') {
          return item.physical
        }
      })

      return useCFIDCatalog.map((item) => {
        return {
          id: item.id,
          description: `${item.id} - ${item.description}`,
        }
      })
    },
  },
  created() {
    this.customerId = this.$route.params.id
    this.loadCustomer()
  },
  methods: {
    ...mapActions('customer', [
      'fetchCustomer',
      'fetchCustomerSatSettings',
      'saveSatSettings',
      'saveSatUnitFavorite',
      'removeSatUnitFavorite',
      'removeSatProdServFavorite',
    ]),
    ...mapActions('customer', [
      'fetchCatalogoServProd',
      'saveSatProdServFavorite',
    ]),
    loadCustomer() {
      this.fetchCustomer(this.customerId)
        .then((response) => {
          this.customer = response.data.customer
          this.loadSatSettings()
        })
        .catch((err) => {
          this.$route.push('/general/customers')
        })
    },
    loadSatSettings() {
      this.fetchCustomerSatSettings(this.customer.id)
        .then((response) => {
          const satSettings = response.data.sat_settings
          this.form.satRegistrationDate = satSettings.sat_registration_date
          this.form.prodservInvoicing =
            satSettings.prodserv_invoicing || defaultProServ
          this.form.taxReturnBlind606 = satSettings.tax_return_blind_606
          this.form.typeDigitalPlatform625 = this.searchDigitalPlatformOption(
            satSettings.type_digital_platform_625
          )
          this.form.cp = satSettings.cp
          this.form.concept = satSettings.concept
          const useCFDI = this.searchUseCFDI(
            satSettings.use_cfdi || defaultUseCFDI
          )
          this.form.useCFDI = {
            id: useCFDI.id,
            description: `${useCFDI.id} - ${useCFDI.description}`,
          }
          this.form.unitCodes = JSON.parse(satSettings.unit_codes)
          this.form.productServCodes = JSON.parse(
            satSettings.product_service_codes
          )
        })
        .catch((err) => {})
    },

    searchProdServ() {
      this.fetchCatalogoServProd(this.keyWord)
        .then((response) => {
          let claves = response.data.filter((element) => element.Value != 0)
          let favProdServ = this.form.productServCodes
          if (favProdServ != null) {
            favProdServ.forEach((elemento1) => {
              claves = claves.filter(
                (elemento2) => elemento2.Value != elemento1.Value
              )
            })
          }

          this.clavesProdServ = claves.map((item) => {
            return {
              ...item,
              id: item.Value,
              elemento: { Value: item.Value, Name: item.Name },
            }
          })
        })
        .catch(() => {
          return []
        })
    },
    select() {
      if (!this.selectAll) {
        for (let i in this.clavesProdServ) {
          this.selected.push(this.clavesProdServ[i].elemento)
        }
      }
    },

    addItemRow() {
      if (this.selectedUnit) {
        const data = {
          id: this.customer.id,
          data: {
            selectedUnit: this.selectedUnit ? this.selectedUnit : '',
          },
        }

        this.saveSatUnitFavorite(data)
          .then((response) => {
            if (response.data.success) {
              this.form.unitCodes = response.data.data
              this.selectedUnit = ''
              window.toastr.success(this.$t('customers.sat_settings.saved'))
            } else {
              window.toastr.error(
                'Debe guardar primero la Configuración General.'
              )
            }
          })
          .catch((err) => {
            window.toastr.error(this.$t('customers.sat_settings.error_save'))
          })
      }
    },

    addClaveProdServ(element) {
      let elementArray = []

      elementArray.push(element)

      const data = {
        id: this.customer.id,
        data: {
          selectedProdServ: elementArray,
        },
      }

      this.saveSatProdServFavorite(data)
        .then((response) => {
          if (response.data.success) {
            this.form.productServCodes = response.data.data
            this.searchProdServ()
            window.toastr.success(this.$t('customers.sat_settings.saved'))
          } else {
            window.toastr.error(
              'Debe guardar primero la Configuración General.'
            )
          }
        })
        .catch((err) => {
          window.toastr.error(this.$t('customers.sat_settings.error_save'))
        })
    },
    removeItem(index) {
      const data = {
        id: this.customer.id,
        data: {
          unidad: index,
        },
      }

      this.removeSatUnitFavorite(data)
        .then((response) => {
          this.form.unitCodes = response.data.data
          window.toastr.success(this.$t('customers.sat_settings.saved'))
        })
        .catch((err) => {
          window.toastr.error(this.$t('customers.sat_settings.error_save'))
        })
    },

    removeItemProdServ(index) {
      const data = {
        id: this.customer.id,
        data: {
          clave: index,
        },
      }

      this.removeSatProdServFavorite(data)
        .then((response) => {
          this.form.productServCodes = response.data.data
          window.toastr.success(this.$t('customers.sat_settings.saved'))
        })
        .catch((err) => {
          window.toastr.error(this.$t('customers.sat_settings.error_save'))
        })
    },

    checkItemsData(index, isValid) {
      this.unitCodeForm.items[index].valid = isValid
    },
    submit() {
      const data = {
        id: this.customer.id,
        data: {
          ...this.form,
          typeDigitalPlatform625: this.form.typeDigitalPlatform625
            ? this.form.typeDigitalPlatform625.key
            : '',
          useCFDI: this.form.useCFDI ? this.form.useCFDI.id : '',
        },
      }
      this.saveSatSettings(data)
        .then((response) => {
          window.toastr.success(this.$t('customers.sat_settings.saved'))
        })
        .catch((err) => {
          window.toastr.error(this.$t('customers.sat_settings.error_save'))
        })
    },
  },
}
</script>
