<template>
  <div class="p-3">
    <form @submit.prevent="save">
      <div class="form-group">
        <label for="year" class="label-form">
          {{ $t('tax_losses.year') }}
        </label>
        <base-input
          :placeholder="$t('tax_losses.year')"
          v-model="form.year"
          type="number"
          @change="$v.form.year.$touch()"
        />
        <div v-if="$v.form.year.$error">
          <span v-if="!$v.form.year.required" class="text-danger">
            {{ $t('validation.required') }}
          </span>
        </div>
      </div>

      <div class="form-group">
        <label for="perodicity">
          {{ $t('tax_losses.periodicity') }}
        </label>
        <base-select
          v-model="form.periodicity"
          :options="catalogs.periodicity"
          :searchable="true"
          :show-labels="false"
          :placeholder="$t('tax_losses.periodicity')"
          label="description"
          @change="$v.form.periodicity.$touch()"
        />
        <div v-if="$v.form.periodicity.$error">
          <span v-if="!$v.form.periodicity.required" class="text-danger">
            {{ $t('validation.required') }}
          </span>
        </div>
      </div>

      <div class="form-group">
        <label for="tax_return_type">
          {{ $t('tax_losses.tax_return_type') }}
        </label>
        <base-select
          v-model="form.tax_return_type"
          :options="catalogs.tax_return_types"
          :searchable="true"
          :show-labels="false"
          :placeholder="$t('tax_losses.tax_return_type')"
          label="description"
          @change="$v.form.tax_return_type.$touch()"
        />
        <div v-if="$v.form.tax_return_type.$error">
          <span v-if="!$v.form.tax_return_type.required" class="text-danger">
            {{ $t('validation.required') }}
          </span>
        </div>
      </div>

      <div class="form-group">
        <label for="operation_number" class="label-form">
          {{ $t('tax_losses.operation_number') }}
        </label>
        <base-input
          :placeholder="$t('tax_losses.operation_number')"
          v-model="form.operation_number"
          @change="$v.form.operation_number.$touch()"
        />
        <div v-if="$v.form.operation_number.$error">
          <span v-if="!$v.form.operation_number.required" class="text-danger">
            {{ $t('validation.required') }}
          </span>
        </div>
      </div>

      <div class="form-group">
        <label for="date_of_presentation" class="label-form">
          {{ $t('tax_losses.date_of_presentation') }}
        </label>
        <base-date-picker
          v-model="form.presentation_date"
          :calendar-button="true"
          calendar-button-icon="calendar"
          @change="$v.form.presentation_date.$touch()"
        />
        <div v-if="$v.form.presentation_date.$error">
          <span v-if="!$v.form.presentation_date.required" class="text-danger">
            {{ $t('validation.required') }}
          </span>
        </div>
      </div>

      <div v-if="showTaxLoss" class="form-group base-input">
        <label v-if="!showRIFLabel" for="amount" class="label-form">
          {{ $t('tax_losses.tax_loss') }}
        </label>
        <label v-if="showRIFLabel" for="amount" class="label-form">
          {{ $t('tax_losses.deduction_greater_than_income') }}
        </label>
        <money
          v-model="form.amount"
          v-bind="customerCurrency"
          class="input-field text-right"
          @change="$v.form.amount.$touch()"
        />
        <div v-if="$v.form.amount.$error">
          <span v-if="!$v.form.amount.required" class="text-danger">
            {{ $t('validation.required') }}
          </span>
        </div>
      </div>

      <div v-if="showIsrInFavor" class="form-group base-input">
        <label for="isr_in_favor" class="label-form">
          {{ $t('tax_losses.isr_in_favor') }}
        </label>
        <money
          v-model="form.isr_in_favor"
          v-bind="customerCurrency"
          class="input-field text-right"
          @change="$v.form.isr_in_favor.$touch()"
        />
        <div v-if="$v.form.isr_in_favor.$error">
          <span v-if="!$v.form.isr_in_favor.required" class="text-danger">
            {{ $t('validation.required') }}
          </span>
        </div>
      </div>

      <div v-if="showIvaInFavor" class="form-group base-input">
        <label for="iva_in_favor" class="label-form">
          {{ $t('tax_losses.iva_in_favor') }}
        </label>
        <money
          v-model="form.iva_in_favor"
          v-bind="customerCurrency"
          class="input-field text-right"
          @change="$v.form.iva_in_favor.$touch()"
        />
        <div v-if="$v.form.iva_in_favor.$error">
          <span v-if="!$v.form.iva_in_favor.required" class="text-danger">
            {{ $t('validation.required') }}
          </span>
        </div>
      </div>

      <div class="card-footer btn-container">
        <base-button
          :loading="isLoading"
          color="theme"
          icon="save"
          type="submit"
        >
          {{ $t('general.save') }}
        </base-button>
      </div>
    </form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],
  data: () => ({
    isLoading: false,
    form: {
      year: null,
      periodicity: null,
      tax_return_type: null,
      operation_number: '',
      presentation_date: null,
      amount: 0,
      iva_in_favor: 0,
      isr_in_favor: 0,
    },
  }),
  validations: {
    form: {
      year: { required },
      periodicity: { required },
      tax_return_type: { required },
      operation_number: { required },
      presentation_date: { required },
      amount: { required },
      iva_in_favor: { required },
      isr_in_favor: { required },
    },
  },
  computed: {
    ...mapGetters('CurrentCustomer', ['currentCustomer']),
    ...mapGetters('calendar', ['defaultYear']),
    ...mapGetters('taxLossAndBalance', ['catalogs']),
    ...mapGetters('currency', ['defaultCurrency']),
    customerCurrency() {
      if (this.defaultCurrency) {
        return {
          decimal: this.defaultCurrency.decimal_separator,
          thousands: this.defaultCurrency.thousand_separator,
          prefix: this.defaultCurrency.symbol + ' ',
          precision: this.defaultCurrency.precision,
          masked: false,
        }
      } else {
        return this.defaultCurrenctForInput
      }
    },
    periodicityCode() {
      if (!this.form.periodicity) {
        return ''
      }
      return this.form.periodicity.code
    },
    showRIFLabel() {
      if (this.periodicityCode == 'bimester') {
        return true
      }
      return false
    },
    showTaxLoss() {
      const periods = ['yearly', 'bimester']
      if (periods.includes(this.periodicityCode)) {
        return true
      }
      return false
    },
    showIsrInFavor() {
      if (this.periodicityCode == 'yearly') {
        return true
      }
      return false
    },
    showIvaInFavor() {
      const periods = ['monthly', 'bimester']
      if (periods.includes(this.periodicityCode)) {
        return true
      }
      return false
    },
  },
  watch: {
    'form.periodicity': function () {
      this.form.amount = 0
      this.form.iva_in_favor = 0
      this.form.isr_in_favor = 0
    },
  },
  created() {
    this.form.year = this.defaultYear
  },
  methods: {
    ...mapActions('taxLossAndBalance', ['fetchTaxLosses', 'saveTaxLoss']),
    ...mapActions('modal', ['closeModal']),
    save() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return null
      }
      const data = {
        ...this.form,
        customer_id: this.currentCustomer.id,
        periodicity: this.form.periodicity.code,
        tax_return_type: this.form.tax_return_type.code,
      }
      this.saveTaxLoss(data)
        .then((response) => {
          if (response.data.success) {
            window.toastr.success(this.$t('tax_losses.messages.tax_loss_saved'))
            const params = { customer_id: this.currentCustomer.id }
            this.fetchTaxLosses(params).then()
            this.closeModal()
          } else {
            window.toastr.error(this.$t('tax_losses.errors.tax_loss_save'))
          }
        })
        .catch(() => {
          window.toastr.error(this.$t('tax_losses.errors.tax_loss_save'))
        })
    },
  },
}
</script>
