var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "card-body" }, [
      _c("form", { staticClass: "pt-4 pb-4 pl-4 pr-4" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-4" },
            [
              _c("label", { attrs: { for: "input1" } }, [
                _vm._v("Ingresos Adicionales"),
              ]),
              _vm._v(" "),
              _c("base-select", {
                staticStyle: { "font-size": "12px" },
                attrs: {
                  options: _vm.adicionalOptions,
                  searchable: true,
                  "allow-empty": false,
                  "show-labels": false,
                  label: "label",
                  "track-by": "id",
                },
                model: {
                  value: _vm.form.adicional,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "adicional", $$v)
                  },
                  expression: "form.adicional",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _vm.form.adicional?.id == "0"
          ? _c("div", { staticClass: "row mt-3" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("label", { attrs: { for: "input1" } }, [
                    _vm._v("Ingresos adicionales por"),
                  ]),
                  _vm._v(" "),
                  _c("base-select", {
                    staticStyle: { "font-size": "12px" },
                    attrs: {
                      options: _vm.conceptoAdicionalOptions,
                      searchable: true,
                      "allow-empty": false,
                      "show-labels": false,
                      label: "label",
                      "track-by": "id",
                    },
                    model: {
                      value: _vm.form.conceptoAdicional,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "conceptoAdicional", $$v)
                      },
                      expression: "form.conceptoAdicional",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.form.adicional?.id == "0" && _vm.form.conceptoAdicional?.id == "0"
          ? _c("div", { staticClass: "row mt-3" }, [
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c("label", { attrs: { for: "input1" } }, [
                    _vm._v("Monto que desea adicionar"),
                  ]),
                  _vm._v(" "),
                  _c("base-input", {
                    attrs: { type: "number" },
                    model: {
                      value: _vm.form.totalAdicional,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "totalAdicional", $$v)
                      },
                      expression: "form.totalAdicional",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.form.adicional?.id == "0" && _vm.form.conceptoAdicional?.id == "0"
          ? _c("div", { staticClass: "row mt-3" }, [
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c("label", { attrs: { for: "input1" } }, [_vm._v("Nota")]),
                  _vm._v(" "),
                  _c("base-text-area", {
                    model: {
                      value: _vm.form.nota,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "nota", $$v)
                      },
                      expression: "form.nota",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card-footer" }, [
      _c(
        "div",
        { staticClass: "mt-2 flex-content-right" },
        [
          _c(
            "base-button",
            {
              attrs: { color: "theme" },
              on: { click: _vm.saveIngresoAdicional },
            },
            [_vm._v("\n      Guardar\n    ")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }