var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-content" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("vue-advanced-chat", {
        attrs: {
          height: "calc(100vh - 30vh)",
          "show-search": "false",
          "show-audio": "false",
          "show-emojis": "false",
          "show-add-room": "false",
          "show-reaction-emojis": "false",
          "show-new-messages-divider": "false",
          "single-room": "true",
          "message-selection-actions": "[]",
          "message-actions": "[]",
          "current-user-id": _vm.currentUserId,
          rooms: _vm.getRooms(),
          "rooms-loaded": true,
          messages: JSON.stringify(_vm.messages),
          "messages-loaded": _vm.messagesLoaded,
          "show-files": true,
        },
        on: {
          "send-message": function ($event) {
            return _vm.sendMessage($event.detail[0])
          },
          "fetch-messages": function ($event) {
            return _vm.fetchMessages($event.detail[0])
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page-header mt-4" }, [
      _c("h3", { staticClass: "page-title" }, [_vm._v("Mensajes")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }