<template>
  <div class="contratacion main-content">
    <div class="page-header mt-4" style="z-index: 1">
      <h3 class="page-title">Declaraciones de Impuestos</h3>
      <h5>
        Selecciona los meses que deseas realizar el cumplimiento de obligaciones
        fiscales
      </h5>
    </div>
    <div class="row">
      <div class="col-md-6">
        <h6>Declaraciones Mensuales</h6>
        <div v-for="(year, index) in years" :key="year">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <div
                class="pl-2"
                v-b-toggle="'collapse-' + index"
                variant="primary"
                @click="cardSelect(index)"
              >
                <font-awesome-icon
                  :icon="selectIndex == index ? 'angle-down' : 'angle-right'"
                  class="icon menu-icon"
                />
                <input
                  type="checkbox"
                  :checked="isSelectedYear(year)"
                  @click.stop="setAllDeclaracionesYear(year)"
                />
                {{ year }}
              </div>
            </b-card-header>

            <b-collapse
              :id="'collapse-' + index"
              class="mt-2"
              :visible="index === 0"
            >
              <div
                class="pl-4 my-3"
                v-for="(month, mesIndex) in meses"
                :key="mesIndex + month"
              >
                <input
                  type="checkbox"
                  @click="setMesDeclaracion({ year, month })"
                  :checked="
                    isSelected({
                      year,
                      month,
                    })
                  "
                />
                {{ month }}
              </div>
            </b-collapse>
          </b-card>
        </div>
      </div>
      <div class="col-md-6">
        <base-button class="mt-5" @click="payment()" color="#1B144A">
          Siguiente
          <font-awesome-icon
            :icon="['fas', 'arrow-right']"
            class="icon menu-icon mr-2"
          />
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {},

  data: () => ({
    currentYear: new Date().getFullYear(),
    years: [], // Inicializa el array de años vacío
    meses: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ],
    selectIndex: 0,
    customer: null,

    declaracionesSeleccioandas: [],
    idCustomer: null,
    update: false,
  }),

  mounted() {},
  created() {
    // Crea el array de años en el método created
    const idCustomerParam = this.$route.params.id
    if (idCustomerParam) {
      this.idCustomer = idCustomerParam
    }
    console.log(idCustomerParam)
    this.fetchAssigned(idCustomerParam)

    this.years = Array.from({ length: 4 }, (_, index) =>
      (this.currentYear - index).toString()
    )
  },
  watch: {},
  methods: {
    ...mapActions('calendar', ['setYear', 'setMonth']),
    ...mapGetters('calendar', ['months', 'defaultMonth', 'defaultYear']),
    setCustomerToViewDetails(year, month) {},
    cardSelect(index) {
      if (this.selectIndex == index) {
        this.selectIndex = -1
      } else {
        this.selectIndex = index
      }
    },
    async fetchAssigned(id) {
      let res = await window.axios.get(`/api/assigned/get/${id}`)
      if (res) {
        // console.log(res.data.declarations)
        this.declaracionesSeleccioandas = res.data.declarations
        this.update = res.data.declarations.length > 0 ? true : false
        console.log(this.update)
      }
    },
    payment() {
      // console.log(this.idCustomer)
      this.postAssigned()
    },

    async postAssigned() {
      try {
        if (this.update) {
          window.axios
            .put('/api/assigned/put', {
              declarations: this.declaracionesSeleccioandas,
              user_id: this.idCustomer,
            })
            .then((response) => {
              if (response.status == 201) {
                this.$router.go(-1); 
              }
            })
        } else {
          window.axios
            .post('/api/assigned/add', {
              declarations: this.declaracionesSeleccioandas,
              user_id: this.idCustomer,
            })
            .then((response) => {
              if (response.status == 201) {
                this.$router.push('/general/customers/pending')
              }
            })
        }
      } catch (error) {
        console.error('Error al enviar los datos:', error)
      }
    },

    setAllDeclaracionesYear(year) {
      // Obtener la lista de meses
      const months = this.meses

      // Verificar si ya existen todas las declaraciones para el año dado
      const declarationsForYear = this.declaracionesSeleccioandas.filter(
        (option) => option.year === year
      )
      const allMonthsExist = months.every((month) =>
        declarationsForYear.some((option) => option.month === month)
      )

      if (allMonthsExist) {
        // Si ya existen todas las declaraciones, elimínalas
        this.declaracionesSeleccioandas =
          this.declaracionesSeleccioandas.filter(
            (option) => option.year !== year
          )
      } else {
        // Si no existen todas las declaraciones, agrégalas todas
        months.forEach((month) => {
          if (!declarationsForYear.some((option) => option.month === month)) {
            this.declaracionesSeleccioandas.push({
              year: year,
              month: month,
            })
          }
        })
      }
      console.log(this.declaracionesSeleccioandas)
    },
    setMesDeclaracion(optionToCheck) {
      // Verificar si optionToCheck ya está en declaracionesSeleccioandas
      const index = this.declaracionesSeleccioandas.findIndex((option) => {
        return (
          option.year === optionToCheck.year &&
          option.month === optionToCheck.month
        )
      })

      if (index !== -1) {
        // Si se encuentra en el array, elimínalo
        this.declaracionesSeleccioandas.splice(index, 1)
      } else {
        // Si no se encuentra en el array, agrégalo
        this.declaracionesSeleccioandas.push(optionToCheck)
      }

      console.log(this.declaracionesSeleccioandas)
    },
    isSelected(optionToCheck) {
      // Verificar si optionToCheck está en declaracionesSeleccioandas
      const isSelected = this.declaracionesSeleccioandas.some((option) => {
        return (
          option.year === optionToCheck.year &&
          option.month === optionToCheck.month
        )
      })
      return isSelected
    },

    isSelectedYear(year) {
      // Obtener la lista de meses
      const months = this.meses

      // Verificar si ya existen todas las declaraciones para el año dado
      const declarationsForYear = this.declaracionesSeleccioandas.filter(
        (option) => option.year === year
      )
      const allMonthsExist = months.every((month) =>
        declarationsForYear.some((option) => option.month === month)
      )
      return allMonthsExist
    },
  },
}
</script>
