var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-content" }, [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.saveCertificates.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c(
              "div",
              { staticClass: "section-title", staticStyle: { width: "100%" } },
              [_c("h5", [_vm._v(_vm._s(_vm.$t("customers.fiel")))])]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _vm.certificates.fiel
                ? _c("div", { staticClass: "col-12 mb-3 table-certificate" }, [
                    _c("div", { staticClass: "table-responsive" }, [
                      _c("table", { staticClass: "table" }, [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [
                              _vm._v(
                                _vm._s(_vm.$t("customers.certificate_number"))
                              ),
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("customers.certificate_expiration")
                                )
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("tbody", [
                          _c("tr", [
                            _c("td", [
                              _vm._v(_vm._s(_vm.certificates.fiel.number)),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(_vm.certificates.fiel.expires_at)),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "form-group col-md-4" }, [
                _c("label", { attrs: { for: "description" } }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.$t("customers.access_file_cer")) +
                      " :\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "image-upload-box" }, [
                  _c("input", {
                    ref: "e_sign_cer",
                    attrs: { type: "file", accept: ".cer" },
                    on: { change: _vm.onESignCerChange },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group col-md-4" }, [
                _c("label", { attrs: { for: "description" } }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.$t("customers.access_file_key")) +
                      ":\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "image-upload-box" }, [
                  _c("input", {
                    ref: "e_sign_key",
                    attrs: { type: "file", accept: ".key" },
                    on: { change: _vm.onESignKeyChange },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group col-md-4" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("customers.fiel_password")) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("base-input", {
                    attrs: {
                      focus: "",
                      type: "password",
                      name: "fiel_password",
                      "tab-index": "1",
                    },
                    model: {
                      value: _vm.formData.sat_password,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "sat_password", $$v)
                      },
                      expression: "formData.sat_password",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c(
              "div",
              { staticClass: "section-title", staticStyle: { width: "100%" } },
              [
                _c("h5", [
                  _vm._v(_vm._s(_vm.$t("customers.digital_seal_certificate"))),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _vm.certificates.csd
                ? _c("div", { staticClass: "col-12 mb-3 table-certificate" }, [
                    _c("div", { staticClass: "table-responsive" }, [
                      _c("table", { staticClass: "table" }, [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [
                              _vm._v(
                                _vm._s(_vm.$t("customers.certificate_number"))
                              ),
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("customers.certificate_expiration")
                                )
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("tbody", [
                          _c("tr", [
                            _c("td", [
                              _vm._v(_vm._s(_vm.certificates.csd.number)),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(_vm.certificates.csd.expires_at)),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "form-group col-md-4" }, [
                _c("label", { attrs: { for: "description" } }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.$t("customers.digital_stamp_cer")) +
                      " :\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "image-upload-box" }, [
                  _c("input", {
                    ref: "digital_stamp_cer",
                    attrs: { type: "file", accept: ".cer" },
                    on: { change: _vm.onDigitalCerChange },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group col-md-4" }, [
                _c("label", { attrs: { for: "description" } }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.$t("customers.digital_stamp_key")) +
                      " :\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "image-upload-box" }, [
                  _c("input", {
                    ref: "digital_stamp_key",
                    attrs: { type: "file", accept: ".key" },
                    on: { change: _vm.onDigitalKeyChange },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group col-md-4" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("customers.csd_password")) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("base-input", {
                    attrs: {
                      focus: "",
                      type: "password",
                      name: "csd_password",
                      "tab-index": "1",
                    },
                    model: {
                      value: _vm.formData.csd_password,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "csd_password", $$v)
                      },
                      expression: "formData.csd_password",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "text-rigth flex flex-content-right" },
          [
            _c(
              "base-button",
              { attrs: { icon: "save", color: "theme", type: "submit" } },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("customers.upload_certificates")) +
                    "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }