<template>
  <div class="h-100" style="background-color: #1b144a">
    <div class="row h-100">
      <div class="col-lg-8 col-xl-8 col-12" style="background-color: #e2f0d9">
        <header class="navbar" style="background-color: #e2f0d9">
          <a href="/" class="brand-main">
            <img
              id="logo-white"
              src="/assets/img/logo-taxati.png"
              alt="Crater Logo"
              class="d-none d-md-inline"
            />
            <img
              id="logo-mobile"
              src="/assets/img/logo-taxati.png"
              alt="Laraspace Logo"
              class="d-md-none"
            />
          </a>
        </header>
        <div
          style="margin-top: 10%"
          class="w-100 h-50 d-flex flex-column justify-content-around align-items-center"
        >
          <div class="container">
            <h3>Forma de pago</h3>
            <h5>Introduce los datos de la tarjeta de crédito</h5>
          </div>

          <form  id="paymentForm"
          class="container">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <p class="input-label">
                    Nombre
                    <span class="text-danger"> * </span>
                  </p>
                  <base-input
                    v-model="paymentData.nombre"
                    :invalid="$v.paymentData.nombre.$error"
                    type="text"
                    name="nombre"
                    @input="$v.paymentData.nombre.$touch()"
                  />
                  <div v-if="$v.paymentData.nombre.$error">
                    <span
                      v-if="!$v.paymentData.nombre.required"
                      class="text-danger"
                      >{{ $tc('validation.required') }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <p class="input-label">
                    Apellidos
                    <span class="text-danger"> * </span>
                  </p>
                  <base-input
                    v-model="paymentData.apellido"
                    :invalid="$v.paymentData.apellido.$error"
                    type="text"
                    name="apellido"
                    @input="$v.paymentData.apellido.$touch()"
                  />
                  <div v-if="$v.paymentData.apellido.$error">
                    <span
                      v-if="!$v.paymentData.apellido.required"
                      class="text-danger"
                      >{{ $tc('validation.required') }}</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <p class="input-label">
                    Numero de tarjeta
                    <span class="text-danger"> * </span>
                  </p>
                  <base-input
                    v-model="paymentData.tarjeta"
                    :invalid="$v.paymentData.tarjeta.$error"
                    type="text"
                    name="tarjeta"
                    @input="$v.paymentData.tarjeta.$touch()"
                  />
                  <div v-if="$v.paymentData.tarjeta.$error">
                    <span
                      v-if="!$v.paymentData.tarjeta.required"
                      class="text-danger"
                      >{{ $tc('validation.required') }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <p class="input-label">
                        Fecha de Exp.
                        <span class="text-danger"> * </span>
                      </p>
                      <base-input
                        v-model="paymentData.fecha"
                        :invalid="$v.paymentData.fecha.$error"
                        type="text"
                        name="fecha"
                        @input="$v.paymentData.fecha.$touch()"
                      />
                      <div v-if="$v.paymentData.fecha.$error">
                        <span
                          v-if="!$v.paymentData.fecha.required"
                          class="text-danger"
                          >{{ $tc('validation.required') }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <p class="input-label">
                        CVV
                        <span class="text-danger"> * </span>
                      </p>
                      <base-input
                        v-model="paymentData.cvv"
                        :invalid="$v.paymentData.cvv.$error"
                        type="text"
                        name="fecha"
                        @input="$v.paymentData.cvv.$touch()"
                      />
                      <div v-if="$v.paymentData.cvv.$error">
                        <span
                          v-if="!$v.paymentData.cvv.required"
                          class="text-danger"
                          >{{ $tc('validation.required') }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-lg-4 col-xl-4 col-12">
        <div
          class="w-100 p-5 d-flex flex-column justify-content-center align-items-center"
        >
          <div class="w-100" style="margin-top: 10%;">
            <h1 class="text-center text-white">Resumen de la Compra</h1>

            <div class="col mt-5">
              <div class="border-top my-3"></div>

              <div class="row">
                <div class="col-6">
                  <p class="text-white">Descripción</p>
                </div>
                <div class="col-6"><p class="text-white">$200</p></div>
              </div>
              <div class="row">
                <div class="col-6">
                  <p class="text-white">Subtotal</p>
                </div>
                <div class="col-6"><p class="text-white">$200</p></div>
              </div>
              <div class="row">
                <div class="col-6">
                  <p class="text-white">IVA</p>
                </div>
                <div class="col-6"><p class="text-white">$200</p></div>
              </div>
              <div class="row">
                <div class="col-6">
                  <p class="text-white">Descuento</p>
                </div>
                <div class="col-6"><p class="text-white">$200</p></div>
              </div>
              <div class="border-top my-3"></div>
              <div class="row">
                <div class="col-6">
                  <h4 class="text-white">Total</h4>
                </div>
                <div class="col-6"><p class="text-white">$200</p></div>
              </div>
              <div class="d-flex justify-content-center"  style="margin-top: 10%;">
                <base-button :loading="isLoading"   @click="validateBeforeSubmit"  color="theme">
                  Pagar
                  <font-awesome-icon
                    icon="credit-card"
                    class="icon menu-icon ml-2"
                  />
                </base-button>
              </div>
            </div>
          </div>

          <!-- Carrto de compras -->
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import { mapActions } from 'vuex'

import { validationMixin } from 'vuelidate'
const { required, email, minLength } = require('vuelidate/lib/validators')

export default {
  components: {},
  mixins: [validationMixin],
  data() {
    return {
      paymentData: {
        nombre: '',
        apellido: '',
        tarjeta: '',
        fecha: '',
        cvv: '',
      },
      submitted: false,
      isLoading: false,
    }
  },
  validations: {
    paymentData: {
      nombre: {
        required,
      },
      apellido: {
        required,
      },
      tarjeta: {
        required,
      },
      fecha: {
        required,
      },
      cvv: {
        required,
      },
    },
  },
  methods: {
    async validateBeforeSubmit() {
      this.$v.paymentData.$touch() 
      if (this.$v.$invalid) {
        return true
      }
            this.$router.push('/wizard/contrato')

      // this.isLoading = true
      // this.register(this.registroData)
      //   .then((res) => {
      //     if (res.status == 200) {
      //       this.$router.push('/wizard/contratacion')
      //     }
      //     this.isLoading = false
      //   })
      //   .catch((er) => {
      //     this.isLoading = false
      //   })
    },
  },
}
</script>
