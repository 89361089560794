<template>
  <div>
    <div v-if="!disable">
      <template v-if="showContent">
        <slot></slot>
      </template>
    </div>
    <div v-else :style="{ 'pointer-events': !showContent ? 'none' : 'auto' }">
      <template>
        <slot :disabled="showContent"></slot>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      showContent: false,
    }
  },
  props: {
    showForAdmin: {
      type: Boolean,
      default: false,
    },
    showForExecutives: {
      type: Boolean,
      default: false,
    },
    showForCustomers: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('userProfile', ['user']),
  },
  mounted() {
    switch (this.user.role) {
      case 'ejecutivo':
        this.showContent = this.showForExecutives
        break
      case 'supervisor':
        this.showContent = this.showForCustomers

        break
      case 'admin':
        this.showContent = this.showForAdmin
        break
      default:
        this.showContent = false
        break
    }
  },
  methods: {
    ...mapActions('userProfile', ['loadData']),
  },
}
</script>
