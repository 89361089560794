<template>
  <div v-if="taxReturn">
    <!-- Table  ISR -->
    <div class="card p-4">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr class="table-primary">
              <th colspan="2" class="text-center">
                {{ $t('tax_returns.isr') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <!-- Total de ingresos efectivamente cobrados  -->

            <tr class="clickable" @click="toggleDisplayTable('incomeCollected')">
              <td>
                <font-awesome-icon class="icon" icon="arrow-down" />
                Total de ingresos efectivamente cobrados
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.revenue_period" />
              </td>
            </tr>
            <!-- Tabla detalle Total de ingresos efectivamente cobrados  -->
            <tr v-if="displayTables.incomeCollected">
              <td colspan="2">
                <tax-return-invoices-table v-if="taxReturn.tables.ingresos_cobrados"
                  :invoices="taxReturn.tables.ingresos_cobrados.invoices" title="Ingresos" />
              </td>
            </tr>

            <!-- Descuentos, devoluciones y bonificaciones -->
            <tr>
              <td>Descuentos, devoluciones y bonificaciones</td>
              <td class="text-right">
                <input type="number" class="w-50" v-model="taxReturn.amounts.discounts_value" @keydown="onKeyDown"
                  @keyup.enter="actualizacion" />
              </td>
            </tr>
            <!-- Ingresos a Disminuir  -->
            <tr>
              <td>Ingresos a Disminuir</td>
              <td class="text-left">
                <!-- <input type="number" class="w-50" v-model="taxReturn.amounts.income_decrease_value"
                  @click="toggleModalIngresoDisminuir" /> -->
                <div class="w-50 border border-dark ml-auto" style="cursor: pointer;"
                  @click="toggleModalIngresoDisminuir">
                  <money-text :amount="taxReturn.amounts.income_decrease_value * 100" />
                </div>
              </td>
            </tr>
            <!-- Ingresos Adicionales -->
            <tr>
              <td>Ingresos Adicionales</td>
              <td class="text-left">
                <!-- <input type="number" class="w-50" v-model="taxReturn.amounts.additional_income_value" @keydown="onKeyDown"
                  @keyup.enter="actualizacion" /> -->
                <div class="w-50 border border-dark ml-auto" style="cursor: pointer;"
                  @click="toggleModalIngresoAdicional">
                  <money-text :amount="taxReturn.amounts.additional_income_value * 100" />
                </div>
              </td>
            </tr>
            <!--Total de ingresos percibidos por la actividad -->
            <tr>
              <td>Total de ingresos percibidos por la actividad</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.total_income_received" />
              </td>
            </tr>
            <!-- Tasa Aplicable -->
            <tr>
              <td>Tasa Aplicable</td>
              <td class="text-right">
                {{ taxReturn.amounts.applicable_rate }}%
              </td>
            </tr>
            <!-- Impuesto Mensual -->
            <tr>
              <td>Impuesto Mensual</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.monthly_tax" />
              </td>
            </tr>
            <!-- ISR Retenido por personas Morales  -->
            <tr class="clickable" @click="toggleModalIsrRetenido()">
              <!-- @click="toggleDisplayTable('retencion_isr')"  -->
              <td>
                <font-awesome-icon class="icon" icon="arrow-down" />
                ISR Retenido por personas Morales
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.isr_detained" />
              </td>
            </tr>
            <!-- Tabla detalle ISR Retenido por personas Morales -->

            <tr v-if="displayTables.retencion_isr">
              <td colspan="2">
                <tax-return-invoices-table v-if="taxReturn.tables.retencion_isr"
                  :invoices="taxReturn.tables.retencion_isr.invoices" title="ISR Retenido por personas Morales" />
              </td>
            </tr>
            <!-- ISR a Cargo -->
            <tr>
              <td>ISR a Cargo</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.isr_in_charge" />
              </td>
            </tr>
            <!-- Actualziaciones -->
            <tr>
              <td>
                {{ $t('tax_returns.update') }}
              </td>
              <td class="text-right">
                <!-- <money-text :amount="taxReturn.amounts.isr_update" /> -->
                <input type="number" class="w-50" v-model="taxReturn.amounts.isr_update_value" @keydown="onKeyDown"
                  @keyup.enter="actualizacion" />
              </td>
            </tr>
            <!-- ISR recargos -->
            <tr>
              <td>
                {{ $t('tax_returns.surcharges') }}
              </td>
              <td class="text-right">
                <input type="number" class="w-50" v-model="taxReturn.amounts.isr_surcharge_value" @keydown="onKeyDown"
                  @keyup.enter="actualizacion" />
                <!-- <money-text :amount="taxReturn.amounts.isr_surcharges" /> -->
              </td>
            </tr>
            <!--Total de contribuciones  -->
            <tr>
              <td>Total de contribuciones</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.resicoTotalContribuciones" />
              </td>
            </tr>
            <!--Subsidio Para el Empleo  -->
            <tr>
              <td>Subsidio Para el Empleo</td>
              <td class="text-right">
                <input type="number" class="w-50" v-model="taxReturn.amounts.employment_subsidy_value"
                  @keydown="onKeyDown" @keyup.enter="actualizacion" />
                <!-- <money-text :amount="taxReturn.amounts.isr_surcharges" /> -->
              </td>
            </tr>
            <!-- Compensaciones -->
            <tr>
              <td>Compensaciones</td>
              <td class="text-right">
                <input type="number" class="w-50" v-model="taxReturn.amounts.compensations_value"
                  @click="toggleModalCompensacion()" />
              </td>
            </tr>
            <!-- Total a pagar de ISR -->
            <tr>
              <td>Total a pagar de ISR</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.cantidadPagarIsr" />
              </td>
            </tr>
          </tbody>
        </table>

        <!-- <table class="table table-bordered">
          <thead>
            <tr class="table-primary">
              <th colspan="2" class="text-center">
                {{ $t('tax_returns.isr') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="clickable"
              @click="toggleDisplayTable('incomeCollected')"
            >
              <td>
                <font-awesome-icon class="icon" icon="arrow-down" /> Ingresos
                cobrados
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.revenue_period" />
              </td>
            </tr>

            <tr v-if="displayTables.incomeCollected">
              <td colspan="2">
                <tax-return-invoices-table
                  v-if="taxReturn.tables.ingresos_cobrados"
                  :invoices="taxReturn.tables.ingresos_cobrados.invoices"
                  title="Ingresos"
                />
              </td>
            </tr>

            <tr>
              <td>{{ $t('tax_returns.revenue_additional') }}</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.revenue_additional" />
              </td>
            </tr>

            <tr>
              <td>{{ $t('tax_returns.revenue_collected_charged') }}</td>
              <td class="text-right">
                <money-text
                  :amount="taxReturn.amounts.revenue_collected_charged"
                />
              </td>
            </tr>

            <tr>
              <td>{{ $t('tax_returns.isr_percentage') }}</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.isr_percentage" />
              </td>
            </tr>

            <tr>
              <td>{{ $t('tax_returns.isr_caused') }}</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.isr_caused" />
              </td>
            </tr>

            <tr>
              <td>{{ $t('tax_returns.isr_detained') }}</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.isr_detained" />
              </td>
            </tr>

            <tr>
              <td>
                {{ $t('tax_returns.isr_in_charge') }}
              </td>
              <td class="text-right">

                  <money-text :amount="taxReturn.amounts.isr_in_charge" />

              </td>
            </tr>

            <tr>
              <td>Actualizaciones</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.resicoUpdates" />
              </td>
            </tr>

            <tr>
              <td>Recargos</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.resicoRecargos" />
              </td>
            </tr>

            <tr>
              <td>Total Contribuciones</td>
              <td class="text-right">

                  <money-text
                    :amount="taxReturn.amounts.resicoTotalContribuciones"
                  />

              </td>
            </tr>

            <tr>
              <td>Compensaciones</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.resicoCompensaciones" />
              </td>
            </tr>

            <tr>
              <td>Cantidad a Pagar de ISR</td>
              <td class="text-right">

                  <money-text :amount="taxReturn.amounts.cantidadPagarIsr" />

              </td>
            </tr>
          </tbody>
        </table> -->
      </div>
    </div>
    <!-- Table IVA -->
    <div class="card p-4">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr class="table-primary">
              <th colspan="2" class="text-center">
                {{ $t('tax_returns.iva') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <!-- Actividades Gravadas a la tasa del 16%  -->
            <tr class="clickable" @click="toggleDisplayTable('tasa_16')">
              <td>
                <font-awesome-icon class="icon" icon="arrow-down" />
                Actividades Gravadas a la tasa del 16%
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.actividades16" />
              </td>
            </tr>

            <!-- Tabla detalle Total de Actividades Gravadas a la tasa del 16%  -->
            <tr v-if="displayTables.tasa_16">
              <td colspan="2">
                <tax-return-invoices-table v-if="taxReturn.tables.tasa_16" :invoices="taxReturn.tables.tasa_16.invoices"
                  title="Actividades Gravadas a la tasa del 16%" />
              </td>
            </tr>
            <!-- Actividades Gravadas a la tasa del 0%  -->
            <tr v-if="taxReturn.tables.tasa_0.invoices.length > 0" class="clickable"
              @click="toggleDisplayTable('tasa_0')">
              <td>
                <font-awesome-icon class="icon" icon="arrow-down" />
                Actividades Gravadas a la tasa del 0%
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.actividades0" />
              </td>
            </tr>

            <!-- Tabla detalle Total de Actividades Gravadas a la tasa del 0%  -->
            <tr v-if="displayTables.tasa_0 &&
              taxReturn.tables.tasa_0.invoices.length > 0
              ">
              <td colspan="2">
                <tax-return-invoices-table v-if="taxReturn.tables.tasa_0" :invoices="taxReturn.tables.tasa_0.invoices"
                  title="Actividades Gravadas a la tasa del 0%" />
              </td>
            </tr>
            <!-- Actividades Exentas  -->
            <tr v-if="taxReturn.tables.tasa_excentos.invoices.length > 0" class="clickable"
              @click="toggleDisplayTable('tasa_excentos')">
              <td>
                <font-awesome-icon class="icon" icon="arrow-down" />
                Actividades Exentas
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.excentos" />

                <!-- <money-text :amount="taxReturn.amounts.iva_caused" /> -->
              </td>
            </tr>
            <!-- Tabla detalle Total de Actividades Exentas -->
            <tr v-if="displayTables.tasa_excentos &&
              taxReturn.tables.tasa_excentos.invoices.length > 0
              ">
              <td colspan="2">
                <tax-return-invoices-table v-if="taxReturn.tables.tasa_excentos"
                  :invoices="taxReturn.tables.tasa_excentos.invoices" title="Actividades Exentas" />
              </td>
            </tr>
            <!-- IVA a Cargo a la Tasa del 16% -->
            <tr>
              <td>IVA a Cargo a la Tasa del 16%</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.iva_caused" />
              </td>
            </tr>

            <!-- Total de IVA a Cargo -->
            <tr>
              <td>Total de IVA a Cargo</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.iva_caused" />
              </td>
            </tr>
            <!-- IVA no cobrado por devoluciones, descuentos y bonificaciones de ventas -->
            <tr>
              <td>
                IVA no cobrado por devoluciones, descuentos y bonificaciones de
                ventas >
              </td>
              <td class="text-right"></td>
            </tr>
            <!-- IVA Retenido -->
            <tr >
              <!-- @click="toggleDisplayTable('iva_retenido')" -->
              <td>
                IVA Retenido
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.iva_detained" />
              </td>
            </tr>
            <!-- Tabla detalle iva retenido -->

            <tr v-if="displayTables.iva_retenido">
              <td colspan="2">
                <tax-return-invoices-table v-if="taxReturn.tables.retencion_iva"
                  :invoices="taxReturn.tables.retencion_iva.invoices" title="IVA Retenido" />
              </td>
            </tr>
            <!-- IVA Acreditable del Periodo-->
            <tr class="clickable" @click="toggleDisplayTable('creditablePeriod')">
              <td>
                <font-awesome-icon class="icon" icon="arrow-down" />
                IVA Acreditable del Periodo
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.iva_acreditable" />
              </td>
            </tr>

            <tr v-if="displayTables.creditablePeriod">
              <td colspan="2">
                <tax-return-creditable-period :taxReturn="taxReturn" title="Ingresos" />
              </td>
            </tr>

            <!-- IVA por devoluciones, descuentos y bonificaciones en gastos -->
            <tr>
              <td>
                IVA por devoluciones, descuentos y bonificaciones en gastos
              </td>
              <td class="text-right">
                <input type="number" class="w-50" v-model="taxReturn.amounts.returns_and_expenses_value"
                  @keydown="onKeyDown" @keyup.enter="actualizacion" />
              </td>
            </tr>
            <!-- IVA a Cargo -->
            <tr>
              <td>IVA a Cargo</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.iva_in_charge" />
              </td>
            </tr>
            <!-- Acreditamiento -->
            <tr>
              <td>Acreditamiento</td>
              <td class="text-right">
                <input type="number" class="w-50" v-model="taxReturn.amounts.accreditation_value"
                  @click="toggleModalAcreditamiento()" />
              </td>
            </tr>
            <!-- Impuesto a Cargo -->
            <tr>
              <td>Impuesto a Cargo</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.tax_charge" />
              </td>
            </tr>
            <!-- IVA actualización -->
            <tr>
              <td>
                {{ $t('tax_returns.update') }}
              </td>
              <td class="text-right">
                <!-- <money-text :amount="taxReturn.amounts.isr_update" /> -->
                <input type="number" v-model="taxReturn.amounts.iva_update_value" @keydown="onKeyDown"
                  @keyup.enter="actualizacion" />
              </td>
            </tr>
            <!-- IVA recargos -->
            <tr>
              <td>
                {{ $t('tax_returns.surcharges') }}
              </td>
              <td class="text-right">
                <input type="number" v-model.number="taxReturn.amounts.iva_surcharge_value" @keydown="onKeyDown"
                  @keyup.enter="actualizacion" />
                <!-- <money-text :amount="taxReturn.amounts.isr_surcharges" /> -->
              </td>
            </tr>
            <!-- Total de Contribuciones -->
            <tr>
              <td>Total de Contribuciones</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.ivaCargoFavor" />
              </td>
            </tr>
            <!-- Pago de lo indebido -->
            <tr>
              <td>Pago de lo indebido</td>
              <td class="text-right">
                <input type="number" class="w-50" v-model="taxReturn.amounts.payment_undue_value" @keydown="onKeyDown"
                  @keyup.enter="actualizacion" />
              </td>
            </tr>
            <!-- Total a pagar de IVA-->
            <tr>
              <td>Total a pagar de IVA</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.total_iva" />
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <table class="table table-bordered">
          <thead>
            <tr class="table-primary">
              <th colspan="2" class="text-center">
                {{ $t('tax_returns.iva') }}
              </th>
            </tr>
          </thead>
          <tbody>

            <tr>
              <td>Ingresos Totales del Mes</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.actividades16" />
              </td>
            </tr>
            <tr>
              <td>{{ $t('tax_returns.iva_percentage') }}</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.iva_percentage" />
              </td>
            </tr>

            <tr>
              <td>{{ $t('tax_returns.iva_caused') }}</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.iva_caused" />
              </td>
            </tr>
            <tr
              class="clickable"
              @click="toggleDisplayTable('purchasesAndDeductions')"
            >
              <td>
                <font-awesome-icon class="icon" icon="arrow-down" />
                {{ $t('tax_returns.iva_creditable') }}
              </td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.iva_creditable" />
              </td>
            </tr>
            <tr v-if="displayTables.purchasesAndDeductions">
              <td colspan="2">

                <tax-return-invoices-table
                  v-if="taxReturn.tables.purchases"
                  :invoices="taxReturn.tables.purchases.invoices"
                  :totals="taxReturn.tables.purchases.totals"
                  title="tax_returns.deductions"
                />
              </td>
            </tr>
            <tr>
              <td>{{ $t('tax_returns.iva_detained') }}</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.iva_detained" />
              </td>
            </tr>
            <tr>
              <td>
                {{ $t('tax_returns.iva_in_charge') }}ss
              </td>
              <td class="text-right">

                  <money-text :amount="taxReturn.amounts.iva_in_charge" />

              </td>
            </tr>
            <tr>
              <td>Actualizaciones</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.resicoUpdatesIva" />
              </td>
            </tr>
            <tr>
              <td>Recargos</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.resicoRecargosIva" />
              </td>
            </tr>
            <tr>
              <td>IVA a Cargo (Favor)</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.ivaCargoFavor" />
              </td>
            </tr>
          </tbody>
        </table> -->
      </div>
    </div>

    <!-- Impuestos por Pagar -->
    <div class="card p-4">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr class="table-primary">
              <th colspan="2" class="text-center">Impuestos por Pagar</th>
            </tr>
          </thead>
          <tbody>
            <!-- ISR -->
            <tr>
              <td>ISR</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.cantidadPagarIsr" />
              </td>
            </tr>
            <!-- IVA-->
            <tr>
              <td>IVA</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.ivaCargoFavor" />
              </td>
            </tr>
            <!-- Total -->
            <tr>
              <td>Total</td>
              <td class="text-right">
                <money-text :amount="taxReturn.amounts.total" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import TaxReturnRetentionsTable from './TaxReturnRetentionsTable.vue'
import TaxReturnInvoicesTable from './TaxReturnInvoicesTable'
import TaxReturnCreditablePeriod from './TaxReturnCreditablePeriod'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    TaxReturnRetentionsTable,
    TaxReturnInvoicesTable,
    TaxReturnCreditablePeriod,
  },
  props: {
    taxReturn: {
      type: Object,
      default: null,
    },
    taxLoad: Function,
  },
  data: () => ({
    displayTables: {
      retentions: false,
      purchasesAndDeductions: false,
      incomeCollected: false,
      creditablePeriod: false,

      tasa_16: false,
      tasa_0: false,
      tasa_excentos: false,
      retencion_isr: false,
      iva_retenido: false,
    },
  }),
  watch: {
    compensacion() {
      this.taxReturn.amounts.compensations_value =
        this.compensacion['saldoAplicar']
      this.taxReturn.amounts.compensations_object =
        this.compensacion
      this.actualizacion()
    },
    acreditamiento() {
      this.taxReturn.amounts.accreditation_value =
        this.acreditamiento['saldoAplicar']
      this.taxReturn.amounts.accreditation_object =
        this.acreditamiento
      this.actualizacion()
    },
    ingresoDisminuir() {
      this.taxReturn.amounts.income_decrease_value =
        this.ingresoDisminuir['totalDisminuir'] / 100
      this.taxReturn.amounts.income_decrease_object =
        this.ingresoDisminuir
      // se disminuye isr retenido
        this.taxReturn.amounts.isr_retenido_resta = - this.isrRetenido['ingresoDisminuircfdi']
        - this.isrRetenido['monto']
        + this.isrRetenido['montoAdicionar']

      this.taxReturn.amounts.isr_retenido_object =
        this.isrRetenido
      this.actualizacion()
    },
    ingresoAdicional() {
      this.taxReturn.amounts.additional_income_value =
        this.ingresoAdicional['totalAdicional']
      this.taxReturn.amounts.additional_income_object =
        this.ingresoAdicional
      this.actualizacion()
    },
    isrRetenido() {
      this.taxReturn.amounts.isr_retenido_resta = - this.isrRetenido['ingresoDisminuircfdi']
        - this.isrRetenido['monto']
        + this.isrRetenido['montoAdicionar']

      this.taxReturn.amounts.isr_retenido_object =
        this.isrRetenido
      this.actualizacion()
    }
  },
  computed: {
    ...mapGetters('taxReturns', ['compensacion',
      'acreditamiento',
      'ingresoDisminuir',
      'ingresoAdicional',
      'isrRetenido',
    ]),
  },
  methods: {
    ...mapActions('modal', ['openModal']),
    ...mapActions('taxReturns', ['updatedModalCompensacion', 'updatedModalAcreditamiento', 'updatedModalIngresoCfdi', 'updatedModalIngresoDisminuir', 'updatedModalIngresoAdicional','updatedModalISRRetenido']),

    toggleDisplayTable(table) {
      this.displayTables[table] = !this.displayTables[table]
    },

    actualizacion() {
      if (this.taxReturn.amounts.condonation_percentage > 100) {
        this.taxReturn.amounts.condonation_percentage = 100
      }
      this.taxLoad(this.taxReturn)
    },

    onKeyDown(event) {
      const regex = /^[0-9]+|^$/

      // Verificar si la tecla presionada es 'Backspace'
      if (event.key === 'Backspace') {
        return // Permitir la eliminación
      }

      if (!regex.test(event.key)) {
        event.preventDefault()
      }
    },
    toggleModalCompensacion() {
      if (this.taxReturn.amounts.compensations_object != null) {
        this.updatedModalCompensacion(this.taxReturn.amounts.compensations_object)
      }
      this.openModal({
        title: 'Detalle de Compensación de ISR',
        componentName: 'DetalleCompensacionModal',
        size: 'xl',
      })
    },
    toggleModalAcreditamiento() {
      if (this.taxReturn.amounts.accreditation_object != null) {
        this.updatedModalAcreditamiento(this.taxReturn.amounts.accreditation_object)
      }
      this.openModal({
        title: 'Detalle de Acreditamiento de IVA',
        componentName: 'DetalleAcreditamientoModal',
        size: 'lg',
      })
    },
    toggleModalIngresoDisminuir() {
      if (this.taxReturn.amounts.income_decrease_object != null) {
        this.updatedModalIngresoDisminuir(this.taxReturn.amounts.income_decrease_object)
      }
      // taxReturn.tables.ingresos_cobrados

      this.updatedModalIngresoCfdi(this.taxReturn.tables.ingresos_cobrados)
      this.openModal({
        title: 'Detalles de Ingresos a disminuir',
        componentName: 'DetalleIngresoDisminuir',
        size: 'lg',
      })
    },
    toggleModalIngresoAdicional() {
      if (this.taxReturn.amounts.additional_income_object != null) {
        this.updatedModalIngresoAdicional(this.taxReturn.amounts.additional_income_object)
      }
      this.openModal({
        title: 'Detalles de Ingresos Adicional',
        componentName: 'DetalleIngresoAdicional',
        size: 'lg',
      })
    },
    toggleModalIsrRetenido() {
      if (this.taxReturn.amounts.isr_retenido_object != null) {
        this.updatedModalIngresoAdicional(this.taxReturn.amounts.isr_retenido_object)
      }
      this.updatedModalIngresoCfdi(this.taxReturn.tables.ingresos_cobrados)

      this.openModal({
        title: 'ISR Retenido por personas Morales',
        componentName: 'DetalleIsrRetenido',
        size: 'lg',
      })
    },
    // toggleModalIvaRetenido() {
    //   // if (this.taxReturn.amounts.additional_income_object != null) {
    //   //   this.updatedModalIngresoAdicional(this.taxReturn.amounts.additional_income_object)
    //   // }
    //   this.openModal({
    //     title: 'IVA',
    //     componentName: 'DetalleIvaRetenido',
    //     size: 'lg',
    //   })
    // },
  },
}
</script>

<style lang="scss" scoped>
.clickable {
  td {
    font-weight: 500;
  }
}
</style>
