import * as types from './mutation-types'



export const sendXml = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post(`/api/invoices/manual/upload`, data).then((response) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}
