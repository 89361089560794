export const getCategories = (state) => state.categories
export const categoriesSelect = (state) => state.categoriesSelect
export const subcategories = (state) => state.subcategories
export const subcategoriesUser = (state) => state.subcategoriesUser
export const modalSubcategoryData = (state) => state.modalSubcategoryData
export const modalChangeSubcategoryData = (state) => state.modalChangeSubcategoryData
export const findSubcategoryById = (state) => (id) => {
  const subcategory = state.subcategories.filter(
    (subcategory) => subcategory.id == id
  )
  if (subcategory.length) {
    return subcategory[0]
  }
  return null
}