var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.taxReturn
    ? _c("div", [
        _c("div", { staticClass: "card p-4" }, [
          _c("div", { staticClass: "table-responsive" }, [
            _c("table", { staticClass: "table table-bordered" }, [
              _c("thead", [
                _c("tr", { staticClass: "table-primary" }, [
                  _c(
                    "th",
                    { staticClass: "text-center", attrs: { colspan: "2" } },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("tax_returns.isr")) +
                          "\n            "
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("tbody", [
                _c(
                  "tr",
                  {
                    staticClass: "clickable",
                    on: {
                      click: function ($event) {
                        return _vm.toggleDisplayTable("incomeCollected")
                      },
                    },
                  },
                  [
                    _c(
                      "td",
                      [
                        _c("font-awesome-icon", {
                          staticClass: "icon",
                          attrs: { icon: "arrow-down" },
                        }),
                        _vm._v(
                          "\n              Total de ingresos efectivamente cobrados\n            "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "text-right" },
                      [
                        _c("money-text", {
                          attrs: {
                            amount: _vm.taxReturn.amounts.revenue_period,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.displayTables.incomeCollected
                  ? _c("tr", [
                      _c(
                        "td",
                        { attrs: { colspan: "2" } },
                        [
                          _vm.taxReturn.tables.ingresos_cobrados
                            ? _c("tax-return-invoices-table", {
                                attrs: {
                                  invoices:
                                    _vm.taxReturn.tables.ingresos_cobrados
                                      .invoices,
                                  title: "Ingresos",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v("Descuentos, devoluciones y bonificaciones"),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.taxReturn.amounts.discounts_value,
                          expression: "taxReturn.amounts.discounts_value",
                        },
                      ],
                      staticClass: "w-50",
                      attrs: { type: "number" },
                      domProps: {
                        value: _vm.taxReturn.amounts.discounts_value,
                      },
                      on: {
                        keydown: _vm.onKeyDown,
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.actualizacion.apply(null, arguments)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.taxReturn.amounts,
                            "discounts_value",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Ingresos a Disminuir")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-left" }, [
                    _c(
                      "div",
                      {
                        staticClass: "w-50 border border-dark ml-auto",
                        staticStyle: { cursor: "pointer" },
                        on: { click: _vm.toggleModalIngresoDisminuir },
                      },
                      [
                        _c("money-text", {
                          attrs: {
                            amount:
                              _vm.taxReturn.amounts.income_decrease_value * 100,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Ingresos Adicionales")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-left" }, [
                    _c(
                      "div",
                      {
                        staticClass: "w-50 border border-dark ml-auto",
                        staticStyle: { cursor: "pointer" },
                        on: { click: _vm.toggleModalIngresoAdicional },
                      },
                      [
                        _c("money-text", {
                          attrs: {
                            amount:
                              _vm.taxReturn.amounts.additional_income_value *
                              100,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v("Total de ingresos percibidos por la actividad"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: {
                          amount: _vm.taxReturn.amounts.total_income_received,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Tasa Aplicable")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.taxReturn.amounts.applicable_rate) +
                        "%\n            "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Impuesto Mensual")]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: { amount: _vm.taxReturn.amounts.monthly_tax },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "tr",
                  {
                    staticClass: "clickable",
                    on: {
                      click: function ($event) {
                        return _vm.toggleModalIsrRetenido()
                      },
                    },
                  },
                  [
                    _c(
                      "td",
                      [
                        _c("font-awesome-icon", {
                          staticClass: "icon",
                          attrs: { icon: "arrow-down" },
                        }),
                        _vm._v(
                          "\n              ISR Retenido por personas Morales\n            "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "text-right" },
                      [
                        _c("money-text", {
                          attrs: { amount: _vm.taxReturn.amounts.isr_detained },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.displayTables.retencion_isr
                  ? _c("tr", [
                      _c(
                        "td",
                        { attrs: { colspan: "2" } },
                        [
                          _vm.taxReturn.tables.retencion_isr
                            ? _c("tax-return-invoices-table", {
                                attrs: {
                                  invoices:
                                    _vm.taxReturn.tables.retencion_isr.invoices,
                                  title: "ISR Retenido por personas Morales",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("ISR a Cargo")]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: { amount: _vm.taxReturn.amounts.isr_in_charge },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("tax_returns.update")) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.taxReturn.amounts.isr_update_value,
                          expression: "taxReturn.amounts.isr_update_value",
                        },
                      ],
                      staticClass: "w-50",
                      attrs: { type: "number" },
                      domProps: {
                        value: _vm.taxReturn.amounts.isr_update_value,
                      },
                      on: {
                        keydown: _vm.onKeyDown,
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.actualizacion.apply(null, arguments)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.taxReturn.amounts,
                            "isr_update_value",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("tax_returns.surcharges")) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.taxReturn.amounts.isr_surcharge_value,
                          expression: "taxReturn.amounts.isr_surcharge_value",
                        },
                      ],
                      staticClass: "w-50",
                      attrs: { type: "number" },
                      domProps: {
                        value: _vm.taxReturn.amounts.isr_surcharge_value,
                      },
                      on: {
                        keydown: _vm.onKeyDown,
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.actualizacion.apply(null, arguments)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.taxReturn.amounts,
                            "isr_surcharge_value",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Total de contribuciones")]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: {
                          amount:
                            _vm.taxReturn.amounts.resicoTotalContribuciones,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Subsidio Para el Empleo")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.taxReturn.amounts.employment_subsidy_value,
                          expression:
                            "taxReturn.amounts.employment_subsidy_value",
                        },
                      ],
                      staticClass: "w-50",
                      attrs: { type: "number" },
                      domProps: {
                        value: _vm.taxReturn.amounts.employment_subsidy_value,
                      },
                      on: {
                        keydown: _vm.onKeyDown,
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.actualizacion.apply(null, arguments)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.taxReturn.amounts,
                            "employment_subsidy_value",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Compensaciones")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.taxReturn.amounts.compensations_value,
                          expression: "taxReturn.amounts.compensations_value",
                        },
                      ],
                      staticClass: "w-50",
                      attrs: { type: "number" },
                      domProps: {
                        value: _vm.taxReturn.amounts.compensations_value,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.toggleModalCompensacion()
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.taxReturn.amounts,
                            "compensations_value",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Total a pagar de ISR")]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: {
                          amount: _vm.taxReturn.amounts.cantidadPagarIsr,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card p-4" }, [
          _c("div", { staticClass: "table-responsive" }, [
            _c("table", { staticClass: "table table-bordered" }, [
              _c("thead", [
                _c("tr", { staticClass: "table-primary" }, [
                  _c(
                    "th",
                    { staticClass: "text-center", attrs: { colspan: "2" } },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("tax_returns.iva")) +
                          "\n            "
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("tbody", [
                _c(
                  "tr",
                  {
                    staticClass: "clickable",
                    on: {
                      click: function ($event) {
                        return _vm.toggleDisplayTable("tasa_16")
                      },
                    },
                  },
                  [
                    _c(
                      "td",
                      [
                        _c("font-awesome-icon", {
                          staticClass: "icon",
                          attrs: { icon: "arrow-down" },
                        }),
                        _vm._v(
                          "\n              Actividades Gravadas a la tasa del 16%\n            "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "text-right" },
                      [
                        _c("money-text", {
                          attrs: {
                            amount: _vm.taxReturn.amounts.actividades16,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.displayTables.tasa_16
                  ? _c("tr", [
                      _c(
                        "td",
                        { attrs: { colspan: "2" } },
                        [
                          _vm.taxReturn.tables.tasa_16
                            ? _c("tax-return-invoices-table", {
                                attrs: {
                                  invoices:
                                    _vm.taxReturn.tables.tasa_16.invoices,
                                  title:
                                    "Actividades Gravadas a la tasa del 16%",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.taxReturn.tables.tasa_0.invoices.length > 0
                  ? _c(
                      "tr",
                      {
                        staticClass: "clickable",
                        on: {
                          click: function ($event) {
                            return _vm.toggleDisplayTable("tasa_0")
                          },
                        },
                      },
                      [
                        _c(
                          "td",
                          [
                            _c("font-awesome-icon", {
                              staticClass: "icon",
                              attrs: { icon: "arrow-down" },
                            }),
                            _vm._v(
                              "\n              Actividades Gravadas a la tasa del 0%\n            "
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: {
                                amount: _vm.taxReturn.amounts.actividades0,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.displayTables.tasa_0 &&
                _vm.taxReturn.tables.tasa_0.invoices.length > 0
                  ? _c("tr", [
                      _c(
                        "td",
                        { attrs: { colspan: "2" } },
                        [
                          _vm.taxReturn.tables.tasa_0
                            ? _c("tax-return-invoices-table", {
                                attrs: {
                                  invoices:
                                    _vm.taxReturn.tables.tasa_0.invoices,
                                  title:
                                    "Actividades Gravadas a la tasa del 0%",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.taxReturn.tables.tasa_excentos.invoices.length > 0
                  ? _c(
                      "tr",
                      {
                        staticClass: "clickable",
                        on: {
                          click: function ($event) {
                            return _vm.toggleDisplayTable("tasa_excentos")
                          },
                        },
                      },
                      [
                        _c(
                          "td",
                          [
                            _c("font-awesome-icon", {
                              staticClass: "icon",
                              attrs: { icon: "arrow-down" },
                            }),
                            _vm._v(
                              "\n              Actividades Exentas\n            "
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: { amount: _vm.taxReturn.amounts.excentos },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.displayTables.tasa_excentos &&
                _vm.taxReturn.tables.tasa_excentos.invoices.length > 0
                  ? _c("tr", [
                      _c(
                        "td",
                        { attrs: { colspan: "2" } },
                        [
                          _vm.taxReturn.tables.tasa_excentos
                            ? _c("tax-return-invoices-table", {
                                attrs: {
                                  invoices:
                                    _vm.taxReturn.tables.tasa_excentos.invoices,
                                  title: "Actividades Exentas",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("IVA a Cargo a la Tasa del 16%")]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: { amount: _vm.taxReturn.amounts.iva_caused },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Total de IVA a Cargo")]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: { amount: _vm.taxReturn.amounts.iva_caused },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v("\n              IVA Retenido\n            "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: { amount: _vm.taxReturn.amounts.iva_detained },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.displayTables.iva_retenido
                  ? _c("tr", [
                      _c(
                        "td",
                        { attrs: { colspan: "2" } },
                        [
                          _vm.taxReturn.tables.retencion_iva
                            ? _c("tax-return-invoices-table", {
                                attrs: {
                                  invoices:
                                    _vm.taxReturn.tables.retencion_iva.invoices,
                                  title: "IVA Retenido",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "tr",
                  {
                    staticClass: "clickable",
                    on: {
                      click: function ($event) {
                        return _vm.toggleDisplayTable("creditablePeriod")
                      },
                    },
                  },
                  [
                    _c(
                      "td",
                      [
                        _c("font-awesome-icon", {
                          staticClass: "icon",
                          attrs: { icon: "arrow-down" },
                        }),
                        _vm._v(
                          "\n              IVA Acreditable del Periodo\n            "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "text-right" },
                      [
                        _c("money-text", {
                          attrs: {
                            amount: _vm.taxReturn.amounts.iva_acreditable,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.displayTables.creditablePeriod
                  ? _c("tr", [
                      _c(
                        "td",
                        { attrs: { colspan: "2" } },
                        [
                          _c("tax-return-creditable-period", {
                            attrs: {
                              taxReturn: _vm.taxReturn,
                              title: "Ingresos",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(
                      "\n              IVA por devoluciones, descuentos y bonificaciones en gastos\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.taxReturn.amounts.returns_and_expenses_value,
                          expression:
                            "taxReturn.amounts.returns_and_expenses_value",
                        },
                      ],
                      staticClass: "w-50",
                      attrs: { type: "number" },
                      domProps: {
                        value: _vm.taxReturn.amounts.returns_and_expenses_value,
                      },
                      on: {
                        keydown: _vm.onKeyDown,
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.actualizacion.apply(null, arguments)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.taxReturn.amounts,
                            "returns_and_expenses_value",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("IVA a Cargo")]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: { amount: _vm.taxReturn.amounts.iva_in_charge },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Acreditamiento")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.taxReturn.amounts.accreditation_value,
                          expression: "taxReturn.amounts.accreditation_value",
                        },
                      ],
                      staticClass: "w-50",
                      attrs: { type: "number" },
                      domProps: {
                        value: _vm.taxReturn.amounts.accreditation_value,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.toggleModalAcreditamiento()
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.taxReturn.amounts,
                            "accreditation_value",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Impuesto a Cargo")]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: { amount: _vm.taxReturn.amounts.tax_charge },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("tax_returns.update")) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.taxReturn.amounts.iva_update_value,
                          expression: "taxReturn.amounts.iva_update_value",
                        },
                      ],
                      attrs: { type: "number" },
                      domProps: {
                        value: _vm.taxReturn.amounts.iva_update_value,
                      },
                      on: {
                        keydown: _vm.onKeyDown,
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.actualizacion.apply(null, arguments)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.taxReturn.amounts,
                            "iva_update_value",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("tax_returns.surcharges")) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.taxReturn.amounts.iva_surcharge_value,
                          expression: "taxReturn.amounts.iva_surcharge_value",
                          modifiers: { number: true },
                        },
                      ],
                      attrs: { type: "number" },
                      domProps: {
                        value: _vm.taxReturn.amounts.iva_surcharge_value,
                      },
                      on: {
                        keydown: _vm.onKeyDown,
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.actualizacion.apply(null, arguments)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.taxReturn.amounts,
                            "iva_surcharge_value",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Total de Contribuciones")]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: { amount: _vm.taxReturn.amounts.ivaCargoFavor },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Pago de lo indebido")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.taxReturn.amounts.payment_undue_value,
                          expression: "taxReturn.amounts.payment_undue_value",
                        },
                      ],
                      staticClass: "w-50",
                      attrs: { type: "number" },
                      domProps: {
                        value: _vm.taxReturn.amounts.payment_undue_value,
                      },
                      on: {
                        keydown: _vm.onKeyDown,
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.actualizacion.apply(null, arguments)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.taxReturn.amounts,
                            "payment_undue_value",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Total a pagar de IVA")]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: { amount: _vm.taxReturn.amounts.total_iva },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card p-4" }, [
          _c("div", { staticClass: "table-responsive" }, [
            _c("table", { staticClass: "table table-bordered" }, [
              _vm._m(1),
              _vm._v(" "),
              _c("tbody", [
                _c("tr", [
                  _c("td", [_vm._v("ISR")]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: {
                          amount: _vm.taxReturn.amounts.cantidadPagarIsr,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("IVA")]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: { amount: _vm.taxReturn.amounts.ivaCargoFavor },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Total")]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: { amount: _vm.taxReturn.amounts.total },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", [
        _vm._v(
          "\n              IVA no cobrado por devoluciones, descuentos y bonificaciones de\n              ventas >\n            "
        ),
      ]),
      _vm._v(" "),
      _c("td", { staticClass: "text-right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", { staticClass: "table-primary" }, [
        _c("th", { staticClass: "text-center", attrs: { colspan: "2" } }, [
          _vm._v("Impuestos por Pagar"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }