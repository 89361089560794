<template>
  <div class="main-content invoice-create-page">
    <form @submit.prevent="submit">
      <div class="page-header pt-5">
        <h3 class="page-title">
          {{ $t('payments.invoice') }}
        </h3>
      </div>

      <div class="row invoice-input-group">
        <!-- Receiver name -->
        <div class="col invoice-input">
          <label>
            {{ $t('payments.name') }} <span class="text-danger"> * </span>
          </label>
          <base-input
            v-model="invoiceForm.receiverName"
            :invalid="$v.invoiceForm.receiverName.$error"
            icon="user"
            type="text"
          />
          <div v-if="$v.invoiceForm.receiverName.$error">
            <span
              v-if="!$v.invoiceForm.receiverName.required"
              class="text-danger"
            >
              {{ $t('validation.required') }}
            </span>
          </div>
        </div>

        <!-- Expedition place -->
        <div class="col invoice-input">
          <label>{{ $t('customers.sat_settings.expedition_place') }}</label>
          <base-input
            v-model="invoiceForm.expeditionPlace"
            :invalid="$v.invoiceForm.expeditionPlace.$error"
            icon="hashtag"
            type="text"
            disabled
          />
          <div v-if="$v.invoiceForm.expeditionPlace.$error">
            <span
              v-if="!$v.invoiceForm.expeditionPlace.required"
              class="text-danger"
            >
              {{ $t('validation.required') }}
            </span>
          </div>
        </div>

        <!-- Use CFDI -->
        <div class="col invoice-input">
          <label>
            {{ $t('customers.sat_settings.use_cfdi') }}
          </label>
          <base-select
            v-model="invoiceForm.receiverCfdiUse"
            :options="useCFDIOptions"
            :searchable="true"
            :allow-empty="false"
            :show-labels="false"
            :placeholder="$t('customers.sat_settings.use_cfdi')"
            label="description"
            track-by="id"
          />
        </div>
      </div>

      <div class="row">
        <!-- Receiver RFC -->
        <div class="col invoice-input">
          <label>
            {{ $t('payments.rfc') }} <span class="text-danger"> * </span>
          </label>
          <base-input
            v-model="invoiceForm.receiverRfc"
            :invalid="$v.invoiceForm.receiverRfc.$error"
            icon="user"
            type="text"
          />
          <div v-if="$v.invoiceForm.receiverRfc.$error">
            <span
              v-if="!$v.invoiceForm.receiverRfc.required"
              class="text-danger"
            >
              {{ $t('validation.required') }}
            </span>
          </div>
        </div>

        <!-- Payment method -->
        <div class="col invoice-input">
          <label>
            {{ $t('invoices.payment_method') }}
          </label>
          <base-select
            v-model="invoiceForm.paymentMethod"
            :options="paymentMethodsOptions"
            :searchable="true"
            :allow-empty="false"
            :show-labels="false"
            :placeholder="$t('invoices.payment_method')"
            label="description"
            track-by="id"
          />
        </div>

        <!-- Way to pay -->
        <div class="col invoice-input">
          <label>
            {{ $t('invoices.way_to_pay') }}
          </label>
          <base-select
            v-model="invoiceForm.paymentForm"
            :options="wayToPayOptions"
            :searchable="true"
            :allow-empty="false"
            :show-labels="false"
            :placeholder="$t('invoices.way_to_pay')"
            :invalid="$v.invoiceForm.paymentForm.$error"
            label="description"
            track-by="id"
          />
          <div v-if="$v.invoiceForm.paymentForm.$error">
            <span
              v-if="!$v.invoiceForm.paymentForm.required"
              class="text-danger"
            >
              {{ $t('validation.required') }}
            </span>
          </div>
        </div>
      </div>
      <br/>
      <div class="row">
        <!-- Zip code receptor fiscal-->
        <div class="col invoice-input">
          <label>Código Postal Registrado del Receptor Fiscal</label>
          <base-input
            v-model="invoiceForm.taxZipCodeReceptorFiscal"
            :invalid="$v.invoiceForm.taxZipCodeReceptorFiscal.$error"
            icon="hashtag"
            type="text"
          />
          <div v-if="$v.invoiceForm.taxZipCodeReceptorFiscal.$error">
            <span
              v-if="!$v.invoiceForm.taxZipCodeReceptorFiscal.required"
              class="text-danger"
            >
              {{ $t('validation.required') }}
            </span>
          </div>
        </div>
        <div class="col invoice-input"></div>
        <div class="col invoice-input"></div>
      </div>

      <!-- Row items -->
      <table class="item-table mt-5">
        <colgroup>
          <col style="width: 20%;" />
          <col style="width: 10%;" />
          <col style="width: 10%;" />
          <col style="width: 20%;" />
          <col style="width: 10%;" />
          <col style="width: 10%;" />
          <col style="width: 10%;" />
        </colgroup>
        <thead class="item-table-header">
          <tr>
            <th class="text-left">
              <span class="column-heading item-heading">
                {{ $tc('items.item', 2) }}
              </span>
            </th>
            <th class="text-center">
              <span class="column-heading">
                {{ $t('invoices.item.clave_prod_serv') }}
              </span>
            </th>
            <th class="text-center">
              <span class="column-heading">
                {{ $t('invoices.item.quantity') }}
              </span>
            </th>
            <th class="text-center">
              <span class="column-heading">
                {{ $t('invoices.item.unit_code') }}
              </span>
            </th>
            <th class="text-left">
              <span class="column-heading">
                {{ $t('invoices.item.price') }}
              </span>
            </th>
            <th class="text-right">
              <span class="column-heading">
                {{ $t('invoices.iva') }}
              </span>
            </th>
            <th class="text-right">
              <span class="column-heading amount-heading">
                {{ $t('invoices.item.total') }}
              </span>
            </th>
          </tr>
        </thead>
        <tbody class="item-body">
          <general-public-invoice-item
            v-for="(item, index) in invoiceForm.items"
            :key="item.id"
            :index="index"
            :item-data="item"
            :items="invoiceForm.items"
            @remove="removeItem"
            @update="updateItem"
            @itemValidate="checkItemsData"
          />
        </tbody>
      </table>
      <div class="add-item-action" @click="addEmptyRow">
        <font-awesome-icon icon="shopping-basket" class="mr-2" />
        {{ $t('invoices.add_item') }}
      </div>

      <div class="row mt-3 invoice-foot">
        <div class="col" />
        <div class="col" />
        <div class="col">
          <div class="invoice-total">
            <!-- Subtotal -->
            <div class="section mt-3">
              <label class="invoice-label">
                {{ $t('invoices.sub_total') }}
              </label>
              <label class="invoice-amount">
                <div v-html="$utils.formatMoney(subtotal, defaultCurrency)" />
              </label>
            </div>

            <!-- Iva -->
            <div class="section border-top mt-3">
              <label class="invoice-label">{{ $t('invoices.iva') }}</label>
              <label class="invoice-amount">
                <div v-html="$utils.formatMoney(iva, defaultCurrency)" />
              </label>
            </div>

            <!-- Total -->
            <div class="section border-top mt-3">
              <label class="invoice-label">{{ $t('invoices.total') }}</label>
              <label class="invoice-amount">
                <div v-html="$utils.formatMoney(total, defaultCurrency)" />
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-6 col-md-4 offset-md-8 flex-content-right">
          <base-button
            :loading="isLoading"
            :disabled="isLoading"
            icon="save"
            color="theme"
            class="invoice-action-btn"
            type="submit"
          >
            {{ $t('payments.general_public_invoices.make_invoice') }}
          </base-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import Guid from 'guid'
import GeneralPublicInvoiceItem from './GeneralPublicInvoiceItem'
const defaultUseCFDI = 'P01'
const defaultPaymentmethod = 'PUE'
const defaultWayToPay = '01'

export default {
  components: { GeneralPublicInvoiceItem },
  mixins: [validationMixin],
  data: () => ({
    isLoading: false,
    invoicingType: '',
    payments: [],
    transactions: [],
    satSettings: {},
    invoiceForm: {
      receiverName: '',
      receiverRfc: '',
      receiverCfdiUse: null,
      currency: 'MXN',
      expeditionPlace: '',
      taxZipCodeReceptorFiscal: '',
      cfdiType: 'I',
      paymentForm: null,
      paymentMethod: null,
      items: [],
    },
  }),
  validations: () => ({
    invoiceForm: {
      receiverName: { required },
      receiverRfc: { required },
      paymentForm: { required },
      expeditionPlace: { required },
      taxZipCodeReceptorFiscal: { required },
    },
  }),
  computed: {
    ...mapGetters('catalogs', [
      'useCFDI',
      'paymentMethods',
      'wayToPay',
      'searchUseCFDI',
      'searchPaymentMethod',
      'searchWayToPay',
      'valuesIva',
      'searchValueIva',
    ]),
    ...mapGetters('CurrentCustomer', ['currentCustomer']),
    ...mapGetters('calendar', ['defaultYear', 'defaultMonth']),
    ...mapGetters('currency', ['defaultCurrency']),
    useCFDIOptions() {
      if (!this.currentCustomer) {
        return []
      }
      const typePerson =
        this.currentCustomer.rfc.length == 12 ? 'moral' : 'physical'
      const useCFIDCatalog = this.useCFDI.filter((item) => {
        if (typePerson == 'moral') {
          return item.moral
        }
        if (typePerson == 'physical') {
          return item.physical
        }
      })

      return useCFIDCatalog.map((item) => {
        return {
          id: item.id,
          description: `${item.id} - ${item.description}`,
        }
      })
    },
    paymentMethodsOptions() {
      return this.paymentMethods.map((item) => {
        return {
          ...item,
          description: `${item.id} - ${item.description}`,
        }
      })
    },
    wayToPayOptions() {
      return this.wayToPay.map((item) => {
        return {
          ...item,
          description: `${item.id} - ${item.description}`,
        }
      })
    },
    subtotal() {
      const subtotal = this.invoiceForm.items.reduce((accumulator, item) => {
        return accumulator + Number(item.subtotal)
      }, 0)
      return subtotal * 100
    },
    iva() {
      return this.invoiceForm.items.reduce((accumulator, item) => {
        /* if (item.ivaType == 'percentage') {
          const iva = item.ivaAmount;
          return iva * 100
        } */
        return accumulator + (Number(item.ivaAmount)*100) 
      }, 0)
    },
    total() {
      return this.subtotal + this.iva
    },
  },
  created() {
    this.invoicingType = this.$route.params.type
  },
  mounted() {
    this.loadSatSettings()
    this.setDefaultsToInvoiceForm()
  },
  methods: {
    ...mapActions('generalPublicInvoice', [
      'fetchPaymentForInvoicing',
      'fetchTransactionsForInvoicing',
      'createInvoice',
    ]),
    ...mapActions('customer', ['fetchCustomerSatSettings']),
    loadSatSettings() {
      this.fetchCustomerSatSettings(this.currentCustomer.id)
        .then((response) => {
          const satSettings = response.data.sat_settings
          this.satSettings = satSettings
          this.invoiceForm.expeditionPlace = satSettings.cp
          const useCFDI = this.searchUseCFDI(
            satSettings.use_cfdi || defaultUseCFDI
          )
          this.invoiceForm.receiverCfdiUse = {
            id: useCFDI.id,
            description: `${useCFDI.id} - ${useCFDI.description}`,
          }
          this.loadItems()
        })
        .catch(() => {})
    },
    loadItems() {
      switch (this.invoicingType) {
        case 'payments':
          this.loadPaymentsForInvoicing()
          break
        case 'transactions':
          this.loadTransactionsForInvoicing()
          break
        default:
          this.addEmptyRow()
          break
      }
    },
    loadPaymentsForInvoicing() {
      const params = {
        customer: this.currentCustomer.id,
        year: this.defaultYear,
        month: this.defaultMonth.id,
      }
      this.fetchPaymentForInvoicing(params)
        .then((response) => {
          const payments = response.data.payments
          if (payments.length == 0) {
            return this.addEmptyRow()
          }
          this.payments = payments
          payments.forEach((payment) => {
            let description = this.satSettings.concept || 'Pago en efectivo'
            if (payment.payment_number) {
              description = `${payment.payment_number} - ${description}`
            }
            this.invoiceForm.items.push({
              guid: Guid.raw(),
              productCode: this.satSettings.prodserv_invoicing || '',
              description: description,
              unitCode: '',
              unitCodes: JSON.parse(this.satSettings.unit_codes),
              productServiceCodes: JSON.parse(this.satSettings.product_service_codes),
              unitPrice: payment.amount / 100,
              quantity: 1,
              subtotal: payment.amount / 100,
              total: payment.amount / 100,
              ivaAmount: 0,
              ivaType: 'percentage',
              valid: false,
              payment_id: payment.id,
            })
          })
        })
        .catch(() => {})
    },
    loadTransactionsForInvoicing() {
      const params = {
        customer: this.currentCustomer.id,
        year: this.defaultYear,
        month: this.defaultMonth.id,
      }
      this.fetchTransactionsForInvoicing(params)
        .then((response) => {
          const transactions = response.data.transactions
          if (transactions.length == 0) {
            return this.addEmptyRow()
          }
          transactions.forEach((transaction) => {
            const amount =
              transaction.payments_amount - transaction.payments_completed

            this.invoiceForm.items.push({
              guid: Guid.raw(),
              productCode: this.satSettings.prodserv_invoicing || '',
              description: transaction.name,
              unitCode: '',
              unitCodes: JSON.parse(this.satSettings.unit_codes),
              productServiceCodes: JSON.parse(this.satSettings.product_service_codes),
              unitPrice: amount / 100,
              quantity: 1,
              subtotal: amount / 100,
              total: amount / 100,
              ivaAmount: 0,
              ivaType: 'fixed',
              valid: false,
              transaction_id: transaction.id,
            })
          })
        })
        .catch(() => {})
    },

    addEmptyRow() {
      this.invoiceForm.items.push({
        guid: Guid.raw(),
        productCode: this.satSettings.prodserv_invoicing || '',
        description: this.satSettings.concept || '',
        unitCode: '',
        unitCodes: JSON.parse(this.satSettings.unit_codes),
        productServiceCodes: JSON.parse(this.satSettings.product_service_codes),
        unitPrice: 0,
        quantity: 1,
        subtotal: 0,
        total: 0,
        ivaAmount: 0,
        ivaType: 'percentage',
        valid: false,
      })
    },

    checkIfInvoiceFormIsValid() {
      this.$v.invoiceForm.$touch()
      window.hub.$emit('checkInvoicingItems')
      let isValid = true
      this.invoiceForm.items.forEach((item) => {
        if (!item.valid) {
          isValid = false
        }
      })
      if (this.$v.invoiceForm.$invalid === false && isValid === true) {
        return true
      }
      return false
    },

    removeItem(index) {
      this.invoiceForm.items.splice(index, 1)
    },
    updateItem(data) {
      Object.assign(this.invoiceForm.items[data.index], { ...data.item })
    },
    checkItemsData(index, isValid) {
      this.invoiceForm.items[index].valid = isValid
    },

    setDefaultsToInvoiceForm() {
      const paymentMethod = this.searchPaymentMethod(defaultPaymentmethod)
      this.invoiceForm.paymentMethod = {
        ...paymentMethod,
        description: `${paymentMethod.id} - ${paymentMethod.description}`,
      }
      const wayToPay = this.searchWayToPay(defaultWayToPay)
      this.invoiceForm.paymentForm = {
        ...wayToPay,
        description: `${wayToPay.id} - ${wayToPay.description}`,
      }
    },

    submit() {
      const formIsValid = this.checkIfInvoiceFormIsValid()
      if (!formIsValid) {
        return false
      }
      this.isLoading = true
      const invoiceForm = {
        invoice: {
          ...this.invoiceForm,
          receiverCfdiUse: this.invoiceForm.receiverCfdiUse.id,
          paymentForm: this.invoiceForm.paymentForm.id,
          paymentMethod: this.invoiceForm.paymentMethod.id,
        },
        customer: this.currentCustomer.id,
      }
      if (this.payments.length) {
        invoiceForm['payments'] = this.payments.map((payment) => payment.id)
      }
      if (this.transactions.length) {
        invoiceForm['transactions'] = this.transactions.map(
          (transaction) => transaction.id
        )
      }

      invoiceForm['invoice']['items'].map(function(unit){

        unit.unitCode = unit.unitCode.id;
        unit.productCode = unit.productCode.id;
        return unit
      }) 

      this.createInvoice(invoiceForm)
        .then((response) => {
          if (response.data.success) {
            window.toastr.success(
              this.$t(
                'payments.general_public_invoices.messages.invoice_created'
              )
            )
            this.$router.push({
              name: 'invoices.view',
              params: {
                id: response.data.invoice_id,
              },
            })
          } else {
            window.toastr.error(
              this.$t('payments.general_public_invoices.errors.invoice_create')
            )
            window.toastr.error(response.data.message)
          }
          this.isLoading = false
        })
        .catch(() => {
          window.toastr.error(
            this.$t('payments.general_public_invoices.errors.invoice_create')
          )
          this.isLoading = false
        })
    },
  },
}
</script>
