export const digitalPlatformOptions = [
  {
    key: 'servicio_transporte_pasajeros',
    description: 'Servicios de transporte terrestre.',
  },
  {
    key: 'servicio_prestacion_servicios',
    description: 'Prestación de servicios de hospedaje.',
  },
  {
    key: 'servicio_enajenacion_bienes',
    description: 'Enajenación de bienes y prestación de servicios.',
  },
]
