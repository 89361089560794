var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showIcon
    ? _c("font-awesome-icon", {
        directives: [
          {
            name: "tooltip",
            rawName: "v-tooltip",
            value: { content: _vm.messageTooltip },
            expression: "{ content: messageTooltip }",
          },
        ],
        staticClass: "text-danger",
        attrs: { icon: "exclamation-circle" },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }