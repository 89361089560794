<template>
  <div class="contracted main-content">
    <div class="page-header mt-4" style="z-index: 1">
      <h3 class="page-title">Períodos - Contratados</h3>
      <div class="btn-group" role="group">
        <!-- <base-button color="theme" class="mr-2" @click="openAddPaqueteDeclaraFoliosModal()">
              Declaraciones
            </base-button> -->
        <conditional-wrapper :show-for-admin="true" :show-for-executives="false" :show-for-customers="false">
          <base-button color="theme" @click="assignRoute()">
            Agregar / Modificar
          </base-button>
        </conditional-wrapper>
      </div>
    </div>

    <div v-for="(year, index) in yearsKeys" :key="year">

      <b-card-header header-tag="header" role="tab">
        <div class="pl-2" v-b-toggle="'collapse-year-' + index" variant="primary">

          {{ year }}
        </div>
      </b-card-header>

      <b-collapse :id="'collapse-year-' + index" class="my-2" :visible="index == 0">
        <!-- 
         colapasabl de los años -->

        <div v-for="(status, indexStatus) in statusYearKey(`${year}`)" :key="status">
          <b-card-header header-tag="header" class="bg-primary text-white" role="tab" variant="primary">
            <div class="pl-2" v-b-toggle="'collapse-status-' + indexStatus+'index'+index">
              {{ status }}
            </div>
          </b-card-header>
          <b-collapse :id="'collapse-status-' + indexStatus+'index'+index" class="my-2" :visible="true">
            <div v-for="(statusYear, index) in statusYearValue(`${year}`, `${status}`)"
              :key="year + status + statusYear.month">
              <div class="pl-4 my-3" @click="
                statusYear.status.slug !== 'PAGADA' &&
                setCustomerToViewDetails(statusYear.year, statusYear.month, statusYear)
                " :class="statusYear.status.slug === 'PAGADA' ? 'disabled' : ''">
                <font-awesome-icon :icon="['fas', statusYear.status.icon]" class="icon menu-icon mr-2"
                  :style="{ color: '#88C349' }" size="1x" v-tooltip="{ content: status }" />
                {{ statusYear.month }} - {{ statusYear.year }}
              </div>
            </div>
          </b-collapse>
        </div>

      </b-collapse>

    </div>



    <!-- <div v-for="(estado, index) in status" :key="estado">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <div class="pl-2" v-b-toggle="'collapse-' + index" variant="primary" @click="cardSelect(index)">

            {{ estado }}
          </div>
        </b-card-header>

        <b-collapse :id="'collapse-' + index" class="mt-2" :visible="true">
          <div class="pl-4 my-3" v-for="(mes, mesIndex) in monthsByYear[estado]" @click="
            mes.status.slug !== 'PAGADA' &&
            setCustomerToViewDetails(mes.year, mes.month, mes)
            " :key="mesIndex + mes" :class="mes.status.slug === 'PAGADA' ? 'disabled' : ''">
            <font-awesome-icon :icon="['fas', mes.status.icon]" class="icon menu-icon mr-2" :style="{ color: '#88C349' }"
              size="1x" v-tooltip="{ content: estado }" />
            {{ mes.month }} - {{ mes.year }}
          </div>
        </b-collapse>
      </b-card>
    </div> -->

    <!-- <el-collapse
      v-model="activeNames"
      @change="handleChange"
      class="accordion-color"
    >

      <el-collapse-item
        v-for="(year, index) in years"
        :title="year"
        :name="index + 1 + year"
        :key="year"
      >

        <div v-for="(mes, mesIndex) in meses" :key="mesIndex + mes">
           {{ mes }}
        </div>

      </el-collapse-item>

    </el-collapse> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { StatusAssigned } from '../../store/modules/assigned/enum'
import ConditionalWrapper from '../components/ConditionalWrapper.vue'

export default {
  components: {
    ConditionalWrapper,
  },

  data: () => ({
    status: [],
    monthsByYear: [],
    taxReturn: {},
    meses: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ],

    selectIndex: 0,
    customer: null,
  }),
  watch: {
    // Observa la propiedad CurrentCustomer
    currentCustomer: {
      handler: 'fetchDataOnCurrentCustomerChange',
      immediate: true, // Llama inmediatamente al cargarse el componente.
    },
  },
  computed: {
    ...mapGetters('CurrentCustomer', ['currentCustomer']),

    yearsKeys() {
      return Object.keys(this.taxReturn).reverse();
    }
    ,

  },

  methods: {
    ...mapActions('CurrentCustomer', ['setCurrentCustomer']),
    ...mapActions('assigned', [
      'fetchAssignedDeclaration',
      'setAssignedSelect',
      'updateStatusAssignedDeclaration',
    ]),

    ...mapActions('calendar', ['setYear', 'setMonth']),
    ...mapGetters('calendar', ['months', 'defaultMonth', 'defaultYear']),
    async fetchDataOnCurrentCustomerChange(newCustomer) {
      if (newCustomer) {
        this.fetchAssignedDeclaration(newCustomer.id).then((response) => {
          // this.monthsByYear = response.monthsByYear
          // this.status = response.status
          this.taxReturn = response;
        })

        // this.fetchAssigned(newCustomer.id)
      }
    },
    assignRoute() {
      // this.setCurrentCustomer(customer)
      this.$router.push({
        path: `/general/customers/${this.currentCustomer.id}/contratacion`,
      })
    },
    setCustomerToViewDetails(year, month, item) {
      if (item.status.slug === StatusAssigned.NOINICIADAS) {
        this.updateStatusAssignedDeclaration({
          id: item.id,
          slug: StatusAssigned.ENPROCESO,
        }).then((res) => {
          this.setAssignedSelect(res.data.declaration)
        })
      } else {
        this.setAssignedSelect(item)
      }
      this.setYear(year)
      this.setMonth(month)
      this.$router.push('/general/payments')
    },
    cardSelect(index) {
      if (this.selectIndex == index) {
        this.selectIndex = -1
      } else {
        this.selectIndex = index
      }
    },

    statusYearKey(key) {
      return Object.keys(this.taxReturn[key]);
    },
    statusYearValue(year, status) {
      return this.taxReturn[year][status]
    }
  },
}
</script>
