export const fetchRateTables = (store, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/rate-tables/${data.year}/${data.taxRegime}`, {
        tables: data.tables,
      })
      .then(resolve)
      .catch(reject)
  })
}

export const fetchSegments = () => {
  return new Promise((resolve, reject) => {
    window.axios.get(`/api/rate-tables/segments`).then(resolve).catch(reject)
  })
}

export const saveRateTable = (store, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/rate-tables/store`, data)
      .then(resolve)
      .catch(reject)
  })
}
export const createRateTables = (store, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/rate-tables/create-tables`, data)
      .then(resolve)
      .catch(reject)
  })
}
