<template>
  <div class="pt-4 pb-4 pl-4 pr-4">
    <div class="row">
      <div class="col">
        <label for="input1">Tipo</label>
        <input type="text" class="form-control" id="input1" placeholder="Ingrese valor" />
      </div>
      <div class="col">
        <label for="input2">Periocidad</label>
        <input type="text" class="form-control" id="input2" placeholder="Ingrese valor" />
      </div>
      <div class="col">
        <label for="input3">Periodo</label>
        <input type="text" class="form-control" id="input3" placeholder="Ingrese valor" />
      </div>
      <div class="col">
        <label for="input4">Ejercicio</label>
        <input type="text" class="form-control" id="input4" placeholder="Ingrese valor" />
      </div>
    </div>

    <!-- Segunda fila con 1 input -->
    <div class="row mt-3">
      <div class="col">
        <label for="input5">Saldo a Aplicar</label>
        <input type="text" class="form-control" id="input5" placeholder="Ingrese valor" />
      </div>
    </div>

    <!-- Tercera fila con 2 inputs -->
    <div class="row mt-3">
      <div class="col">
        <label for="input6">Tipo de Declaración</label>
        <input type="text" class="form-control" id="input6" placeholder="Ingrese valor" />
      </div>
      <div class="col">
        <label for="input7">Número de Operación</label>
        <input type="text" class="form-control" id="input7" placeholder="Ingrese valor" />
      </div>
    </div>

    <!-- Cuarta fila con 2 inputs -->
    <div class="row mt-3">
      <div class="col">
        <label for="input8">Monto Original del Saldo a Favor</label>
        <input type="text" class="form-control" id="input8" placeholder="Ingrese valor" />
      </div>
      <div class="col">
        <label for="input9">Remanente Historico Antes de la Aplicación</label>
        <input type="text" class="form-control" id="input9" placeholder="Ingrese valor" />
      </div>
    </div>

    <!-- Quinta fila con 2 inputs -->
    <div class="row mt-3">
      <div class="col form-group">
        <label>Fecha en que se presento el Saldo a Favor</label>
        <base-date-picker v-model="form.payment_date" :calendar-button="true" :invalid="$v.form.payment_date.$error"
          calendar-button-icon="calendar" :typeable='true' @change="$v.form.payment_date.$touch()" />
        <div v-if="$v.form.payment_date.$error">
          {{ $t('validation.required') }}
        </div>
      </div>
      <div class="col">
        <label for="input11">Remanente Actualizado Antes de la Aplicación</label>
        <input type="text" class="form-control" id="input11" placeholder="Ingrese valor" />
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import decimalValidation from 'vuelidate/lib/validators/decimal'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [validationMixin],

  data: () => ({
    form: {
      document_type: null,
      payment_date: '',
      amount: 0,
    },
  }),

  computed: {
    ...mapGetters('currency', ['defaultCurrency']),
    ...mapGetters('invoice', ['dataModalAddPaidDocument']),
    ...mapGetters('catalogs', [
      'paidDocumentOptions',
      'searchPaidDocumentOption',
    ]),

    customerCurrency() {
      if (this.defaultCurrency) {
        return {
          decimal: this.defaultCurrency.decimal_separator,
          thousands: this.defaultCurrency.thousand_separator,
          prefix: this.defaultCurrency.symbol + ' ',
          precision: this.defaultCurrency.precision,
          masked: false,
        }
      } else {
        return this.defaultCurrenctForInput
      }
    },
  },

  validations: {
    form: {
      document_type: {
        required,
      },
      payment_date: {
        required,
      },
      amount: {
        required,
        decimal: decimalValidation,
      },
    },
  },

  created() {
    this.form.document_type = this.searchPaidDocumentOption('direct_collection')
  },
  mounted() {
    this.form.payment_date = moment().toISOString()
    this.form.amount = this.dataModalAddPaidDocument.amount
  },

  methods: {
    ...mapActions('invoice', ['fetchSavePaidDocument']),
    ...mapActions('modal', ['closeModal']),

    savePaidDocument() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid !== false) {
        return
      }

      const data = {
        ...this.form,
        document_type: this.form.document_type.name,
        amount: Number(this.form.amount) * 100,
        invoice_id: this.dataModalAddPaidDocument.invoiceId,
      }

      this.fetchSavePaidDocument(data)
        .then((response) => {
          if (response.data.success) {
            window.toastr['success'](
              this.$t('invoices.messages.paid_document_added')
            )
            window.hub.$emit('paid_document_added')
            this.closeModal()
          } else {
            window.toastr['error'](
              this.$t('invoices.errors.cant_apply_payment')
            )
          }
        })
        .catch((err) => {
          window.toastr['error'](this.$t('invoices.errors.paid_document_add'))
        })
    },
  },
}
</script>
