<template>
    <tr @click="onClick" @mouseover="mouseOver($event)">
        <table-cell
            v-for="column in visibleColumns"
            :row="row"
            :column="column"
            :key="column.id"
            :responsive-label="column.label"
            @click="columnClick($event)"
            
        ></table-cell>
    </tr>
</template>

<script>
    import TableCell from './TableCell';

    export default {
        props: ['columns', 'row'],

        components: {
            TableCell,
        },

        computed: {
            visibleColumns() {
                return this.columns.filter(column => ! column.hidden);
            }
        },

        methods: {
            onClick(e) {
                this.$emit('rowClick', {
                    e,
                    row: this.row
                })
            },
            columnClick (column) {
                this.$emit('columnClick', {column})
            },
            mouseOver(event){
                this.$emit('mouseOver', {event})
            },
        }
    };
</script>
