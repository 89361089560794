<template>
  <div class="sidebar-left">
    <div class="sidebar-body scroll-pane">
      <div class="side-nav">
        <!-- <div v-if="currentCustomer" class="current-customer">

          <small>{{ $t('customers.current_customer') }}</small><br />
          {{ currentCustomer.rfc }}
          <font-awesome-icon :icon="!currentCustomer.status ? 'exclamation-triangle' : ''" class="right-icon"
            color="#eed202" v-tooltip="{ content: currentCustomer.textstatus }" />
          <br />
          {{ currentCustomer.name }}
          
          <br />
          <font-awesome-icon :icon="'user-minus'" class="right-icon" @click="deselectCustomer" color="red"
            v-tooltip="{ content: 'Quitar Usuario' }" />
          <br />
        </div> -->
        <!-- <template v-if="currentCustomer">
          <div v-for="(menuItems, index) in menu" :key="index + 'a'" class="menu-group">
            <router-link v-for="(item, index1) in menuItems" :key="index1 + 'a'" :to="item.route"
              :class="{ disabled: item.require_customer && !currentCustomer }" class="menu-item" @click.native="Toggle">
              <font-awesome-icon :icon="item.icon" class="icon menu-icon" /><span class="ml-3 menu-text">{{ $t(item.title)
              }}</span>
            </router-link>
          </div>

           <div>
            <button class="btn btn-primary btn-block" type="button" data-toggle="collapse" data-target="#collapseExample"
              aria-expanded="false" aria-controls="collapseExample">
              Generales
            </button>
          </div>
          <div class="collapse" id="collapseExample">
            <div class="card card-body">
              <div v-for="(menuItems, index2) in menu2" :key="index2 + 'b'" class="menu-group">
                <router-link v-for="(item, index1b) in menuItems" :key="index1b + 'b'" :to="item.route" :class="{
                  disabled: item.require_customer && !currentCustomer,
                }" class="menu-item" @click.native="Toggle">
                  <font-awesome-icon :icon="item.icon" class="icon menu-icon" /><span class="ml-3 menu-text">{{
                    $t(item.title) }}</span>
                </router-link>
              </div>
            </div>
          </div> 
        </template> -->

        <template>
          <!-- <div v-for="(menuItems, index2) in menu2" :key="index2 + 'b'" class="menu-group">
            <router-link v-for="(item, index1b) in menuItems" :key="index1b + 'b'" :to="item.route"
              :class="{ disabled: item.require_customer && !currentCustomer }" class="menu-item" @click.native="Toggle">
              <font-awesome-icon :icon="item.icon" class="icon menu-icon" /><span class="ml-3 menu-text">{{ $t(item.title)
              }}</span>
            </router-link>
          </div> -->
          <div
            class="flex-shrink-0 p-3 bg-white"
            v-for="(menuItems, index2) in menu2"
            :key="index2 + 'b'"
          >
            <ul class="list-unstyled ps-0">
              <li v-for="(item, index1b) in menuItems" class="mb-1">
                <!-- Verificar si el elemento tiene submenús -->
                <conditional-wrapper
                  :show-for-admin="item.admin"
                  :show-for-executives="item.ejecutivo"
                  :show-for-customers="item.supervisor"
                >
                  <template v-if="item.submenu">
                    <div
                      tag="router-link"
                      :class="{
                        disabled: item.require_customer && !currentCustomer,
                      }"
                      class="menu-item"
                      @click="toggleCollapse(item.id_collapse)"
                    >
                      <font-awesome-icon
                        :icon="item.icon"
                        class="icon menu-icon"
                      /><span class="ml-3 menu-text">{{ $t(item.title) }}</span>
                    </div>
                    <div
                      :class="{ collapse: !collapsed[item.id_collapse] }"
                      :id="item.id_collapse"
                    >
                      <ul
                        v-for="(item2, index1b) in item.submenu"
                        class="btn-toggle-nav list-unstyled fw-normal pb-1 small"
                      >
                        <router-link
                          :to="item2.route"
                          :class="{
                            disabled: item.require_customer && !currentCustomer,
                          }"
                          class="menu-item"
                          @click.native="Toggle"
                        >
                          <span class="ml-3 menu-text">{{
                            $t(item2.title)
                          }}</span>
                        </router-link>
                      </ul>
                    </div>
                  </template>
                  <!-- Si no tiene submenús, crear un enlace directo -->
                  <template v-else>
                    <router-link
                      :to="item.route"
                      :class="{
                        disabled: item.require_customer && !currentCustomer,
                      }"
                      class="menu-item"
                      @click.native="Toggle"
                    >
                      <font-awesome-icon
                        :icon="item.icon"
                        class="icon menu-icon"
                      /><span class="ml-3 menu-text">{{ $t(item.title) }}</span>
                    </router-link>
                  </template>
                </conditional-wrapper>
              </li>
              <!-- Otros elementos colapsables aquí -->
            </ul>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import { mapActions, mapGetters } from 'vuex'
import ConditionalWrapper from '../../components/ConditionalWrapper.vue'

export default {
  components: {
    ConditionalWrapper,
  },
  data() {
    return {
      currentRoute: '',
      sidebar: 'sidebar',
      collapsed: {
        'home-collapse': false, // Inicialmente colapsado
        'cliente-collapse': true,
        // Otros elementos colapsables aquí
      },

      menu2: [
        [
          {
            title: 'navigation.customers',
            icon: 'user',
            route: '/general/customers/pending',
            require_customer: false,
            admin: true,
            supervisor: true,
            ejecutivo: true,
          },

          {
            title: 'navigation.rate_tables',
            icon: 'file-alt',
            route: '/general/rate-tables/621',
            require_customer: false,
            admin: true,
            supervisor: true,
            ejecutivo: true,
          },

          {
            title: 'Paquetes',
            icon: 'file',
            route: '/general/paquetes',
            require_customer: false,
            admin: true,
            supervisor: false,
            ejecutivo: false,
          },
          {
            title: 'Usuarios',
            icon: 'user',
            route: '/general/users',
            require_customer: false,
            admin: true,
            supervisor: false,
            ejecutivo: false,
          },
        ],
      ],
    }
  },
  computed: {
    ...mapGetters('CurrentCustomer', ['currentCustomer']),
  },

  methods: {
    ...mapActions('CurrentCustomer', ['setCurrentCustomer']),
    Toggle() {
      this.$utils.toggleSidebar()
    },
    toggleCollapse(target) {
      this.collapsed[target] = !this.collapsed[target]
    },
    deselectCustomer() {
      this.setCurrentCustomer(null)
      this.$router.push('/general/customers/pending')
    },
  },
  mounted() {
    this.currentRoute = this.$route.path
    if (this.currentRoute.includes('customers')) {
      this.collapsed['cliente-collapse'] = true
    } else {
      this.collapsed['cliente-collapse'] = false
    }
  },
  watch: {
    $route(to, from) {
      this.currentRoute = to.path
      if (currentRoute.includes('customers')) {
        this.collapsed['cliente-collapse'] = true
      } else {
        this.collapsed['cliente-collapse'] = false
      }
    },
  },
}
</script>
