var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login-page login-3" }, [
    _c("div", { staticClass: "site-wrapper" }, [
      _c("div", { staticClass: "login-box" }, [
        _c(
          "div",
          { staticClass: "box-wrapper" },
          [_vm._m(0), _vm._v(" "), _c("router-view"), _vm._v(" "), _vm._m(1)],
          1
        ),
      ]),
      _vm._v(" "),
      _vm._m(2),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "logo-main" }, [
      _c("a", { attrs: { href: "/general" } }, [
        _c("img", {
          attrs: { src: "/assets/img/logo-taxati.png", alt: "Crater Logo" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page-copyright" }, [
      _c("p", [_vm._v("Strappberry - 2020")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content-box" }, [
      _c("div", { staticClass: "content-bottom" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }