import moment from 'moment';
import * as types from './mutation-types'
import ls from '../../../services/ls';

export const months = (state) => {
  return state.months
}

export const defaultMonth = (state) => {
  const stateMonth = state.month != '' ? state.month : ls.get(types.LOCAL_MONTH)

  return state.months.filter((month) => month.name == stateMonth)[0];
  // return state.month;
}

export const defaultYear = (state) => {
  const stateYear = state.year != '' ? state.year : ls.get(types.LOCAL_YEAR)

  return stateYear;

}

export const getMonthByNumer = (state) => (month) => {
  return state.months.find((m) => Number(m.id) == Number(month))
  // const filtered = state.months.filter((m) => Number(m.id) == Number(month))
  // if (filtered.length) {
  //   return filtered[0]
  // }
  // return null
}
