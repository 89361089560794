<template>
  <div v-if="taxReturn">
    <div class="card p-2">
      <table class="table table-bordered">
        <thead>
          <tr class="table-primary">
            <th colspan="2" class="text-center">
              {{ $t('tax_returns.amounts') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <!-- Ingresos -->
          <tr class="clickable" @click="toggleDisplayTable('revenue')">
            <td><font-awesome-icon class="icon" icon="arrow-down"/> {{ $t('tax_returns.revenue') }}</td>
            <td class="text-right">
              <money-text :amount="taxReturn.amounts.revenue_period" />
            </td>
          </tr>
          <tr v-if="displayTables.revenue">
            <td colspan="2">
              <tax-return-invoices-table
                v-if="taxReturn.tables.revenue"
                :invoices="taxReturn.tables.revenue.invoices"
                :totals="taxReturn.tables.revenue.totals"
                title="tax_returns.revenue"
              />
            </td>
          </tr>
          <!-- Deducciones -->
          <tr class="clickable" @click="toggleDisplayTable('taxDeductions')">
            <td><font-awesome-icon class="icon" icon="arrow-down"/> {{ $t('tax_returns.deductions') }}</td>
            <td class="text-right">
              <money-text :amount="taxReturn.amounts.deductions_period" />
            </td>
          </tr>
          <tr v-if="displayTables.taxDeductions">
            <td colspan="2">
              <tax-return-invoices-table
                v-if="taxReturn.tables.tax_deductions"
                :invoices="taxReturn.tables.tax_deductions.invoices"
                :totals="taxReturn.tables.tax_deductions.totals"
                title="tax_returns.deductions"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Table ISR -->
    <div class="card p-2">
      <table class="table table-bordered">
        <thead>
          <tr class="table-primary">
            <th colspan="2" class="text-center">
              {{ $t('tax_returns.isr') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <!-- Ingresos -->
          <tr>
            <td>{{ $t('tax_returns.revenue') }}</td>
            <td class="text-right">
              <money-text :amount="taxReturn.amounts.revenue_accumulated" />
            </td>
          </tr>
          <!-- Deducciones -->
          <tr>
            <td>{{ $t('tax_returns.deductions') }}</td>
            <td class="text-right">
              <money-text :amount="taxReturn.amounts.deductions_accumulated" />
            </td>
          </tr>
          <!-- Ingresos gravados -->
          <tr>
            <td>{{ $t('tax_returns.taxed_income') }}</td>
            <td class="text-right">
              <money-text :amount="taxReturn.amounts.taxed_income" />
            </td>
          </tr>
          <!-- PTU pagado en el ejercicio -->
          <tr>
            <td>{{ $t('tax_returns.ptu_paid') }}</td>
            <td class="text-right">
              <money-text :amount="taxReturn.amounts.ptu_paid" />
            </td>
          </tr>
          <!-- Perdidas fiscales -->
          <tr>
            <td>{{ $t('tax_returns.tax_loss') }}</td>
            <td class="text-right">
              <money-text :amount="taxReturn.amounts.tax_lost" />
            </td>
          </tr>
          <!-- Base gravable -->
          <tr>
            <td>{{ $t('tax_returns.taxable_base') }}</td>
            <td class="text-right">
              <money-text :amount="taxReturn.amounts.taxable_base" />
            </td>
          </tr>
          <!-- Limite inferior -->
          <tr>
            <td>
              {{ $t('rate_tables.lower_limit') }}
            </td>
            <td class="text-right">
              <money-text :amount="taxReturn.amounts.lower_limit" />
            </td>
          </tr>
          <!-- Excedente limite inferior -->
          <tr>
            <td>
              {{ $t('tax_returns.surplus_lower_limit') }}
            </td>
            <td class="text-right">
              <money-text :amount="taxReturn.amounts.surplus_lower_limit" />
            </td>
          </tr>
          <!-- Tasa S/Excedente -->
          <tr>
            <td>
              {{ $t('tax_returns.rate_on_surplus') }}
            </td>
            <td class="text-right">
              {{ taxReturn.amounts.rate_on_surplus }} %
            </td>
          </tr>
          <!-- Importe marginal -->
          <tr>
            <td>
              {{ $t('tax_returns.marginal_amount') }}
            </td>
            <td class="text-right">
              <money-text :amount="taxReturn.amounts.marginal_amount" />
            </td>
          </tr>
          <!-- Cuota fija -->
          <tr>
            <td>
              {{ $t('rate_tables.fixed_fee') }}
            </td>
            <td class="text-right">
              <money-text :amount="taxReturn.amounts.fixed_fee" />
            </td>
          </tr>
          <!-- ISR Causado -->
          <tr>
            <td>
              {{ $t('tax_returns.isr_caused') }}
            </td>
            <td class="text-right">
              <money-text :amount="taxReturn.amounts.isr_caused" />
            </td>
          </tr>
          <!-- ISR retenido -->
          <tr>
            <td>
              {{ $t('tax_returns.isr_detained') }}
            </td>
            <td class="text-right">
              <money-text :amount="taxReturn.amounts.isr_detained" />
            </td>
          </tr>
          <!-- ISR total -->
          <tr>
            <td>
              {{ $t('tax_returns.isr_total') }}
            </td>
            <td class="text-right">
              <money-text :amount="taxReturn.amounts.isr_total" />
            </td>
          </tr>
          <!-- ISR a favor -->
          <tr>
            <td>
              {{ $t('tax_returns.isr_in_favor') }}
            </td>
            <td class="text-right">
              <money-text :amount="taxReturn.amounts.isr_in_favor" />
            </td>
          </tr>
          <!-- Pagos provisionales efectuados -->
          <tr>
            <td>
              {{ $t('tax_returns.previous_provisional_paid') }}
            </td>
            <td class="text-right">
              <money-text :amount="taxReturn.amounts.isr_previous_paid" />
            </td>
          </tr>
          <!-- ISR a pagar -->
          <tr>
            <td>
              {{ $t('tax_returns.isr_to_pay') }}
            </td>
            <td class="text-right">
              <money-text :amount="taxReturn.amounts.isr_to_pay" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Table IVA -->
    <div class="card p-2">
      <table class="table table-bordered">
        <thead>
          <tr class="table-primary">
            <th colspan="2" class="text-center">
              {{ $t('tax_returns.iva') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <!-- Ingresos -->
          <tr class="clickable" @click="toggleDisplayTable('revenue')">
            <td><font-awesome-icon class="icon" icon="arrow-down"/> {{ $t('tax_returns.income_which_to_charge_iva_16') }}</td>
            <td class="text-right">
              <money-text :amount="taxReturn.amounts.revenue_period" />
            </td>
          </tr>
          <tr v-if="displayTables.revenue">
            <td colspan="2">
              <tax-return-invoices-table
                v-if="taxReturn.tables.revenue"
                :invoices="taxReturn.tables.revenue.invoices"
                :totals="taxReturn.tables.revenue.totals"
                title="tax_returns.income_which_to_charge_iva_16"
              />
            </td>
          </tr>
          <!-- IVA cobrado -->
          <tr>
            <td>
              {{ $t('tax_returns.iva_charged') }}
            </td>
            <td class="text-right">
              <money-text :amount="taxReturn.amounts.iva_charged" />
            </td>
          </tr>
          <!-- IVA retenido -->
          <tr>
            <td>
              {{ $t('tax_returns.iva_detained') }}
            </td>
            <td class="text-right">
              <money-text :amount="taxReturn.amounts.iva_detained" />
            </td>
          </tr>
          <!-- IVA compras y gastos -->
          <tr>
            <td>
              {{ $t('tax_returns.iva_creditable_for_expenses') }}
            </td>
            <td class="text-right">
              <money-text :amount="taxReturn.amounts.iva_expenses" />
            </td>
          </tr>
          <!-- IVA a cargo -->
          <tr>
            <td>
              {{ $t('tax_returns.amount_in_charge') }}
            </td>
            <td class="text-right">
              <money-text :amount="taxReturn.amounts.iva_in_charge" />
            </td>
          </tr>
          <!-- IVA a favor -->
          <tr>
            <td>
              {{ $t('tax_returns.iva_in_favor') }}
            </td>
            <td class="text-right">
              <money-text :amount="taxReturn.amounts.iva_in_favor" />
            </td>
          </tr>
          <!-- IVA Total -->
          <tr>
            <td>
              {{ $t('tax_returns.iva_total') }}
            </td>
            <td class="text-right">
              <money-text :amount="taxReturn.amounts.iva_total" />
            </td>
          </tr>
          <!-- IVA acreditable -->
          <tr>
            <td>
              {{ $t('tax_returns.iva_creditable') }}
            </td>
            <td class="text-right">
              <money-text :amount="taxReturn.amounts.iva_creditable" />
            </td>
          </tr>
          <!-- IVA a pagar -->
          <tr>
            <td>
              {{ $t('tax_returns.iva_to_pay') }}
            </td>
            <td class="text-right">
              <money-text :amount="taxReturn.amounts.iva_to_pay" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import TaxReturnInvoicesTable from './TaxReturnInvoicesTable.vue'
export default {
  components: { TaxReturnInvoicesTable },
  props: {
    taxReturn: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    displayTables: {
      revenue: false,
      purchases: false,
      taxDeductions: false,
    },
  }),
  methods: {
    toggleDisplayTable(table) {
      this.displayTables[table] = !this.displayTables[table]
    },
  },
}
</script>

<style lang="scss" scoped>
.clickable {
  td {
    font-weight: 500;
  }
}
</style>
