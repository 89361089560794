<template>
  <div class="main-content">
    <form @submit.prevent="saveCertificates">
      <!-- FIEL -->
      <div class="card">
        <div class="card-body">
          <div class="section-title" style="width: 100%;">
            <h5>{{ $t('customers.fiel') }}</h5>
          </div>
          <!-- Table with fiel info -->
          <div class="row">
            <div v-if="certificates.fiel" class="col-12 mb-3 table-certificate">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>{{ $t('customers.certificate_number') }}</th>
                      <th>{{ $t('customers.certificate_expiration') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ certificates.fiel.number }}</td>
                      <td>{{ certificates.fiel.expires_at }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!-- Form Fiel Inputs -->
          <div class="row">
            <div class="form-group col-md-4">
              <label for="description">
                {{ $t('customers.access_file_cer') }} :
              </label>
              <div class="image-upload-box">
                <input
                  ref="e_sign_cer"
                  class=""
                  type="file"
                  accept=".cer"
                  @change="onESignCerChange"
                />
              </div>
            </div>
            <div class="form-group col-md-4">
              <label for="description">
                {{ $t('customers.access_file_key') }}:
              </label>
              <div class="image-upload-box">
                <input
                  ref="e_sign_key"
                  class=""
                  type="file"
                  accept=".key"
                  @change="onESignKeyChange"
                />
              </div>
            </div>
            <div class="form-group col-md-4">
              <label class="form-label">
                {{ $t('customers.fiel_password') }}
              </label>
              <base-input
                v-model="formData.sat_password"
                focus
                type="password"
                name="fiel_password"
                tab-index="1"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- CSD -->
      <div class="card">
        <div class="card-body">
          <div class="section-title" style="width: 100%;">
            <h5>{{ $t('customers.digital_seal_certificate') }}</h5>
          </div>

          <!-- Table with csd info -->
          <div class="row">
            <div v-if="certificates.csd" class="col-12 mb-3 table-certificate">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>{{ $t('customers.certificate_number') }}</th>
                      <th>{{ $t('customers.certificate_expiration') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ certificates.csd.number }}</td>
                      <td>{{ certificates.csd.expires_at }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-md-4">
              <label for="description">
                {{ $t('customers.digital_stamp_cer') }} :
              </label>
              <div class="image-upload-box">
                <input
                  ref="digital_stamp_cer"
                  class=""
                  type="file"
                  accept=".cer"
                  @change="onDigitalCerChange"
                />
              </div>
            </div>
            <div class="form-group col-md-4">
              <label for="description">
                {{ $t('customers.digital_stamp_key') }} :
              </label>
              <div class="image-upload-box">
                <input
                  ref="digital_stamp_key"
                  class=""
                  type="file"
                  accept=".key"
                  @change="onDigitalKeyChange"
                />
              </div>
            </div>
            <div class="form-group col-md-4">
              <label class="form-label">
                {{ $t('customers.csd_password') }}
              </label>
              <base-input
                v-model="formData.csd_password"
                focus
                type="password"
                name="csd_password"
                tab-index="1"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="text-rigth flex flex-content-right">
        <base-button icon="save" color="theme" type="submit">
          {{ $t('customers.upload_certificates') }}
        </base-button>
      </div>
    </form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { mapActions } from 'vuex'
import BaseButton from '../../../components/base/BaseButton.vue'

export default {
  components: { BaseButton },
  mixins: [validationMixin],
  data: () => ({
    formData: {
      sat_password: '',
      csd_password: '',
    },
    e_sign_cer: null,
    e_sign_key: null,
    digital_stamp_cer: null,
    digital_stamp_key: null,
    certificates: {
      fiel: null,
      csd: null,
    },
  }),

  created() {
    this.loadCertificates()
  },

  methods: {
    ...mapActions('customerModuleUser', [
      'fetchCustomerCertificates',
      'uploadCustomerCertificates',
    ]),

    loadCertificates() {
      this.fetchCustomerCertificates().then((response) => {
        this.certificates = response.data.certificates
      })
    },

    saveCertificates() {
      const validForm = this.validateInputs()
      if (!validForm) {
        return
      }
      const data = new FormData()
      if (this.e_sign_cer) {
        data.append('e_sign_cer', this.e_sign_cer)
      }
      if (this.e_sign_key) {
        data.append('e_sign_key', this.e_sign_key)
      }
      if (this.digital_stamp_cer) {
        data.append('digital_stamp_cer', this.digital_stamp_cer)
      }
      if (this.digital_stamp_key) {
        data.append('digital_stamp_key', this.digital_stamp_key)
      }
      if (this.formData.sat_password) {
        data.append('sat_password', this.formData.sat_password)
      }
      if (this.formData.csd_password) {
        data.append('csd_password', this.formData.csd_password)
      }
      this.uploadCustomerCertificates(data)
        .then((response) => {
          this.loadCertificates()
          if (response.data.messages) {
            response.data.messages.forEach((message) => {
              window.toastr[message.type](message.message)
            })
          }
        })
        .catch(() => {})
    },

    validateInputs() {
      if (
        (this.e_sign_cer && !this.e_sign_key) ||
        (this.e_sign_key && !this.e_sign_cer)
      ) {
        window.swal({
          text: this.$t('customers.xcertificate_must_select_both_files', {
            xcertificate: 'FIEL',
          }),
          icon: 'error',
          button: this.$t('general.accept'),
        })
        return false
      }
      if (this.e_sign_cer && this.e_sign_key && !this.formData.sat_password) {
        window.swal({
          text: this.$t('customers.xcertificate_must_enter_password', {
            xcertificate: 'FIEL',
          }),
          icon: 'error',
          button: this.$t('general.accept'),
        })
        return false
      }

      if (
        (this.digital_stamp_cer && !this.digital_stamp_key) ||
        (this.digital_stamp_key && !this.digital_stamp_cer)
      ) {
        window.swal({
          text: this.$t('customers.xcertificate_must_select_both_files', {
            xcertificate: 'CSD',
          }),
          icon: 'error',
          button: this.$t('general.accept'),
        })
        return false
      }
      if (
        this.digital_stamp_cer &&
        this.digital_stamp_key &&
        !this.formData.csd_password
      ) {
        window.swal({
          text: this.$t('customers.xcertificate_must_enter_password', {
            xcertificate: 'CSD',
          }),
          icon: 'error',
          button: this.$t('general.accept'),
        })
        return false
      }
      return true
    },

    onESignCerChange(event) {
      var input = event.target
      var filePath = input.value
      var allowedExtensions = /(\.cer)$/i
      if (!allowedExtensions.exec(filePath)) {
        window.swal('Revise que el archivo sea .CER ')
        input.value = ''
        return false
      }
      this.e_sign_cer = input.files[0]
      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = (e) => {
          this.previewESignCer = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      }
    },
    onESignKeyChange(event) {
      var input = event.target
      var filePath = input.value

      var allowedExtensions = /(\.key)$/i
      if (!allowedExtensions.exec(filePath)) {
        window.swal('Revise que el archivo sea .KEY ')
        input.value = ''
        return false
      }
      this.e_sign_key = input.files[0]
      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = (e) => {
          this.previewESignCer = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      }
    },
    onDigitalCerChange(event) {
      var input = event.target
      var filePath = input.value

      var allowedExtensions = /(\.cer)$/i
      if (!allowedExtensions.exec(filePath)) {
        window.swal('Revise que el archivo sea .CER ')
        input.value = ''
        return false
      }
      this.digital_stamp_cer = input.files[0]

      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = (e) => {
          this.previewESignCer = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      }
    },
    onDigitalKeyChange(event) {
      var input = event.target
      var filePath = input.value

      var allowedExtensions = /(\.key)$/i
      if (!allowedExtensions.exec(filePath)) {
        window.swal('Revise que el archivo sea .KEY ')
        input.value = ''
        return false
      }
      this.digital_stamp_key = input.files[0]
      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = (e) => {
          this.previewESignCer = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      }
    },
  },
}
</script>

<style scoped>
.table-certificate {
  border: 1px solid #ebf1fa;
  border-radius: 4px;
}
.image-upload-box {
  padding: 0;
  height: 70px;
}
</style>
