var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pt-4 pb-4 pl-4 pr-4" }, [
    _vm._m(0),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _vm._m(2),
    _vm._v(" "),
    _vm._m(3),
    _vm._v(" "),
    _c("div", { staticClass: "row mt-3" }, [
      _c(
        "div",
        { staticClass: "col form-group" },
        [
          _c("label", [_vm._v("Fecha en que se presento el Saldo a Favor")]),
          _vm._v(" "),
          _c("base-date-picker", {
            attrs: {
              "calendar-button": true,
              invalid: _vm.$v.form.payment_date.$error,
              "calendar-button-icon": "calendar",
              typeable: true,
            },
            on: {
              change: function ($event) {
                return _vm.$v.form.payment_date.$touch()
              },
            },
            model: {
              value: _vm.form.payment_date,
              callback: function ($$v) {
                _vm.$set(_vm.form, "payment_date", $$v)
              },
              expression: "form.payment_date",
            },
          }),
          _vm._v(" "),
          _vm.$v.form.payment_date.$error
            ? _c("div", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("validation.required")) +
                    "\n      "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(4),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("label", { attrs: { for: "input1" } }, [_vm._v("Tipo")]),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control",
          attrs: { type: "text", id: "input1", placeholder: "Ingrese valor" },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [
        _c("label", { attrs: { for: "input2" } }, [_vm._v("Periocidad")]),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control",
          attrs: { type: "text", id: "input2", placeholder: "Ingrese valor" },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [
        _c("label", { attrs: { for: "input3" } }, [_vm._v("Periodo")]),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control",
          attrs: { type: "text", id: "input3", placeholder: "Ingrese valor" },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [
        _c("label", { attrs: { for: "input4" } }, [_vm._v("Ejercicio")]),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control",
          attrs: { type: "text", id: "input4", placeholder: "Ingrese valor" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mt-3" }, [
      _c("div", { staticClass: "col" }, [
        _c("label", { attrs: { for: "input5" } }, [_vm._v("Saldo a Aplicar")]),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control",
          attrs: { type: "text", id: "input5", placeholder: "Ingrese valor" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mt-3" }, [
      _c("div", { staticClass: "col" }, [
        _c("label", { attrs: { for: "input6" } }, [
          _vm._v("Tipo de Declaración"),
        ]),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control",
          attrs: { type: "text", id: "input6", placeholder: "Ingrese valor" },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [
        _c("label", { attrs: { for: "input7" } }, [
          _vm._v("Número de Operación"),
        ]),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control",
          attrs: { type: "text", id: "input7", placeholder: "Ingrese valor" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mt-3" }, [
      _c("div", { staticClass: "col" }, [
        _c("label", { attrs: { for: "input8" } }, [
          _vm._v("Monto Original del Saldo a Favor"),
        ]),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control",
          attrs: { type: "text", id: "input8", placeholder: "Ingrese valor" },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [
        _c("label", { attrs: { for: "input9" } }, [
          _vm._v("Remanente Historico Antes de la Aplicación"),
        ]),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control",
          attrs: { type: "text", id: "input9", placeholder: "Ingrese valor" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col" }, [
      _c("label", { attrs: { for: "input11" } }, [
        _vm._v("Remanente Actualizado Antes de la Aplicación"),
      ]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control",
        attrs: { type: "text", id: "input11", placeholder: "Ingrese valor" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }