<template>
  <div class="sidebar-left">
    <div class="sidebar-body scroll-pane">
      <div class="side-nav">
        <div class="current-customer">
          <small>{{ $t('customers.current_customer') }}</small
          ><br />
          {{ currentCustomer.rfc }}
          <font-awesome-icon
            :icon="!currentCustomer.status ? 'exclamation-triangle' : ''"
            class="right-icon"
            color="#eed202"
            v-tooltip="{ content: currentCustomer.textstatus }"
          />
          <br />
          {{ currentCustomer.name }}

          <br />
          Periodo: {{ defaultMonth['name'] }} | {{ defaultYear }}
          <br />
          <font-awesome-icon
            :icon="'user-minus'"
            class="right-icon"
            @click="deselectCustomer"
            color="red"
            v-tooltip="{ content: 'Quitar Usuario' }"
          />
          <font-awesome-icon
            :icon="'box'"
            class="right-icon ml-5"
            @click="changePeriodo"
            color="theme"
            v-tooltip="{ content: 'Cambiar Periodo' }"
          />
          <br />
        </div>
        <template>
          <div
            v-for="(menuItems, index) in menu"
            :key="index + 'a'"
            class="menu-group"
          >
            <router-link
              v-for="(item, index1) in menuItems"
              :key="index1 + 'a'"
              :to="item.route"
              :class="{ disabled: item.require_customer && !currentCustomer }"
              class="menu-item"
              @click.native="Toggle"
            >
              <font-awesome-icon
                :icon="item.icon"
                class="icon menu-icon"
              /><span class="ml-3 menu-text">{{ $t(item.title) }}</span>
            </router-link>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      currentRoute: '',
      sidebar: 'sidebar',
      collapsed: {
        'home-collapse': false, // Inicialmente colapsado
        'cliente-collapse': true,
        // Otros elementos colapsables aquí
      },
      menu: [
        [
          {
            title: 'navigation.revenue' /* 'navigation.payments' */,
            icon: 'credit-card',
            route: '/general/payments',
            require_customer: true,
          },
          {
            title: 'navigation.expenses',
            icon: 'space-shuttle',
            route: '/general/expenses',
            require_customer: true,
          },
          {
            title: 'Nomina',
            icon: 'money-check',
            route: '/general/nomina',
            require_customer: true,
          },
          {
            title: 'navigation.retenciones',
            icon: 'file',
            route: '/general/retenciones',
            require_customer: true,
          },
          {
            title: 'navigation.treasury',
            icon: 'university',
            route: '/general/treasury-accounts',
            require_customer: true,
          },
          {
            title: 'Carga manual',
            icon: 'file',
            route: '/general/manual/upload',
            require_customer: true,
          },
          {
            title: 'navigation.tax_return',
            icon: 'file-alt',
            route: '/general/tax-returns',
            require_customer: true,
          },
          {
            title: 'navigation.concept_categories',
            icon: 'file',
            route: '/general/concept-categories-personal',
            require_customer: true,
          },
          {
            title: 'Pagos',
            icon: 'money-bill',
            route: '/general/pagos',
            require_customer: true,
          },

          {
            title: 'Mensajes',
            icon: 'comment',
            route: '/general/chat',
            require_customer: true,
          },
        ],
      ],
    }
  },
  computed: {
    ...mapGetters('CurrentCustomer', ['currentCustomer']),
    ...mapGetters('calendar', ['months', 'defaultMonth', 'defaultYear']),
  },

  methods: {
    ...mapActions('CurrentCustomer', ['setCurrentCustomer']),
    Toggle() {
      this.$utils.toggleSidebar()
    },
    toggleCollapse(target) {
      this.collapsed[target] = !this.collapsed[target]
    },
    deselectCustomer() {
      this.setCurrentCustomer(null)
      this.$router.push('/general/customers/pending')
    },
    changePeriodo() {
      this.$router.push('/general/customer/contracted')
    },
  },
  mounted() {
    this.currentRoute = this.$route.path
    if (this.currentRoute.includes('customers')) {
      this.collapsed['cliente-collapse'] = true
    } else {
      this.collapsed['cliente-collapse'] = false
    }
  },
  watch: {
    $route(to, from) {
      this.currentRoute = to.path
      if (currentRoute.includes('customers')) {
        this.collapsed['cliente-collapse'] = true
      } else {
        this.collapsed['cliente-collapse'] = false
      }
    },
  },
}
</script>
