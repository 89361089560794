<template >
  <div>
    <div class="card-body">
      <form class="pt-4 pb-4 pl-4 pr-4">

        <!-- ! primera fila -->
        <div class="row">
          <div class="col-4">
            <label for="input1">Ingresos Adicionales</label>
            <base-select v-model="form.adicional" :options="adicionalOptions" :searchable="true" :allow-empty="false"
              :show-labels="false" label="label" track-by="id" style="font-size: 12px" />
          </div>
        </div>
        <!-- ! segunda fila
        <div class="row mt-3" v-if="form.adicional?.id == '0'">
          <div class=" col-12">
            <h6 class="my-2">Total de los ingresos a disminuir:
              <money-text :amount="form.totalDisminuir" />
            </h6>
          </div>
        </div> -->

        <!-- Tercera fila con 2 inputs -->
        <div class="row mt-3" v-if="form.adicional?.id == '0'">
          <div class="col-12">
            <label for="input1">Ingresos adicionales por</label>
            <base-select v-model="form.conceptoAdicional" :options="conceptoAdicionalOptions" :searchable="true"
              :allow-empty="false" :show-labels="false" label="label" track-by="id" style="font-size: 12px" />
          </div>
        </div>

        <div class="row mt-3" v-if="form.adicional?.id == '0' && form.conceptoAdicional?.id == '0'">
          <div class="col-6">
            <label for="input1">Monto que desea adicionar</label>
            <base-input type="number" v-model="form.totalAdicional" />

          </div>
        </div>

        <div class="row mt-3"  v-if="form.adicional?.id == '0' && form.conceptoAdicional?.id == '0'">
            <div class=" col-6">
          <label for="input1">Nota</label>
          <base-text-area v-model="form.nota" />

        </div>
    </div>

    </form>
  </div>
  <div class="card-footer">
    <div class="mt-2 flex-content-right">
      <base-button color="theme" @click="saveIngresoAdicional">
        Guardar
      </base-button>
    </div>
  </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import TaxReturnInvoicesTableModal from './../../../views/components/TaxReturnInvoicesTableModal'

export default {
  components: {
    TaxReturnInvoicesTableModal,
  },
  mixins: [validationMixin],

  data: () => ({
    form: {
      adicional: null,
      conceptoAdicional: null,
      totalAdicional:0,
      nota: '',
    },

    disminuirOption: [
      { id: 0, name: 'SI' },
      { id: 1, name: 'NO' },
    ],

    conceptoOption: [
      { id: 0, name: 'Ingresos no considerados en el prellenado' },
    ],

   


  }),
  created() {

  },
  watch: {


  },
  computed: {
    ...mapGetters('taxReturns', [ 'ingresoAdicional']),

    adicionalOptions() {
      return this.disminuirOption.map((item) => {
        return {
          ...item,
          id: item.id,
          label: `${item.name}`,
        }
      })
    },
    conceptoAdicionalOptions() {
      return this.conceptoOption.map((item) => {
        return {
          ...item,
          id: item.id,
          label: `${item.name}`,
        }
      })
    },


  },

  mounted() {
    this.form = JSON.parse(JSON.stringify(this.ingresoAdicional));
  },


  methods: {
    ...mapActions('taxReturns', ['updatedModalIngresoAdicional']),
    ...mapActions('modal', ['closeModal']),

    saveIngresoAdicional() {
      this.updatedModalIngresoAdicional(this.form)
      this.closeCategoryModal()
    },

    closeCategoryModal() {
      this.closeModal()
    },
  },
}
</script>
