<template>
  <div class="main-content">
    <div class="page-title">
      <h3 class="page-title">
        {{ $t('retenciones.retention') }}
      </h3>
    </div>

    <!-- Retencion -->
    <div v-if="retention">
      <div class="row">
        <div class="col col-md-6">
          <div class="card">
            <div class="card-body text-center">
              <strong>{{ $t('retenciones.issuing_name') }}</strong> <br>
              <strong>{{ retention.issuing_rfc }}</strong> <br>
              <strong>{{ retention.issuing_name }}</strong>
            </div>
          </div>
        </div>
        <div class="col col-md-6">
          <div class="card">
            <div class="card-body text-center">
              <strong>{{ $t('retenciones.receiver_name') }}</strong> <br>
              <strong>{{ retention.receiver_type }}</strong> <br>
              <strong>{{ retention.receiver_rfc }}</strong> <br>
              <strong>{{ retention.receiver_name }}</strong>
              <div v-if="retention.receiver_curpr">
                <strong>{{ $t('retenciones.receiver_curpr') }}</strong> {{ retention.receiver_curpr }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <!-- Dates and UUID -->
          <div class="row">
            <div class="col-4 text-center">
              <strong>{{ $t('retenciones.date') }}</strong> <br>
              {{ retention.retention_date }}
            </div>
            <div class="col-4 text-center">
              <strong>{{ $t('retenciones.stamped_date') }}</strong> <br>
              {{ retention.retention_stamped_date }}
            </div>
            <div class="col-4 text-center">
              <strong>{{ $t('retenciones.uuid') }}</strong> <br>
              {{ retention.uuid }}
            </div>
          </div>

          <hr>

          <!-- Totals -->
          <div class="row">
            <div class="col col-md-3 text-center">
              <strong>{{ $t('retenciones.total_exempt_amount') }}</strong>
              <div v-html="$utils.formatMoney(retention.total_exempt_amount, defaultCurrency)" />
            </div>
            <div class="col col-md-3 text-center">
              <strong>{{ $t('retenciones.total_amount_taxed') }}</strong>
              <div v-html="$utils.formatMoney(retention.total_amount_taxed, defaultCurrency)" />
            </div>
            <div class="col col-md-3 text-center">
              <strong>{{ $t('retenciones.total_transaction_amount') }}</strong>
              <div v-html="$utils.formatMoney(retention.total_transaction_amount, defaultCurrency)" />
            </div>
            <div class="col col-md-3 text-center">
              <strong>{{ $t('retenciones.total_retention_amount') }}</strong>
              <div v-html="$utils.formatMoney(retention.total_retention_amount, defaultCurrency)" />
            </div>
          </div>

          <hr>

          <!-- Period -->
          <div class="row mt-2">
            <div class="col col-sm-4 text-center">
              <strong>{{ $t('retenciones.period_year') }}</strong> <br>
              {{ retention.period_year }}
            </div>
            <div class="col col-sm-4 text-center">
              <strong>{{ $t('retenciones.period_start_month') }}</strong> <br>
              {{ retention.period_start_month }}
            </div>
            <div class="col col-sm-4 text-center">
              <strong>{{ $t('retenciones.period_end_month') }}</strong> <br>
              {{ retention.period_end_month }}
            </div>
          </div>
        </div>
      </div>

      <!-- Tax list -->
      <div class="card">
        <div class="card-body">
          <table class="table">
            <thead>
              <tr>
                <th>{{ $t('retenciones.taxes.tax_base') }}</th>
                <th>{{ $t('retenciones.taxes.tax') }}</th>
                <th>{{ $t('retenciones.taxes.payment_type') }}</th>
                <th>{{ $t('retenciones.taxes.retention_amount') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="tax in retention.taxes" :key="tax.id">
                <td>
                  <div v-html="$utils.formatMoney(tax.tax_base, defaultCurrency)" />
                </td>
                <td>
                  <retention-tax :tax-code="tax.tax" />
                </td>
                <td>
                  {{ tax.payment_type }}
                </td>
                <td>
                  <div v-html="$utils.formatMoney(tax.retention_amount, defaultCurrency)" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Complement Digital Platforms -->
      <div v-if="retention.complement_digital_platform" class="card">
        <div class="card-body">
          <h5 class="text-center">
            {{ $t('retenciones.complement_digital_platform') }}
          </h5>

          <!-- Datos del complemento -->
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>{{ $t('retenciones.total_amount_wo_iva') }}</th>
                  <th>{{ $t('retenciones.total_iva_transfered') }}</th>
                  <th>{{ $t('retenciones.total_iva_detained') }}</th>
                  <th>{{ $t('retenciones.total_isr_detained') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <money-text
                      :amount="
                        retention.complement_digital_platform
                          .total_amount_wo_iva
                      "
                    />
                  </td>
                  <td>
                    <money-text
                      :amount="
                        retention.complement_digital_platform
                          .total_iva_transfered
                      "
                    />
                  </td>
                  <td>
                    <money-text
                      :amount="
                        retention.complement_digital_platform.total_iva_detained
                      "
                    />
                  </td>
                  <td>
                    <money-text
                      :amount="
                        retention.complement_digital_platform.total_isr_detained
                      "
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Datos de los servicios del complemento -->
          <h6 class="text-center">
            {{ $t('retenciones.services') }}
          </h6>
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>{{ $t('retenciones.service_type') }}</th>
                  <th>{{ $t('retenciones.commission_amount') }}</th>
                  <th>{{ $t('retenciones.taxes.tax') }}</th>
                  <th>{{ $t('retenciones.taxes.tax_amount') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="service in retention.complement_digital_platform
                    .services"
                  :key="service.id"
                >
                  <td>{{ service.type_service }}</td>
                  <td>
                    <money-text :amount="service.comm_amount" />
                  </td>
                  <td>
                    <retention-tax :tax-code="service.taxt_tax" />
                  </td>
                  <td>
                    <money-text :amount="service.taxt_amount" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import RetentionTax from '../components/RetentionTax'
export default {
  components: {
    RetentionTax
  },
  data: () => ({
    retentionId: null,
    retention: null
  }),

  computed: {
    ...mapGetters('currency', [
      'defaultCurrency'
    ]),
  },

  created () {
    this.retentionId = this.$route.params.id;
  },

  mounted () {
    this.loadRetention();
  },

  methods: {
    ...mapActions('retenciones', [
      'fetchRetention',
    ]),
    loadRetention () {
      this.fetchRetention(this.retentionId)
        .then(response => {
          this.retention = response.data;
        });
    }
  }
}
</script>