var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-content" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c("h3", { staticClass: "page-title" }, [
          _vm._v(
            "\n      " + _vm._s(_vm.$t("treasury_accounts.relate")) + "\n      "
          ),
          _vm.typeRelate
            ? _c("span", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t(`treasury_accounts.${_vm.typeRelate}`)) +
                    "\n      "
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _vm.transaction
        ? _c("div", { staticClass: "row" }, [
            _vm.typeRelate == "payments"
              ? _c(
                  "div",
                  { staticClass: "col col-md-4 offset-md-8 text-right" },
                  [
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.$t("treasury_accounts.transaction_amount"))
                      ),
                    ]),
                    _vm._v(" "),
                    _c("strong", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$utils.formatMoney(
                            _vm.transaction.payments_amount,
                            _vm.defaultCurrency
                          )
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.$t("treasury_accounts.transaction_related"))
                      ),
                    ]),
                    _vm._v(" "),
                    _c("strong", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$utils.formatMoney(
                            _vm.transaction.payments_completed,
                            _vm.defaultCurrency
                          )
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$t("treasury_accounts.remainder"))),
                    ]),
                    _vm._v(" "),
                    _c("strong", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$utils.formatMoney(
                            _vm.remainder,
                            _vm.defaultCurrency
                          )
                        ),
                      },
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.typeRelate == "expenses"
              ? _c(
                  "div",
                  { staticClass: "col col-md-4 offset-md-8 text-right" },
                  [
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.$t("treasury_accounts.transaction_amount"))
                      ),
                    ]),
                    _vm._v(" "),
                    _c("strong", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$utils.formatMoney(
                            _vm.transaction.expenses_amount,
                            _vm.defaultCurrency
                          )
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.$t("treasury_accounts.transaction_related"))
                      ),
                    ]),
                    _vm._v(" "),
                    _c("strong", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$utils.formatMoney(
                            _vm.transaction.expenses_completed,
                            _vm.defaultCurrency
                          )
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$t("treasury_accounts.remainder"))),
                    ]),
                    _vm._v(" "),
                    _c("strong", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$utils.formatMoney(
                            _vm.remainder,
                            _vm.defaultCurrency
                          )
                        ),
                      },
                    }),
                  ]
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("h3", [_vm._v(_vm._s(_vm.$t("treasury_accounts.related_invoices")))]),
      _vm._v(" "),
      _c(
        "table-component",
        {
          ref: "tableRelatedInvoices",
          attrs: {
            "show-filter": false,
            data: _vm.getRelatedInvoices,
            "filter-no-results": _vm.$t("general.filterNoResults"),
            "is-invoices-list": true,
            "table-class": "table",
          },
        },
        [
          _c("table-column", {
            attrs: {
              label: _vm.$t("treasury_accounts.date"),
              show: "invoice_date",
            },
          }),
          _vm._v(" "),
          _c("table-column", {
            attrs: {
              label: _vm.$t("invoices.invoice_number"),
              show: "invoice_number",
            },
          }),
          _vm._v(" "),
          _vm.typeRelate == "payments"
            ? _c("table-column", {
                attrs: {
                  label: _vm.$t("payments.customer"),
                  show: "receiver_name",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.typeRelate == "payments"
            ? _c("table-column", {
                attrs: {
                  label: _vm.$t("payments.customer_rfc"),
                  show: "receiver_rfc",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.typeRelate == "expenses"
            ? _c("table-column", {
                attrs: {
                  label: _vm.$t("expenses.provider"),
                  show: "issuing_name",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.typeRelate == "expenses"
            ? _c("table-column", {
                attrs: {
                  label: _vm.$t("expenses.provider_rfc"),
                  show: "issuing_rfc",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("table-column", {
            attrs: {
              label: _vm.$t("treasury_accounts.related_amount"),
              "sort-as": "total",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("treasury_accounts.related"))),
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$utils.formatMoney(
                            row.invoice_related.amount,
                            _vm.defaultCurrency
                          )
                        ),
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("table-column", {
            attrs: {
              label: _vm.$t("treasury_accounts.remainder"),
              "sort-as": "total",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("treasury_accounts.remainder"))),
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$utils.formatMoney(
                            row.total - row.related_amount,
                            _vm.defaultCurrency
                          )
                        ),
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("table-column", {
            attrs: { label: _vm.$t("invoices.action") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [_vm._v(_vm._s(_vm.$t("invoices.action")))]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "table-button",
                        on: {
                          click: function ($event) {
                            return _vm.stopRelateInvoice(row)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("treasury_accounts.stop_relate")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "table-component",
        {
          ref: "tableInvoicesPeriodo",
          attrs: {
            "show-filter": false,
            data: _vm.getInvoicesPeriodo,
            "filter-no-results": _vm.$t("general.filterNoResults"),
            "is-invoices-list": true,
            "table-class": "table",
          },
        },
        [
          _c("table-column", {
            attrs: {
              label: _vm.$t("treasury_accounts.date"),
              show: "invoice_date",
            },
          }),
          _vm._v(" "),
          _c("table-column", {
            attrs: {
              label: _vm.$t("invoices.invoice_number"),
              show: "invoice_number",
            },
          }),
          _vm._v(" "),
          _vm.typeRelate == "payments"
            ? _c("table-column", {
                attrs: {
                  label: _vm.$t("payments.customer"),
                  show: "receiver_name",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.typeRelate == "payments"
            ? _c("table-column", {
                attrs: {
                  label: _vm.$t("payments.customer_rfc"),
                  show: "receiver_rfc",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.typeRelate == "expenses"
            ? _c("table-column", {
                attrs: {
                  label: _vm.$t("expenses.provider"),
                  show: "issuing_name",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.typeRelate == "expenses"
            ? _c("table-column", {
                attrs: {
                  label: _vm.$t("expenses.provider_rfc"),
                  show: "issuing_rfc",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("table-column", {
            attrs: { label: _vm.$t("invoices.total"), "sort-as": "total" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [_vm._v(_vm._s(_vm.$t("invoices.total")))]),
                    _vm._v(" "),
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$utils.formatMoney(row.total, _vm.defaultCurrency)
                        ),
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("table-column", {
            attrs: {
              label: _vm.$t("treasury_accounts.remainder"),
              "sort-as": "total",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("treasury_accounts.remainder"))),
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$utils.formatMoney(
                            row.total - row.related_amount,
                            _vm.defaultCurrency
                          )
                        ),
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("table-column", {
            attrs: { label: _vm.$t("invoices.action") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [_vm._v(_vm._s(_vm.$t("invoices.action")))]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "table-button",
                        on: {
                          click: function ($event) {
                            return _vm.relateInvoice(row)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("invoices.relate")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col col-md-8" }, [
          _c("h3", [
            _vm._v(_vm._s(_vm.$t("treasury_accounts.invoices_to_relate"))),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col col-md-4" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("treasury_accounts.name_or_rfc")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("base-input", {
                attrs: { type: "text" },
                model: {
                  value: _vm.filters.nameOrRFC,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters, "nameOrRFC", $$v)
                  },
                  expression: "filters.nameOrRFC",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "table-component",
        {
          ref: "tableInvoices",
          attrs: {
            "show-filter": false,
            data: _vm.getInvoices,
            "filter-no-results": _vm.$t("general.filterNoResults"),
            "is-invoices-list": true,
            "table-class": "table",
          },
        },
        [
          _c("table-column", {
            attrs: {
              label: _vm.$t("treasury_accounts.date"),
              show: "invoice_date",
            },
          }),
          _vm._v(" "),
          _c("table-column", {
            attrs: {
              label: _vm.$t("invoices.invoice_number"),
              show: "invoice_number",
            },
          }),
          _vm._v(" "),
          _vm.typeRelate == "payments"
            ? _c("table-column", {
                attrs: {
                  label: _vm.$t("payments.customer"),
                  show: "receiver_name",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.typeRelate == "payments"
            ? _c("table-column", {
                attrs: {
                  label: _vm.$t("payments.customer_rfc"),
                  show: "receiver_rfc",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.typeRelate == "expenses"
            ? _c("table-column", {
                attrs: {
                  label: _vm.$t("expenses.provider"),
                  show: "issuing_name",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.typeRelate == "expenses"
            ? _c("table-column", {
                attrs: {
                  label: _vm.$t("expenses.provider_rfc"),
                  show: "issuing_rfc",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("table-column", {
            attrs: { label: _vm.$t("invoices.total"), "sort-as": "total" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [_vm._v(_vm._s(_vm.$t("invoices.total")))]),
                    _vm._v(" "),
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$utils.formatMoney(row.total, _vm.defaultCurrency)
                        ),
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("table-column", {
            attrs: {
              label: _vm.$t("treasury_accounts.remainder"),
              "sort-as": "total",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("treasury_accounts.remainder"))),
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$utils.formatMoney(
                            row.total - row.related_amount,
                            _vm.defaultCurrency
                          )
                        ),
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("table-column", {
            attrs: { label: _vm.$t("invoices.action") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [_vm._v(_vm._s(_vm.$t("invoices.action")))]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "table-button",
                        on: {
                          click: function ($event) {
                            return _vm.relateInvoice(row)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("invoices.relate")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col col-md-8" }, [
        _c("h3", [_vm._v("Facturas del Periodo")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }