var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "mt-2 mb-4" }, [
        _c("table", { staticClass: "table-bordered invoice-info w-100" }, [
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("invoices.complements.serie")))]),
              _vm._v(" "),
              _c("th", [
                _vm._v(_vm._s(_vm.$t("invoices.complements.invoice_number"))),
              ]),
              _vm._v(" "),
              _c("th", { staticClass: "amount-cell" }, [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.$t("invoices.complements.currency")) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c("th", { staticClass: "amount-cell" }, [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.$t("invoices.complements.payment_method")) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c("th", { staticClass: "amount-cell" }, [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.$t("invoices.complements.partiality")) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c("th", { staticClass: "text-center amount-cell-md" }, [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.$t("invoices.complements.previous_balance")) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c("th", { staticClass: "text-center amount-cell-md" }, [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.$t("invoices.complements.amount_payment")) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c("th", { staticClass: "text-center amount-cell-md" }, [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.$t("invoices.complements.outstanding_balance")) +
                    "\n            "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("tbody", [
            _c("tr", [
              _c("td", [_vm._v(_vm._s(_vm.modalData.serie))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.modalData.invoice_number))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.modalData.currency))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.modalData.payment_method))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm.modalData.partiality))]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("money-text", {
                    attrs: { amount: Number(_vm.modalData.previous_balance) },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("money-text", {
                    attrs: { amount: Number(_vm.modalData.amount_payment) },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("money-text", {
                    attrs: {
                      amount: Number(_vm.modalData.outstanding_balance),
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card-footer" }, [
      _c(
        "div",
        { staticClass: "mt-2 flex-content-right" },
        [
          _c(
            "base-button",
            {
              attrs: { color: "theme" },
              on: { click: _vm.closeCategoryModal },
            },
            [_vm._v("\n        Cerrar\n      ")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }