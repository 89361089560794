<template>
  <header class="site-header">
    <a href="/" class="brand-main">
      <img
        id="logo-white"
        src="/assets/img/logo-taxati.png"
        alt="Crater Logo"
        class="d-none d-md-inline"
      />
      <img
        id="logo-mobile"
        src="/assets/img/logo-taxati.png"
        alt="Laraspace Logo"
        class="d-md-none"
      />
    </a>
  </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  computed: {},

  created() {},

  methods: {},
}
</script>
