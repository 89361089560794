export const UPDATED_RIF_ISR = 'UPDATED_RIF_ISR'

export const UPDATED_RIF_IVA = 'UPDATED_RIF_IVA'

export const UPDATED_COMPENSACION = 'UPDATED_COMPENSACION'
export const UPDATED_ACREDITAMIENTO = 'UPDATED_ACREDITAMIENTO'
export const UPDATED_INGRESOS_CFDI = 'UPDATED_INGRESOS_CFDI'
export const UPDATED_INGRESOS_DISMINUIR = 'UPDATED_INGRESOS_DISMINUIR'
export const UPDATED_INGRESOS_ADICIONAL = 'UPDATED_INGRESOS_ADICIONAL'
export const UPDATED_ISR_RETENIDO = 'UPDATED_INGRESOS_ADICIONAL'





