<template>
  <div>
    <div v-if="years.length" class="row mb-3">
      <div class="col-12 text-center">
        <nav class="nav nav-pills">
          <a
            v-for="year in years"
            :key="year"
            :class="{ active: year == yearShowing }"
            class="nav-link"
            href="#"
            @click.prevent="setShowingYear(year)"
          >
            {{ year }}
          </a>
        </nav>
      </div>
    </div>
    <div class="row">
      <div v-for="table in tables" :key="table.id" class="col-12 col-lg-6 mt-2">
        <h6>{{ table.name }}</h6>
        <conditional-wrapper
                  :show-for-admin="true"
                  :show-for-executives="false"
                  :show-for-customers="false"
                  :disable="true"
                >
                <hot-table
                  :nested-headers="colHeaders"
                  :columns="columns"
                  :data="table.items"
                  :max-cols="4"
                  license-key="non-commercial-and-evaluation"
                />
              </conditional-wrapper>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12 text-center">
        <button class="btn btn-primary" @click="save()">
          {{ $t('general.save') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { HotTable } from '@handsontable/vue'
import { mapActions, mapGetters } from 'vuex'
import ConditionalWrapper from '../../components/ConditionalWrapper.vue'

export default {
  components: {
    HotTable,
    ConditionalWrapper
  },
  data: () => ({
    regimeId: null,
    taxRegime: null,
    tables: [],
    yearShowing: null,
    years: [],
    columns: [
      { className: 'htRight' },
      { className: 'htRight' },
      { className: 'htRight' },
      { className: 'htRight' },
    ],
  }),
  computed: {
    ...mapGetters('calendar', ['getMonthByNumer']),
    ...mapGetters('catalogs', ['searchTaxRegime']),
    ...mapGetters('calendar', ['defaultYear']),
    colHeaders() {
      return [
        [
          this.$t('rate_tables.lower_limit'),
          this.$t('rate_tables.upper_limit'),
          this.$t('rate_tables.fixed_fee'),
          this.$t('rate_tables.percent_to_be_applied_on'),
        ],
        ['$', '$', '$', '%'],
      ]
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name == 'rate-tables.table-regime') {
      this.regimeId = to.params.regime
      this.tables = []
      this.years = []
      this.yearShowing = this.defaultYear
      this.loadTables()
    }
    next()
  },
  created() {
    this.regimeId = this.$route.params.regime
    this.yearShowing = this.defaultYear
    this.loadTables()
  },
  methods: {
    ...mapActions('rateTables', ['fetchRateTables', 'saveRateTable']),
    createTableArrays(tables) {
      this.tables = tables.map((table) => {
        const items = table.items.map((item) => {
          return [
            item['lower_limit'],
            item['upper_limit'],
            item['fixed_fee'],
            item['percent_to_be_applied_on'],
          ]
        })
        for (let i = 15 - items.length; i > 0; i--) {
          items.push(['', '', '', ''])
        }

        return {
          ...table,
          items,
        }
      })
    },

    loadTables() {
      this.taxRegime = this.searchTaxRegime(this.regimeId)
      const data = {
        taxRegime: this.regimeId,
        year: this.yearShowing,
      }
      this.fetchRateTables(data)
        .then((response) => {
          this.years = response.data.years
          this.createTableArrays(response.data.tables)
        })
        .catch((err) => {})
    },

    setShowingYear(year) {
      this.yearShowing = year
      this.loadTables()
    },

    save() {
      const data = {
        tables: this.tables.map((table) => {
          return {
            id: table.id,
            items: table.items,
          }
        }),
      }

      this.saveRateTable(data)
        .then((response) => {
          window.toastr.success(this.$t('rate_tables.messages.tables_saved'))
        })
        .catch((err) => {
          window.toastr.success(this.$t('rate_tables.errors.tables_save'))
        })
    },
  },
}
</script>
