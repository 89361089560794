var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", [
    _c(
      "td",
      [
        _c("sub-string", {
          attrs: { string: _vm.concept.description, length: 30 },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("td", [_vm._v("\n    " + _vm._s(_vm.concept.clave_prod_serv) + "\n  ")]),
    _vm._v(" "),
    _c("td", { staticClass: "text-center" }, [
      _vm._v("\n    " + _vm._s(_vm.concept.quantity) + "\n  "),
    ]),
    _vm._v(" "),
    _c(
      "td",
      { staticClass: "text-center" },
      [_c("money-text", { attrs: { amount: Number(_vm.concept.total) } })],
      1
    ),
    _vm._v(" "),
    _c(
      "td",
      { staticClass: "text-center" },
      [
        _c("money-text", {
          attrs: { amount: Number(_vm.concept.discount_val) },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "td",
      { staticClass: "text-center" },
      [_c("money-text", { attrs: { amount: Number(_vm.concept.iva) } })],
      1
    ),
    _vm._v(" "),
    _c(
      "td",
      { staticClass: "text-center" },
      [
        _c("money-text", {
          attrs: {
            amount: Number(_vm.concept.total) + Number(_vm.concept.iva),
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "td",
      [
        _c("expense-category", {
          ref: _vm.category,
          attrs: { concept: _vm.concept },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("td", [
      _c("div", { staticClass: "text-center" }, [
        _c("input", {
          attrs: { type: "checkbox" },
          domProps: { checked: _vm.inTaxReturn },
          on: { click: _vm.toogleInTaxReturn },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }