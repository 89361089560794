var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "contratacion main-content" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("h6", [_vm._v("Declaraciones Mensuales")]),
          _vm._v(" "),
          _vm._l(_vm.years, function (year, index) {
            return _c(
              "div",
              { key: year },
              [
                _c(
                  "b-card",
                  { staticClass: "mb-1", attrs: { "no-body": "" } },
                  [
                    _c(
                      "b-card-header",
                      {
                        staticClass: "p-1",
                        attrs: { "header-tag": "header", role: "tab" },
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-toggle",
                                rawName: "v-b-toggle",
                                value: "collapse-" + index,
                                expression: "'collapse-' + index",
                              },
                            ],
                            staticClass: "pl-2",
                            attrs: { variant: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.cardSelect(index)
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "icon menu-icon",
                              attrs: {
                                icon:
                                  _vm.selectIndex == index
                                    ? "angle-down"
                                    : "angle-right",
                              },
                            }),
                            _vm._v(" "),
                            _c("input", {
                              attrs: { type: "checkbox" },
                              domProps: { checked: _vm.isSelectedYear(year) },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.setAllDeclaracionesYear(year)
                                },
                              },
                            }),
                            _vm._v(
                              "\n              " +
                                _vm._s(year) +
                                "\n            "
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-collapse",
                      {
                        staticClass: "mt-2",
                        attrs: {
                          id: "collapse-" + index,
                          visible: index === 0,
                        },
                      },
                      _vm._l(_vm.meses, function (month, mesIndex) {
                        return _c(
                          "div",
                          { key: mesIndex + month, staticClass: "pl-4 my-3" },
                          [
                            _c("input", {
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: _vm.isSelected({
                                  year,
                                  month,
                                }),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.setMesDeclaracion({ year, month })
                                },
                              },
                            }),
                            _vm._v(
                              "\n              " +
                                _vm._s(month) +
                                "\n            "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c(
            "base-button",
            {
              staticClass: "mt-5",
              attrs: { color: "#1B144A" },
              on: {
                click: function ($event) {
                  return _vm.payment()
                },
              },
            },
            [
              _vm._v("\n        Siguiente\n        "),
              _c("font-awesome-icon", {
                staticClass: "icon menu-icon mr-2",
                attrs: { icon: ["fas", "arrow-right"] },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "page-header mt-4", staticStyle: { "z-index": "1" } },
      [
        _c("h3", { staticClass: "page-title" }, [
          _vm._v("Declaraciones de Impuestos"),
        ]),
        _vm._v(" "),
        _c("h5", [
          _vm._v(
            "\n      Selecciona los meses que deseas realizar el cumplimiento de obligaciones\n      fiscales\n    "
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }