export const valuesIva = [
  {
    id: 'IVA16',
    value: 16,
    description: 'IVA',
  },
  {
    id: 'IVA8',
    value: 8,
    description: 'IVA',
  },
  {
    id: 'IVA0',
    value: 0,
    description: 'IVA',
  },
  {
    id: 'EXENTO',
    value: 0,
    description: 'IVA',
  },
]
