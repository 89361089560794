<template>
  <div class="main-content">
    <div class="page-header">
      <h3 class="page-title">{{ $t('customer_prod_serv.prod_serv_groups') }}</h3>
    </div>
    
    <div class="card">
      <div class="card-body">
        <table class="table">
          <thead>
            <tr>
              <th>{{ $t('customer_prod_serv.category') }}</th>
              <th>{{ $t('customer_prod_serv.tax_return_payments') }}</th>
              <th>{{ $t('customer_prod_serv.tax_return_expenses') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="group in prodServGroups" :key="group.id">
              <td>{{ group.description }}</td>
              <td class="text-center">
                <base-switch
                  v-model="group.group_category.payments_tax_return"
                  class="btn-switch"
                  @change="changeGroupInTaxReturn('payments', group, $event)"
                />
              </td>
              <td class="text-center">
                <base-switch
                  v-model="group.group_category.expenses_tax_return"
                  class="btn-switch"
                  @change="changeGroupInTaxReturn('expenses', group, $event)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data: () => ({
    prodServGroups: [],
  }),
  computed: {
    ...mapGetters('CurrentCustomer', [
      'currentCustomer',
    ]),
  },
  created () {},
  mounted () {
    this.loadCustomerProdServGroups();
  },
  methods: {
    ...mapActions('prodServ', [
      'fetchCustomerProdServGroups',
      'updateCustomerProdServGroupTaxReturn',
    ]),
    loadCustomerProdServGroups ()
    {
      this.fetchCustomerProdServGroups(this.currentCustomer.id)
      .then(response => {
        this.prodServGroups = response.data;
      })
    },

    changeGroupInTaxReturn (type, group, value)
    {
      this.updateCustomerProdServGroupTaxReturn({
        customer_id: this.currentCustomer.id,
        prod_serv_group_id: group.id,
        in_tax_return: value,
        type,
      })
        .then(response => {
          window.toastr['success'](this.$t('customer_prod_serv.messages.customer_group_update'));
        })
        .catch(err => {
          window.toastr['error'](this.$t('customer_prod_serv.errors.customer_group_update'));
        })
    }
  }
}
</script>
