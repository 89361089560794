<template>
  <div class="main-content mt-5">
    <expense
      v-for="expense in quickAssignmentInvoices"
      :key="expense.id"
      :expense="expense"
    ></expense>
    <infinite-loading @infinite="loadExpenses($event)" ref="infinite">
      <div slot="no-more">{{ $t('general.no_more_elements') }}</div>
      <div slot="no-results">{{ $t('general.no_more_elements') }}</div>
    </infinite-loading>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Expense from './components/quick_assignment/Expense'
export default {
  components: { Expense },
  data: () => ({
    filters: {
      month: '',
      year: '',
      type: 'expenses',
      orderByField: 'invoice_date',
      orderBy: 'asc',
    },
    currentPage: 0,
    lastPage: 1,
  }),
  computed: {
    ...mapGetters('calendar', ['defaultMonth', 'defaultYear']),
    ...mapGetters('CurrentCustomer', ['currentCustomer']),
    ...mapGetters('invoice', ['quickAssignmentInvoices']),
  },
  created() {
    this.filters.month = this.defaultMonth.id
    this.filters.year = this.defaultYear
  },
  mounted() {
    setTimeout(() => {
      window.inf = this.$refs.infinite
    }, 1000)
  },
  beforeCreate() {
    this.currentPage = 0
  },
  beforeDestroy() {
    this.clearQuickAssigment()
  },
  methods: {
    ...mapActions('invoice', [
      'invoicesForQuickAssignment',
      'clearQuickAssigment',
    ]),
    loadExpenses($state) {
      if (this.currentPage < this.lastPage) {
        this.currentPage++
      } else {
        $state.complete()
        return
      }
      const params = {
        ...this.filters,
        customer_id: this.currentCustomer.id,
        page: this.currentPage,
      }
      this.invoicesForQuickAssignment(params)
        .then((response) => {
          this.lastPage = response.data.invoices.last_page
          if (this.lastPage > this.currentPage) {
            $state.loaded()
          } else {
            $state.loaded()
          }
        })
        .catch(() => {
          $state.complete()
        })
    },
  },
}
</script>
