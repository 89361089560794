<template>
  <div class="main-content treasuries-accounts">
    <div class="page-header mt-5">
      <h3 class="page-title">
        {{ $t('treasury_accounts.page_title') }}
        | {{ filters.month['name'] }},
        {{ filters.year }}
      </h3>

      <div class="page-actions row">
        <!-- <router-link :to="{ name: 'treasury_accounts.upload' }" class="mr-2">
          <base-button :outline="true" color="theme" icon="plus" size="large">
            {{ $t('treasury_accounts.upload_transactions') }}
          </base-button>
        </router-link> -->
        <!-- cuentas -->
        <router-link
          :to="{
            name: 'account.index',
          }"
          class="mr-2"
        >
          <base-button
            v-tooltip.top-center="'Cuentas'"
            :outline="true"
            color="theme"
            size="large"
          >
            Cuentas
          </base-button>
        </router-link>

        <!-- General public invoice -->
        <router-link
          :to="{
            name: 'general-public-invocie-treasury.index',
          }"
          class="mr-2"
        >
          <base-button
            v-tooltip.top-center="$t('payments.general_public_invoices.title')"
            :outline="true"
            color="theme"
            icon="file-alt"
            size="large"
          >
            {{ $t('payments.general_public_invoices.abbr') }}
          </base-button>
        </router-link>

        <!-- Add button -->
        <router-link :to="{ name: 'treasury_accounts.create' }">
          <base-button color="theme" icon="plus" size="large">
            {{ $t('treasury_accounts.add_transaction') }}
          </base-button>
        </router-link>
      </div>
    </div>

    <div v-for="(cuenta, index) in treasuries" :key="index">
      <b-card-header header-tag="header" role="tab">
        <div
          class="d-flex justify-content-between align-items-center"
          v-b-toggle="'collapse-cuenta-' + index"
          variant="primary"
        >
          <!-- Nombre ennegrecido y en UpperCamelCase -->
          <div class="pl-2 font-weight-bold text-uppercase">
            {{ cuenta.name }}
          </div>

          <!-- Iconos en el lado derecho -->
          <div>
            <font-awesome-icon
              @click="importarTransaccion(cuenta.id)"
              v-tooltip="{
                content: $t('treasury_accounts.upload_transactions'),
              }"
              class="icon mr-2"
              icon="plus"
            />

            <!-- <font-awesome-icon class="icon" icon="edit" />
            <font-awesome-icon class="icon" icon="circle-notch" /> -->
          </div>
        </div>
      </b-card-header>

      <b-collapse
        :id="'collapse-cuenta-' + index"
        class="my-2"
        :visible="false"
      >
        <!-- <table-component
          :ref="'table_' + index"
          :data="cuenta.treasury_accounts"
          :filter-no-results="$t('general.filterNoResults')"
          :is-transactions-list="true"
          :show-filter="false"
          table-class="table"
        >
          <table-column
            :label="$t('treasury_accounts.treasury_date')"
            sort-as="treasury_date"
            show="treasury_date"
            style="background-color: aqua;"
          />
          <table-column label="Descripción" show="name" />
          <table-column label="N/A" :sortable="false" :filterable="false">
            <template slot-scope="row">
              <div class="text-center" v-if="row.id != undefined">
                <input
                  type="checkbox"
                  class="form-check-input"
                  :checked="row.not_applicable"
                  @click="updateSelectedRows(row, index)"
                />
              </div>
            </template>
          </table-column>
          <table-column label="Depositos" show="payments_amount">
            <template slot-scope="row">
              <span>{{ $t('treasury_accounts.payments') }}</span>
              <div class="flex-row flex-center" v-if="row.id != undefined">
                <div
                  v-html="
                    $utils.formatMoney(row.payments_amount, defaultCurrency)
                  "
                />
                <div
                  v-if="row.payments_amount > 0 && row.not_applicable == 0"
                  class="px-3 space-left"
                >
                  <router-link
                    :to="{
                      name: 'treasury_accounts.relate_invoices',
                      params: { type: 'payments', id: row.id },
                    }"
                  >
                    <font-awesome-icon icon="plus-circle" />
                  </router-link>
                </div>
              </div>
            </template>
          </table-column>

          <table-column label="Retiros" show="expenses_amount">
            <template slot-scope="row">
              <span>{{ $t('treasury_accounts.expenses') }}</span>
              <div
                class="text-center d-flex align-items-center px-3"
                v-if="row.id != undefined"
              >
                <div class="mr-2">
                  <span
                    v-html="
                      $utils.formatMoney(row.expenses_amount, defaultCurrency)
                    "
                  />
                </div>

                <div
                  v-if="row.expenses_amount > 0 && row.not_applicable == 0"
                  class="px-3 space-left"
                >
                  <router-link
                    :to="{
                      name: 'treasury_accounts.relate_invoices',
                      params: { type: 'expenses', id: row.id },
                    }"
                  >
                    <font-awesome-icon icon="plus-circle" />
                  </router-link>
                </div>
              </div>
            </template>
          </table-column>
          <table-column :label="Saldo" show="Saldo">
            <template slot-scope="row">
              <span>Saldo</span>
              <div
                class="text-center"
                v-html="$utils.formatMoney(row.total, defaultCurrency)"
              />
            </template>
          </table-column>
          <table-column
            :label="$t('payments.general_public_invoices.abbr')"
            :sortable="false"
            :filterable="false"
          >
            <template slot-scope="row">
              <span>
                {{ $t('payments.general_public_invoices.abbr') }}
              </span>
              <div
                class="text-center"
                v-if="row.payments_amount > 0 && row.not_applicable == 0"
              >
                <template v-if="!row.general_public_invoiced_at">
                  <input
                    :checked="row.general_public_invoice"
                    type="checkbox"
                    class="form-check-input"
                    value="1"
                    @change="toggleRowGeneralPublicInvoice(row)"
                  />
                </template>
              </div>
            </template>
          </table-column>

          <table-column
            label="Depositos sin relacionar"
            :sortable="false"
            :filterable="false"
          >
            <template slot-scope="row">
              <div
                class="text-center"
                v-if="
                  row.id != undefined &&
                  !row.expenses_amount > 0 &&
                  row.not_applicable == 0
                "
              >
                <div
                  v-html="$utils.formatMoney(row.remanente, defaultCurrency)"
                />
              </div>
            </template>
          </table-column>
        </table-component> -->

        <table class="table">
          <thead class="thead-dark">
            <tr>
              <th>
                {{ $t('treasury_accounts.treasury_date') }}
              </th>
              <th>Descripción</th>
              <th>N/A</th>
              <th>Depositos</th>
              <th>Retiros</th>
              <th>Saldo</th>
              <th>{{ $t('payments.general_public_invoices.abbr') }}</th>
              <th>Importe sin relacionar</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, index2) in cuenta.treasury_accounts"
              :key="index2"
              :style="styleRow(row)"
              @dblclick="editDescription()"
            >
              <td>{{ row.treasury_date }}</td>
              <td>{{ row.name }}</td>
              <td class="text-center">
                <input
                  v-if="row.id != undefined"
                  type="checkbox"
                  :checked="row.not_applicable"
                  @mousedown.prevent="updateSelectedRows(row, index)"
                />
              </td>
              <td>
                <div class="flex-row flex-center" v-if="row.id != undefined">
                  <div
                    v-html="
                      $utils.formatMoney(row.payments_amount, defaultCurrency)
                    "
                  ></div>
                  <div
                    v-if="row.payments_amount > 0 && row.not_applicable == 0"
                    class="px-3 space-left"
                  >
                    <router-link
                      :to="{
                        name: 'treasury_accounts.relate_invoices',
                        params: { type: 'payments', id: row.id },
                      }"
                    >
                      <font-awesome-icon icon="plus-circle" />
                    </router-link>
                  </div>
                </div>
              </td>
              <td>
                <div
                  class="text-center d-flex align-items-center px-3"
                  v-if="row.id != undefined"
                >
                  <div class="mr-2">
                    <span
                      v-html="
                        $utils.formatMoney(row.expenses_amount, defaultCurrency)
                      "
                    ></span>
                  </div>
                  <div
                    v-if="row.expenses_amount > 0 && row.not_applicable == 0"
                    class="px-3 space-left"
                  >
                    <router-link
                      :to="{
                        name: 'treasury_accounts.relate_invoices',
                        params: { type: 'expenses', id: row.id },
                      }"
                    >
                      <font-awesome-icon icon="plus-circle" />
                    </router-link>
                  </div>
                </div>
              </td>
              <td
                class="text-center"
                v-html="$utils.formatMoney(row.total, defaultCurrency)"
              ></td>
              <td class="text-center">
                <div v-if="row.payments_amount > 0 && row.not_applicable == 0">
                  <template v-if="!row.general_public_invoiced_at">
                    <input
                      :checked="row.general_public_invoice"
                      type="checkbox"
                      value="1"
                      @change="toggleRowGeneralPublicInvoice(row)"
                    />
                  </template>
                </div>
              </td>
              <!-- importe sin relacionar -->
              <td class="text-center">
                <div
                  v-if="
                    row.id != undefined &&
                    !row.expenses_amount > 0 &&
                    row.not_applicable == 0
                  "
                  v-html="$utils.formatMoney(row.remanente, defaultCurrency)"
                ></div>
              </td>
              <!-- <td>
                <font-awesome-icon
                  @click="editDescription()"
                  v-tooltip="{
                    content: 'Editar descripción',
                  }"
                  class="icon mr-2"
                  icon="edit"
                />
              </td> -->
            </tr>
          </tbody>
        </table>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {},
  data: () => ({
    filters: {
      month: null,
      customer: null,
      year: null,
    },
    showFilters: true,
    treasuries: [],
  }),
  computed: {
    ...mapGetters('calendar', ['defaultMonth', 'defaultYear', 'months']),
    ...mapGetters('CurrentCustomer', ['currentCustomer']),
    ...mapGetters('currency', ['defaultCurrency']),
    ...mapGetters('assigned', ['assingedSelect']),

    filterIcon() {
      return this.showFilters ? 'times' : 'filter'
    },
  },
  // watch: {
  //   filters: {
  //     handler: 'setFilters',
  //     deep: true,
  //   },
  // },
  created() {
    let sessionFilterMonth
    if (sessionStorage.getItem('filtersPaymentsMonth') != 'undefined') {
      sessionFilterMonth = JSON.parse(
        sessionStorage.getItem('filtersPaymentsMonth')
      )
    }
    let sessionFilterYear = sessionStorage.getItem('filtersPaymentsYear')

    if (this.defaultMonth && this.defaultYear) {
      this.filters.month = this.defaultMonth
      this.filters.year = this.defaultYear
      sessionStorage.setItem(
        'filtersPaymentsMonth',
        JSON.stringify(this.filters.month)
      )
      sessionStorage.setItem('filtersPaymentsYear', this.filters.year)
    } else {
      this.filters.month = sessionFilterMonth
      this.filters.year = sessionFilterYear
    }

    if (!this.currentCustomer) {
      this.$router.push('/general/customers')
      return
    }
  },
  mounted() {
    this.getTreasuryAccounts()
  },
  methods: {
    ...mapActions('treasuryAccounts', [
      'fetchTreasuryAccounts',
      'toggleGeneralPublicInvoice',
      'toggleNotApplicable',
    ]),
    ...mapActions('modal', ['openModal']),

    async getTreasuryAccounts() {
      const params = {
        id: this.assingedSelect.id,
        customer_id: this.currentCustomer.id,
        month: this.filters.month
          ? this.filters.month.id
          : this.defaultMonth.id,
        year: this.filters.year,
        // orderByField: sort.fieldName || 'treasury_date',
        // orderBy: sort.order || 'desc',
        // page,
      }
      const response = await this.fetchTreasuryAccounts(params)
      this.treasuries = response.data
      // return {
      //   data: response.data.data.reverse(),
      //   pagination: {
      //     totalPages: response.data.last_page,
      //     currentPage: response.data.current_page,
      //     count: response.data.scount,
      //   },
      // }
    },
    clearFilter() {
      this.filters = {
        month: this.defaultMonth,
      }
    },
    refreshTable() {
      this.$refs.table.refresh()
    },
    // setFilters() {
    //   if (this.showFilters) {
    //     sessionStorage.setItem(
    //       'filtersPaymentsMonth',
    //       JSON.stringify(this.filters.month)
    //     )
    //     sessionStorage.setItem('filtersPaymentsYear', this.filters.year)
    //   }

    //   // this.filtersApplied = true
    //   this.refreshTable()
    // },
    toggleFilter() {
      if (this.showFilters && this.filtersApplied) {
        // this.clearFilter()
        // this.refreshTable();
      }

      this.showFilters = !this.showFilters
    },

    importarTransaccion(id) {
      this.$router.push({
        name: 'treasury_accounts.upload',
        params: { id: id },
      })
    },
    toggleRowGeneralPublicInvoice(row) {
      if (row['general_public_invoiced_at'] == null) {
        this.toggleGeneralPublicInvoice(row.id)
          .then((response) => {
            window.toastr.success(
              this.$t('payments.general_public_invoices.messages.saved_status')
            )
          })
          .catch((err) => {
            window.toastr.error(
              this.$t('payments.general_public_invoices.errors.save_status')
            )
          })
      }
    },

    styleRow(row) {
      if (row.not_applicable) {
        return 'background-color:#ececec'
      }
      if (row.remanente == row.payments_amount) {
        return 'background-color:white'
      }
      if (row.remanente == 0) {
        return 'background-color:#88c349'
      }
      if (row.remanente == row.expenses_amount) {
        return 'background-color:white'
      }
      if (row.remanente == 0) {
        return 'background-color:#88c349'
      }

      if (row.remanente > 0) {
        return 'background-color:yellow'
      }
      if (row.remanente == 0) {
        return 'background-color:#88c349'
      }

      if (row.remanente == 0) {
        return 'background-color:#88c349'
      }

      return ''
    },

    editDescription() {
      this.openModal({
        title: 'Editar descripción',
        componentName: 'EditDescriptionAccount',
      })
    },
    updateSelectedRows(row, index) {
      if (row.remanente == 0) {
        swal({
          title: this.$t('general.are_you_sure'),
          text: 'Este registro ya tiene un documento asignado. ¿Desea deshabilitarlo?',
          icon: '/assets/icon/times-circle-solid.svg',
          buttons: true,
          dangerMode: true,
        }).then(async (confirm) => {
          if (confirm) {
            this.updateNotApplicable(row, index)
          }
        })
      } else {
        this.updateNotApplicable(row, index)
      }
    },

    updateNotApplicable(row, index) {
      let treasury_accounts = this.treasuries[index]['treasury_accounts']
      let index_treasury = treasury_accounts.findIndex((treasury) => {
        return treasury.id == row.id
      })
      let treasury_accounts_child =
        this.treasuries[index]['treasury_accounts'][index_treasury]

      this.toggleNotApplicable(row.id)
        .then((response) => {
          window.toastr.success('Estatus cambiado')
          this.treasuries[index]['treasury_accounts'][index_treasury][
            'not_applicable'
          ] =
            !this.treasuries[index]['treasury_accounts'][index_treasury][
              'not_applicable'
            ]
        })
        .catch((err) => {
          console.log(err)
          window.toastr.error('Error al cambiar estatus')
        })
    },
  },
}
</script>
