<template>
  <div class="main-content">
    <div class="page-header">
      <h3 class="page-title">
        {{ $t('tax_returns.title') }}
      </h3>
    </div>

    <div class="card p-3">
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>{{ $t('tax_returns.period') }}</th>
              <th>{{ $t('tax_returns.year') }}</th>
              <th class="text-right">{{ $t('tax_returns.isr_to_pay') }}</th>
              <th class="text-right">
                {{ $t('tax_returns.iva_in_charge') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="taxReturn in taxReturns" :key="taxReturn.id">
              <td>
                <router-link
                  :to="{
                    name: 'customer_dashboard.tax-returns.details',
                    params: { id: taxReturn.id },
                  }"
                >
                  <month-period
                    :start-month="taxReturn.start_month"
                    :end-month="taxReturn.end_month"
                  />
                </router-link>
              </td>
              <td>
                <router-link
                  :to="{
                    name: 'customer_dashboard.tax-returns.details',
                    params: { id: taxReturn.id },
                  }"
                >
                  {{ taxReturn.year }}
                </router-link>
              </td>
              <td class="text-right">
                <router-link
                  :to="{
                    name: 'customer_dashboard.tax-returns.details',
                    params: { id: taxReturn.id },
                  }"
                >
                  <money-text :amount="taxReturn.isr_to_pay" />
                </router-link>
              </td>
              <td class="text-right">
                <router-link
                  :to="{
                    name: 'customer_dashboard.tax-returns.details',
                    params: { id: taxReturn.id },
                  }"
                >
                  <money-text :amount="taxReturn.iva_in_charge" />
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import MonthPeriod from '../../components/MonthPeriod.vue'
export default {
  components: { MonthPeriod },
  data: () => ({
    taxReturns: [],
  }),
  mounted() {
    this.loadTaxReturns()
  },
  methods: {
    ...mapActions('customerTaxReturns', ['fetchTaxReturns']),
    loadTaxReturns() {
      this.fetchTaxReturns().then((response) => {
        this.taxReturns = response.data.tax_returns
      })
    },
  },
}
</script>
