import * as types from './mutation-types'
import * as dashboardTypes from '../dashboard/mutation-types'

export const download = ({ commit, dispatch, state }, params) => {
  console.log(params);
  return new Promise((resolve, reject) => {
    window.axios.post(`/api/cfdi-download/generar-descarga`, params).then((response) => {
      console.log(response);
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}
