var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "p-3" }, [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.savePaquete()
          },
        },
      },
      [
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "input-group mb-3" }, [
            _c("label", { attrs: { for: "" } }, [_vm._v("Nombre del paquete")]),
            _vm._v(" "),
            _c("div", { staticClass: "input-group mb-3" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.nombre,
                    expression: "form.nombre",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text" },
                domProps: { value: _vm.form.nombre },
                on: {
                  change: function ($event) {
                    return _vm.$v.form.$touch()
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "nombre", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            !_vm.$v.form.nombre.required
              ? _c("div", { staticClass: "error" }, [
                  _vm._v(
                    "\n          El nombre del paquete es requerido.\n        "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input-group mb-3" }, [
            _c("label", { attrs: { for: "" } }, [_vm._v("Precio")]),
            _vm._v(" "),
            _c("div", { staticClass: "input-group mb-3" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.precio,
                    expression: "form.precio",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "number" },
                domProps: { value: _vm.form.precio },
                on: {
                  change: function ($event) {
                    return _vm.$v.form.$touch()
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "precio", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            !_vm.$v.form.precio.required
              ? _c("div", { staticClass: "error" }, [
                  _vm._v("\n          La precio es requerido.\n        "),
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.$v.form.precio.minValueValue
              ? _c("div", { staticClass: "error" }, [
                  _vm._v(
                    "\n          La precio debe ser mayor a cero.\n        "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input-group mb-3" }, [
            _c("label", { attrs: { for: "" } }, [
              _vm._v("Periodicidad en meses"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "input-group mb-3" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.periodicidad,
                      expression: "form.periodicidad",
                    },
                  ],
                  staticClass: "custom-select",
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.form,
                          "periodicidad",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        return _vm.$v.form.$touch()
                      },
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "", selected: "" } }, [
                    _vm._v("Elegir..."),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.selectPeriodicidad, function (period) {
                    return _c(
                      "option",
                      { key: period.id, domProps: { value: period } },
                      [_vm._v(_vm._s(period.nombre))]
                    )
                  }),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            !_vm.$v.form.periodicidad.required
              ? _c("div", { staticClass: "error" }, [
                  _vm._v("\n          La periodicidad es requerido.\n        "),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input-group mb-3" }, [
            _c("label", { attrs: { for: "" } }, [_vm._v("Cantidad de folios")]),
            _vm._v(" "),
            _c("div", { staticClass: "input-group mb-3" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.cantidad_folios,
                    expression: "form.cantidad_folios",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "number" },
                domProps: { value: _vm.form.cantidad_folios },
                on: {
                  change: function ($event) {
                    return _vm.$v.form.$touch()
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "cantidad_folios", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            !_vm.$v.form.cantidad_folios.required
              ? _c("div", { staticClass: "error" }, [
                  _vm._v("\n          La cantidad es requerido.\n        "),
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.$v.form.cantidad_folios.minValueValue
              ? _c("div", { staticClass: "error" }, [
                  _vm._v(
                    "\n          La cantidad debe ser mayor a cero.\n        "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input-group mb-3" }, [
            _c("label", { attrs: { for: "" } }, [
              _vm._v("Descripción del paquete"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "input-group mb-3" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.descripcion,
                    expression: "form.descripcion",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text" },
                domProps: { value: _vm.form.descripcion },
                on: {
                  change: function ($event) {
                    return _vm.$v.form.$touch()
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "descripcion", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            !_vm.$v.form.descripcion.required
              ? _c("div", { staticClass: "error" }, [
                  _vm._v(
                    "\n          La descripción del paquete es requerido.\n        "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input-group mb-3" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.regimen,
                    expression: "form.regimen",
                  },
                ],
                staticClass: "custom-select",
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.form,
                        "regimen",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                    function ($event) {
                      return _vm.$v.form.$touch()
                    },
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "", selected: "" } }, [
                  _vm._v("Elegir..."),
                ]),
                _vm._v(" "),
                _vm._l(_vm.selectRegimen, function (regimen) {
                  return _c(
                    "option",
                    { key: regimen.id, domProps: { value: regimen.id } },
                    [_vm._v(_vm._s(regimen.name))]
                  )
                }),
              ],
              2
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-right" }, [
          _c(
            "button",
            { staticClass: "btn btn-primary", attrs: { type: "submit" } },
            [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("invoices.save")) + "\n      "
              ),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c(
        "label",
        {
          staticClass: "input-group-text",
          attrs: { for: "inputGroupSelect01" },
        },
        [_vm._v("Regimen")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }