var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "p-3" }, [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.save.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", { staticClass: "label-form", attrs: { for: "year" } }, [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("tax_losses.year")) + "\n      "
              ),
            ]),
            _vm._v(" "),
            _c("base-input", {
              attrs: { placeholder: _vm.$t("tax_losses.year"), type: "number" },
              on: {
                change: function ($event) {
                  return _vm.$v.form.year.$touch()
                },
              },
              model: {
                value: _vm.form.year,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "year", $$v)
                },
                expression: "form.year",
              },
            }),
            _vm._v(" "),
            _vm.$v.form.year.$error
              ? _c("div", [
                  !_vm.$v.form.year.required
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("validation.required")) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", { attrs: { for: "perodicity" } }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("tax_losses.periodicity")) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c("base-select", {
              attrs: {
                options: _vm.catalogs.periodicity,
                searchable: true,
                "show-labels": false,
                placeholder: _vm.$t("tax_losses.periodicity"),
                label: "description",
              },
              on: {
                change: function ($event) {
                  return _vm.$v.form.periodicity.$touch()
                },
              },
              model: {
                value: _vm.form.periodicity,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "periodicity", $$v)
                },
                expression: "form.periodicity",
              },
            }),
            _vm._v(" "),
            _vm.$v.form.periodicity.$error
              ? _c("div", [
                  !_vm.$v.form.periodicity.required
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("validation.required")) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", { attrs: { for: "tax_return_type" } }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("tax_losses.tax_return_type")) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c("base-select", {
              attrs: {
                options: _vm.catalogs.tax_return_types,
                searchable: true,
                "show-labels": false,
                placeholder: _vm.$t("tax_losses.tax_return_type"),
                label: "description",
              },
              on: {
                change: function ($event) {
                  return _vm.$v.form.tax_return_type.$touch()
                },
              },
              model: {
                value: _vm.form.tax_return_type,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "tax_return_type", $$v)
                },
                expression: "form.tax_return_type",
              },
            }),
            _vm._v(" "),
            _vm.$v.form.tax_return_type.$error
              ? _c("div", [
                  !_vm.$v.form.tax_return_type.required
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("validation.required")) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c(
              "label",
              { staticClass: "label-form", attrs: { for: "operation_number" } },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("tax_losses.operation_number")) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c("base-input", {
              attrs: { placeholder: _vm.$t("tax_losses.operation_number") },
              on: {
                change: function ($event) {
                  return _vm.$v.form.operation_number.$touch()
                },
              },
              model: {
                value: _vm.form.operation_number,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "operation_number", $$v)
                },
                expression: "form.operation_number",
              },
            }),
            _vm._v(" "),
            _vm.$v.form.operation_number.$error
              ? _c("div", [
                  !_vm.$v.form.operation_number.required
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("validation.required")) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c(
              "label",
              {
                staticClass: "label-form",
                attrs: { for: "date_of_presentation" },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("tax_losses.date_of_presentation")) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c("base-date-picker", {
              attrs: {
                "calendar-button": true,
                "calendar-button-icon": "calendar",
              },
              on: {
                change: function ($event) {
                  return _vm.$v.form.presentation_date.$touch()
                },
              },
              model: {
                value: _vm.form.presentation_date,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "presentation_date", $$v)
                },
                expression: "form.presentation_date",
              },
            }),
            _vm._v(" "),
            _vm.$v.form.presentation_date.$error
              ? _c("div", [
                  !_vm.$v.form.presentation_date.required
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("validation.required")) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _vm.showTaxLoss
          ? _c(
              "div",
              { staticClass: "form-group base-input" },
              [
                !_vm.showRIFLabel
                  ? _c(
                      "label",
                      { staticClass: "label-form", attrs: { for: "amount" } },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("tax_losses.tax_loss")) +
                            "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showRIFLabel
                  ? _c(
                      "label",
                      { staticClass: "label-form", attrs: { for: "amount" } },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.$t("tax_losses.deduction_greater_than_income")
                            ) +
                            "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "money",
                  _vm._b(
                    {
                      staticClass: "input-field text-right",
                      on: {
                        change: function ($event) {
                          return _vm.$v.form.amount.$touch()
                        },
                      },
                      model: {
                        value: _vm.form.amount,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "amount", $$v)
                        },
                        expression: "form.amount",
                      },
                    },
                    "money",
                    _vm.customerCurrency,
                    false
                  )
                ),
                _vm._v(" "),
                _vm.$v.form.amount.$error
                  ? _c("div", [
                      !_vm.$v.form.amount.required
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("validation.required")) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.showIsrInFavor
          ? _c(
              "div",
              { staticClass: "form-group base-input" },
              [
                _c(
                  "label",
                  { staticClass: "label-form", attrs: { for: "isr_in_favor" } },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("tax_losses.isr_in_favor")) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "money",
                  _vm._b(
                    {
                      staticClass: "input-field text-right",
                      on: {
                        change: function ($event) {
                          return _vm.$v.form.isr_in_favor.$touch()
                        },
                      },
                      model: {
                        value: _vm.form.isr_in_favor,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "isr_in_favor", $$v)
                        },
                        expression: "form.isr_in_favor",
                      },
                    },
                    "money",
                    _vm.customerCurrency,
                    false
                  )
                ),
                _vm._v(" "),
                _vm.$v.form.isr_in_favor.$error
                  ? _c("div", [
                      !_vm.$v.form.isr_in_favor.required
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("validation.required")) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.showIvaInFavor
          ? _c(
              "div",
              { staticClass: "form-group base-input" },
              [
                _c(
                  "label",
                  { staticClass: "label-form", attrs: { for: "iva_in_favor" } },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("tax_losses.iva_in_favor")) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "money",
                  _vm._b(
                    {
                      staticClass: "input-field text-right",
                      on: {
                        change: function ($event) {
                          return _vm.$v.form.iva_in_favor.$touch()
                        },
                      },
                      model: {
                        value: _vm.form.iva_in_favor,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "iva_in_favor", $$v)
                        },
                        expression: "form.iva_in_favor",
                      },
                    },
                    "money",
                    _vm.customerCurrency,
                    false
                  )
                ),
                _vm._v(" "),
                _vm.$v.form.iva_in_favor.$error
                  ? _c("div", [
                      !_vm.$v.form.iva_in_favor.required
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("validation.required")) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-footer btn-container" },
          [
            _c(
              "base-button",
              {
                attrs: {
                  loading: _vm.isLoading,
                  color: "theme",
                  icon: "save",
                  type: "submit",
                },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("general.save")) + "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }