var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-content" },
    [
      _c("div", { staticClass: "page-header mt-4" }, [
        _c("h3", { staticClass: "page-title" }, [
          _vm._v(_vm._s(_vm.$t("concept_categories.categories"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "page-actions row" }, [
          _c(
            "div",
            { staticClass: "col-4" },
            [
              _c(
                "base-button",
                {
                  attrs: { color: "theme" },
                  on: {
                    click: function ($event) {
                      return _vm.openAddCategoryModal()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("concept_categories.add_category")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm._l(_vm.categories, function (category) {
        return _c(
          "div",
          { key: category.id, staticClass: "card p-2" },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-8" }, [
                _c("h5", [_vm._v(_vm._s(category.name))]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-4 flex-content-right" },
                [
                  category.removeable
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "base-button btn btn-sm btn-danger text-white",
                          on: {
                            click: function ($event) {
                              return _vm.deleteCategory(category)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.delete")) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v("\n         \n        "),
                  _c(
                    "base-button",
                    {
                      attrs: { color: "theme-light", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.openAddSubCategoryModal(category)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("concept_categories.add_subcategory")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm._l(category.concept_subcategories, function (subcategory) {
              return _c("div", { key: subcategory.id, staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12 col-md-6 pl-5 flex-vertical-center" },
                  [_vm._v("\n        " + _vm._s(subcategory.name) + "\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-12 col-md-2 flex-vertical-center" },
                  [
                    subcategory.removeable
                      ? _c("base-switch", {
                          on: {
                            change: function ($event) {
                              return _vm.updateSubcategoryInTaxReturn(
                                subcategory
                              )
                            },
                          },
                          model: {
                            value: subcategory.in_tax_return,
                            callback: function ($$v) {
                              _vm.$set(subcategory, "in_tax_return", $$v)
                            },
                            expression: "subcategory.in_tax_return",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-12 col-md-2 flex-vertical-center" },
                  [
                    subcategory.removeable
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-danger text-white",
                            on: {
                              click: function ($event) {
                                return _vm.deleteSubcategory(subcategory)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("general.delete")) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ])
            }),
          ],
          2
        )
      }),
      _vm._v(" "),
      _vm.paginationCategories
        ? _c("pagination", {
            attrs: { pagination: _vm.paginationCategories },
            on: { pageChange: _vm.pageChange },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }