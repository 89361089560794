var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip.top-center",
          value: _vm.description,
          expression: "description",
          modifiers: { "top-center": true },
        },
      ],
      staticClass: "text-center",
    },
    [
      _c("font-awesome-icon", {
        staticClass: "icon",
        class: _vm.iconClass,
        attrs: { icon: _vm.iconName, size: "lg" },
        on: {
          click: function ($event) {
            return _vm.$emit("click")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }