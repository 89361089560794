var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-content treasuries-accounts" },
    [
      _c("div", { staticClass: "page-header mt-5" }, [
        _c("h3", { staticClass: "page-title" }, [_vm._v("Cuentas")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "page-actions row" },
          [
            _c(
              "router-link",
              {
                staticClass: "mr-2",
                attrs: { to: { name: "account.create" } },
              },
              [
                _c(
                  "base-button",
                  {
                    attrs: {
                      outline: true,
                      color: "theme",
                      icon: "plus",
                      size: "large",
                    },
                  },
                  [_vm._v("\n          Agregar\n        ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm._l(_vm.treasuries, function (cuenta, index) {
        return _c(
          "div",
          { key: cuenta },
          [
            _c(
              "b-card-header",
              { attrs: { "header-tag": "header", role: "tab" } },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "b-toggle",
                        rawName: "v-b-toggle",
                        value: "collapse-cuenta-" + index,
                        expression: "'collapse-cuenta-' + index",
                      },
                    ],
                    staticClass:
                      "d-flex justify-content-between align-items-center",
                    attrs: { variant: "primary" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "pl-2 font-weight-bold text-uppercase" },
                      [
                        _vm._v(
                          "\n          " + _vm._s(cuenta.name) + "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div"),
                  ]
                ),
              ]
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }