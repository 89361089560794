<template>
  <div v-if="isAppLoaded" class="template-container">
    <customer-header />
    <customer-sidebar />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <site-footer />
  </div>
  <div v-else class="template-container">
    <font-awesome-icon icon="spinner" class="fa-spin" />
  </div>
</template>

<script>
import CustomerSidebar from './partials/CustomerSiteSidebar'
import CustomerHeader from './partials/CustomerHeader'
import SiteFooter from './partials/TheSiteFooter'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    CustomerSidebar,
    CustomerHeader,
    SiteFooter,
  },
  computed: {
    ...mapGetters(['isAppLoaded']),
  },
  created() {
    this.customerBootstrap()
    this.fetchCustomerProfile()
  },
  methods: {
    ...mapActions(['customerBootstrap']),
    ...mapActions('customerModuleUser', ['fetchCustomerProfile']),
  },
}
</script>
