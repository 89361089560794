<template>
  <span>{{ tax }}</span>
</template>

<script>
export default {
  props: {
    taxCode: {
      default: '',
      type: String
    }
  },
  data: () => ({
    catalog: [
      {
        code: '001',
        description: 'ISR',
      },
      {
        code: '002',
        description: 'IVA',
      },
      {
        code: '003',
        description: 'IEPS',
      },
    ],
  }),
  computed: {
    tax () {
      const tax = this.catalog.filter(t => t.code == this.taxCode)
      if (tax.length) {
        return `${tax[0].code} - ${tax[0].description}`
      }
      return ''
    }
  }
}
</script>
