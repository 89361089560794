<template>
  <div>
    {{ value }}
  </div>
</template>

<script>
export default {
  props: {
    code: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    catalog: [
      {
        id: '01',
        description: 'Nota de crédito de los documentos relacionados',
      },
      {
        id: '02',
        description: 'Nota de débito de los documentos relacionados',
      },
      {
        id: '03',
        description:
          'Devolución de mercancía sobre facturas o traslados previos',
      },
      {
        id: '04',
        description: 'Sustitución de los CFDI previos',
      },
      {
        id: '05',
        description: 'Traslados de mercancias facturados previamente',
      },
      {
        id: '06',
        description: 'Factura generada por los traslados previos',
      },
      {
        id: '07',
        description: 'CFDI por aplicación de anticipo',
      },
      {
        id: '08',
        description: 'Factura generada por pagos en parcialidades',
      },
      {
        id: '09',
        description: 'Factura generada por pagos diferidos',
      },
    ],
  }),
  computed: {
    value() {
      const filtered = this.catalog.filter((item) => {
        return item.id == this.code
      })
      if (filtered.length) {
        return filtered[0].description
      }
      return this.code
    },
  },
}
</script>
