<template>
  <money-text :amount="taxToPrint" />
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    taxCode: {
      default: '02',
      type: String
    },
    taxes: {
      default: () => [],
      type: Array,
    }
  },
  computed: {
    ...mapGetters('currency', [
      'defaultCurrency'
    ]),
    taxToPrint () {
      const tax = this.taxes.filter((taxItem) => taxItem.tax == this.taxCode);
      if (tax.length) {
        return Number(tax[0].retention_amount);
      }
      return 0;
    }
  }
}
</script>
