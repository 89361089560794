var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.taxReturn
    ? _c("div", { staticClass: "table-responsive" }, [
        _c("table", { staticClass: "table table-bordered" }, [
          _c("thead", [
            _c("tr", { staticClass: "table-primary" }, [
              _c(
                "th",
                { staticClass: "text-center", attrs: { colspan: "2" } },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("tax_returns.isr")) +
                      "\n        "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("tbody", [
            _c("tr", [
              _c("td", [
                _vm._v(_vm._s(_vm.$t("tax_returns.revenue_collected"))),
              ]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("money-text", {
                    attrs: { amount: _vm.taxReturn.revenue_collected },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [_vm._v(_vm._s(_vm.$t("tax_returns.paid_salaries")))]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("money-text", {
                    attrs: { amount: _vm.taxReturn.paid_salaries },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [_vm._v(_vm._s(_vm.$t("tax_returns.purchases")))]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("money-text", {
                    attrs: { amount: _vm.taxReturn.purchases_subtotal },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [_vm._v(_vm._s(_vm.$t("tax_returns.deductions")))]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("money-text", {
                    attrs: { amount: _vm.taxReturn.isr_in_tax_return },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t("tax_returns.excess_of_expenses_over_revenue")
                    ) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("money-text", {
                    attrs: {
                      amount: _vm.taxReturn.excess_expenses_over_revenue,
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t("tax_returns.excess_of_expenses_previous_period")
                    ) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("money-text", {
                    attrs: {
                      amount: _vm.taxReturn.excess_expenses_previous_periods,
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("tax_returns.isr_base")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("money-text", {
                    attrs: { amount: _vm.taxReturn.isr_base },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("tax_returns.taxable_base")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("money-text", {
                    attrs: { amount: _vm.taxReturn.taxable_base },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("rate_tables.lower_limit")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("money-text", {
                    attrs: { amount: _vm.taxReturn.lower_limit },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("tax_returns.surplus_lower_limit")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("money-text", {
                    attrs: { amount: _vm.taxReturn.surplus_lower_limit },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("tax_returns.rate_on_surplus")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "text-right" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.taxReturn.rate_on_surplus) +
                    " %\n          "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("tax_returns.marginal_amount")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("money-text", {
                    attrs: { amount: _vm.taxReturn.marginal_amount },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("rate_tables.fixed_fee")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("money-text", {
                    attrs: { amount: _vm.taxReturn.fixed_fee },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("tax_returns.isr_caused")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("money-text", {
                    attrs: { amount: _vm.taxReturn.isr_caused },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("tax_returns.condonation_percentage")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "text-right" }, [
                _vm._v(_vm._s(_vm.taxReturn.condonation_percentage) + " %"),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("tax_returns.amount_forgiven")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("money-text", {
                    attrs: { amount: _vm.taxReturn.amount_forgiven },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("tax_returns.isr_to_pay")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("money-text", {
                    attrs: { amount: _vm.taxReturn.isr_to_pay },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("table", { staticClass: "table table-bordered" }, [
          _c("thead", [
            _c("tr", { staticClass: "table-primary" }, [
              _c(
                "th",
                { staticClass: "text-center", attrs: { colspan: "2" } },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("tax_returns.iva")) +
                      "\n        "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("tbody", [
            _c("tr", [
              _c("td", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("tax_returns.revenue_general_public_sales")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("money-text", {
                    attrs: {
                      amount: _vm.taxReturn.revenue_general_public_sales,
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _vm._v(_vm._s(_vm.$t("tax_returns.revenue_individual_sales"))),
              ]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("money-text", {
                    attrs: { amount: _vm.taxReturn.revenue_individual_sales },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [_vm._v(_vm._s(_vm.$t("tax_returns.total_revenue")))]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("money-text", {
                    attrs: { amount: _vm.taxReturn.total_revenue },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _vm._v(
                  _vm._s(_vm.$t("tax_returns.percent_iva_general_public"))
                ),
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "text-right" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.taxReturn.percentage_iva_general_public) +
                    " %\n        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _vm._v(_vm._s(_vm.$t("tax_returns.iva_general_public_sales"))),
              ]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("money-text", {
                    attrs: { amount: _vm.taxReturn.iva_general_public },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _vm._v(_vm._s(_vm.$t("tax_returns.percentage_iva_reduction"))),
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "text-right" }, [
                _vm._v(_vm._s(_vm.taxReturn.percentage_iva_reduction) + " %"),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [_vm._v(_vm._s(_vm.$t("tax_returns.iva_reduction")))]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("money-text", {
                    attrs: { amount: _vm.taxReturn.iva_reduction },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _vm._v(
                  _vm._s(_vm.$t("tax_returns.iva_caused_general_public_sales"))
                ),
              ]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("money-text", {
                    attrs: {
                      amount: _vm.taxReturn.iva_caused_general_public_sales,
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _vm._v(
                  _vm._s(_vm.$t("tax_returns.iva_caused_individual_sales"))
                ),
              ]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("money-text", {
                    attrs: {
                      amount: _vm.taxReturn.iva_caused_induvidual_sales,
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _vm._v(_vm._s(_vm.$t("tax_returns.total_iva_caused"))),
              ]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("money-text", {
                    attrs: { amount: _vm.taxReturn.total_iva_caused },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "tax_returns.purchases_and_expenses_paid_16_percent"
                      )
                    ) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("money-text", {
                    attrs: {
                      amount: _vm.taxReturn.purchases_and_expenses_paid16,
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t("tax_returns.purchases_and_expenses_no_object")
                    ) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("money-text", {
                    attrs: {
                      amount: _vm.taxReturn.purchases_and_expenses_no_object,
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("tax_returns.total_purchases")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("money-text", {
                    attrs: { amount: _vm.taxReturn.total_purchases },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("tax_returns.iva_paid_bimester")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("money-text", {
                    attrs: { amount: _vm.taxReturn.iva_paid_bimester },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("tax_returns.proportion_of_iva_creditable")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "text-right" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.taxReturn.proportion_of_iva_creditable) +
                    " %\n        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("tax_returns.iva_creditable")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("money-text", {
                    attrs: { amount: _vm.taxReturn.iva_creditable },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("tax_returns.iva_in_charge")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("money-text", {
                    attrs: { amount: _vm.taxReturn.iva_in_charge },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }