var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.estimate
    ? _c("div", { staticClass: "main-content estimate-view-page" }, [
        _c("div", { staticClass: "page-header" }, [
          _c("h3", { staticClass: "page-title" }, [
            _vm._v(_vm._s(_vm.estimate.estimate_number)),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page-actions row" },
            [
              _c(
                "div",
                { staticClass: "col-xs-2 mr-3" },
                [
                  _vm.estimate.status === "DRAFT"
                    ? _c(
                        "base-button",
                        {
                          attrs: {
                            loading: _vm.isMarkAsSent,
                            disabled: _vm.isMarkAsSent,
                            outline: true,
                            color: "theme",
                          },
                          on: { click: _vm.onMarkAsSent },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("estimates.mark_as_sent")) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-xs-2" },
                [
                  _vm.estimate.status === "DRAFT"
                    ? _c(
                        "base-button",
                        {
                          attrs: {
                            loading: _vm.isSendingEmail,
                            disabled: _vm.isSendingEmail,
                            color: "theme",
                          },
                          on: { click: _vm.onSendEstimate },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("estimates.send_estimate")) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-dropdown",
                {
                  staticClass: "filter-container",
                  attrs: { "close-on-select": true, align: "left" },
                },
                [
                  _c(
                    "a",
                    {
                      attrs: { slot: "activator", href: "#" },
                      slot: "activator",
                    },
                    [
                      _c(
                        "base-button",
                        { attrs: { color: "theme" } },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "ellipsis-h" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-dropdown-item",
                    [
                      _c(
                        "div",
                        {
                          staticClass: "dropdown-item",
                          on: {
                            click: function ($event) {
                              return _vm.copyPdfUrl()
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "dropdown-item-icon",
                            attrs: { icon: ["fas", "link"] },
                          }),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("general.copy_pdf_url")) +
                              "\n          "
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: {
                            to: {
                              path: `/general/estimates/${_vm.$route.params.id}/edit`,
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "dropdown-item-icon",
                            attrs: { icon: ["fas", "pencil-alt"] },
                          }),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("general.edit")) +
                              "\n          "
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "dropdown-item",
                          on: {
                            click: function ($event) {
                              return _vm.removeEstimate(_vm.$route.params.id)
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "dropdown-item-icon",
                            attrs: { icon: ["fas", "trash"] },
                          }),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("general.delete")) +
                              "\n          "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "estimate-sidebar" },
          [
            _vm.isSearching
              ? _c("base-loader")
              : _c(
                  "div",
                  { staticClass: "side-header" },
                  [
                    _c("base-input", {
                      attrs: {
                        placeholder: _vm.$t("general.search"),
                        "input-class": "inv-search",
                        icon: "search",
                        type: "text",
                        "align-icon": "right",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.onSearched()
                        },
                      },
                      model: {
                        value: _vm.searchData.searchText,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchData, "searchText", $$v)
                        },
                        expression: "searchData.searchText",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "btn-group ml-3",
                        attrs: { role: "group", "aria-label": "First group" },
                      },
                      [
                        _c(
                          "v-dropdown",
                          {
                            staticClass: "filter-container",
                            attrs: { "close-on-select": false, align: "left" },
                          },
                          [
                            _c(
                              "a",
                              {
                                attrs: { slot: "activator", href: "#" },
                                slot: "activator",
                              },
                              [
                                _c(
                                  "base-button",
                                  {
                                    staticClass:
                                      "inv-button inv-filter-fields-btn",
                                    attrs: { color: "default", size: "medium" },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "filter" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "filter-title" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("general.sort_by")) +
                                  "\n          "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "filter-items" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.searchData.orderByField,
                                    expression: "searchData.orderByField",
                                  },
                                ],
                                staticClass: "inv-radio",
                                attrs: {
                                  id: "filter_estimate_date",
                                  type: "radio",
                                  name: "filter",
                                  value: "estimate_date",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.searchData.orderByField,
                                    "estimate_date"
                                  ),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      return _vm.$set(
                                        _vm.searchData,
                                        "orderByField",
                                        "estimate_date"
                                      )
                                    },
                                    _vm.onSearched,
                                  ],
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "inv-label",
                                  attrs: { for: "filter_estimate_date" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("reports.estimates.estimate_date")
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "filter-items" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.searchData.orderByField,
                                    expression: "searchData.orderByField",
                                  },
                                ],
                                staticClass: "inv-radio",
                                attrs: {
                                  id: "filter_due_date",
                                  type: "radio",
                                  name: "filter",
                                  value: "expiry_date",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.searchData.orderByField,
                                    "expiry_date"
                                  ),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      return _vm.$set(
                                        _vm.searchData,
                                        "orderByField",
                                        "expiry_date"
                                      )
                                    },
                                    _vm.onSearched,
                                  ],
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "inv-label",
                                  attrs: { for: "filter_due_date" },
                                },
                                [_vm._v(_vm._s(_vm.$t("estimates.due_date")))]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "filter-items" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.searchData.orderByField,
                                    expression: "searchData.orderByField",
                                  },
                                ],
                                staticClass: "inv-radio",
                                attrs: {
                                  id: "filter_estimate_number",
                                  type: "radio",
                                  name: "filter",
                                  value: "estimate_number",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.searchData.orderByField,
                                    "estimate_number"
                                  ),
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      return _vm.$set(
                                        _vm.searchData,
                                        "orderByField",
                                        "estimate_number"
                                      )
                                    },
                                    _vm.onSearched,
                                  ],
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "inv-label",
                                  attrs: { for: "filter_estimate_number" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("estimates.estimate_number"))
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "base-button",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip.top-center",
                                value: { content: _vm.getOrderName },
                                expression: "{ content: getOrderName }",
                                modifiers: { "top-center": true },
                              },
                            ],
                            staticClass: "inv-button inv-filter-sorting-btn",
                            attrs: { color: "default", size: "medium" },
                            on: { click: _vm.sortData },
                          },
                          [
                            _vm.getOrderBy
                              ? _c("font-awesome-icon", {
                                  attrs: { icon: "sort-amount-up" },
                                })
                              : _c("font-awesome-icon", {
                                  attrs: { icon: "sort-amount-down" },
                                }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "side-content" },
              [
                _vm._l(_vm.estimates, function (estimate, index) {
                  return _c(
                    "router-link",
                    {
                      key: index,
                      staticClass: "side-estimate",
                      attrs: { to: `/general/estimates/${estimate.id}/view` },
                    },
                    [
                      _c("div", { staticClass: "left" }, [
                        _c("div", { staticClass: "inv-name" }, [
                          _vm._v(_vm._s(estimate.user.name)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "inv-number" }, [
                          _vm._v(_vm._s(estimate.estimate_number)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "inv-status",
                            class:
                              "est-status-" + estimate.status.toLowerCase(),
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(estimate.status) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "right" }, [
                        _c("div", {
                          staticClass: "inv-amount",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$utils.formatMoney(
                                estimate.total,
                                estimate.user.currency
                              )
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "inv-date" }, [
                          _vm._v(_vm._s(estimate.formattedEstimateDate)),
                        ]),
                      ]),
                    ]
                  )
                }),
                _vm._v(" "),
                !_vm.estimates.length
                  ? _c("p", { staticClass: "no-result" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("estimates.no_matching_estimates")) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
              ],
              2
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "estimate-view-page-container" }, [
          _c("iframe", {
            staticClass: "frame-style",
            attrs: { src: `${_vm.shareableLink}` },
          }),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }