import mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'
import { catalogs } from './catalogs'

const initialState = {
  tax_losses: [],
  tax_loss: null,
  positive_balance: [],
  catalogs: catalogs,
}

export default {
  namespaced: true,
  state: initialState,
  getters: getters,
  actions: actions,
  mutations: mutations,
}
