<template>
    <div
      v-tooltip.top-center="description"
      class="text-center"
    >
      <font-awesome-icon
      :class="iconClass"
      :icon="iconName"
      @click="$emit('click')"
      class="icon"
      size="lg"
    />
    </div>
</template>

<script>
import { mapActions } from 'vuex'
const STATUS_COLORS = {
  enviar: 'text-black',
  revision: 'text-warning',
  comentarios: 'text-danger',
  aceptado: 'text-success',
  cerrado: 'text-blue',
}
const STATUS_ICONS = {
  enviar: 'paper-plane',
  revision: 'user-clock',
  comentarios: 'comment-dots',
  aceptado: 'check-circle',
  cerrado: 'check-circle',
}
export default {
  props: {
    status: {
      default: 'UNASSIGNED',
      type: String
    },
    id: {
      default: 0,
      type: Number
    },
  },
  data: () => ({
    statusIcon: '',
  }),
  computed: {
    iconClass () {
      return STATUS_COLORS[this.statusIcon];
    },
    iconName () {
      this.statusIcon = this.status ? this.status : 'enviar';
      return STATUS_ICONS[this.statusIcon];
    },
    description () {
      return this.statusIcon
    }
  },
}
</script>