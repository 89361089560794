var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "estimate-create-page main-content" }, [
    !_vm.initLoading
      ? _c(
          "form",
          {
            attrs: { action: "" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submitEstimateData.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "page-header" }, [
              _vm.$route.name === "estimates.edit"
                ? _c("h3", { staticClass: "page-title" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("estimates.edit_estimate")) +
                        "\n      "
                    ),
                  ])
                : _c("h3", { staticClass: "page-title" }, [
                    _vm._v(_vm._s(_vm.$t("estimates.new_estimate"))),
                  ]),
              _vm._v(" "),
              _c("ol", { staticClass: "breadcrumb" }, [
                _c(
                  "li",
                  { staticClass: "breadcrumb-item" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: { slot: "item-title", to: "/general/dashboard" },
                        slot: "item-title",
                      },
                      [_vm._v(_vm._s(_vm.$t("general.home")))]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "breadcrumb-item" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: { slot: "item-title", to: "/general/estimates" },
                        slot: "item-title",
                      },
                      [_vm._v(_vm._s(_vm.$tc("estimates.estimate", 2)))]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.$route.name === "estimates.edit"
                  ? _c("li", { staticClass: "breadcrumb-item" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("estimates.edit_estimate")) +
                          "\n        "
                      ),
                    ])
                  : _c("li", { staticClass: "breadcrumb-item" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("estimates.new_estimate")) +
                          "\n        "
                      ),
                    ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "page-actions row" },
                [
                  _vm.$route.name === "estimates.edit"
                    ? _c(
                        "a",
                        {
                          staticClass:
                            "mr-3 base-button btn btn-outline-primary default-size invoice-action-btn",
                          attrs: {
                            href: `/estimates/pdf/${_vm.newEstimate.unique_hash}`,
                            target: "_blank",
                            outline: "",
                            color: "theme",
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.view_pdf")) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "base-button",
                    {
                      staticClass: "invoice-action-btn",
                      attrs: {
                        loading: _vm.isLoading,
                        disabled: _vm.isLoading,
                        icon: "save",
                        color: "theme",
                        type: "submit",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("estimates.save_estimate")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row estimate-input-group" }, [
              _c(
                "div",
                { staticClass: "col-md-5 estimate-customer-container" },
                [
                  _vm.selectedCustomer
                    ? _c("div", { staticClass: "show-customer" }, [
                        _c("div", { staticClass: "row px-2 mt-1" }, [
                          _vm.selectedCustomer.billing_address
                            ? _c("div", { staticClass: "col col-6" }, [
                                _c("div", { staticClass: "row address-menu" }, [
                                  _c(
                                    "label",
                                    { staticClass: "col-sm-4 px-2 title" },
                                    [_vm._v(_vm._s(_vm.$t("general.bill_to")))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-sm p-0 px-2 content" },
                                    [
                                      _vm.selectedCustomer.billing_address.name
                                        ? _c("label", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.selectedCustomer
                                                    .billing_address.name
                                                ) +
                                                "\n                  "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.selectedCustomer.billing_address
                                        .address_street_1
                                        ? _c("label", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.selectedCustomer
                                                    .billing_address
                                                    .address_street_1
                                                ) +
                                                "\n                  "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.selectedCustomer.billing_address
                                        .address_street_2
                                        ? _c("label", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.selectedCustomer
                                                    .billing_address
                                                    .address_street_2
                                                ) +
                                                "\n                  "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.selectedCustomer.billing_address
                                        .city &&
                                      _vm.selectedCustomer.billing_address.state
                                        ? _c("label", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.selectedCustomer
                                                    .billing_address.city
                                                ) +
                                                ",\n                    " +
                                                _vm._s(
                                                  _vm.selectedCustomer
                                                    .billing_address.state
                                                ) +
                                                "\n                    " +
                                                _vm._s(
                                                  _vm.selectedCustomer
                                                    .billing_address.zip
                                                ) +
                                                "\n                  "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.selectedCustomer.billing_address
                                        .country
                                        ? _c("label", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.selectedCustomer
                                                    .billing_address.country
                                                    .name
                                                ) +
                                                "\n                  "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.selectedCustomer.billing_address.phone
                                        ? _c("label", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.selectedCustomer
                                                    .billing_address.phone
                                                ) +
                                                "\n                  "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.selectedCustomer.shipping_address
                            ? _c("div", { staticClass: "col col-6" }, [
                                _c("div", { staticClass: "row address-menu" }, [
                                  _c(
                                    "label",
                                    { staticClass: "col-sm-4 px-2 title" },
                                    [_vm._v(_vm._s(_vm.$t("general.ship_to")))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-sm p-0 px-2 content" },
                                    [
                                      _vm.selectedCustomer.shipping_address.name
                                        ? _c("label", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.selectedCustomer
                                                    .shipping_address.name
                                                ) +
                                                "\n                  "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.selectedCustomer.shipping_address
                                        .address_street_1
                                        ? _c("label", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.selectedCustomer
                                                    .shipping_address
                                                    .address_street_1
                                                ) +
                                                "\n                  "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.selectedCustomer.shipping_address
                                        .address_street_2
                                        ? _c("label", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.selectedCustomer
                                                    .shipping_address
                                                    .address_street_2
                                                ) +
                                                "\n                  "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.selectedCustomer.shipping_address
                                        .city &&
                                      _vm.selectedCustomer.shipping_address
                                        ? _c("label", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.selectedCustomer
                                                    .shipping_address.city
                                                ) +
                                                ",\n                    " +
                                                _vm._s(
                                                  _vm.selectedCustomer
                                                    .shipping_address.state
                                                ) +
                                                "\n                    " +
                                                _vm._s(
                                                  _vm.selectedCustomer
                                                    .shipping_address.zip
                                                ) +
                                                "\n                  "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.selectedCustomer.shipping_address
                                        .country
                                        ? _c(
                                            "label",
                                            { staticClass: "country" },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.selectedCustomer
                                                      .shipping_address.country
                                                      .name
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.selectedCustomer.shipping_address
                                        .phone
                                        ? _c(
                                            "label",
                                            { staticClass: "phone" },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.selectedCustomer
                                                      .shipping_address.phone
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "customer-content mb-1" }, [
                          _c("label", { staticClass: "email" }, [
                            _vm._v(_vm._s(_vm.selectedCustomer.name)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "action",
                              on: { click: _vm.editCustomer },
                            },
                            [_vm._v(_vm._s(_vm.$t("general.edit")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "action",
                              on: { click: _vm.removeCustomer },
                            },
                            [_vm._v(_vm._s(_vm.$t("general.deselect")))]
                          ),
                        ]),
                      ])
                    : _c(
                        "base-popup",
                        {
                          class: [
                            "add-customer",
                            {
                              "customer-required":
                                _vm.$v.selectedCustomer.$error,
                            },
                          ],
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "add-customer-action",
                              attrs: { slot: "activator" },
                              slot: "activator",
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "customer-icon",
                                attrs: { icon: "user" },
                              }),
                              _vm._v(" "),
                              _c("div", [
                                _c("label", [
                                  _vm._v(
                                    _vm._s(_vm.$t("customers.new_customer")) +
                                      "\n                "
                                  ),
                                  _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(" * "),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _vm.$v.selectedCustomer.$error &&
                                !_vm.$v.selectedCustomer.required
                                  ? _c("p", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("estimates.errors.required")
                                          ) +
                                          "\n              "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("customer-select-popup", {
                            attrs: { type: "estimate" },
                          }),
                        ],
                        1
                      ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col estimate-input" }, [
                _c("div", { staticClass: "row mb-3" }, [
                  _c(
                    "div",
                    { staticClass: "col collapse-input" },
                    [
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.$t("reports.estimates.estimate_date"))
                        ),
                        _c("span", { staticClass: "text-danger" }, [
                          _vm._v(" * "),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("base-date-picker", {
                        attrs: {
                          "calendar-button": true,
                          "calendar-button-icon": "calendar",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$v.newEstimate.estimate_date.$touch()
                          },
                        },
                        model: {
                          value: _vm.newEstimate.estimate_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.newEstimate, "estimate_date", $$v)
                          },
                          expression: "newEstimate.estimate_date",
                        },
                      }),
                      _vm._v(" "),
                      _vm.$v.newEstimate.estimate_date.$error &&
                      !_vm.$v.newEstimate.estimate_date.required
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("validation.required")) +
                                "\n            "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col collapse-input" },
                    [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("estimates.due_date"))),
                        _c("span", { staticClass: "text-danger" }, [
                          _vm._v(" * "),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("base-date-picker", {
                        attrs: {
                          invalid: _vm.$v.newEstimate.expiry_date.$error,
                          "calendar-button": true,
                          "calendar-button-icon": "calendar",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$v.newEstimate.expiry_date.$touch()
                          },
                        },
                        model: {
                          value: _vm.newEstimate.expiry_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.newEstimate, "expiry_date", $$v)
                          },
                          expression: "newEstimate.expiry_date",
                        },
                      }),
                      _vm._v(" "),
                      _vm.$v.newEstimate.expiry_date.$error &&
                      !_vm.$v.newEstimate.expiry_date.required
                        ? _c("span", { staticClass: "text-danger mt-1" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("validation.required"))
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row mt-4" }, [
                  _c(
                    "div",
                    { staticClass: "col collapse-input" },
                    [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("estimates.estimate_number"))),
                        _c("span", { staticClass: "text-danger" }, [
                          _vm._v(" * "),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("base-prefix-input", {
                        attrs: {
                          invalid: _vm.$v.estimateNumAttribute.$error,
                          prefix: _vm.estimatePrefix,
                          icon: "hashtag",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.estimateNumAttribute.$touch()
                          },
                        },
                        model: {
                          value: _vm.estimateNumAttribute,
                          callback: function ($$v) {
                            _vm.estimateNumAttribute = $$v
                          },
                          expression: "estimateNumAttribute",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.$v.estimateNumAttribute.$error &&
                                !_vm.$v.estimateNumAttribute.required,
                              expression:
                                "\n                $v.estimateNumAttribute.$error &&\n                !$v.estimateNumAttribute.required\n              ",
                            },
                          ],
                          staticClass: "text-danger mt-1",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$tc("estimates.errors.required")) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.$v.estimateNumAttribute.numeric,
                              expression: "!$v.estimateNumAttribute.numeric",
                            },
                          ],
                          staticClass: "text-danger mt-1",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$tc("validation.numbers_only")) +
                              "\n            "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col collapse-input" },
                    [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("estimates.ref_number"))),
                      ]),
                      _vm._v(" "),
                      _c("base-input", {
                        attrs: {
                          invalid: _vm.$v.newEstimate.reference_number.$error,
                          icon: "hashtag",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.newEstimate.reference_number.$touch()
                          },
                        },
                        model: {
                          value: _vm.newEstimate.reference_number,
                          callback: function ($$v) {
                            _vm.$set(_vm.newEstimate, "reference_number", $$v)
                          },
                          expression: "newEstimate.reference_number",
                        },
                      }),
                      _vm._v(" "),
                      _vm.$v.newEstimate.reference_number.$error
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$tc("validation.ref_number_maxlength")
                                ) +
                                "\n            "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "table",
              { staticClass: "item-table" },
              [
                _c("colgroup", [
                  _c("col", { staticStyle: { width: "40%" } }),
                  _vm._v(" "),
                  _c("col", { staticStyle: { width: "10%" } }),
                  _vm._v(" "),
                  _c("col", { staticStyle: { width: "15%" } }),
                  _vm._v(" "),
                  _vm.discountPerItem === "YES"
                    ? _c("col", { staticStyle: { width: "15%" } })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("col", { staticStyle: { width: "15%" } }),
                ]),
                _vm._v(" "),
                _c("thead", { staticClass: "item-table-header" }, [
                  _c("tr", [
                    _c("th", { staticClass: "text-left" }, [
                      _c(
                        "span",
                        { staticClass: "column-heading item-heading" },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$tc("items.item", 2)) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("th", { staticClass: "text-right" }, [
                      _c("span", { staticClass: "column-heading" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("estimates.item.quantity")) +
                            "\n            "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("th", { staticClass: "text-left" }, [
                      _c("span", { staticClass: "column-heading" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("estimates.item.price")) +
                            "\n            "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.discountPerItem === "YES"
                      ? _c("th", { staticClass: "text-right" }, [
                          _c("span", { staticClass: "column-heading" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("estimates.item.discount")) +
                                "\n            "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("th", { staticClass: "text-right" }, [
                      _c(
                        "span",
                        { staticClass: "column-heading amount-heading" },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("estimates.item.amount")) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "draggable",
                  {
                    staticClass: "item-body",
                    attrs: { tag: "tbody", handle: ".handle" },
                    model: {
                      value: _vm.newEstimate.items,
                      callback: function ($$v) {
                        _vm.$set(_vm.newEstimate, "items", $$v)
                      },
                      expression: "newEstimate.items",
                    },
                  },
                  _vm._l(_vm.newEstimate.items, function (item, index) {
                    return _c("estimate-item", {
                      key: item.id,
                      attrs: {
                        index: index,
                        "item-data": item,
                        currency: _vm.currency,
                        "estimate-items": _vm.newEstimate.items,
                        "tax-per-item": _vm.taxPerItem,
                        "discount-per-item": _vm.discountPerItem,
                      },
                      on: {
                        remove: _vm.removeItem,
                        update: _vm.updateItem,
                        itemValidate: _vm.checkItemsData,
                      },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "add-item-action", on: { click: _vm.addItem } },
              [
                _c("font-awesome-icon", {
                  staticClass: "mr-2",
                  attrs: { icon: "shopping-basket" },
                }),
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("estimates.add_item")) + "\n    "
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "estimate-foot" }, [
              _c(
                "div",
                [
                  _c("label", [_vm._v(_vm._s(_vm.$t("estimates.notes")))]),
                  _vm._v(" "),
                  _c("base-text-area", {
                    attrs: { rows: "3", cols: "50" },
                    on: {
                      input: function ($event) {
                        return _vm.$v.newEstimate.notes.$touch()
                      },
                    },
                    model: {
                      value: _vm.newEstimate.notes,
                      callback: function ($$v) {
                        _vm.$set(_vm.newEstimate, "notes", $$v)
                      },
                      expression: "newEstimate.notes",
                    },
                  }),
                  _vm._v(" "),
                  _vm.$v.newEstimate.notes.$error
                    ? _c("div", [
                        !_vm.$v.newEstimate.notes.maxLength
                          ? _c("span", { staticClass: "text-danger" }, [
                              _vm._v(
                                _vm._s(_vm.$t("validation.notes_maxlength"))
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("label", { staticClass: "mt-3 mb-1 d-block" }, [
                    _vm._v(
                      _vm._s(_vm.$t("estimates.estimate_template")) +
                        "\n          "
                    ),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "base-button",
                    {
                      staticClass: "btn-template",
                      attrs: {
                        type: "button",
                        icon: "pencil-alt",
                        "right-icon": "",
                      },
                      on: { click: _vm.openTemplateModal },
                    },
                    [
                      _c("span", { staticClass: "mr-4" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("estimates.estimate_template")) +
                            " " +
                            _vm._s(_vm.getTemplateId) +
                            "\n          "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "estimate-total" },
                [
                  _c("div", { staticClass: "section" }, [
                    _c("label", { staticClass: "estimate-label" }, [
                      _vm._v(_vm._s(_vm.$t("estimates.sub_total"))),
                    ]),
                    _vm._v(" "),
                    _c("label", { staticClass: "estimate-amount" }, [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$utils.formatMoney(_vm.subtotal, _vm.currency)
                          ),
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.allTaxes, function (tax) {
                    return _c(
                      "div",
                      { key: tax.tax_type_id, staticClass: "section" },
                      [
                        _c("label", { staticClass: "estimate-label" }, [
                          _vm._v(
                            _vm._s(tax.name) +
                              " - " +
                              _vm._s(tax.percent) +
                              "%\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("label", { staticClass: "estimate-amount" }, [
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$utils.formatMoney(tax.amount, _vm.currency)
                              ),
                            },
                          }),
                        ]),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm.discountPerItem === "NO" || _vm.discountPerItem === null
                    ? _c("div", { staticClass: "section mt-2" }, [
                        _c("label", { staticClass: "estimate-label" }, [
                          _vm._v(_vm._s(_vm.$t("estimates.discount"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "btn-group discount-drop-down",
                            attrs: { role: "group" },
                          },
                          [
                            _c("base-input", {
                              attrs: {
                                invalid: _vm.$v.newEstimate.discount_val.$error,
                                "input-class": "item-discount",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.$v.newEstimate.discount_val.$touch()
                                },
                              },
                              model: {
                                value: _vm.discount,
                                callback: function ($$v) {
                                  _vm.discount = $$v
                                },
                                expression: "discount",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "v-dropdown",
                              { attrs: { "show-arrow": false } },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn item-dropdown dropdown-toggle",
                                    attrs: {
                                      slot: "activator",
                                      type: "button",
                                      "data-toggle": "dropdown",
                                      "aria-haspopup": "true",
                                      "aria-expanded": "false",
                                    },
                                    slot: "activator",
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.newEstimate.discount_type ==
                                            "fixed"
                                            ? _vm.currency.symbol
                                            : "%"
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("v-dropdown-item", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.selectFixed.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.$t("general.fixed")) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("v-dropdown-item", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.selectPercentage.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.$t("general.percentage")) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.taxPerItem === "NO" || _vm.taxPerItem === null
                    ? _c(
                        "div",
                        _vm._l(_vm.newEstimate.taxes, function (tax, index) {
                          return _c("tax", {
                            key: tax.id,
                            attrs: {
                              index: index,
                              total: _vm.subtotalWithDiscount,
                              tax: tax,
                              taxes: _vm.newEstimate.taxes,
                              currency: _vm.currency,
                              "total-tax": _vm.totalSimpleTax,
                            },
                            on: {
                              remove: _vm.removeEstimateTax,
                              update: _vm.updateTax,
                            },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.taxPerItem === "NO" || _vm.taxPerItem === null
                    ? _c(
                        "base-popup",
                        { ref: "taxModal", staticClass: "tax-selector" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "float-right",
                              attrs: { slot: "activator" },
                              slot: "activator",
                            },
                            [
                              _vm._v(
                                "\n            + " +
                                  _vm._s(_vm.$t("estimates.add_tax")) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("tax-select-popup", {
                            attrs: { taxes: _vm.newEstimate.taxes },
                            on: { select: _vm.onSelectTax },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "section border-top mt-3" }, [
                    _c("label", { staticClass: "estimate-label" }, [
                      _vm._v(
                        _vm._s(_vm.$t("estimates.total")) +
                          " " +
                          _vm._s(_vm.$t("estimates.amount")) +
                          ":"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("label", { staticClass: "estimate-amount total" }, [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$utils.formatMoney(_vm.total, _vm.currency)
                          ),
                        },
                      }),
                    ]),
                  ]),
                ],
                2
              ),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }