var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-content" },
    [
      _c("div", { staticClass: "page-header mt-4" }, [
        _c("h3", { staticClass: "page-title" }, [
          _vm._v("\n      " + _vm._s(_vm.$t("rate_tables.title")) + "\n    "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "page-actions row" },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "rate-tables.generate-year-tables" } } },
              [
                _c(
                  "base-button",
                  { attrs: { color: "theme", icon: "plus", size: "large" } },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("rate_tables.add_year")) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "nav nav-pills mb-3" },
        [
          _vm._l(_vm.taxRegimes, function (regime) {
            return _c(
              "li",
              { key: regime.id, staticClass: "nav-item" },
              [
                _c(
                  "router-link",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.top-center",
                        value: regime.description,
                        expression: "regime.description",
                        modifiers: { "top-center": true },
                      },
                    ],
                    staticClass: "nav-link",
                    attrs: {
                      to: {
                        name: "rate-tables.table-regime",
                        params: { regime: regime.id },
                      },
                    },
                  },
                  [_c("sub-string", { attrs: { string: regime.description } })],
                  1
                ),
              ],
              1
            )
          }),
          _vm._v(" "),
          _vm._l(_vm.segments, function (segment) {
            return _c(
              "li",
              { key: segment.segment },
              [
                _c(
                  "router-link",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.top-center",
                        value: segment.title,
                        expression: "segment.title",
                        modifiers: { "top-center": true },
                      },
                    ],
                    staticClass: "nav-link",
                    attrs: {
                      to: {
                        name: "rate-tables.table-regime",
                        params: { regime: segment.segment },
                      },
                    },
                  },
                  [_c("sub-string", { attrs: { string: segment.title } })],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }