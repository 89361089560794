var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-100", staticStyle: { "background-color": "#1b144a" } },
    [
      _c("div", { staticClass: "row h-100" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "col-lg-6" }, [
          _c(
            "div",
            {
              staticClass:
                "h-100 w-100 p-5 d-flex flex-column justify-content-center align-items-center",
            },
            [
              _c("h1", { staticClass: "text-center text-white" }, [
                _vm._v("Registro"),
              ]),
              _vm._v(" "),
              _c(
                "form",
                {
                  staticClass: "w-100 px-5",
                  attrs: { id: "registerForm" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.validateBeforeSubmit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "d-flex flex-column my-3" }, [
                    _c(
                      "div",
                      {
                        staticClass: "flex flex-row",
                        class: { "form-group": true },
                      },
                      [
                        _vm._m(1),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { width: "100%" } },
                          [
                            _c("base-input", {
                              attrs: {
                                invalid: _vm.$v.registroData.rfc.$error,
                                focus: "",
                                type: "text",
                                name: "rfc",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.$v.registroData.rfc.$touch()
                                },
                              },
                              model: {
                                value: _vm.registroData.rfc,
                                callback: function ($$v) {
                                  _vm.$set(_vm.registroData, "rfc", $$v)
                                },
                                expression: "registroData.rfc",
                              },
                            }),
                            _vm._v(" "),
                            _vm.$v.registroData.rfc.$error
                              ? _c("div", [
                                  !_vm.$v.registroData.rfc.required
                                    ? _c(
                                        "span",
                                        { staticClass: "w-50 text-danger" },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$tc("validation.required")
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex flex-column my-3" }, [
                    _c(
                      "div",
                      {
                        staticClass: "flex flex-row",
                        class: { "form-group": true },
                      },
                      [
                        _vm._m(2),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { width: "100%" } },
                          [
                            _c("base-input", {
                              attrs: {
                                invalid: _vm.$v.registroData.razonSocial.$error,
                                focus: "",
                                type: "text",
                                name: "razonSocial",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.$v.registroData.razonSocial.$touch()
                                },
                              },
                              model: {
                                value: _vm.registroData.razonSocial,
                                callback: function ($$v) {
                                  _vm.$set(_vm.registroData, "razonSocial", $$v)
                                },
                                expression: "registroData.razonSocial",
                              },
                            }),
                            _vm._v(" "),
                            _vm.$v.registroData.razonSocial.$error
                              ? _c("div", [
                                  !_vm.$v.registroData.razonSocial.required
                                    ? _c(
                                        "span",
                                        { staticClass: "w-50 text-danger" },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$tc("validation.required")
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex flex-column my-3" }, [
                    _c(
                      "div",
                      {
                        staticClass: "flex flex-row",
                        class: { "form-group": true },
                      },
                      [
                        _vm._m(3),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { width: "100%" } },
                          [
                            _c("base-input", {
                              attrs: {
                                invalid: _vm.$v.registroData.telefono.$error,
                                focus: "",
                                type: "text",
                                name: "telefono",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.$v.registroData.telefono.$touch()
                                },
                              },
                              model: {
                                value: _vm.registroData.telefono,
                                callback: function ($$v) {
                                  _vm.$set(_vm.registroData, "telefono", $$v)
                                },
                                expression: "registroData.telefono",
                              },
                            }),
                            _vm._v(" "),
                            _vm.$v.registroData.telefono.$error
                              ? _c("div", [
                                  !_vm.$v.registroData.telefono.required
                                    ? _c(
                                        "span",
                                        { staticClass: "w-50 text-danger" },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$tc("validation.required")
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex flex-column my-3" }, [
                    _c(
                      "div",
                      {
                        staticClass: "flex flex-row",
                        class: { "form-group": true },
                      },
                      [
                        _vm._m(4),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { width: "100%" } },
                          [
                            _c("base-input", {
                              attrs: {
                                invalid: _vm.$v.registroData.correo.$error,
                                focus: "",
                                type: "email",
                                name: "correo",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.$v.registroData.correo.$touch()
                                },
                              },
                              model: {
                                value: _vm.registroData.correo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.registroData, "correo", $$v)
                                },
                                expression: "registroData.correo",
                              },
                            }),
                            _vm._v(" "),
                            _vm.$v.registroData.correo.$error
                              ? _c("div", [
                                  !_vm.$v.registroData.correo.required
                                    ? _c(
                                        "span",
                                        { staticClass: "w-50 text-danger" },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$tc("validation.required")
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.$v.registroData.correo.email
                                    ? _c(
                                        "span",
                                        { staticClass: "w-50 text-danger" },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$tc(
                                                  "validation.email_incorrect"
                                                )
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex flex-column my-3" }, [
                    _c(
                      "div",
                      {
                        staticClass: "flex flex-row",
                        class: { "form-group": true },
                      },
                      [
                        _vm._m(5),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { width: "100%" } },
                          [
                            _c("base-input", {
                              attrs: {
                                invalid: _vm.$v.registroData.password.$error,
                                type: "password",
                                name: "password",
                                "show-password": "",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.$v.registroData.password.$touch()
                                },
                              },
                              model: {
                                value: _vm.registroData.password,
                                callback: function ($$v) {
                                  _vm.$set(_vm.registroData, "password", $$v)
                                },
                                expression: "registroData.password",
                              },
                            }),
                            _vm._v(" "),
                            _vm.$v.registroData.password.$error
                              ? _c("div", [
                                  !_vm.$v.registroData.password.required
                                    ? _c(
                                        "span",
                                        { staticClass: "w-50 text-danger" },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$tc("validation.required")
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.$v.registroData.password.minLength
                                    ? _c(
                                        "span",
                                        { staticClass: "w-50 text-danger" },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$tc(
                                                  "validation.password_min_length",
                                                  _vm.$v.registroData.password
                                                    .$params.minLength.min,
                                                  {
                                                    count:
                                                      _vm.$v.registroData
                                                        .password.$params
                                                        .minLength.min,
                                                  }
                                                )
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-center mt-4" },
                    [
                      _c(
                        "base-button",
                        {
                          attrs: {
                            loading: _vm.isLoading,
                            type: "submit",
                            color: "theme",
                          },
                        },
                        [
                          _vm._v("\n              Siguiente\n              "),
                          _c("font-awesome-icon", {
                            staticClass: "icon menu-icon ml-2",
                            attrs: { icon: "arrow-right" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "col-lg-6 d-none d-lg-block",
        staticStyle: { "background-color": "#e2f0d9" },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "w-100 h-100 d-flex justify-content-center align-items-center",
          },
          [
            _c("img", {
              staticClass: "w-50",
              attrs: { src: "/assets/img/logo-taxati.png", alt: "Crater Logo" },
            }),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h6", { staticClass: "input-label text-white w-50" }, [
      _vm._v("\n                RFC "),
      _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h6", { staticClass: "input-label text-white w-50" }, [
      _vm._v("\n                Nombre o Razón Social "),
      _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h6", { staticClass: "input-label text-white w-50" }, [
      _vm._v("\n                Número telefónico "),
      _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h6", { staticClass: "input-label text-white w-50" }, [
      _vm._v("\n                Correo Electrónico "),
      _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h6", { staticClass: "input-label text-white w-50" }, [
      _vm._v("\n                Contraseña "),
      _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }