export const catalogs = {
  periodicity: [
    {
      code: 'yearly',
      description: 'Anual',
    },
    {
      code: 'bimester',
      description: 'Bimestral',
    },
    {
      code: 'monthly',
      description: 'Mensual',
    },
  ],

  tax_return_types: [
    {
      code: 'normal',
      description: 'Normal',
    },
    {
      code: 'supplemental',
      description: 'Complementaria',
    },
  ],
}
