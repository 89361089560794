<template>
  <div class="main-content">
    <div class="page-header mt-4">
      <h3 class="page-title">Crear cuenta</h3>
    </div>

    <div class="card mt-3 pt-4 pb-4">
      <div class="row">
        <div class="col-12 col-md-6 offset-md-3">
          <form @submit.prevent="submit">
            <!-- Nombre -->
            <div class="form-group">
              <label for="nombre">Nombre</label>
              <base-input id="nombre" ref="name" v-model="name" type="text" />
            </div>

            <div class="form-group text-center">
              <button class="btn btn-primary" type="submit">
                {{ $t('general.save') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data: () => ({
    file: null,
    name: '',
  }),
  computed: {
    ...mapGetters('CurrentCustomer', ['currentCustomer']),
    ...mapGetters('assigned', ['assingedSelect']),
  },
  methods: {
    ...mapActions('treasuryAccounts', ['uploadAccount']),
    readFile(event) {
      const input = event.target
      this.file = input.files[0]
    },

    submit() {
      const formData = {
        customer_id: this.currentCustomer.id,
        name: this.name,
      }

      this.uploadAccount(formData)
        .then((response) => {
          window.toastr.success('Cuenta creada correctamete')
          this.$router.push({ name: 'account.index' })
        })
        .catch((err) => {
          window.toastr.error('Error al crear la cuenta')
        })
    },
  },
}
</script>
