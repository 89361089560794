var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeDropdown,
          expression: "closeDropdown",
        },
      ],
      class: [
        "dropdown-group",
        { active: _vm.toggle || _vm.isActive() },
        { "has-child": _vm.hasChild },
        { "toggle-arrow": _vm.hasChild && _vm.showArrow },
        { "dropdown-light": _vm.themeLight },
      ],
    },
    [
      _c(
        "div",
        {
          staticClass: "dropdown-activator",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.showDropdown.apply(null, arguments)
            },
          },
        },
        [_vm._t("activator")],
        2
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "bounce" } }, [
        _vm.hasChild
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.toggle,
                    expression: "toggle",
                  },
                ],
                ref: "dropdownItems",
                class: [
                  "dropdown-container",
                  { "align-right": _vm.rightAlign },
                ],
                on: { click: _vm.closeOnSelectDropdownItem },
              },
              [_vm._t("default")],
              2
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }