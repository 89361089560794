<template>
  <div class="relate-invoice-to-treasury-account">
    <form @submit.prevent="submit">

      <div v-if="dataToRelateToTreasuryAccount.invoice" class="row">
        <div class="col-6 col-sm-6 col-md-4 col-lg-4 text-center mt-2">
          <strong class="title">{{ $t('invoices.serie') }}</strong> <br>
          {{ dataToRelateToTreasuryAccount.invoice.serie }}
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-4 text-center mt-2">
          <strong class="title">{{ $t('invoices.invoice_number') }}</strong> <br>
          {{ dataToRelateToTreasuryAccount.invoice.invoice_number }}
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-4 text-center mt-2">
          <strong class="title">{{ $t('invoices.uuid') }}</strong> <br>
          {{ dataToRelateToTreasuryAccount.invoice.uuid }}
        </div>

        <div
          v-if="dataToRelateToTreasuryAccount.typeRelate=='payments'"
          class="col-6 col-sm-6 col-md-4 col-lg-4 text-center mt-2"
        >
          <strong class="title">{{ $t('payments.customer') }}</strong> <br>
          {{ dataToRelateToTreasuryAccount.invoice.receiver_name }}
        </div>
        <div
          v-if="dataToRelateToTreasuryAccount.typeRelate=='payments'"
          class="col-6 col-sm-6 col-md-4 col-lg-4 text-center mt-2"
        >
          <strong class="title">{{ $t('payments.customer_rfc') }}</strong> <br>
          {{ dataToRelateToTreasuryAccount.invoice.receiver_rfc }}
        </div>

        <div
          v-if="dataToRelateToTreasuryAccount.typeRelate=='expenses'"
          class="col-6 col-sm-6 col-md-4 col-lg-4 text-center mt-2"
        >
          <strong class="title">{{ $t('expenses.provider') }}</strong> <br>
          {{ dataToRelateToTreasuryAccount.invoice.issuing_name }}
        </div>
        <div
          v-if="dataToRelateToTreasuryAccount.typeRelate=='expenses'"
          class="col-6 col-sm-6 col-md-4 col-lg-4 text-center mt-2"
        >
          <strong class="title">{{ $t('expenses.provider_rfc') }}</strong> <br>
          {{ dataToRelateToTreasuryAccount.invoice.issuing_rfc }}
        </div>

        <div class="col-6 col-sm-6 col-md-4 col-lg-4 text-center mt-2">
          <strong class="title">{{ $t('invoices.total') }}</strong> <br>
          <span v-html="$utils.formatMoney(dataToRelateToTreasuryAccount.invoice.total, defaultCurrency)" />
        </div>

        <div class="col-6 col-sm-6 col-md-4 col-lg-4 text-center mt-2">
          <strong class="title">{{ $t('treasury_accounts.remainder') }}</strong> <br>
          <span v-html="$utils.formatMoney(dataToRelateToTreasuryAccount.invoice.total - dataToRelateToTreasuryAccount.invoice.related_amount, defaultCurrency)" />
        </div>
      </div>

      <div class="row mt-4">
        <div class="col invoice-input">
          <div class="base-input">
            <label>{{ $t('treasury_accounts.relate_invoice_amount') }}:</label>
            <money
              v-model="form.amount"
              v-bind="customerCurrency"
              class="input-field"
              @input="$v.form.amount.$touch()"
            />
          </div>
          <div v-if="$v.form.amount.$error">
            <span v-if="!$v.form.amount.between" class="text-danger">{{ $t('validation.amount_greater_than_total_amount') }}</span>
          </div>
        </div>
      </div>

      <div class="mt-4 btn-container">
        <base-button
          :loading="isLoading"
          :disabled="isLoading"
          icon="save"
          color="theme"
          class="invoice-action-btn"
          type="submit"
        >
          {{ $t('treasury_accounts.relate_invoice') }}
        </base-button>
      </div>

    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required, numeric, between } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';

export default {
  mixins: [validationMixin],
  data: () => ({
    form: {
      amount: 0,
    },
    isLoading: false
  }),
  computed: {
    ...mapGetters('currency', [
      'defaultCurrency'
    ]),
    ...mapGetters('treasuryAccounts', [
      'dataToRelateToTreasuryAccount',
    ]),
    customerCurrency () {
      if (this.defaultCurrency) {
        return {
          decimal: this.defaultCurrency.decimal_separator,
          thousands: this.defaultCurrency.thousand_separator,
          prefix: this.defaultCurrency.symbol + ' ',
          precision: this.defaultCurrency.precision,
          masked: false
        }
      } else {
        return this.defaultCurrenctForInput
      }
    },
  },
  validations: function () {
    return {
      form: {
        amount: {
          required,
          numeric,
          between: between(0.01, this.dataToRelateToTreasuryAccount.invoice.total),
        },
      },
    }
  },
  created () {
    this.form.amount = (this.dataToRelateToTreasuryAccount.invoice.total - this.dataToRelateToTreasuryAccount.invoice.related_amount) / 100;
  },
  mounted () {},
  methods: {
    ...mapActions('modal', [
      'closeModal',
      'resetModalData'
    ]),
    ...mapActions('treasuryAccounts', [
      'relateInvoiteToTreasuryAccount',
    ]),
    submit () {
      this.$v.form.$touch();
      if (this.$v.form.isInvalid === true) {
        return;
      }
      const data = {
        amount: this.form.amount,
        type_relate: this.dataToRelateToTreasuryAccount.typeRelate,
        invoice_id: this.dataToRelateToTreasuryAccount.invoice.id,
        treasury_account_id: this.dataToRelateToTreasuryAccount.treasuryAccountId,
      }

      this.relateInvoiteToTreasuryAccount(data)
      .then((response) => {
        if (response.data.success) {
          window.hub.$emit('invoice-related', null);
          this.closeModal();
        } else {
          window.toastr['error'](this.$t('treasury_accounts.errors.available_amount_cant_grater'));
        }
      })
      .catch(() => {
        window.toastr['error'](this.$t('treasury_accounts.errors.relate_invoice'));
      });
    }
  }
}
</script>