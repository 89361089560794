var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-content" }, [
    _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [
        _vm._v(
          "\n      " + _vm._s(_vm.$t("tax_returns.tax_return")) + "\n    "
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "card p2" },
      [_c("rif", { attrs: { "tax-return": _vm.taxReturn } })],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "mt-3 text-center" }, [
      _c(
        "button",
        { staticClass: "btn btn-success", on: { click: _vm.approveTaxReturn } },
        [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("tax_returns.approve_tax_return")) +
              "\n    "
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }