var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "p-3" }, [
    _c("div", { staticClass: "table-responsive" }, [
      _c("table", { staticClass: "table table-bordered" }, [
        _c("thead", [
          _c("tr", [
            _c("th", [_vm._v(_vm._s(_vm.$t("tax_losses.year")))]),
            _vm._v(" "),
            _c("th", { staticClass: "text-right" }, [
              _vm._v(_vm._s(_vm.$t("tax_losses.tax_loss"))),
            ]),
            _vm._v(" "),
            _c("th", { staticClass: "text-right" }, [
              _vm._v(_vm._s(_vm.$t("tax_losses.updates"))),
            ]),
            _vm._v(" "),
            _c("th", { staticClass: "text-right" }, [
              _vm._v(_vm._s(_vm.$t("tax_losses.remaining"))),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.losses, function (taxLoss) {
            return _c("tr", { key: taxLoss.id }, [
              _c("td", [
                _vm._v(
                  "\n            " + _vm._s(taxLoss.year) + "\n          "
                ),
              ]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [_c("money-text", { attrs: { amount: taxLoss.amount } })],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("money-text", {
                    attrs: { amount: Number(taxLoss.amount_update) },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "text-right" },
                [
                  _c("money-text", {
                    attrs: { amount: taxLoss.remaining_amount },
                  }),
                ],
                1
              ),
            ])
          }),
          0
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "table-responsive mt-4" }, [
      _c("table", { staticClass: "table table-bordered" }, [
        _c("tbody", [
          _c("tr", [
            _c("td", { staticClass: "text-right" }, [
              _vm._v(_vm._s(_vm.$t("tax_losses.tax_loss"))),
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "text-right" }, [
              _vm._v(_vm._s(_vm.$t("tax_losses.amount_to_apply"))),
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "text-right" }, [
              _vm._v(_vm._s(_vm.$t("tax_losses.remaining"))),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c(
              "td",
              { staticClass: "text-right" },
              [_c("money-text", { attrs: { amount: _vm.amountOfTaxLosses } })],
              1
            ),
            _vm._v(" "),
            _c("td", { staticClass: "text-right" }, [
              _c(
                "div",
                { staticClass: "base-input" },
                [
                  _c(
                    "money",
                    _vm._b(
                      {
                        staticClass: "input-field text-right",
                        model: {
                          value: _vm.amountToApply,
                          callback: function ($$v) {
                            _vm.amountToApply = $$v
                          },
                          expression: "amountToApply",
                        },
                      },
                      "money",
                      _vm.customerCurrency,
                      false
                    )
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "td",
              {
                staticClass: "text-right",
                class: { "text-danger": _vm.disableApplyTaxLoss },
              },
              [_c("money-text", { attrs: { amount: _vm.remainingAmount } })],
              1
            ),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mt-2 flex-content-right" },
      [
        _c(
          "base-button",
          {
            attrs: { disabled: _vm.disableApplyTaxLoss, color: "theme" },
            on: { click: _vm.applyTaxLoss },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("tax_losses.apply_tax_loss")) +
                "\n    "
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }