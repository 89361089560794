var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "customer-create main-content mt-5" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitCustomerData.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "page-header" }, [
          _c("h3", { staticClass: "page-title" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.isEdit
                    ? _vm.$t("customers.edit_customer")
                    : "Nuevo usuario"
                ) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page-actions header-button-container" },
            [
              _c(
                "base-button",
                {
                  attrs: {
                    loading: _vm.isLoading,
                    disabled: _vm.isLoading,
                    tabindex: 23,
                    icon: "save",
                    color: "theme",
                    type: "submit",
                  },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.isEdit
                          ? _vm.$t("customers.update_customer")
                          : "Guardar usuario"
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "customer-card card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "section-title col-sm-2" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("customers.basic_info")) +
                    "\n          "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-5" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s("Nombre Completo")),
                    ]),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        invalid: _vm.$v.formData.name.$error,
                        focus: "",
                        type: "text",
                        name: "name",
                        "tab-index": "1",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.name.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "name", $$v)
                        },
                        expression: "formData.name",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.name.$error
                      ? _c("div", [
                          !_vm.$v.formData.name.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$tc("validation.required")) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.formData.name.minLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$tc(
                                        "validation.name_min_length",
                                        _vm.$v.formData.name.$params.minLength
                                          .min,
                                        {
                                          count:
                                            _vm.$v.formData.name.$params
                                              .minLength.min,
                                        }
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.email"))),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _c("base-input", {
                      attrs: {
                        invalid: _vm.$v.formData.email.$error,
                        type: "text",
                        name: "email",
                        "tab-index": "3",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.email.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.email,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "email",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.email",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.email.$error
                      ? _c("div", [
                          !_vm.$v.formData.email.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$tc("validation.required")) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.formData.email.email
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$tc("validation.email_incorrect")
                                    ) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v("Contraseña"),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _c("base-input", {
                      attrs: {
                        invalid: _vm.$v.formData.password.$error,
                        type: "text",
                        name: "pasword",
                        "tab-index": "3",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.password.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.password,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "password",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.password",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.password.$error
                      ? _c("div", [
                          !_vm.$v.formData.password.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$tc("validation.required")) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.formData.password.minLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$tc(
                                        "validation.name_min_length",
                                        _vm.$v.formData.password.$params
                                          .minLength.min,
                                        {
                                          count:
                                            _vm.$v.formData.password.$params
                                              .minLength.min,
                                        }
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v("Role"),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c(
                      "v-dropdown",
                      {
                        attrs: { "show-arrow": false },
                        model: {
                          value: _vm.formData.role,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "role",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.role",
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "table-actions-button dropdown-toggle",
                            attrs: { slot: "activator", href: "#" },
                            slot: "activator",
                          },
                          [
                            _vm._v(
                              "\n                 " +
                                _vm._s(_vm.formData.role) +
                                "\n                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("v-dropdown-item", [
                          _c(
                            "div",
                            {
                              staticClass: "dropdown-item",
                              on: {
                                click: function ($event) {
                                  return _vm.setRole("ejecutivo")
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                    ejecutivo\n                  "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("v-dropdown-item", [
                          _c(
                            "div",
                            {
                              staticClass: "dropdown-item",
                              on: {
                                click: function ($event) {
                                  return _vm.setRole("admin")
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                    administrador\n                  "
                              ),
                            ]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }