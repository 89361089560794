import * as types from './mutation-types'

export default {
    [types.SET_TREASURY_ACCOUNTS] (state, accounts) {
        state.treasuryAccounts = accounts
    },

    [types.SET_INVOICE_TO_RELATE] (state, data) {
        state.dataToRelateToTreasuryAccount = data;
    }
}
