export const deductionTypes = [
  {
    id: '001',
    description: 'Seguridad social',
  },
  {
    id: '002',
    description: 'ISR',
  },
  {
    id: '003',
    description: 'Aportaciones a retiro, cesantía en edad avanzada y vejez.',
  },
  {
    id: '004',
    description: 'Otros',
  },
  {
    id: '005',
    description: 'Aportaciones a Fondo de vivienda',
  },
  {
    id: '006',
    description: 'Descuento por incapacidad',
  },
  {
    id: '007',
    description: 'Pensión alimenticia',
  },
  {
    id: '008',
    description: 'Renta',
  },
  {
    id: '009',
    description:
      'Préstamos provenientes del Fondo Nacional de la Vivienda para los Trabajadores',
  },
  {
    id: '010',
    description: 'Pago por crédito de vivienda',
  },
  {
    id: '011',
    description: 'Pago de abonos INFONACOT',
  },
  {
    id: '012',
    description: 'Anticipo de salarios',
  },
  {
    id: '013',
    description: 'Pagos hechos con exceso al trabajador',
  },
  {
    id: '014',
    description: 'Errores',
  },
  {
    id: '015',
    description: 'Pérdidas',
  },
  {
    id: '016',
    description: 'Averías',
  },
  {
    id: '017',
    description:
      'Adquisición de artículos producidos por la empresa o establecimiento',
  },
  {
    id: '018',
    description:
      'Cuotas para la constitución y fomento de sociedades cooperativas y de cajas de ahorro',
  },
  {
    id: '019',
    description: 'Cuotas sindicales',
  },
  {
    id: '020',
    description: 'Ausencia (Ausentismo)',
  },
  {
    id: '021',
    description: 'Cuotas obrero patronales',
  },
  {
    id: '022',
    description: 'Impuestos Locales',
  },
  {
    id: '023',
    description: 'Aportaciones voluntarias',
  },
  {
    id: '024',
    description: 'Ajuste en Gratificación Anual (Aguinaldo) Exento',
  },
  {
    id: '025',
    description: 'Ajuste en Gratificación Anual (Aguinaldo) Gravado',
  },
  {
    id: '026',
    description:
      'Ajuste en Participación de los Trabajadores en las Utilidades PTU Exento',
  },
  {
    id: '027',
    description:
      'Ajuste en Participación de los Trabajadores en las Utilidades PTU Gravado',
  },
  {
    id: '028',
    description:
      'Ajuste en Reembolso de Gastos Médicos Dentales y Hospitalarios Exento',
  },
  {
    id: '029',
    description: 'Ajuste en Fondo de ahorro Exento',
  },
  {
    id: '030',
    description: 'Ajuste en Caja de ahorro Exento',
  },
  {
    id: '031',
    description:
      'Ajuste en Contribuciones a Cargo del Trabajador Pagadas por el Patrón Exento',
  },
  {
    id: '032',
    description: 'Ajuste en Premios por puntualidad Gravado',
  },
  {
    id: '033',
    description: 'Ajuste en Prima de Seguro de vida Exento',
  },
  {
    id: '034',
    description: 'Ajuste en Seguro de Gastos Médicos Mayores Exento',
  },
  {
    id: '035',
    description: 'Ajuste en Cuotas Sindicales Pagadas por el Patrón Exento',
  },
  {
    id: '036',
    description: 'Ajuste en Subsidios por incapacidad Exento',
  },
  {
    id: '037',
    description: 'Ajuste en Becas para trabajadores y/o hijos Exento',
  },
  {
    id: '038',
    description: 'Ajuste en Horas extra Exento',
  },
  {
    id: '039',
    description: 'Ajuste en Horas extra Gravado',
  },
  {
    id: '040',
    description: 'Ajuste en Prima dominical Exento',
  },
  {
    id: '041',
    description: 'Ajuste en Prima dominical Gravado',
  },
  {
    id: '042',
    description: 'Ajuste en Prima vacacional Exento',
  },
  {
    id: '043',
    description: 'Ajuste en Prima vacacional Gravado',
  },
  {
    id: '044',
    description: 'Ajuste en Prima por antigüedad Exento',
  },
  {
    id: '045',
    description: 'Ajuste en Prima por antigüedad Gravado',
  },
  {
    id: '046',
    description: 'Ajuste en Pagos por separación Exento',
  },
  {
    id: '047',
    description: 'Ajuste en Pagos por separación Gravado',
  },
  {
    id: '048',
    description: 'Ajuste en Seguro de retiro Exento',
  },
  {
    id: '049',
    description: 'Ajuste en Indemnizaciones Exento',
  },
  {
    id: '050',
    description: 'Ajuste en Indemnizaciones Gravado',
  },
  {
    id: '051',
    description: 'Ajuste en Reembolso por funeral Exento',
  },
  {
    id: '052',
    description:
      'Ajuste en Cuotas de seguridad social pagadas por el patrón Exento',
  },
  {
    id: '053',
    description: 'Ajuste en Comisiones Gravado',
  },
  {
    id: '054',
    description: 'Ajuste en Vales de despensa Exento',
  },
  {
    id: '055',
    description: 'Ajuste en Vales de restaurante Exento',
  },
  {
    id: '056',
    description: 'Ajuste en Vales de gasolina Exento',
  },
  {
    id: '057',
    description: 'Ajuste en Vales de ropa Exento',
  },
  {
    id: '058',
    description: 'Ajuste en Ayuda para renta Exento',
  },
  {
    id: '059',
    description: 'Ajuste en Ayuda para artículos escolares Exento',
  },
  {
    id: '060',
    description: 'Ajuste en Ayuda para anteojos Exento',
  },
  {
    id: '061',
    description: 'Ajuste en Ayuda para transporte Exento',
  },
  {
    id: '062',
    description: 'Ajuste en Ayuda para gastos de funeral Exento',
  },
  {
    id: '063',
    description: 'Ajuste en Otros ingresos por salarios Exento',
  },
  {
    id: '064',
    description: 'Ajuste en Otros ingresos por salarios Gravado',
  },
  {
    id: '065',
    description:
      'Ajuste en Jubilaciones, pensiones o haberes de retiro en una sola exhibición Exento',
  },
  {
    id: '066',
    description:
      'Ajuste en Jubilaciones, pensiones o haberes de retiro en una sola exhibición Gravado',
  },
  {
    id: '067',
    description: 'Ajuste en Pagos por separación Acumulable',
  },
  {
    id: '068',
    description: 'Ajuste en Pagos por separación No acumulable',
  },
  {
    id: '069',
    description:
      'Ajuste en Jubilaciones, pensiones o haberes de retiro en parcialidades Exento',
  },
  {
    id: '070',
    description:
      'Ajuste en Jubilaciones, pensiones o haberes de retiro en parcialidades Gravado',
  },
  {
    id: '071',
    description:
      'Ajuste en Subsidio para el empleo (efectivamente entregado al trabajador)',
  },
  {
    id: '072',
    description:
      'Ajuste en Ingresos en acciones o títulos valor que representan bienes Exento',
  },
  {
    id: '073',
    description:
      'Ajuste en Ingresos en acciones o títulos valor que representan bienes Gravado',
  },
  {
    id: '074',
    description: 'Ajuste en Alimentación Exento',
  },
  {
    id: '075',
    description: 'Ajuste en Alimentación Gravado',
  },
  {
    id: '076',
    description: 'Ajuste en Habitación Exento',
  },
  {
    id: '077',
    description: 'Ajuste en Habitación Gravado',
  },
  {
    id: '078',
    description: 'Ajuste en Premios por asistencia',
  },
  {
    id: '079',
    description:
      'Ajuste en Pagos distintos a los listados y que no deben considerarse como ingreso por sueldos, salarios o ingresos asimilados.',
  },
  {
    id: '080',
    description: 'Ajuste en Viáticos gravados',
  },
  {
    id: '081',
    description: 'Ajuste en Viáticos (entregados al trabajador)',
  },
  {
    id: '082',
    description: 'Ajuste en Fondo de ahorro Gravado',
  },
  {
    id: '083',
    description: 'Ajuste en Caja de ahorro Gravado',
  },
  {
    id: '084',
    description: 'Ajuste en Prima de Seguro de vida Gravado',
  },
  {
    id: '085',
    description: 'Ajuste en Seguro de Gastos Médicos Mayores Gravado',
  },
  {
    id: '086',
    description: 'Ajuste en Subsidios por incapacidad Gravado',
  },
  {
    id: '087',
    description: 'Ajuste en Becas para trabajadores y/o hijos Gravado',
  },
  {
    id: '088',
    description: 'Ajuste en Seguro de retiro Gravado',
  },
  {
    id: '089',
    description: 'Ajuste en Vales de despensa Gravado',
  },
  {
    id: '090',
    description: 'Ajuste en Vales de restaurante Gravado',
  },
  {
    id: '091',
    description: 'Ajuste en Vales de gasolina Gravado',
  },
  {
    id: '092',
    description: 'Ajuste en Vales de ropa Gravado',
  },
  {
    id: '093',
    description: 'Ajuste en Ayuda para renta Gravado',
  },
  {
    id: '094',
    description: 'Ajuste en Ayuda para artículos escolares Gravado',
  },
  {
    id: '095',
    description: 'Ajuste en Ayuda para anteojos Gravado',
  },
  {
    id: '096',
    description: 'Ajuste en Ayuda para transporte Gravado',
  },
  {
    id: '097',
    description: 'Ajuste en Ayuda para gastos de funeral Gravado',
  },
  {
    id: '098',
    description: 'Ajuste a ingresos asimilados a salarios gravados',
  },
  {
    id: '099',
    description: 'Ajuste a ingresos por sueldos y salarios gravados',
  },
  {
    id: '100',
    description: 'Ajuste en Viáticos exentos',
  },
  {
    id: '101',
    description: 'ISR Retenido de ejercicio anterior',
  },
  {
    id: '102',
    description:
      'Ajuste a pagos por gratificaciones, primas, compensaciones, recompensas u otros a extrabajadores derivados de jubilación en parcialidades, gravados',
  },
  {
    id: '103',
    description:
      'Ajuste a pagos que se realicen a extrabajadores que obtengan una jubilación en parcialidades derivados de la ejecución de una resolución judicial o de un laudo gravados',
  },
  {
    id: '104',
    description:
      'Ajuste a pagos que se realicen a extrabajadores que obtengan una jubilación en parcialidades derivados de la ejecución de una resolución judicial o de un laudo exentos',
  },
  {
    id: '105',
    description:
      'Ajuste a pagos que se realicen a extrabajadores que obtengan una jubilación en una sola exhibición derivados de la ejecución de una resolución judicial o de un laudo gravados',
  },
  {
    id: '106',
    description:
      'Ajuste a pagos que se realicen a extrabajadores que obtengan una jubilación en una sola exhibición derivados de la ejecución de una resolución judicial o de un laudo exentos',
  },
  {
    id: '107',
    description: 'Ajuste al Subsidio Causado',
  },
]
