<template>
  <div class="main-content mt-5">
    <div class="page-header">
      <h3 class="page-title">
        Estados de cuenta
      </h3>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card p-4">
          <table-component ref="table" :data="listFiles" :filter-no-results="$t('general.filterNoResults')"
            :is-clickable="true" :show-filter="false" table-class="table">
            <table-column label="Nombre">
              <template slot-scope="row">
                <div>
                  {{ row.filename }}
                </div>
              </template>
            </table-column>
            <table-column label="" cell-class="no-click">

              <template slot-scope="row">
                <base-button size="small" color="theme" @click="verArchivos(row.url)">
                  Ver archivo
                </base-button>
              </template>
            </table-column>
            <table-column label="Fecha de carga" show="created_at" />
          </table-component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MonthPeriod from '../components/MonthPeriod.vue'
import TaxRegimeCatalog from '../components/TaxRegimeCatalog.vue'
import IconInCustomerTaxReturn from '../components/IconInCustomerTaxReturn'
import TableColumn from '../../components/base/base-table/components/TableColumn.vue'
export default {
  components: {
    MonthPeriod,
    TaxRegimeCatalog,
    IconInCustomerTaxReturn,
    TableColumn,
  },
  data: () => ({
    listFiles: [],
  }),
  computed: {
    ...mapGetters('CurrentCustomer', ['currentCustomer']),
  },
  mounted() {
    const id = this.$route.params.id
    this.loadTaxReturns(id);
  },

  methods: {
    ...mapActions('statementAccount', [
      'listFileStatementAccount',
    ]),
    loadTaxReturns(id) {

      this.listFileStatementAccount(id).then((response) => {
        this.listFiles = (response.data.files)
      })
    },

    verArchivos(ruta) {
      window.open(`${ruta}`, '_blank')

    }
  },
}
</script>
