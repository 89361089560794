<template>
  <div class="main-content">
    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    ></loading>

    <div class="page-header mt-4">
      <h3 class="page-title">Carga manual de CFDI</h3>
    </div>

    <div class="card mt-3 pt-4 pb-4">
      <div class="row">
        <div class="col-12 col-md-6 offset-md-3">
          <form @submit.prevent="submit">
            <div class="form-group">
              <input
                type="file"
                class="form-control"
                accept=".xml,.zip"
                @change="readFile"
              />
            </div>

            <div class="form-group text-center">
              <button class="btn btn-primary" type="submit">
                {{ $t('general.save') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="page-header mt-4">
      <h3 class="page-title">Carga manual scrapper</h3>
    </div>
    <div class="card mt-3 pt-4 pb-4">
      <div class="row">
        <div class="col-12 col-md-6 offset-md-3">
          <div class="form-group text-center">
            <button class="btn btn-primary" @click="descargarScrapper">
              Descargar CFDI
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  data: () => ({
    file: null,
    isLoading: false,
    fullPage: true,
  }),
  components: {
    Loading,
  },
  computed: {
    ...mapGetters('CurrentCustomer', ['currentCustomer']),
    ...mapGetters('assigned', ['assingedSelect']),
  },
  methods: {
    ...mapActions('invoiceManual', ['sendXml']),
    ...mapActions('downloadCfdi', ['download']),

    readFile(event) {
      const input = event.target
      this.file = input.files[0]
    },
    submit() {
      const formData = new FormData()
      if (this.file.type === 'text/xml') {
        formData.append('xmlFile', this.file)
      } else {
        formData.append('zipFile', this.file)
      }
      formData.append('customer_id', this.currentCustomer.id)
      this.sendXml(formData)
        .then((response) => {
          console.log(response.status)
          if (response.status == 200) {
            this.$router.push('/general/payments')
          }
        })
        .catch((err) => {})
    },
    descargarScrapper() {
      var data = {
        user_id: this.currentCustomer.id,
        assigned_declaration_id: this.assingedSelect.id,
      }
      this.isLoading = true
      this.download(data).then((response) => {
        this.isLoading = false
        if (response.status == 200) {
          this.$router.push('/general/payments')
        }
      })
    },
    onCancel() {
      console.log('User cancelled the loader.')
    },
  },
}
</script>
