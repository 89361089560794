import * as getters from './getters'
import { taxRegimesCatalog } from './tax_regimes'
import { digitalPlatformOptions } from './digital_platforms'
import { useCFDI } from './use_cfdi'
import { paymentMethods } from './payment_methods'
import { valuesIva } from './values_iva'
import { wayToPay } from './way_to_pay'
import { taxReturnPeriodPerTaxRegime } from './tax_regime_periods'
import { PaidDocumentOptions } from './paid_document_options'
import { perceptionTypes } from './perception_types'
import { deductionTypes } from './deduction_types'
import { otherPaymentTypes } from './other_payment_types'
import { unitCodeSelect } from './unit_code';

const initialState = {
  taxRegimes: taxRegimesCatalog,
  digitalPlatformOptions: digitalPlatformOptions,
  useCFDI: useCFDI,
  paymentMethods: paymentMethods,
  valuesIva: valuesIva,
  wayToPay: wayToPay,
  taxReturnPeriodPerTaxRegime: taxReturnPeriodPerTaxRegime,
  paidDocumentOptions: PaidDocumentOptions,
  perceptionTypes: perceptionTypes,
  deductionTypes: deductionTypes,
  otherPaymentTypes: otherPaymentTypes,
  unitCodeSelect: unitCodeSelect,
}

export default {
  namespaced: true,

  state: initialState,

  getters: getters,

  actions: {},

  mutations: {},
}
