var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.shouldShowPagination
    ? _c("nav", [
        _c(
          "ul",
          { staticClass: "pagination justify-content-center" },
          [
            _c(
              "li",
              { class: { disabled: _vm.pagination.currentPage === 1 } },
              [
                _c(
                  "a",
                  {
                    class: { disabled: _vm.pagination.currentPage === 1 },
                    on: {
                      click: function ($event) {
                        return _vm.pageClicked(_vm.pagination.currentPage - 1)
                      },
                    },
                  },
                  [_c("i", { staticClass: "left chevron icon" }, [_vm._v("«")])]
                ),
              ]
            ),
            _vm._v(" "),
            _vm.hasFirst
              ? _c(
                  "li",
                  {
                    staticClass: "page-item",
                    class: { active: _vm.isActive(1) },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "page-link",
                        on: {
                          click: function ($event) {
                            return _vm.pageClicked(1)
                          },
                        },
                      },
                      [_vm._v("1")]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hasFirstEllipsis
              ? _c("li", [
                  _c("span", { staticClass: "pagination-ellipsis" }, [
                    _vm._v("…"),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.pages, function (page) {
              return _c(
                "li",
                {
                  key: page,
                  staticClass: "page-item",
                  class: {
                    active: _vm.isActive(page),
                    disabled: page === "...",
                  },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "page-link",
                      on: {
                        click: function ($event) {
                          return _vm.pageClicked(page)
                        },
                      },
                    },
                    [_vm._v(_vm._s(page))]
                  ),
                ]
              )
            }),
            _vm._v(" "),
            _vm.hasLastEllipsis
              ? _c("li", [
                  _c("span", { staticClass: "pagination-ellipsis" }, [
                    _vm._v("…"),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.hasLast
              ? _c(
                  "li",
                  {
                    staticClass: "page-item",
                    class: { active: _vm.isActive(this.pagination.totalPages) },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "page-link",
                        on: {
                          click: function ($event) {
                            return _vm.pageClicked(_vm.pagination.totalPages)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.pagination.totalPages) +
                            "\n      "
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  class: {
                    disabled:
                      _vm.pagination.currentPage === _vm.pagination.totalPages,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.pageClicked(_vm.pagination.currentPage + 1)
                    },
                  },
                },
                [_c("i", { staticClass: "right chevron icon" }, [_vm._v("»")])]
              ),
            ]),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }