var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "customer-select" }, [
    _c("div", { staticClass: "main" }, [
      _c(
        "div",
        { staticClass: "search-bar" },
        [
          _c("base-input", {
            attrs: {
              placeholder: _vm.$t("general.search"),
              focus: "",
              type: "text",
              icon: "search",
            },
            on: { input: _vm.searchCustomer },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.customers.length > 0 && !_vm.loading
        ? _c(
            "div",
            { staticClass: "list" },
            _vm._l(_vm.customers, function (customer, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "list-item",
                  on: {
                    click: function ($event) {
                      return _vm.selectNewCustomer(customer.id)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "avatar" }, [
                    _vm._v(_vm._s(_vm.initGenerator(customer.name))),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "name" }, [
                    _c("label", { staticClass: "title" }, [
                      _vm._v(_vm._s(customer.name)),
                    ]),
                    _vm._v(" "),
                    _c("label", { staticClass: "sub-title" }, [
                      _vm._v(_vm._s(customer.contact_name)),
                    ]),
                  ]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loading
        ? _c(
            "div",
            {
              staticClass:
                "list flex justify-content-center align-items-center",
            },
            [
              _c("font-awesome-icon", {
                staticClass: "fa-spin",
                attrs: { icon: "spinner" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.customers.length === 0
        ? _c("div", { staticClass: "no-data-label" }, [
            _c("label", [
              _vm._v(
                " " + _vm._s(_vm.$t("customers.no_customers_found")) + " "
              ),
            ]),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "list-add-button",
        attrs: { type: "button" },
        on: { click: _vm.openCustomerModal },
      },
      [
        _c("font-awesome-icon", {
          staticClass: "icon",
          attrs: { icon: "user-plus" },
        }),
        _vm._v(" "),
        _c("label", [_vm._v(_vm._s(_vm.$t("customers.add_new_customer")))]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }