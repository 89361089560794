<template>
    <div
      v-tooltip.top-center="description"
      class="text-center"
    >
      <font-awesome-icon
        :class="iconClass"
        :icon="iconName"
        @click="emitClick()"
        class="icon"
      />
    </div>
</template>

<script>
const STATUS_COLORS = {
  PENDING_PROCESSING: 'text-black',
  UNASSIGNED: 'text-danger',
  PARTIALLY: 'text-warning',
  COMPLETE: 'text-success',
  COMPLETE_WITH_PAID_DOCS: 'text-blue',
}
const STATUS_ICONS = {
  PENDING_PROCESSING: 'minus-circle',
  UNASSIGNED: 'times-circle',
  PARTIALLY: 'check-circle',
  COMPLETE: 'check-circle',
  COMPLETE_WITH_PAID_DOCS: 'check-circle',
}
export default {
  props: {
    status: {
      default: 'UNASSIGNED',
      type: String
    },
    module: {
      default: '',
      type: String
    },
  },
  data: () => ({
    showingPopover: false,
  }),
  computed: {
    iconClass () {
      return STATUS_COLORS[this.status];
    },
    iconName () {
      return STATUS_ICONS[this.status];
    },
    description () {
      return this.$t(`messages.tooltips.status.${this.module}.${this.status}`)
    }
  },
  methods: {
    emitClick () {
      this.$emit('click');
    },
  }
}
</script>
