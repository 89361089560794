export const SET_PAYMENTS = 'SET_PAYMENTS'
export const ADD_PAYMENT = 'ADD_PAYMENT'
export const UPDATE_PAYMENT = 'UPDATE_PAYMENT'
export const DELETE_PAYMENT = 'DELETE_PAYMENT'
export const DELETE_MULTIPLE_PAYMENTS = 'DELETE_MULTIPLE_PAYMENTS'
export const SET_SELECTED_PAYMENTS = 'SET_SELECTED_PAYMENTS'
export const SET_TOTAL_PAYMENTS = 'SET_TOTAL_PAYMENTS'
export const SET_SELECT_ALL_STATE = 'SET_SELECT_ALL_STATE'
export const ADD_PAYMENT_MODE = 'ADD_PAYMENT_MODE'
export const DELETE_PAYMENT_MODE = 'DELETE_PAYMENT_MODE'
export const SET_PAYMENT_MODES = 'SET_PAYMENT_MODES'
export const UPDATE_PAYMENT_MODE = 'UPDATE_PAYMENT_MODE'
