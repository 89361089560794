<template>
  <span>{{ result }}</span>
</template>

<script>
export default {
  props: {
    code: {
      default: '',
      type: String
    }
  },
  data: () => ({
    catalog: [
      {
        code: 'I',
        description: 'Ingreso',
      },
      {
        code: 'E',
        description: 'Egreso',
      },
      {
        code: 'T',
        description: 'Traslado',
      },
      {
        code: 'N',
        description: 'Nómina',
      },
      {
        code: 'P',
        description: 'Pago',
      },
    ],
  }),
  computed: {
    result () {
      const result = this.catalog.filter(t => t.code == this.code)
      if (result.length) {
        return `${result[0].code} - ${result[0].description}`
      }
      return this.code;
    }
  }
}
</script>
