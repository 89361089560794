import * as types from './mutation-types';

export default {
  [types.SET_PROD_SERV_GROUPS] (state, prodServGroups) {
    state.prodServGroups = prodServGroups;
  },

  [types.APPEND_PROD_SERV_GROUP] (state, prodServGroup) {
    state.prodServGroups.push(prodServGroup);
  },

  [types.SET_CHANGE_CATEGORY_DATA] (state, data) {
    state.changeCategoryData = data;
  },

}

