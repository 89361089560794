var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    {
      on: {
        click: _vm.onClick,
        mouseover: function ($event) {
          return _vm.mouseOver($event)
        },
      },
    },
    _vm._l(_vm.visibleColumns, function (column) {
      return _c("table-cell", {
        key: column.id,
        attrs: {
          row: _vm.row,
          column: column,
          "responsive-label": column.label,
        },
        on: {
          click: function ($event) {
            return _vm.columnClick($event)
          },
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }