var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sidebar-left" }, [
    _c("div", { staticClass: "sidebar-body scroll-pane" }, [
      _c(
        "div",
        { staticClass: "side-nav" },
        [
          _vm._l(_vm.menu2, function (menuItems, index2) {
            return _c(
              "div",
              { key: index2 + "b", staticClass: "flex-shrink-0 p-3 bg-white" },
              [
                _c(
                  "ul",
                  { staticClass: "list-unstyled ps-0" },
                  _vm._l(menuItems, function (item, index1b) {
                    return _c(
                      "li",
                      { staticClass: "mb-1" },
                      [
                        _c(
                          "conditional-wrapper",
                          {
                            attrs: {
                              "show-for-admin": item.admin,
                              "show-for-executives": item.ejecutivo,
                              "show-for-customers": item.supervisor,
                            },
                          },
                          [
                            item.submenu
                              ? [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "menu-item",
                                      class: {
                                        disabled:
                                          item.require_customer &&
                                          !_vm.currentCustomer,
                                      },
                                      attrs: { tag: "router-link" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleCollapse(
                                            item.id_collapse
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "icon menu-icon",
                                        attrs: { icon: item.icon },
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "ml-3 menu-text" },
                                        [_vm._v(_vm._s(_vm.$t(item.title)))]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      class: {
                                        collapse:
                                          !_vm.collapsed[item.id_collapse],
                                      },
                                      attrs: { id: item.id_collapse },
                                    },
                                    _vm._l(
                                      item.submenu,
                                      function (item2, index1b) {
                                        return _c(
                                          "ul",
                                          {
                                            staticClass:
                                              "btn-toggle-nav list-unstyled fw-normal pb-1 small",
                                          },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "menu-item",
                                                class: {
                                                  disabled:
                                                    item.require_customer &&
                                                    !_vm.currentCustomer,
                                                },
                                                attrs: { to: item2.route },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.Toggle.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "ml-3 menu-text",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(item2.title)
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              : [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "menu-item",
                                      class: {
                                        disabled:
                                          item.require_customer &&
                                          !_vm.currentCustomer,
                                      },
                                      attrs: { to: item.route },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.Toggle.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "icon menu-icon",
                                        attrs: { icon: item.icon },
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "ml-3 menu-text" },
                                        [_vm._v(_vm._s(_vm.$t(item.title)))]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]
            )
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }