var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "h6",
      [
        _c("status-icon", {
          attrs: { status: _vm.expense.in_tax_return, module: "in_tax_return" },
        }),
        _vm._v(
          "\n    " +
            _vm._s(_vm.expense.serie) +
            " " +
            _vm._s(_vm.expense.invoice_number) +
            "\n  "
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "card p-1" }, [
      _c("div", { staticClass: "table-responsive" }, [
        _c("table", { staticClass: "table table-bordered" }, [
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("invoices.item.description")))]),
              _vm._v(" "),
              _c("th", [
                _vm._v(_vm._s(_vm.$t("invoices.item.clave_prod_serv"))),
              ]),
              _vm._v(" "),
              _c("th", { staticClass: "text-center" }, [
                _vm._v(_vm._s(_vm.$t("invoices.item.quantity"))),
              ]),
              _vm._v(" "),
              _c("th", { staticClass: "text-center amount-cell" }, [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.$t("invoices.item.amount")) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c("th", { staticClass: "text-center amount-cell" }, [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.$t("invoices.discount")) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c("th", { staticClass: "text-center amount-cell" }, [
                _vm._v(_vm._s(_vm.$t("invoices.iva"))),
              ]),
              _vm._v(" "),
              _c("th", { staticClass: "text-center amount-cell" }, [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.$t("invoices.item.total")) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$tc("invoices.category", 1)))]),
              _vm._v(" "),
              _c("th", { staticClass: "text-center" }, [
                _vm._v(_vm._s(_vm.$tc("invoices.tax", 1))),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.expense.items, function (concept) {
              return _c("expense-concept", {
                key: concept.id,
                ref: `concept_${concept.id}`,
                refInFor: true,
                attrs: { concept: concept },
              })
            }),
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "text-right mt-2" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary btn-sm",
            attrs: { disabled: _vm.expense.confirmed },
            on: { click: _vm.serializeInvoice },
          },
          [
            _vm._v(
              "\n        " + _vm._s(_vm.$t("general.confirm")) + "\n      "
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }