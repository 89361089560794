import * as types from './mutation-types'

export default {
  [types.SET_CATEGORIES](state, data) {
    state.categories = data
  },
  [types.ADD_CATEGORIES_SELECT](state, data) {
    state.categoriesSelect.push(data);
  },

  [types.ADD_CATEGORIES_SELECT_ALL](state, data) {
    state.categoriesSelect = data
  },

  [types.CLEAR_CATEGORIES_SELECT](state, data) {
    state.categoriesSelect = [];
  },

  [types.REMOVE_CATEGORIES_SELECT](state, data) {
    const index = state.categoriesSelect.indexOf(data);
    if (index !== -1) {
      state.categoriesSelect.splice(index, 1);
    }
  },

  [types.SET_SUBCATEGORIES_USER](state, data) {
    state.subcategoriesUser = data
  },
  [types.SET_SUBCATEGORIES](state, data) {
    state.subcategories = data
  },

  [types.SET_SUB_CATEGORY_MODAL_DATA](state, data) {
    state.modalSubcategoryData = data
  },

  [types.SET_MODAL_CHANGE_CATEGORY_DATA](state, data) {
    state.modalChangeSubcategoryData = data
  },
}
