<template>
  <span>{{ result }}</span>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    type: {
      default: '',
      type: String,
      validator(val) {
        return ['perception', 'deduction', 'other_payment'].includes(val)
      },
    },
    code: {
      default: '',
      type: String,
    },
  },
  computed: {
    ...mapGetters('catalogs', [
      'perceptionTypes',
      'deductionTypes',
      'otherPaymentTypes',
    ]),
    result() {
      const keyOfCatalog = {
        perception: 'perceptionTypes',
        deduction: 'deductionTypes',
        other_payment: 'otherPaymentTypes',
      }
      const catalog = this[keyOfCatalog[this.type]]
      const result = catalog.filter((t) => t.id == this.code)
      if (result.length) {
        return `${result[0].id} - ${result[0].description}`
      }
      return this.code
    },
  },
}
</script>
