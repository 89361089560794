import * as types from './mutation-types'
import ls from '../../../services/ls'

export const assingedSelect = (state) => {
  return state.assingedSelect != null
    ? state.assingedSelect
    : ls.getObject(types.LOCAL_ASSIGNED_SELECTE)
}

// export const getCategoryById = (state) => (id) => {
//   return state.categories.find(category => category.id === id)
// }
