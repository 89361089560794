import * as types from './mutation-types'

const KEY_CURRENT_CUSTOMER = 'current_customer'

export const setCurrentCustomer = ({ commit }, customer) => {
  if (customer == null) {
    localStorage.removeItem(KEY_CURRENT_CUSTOMER)
    return
  }
  localStorage.setItem(KEY_CURRENT_CUSTOMER, JSON.stringify(customer))
  commit(types.SET_CURRENT_CUSTOMER, customer)
}

export const loadUserFromStorage = ({ commit, dispatch }) => {
  const currentCustomer = localStorage.getItem(KEY_CURRENT_CUSTOMER)
  if (currentCustomer) {
    const customer = JSON.parse(currentCustomer)
    commit(types.SET_CURRENT_CUSTOMER, customer)
    dispatch('customer/fetchCustomer', customer.id, { root: true })
      .then((response) => {
        commit(types.SET_CURRENT_CUSTOMER, response.data.customer)
        dispatch('setCurrentCustomer', response.data.customer)
      })
      .catch(() => {})
  }
}
