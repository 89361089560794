var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.retentions
    ? _c("div", { staticClass: "table-responsive" }, [
        _c("h6", { staticClass: "text-center" }, [
          _vm._v(_vm._s(_vm.$t(_vm.title))),
        ]),
        _vm._v(" "),
        _c("table", { staticClass: "table table-bordered" }, [
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("invoices.uuid")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("invoices.rfc")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("invoices.name")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("retenciones.date")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("invoices.iva")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("expenses.retention_iva")))]),
              _vm._v(" "),
              _c("th", [_vm._v(_vm._s(_vm.$t("expenses.retention_isr")))]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.retentions, function (retention) {
              return _c("tr", { key: retention.id }, [
                _c("td", [_vm._v(_vm._s(retention.uuid))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(retention.rfc))]),
                _vm._v(" "),
                _c(
                  "td",
                  [_c("sub-string", { attrs: { string: retention.name } })],
                  1
                ),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(retention.date))]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("money-text", {
                      attrs: { amount: retention.iva_transferred },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("money-text", {
                      attrs: { amount: retention.retention_iva },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("money-text", {
                      attrs: { amount: retention.retention_isr },
                    }),
                  ],
                  1
                ),
              ])
            }),
            0
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }