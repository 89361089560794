<template>
  <tr class="item-row invoice-item-row">
    <!-- Description -->
    <td style="padding: 10px !important;">
      <base-input
        v-model="item.description"
        :invalid="$v.item.description.$error"
        type="text"
        @input="$v.item.description.$touch()"
        @keyup="updateItem"
      />
      <div v-if="$v.item.description.$error">
        <span v-if="!$v.item.description.required" class="text-danger">
          {{ $t('validation.required') }}
        </span>
      </div>
    </td>

    <!-- Prod/Serv -->
    <td class="text-center" style="padding: 10px !important;">
      <base-select
        v-model="item.productCode"
        :options="clavesProdServ"
        :searchable="true"
        :allow-empty="false"
        :show-labels="false"
        :placeholder="$t('items.select_a_unit')"
        :invalid="$v.item.unitCode.$error"
        label="description"
        track-by="id"
      />

      <div v-if="$v.item.productCode.$error">
        <span v-if="!$v.item.productCode.required" class="text-danger">
          {{ $t('validation.required') }}
        </span>
      </div>
    </td>

    <!-- Quantity -->
    <td class="text-center" style="padding: 10px !important;">
      <base-input
        v-model="item.quantity"
        :invalid="$v.item.quantity.$error"
        type="text"
        small
        @keyup="updateItem"
        @input="$v.item.quantity.$touch()"
      />
      <div v-if="$v.item.quantity.$error">
        <span v-if="!$v.item.quantity.required" class="text-danger">
          {{ $t('validation.required') }}
        </span>
        <span v-if="!$v.item.quantity.maxLength" class="text-danger">
          {{ $t('validation.quantity_maxlength') }}
        </span>
      </div>
    </td>

    <!-- Unit Code -->
    <td class="text-center" style="padding: 10px !important;">
      <div class="btn-group discount-drop-down" role="group">
        <base-select
          v-model="item.unitCode"
          :options="unitCodeOptions"
          :searchable="true"
          :allow-empty="false"
          :show-labels="false"
          :placeholder="$t('items.select_a_unit')"
          :invalid="$v.item.unitCode.$error"
          label="description"
          track-by="id"
        />

        <div v-if="$v.item.unitCode.$error">
          <span v-if="!$v.item.unitCode.required" class="text-danger">
            {{ $t('validation.required') }}
          </span>
        </div>
      </div>
    </td>

    <!-- Unit price -->
    <td class="text-left" style="padding: 10px !important;">
      <div class="d-flex flex-column">
        <div class="flex-fillbd-highlight">
          <div class="base-input">
            <money
              v-model="item.unitPrice"
              v-bind="customerCurrency"
              class="input-field"
              @input="$v.item.unitPrice.$touch()"
              @keyup="updateItem"
            />
          </div>
          <div v-if="$v.item.unitPrice.$error">
            <span v-if="!$v.item.unitPrice.maxLength" class="text-danger">
              {{ $t('validation.price_maxlength') }}
            </span>
          </div>
        </div>
      </div>
    </td>

    <!-- Iva -->
    <td class="text-center" style="padding: 10px !important;">
      <div class="invoice-amount">
        <div class="btn-group discount-drop-down" role="group">
          <base-input
            v-model="item.ivaAmount"
            readOnly
            input-class="item-discount"
          />
          <v-dropdown :show-arrow="false">
            <button
              slot="activator"
              type="button"
              class="btn item-dropdown dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <!-- {{ item.ivaType == 'fixed' ? defaultCurrency.symbol : '%' }} -->
              {{ '%' }}
            </button>
            <v-dropdown-item>
              <a
                class="dropdown-item"
                href="#"
                @click.prevent="selectPercentageValor('16')"
              >
                {{ '16%' }}
              </a>
            </v-dropdown-item>
            <v-dropdown-item>
              <a
                class="dropdown-item"
                href="#"
                @click.prevent="selectPercentageValor('8')"
              >
                {{ '8%' }}
              </a>
            </v-dropdown-item>
            <v-dropdown-item>
              <a
                class="dropdown-item"
                href="#"
                @click.prevent="selectPercentageValor('0')"
              >
                {{ '0%' }}
              </a>
            </v-dropdown-item>
            <v-dropdown-item>
              <a
                class="dropdown-item"
                href="#"
                @click.prevent="selectPercentageValor('0')"
              >
                {{ 'EXENTO' }}
              </a>
            </v-dropdown-item>
            <!-- <v-dropdown-item>
              <a class="dropdown-item" href="#" @click.prevent="selectFixed">
                {{ $t('general.fixed') }}
              </a>
            </v-dropdown-item> -->
          </v-dropdown>
        </div>
      </div>
    </td>

    <!-- Total -->
    <td class="text-right" style="padding: 10px !important;">
      <div class="item-amount">
        <span>
          <div v-html="$utils.formatMoney(total, defaultCurrency)" />
        </span>

        <div class="remove-icon-wrapper">
          <font-awesome-icon
            v-if="showRemoveItemIcon"
            class="remove-icon"
            icon="trash-alt"
            @click="removeItem"
          />
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import { required, maxLength, minValue } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    itemData: {
      type: Object,
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
  },
  validationMixin: [validationMixin],
  data: () => ({
    item: {},
    clavesProdServ: [],
  }),
  validations: () => ({
    item: {
      description: {
        required,
      },
      quantity: {
        required,
        minValue: minValue(1),
        maxLength: maxLength(20),
      },
      unitPrice: {
        required,
        minValue: minValue(1),
        maxLength: maxLength(20),
      },
      productCode: { required },
      unitCode: { required },
    },
  }),
  computed: {
    ...mapGetters('currency', ['defaultCurrency']),
    /*     ...mapGetters('catalogs', [
          'valuesIva',
          'searchValueIva',
        ]), */
    /*     valuesIvaOptions() {
          return this.valuesIva.map((item) => {
            return {
              ...item,
              description: `${item.id} - ${item.description}`,
            }
          }) 
        },*/
    ...mapGetters('catalogs', ['unitCodeSelect']),
    customerCurrency() {
      if (this.defaultCurrency) {
        return {
          decimal: this.defaultCurrency.decimal_separator,
          thousands: this.defaultCurrency.thousand_separator,
          prefix: this.defaultCurrency.symbol + ' ',
          precision: this.defaultCurrency.precision,
          masked: false,
        }
      } else {
        return this.defaultCurrenctForInput
      }
    },

    unitCodeOptions() {
      let arrayCodes = this.itemData.unitCodes
      let arregloFiltro1 = []
      let arregloFiltro2 = this.unitCodeSelect

      if (arrayCodes != null && arrayCodes != '' && arrayCodes.length > 0) {
        arrayCodes.forEach((elemento1) => {
          let configElemento = this.unitCodeSelect.filter(
            (element2) => element2.id == elemento1.id
          )
          if (configElemento) {
            arregloFiltro1.push(configElemento[0])
          }
        })

        arrayCodes.forEach((elemento1) => {
          arregloFiltro2 = arregloFiltro2.filter(
            (element2) => element2.id != elemento1.id
          )
        })
      }

      let arregloFiltro3 = arregloFiltro1.concat(arregloFiltro2)

      return arregloFiltro3.map((item) => {
        return {
          ...item,
          description: `${item.id} - ${item.description}`,
        }
      })
    },
    showRemoveItemIcon() {
      if (this.items.length == 1) {
        return false
      }
      return true
    },
    subtotal() {
      return Number(this.item.unitPrice) * Number(this.item.quantity)
    },
    iva() {
      const ivaAmount = Number(this.item.ivaAmount)
      /* if (this.item.ivaType == 'percentage') { */
        let iva = this.subtotal * (ivaAmount / 100)
        return iva
/*       }
      return ivaAmount */
    },
    total() {
      let total = this.subtotal + this.iva
      return total * 100
    },
  },
  watch: {
    item: {
      handler: 'updateItem',
      deep: true,
    },
  },
  created() {
    this.item = {
      ...this.itemData,
    }
  },
  mounted() {
    window.hub.$on('checkInvoicingItems', this.validateItem)
    this.claveProductoServicio()
  },
  methods: {
    ...mapActions('customer', ['fetchCatalogoServProd']),
    selectUnitCode(unidad) {
      this.item.unitCode = unidad
    },
    selectPercentageValor(valor) {
      this.item.ivaAmount = valor
    },
    selectPercentage() {
      this.item.ivaType = 'percentage'
    },
    removeItem() {
      this.$emit('remove', this.index)
    },
    selectFixed() {
      this.item.ivaType = 'fixed'
    },
    updateItem() {
      this.$emit('update', {
        index: this.index,
        item: {
          ...this.item,
          subtotal: this.subtotal,
          ivaAmount: this.iva,
          total: this.total,
        },
      })
    },
    validateItem() {
      this.$v.item.$touch()
      if (this.item !== null) {
        this.$emit('itemValidate', this.index, !this.$v.$invalid)
      } else {
        this.$emit('itemValidate', this.index, false)
      }
    },
    claveProductoServicio() {
      try{
      this.clavesProdServ = this.itemData.productServiceCodes.map((item) => {
        return {
          ...item,
          description: `${item.Value} - ${item.Name}`,
          id: item.Value,
        }
      })
      }catch(err){
        window.toastr['error']("Debe configurar las claves de producto/servicio en la Configuración SAT del cliente.")
      }
    },
  },
}
</script>
