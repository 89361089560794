<template>
  <div class="main-content invoice-create-page">
    <form @submit.prevent="submitForm">
      <div class="page-header mt-5">
        <h4 class="page-title">{{ $t('expenses.title') }}</h4>
        <div class="page-actions" />
      </div>

      <!-- Row expense info -->
      <div class="row invoice-input-group">
        <!-- Provider name -->
        <div class="col invoice-input">
          <label>{{ $t('expenses.name') }} <span class="text-danger"> * </span></label>
          <base-input
            v-model="expenseForm.name"
            :invalid="$v.expenseForm.name.$error"
            icon="user"
            type="text"
          />
          <div v-if="$v.expenseForm.name.$error">
            <span v-if="!$v.expenseForm.name.maxLength" class="text-danger">{{ $t('validation.required') }}</span>
          </div>
        </div>
        <!-- Expense date -->
        <div class="col invoice-input">
          <label>{{ $t('expenses.expense_date') }} <span class="text-danger"> * </span></label>
          <base-date-picker
            v-model="expenseForm.expense_date"
            :invalid="$v.expenseForm.expense_date.$error"
            :calendar-button="true"
            calendar-button-icon="calendar"
            @change="$v.expenseForm.expense_date.$touch()"
          />
          <span v-if="$v.expenseForm.expense_date.$error && !$v.expenseForm.expense_date.required" class="text-danger mt-1"> 
            {{ $t('validation.required') }}
          </span>
        </div>
        <!-- Expense number -->
        <div class="col invoice-input">
          <label>{{ $t('expenses.expense_number') }}</label>
          <base-input
            v-model="expenseForm.expense_number"
            icon="hashtag"
            type="text"
          />
        </div>
      </div>
      <div class="row mt-4">
        <!-- Provider RFC -->
        <div class="col invoice-input">
          <label>{{ $t('expenses.rfc') }} <span class="text-danger"> * </span></label>
          <base-input
            v-model="expenseForm.rfc"
            :invalid="$v.expenseForm.rfc.$error"
            icon="user"
            type="text"
          />
          <div v-if="$v.expenseForm.rfc.$error">
            <span v-if="!$v.expenseForm.rfc.maxLength" class="text-danger">{{ $t('validation.required') }}</span>
          </div>
        </div>
        <div class="col invoice-input form-group">
          <label class="control-label">{{ $t('expenses.category') }}</label><span class="text-danger"> * </span>
          <base-select
            ref="baseSelect"
            v-model="category"
            :options="categories"
            :invalid="$v.category.$error"
            :searchable="true"
            :show-labels="false"
            :placeholder="$t('expenses.categories.select_a_category')"
            label="name"
            track-by="id"
            @input="$v.category.$touch()"
          >
            <div slot="afterList">
              <button type="button" class="list-add-button" @click="openCategoryModal">
                <font-awesome-icon class="icon" icon="cart-plus" />
                <label>{{ $t('settings.expense_category.add_new_category') }}</label>
              </button>
            </div>
          </base-select>
          <div v-if="$v.category.$error">
            <span v-if="!$v.category.required" class="text-danger">{{ $t('validation.required') }}</span>
          </div>
        </div>
        <div class="col invoice-input" />
      </div>

      <!-- Row items -->
      <table class="item-table mt-5">
        <colgroup>
          <col style="width: 40%;">
          <col style="width: 10%;">
          <col style="width: 15%;">
          <col style="width: 15%;">
        </colgroup>
        <thead class="item-table-header">
          <tr>
            <th class="text-left">
              <span class="column-heading item-heading">
                {{ $tc('items.item',2) }}
              </span>
            </th>
            <th class="text-center">
              <span class="column-heading">
                {{ $t('invoices.item.quantity') }}
              </span>
            </th>
            <th class="text-left">
              <span class="column-heading">
                {{ $t('invoices.item.price') }}
              </span>
            </th>
            <th class="text-right">
              <span class="column-heading amount-heading">
                {{ $t('invoices.item.total') }}
              </span>
            </th>
          </tr>
        </thead>
        <tbody class="item-body">
          <create-expense-item
            v-for="(item, index) in expenseForm.items"
            :key="item.id"
            :index="index"
            :item-data="item"
            :items="expenseForm.items"
            @remove="removeItem"
            @update="updateItem"
            @itemValidate="checkItemsData"
          />
        </tbody>
      </table>
      <div class="add-item-action" @click="addItem">
        <font-awesome-icon icon="shopping-basket" class="mr-2"/>
        {{ $t('invoices.add_item') }}
      </div>

      <!-- Row notes, subtotal, discount, total -->
      <div class="row mt-4 invoice-foot">
        <!-- Notes -->
        <div class="col">
          <label>{{ $t('invoices.notes') }}</label>
          <base-text-area
            v-model="expenseForm.notes"
            rows="3"
            cols="50"
            @input="$v.expenseForm.notes.$touch()"
          />
          <div v-if="$v.expenseForm.notes.$error">
            <span v-if="!$v.expenseForm.notes.maxLength" class="text-danger">{{ $t('validation.notes_maxlength') }}</span>
          </div>
        </div>

        <div class="col" />

        <div class="col">
          <div class="invoice-total">

            <!-- Subtotal -->
            <div class="section">
              <label class="invoice-label">{{ $t('expenses.sub_total') }}</label>
              <label class="invoice-amount">
                <div v-html="$utils.formatMoney(subtotal, defaultCurrency)" />
              </label>
            </div>

            <!-- Discount -->
            <div class="section">
              <label class="invoice-label">{{ $t('invoices.discount') }}</label>
              <div class="invoice-amount">
                <div
                  class="btn-group discount-drop-down"
                  role="group"
                >
                  <base-input
                    v-model="expenseForm.discount"
                    :invalid="$v.expenseForm.discount_val.$error"
                    input-class="item-discount"
                    @input="$v.expenseForm.discount_val.$touch()"
                  />
                  <v-dropdown :show-arrow="false">
                    <button
                      slot="activator"
                      type="button"
                      class="btn item-dropdown dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {{ expenseForm.discount_type == 'fixed' ? defaultCurrency.symbol : '%' }}
                    </button>
                    <v-dropdown-item>
                      <a class="dropdown-item" href="#" @click.prevent="selectPercentage">
                        {{ $t('general.percentage') }}
                      </a>
                    </v-dropdown-item>
                    <v-dropdown-item>
                      <a class="dropdown-item" href="#" @click.prevent="selectFixed">
                        {{ $t('general.fixed') }}
                      </a>
                    </v-dropdown-item>
                  </v-dropdown>
                </div>
              </div>
            </div>

            <!-- Base -->
            <div class="section">
              <label class="invoice-label">{{ $t('expenses.base') }}</label>
              <label class="invoice-amount">
                <div v-html="$utils.formatMoney(base, defaultCurrency)" />
              </label>
            </div>

            <!-- Tax list -->
            <div>
              <tax
                v-for="(tax, index) in expenseForm.taxes"
                :index="index"
                :total="base"
                :key="tax.id"
                :tax="tax"
                :taxes="expenseForm.taxes"
                :currency="defaultCurrency"
                :total-tax="totalTax"
                @remove="removeExpenseTax"
                @update="updateTax"
              />
            </div>

            <!-- Tax selector -->
            <div>
              <base-popup ref="taxModal" class="tax-selector">
                <div slot="activator" class="float-right">
                  + {{ $t('invoices.add_tax') }}
                </div>
                <tax-select-popup 
                  :taxes="[]"
                  :show-search-bar="false"
                  :show-add-new-tax="false"
                  @select="onSelectTax"
                />
              </base-popup>
            </div>

            <!-- Total -->
            <div class="section border-top mt-3">
              <label class="invoice-label">{{ $t('invoices.total') }}</label>
              <label class="invoice-amount">
                <div v-html="$utils.formatMoney(totalAmount, defaultCurrency)" />
              </label>
            </div>

          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-4 offset-8 center-content">
          <base-button
            :loading="isLoading"
            :disabled="isLoading"
            icon="save"
            color="theme"
            class="invoice-action-btn"
            type="submit"
          >
            {{ $t('expenses.save_expense') }}
          </base-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required, between, maxLength } from 'vuelidate/lib/validators';
import moment from 'moment';
import draggable from 'vuedraggable';
import { validationMixin } from 'vuelidate';
import { mapActions, mapGetters } from 'vuex';
import CreateExpenseItem from './CreateExpenseItem';
import Tax from '../invoices/InvoiceTax';
import ExpenseStub from '../../stub/expense';
import TaxStub from '../../stub/tax';
import Guid from 'guid';

export default {
  components: {
    draggable,
    CreateExpenseItem,
    Tax,
  },
  mixins: [validationMixin],
  data: () => ({
    expenseForm: {
      name: '',
      rfc: '',
      customer_id: null,
      expense_date: '',
      expense_number: '',
      subtotal: 0,
      discount: 0,
      discount_type: 'percentage',
      discount_val: 0,
      notes: '',
      base: 0,
      total: 0,
      items: [
        {
          id: Guid.raw(),
          ...ExpenseStub,
        }
      ],
      tax_amount: 0,
      taxes: [],
      expense_category_id: null,
    },
    category: null,
    isLoading: false,
  }),
  computed: {
    ...mapGetters('category', [
      'categories'
    ]),
    ...mapGetters('currency', [
      'defaultCurrency'
    ]),
    ...mapGetters('CurrentCustomer', [
      'currentCustomer',
    ]),
    base () {
      return this.subtotal - this.discountVal;
    },
    discountVal () {
      if (this.expenseForm.discount == 0) {
        return 0;
      }
      if (this.expenseForm.discount_type === 'percentage') {
        return this.subtotal * (Number(this.expenseForm.discount) / 100);
      } else {
        return Number(this.expenseForm.discount) * 100;
      }
    },
    subtotal () {
      return this.expenseForm.items.reduce((accumulator, item) => {
        return accumulator + ((item.price * 100) * item.quantity);
      }, 0);
    },
    totalAmount () {
      return this.base + this.taxAmount - this.retentionAmount;
    },
    totalTax () {
      return this.expenseForm.tax_amount - this.expenseForm.retention_amount;
    },
    taxAmount () {
      const taxes = this.expenseForm.taxes.filter(tax => tax.tax_type == 'tax');
      
      return taxes.reduce((accumulator, tax) => {
        return accumulator + tax.amount;
      }, 0);
    },
    retentionAmount () {
      const taxes = this.expenseForm.taxes.filter(tax => tax.tax_type == 'retention');

      return taxes.reduce((accumulator, tax) => {
        return accumulator + tax.amount;
      }, 0);
    },
  },
  watch: {
    category (newValue) {
      this.expenseForm.expense_category_id = newValue.id;
    }
  },
  validations: function () {
    return {
      category: {
        required
      },
      expenseForm: {
        name: {
          required,
        },
        rfc: {
          required,
        },
        expense_date: {
          required,
        },
        discount_val: {
          between: between(0, this.subtotal)
        },
        notes: {
          maxLength: maxLength(255)
        },
      }
    };
  },
  created () {
    this.expenseForm.expense_date = moment().toString();
  },
  mounted () {
    if (!this.currentCustomer) {
      this.$router.push('/general/customers')
    }
    window.hub.$on('newCategory', (val) => {
      this.category = val
    });
  },
  methods: {
    ...mapActions('category', [
      'fetchCategories'
    ]),
    ...mapActions('expense', [
      'addExpense',
    ]),
    ...mapActions('modal', [
      'openModal'
    ]),
    addItem () {
      this.expenseForm.items.push({
        id: Guid.raw(),
        ...ExpenseStub,
      });
    },
    changeForm () {
      for (let i = 0; i < this.expenseForm.taxes.length; i++) {
        const tax = this.expenseForm.taxes[i];
        this.expenseForm.taxes[i].amount = (this.base * tax.percent) / 100;
      }
    },
    checkItemsData (index, isValid) {
      this.expenseForm.items[index].valid = isValid
    },
    checkValid () {
      this.$v.expenseForm.$touch()
      this.$v.category.$touch()
      window.hub.$emit('checkItems')
      let isValid = true
      this.expenseForm.items.forEach((item) => {
        if (!item.valid) {
          isValid = false
        }
      })
      if (this.$v.expenseForm.$invalid === false && this.$v.category.$invalid === false && isValid === true) {
        return true;
      }
      return false;
    },
    createExpense () {
      this.isLoading = true;
      const data = {
        ...this.expenseForm,
        expense_date: moment(this.expenseForm.expense_date).format('DD/MM/YYYY'),
        customer_id: this.currentCustomer.id,
        subtotal: this.subtotal,
        base: this.base,
        total: this.totalAmount,
        discount_val: this.discountVal,
        tax_amount: this.taxAmount - this.retentionAmount,
      };
      data['taxes'] = this.expenseForm.taxes.map((tax) => {
        return tax.tax_type_id;
      });
      this.addExpense(data)
        .then((response) => {
          if (response.data.success) {
            window.toastr['success'](this.$t('expenses.created_message'));
            this.isLoading = false;
            this.$router.push('/general/expenses');
          }
        })
        .catch((err) => {
          this.isLoading = false;
          window.toastr['error'](err.response.data.error)
        })
    },
    openCategoryModal () {
      this.openModal({
        'title': 'Add Category',
        'componentName': 'CategoryModal'
      })
      // this.$refs.table.refresh()
    },
    onSelectTax (selectedTax) {
      const existsTax = this.expenseForm.taxes.filter((tax) => tax.tax_type_id == selectedTax.id).length;
      if (existsTax >= 1) {
        window.toastr['warning'](this.$t('validation.you_cant_add_same_tax'));
        this.$refs.taxModal.close();
        return;
      }

      let amount = 0
      if (this.base && selectedTax.percent) {
        amount = (this.base * selectedTax.percent) / 100
      }

      this.expenseForm.taxes.push({
        ...TaxStub,
        id: Guid.raw(),
        name: selectedTax.name,
        percent: selectedTax.percent,
        compound_tax: selectedTax.compound_tax,
        tax_type_id: selectedTax.id,
        tax_type: selectedTax.tax_type,
        amount
      })

      this.changeForm();
      this.$refs.taxModal.close();
    },
    removeItem (index) {
      this.expenseForm.items.splice(index, 1);
    },
    removeExpenseTax (index) {
      this.expenseForm.taxes.splice(index, 1)
    },
    submitForm () {
      if (!this.checkValid()) {
        return false
      }

      this.createExpense();
    },
    selectFixed () {
      if (this.expenseForm.discount_type === 'fixed') {
        return;
      }
      this.expenseForm.discount_val = this.expenseForm.discount * 100;
      this.expenseForm.discount_type = 'fixed';
    },
    selectPercentage () {
      if (this.expenseForm.discount_type === 'percentage') {
        return;
      }
      this.expenseForm.discount_val = (this.subtotal * this.expenseForm.discount) / 100;
      this.expenseForm.discount_type = 'percentage';
    },
    updateItem (data) {
      Object.assign(this.expenseForm.items[data.index], {...data.item})
      this.changeForm();
    },
    updateTax (data) {
      Object.assign(this.expenseForm.taxes[data.index], {...data.item})
    },
  }
}
</script>
