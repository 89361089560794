<template>
  <div class="table-responsive">
    <h6 class="text-center">{{ $t(title) }}</h6>

    <table class="table table-bordered table-active">
      <!-- <thead>
        <tr>
          <th>{{ $t('invoices.uuid') }}</th>
          <th>{{ $t('invoices.rfc') }}</th>
          <th>{{ $t('invoices.name') }}</th>
          <th>{{ $t('retenciones.date') }}</th>
          <th>{{ $t('invoices.iva') }}</th>
          <th>{{ $t('expenses.retention_iva') }}</th>
          <th>{{ $t('expenses.retention_isr') }}</th>
        </tr>
      </thead> -->
      <tbody>
        <tr
          class="clickable"
          @click="toggleDisplayTable('iva_pagado_gastos_adquisiciones')"
        >
          <td>
            <font-awesome-icon class="icon" icon="arrow-down" />
            IVA pagado en gastos y adquisiciones
          </td>
          <td class="text-right">
            <money-text
              :amount="taxReturn.amounts.iva_pagado_gastos_adquisiciones"
            />
          </td>
        </tr>
        <tr v-if="displayTables.iva_pagado_gastos_adquisiciones">
          <td colspan="2">
            <tax-return-invoices-table
              v-if="taxReturn.tables.purchases"
              :invoices="taxReturn.tables.purchases.invoices"
              title="IVA pagado en gastos y adquisiciones"
            />
          </td>
        </tr>
        <tr class="clickable" @click="toggleDisplayTable('iva_acreditable')">
          <td>
            <font-awesome-icon class="icon" icon="arrow-down" />
            IVA acreditable por actividades gravadas a tasa 16% u 8% y tasa 0%
          </td>
          <td class="text-right">
            <money-text :amount="taxReturn.amounts.iva_acreditable" />
          </td>
        </tr>
        <tr v-if="displayTables.iva_acreditable">
          <td colspan="2">
            <table class="table table-bordered table-info">
              <tbody>
                <tr>
                  <td>Total IVA pagado en gastos y adquisiones</td>
                  <td class="text-right">
                    <money-text
                      :amount="
                        taxReturn.amounts.iva_pagado_gastos_adquisiciones
                      "
                    />
                  </td>
                </tr>
                <tr
                  class="clickable"
                  @click="toggleDisplayTable('proporcion_iva')"
                >
                  <td>
                    <font-awesome-icon class="icon" icon="arrow-down" />
                    Proporción de IVA
                  </td>
                  <td class="text-right">
                    {{ taxReturn.amounts.proporcion_iva }}
                  </td>
                </tr>
                <tr v-if="displayTables.proporcion_iva">
                  <td colspan="2">
                    <table class="table table-bordered table-success">
                      <tbody>
                        <tr>
                          <td>Actos gravados 16% + Actos gravados 0%</td>
                          <td class="text-right">
                            <money-text
                              :amount="
                                taxReturn.amounts
                                  .actos_gravados_16_actos_gravados_0
                              "
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Actos gravados 16% + Actos gravados 0%+ Actos
                            exentos
                          </td>
                          <td class="text-right">
                            <money-text
                              :amount="
                                taxReturn.amounts
                                  .actos_gravados_16_actos_gravados_0_actos_excentros
                              "
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>IVA acreditable</td>
                  <td class="text-right">
                    <money-text :amount="taxReturn.amounts.iva_acreditable" />
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>

        <tr>
          <td>*IVA acreditable por actividades mixtas</td>
          <td class="text-right">
            <!-- <money-text :amount="taxReturn.amounts.resicoRecargosIva" /> -->
          </td>
        </tr>
        <tr>
          <td>IVA acreditable del periodo</td>
          <td class="text-right">
            <money-text :amount="taxReturn.amounts.iva_acreditable" />
          </td>
        </tr>
        <tr>
          <td>IVA no acreditable</td>
          <td class="text-right">
            <money-text :amount="taxReturn.amounts.iva_no_acreditable" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import TaxReturnInvoicesTable from './TaxReturnInvoicesTable'

export default {
  components: {
    TaxReturnInvoicesTable,
  },
  props: {
    taxReturn: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    displayTables: {
      iva_pagado_gastos_adquisiciones: false,
      iva_acreditable: false,
      proporcion_iva: false,
    },
  }),
  computed: {},
  methods: {
    toggleDisplayTable(table) {
      this.displayTables[table] = !this.displayTables[table]
    },
  },
}
</script>

<style lang="scss" scoped>
.table {
  border: 1px solid #d7d8da;
}
.clickable {
  td {
    font-weight: 500;
  }
}
</style>
