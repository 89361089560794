<template>
  <div class="main-content create-treasury">
    <form @submit.prevent="submitForm">
      <div class="page-header pt-5">
        <h3>{{ $t('treasury_accounts.transaction') }}</h3>
      </div>

      <div class="row">
        <!-- Name input -->
        <div class="col-4">
          <div class="form-group">
            <label>{{ $t('treasury_accounts.name') }} <span class="text-danger"> * </span></label>
            <base-input
              v-model="treasuryForm.name"
              :invalid="$v.treasuryForm.name.$error"
              type="text"
              @input="$v.treasuryForm.name.$touch()"
            />
            <div v-if="$v.treasuryForm.name.$error">
              <span v-if="!$v.treasuryForm.name.required" class="text-danger">{{ $t('validation.required') }}</span>
            </div>
          </div>
        </div>

        <!-- Date input -->
        <div class="col-4">
          <label>{{ $t('treasury_accounts.treasury_date') }} <span class="text-danger"> * </span></label>
          <base-date-picker
            v-model="treasuryForm.treasury_date"
            :invalid="$v.treasuryForm.treasury_date.$error"
            :calendar-button="true"
            calendar-button-icon="calendar"
            @change="$v.treasuryForm.treasury_date.$touch()"
          />
          <span v-if="$v.treasuryForm.treasury_date.$error && !$v.treasuryForm.treasury_date.required" class="text-danger mt-1"> 
            {{ $t('validation.required') }}
          </span>
        </div>
      </div>

      <div class="row">
        <!-- Payments amount input -->
        <div class="col-4">
          <div class="form-group">
            <label>{{ $t('treasury_accounts.payments') }} <span class="text-danger"> * </span></label>
            <div class="base-input">
              <money
                v-model="treasuryForm.payments_amount"
                v-bind="customerCurrency"
                class="input-field"
                @input="$v.treasuryForm.payments_amount.$touch()"
              />
            </div>
            <div v-if="$v.treasuryForm.payments_amount.$error">
              <span v-if="!$v.treasuryForm.payments_amount.decimal" class="text-danger">{{ $t('validation.numbers_only') }}</span>
            </div>
          </div>
        </div>

        <!-- Expenses amount input -->
        <div class="col-4">
          <div class="form-group">
            <label>{{ $t('treasury_accounts.expenses') }} <span class="text-danger"> * </span></label>
            <div class="base-input">
              <money
                v-model="treasuryForm.expenses_amount"
                v-bind="customerCurrency"
                class="input-field"
                @input="$v.treasuryForm.expenses_amount.$touch()"
              />
            </div>
            <div v-if="$v.treasuryForm.expenses_amount.$error">
              <span v-if="!$v.treasuryForm.expenses_amount.required" class="text-danger">{{ $t('validation.required') }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-4 offset-4 flex-content-right">
          <base-button
            :loading="isLoading"
            :disabled="isLoading"
            icon="save"
            color="theme"
            type="submit"
          >
            {{ $t('treasury_accounts.save_transaction') }}
          </base-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import decimalValidation from 'vuelidate/lib/validators/decimal';
import { validationMixin } from 'vuelidate';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

export default {
  mixins: [validationMixin],
  data: () => ({
    isLoading: false,
    treasuryForm: {
      name: '',
      payments_amount: 0,
      expenses_amount: 0,
      treasury_date: '',
    }
  }),
  computed: {
    ...mapGetters('currency', [
      'defaultCurrency'
    ]),
    ...mapGetters('CurrentCustomer', [
      'currentCustomer',
    ]),
    customerCurrency () {
      if (this.defaultCurrency) {
        return {
          decimal: this.defaultCurrency.decimal_separator,
          thousands: this.defaultCurrency.thousand_separator,
          prefix: this.defaultCurrency.symbol + ' ',
          precision: this.defaultCurrency.precision,
          masked: false
        }
      } else {
        return this.defaultCurrenctForInput
      }
    },
  },
  validations: {
    treasuryForm: {
      name: {
        required
      },
      payments_amount: {
        decimal: decimalValidation,
      },
      expenses_amount: {
        decimal: decimalValidation,
      },
      treasury_date: {
        required,
      },
    }
  },
  created () {
    if (!this.currentCustomer) {
      this.$router.push('/general/customers');
    }
    this.treasuryForm.treasury_date = moment()
      .subtract(1, 'month')
      .toISOString()
  },
  methods: {
    ...mapActions('treasuryAccounts', [
      'createTreasuryAccount'
    ]),
    sendCreateTreasury () {
      this.isLoading = true;
      const data = {
        ...this.treasuryForm,
        treasury_date: moment(this.treasuryForm.treasury_date).format('YYYY-MM-DD'),
        user_id: this.currentCustomer.id,
      };
      this.createTreasuryAccount(data)
        .then(response => {
          console.log(response);
          this.isLoading = false;
          if (response.data.success) {
            window.toastr['success'](this.$t('treasury_accounts.created_message'));
            this.$router.push({name: 'treasury_accounts.index'});
          }
        })
        .catch(err => {
          this.isLoading = false;
          window.toastr['error'](this.$t('treasury_accounts.errors.save'));
          if (err.response) {
            window.toastr['error'](err.response.data.error);
          }
        });
    },
    submitForm () {
      this.$v.treasuryForm.$touch();
      if (this.$v.treasuryForm.$invalid !== false) {
        return;
      }
      this.sendCreateTreasury();
    }
  }
}
</script>