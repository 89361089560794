<template>
  <transition name="fade">
    <div v-if="modalActive" :class="'size-' + modalSize" class="base-modal">
      <div class="modal-body">
        <div class="close-icon" @click="closeModal">
          <font-awesome-icon icon="times" />
        </div>
        <div class="modal-header p-3">
          <h5 class="modal-heading">{{ modalTitle }}</h5>
        </div>
        <component :is="component" style="max-height: 80vh; overflow-y: auto" />
      </div>
    </div>
  </transition>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import TaxTypeModal from './TaxTypeModal'
import ItemModal from './ItemModal'
import EstimateTemplate from './EstimateTemplate'
import InvoiceTemplate from './InvoiceTemplate'
import CustomerModal from './CustomerModal'
import CategoryModal from './CategoryModal'
import PaymentMode from './PaymentModeModal'
import ItemUnit from './ItemUnitModal'
import MailTestModal from './MailTestModal'
import UploadModal from './UploadModal'
import RelateInvoiceToTreasuryAccountModal from './RelateInvoiceToTreasuryAccountModal'
import ChangeCategoryToInvoiceConceptModal from './ChangeCategoryToInvoiceConceptModal'
import ChangeCategoriesToInvoiceConceptModal from './ChangeCategoriesToInvoiceConceptModal'
import AddPaidDocumentModal from './AddPaidDocumentModal'
import AddConceptCategoryModal from './AddConceptCategoryModal'
import AddConceptCategoryModalUser from './AddConceptCategoryModalUser'
import CompensacionesModal from './CompensacionesModal'
import ExcesoRifModal from './ExcesoRifModal'

import AddConceptSubCategoryModal from './AddConceptSubcategoryModal'
import AddPagoModal from './AddPagoModal'
import AddPaqueteModal from './AddPaqueteModal'
import AddPaqueteFoliosModal from './AddPaqueteFoliosModal'
import AddPaqueteDeclaraFoliosModal from './AddPaqueteDeclaraFoliosModal'
import AddTaxLossModal from './AddTaxLossModal'
import ApplyTaxLossModal from './ApplyTaxLossModal'
import SaldoRifModal from './SaldoRifModal'
import DetalleCompensacionModal from './DetalleCompensacionModal'
import DetalleAcreditamientoModal from './DetalleAcreditamientoModal'
import DetalleIngresoDisminuir from './DetalleIngresoDisminuir'
import DetalleIngresoAdicional from './DetalleIngresoAdicional'
import DetalleIsrRetenido from './DetalleIsrRetenido'
import DetalleIvaRetenido from './DetalleIvaRetenido'
import DetalleComplementoPago from './DetalleComplementoPago'
import EditDescriptionAccount from './EditDescriptionAccount'

export default {
  components: {
    TaxTypeModal,
    ItemModal,
    EstimateTemplate,
    InvoiceTemplate,
    CustomerModal,
    CategoryModal,
    PaymentMode,
    ItemUnit,
    MailTestModal,
    UploadModal,
    RelateInvoiceToTreasuryAccountModal,
    ChangeCategoryToInvoiceConceptModal,
    ChangeCategoriesToInvoiceConceptModal,
    AddPaidDocumentModal,
    AddConceptCategoryModal,
    AddConceptCategoryModalUser,
    AddConceptSubCategoryModal,
    AddPagoModal,
    AddPaqueteModal,
    AddPaqueteFoliosModal,
    AddPaqueteDeclaraFoliosModal,
    AddTaxLossModal,
    ApplyTaxLossModal,
    CompensacionesModal,
    ExcesoRifModal,
    SaldoRifModal,
    DetalleCompensacionModal,
    DetalleAcreditamientoModal,
    DetalleIngresoDisminuir,
    DetalleIngresoAdicional,
    DetalleIsrRetenido,
    DetalleIvaRetenido,
    DetalleComplementoPago,
    EditDescriptionAccount,
  },
  data() {
    return {
      component: '',
      hasFocus: false,
    }
  },
  computed: {
    ...mapGetters('modal', [
      'modalActive',
      'modalTitle',
      'componentName',
      'modalSize',
      'modalData',
    ]),
  },
  watch: {
    componentName(component) {
      if (!component) {
        return
      }

      this.component = component
    },
  },
  methods: {
    ...mapActions('modal', ['openModal', 'closeModal']),
  },
}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
