<template>
  <div v-if="invoices" class="table-responsive">
    <h6 v-if="title" class="text-center">{{ $t(title) }}</h6>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>{{ $t('invoices.uuid') }}</th>
          <th>{{ $t('invoices.rfc') }}</th>
          <th>{{ $t('invoices.name') }}</th>
          <th>{{ $t('invoices.invoice_date') }}</th>
          <!-- <th>{{ $t('invoices.percentage_paid') }}</th> -->
          <th>{{ $t('invoices.sub_total') }}</th>
          <th>{{ $t('invoices.iva') }}</th>
          <th v-if="hasRetentionIva">{{ $t('expenses.retention_iva') }}</th>
          <th v-if="hasRetentionIsr">{{ $t('expenses.retention_isr') }}</th>
          <th>{{ $t('invoices.total') }}</th>
          <th v-if="showNoObjectColumn">
            {{ $t('tax_return.no_object') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="invoice in invoices" :key="invoice.id">
          <td>
            <router-link
              :to="{ name: 'invoices.view', params: { id: invoice.id } }"
            >
              {{ invoice.uuid }}
            </router-link>
          </td>
          <td>{{ invoice.rfc }}</td>
          <td>
            <sub-string :string="invoice.name" />
          </td>
          <td>{{ invoice.invoice_date }}</td>
          <!-- <td>{{ invoice.percentage_paid }}</td> -->
          <td class="text-right">
            <money-text :amount="invoice.subtotal" />
          </td>
          <td class="text-right">
            <money-text :amount="invoice.iva" />
          </td>
          <td v-if="hasRetentionIva" class="text-right">
            <money-text :amount="invoice.retention_iva" />
          </td>
          <td v-if="hasRetentionIsr" class="text-right">
            <money-text :amount="invoice.retention_isr" />
          </td>
          <td class="text-right">
            <money-text :amount="invoice.total" />
          </td>
          <td v-if="showNoObjectColumn" class="text-right">
            <money-text :amount="invoice.no_object" />
          </td>
        </tr>
        <tr v-if="tableTotals">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="text-right">
            <strong>
              <money-text :amount="tableTotals.subtotal" />
            </strong>
          </td>
          <td class="text-right">
            <strong>
              <money-text :amount="tableTotals.iva" />
            </strong>
          </td>
          <td v-if="hasRetentionIva" class="text-right">
            <money-text :amount="tableTotals.retention_iva" />
          </td>
          <td v-if="hasRetentionIsr" class="text-right">
            <money-text :amount="tableTotals.retention_isr" />
          </td>
          <td class="text-right">
            <strong>
              <money-text :amount="tableTotals.total" />
            </strong>
          </td>
          <td v-if="showNoObjectColumn" class="text-right">
            <strong>
              <money-text :amount="tableTotals.no_object" />
            </strong>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    invoices: {
      type: Array,
      default: null,
    },
    totals: {
      type: Object,
      default: null,
    },
    canDisplayNoObject: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasRetentionIva() {
      if (!this.invoices.length) {
        return false
      }
      if (this.invoices[0].hasOwnProperty('retention_iva')) {
        return true
      }
      return false
    },
    hasRetentionIsr() {
      if (!this.invoices.length) {
        return false
      }
      if (this.invoices[0].hasOwnProperty('retention_isr')) {
        return true
      }
      return false
    },
    tableTotals() {
      if (this.totals) return this.totals
      return this.invoices.reduce(
        (accumulator, item) => {
          return {
            iva: accumulator.iva + Number(item.iva),
            subtotal: accumulator.subtotal + Number(item.subtotal),
            total: accumulator.total + Number(item.total),
            retention_isr:
              accumulator.retention_isr + Number(item.retention_isr),
            retention_iva:
              accumulator.retention_iva + Number(item.retention_iva),
            exempt: accumulator.exempt + Number(item.exempt),
            no_object: accumulator.no_object + Number(item.no_object),
          }
        },
        {
          iva: 0,
          subtotal: 0,
          total: 0,
          retention_isr: 0,
          retention_iva: 0,
          exempt: 0,
          no_object: 0,
        }
      )
    },
    showNoObjectColumn() {
      return this.tableTotals.no_object && this.canDisplayNoObject
    },
  },
}
</script>

<style lang="sass" scoped>
.table {
  border: 1px solid #d7d8da;
  th, td {
    font-size: 12px;
  }
}
</style>
