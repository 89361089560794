<template>
  <div class="main-content">
    <div class="page-header mt-4">
      <h3 class="page-title">Pagos</h3>
      <div class="page-actions row">
        <div class="col-4">
          <base-button @click="openAddPagosModal()" color="theme">
            Registrar Pago
          </base-button>
        </div>
      </div>
    </div>

    <table-component
      ref="table"
      :data="pedidos"
      :filter-no-results="$t('general.filterNoResults')"
      :is-clickable="true"
      :show-filter="false"
      table-class="table"
      @columnClick="rowClicked"
    >
      <table-column label="Plan" show="stripe_plan" />
      <table-column label="Precio unitario" show="unit_price" />
      <table-column label="Inicia" show="name_month_start" />
      <table-column label="Termina" show="name_month_end" />
      <table-column label="Cantidad de Folios" show="quantity" style="width: 30px;"/>
      <table-column label="Folios Utilizados" show="quantity" />
    </table-component>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Pagination from '../../components/base/base-table/components/Pagination'

export default {
  components: {
    Pagination,
  },

  data: () => ({
    user_id: '',
    pedidos: [],
  }),

  computed: {
    ...mapGetters('CurrentCustomer', ['currentCustomer']),
  },

  mounted() {
    this.loadPedidos()
    window.hub.$on('concept_categories.category.created', () => {
      this.loadPedidos()
    })
  },

  methods: {
    ...mapActions('modal', ['openModal']),
    ...mapActions('customer', ['fetchPagos']),

    loadPedidos() {
      this.user_id = this.currentCustomer.id
      this.fetchPagos(this.user_id).then((response) => {
        this.pedidos = response.data.data
      })
    },
    openAddPagosModal() {
      this.openModal({
        title: 'Registrar pago',
        componentName: 'AddPagoModal',
      })
    },
    rowClicked(){
      console.log("click row")
    }
  },
}
</script>
