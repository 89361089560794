import * as types from './mutation-types';

export const fetchRetenciones = ({commit}, params) => {
  return new Promise((resolve, reject) => {
    window.axios.get('/api/retentions', {params})
    .then(response => {
      commit(types.SET_RETENCIONES, response.data.data);
      resolve(response);
    })
    .catch(err => reject(err));
  })
}

export const fetchRetention = (store, id) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`/api/retentions/${id}`)
    .then(response => resolve(response))
    .catch(err => reject(err));
  });
}