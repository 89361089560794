var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-content" }, [
    _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [
        _vm._v(_vm._s(_vm.$t("customer_prod_serv.prod_serv_groups"))),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("table", { staticClass: "table" }, [
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("customer_prod_serv.category")))]),
              _vm._v(" "),
              _c("th", [
                _vm._v(
                  _vm._s(_vm.$t("customer_prod_serv.tax_return_payments"))
                ),
              ]),
              _vm._v(" "),
              _c("th", [
                _vm._v(
                  _vm._s(_vm.$t("customer_prod_serv.tax_return_expenses"))
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.prodServGroups, function (group) {
              return _c("tr", { key: group.id }, [
                _c("td", [_vm._v(_vm._s(group.description))]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-center" },
                  [
                    _c("base-switch", {
                      staticClass: "btn-switch",
                      on: {
                        change: function ($event) {
                          return _vm.changeGroupInTaxReturn(
                            "payments",
                            group,
                            $event
                          )
                        },
                      },
                      model: {
                        value: group.group_category.payments_tax_return,
                        callback: function ($$v) {
                          _vm.$set(
                            group.group_category,
                            "payments_tax_return",
                            $$v
                          )
                        },
                        expression: "group.group_category.payments_tax_return",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-center" },
                  [
                    _c("base-switch", {
                      staticClass: "btn-switch",
                      on: {
                        change: function ($event) {
                          return _vm.changeGroupInTaxReturn(
                            "expenses",
                            group,
                            $event
                          )
                        },
                      },
                      model: {
                        value: group.group_category.expenses_tax_return,
                        callback: function ($$v) {
                          _vm.$set(
                            group.group_category,
                            "expenses_tax_return",
                            $$v
                          )
                        },
                        expression: "group.group_category.expenses_tax_return",
                      },
                    }),
                  ],
                  1
                ),
              ])
            }),
            0
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }